import { defineStore, acceptHMRUpdate } from "pinia";
import { apiGetSearchQuery } from "@/modules/common/_utils/api";
export interface StateInterface {
  sidebarCollapsed: boolean;
  inSearchMode: boolean;
  searchQuery: string;
  searchQueryResults: any;
}

export const useCommonStore = defineStore("common", {
  state: (): StateInterface => {
    return {
      sidebarCollapsed: true,
      inSearchMode: false,
      searchQuery: "",
      searchQueryResults: {},
    };
  },
  actions: {
    toggleSidebarCollapse(): void {
      this.sidebarCollapsed = !this.sidebarCollapsed;
    },

    resetSearchMode(): void {
      this.inSearchMode = false;
    },

    enterSearchMode(): void {
      this.inSearchMode = true;
    },

    resetSearchQuery(): void {
      this.searchQuery = "";
      this.searchQueryResults = {};
    },

    getSearchQueryResults(query: string): any {
      return new Promise((resolve, reject) => {
        this.setSearchQuery(query);
        apiGetSearchQuery(query)
          .then((res: any) => {
            this.setSearchQueryResults(res.data.data);
            resolve(res.data.data);
          })
          .catch((err: any) => {
            reject(err);
          });
      });
    },

    setSearchQuery(query: string): void {
      this.searchQuery = query;
    },

    setSearchQueryResults(result: any): void {
      this.searchQueryResults = result;
    },
  },
});

// HMR
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCommonStore, import.meta.hot));
}
