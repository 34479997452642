<template>
  <a-layout-content class="my-4 mx-3">
    <div class="container">
      <!-- HEADER -->
      <div class="d-flex justify-content-between">
        <h2>Week Schedule</h2>
        <div class="d-flex">
          <portal-target name="week-schedule-filter-buttons"></portal-target>
        </div>
      </div>

      <!-- SCHEDULE FILTER -->
      <week-schedule-filter
        :profile="profile"
        :filters="filtersRef"
        :number-of-results="schedulePagination?.total"
        @apply-filters="(filters) => applyFilters(filters)"
      />

      <!-- SCHEDULE TABLE -->
      <week-schedule-user-list
        :schedule-page="schedulePage"
        :current-page="currentPage"
        :page-size="pageSizeRef"
        :loading-table="loadingTable"
        :schedule-pagination="schedulePagination"
        :sortable="true"
        :profile="profile"
        :filters="filtersRef"
        :acl="baseAcl"
        @apply-sorting="(sorting) => applySorting(sorting)"
        @get-schedule-page="(params) => getSchedulePage(params)"
      />
    </div>
  </a-layout-content>
</template>

<script setup lang="ts">
import { ref, onBeforeMount, computed, getCurrentInstance } from "vue";
import { BaseAclInterface, ProfileInterface } from "@/modules/authentication/types";
import WeekScheduleUserList from "../_components/WeekScheduleUserList.vue";
import { useBookmarkedFiltersStore } from "@/modules/bookmarked_filters/_store";
import WeekScheduleFilter from "../_components/WeekScheduleFilter.vue";
import { useAuthenticationStore } from "@/modules/authentication/_store";
import { useWeekScheduleStore } from "@/modules/week_schedule/_store";
import { WeekScheduleFilterInterface } from "../types";
import { PaginationInterface } from "@/common/types";
import useMixin from "@/useMixin";
// Pinia
const authenticationStore = useAuthenticationStore();
const bookmarkedFiltersStore = useBookmarkedFiltersStore();
const weekScheduleStore = useWeekScheduleStore();
const profile = computed<ProfileInterface | undefined>(() => authenticationStore.profile);
const baseAcl = computed<BaseAclInterface>(() => authenticationStore.baseAcl);
const schedulePagination = computed<PaginationInterface | undefined>(
  () => weekScheduleStore.schedulePagination
);

// Mixins
const { qsDecode, loadTablePageSize, syncRouteParams, persistTablePageSize } = useMixin();

// Instances
const instance = getCurrentInstance();
const $route = instance?.proxy?.$route;

// Data properties
const filtersRef = ref<WeekScheduleFilterInterface>({
  employment_type: ["fixed", "variable", "subcontractor"],
});
const loadingTable = ref<boolean>(false);
const schedulePage = ref<Array<any>>([]);
const pageSizeRef = ref<number>(25);
const currentPage = ref<number>(1);
const sortingRef = ref<object>({});

// Lifecycle hooks
onBeforeMount(() => {
  bookmarkedFiltersStore.getUserBookmarkedFilters(profile.value?.id as number);
  getSchedulePagefromURL();
});

// Component methods
const getSchedulePagefromURL = (url: any = $route?.query) => {
  const { page, filters, sorting, pageSize } = qsDecode(url);

  pageSizeRef.value = loadTablePageSize();

  if (page) currentPage.value = parseInt(page);
  if (filters) filtersRef.value = filters;
  if (sorting) sortingRef.value = sorting;
  if (pageSize) pageSizeRef.value = parseInt(pageSize);
  if (!filters) {
    filtersRef.value.min_workload = 20;
  }

  getSchedulePage({ page: currentPage.value });
};

const getSchedulePage = ({
  page,
  pageSize = pageSizeRef.value,
  filters = filtersRef.value,
  sorting = sortingRef.value,
}: {
  page: any;
  pageSize?: number;
  filters?: any;
  sorting?: object;
}): void => {
  loadingTable.value = true;
  currentPage.value = page;
  pageSizeRef.value = pageSize;

  syncRouteParams(
    {
      page: currentPage.value,
      pageSize: pageSizeRef.value,
      filters: filtersRef.value,
      sorting: sortingRef.value,
    },
    instance
  );
  persistTablePageSize(pageSize);
  weekScheduleStore
    .getScheduleByPage({
      page,
      page_size: pageSize,
      ...{
        ...filters,
        min_workload: (filters.min_workload || 0) / 100,
      },
      ...sorting,
    })
    .then((results: any) => {
      loadingTable.value = false;
      schedulePage.value = results;
    });
};

const applyFilters = (filters: object): void => {
  filtersRef.value = filters;
  currentPage.value = 1;
  syncRouteParams(
    {
      page: currentPage.value,
      pageSize: pageSizeRef.value,
      filters: filtersRef.value,
      sorting: sortingRef.value,
    },
    instance
  );
  getSchedulePage({ page: currentPage.value });
};

const applySorting = (sorting: object): void => {
  sortingRef.value = sorting;

  syncRouteParams(
    {
      page: currentPage.value,
      pageSize: pageSizeRef.value,
      filters: filtersRef.value,
      sorting: sortingRef.value,
    },
    instance
  );
  getSchedulePage({ page: currentPage.value });
};
</script>
