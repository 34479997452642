<template>
  <a-modal
    :visible="visible"
    :destroy-on-close="true"
    title="Create Team"
    ok-text="Create"
    @ok="() => handleStoreTeam()"
    @cancel="$emit('close')"
  >
    <a-form layout="horizontal">
      <SkInput
        v-model="newTeam.name"
        v-validate="'required|max:255'"
        :error="$validator.errors.first('name')"
        data-vv-name="name"
        label="Name"
        placeholder="Team name"
      />

      <SkNameSelect
        v-model="newTeam.team_leader_id"
        v-validate="'required'"
        :error="$validator.errors.first('team_leader')"
        data-vv-name="team_leader"
        data-vv-as="team leader"
        label="Team leader"
        placeholder="Select a team leader"
        :options="users"
      />
    </a-form>
  </a-modal>
</template>

<script setup lang="ts">
import { getCurrentInstance, ref, watch } from "vue";
import { MinimalUserInterface } from "@/modules/users/types";
import useMixin from "@/useMixin";

// Props
const props = defineProps({
  users: { type: Array as () => Array<MinimalUserInterface>, default: () => [] },
  visible: { type: Boolean, default: false },
});

// Emits
const emits = defineEmits(["get-users", "store-team", "close"]);

// Mixins
const { setObject } = useMixin();

// Instances
const instance = getCurrentInstance();
const $validator = instance?.proxy.$validator;

// Data properties
const newTeam = ref<any>({
  name: undefined,
  team_leader_id: undefined,
});

// Watchers
watch(
  () => props.visible,
  (newProp, oldProp) => {
    if (newProp && !oldProp) {
      // Initialize all fields
      newTeam.value = setObject(newTeam.value, undefined);

      // Load users
      emits("get-users");
    }
  }
);

// Component methods
const handleStoreTeam = (): void => {
  $validator?.validateAll().then((result) => {
    if (result) {
      emits("store-team", newTeam.value);
      emits("close");
    }
  });
};
</script>
