<template>
  <a-modal
    :visible="visible"
    :destroy-on-close="true"
    title="Edit Subproject"
    @ok="() => handleUpdateSubproject()"
    @cancel="() => closeModal()"
  >
    <a-form data-vv-scope="updatedSubproject" layout="horizontal">
      <SkInput
        v-model="updatedSubproject.name"
        v-validate="'required|max:255'"
        :error="$validator.errors.first('updatedSubproject.name')"
        label="Name"
        placeholder="Subproject name"
        data-vv-name="name"
      />
    </a-form>

    <a-form-item
      :label-col="{ span: 8 }"
      :wrapper-col="{ span: 16 }"
      :validate-status="$validator.errors.first('updatedSubproject.start_date') ? 'error' : ''"
      :error="$validator.errors.first('updatedSubproject.start_date')"
      :help="$validator.errors.first('updatedSubproject.start_date')"
      label="Start date"
    >
      <a-date-picker
        v-validate="'required'"
        :value="parseDate(updatedSubproject.start_date)"
        :disabled-date="disabledDateForStartDate"
        class="w-100"
        allow-clear
        placeholder="Start date"
        data-vv-name="start_date"
        data-vv-as="start date"
        @change="(date) => onDateChange('start_date', date)"
      />
    </a-form-item>

    <a-form-item
      :label-col="{ span: 8 }"
      :wrapper-col="{ span: 16 }"
      :validate-status="$validator.errors.first('updatedSubproject.end_date') ? 'error' : ''"
      :help="$validator.errors.first('updatedSubproject.end_date')"
      label="End date"
    >
      <a-date-picker
        v-validate="'required'"
        :value="parseDate(updatedSubproject.end_date)"
        :disabled-date="disabledDateForEndDate"
        class="w-100"
        allow-clear
        placeholder="End date"
        data-vv-name="end_date"
        data-vv-as="end date"
        @change="(date) => onDateChange('end_date', date)"
      />
    </a-form-item>

    <a-form-item :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }" label="Description">
      <a-textarea
        v-model="updatedSubproject.description"
        :auto-size="{ minRows: 3 }"
        placeholder="Add a description"
      />
    </a-form-item>

    <SkInput
      v-model="updatedSubproject.link"
      :error="$validator.errors.first('step-info.link')"
      data-vv-name="link"
      label="URL"
      placeholder="Add a link"
    />
  </a-modal>
</template>

<script setup lang="ts">
import { ref, watch, getCurrentInstance } from "vue";
import { ProjectInterface, NewSubprojectInterface } from "../../projects/types";
import moment from "@/date";
import useMixin from "@/useMixin";
import { Moment } from "moment";
import useFilters from "@/useFilters";

// Props
const props = defineProps({
  project: { type: Object as () => ProjectInterface, default: undefined },
  subproject: { type: Object as () => NewSubprojectInterface, default: undefined },
  visible: { type: Boolean, default: false },
});

// Emits
const emits = defineEmits(["update-subproject", "close"]);

// Mixins
const { setObject } = useMixin();

// Filters
const { parseDate } = useFilters();

// Data Properties
const updatedSubproject = ref<any>({
  id: null,
  project: null,
  name: null,
  start_date: moment().format("YYYY-MM-DD"),
  end_date: undefined,
  description: undefined,
  link: undefined,
});

// Watchers
watch(
  () => props.visible,
  (newProp, oldProp) => {
    if (newProp && !oldProp) {
      updatedSubproject.value = setObject(updatedSubproject.value, props.subproject);
      updatedSubproject.value.name = props.subproject.name;
      updatedSubproject.value.start_date = props.subproject.start_date;
      updatedSubproject.value.end_date = props.subproject.end_date;
      updatedSubproject.value.description = props.subproject.description;
      updatedSubproject.value.link = props.subproject.link;
    }
  }
);

// Class Methods
const onDateChange = (dateName: string, date: Moment): void => {
  updatedSubproject.value[dateName] = date ? date.format("YYYY-MM-DD") : null;
};

const disabledDateForStartDate = (current: Moment): boolean => {
  return Boolean(
    current &&
      updatedSubproject.value.end_date &&
      current > moment(updatedSubproject.value.end_date).endOf("day")
  );
};

const disabledDateForEndDate = (current: Moment): boolean => {
  return Boolean(
    current &&
      updatedSubproject.value.start_date &&
      current < moment(updatedSubproject.value.start_date).startOf("day")
  );
};

const closeModal = (): void => {
  emits("close");
};

const instance = getCurrentInstance();
const $validator = instance?.proxy?.$validator;
const handleUpdateSubproject = (): void => {
  $validator?.validateAll("updatedSubproject").then((result) => {
    // If last step and validation pass...
    if (result) {
      emits("update-subproject", updatedSubproject.value);
      emits("close");
    }
  });
};
</script>
