import { TableColumnInterface } from "@/common/types";

const nameColumn: TableColumnInterface = {
  title: "Name",
  dataIndex: "name",
  key: "name",
  width: 420,
  sorter: true,
  scopedSlots: { customRender: "name" },
};

const lastModifiedColumn: TableColumnInterface = {
  title: "Last Modified",
  dataIndex: "updated_at",
  key: "updated_at",
  align: "center",
  sorter: true,
  scopedSlots: { customRender: "last_modified" },
};

const endDateColumn: TableColumnInterface = {
  title: "End Date",
  dataIndex: "completion_date",
  key: "completion_date",
  align: "center",
  sorter: true,
};

const statusColumn: TableColumnInterface = {
  title: "Status",
  dataIndex: "status",
  key: "status",
  align: "center",
  sorter: false,
  scopedSlots: { customRender: "status" },
};

const pendingColumn: TableColumnInterface = {
  title: "Pending",
  dataIndex: "pending",
  key: "pending",
  sorter: true,
  align: "center",
  scopedSlots: { customRender: "pending" },
};

const invoicedColumn: TableColumnInterface = {
  title: "Invoiced",
  dataIndex: "invoiced",
  key: "invoiced",
  sorter: true,
  align: "center",
  scopedSlots: { customRender: "invoiced" },
};

const editColumn: TableColumnInterface = {
  title: "Edit",
  key: "edit",
  align: "center",
  scopedSlots: { customRender: "edit" },
};

export const projectsColumns: Array<TableColumnInterface> = [
  nameColumn,
  lastModifiedColumn,
  endDateColumn,
  statusColumn,
  pendingColumn,
  invoicedColumn,
  editColumn,
];

export const myProjectsColumns: Array<TableColumnInterface> = [
  nameColumn,
  lastModifiedColumn,
  endDateColumn,
  statusColumn,
  pendingColumn,
  invoicedColumn,
  editColumn,
];

export const clientProjectsColumns: Array<TableColumnInterface> = [
  nameColumn,
  lastModifiedColumn,
  endDateColumn,
  statusColumn,
  pendingColumn,
  invoicedColumn,
  editColumn,
];

export const subprojectsColumns: Array<TableColumnInterface> = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    scopedSlots: { customRender: "name" },
  },
  {
    title: "Start date",
    dataIndex: "start_date",
    key: "start_date",
    scopedSlots: { customRender: "start_date" },
  },
  {
    title: "End date",
    dataIndex: "end_date",
    key: "end_date",
    scopedSlots: { customRender: "end_date" },
  },
  {
    title: "Actions",
    key: "actions",
    scopedSlots: { customRender: "actions" },
  },
];
