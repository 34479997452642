<template>
  <a-modal
    :visible="visible"
    :destroy-on-close="true"
    title="Create a new Cost Center"
    @cancel="$emit('close')"
    @ok="handleStoreCostCenter"
  >
    <a-form>
      <SkInput
        v-model="newCostCenter.name"
        v-validate="'required|max:255'"
        :error="errors.first('name')"
        data-vv-name="name"
        label="Name"
        placeholder="Cost center name"
      />

      <SkSelect
        v-model="newCostCenter.manager_id"
        v-validate="'required'"
        :options="users"
        :error="errors.first('manager')"
        data-vv-name="manager"
        label="Manager"
        placeholder="Select a manager"
        display-prop="name"
      />

      <SkTextarea
        v-model="newCostCenter.description"
        v-validate="'required'"
        :error="errors.first('description')"
        data-vv-name="description"
        label="Description"
      />

      <SkInput
        v-model="newCostCenter.fortnox_cost_center_id"
        v-validate="'alpha_num|max:255'"
        :error="errors.first('fortnox_cost_center_id')"
        data-vv-name="fortnox_cost_center_id"
        data-vv-as="Fortnox ID"
        label="Fortnox ID"
        placeholder="Fortnox ID"
      />

      <SkCheckbox v-model="newCostCenter.billable" label="Cost center is billable" />
    </a-form>
  </a-modal>
</template>

<script setup lang="ts">
import { ref, watch, getCurrentInstance } from "vue";
import { MinimalUserInterface } from "@/modules/users/types";
import useMixin from "@/useMixin";

// Props
const props = defineProps({
  users: { type: Array as () => Array<MinimalUserInterface>, default: () => [] },
  visible: { type: Boolean, default: false },
});

// Emits
const emits = defineEmits(["store-cost-center", "close"]);

// Mixins
const { setObject } = useMixin();

// Data properties
const newCostCenter = ref<any>({
  name: undefined,
  manager_id: undefined,
  description: undefined,
  fortnox_cost_center_id: undefined,
  billable: false,
});

// Watchers
watch(
  () => props.visible,
  (newProp: boolean, oldProp: boolean) => {
    if (newProp && !oldProp) {
      newCostCenter.value = setObject(newCostCenter.value, undefined);
    }
  }
);

// Class methods
const instance = getCurrentInstance();
const $validator = instance?.proxy.$validator;
const handleStoreCostCenter = (): void => {
  $validator?.validateAll().then((result) => {
    if (result) {
      emits("store-cost-center", newCostCenter.value);
      emits("close");
    }
  });
};
</script>
