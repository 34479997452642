import { TableColumnInterface } from "@/common/types";

const codeColumn: TableColumnInterface = {
  title: "Code",
  dataIndex: "id",
  key: "code",
  scopedSlots: { customRender: "code" },
};

const projectColumn: TableColumnInterface = {
  title: "Project",
  dataIndex: "project",
  key: "project",
  scopedSlots: { customRender: "project" },
};

const dateColumn: TableColumnInterface = {
  title: "Date",
  dataIndex: "date",
  key: "date",
  sorter: true,
};

const statusColumn: TableColumnInterface = {
  title: "Status",
  dataIndex: "status",
  key: "status",
  scopedSlots: { customRender: "status" },
};

const hoursColumn: TableColumnInterface = {
  title: "Hours",
  dataIndex: "total_reports_hours",
  key: "hours",
  scopedSlots: { customRender: "hours" },
};

const valueColumn: TableColumnInterface = {
  title: "Value",
  dataIndex: "total_reports_amount",
  key: "value",
  scopedSlots: { customRender: "value" },
};

const generatedByColumn: TableColumnInterface = {
  title: "Generated By",
  dataIndex: "user.name",
  key: "user",
  scopedSlots: { customRender: "user" },
};

const actionsColumn: TableColumnInterface = {
  title: "Actions",
  key: "actions",
  scopedSlots: { customRender: "actions" },
};

export const invoicesColumns: Array<TableColumnInterface> = [
  codeColumn,
  projectColumn,
  dateColumn,
  statusColumn,
  hoursColumn,
  valueColumn,
];

export const projectInvoicesColumns: Array<TableColumnInterface> = [
  codeColumn,
  dateColumn,
  generatedByColumn,
  statusColumn,
  actionsColumn,
];

export const invoiceRequestItemsTableColumns: Array<TableColumnInterface> = [
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    scopedSlots: { customRender: "description" },
  },
  {
    title: "Quantity",
    dataIndex: "quantity",
    key: "quantity",
    width: 150,
  },
  {
    title: "Unit Cost",
    dataIndex: "unit_cost",
    key: "unit_cost",
    width: 150,
    scopedSlots: { customRender: "unitCost" },
  },
  {
    title: "Total Cost",
    key: "total_cost",
    width: 150,
    scopedSlots: { customRender: "totalCost" },
  },
  {
    title: "",
    key: "action",
    width: 50,
    scopedSlots: { customRender: "actions" },
  },
];
