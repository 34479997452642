import { RouteInterface } from "@/common/types";
import Quiz from "./_views/Quiz.vue";

export default [
  {
    path: "quiz",
    component: Quiz,
    name: "quiz",
    meta: { auth: true },
  },
] as Array<RouteInterface>;
