import { TableColumnInterface } from "@/common/types";

export const scheduleColumns: Array<TableColumnInterface> = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    sorter: true,
    scopedSlots: { customRender: "scheduleName" },
  },
  {
    title: "Main role",
    dataIndex: "main_role",
    key: "main_role",
    scopedSlots: { customRender: "scheduleMainRole" },
  },
  {
    title: "Utilisation",
    dataIndex: "utilisation",
    key: "utilisation",
    scopedSlots: { customRender: "scheduleUtilisation" },
  },
  {
    title: "Expected workload",
    dataIndex: "expected_workload",
    key: "expected_workload",
    scopedSlots: { customRender: "scheduleExpectedWorkload" },
  },
  {
    title: "Main role end date",
    key: "main_role_end_date",
    sorter: true,
    scopedSlots: { customRender: "scheduleMainRoleEndDate" },
  },
  {
    title: "Extension status",
    key: "extension_status",
    scopedSlots: { customRender: "scheduleExtensionStatus" },
  },
];

export const vacationReportColumns: Array<TableColumnInterface> = [
  {
    title: "Start date",
    dataIndex: "start_date",
    key: "start_date",
  },
  {
    title: "End date",
    dataIndex: "end_date",
    key: "end_date",
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    scopedSlots: { customRender: "vacationType" },
  },
];

export const roleExtensionColumns: Array<TableColumnInterface> = [
  {
    title: "Project",
    key: "project",
    scopedSlots: { customRender: "roleExtensionProject" },
  },
  {
    title: "Role Title",
    key: "role_title",
    scopedSlots: { customRender: "roleExtensionRoleTitle" },
  },
  {
    title: "Created By",
    dataIndex: "created_by_user",
    key: "created_by_user",
    scopedSlots: { customRender: "roleExtensionCreatedByUser" },
  },
  {
    title: "PipeDrive ID",
    dataIndex: "pipedrive_id",
    key: "pipedrive_id",
    scopedSlots: { customRender: "roleExtensionPipeDriveId" },
  },
  {
    title: "Status",
    key: "status",
    scopedSlots: { customRender: "roleExtensionStatus" },
  },
];
