<template>
  <div v-show="visible">
    <h3>Summary</h3>
    <div v-if="allStepsCompleted()">
      <p>You've made it to the end of the onboarding process! 🎉</p>
      <p>Click 'Finish' to officially complete your Skynet onboarding.</p>
      <p>
        If there is anything that you feel could have been better during this onboarding process,
        please make sure to let us know. You could even create your first Linear ticket!
      </p>
    </div>
    <div v-else>
      <p>Unfortunately it seems like some steps are still incomplete.</p>
      <p>Please go back and complete them.</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, watch } from "vue";
import { StepInformation } from "../_utils/types";

// Props
const props = defineProps({
  visible: { type: Boolean, default: false },
  stepsInformation: { type: Array as () => Array<StepInformation>, default: () => [] },
});

// Emits
const emits = defineEmits(["update"]);

// Lifecycle hooks
onMounted(() => {
  emits("update");
});

// Watchers
watch(
  () => props.visible,
  (visible) => {
    if (visible) {
      emits("update");
    }
  }
);

// Methods
const allStepsCompleted = (): boolean => {
  return props.stepsInformation.every((info: StepInformation) => info.completed);
};
</script>
