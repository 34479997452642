<template>
  <div class="card">
    <div class="d-flex justify-content-between w-100 mb-2">
      <h2>Budgets</h2>

      <!-- CREATE BUDGET SHORTCUT ICON -->
      <div
        class="action-default d-flex justify-content-end align-items-baseline"
        @click="showAddBudgetModal"
      >
        <a-icon type="plus" class="pr-1" />
        <span>Create Budget</span>
      </div>
    </div>

    <div class="mb-4">
      <a-list
        v-if="costCentersReport.length > 0"
        :data-source="costCentersReport"
        size="small"
        bordered
      >
        <template #renderItem="item">
          <a-list-item>
            {{ `${item.cost_center_name}: ${item.amount}` }}
          </a-list-item>
        </template>
        <template #header>
          <div>Project Cost Centers Report</div>
        </template>
      </a-list>
    </div>

    <!-- BUDGET TABLE -->
    <a-table
      :columns="budgetsColumns"
      :data-source="budgets"
      :row-key="'id'"
      :scroll="{ x: 1000 }"
      :loading="loadingTable"
      :pagination="false"
      :expand-row-by-click="true"
    >
      <template #name="name, b">
        <span>
          <a-icon v-if="!!b.recurring" type="reload" />
          {{ name }}
        </span>
      </template>

      <template #interval="interval, b">
        <p>{{ `${b.start_date} - ${b.end_date}` }}</p>
      </template>

      <template #amount="amount, b">
        <p v-if="b.type === 'money-budget'">{{ toMoney(b.amount) }}</p>
        <p v-if="b.type === 'hourly-budget'">{{ `${b.amount} hours` }}</p>
      </template>

      <template #progress="progress, b">
        <a-tooltip v-if="b.type === 'money-budget'" placement="top">
          <template #title>{{ toMoney(b.total_spent_amount) }}</template>
          <a-progress
            v-if="b.total_spent_amount.value <= b.amount.value"
            :percent="(b.total_spent_amount.value / b.amount.value) * 100"
            :show-info="false"
          />
          <a-progress
            v-if="b.total_spent_amount.value > b.amount.value"
            :percent="100"
            :show-info="false"
            status="exception"
          />
        </a-tooltip>

        <a-tooltip v-if="b.type === 'hourly-budget'" placement="top">
          <template #title>{{ `${b.total_spent_amount} hours` }}</template>
          <a-progress
            v-if="b.total_spent_amount <= b.amount"
            :percent="(b.total_spent_amount / b.amount) * 100"
            :show-info="false"
          />
          <a-progress
            v-if="b.total_spent_amount > b.amount"
            :percent="100"
            :show-info="false"
            status="exception"
          />
        </a-tooltip>
      </template>

      <template #expandedRowRender="v_slot_budget">
        <div class="m-0">
          <a-list
            v-if="v_slot_budget.roles.length > 0"
            :data-source="v_slot_budget.roles"
            size="small"
            bordered
          >
            <template #renderItem="item">
              <a-list-item>
                {{ `${item.title}: ${item.user.name}` }}
              </a-list-item>
            </template>
            <template #header>
              <div>Roles</div>
            </template>
          </a-list>
          <p v-else>No role is associated with this budget!</p>
          <div class="mt-2">
            <a-list
              v-if="v_slot_budget.cost_centers_report.length > 0"
              :data-source="v_slot_budget.cost_centers_report"
              size="small"
              bordered
            >
              <template #renderItem="item">
                <div v-if="v_slot_budget.type === 'hourly-budget'">
                  <a-list-item>{{ `${item.cost_center_name}: ${item.amount}` }}</a-list-item>
                </div>
                <div v-else>
                  <a-list-item>
                    {{
                      `${item.cost_center_name}: ${item.amount / 100} ${
                        v_slot_budget.amount.currency_code
                      }`
                    }}
                  </a-list-item>
                </div>
              </template>
              <template #header>
                <div>Budget Cost Centers Report</div>
              </template>
              <template #footer>
                <div v-if="v_slot_budget.type === 'hourly-budget'">
                  {{ `Total Spent: ${v_slot_budget.total_spent_amount} hours` }}
                </div>
                <div v-if="v_slot_budget.type === 'money-budget'">
                  {{ `Total Expenditure: ${toMoney(v_slot_budget.total_spent_amount)}` }}
                </div>
              </template>
            </a-list>
            <p v-else>No report is associated with this budget!</p>
          </div>
        </div>
      </template>

      <template #actions="text, record">
        <span class="d-flex align-items-center">
          <a-icon type="delete" class="action-danger" @click="handleDeleteBudget(record.id)" />
          <a-divider type="vertical" />
          <a-icon type="edit" class="action-danger" @click="showEditBudgetModal(record)" />
        </span>
      </template>
    </a-table>

    <a-pagination
      :current="currentPage"
      :total="budgetsPagination ? budgetsPagination.total : 1"
      :show-size-changer="true"
      :page-size="pageSize"
      :page-size-options="['10', '25', '50', '100']"
      size="small"
      class="d-flex justify-content-end mt-2"
      @change="(page, pageSize) => handleGetBudgetsPage(page, pageSize)"
      @showSizeChange="(pageNumber, newPageSize) => handleGetBudgetsPage(pageNumber, newPageSize)"
    />

    <!-- ADD BUDGET MODAL  -->
    <add-budget-modal
      :visible="addBudgetModalVisible"
      :current-user="currentUser"
      :roles="projectRoles"
      :project="project"
      @store-budget="handleStoreBudget"
      @close="addBudgetModalVisible = false"
    />

    <!-- EDIT BUDGET MODAL  -->
    <edit-budget-modal
      :visible="editBudgetModalVisible"
      :current-user="currentUser"
      :roles="project.roles"
      :project="project"
      :budget="budget"
      @update-budget="handleUpdateBudget"
      @close="editBudgetModalVisible = false"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, onBeforeMount, getCurrentInstance } from "vue";
import { BudgetInterface, BudgetPayloadInterface } from "@/modules/budgets/types";
import { ProjectInterface, CostCenterItemInterface } from "../../projects/types";
import EditBudgetModal from "@/modules/budgets/_components/EditBudgetModal.vue";
import AddBudgetModal from "@/modules/budgets/_components/AddBudgetModal.vue";
import { apiGetProjectRoles } from "@/modules/projects/_utils/api";
import { ProfileInterface } from "@/modules/authentication/types";
import { budgetsColumns } from "@/modules/budgets/_utils/table";
import { PaginationInterface } from "../../../common/types";
import { RoleInterface } from "@/modules/roles/types";
import { AxiosResponse } from "axios";
import useMixin from "@/useMixin";

// Props
const props = defineProps({
  budgets: { type: Array as () => Array<BudgetInterface>, default: () => [] },
  currentUser: { type: Object as () => ProfileInterface, default: undefined },
  project: { type: Object as () => ProjectInterface, default: undefined },
  budgetsPagination: { type: Object as () => PaginationInterface, default: undefined },
  costCentersReport: { type: Array as () => Array<CostCenterItemInterface>, default: undefined },
  loadingTable: { type: Boolean, default: false },
  currentPage: { type: Number, default: 1 },
  pageSize: { type: Number, default: 25 },
});

// Emits
const emits = defineEmits(["store-budget", "update-budget", "get-budgets-page", "destroy-budget"]);

// Mixins
const { toMoney } = useMixin();

// Instances
const instance = getCurrentInstance();
const $confirm = instance?.proxy.$confirm;

// Data properties
const addBudgetModalVisible = ref<boolean>(false);
const editBudgetModalVisible = ref<boolean>(false);
const deleteBudgetModalVisible = ref<boolean>(false);
const budget = ref<BudgetInterface | undefined>(undefined);
const projectRoles = ref<Array<RoleInterface>>([]);

// Hooks
onBeforeMount(() => {
  apiGetProjectRoles(props.project.id).then(
    (res: AxiosResponse<{ data: Array<RoleInterface> }>) => {
      projectRoles.value = res.data.data;
    }
  );
});

// Component Methods
const showAddBudgetModal = () => {
  addBudgetModalVisible.value = true;
};

const showEditBudgetModal = (b: BudgetInterface): void => {
  budget.value = b;
  editBudgetModalVisible.value = true;
};

const showDeleteBudgetModal = (budget: BudgetInterface): void => {
  budget = budget;
  deleteBudgetModalVisible.value = true;
};

const handleStoreBudget = (budget: BudgetPayloadInterface): void => {
  emits("store-budget", budget);
};

const handleDeleteBudget = (budgetId: number): void => {
  const mod = $confirm?.({
    title: "Are you sure you want to delete this Budget?",
    okText: "Yes",
    okType: "danger",
    cancelText: "No",
    onOk() {
      emits("destroy-budget", budgetId);
      mod?.destroy();
    },
  });
};

const handleUpdateBudget = (updatedBudget: BudgetPayloadInterface): void => {
  emits("update-budget", updatedBudget);
};

const handleGetBudgetsPage = (page: number, pageSize: number): void => {
  emits("get-budgets-page", { page, pageSize });
};
</script>
