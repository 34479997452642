<template>
  <a-modal
    :visible="visible"
    :destroy-on-close="true"
    title="Edit Invoice"
    ok-text="Update"
    @ok="() => handleUpdateInvoice()"
    @cancel="$emit('close')"
  >
    <a-form layout="horizontal">
      <a-form-item
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
        :validate-status="$validator.errors.first('date') ? 'error' : ''"
        :help="$validator.errors.first('date')"
        label="Invoice date"
      >
        <a-date-picker
          v-validate="'required'"
          :value="parseDate(updatedInvoice.date)"
          class="w-100"
          data-vv-name="date"
          @change="(date) => onDatesChange(date, 'date')"
        />
      </a-form-item>

      <a-form-item :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }" label="Status">
        <a-select v-model="updatedInvoice.status" placeholder="Select a status">
          <a-select-option value="requested">Requested</a-select-option>
          <a-select-option value="approved">Approved</a-select-option>
          <a-select-option value="rejected">Rejected</a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }" label="Comments">
        <a-textarea v-model="updatedInvoice.comment" :auto-size="{ minRows: 3 }" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script setup lang="ts">
import { ref, watch, toRef, getCurrentInstance } from "vue";
import useMixin from "@/useMixin";
import useFilters from "@/useFilters";

// Props
const props = defineProps({
  visible: { type: Boolean, default: false },
  invoice: { type: Object, default: undefined },
});

// Emits
const emits = defineEmits(["update-invoice", "close"]);

// Mixins
const { setObject } = useMixin();

// Filters
const { parseDate } = useFilters();

// Instances
const instance = getCurrentInstance();
const $validator = instance?.proxy.$validator;

// Data properties
const updatedInvoice = ref<any>({
  date: undefined,
  comment: undefined,
  status: undefined,
  type: undefined,
});

// Watchers
watch(toRef(props, "visible"), (newProp, oldProp) => {
  if (newProp && !oldProp) {
    updatedInvoice.value = setObject(updatedInvoice.value, props.invoice);
    updatedInvoice.value.type = "RequestedInvoice";
  }
});

// Component methods
const onDatesChange = (date: any, attribute: string): void => {
  updatedInvoice.value[attribute] = date ? date.format("YYYY-MM-DD") : undefined;
};

const handleUpdateInvoice = (): void => {
  $validator?.validateAll().then((result) => {
    if (result) {
      emits("update-invoice", updatedInvoice.value);
      emits("close");
    }
  });
};
</script>
