import Users from "./_views/Users.vue";
import User from "./_views/User.vue";
import { RouteInterface } from "@/common/types";

export default [
  {
    path: "users/:id",
    component: User,
    name: "user",
    meta: { auth: true },
  },
  {
    path: "users",
    component: Users,
    name: "users",
    meta: { auth: true },
  },
] as Array<RouteInterface>;
