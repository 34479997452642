import axios from "@/network";
import { AxiosPromise } from "axios";

export function authenticate(
  code: string,
  clientId: string,
  redirectUri: string
): AxiosPromise<{ token: string }> {
  const url = `/auth/google`;
  const payload = {
    code,
    clientId,
    redirectUri,
  };
  return axios.post<{ token: string }>(url, payload);
}

export function getProfile(profileId: number) {
  const url = `/users/${profileId}`;
  return axios.get(url);
}
