<template>
  <div v-if="invoicesPage">
    <div class="d-flex justify-content-between w-100 mb-2">
      <h2>{{ title }}</h2>
      <div class="d-flex">
        <portal-target class="mr-2" name="invoices-filter-buttons"></portal-target>

        <div v-if="projectId" class="d-flex justify-content-end align-items-baseline">
          <div
            v-if="acl.isFinancial"
            class="pr-1 ml-2 action-default"
            @click="navigateToInvoiceBuilder"
          >
            <a-icon type="file-text" class="pr-1" />New Invoice
          </div>
          <div
            v-if="acl.isAdmin || acl.isCr || acl.isFinancial"
            class="pr-1 ml-2 action-default"
            @click="navigateToInvoiceRequester"
          >
            <a-icon type="file-exclamation" class="pr-1" />Request Invoice
          </div>
        </div>

        <a-button v-else class="ml-2" icon="plus" type="primary" @click="openAddInvoiceModal" />
      </div>
    </div>

    <invoices-filter
      v-if="allowFiltering"
      :profile="profile"
      :number-of-results="invoicesPagination?.total"
      :filters="{ ...filtersRef, ...invoicesFilter }"
      :unchangeable-filter="invoicesFilter"
      @apply-filters="applyFilters"
    />

    <div :class="allowFiltering ? 'card' : ''">
      <invoices-table
        :invoices="invoicesPage"
        :loading-table="loadingTable"
        :current-page="currentPage"
        :page-size="pageSizeRef"
        :invoices-pagination="invoicesPagination"
        :profile="profile"
        :invoices-columns="invoicesColumns"
        @get-invoices-page="getInvoicesPage"
        @destroy-invoice="handleDestroyInvoice"
        @apply-sorting="applySorting"
      />
    </div>

    <add-invoice-modal
      :visible="addInvoiceModalVisible"
      @navigate-to-invoice="navigateToInvoice"
      @close="addInvoiceModalVisible = false"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, onBeforeMount, computed, getCurrentInstance } from "vue";
import { BaseAclInterface, ProfileInterface } from "@/modules/authentication/types";
import { PaginationInterface, TableColumnInterface } from "@/common/types";
import { useBookmarkedFiltersStore } from "@/modules/bookmarked_filters/_store";
import { useAuthenticationStore } from "@/modules/authentication/_store";
import { invoicesColumns as defaultInvoicesColumns } from "@/modules/invoices/_utils/tables";
import { InvoiceInterface, InvoicesFilterInterface } from "../types";
import useMixin from "@/useMixin";
import { useInvoicesStore } from "../_store";
import InvoicesTable from "./InvoicesTable.vue";
import InvoicesFilter from "./InvoicesFilter.vue";
import AddInvoiceModal from "./AddInvoiceModal.vue";

// Props
const props = defineProps({
  acl: { type: Object as () => BaseAclInterface, default: undefined },
  allowFiltering: { type: Boolean, default: false },
  title: { type: String, default: "Invoices" },
  projectId: { type: Number, default: undefined },
  invoicesColumns: {
    type: Array as () => Array<TableColumnInterface>,
    default: () => defaultInvoicesColumns,
  },
  invoicesFilter: { type: Object as () => InvoicesFilterInterface, default: undefined },
});

// Pinia
const authenticationStore = useAuthenticationStore();
const bookmarkedFiltersStore = useBookmarkedFiltersStore();
const invoicesStore = useInvoicesStore();
const invoicesPagination = computed<PaginationInterface | undefined>(
  () => invoicesStore.invoicesPagination
);
const profile = computed<ProfileInterface | undefined>(() => authenticationStore.profile);

// Emits
const emits = defineEmits(["destroy-invoice"]);

// Mixins
const { loadTablePageSize, qsDecode, syncRouteParams, persistTablePageSize } = useMixin();

// Instances
const instance = getCurrentInstance();
const $route = instance?.proxy?.$route;
const $router = instance?.proxy?.$router;

// Data properties
const invoicesPage = ref<Array<InvoiceInterface>>([]);
const loadingTable = ref<boolean>(false);
const currentPage = ref<number>(1);
const pageSizeRef = ref<number>(25);
const filtersRef = ref<InvoicesFilterInterface>({});
const sortingRef = ref<object>({});
const addInvoiceModalVisible = ref<boolean>(false);

// Life-cycle hooks
onBeforeMount(() => {
  bookmarkedFiltersStore.getUserBookmarkedFilters(profile.value?.id as number);
  getInvoicesPagefromURL();
});

// Component methods
const getInvoicesPagefromURL = (url: any = $route?.query): void => {
  const { page, filters, sorting, pageSize } = qsDecode(url);

  pageSizeRef.value = loadTablePageSize();

  if (page) currentPage.value = parseInt(page);
  if (filters) filtersRef.value = filters;
  if (sorting) sortingRef.value = sorting;
  if (pageSize) pageSizeRef.value = parseInt(pageSize);

  refreshInvoices();
};

const getInvoicesPage = ({
  page,
  pageSize = pageSizeRef.value,
  filters = filtersRef.value,
  sorting = sortingRef.value,
}: {
  page: any;
  pageSize?: number;
  filters?: InvoicesFilterInterface;
  sorting?: object;
}): void => {
  loadingTable.value = true;
  currentPage.value = page;
  pageSizeRef.value = pageSize;

  if (props.allowFiltering) {
    syncRouteParams(
      {
        page: currentPage.value,
        pageSize: pageSizeRef.value,
        filters: filtersRef.value,
        sorting: sortingRef.value,
      },
      instance
    );
  }
  persistTablePageSize(pageSize);

  const params = {
    page,
    page_size: pageSize,
    ...{ ...filters, ...props.invoicesFilter },
    ...sorting,
  };

  if (props.projectId === undefined) {
    invoicesStore.getInvoicesByPage(params).then((results: any) => {
      loadingTable.value = false;
      invoicesPage.value = results;
    });
  } else {
    invoicesStore.getProjectInvoicesByPage(props.projectId, params).then((results: any) => {
      loadingTable.value = false;
      invoicesPage.value = results;
    });
  }
};

const applyFilters = (filters: InvoicesFilterInterface): void => {
  filtersRef.value = filters;
  currentPage.value = 1;
  syncRouteParams(
    {
      page: currentPage.value,
      pageSize: pageSizeRef.value,
      filters: filtersRef.value,
      sorting: sortingRef.value,
    },
    instance
  );
  refreshInvoices();
};

const applySorting = (sorting: object): void => {
  sortingRef.value = sorting;

  syncRouteParams(
    {
      page: currentPage.value,
      pageSize: pageSizeRef.value,
      filters: filtersRef.value,
      sorting: sortingRef.value,
    },
    instance
  );
  refreshInvoices();
};

const handleDestroyInvoice = (invoice: any) => {
  emits("destroy-invoice", invoice);
};

const refreshInvoices = (): void => {
  getInvoicesPage({ page: currentPage.value });
};

const openAddInvoiceModal = (): void => {
  // handleGetProjectsCompactList();
  addInvoiceModalVisible.value = true;
};

const navigateToInvoice = (projectId: number): void => {
  $router?.push({
    name: "new-invoice",
    params: { projectId },
  } as any);
};

const navigateToInvoiceBuilder = (): void => {
  $router?.push({
    name: "new-invoice",
    params: { projectId: props.projectId + "" },
  });
};

const navigateToInvoiceRequester = (): void => {
  $router?.push({
    name: "request-invoice",
    params: { projectId: props.projectId + "" },
  });
};

defineExpose({ refreshInvoices, navigateToInvoice, currentPage });
</script>
