<template>
  <div class="card">
    <roles-view
      ref="rolesview"
      title="Project Roles"
      :acl="acl"
      :roles-filter="userRolesFilter"
      :roles-columns="myRolesColumns"
      @role-refreshed="$emit('reload-role-extension-banners')"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, toRefs, watch } from "vue";
import { BaseAclInterface } from "../../authentication/types";
import RolesView from "../../roles/_components/RolesView.vue";
import { myRolesColumns } from "../_utils/tables";
import { RolesFilterInterface } from "../types";

// Props
const props = defineProps({
  acl: { type: Object as () => BaseAclInterface, default: undefined },
  userId: { type: Number, default: undefined },
  tab: { type: String, default: undefined },
});

// Data properties
const { userId } = toRefs(props);
const userRolesFilter = ref<RolesFilterInterface>({ user_id: [userId.value] });
const rolesview = ref<InstanceType<typeof RolesView>>();

// Watchers
watch(
  () => props.tab,
  (newProp, oldProp) => {
    if (newProp === "roles" && oldProp !== "roles") {
      rolesview.value?.refreshRoles();
    }
  }
);
</script>
