<template>
  <a-modal
    :visible="visible"
    :destroy-on-close="true"
    title="Apply Role Extension"
    ok-text="Apply"
    @ok="handleApplyRoleExtension"
    @cancel="$emit('close')"
  >
    <h3 v-if="roleExtension && roleExtension.role">
      The following changes will be applied to
      <strong> {{ roleExtension.role.user.name }} </strong>'s
      <strong>{{ roleExtension.role.title }}</strong> role at
      <strong> {{ roleExtension.role.project.name }} </strong>:
    </h3>

    <br />

    <a-form-item
      v-if="isEndDateModified()"
      :label-col="{ span: 8 }"
      :wrapper-col="{ span: 16 }"
      label="End date"
    >
      <strong>{{ roleExtension.end_date }}</strong>
      (changed from
      <em>
        {{ role.end_date }}, extended
        {{ formatExtensionDuration(role.end_date, roleExtension.end_date) }} </em
      >)
    </a-form-item>

    <a-form-item
      v-if="isHourlyRateModified()"
      :label-col="{ span: 8 }"
      :wrapper-col="{ span: 16 }"
      label="Hourly rate"
    >
      <strong v-if="roleExtension.hourly_rate"
        >{{ roleExtension.hourly_rate.formatted_value }}
        {{ roleExtension.hourly_rate.currency_code }}</strong
      >
      (changed from
      <em
        >{{ role.hourly_rate?.formatted_value }} {{ role.hourly_rate?.currency_code }} and applied
        {{
          roleExtension.future_hourly_rate_date
            ? "from " + roleExtension.future_hourly_rate_date
            : "directly"
        }} </em
      >)
    </a-form-item>

    <a-form-item
      v-if="isWorkloadModified()"
      :label-col="{ span: 8 }"
      :wrapper-col="{ span: 16 }"
      label="Workload"
    >
      <strong>{{ roleExtension.workload * 100 }}%</strong>
      (changed from <em>{{ role.workload * 100 }}%</em>)
    </a-form-item>

    <a-form-item
      v-if="isNoticePeriodModified()"
      :label-col="{ span: 8 }"
      :wrapper-col="{ span: 16 }"
      label="Notice period"
    >
      <strong v-if="roleExtension">{{ roleExtension.notice_period }} days</strong>
      (changed from <em>{{ role.notice_period }} days</em>)
    </a-form-item>
  </a-modal>
</template>

<script setup lang="ts">
import { getCurrentInstance } from "vue";
import { RoleExtensionInterface, RoleInterface } from "@/modules/roles/types";
import { formatDatesDuration } from "@/utils";

// Props
const props = defineProps({
  visible: { type: Boolean, default: false },
  role: { type: Object as () => RoleInterface, default: undefined },
  roleExtension: { type: Object as () => RoleExtensionInterface, default: undefined },
});

// Emits
const emits = defineEmits(["apply-role-extension", "close"]);

// Instances
const instance = getCurrentInstance();
const $validator = instance?.proxy?.$validator;

// Component methods
const handleApplyRoleExtension = (): void => {
  $validator?.errors.clear();
  emits("apply-role-extension", props.roleExtension);
  emits("close");
};

const isEndDateModified = (): boolean => {
  return props.role.end_date != props.roleExtension!.end_date;
};

const isHourlyRateModified = (): boolean => {
  return (
    props.role.hourly_rate?.value != props.roleExtension!.hourly_rate?.value ||
    props.role.hourly_rate?.currency_code != props.roleExtension!.hourly_rate?.currency_code
  );
};

const isWorkloadModified = (): boolean => {
  return props.role.workload != props.roleExtension!.workload;
};

const isNoticePeriodModified = (): boolean => {
  return props.role.notice_period != props.roleExtension!.notice_period;
};

const formatExtensionDuration = (start: string, end: string): string => {
  return formatDatesDuration(start, end);
};
</script>
