import { BookmarkedFilterPayloadInterface } from "../types";
import axios from "@/network";

export function apiGetUserBookmarkedFilters(userId: number) {
  const url = `users/${userId}/bookmarked_filters`;
  return axios.get(url);
}

export function apiStoreUserBookmarkedFilter(
  userId: number,
  newBookmarkedFilter: BookmarkedFilterPayloadInterface
) {
  const url = `users/${userId}/bookmarked_filters`;
  const payload = { bookmarked_filter: newBookmarkedFilter };
  return axios.post(url, payload);
}

export function apiUpdateUserBookmarkedFilter(
  userId: number,
  bookmarkedFilterId: number,
  updatedBookmarkedFilter: BookmarkedFilterPayloadInterface
) {
  const url = `users/${userId}/bookmarked_filters/${bookmarkedFilterId}`;
  const payload = { bookmarked_filter: updatedBookmarkedFilter };
  return axios.patch(url, payload);
}

export function apiDestroyUserBookmarkedFilter(userId: number, bookmarkedFilterId: number) {
  const url = `users/${userId}/bookmarked_filters/${bookmarkedFilterId}`;
  return axios.delete(url);
}
