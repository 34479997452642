import axios from "@/network";
import { JobPostingStatus } from "../types";

export function apiGetJobPostings(status: JobPostingStatus) {
  const url = "/job_postings";
  const params = { status: status };
  return axios.get(url, { params });
}

export function apiGetJobPosting(id: number) {
  return axios.get(`/job_postings/${id}`);
}

export function apiGetJobPostingDetails(jobPostingId: number) {
  const url = `/job_postings/${jobPostingId}/details`;
  return axios.get(url);
}

export function apiPostJobPostingInterest(jobPostingId: number) {
  const url = `/job_postings/${jobPostingId}/interests`;
  return axios.post(url);
}
