import { TableColumnInterface } from "@/common/types";

export const recurringJobsColumns = [
  {
    title: "Title",
    dataIndex: "title",
    key: "title",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    scopedSlots: { customRender: "status" },
  },
  {
    title: "Next execution date",
    key: "dates",
    scopedSlots: { customRender: "dates" },
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    scopedSlots: { customRender: "type" },
  },
  {
    title: "Frequency",
    key: "frequency",
    scopedSlots: { customRender: "frequency" },
  },
  {
    title: "Actions",
    key: "actions",
    scopedSlots: { customRender: "actions" },
  },
] as Array<TableColumnInterface>;
