<template>
  <a-modal
    v-if="role"
    :visible="visible"
    title="Are you sure you want to delete this role and all its associations?"
    ok-text="Yes"
    ok-type="danger"
    cancel-text="No"
    @ok="() => handleDeleteRole(role.project.id, role.id)"
    @cancel="$emit('close')"
  >
    <h3 class="mb-0">Associations:</h3>
    <p v-for="entity in role.associations" :key="entity.name" class="mb-0">
      <strong>{{ entity.name }}:</strong>
      {{ entity.count }}
    </p>
  </a-modal>
</template>

<script setup lang="ts">
import { RoleInterface } from "@/modules/roles/types";

// Props
const props = defineProps({
  visible: { type: Boolean, default: false },
  role: { type: Object as () => RoleInterface, default: undefined },
});

// Emits
const emits = defineEmits(["destroy-role", "close"]);

// Class methods
const handleDeleteRole = (projectId: number, roleId: number): void => {
  emits("destroy-role", projectId, roleId);
  emits("close");
};
</script>
