import moment from "@/date";

/**
 * Returns a moment Object or undefined
 * Main purpose:
 * Ant-components that require a Moment object
 *
 * @param {String|Date|Moment} date
 *
 * @returns {Moment}
 */
export function parseDate(date: string | undefined): any {
  return date ? moment(date) : undefined;
}

/**
 * Returns a formatted Date String
 * Main purpose:
 * To help users read a date
 *
 * @param {String|Date|Moment} date
 *
 * @returns {String} format: "YYYY-MM-DD"
 */
export function toHumanDate(date: string | undefined): any {
  return date ? moment(date).format("YYYY-MM-DD") : undefined;
}

/**
 * Returns, using words, the amount of time elapsed between two dates.
 *
 * Main purpose:
 * To prevent users from having to do the mental substraction themselves.
 *
 * @param {Date} future
 * @param {Date} past
 *
 * @returns {String}
 */
export function durationBetweenDates(future: Date, past: Date): string {
  const diff = Math.floor(future.getTime() - past.getTime()) / 1000;

  if (diff < 0) {
    return durationBetweenDates(past, future);
  }

  function format(amount_in_sec: number, interval: number, word: string): string {
    return `${Math.floor(amount_in_sec / interval)} ${word}${
      amount_in_sec >= 2 * interval ? "s" : ""
    }`;
  }

  const year_in_sec = 31536000;
  if (diff / year_in_sec >= 1) {
    return format(diff, year_in_sec, "year");
  }

  const month_in_sec = 2592000;
  if (diff / month_in_sec >= 1) {
    return format(diff, month_in_sec, "month");
  }

  const day_in_sec = 86400;
  if (diff / day_in_sec >= 1) {
    return format(diff, day_in_sec, "day");
  }

  const hour_in_sec = 3600;
  if (diff / hour_in_sec >= 1) {
    return format(diff, hour_in_sec, "hour");
  }

  const minute_in_sec = 60;
  if (diff / minute_in_sec >= 1) {
    return format(diff, minute_in_sec, "minute");
  }

  return format(diff, 1, "second");
}
