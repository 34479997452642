<template>
  <div v-show="visible">
    <h3>Welcome to Skry</h3>

    <div class="welcome-text">Hello {{ userName }},</div>

    <div class="welcome-text">We are thrilled to have you on board!</div>

    <div class="welcome-text">
      <div class="welcome-section-title">What is this?</div>
      As part of your onboarding process, you will now get familiar with Skynet. You will have to
      fill out information needed to get you integrated into Skry as quickly as possible. This
      mainly concerns our client relations team: in order for them to send out your profile to
      clients, they need a complete and robust CV. Most of the information you will provide in the
      next steps will serve that purpose.
    </div>

    <div class="welcome-text">
      <div class="welcome-section-title">What is Skynet?</div>
      Skynet is our in-house management platform. It is through Skynet that you will be able to
      report your hours, as well as your vacation days, sick days and more. From there, you can also
      see which clients we work with and which projects are ongoing. You can search other Skry
      colleagues and see which assignments they are currently working on. Skynet is also the gateway
      through which you will scan and upload receipts you wish to write off as business expenses.
    </div>

    <div class="welcome-text">
      Once you are done with this onboarding process, we encourage you to wander through the website
      and see the many more things Skynet has to offer.
    </div>

    <div class="welcome-text">
      We will leave you to it now. If at any point you have a question or are unsure about
      something, please do not hesitate to ask someone for help. You could also post your question
      on our Slack.
    </div>

    <div class="welcome-text">
      Let's get started,
      <br />
      Skry
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from "vue";

// Props
const props = defineProps({
  visible: { type: Boolean, default: false },
  userName: { type: String, default: undefined },
});

// Emits
const emits = defineEmits(["update"]);

onMounted(() => {
  emits("update", true);
});
</script>

<style>
.welcome-section-title {
  margin-bottom: 0px;
  font-size: 14px;
  font-style: italic;
  font-weight: 600;
}

.welcome-text {
  margin-top: 20px;
  margin-bottom: 14px;
  font-size: 14px;
}
</style>
