import { QuizTagPayloadInterface } from "../types";
import axios from "@/network";

export function apiGetQuizTags() {
  const url = "/quiz_tags";
  return axios.get(url);
}

export function apiStoreQuizTag(newQuizTag: QuizTagPayloadInterface) {
  const url = "/quiz_tags";
  const payload = { quiz_tag: newQuizTag };
  return axios.post(url, payload);
}

export function apiUpdateQuizTag(updatedQuizTag: QuizTagPayloadInterface) {
  const url = `/quiz_tags/${updatedQuizTag.id}`;
  const payload = { quiz_tag: updatedQuizTag };
  return axios.patch(url, payload);
}

export function apiDestroyQuizTag(quizTagId: number) {
  const url = `/quiz_tags/${quizTagId}`;
  return axios.delete(url);
}
