<template>
  <a-layout-content class="my-4 mx-3">
    <div class="container">
      <!-- HEADER -->
      <div class="d-flex justify-content-between">
        <h2>Reports</h2>
        <div class="d-flex">
          <div v-if="showSubprojectReportDownload">
            <a-button
              :loading="downloadingCsv"
              class="mr-2"
              icon="download"
              type="text"
              @click="() => handleDownloadSubprojectCSV()"
              >Subproject Report</a-button
            >
          </div>
          <a-button
            :loading="downloadingCsv"
            class="mr-2"
            icon="download"
            type="default"
            @click="() => handleDownloadCsv()"
          />
          <portal-target name="reports-filter-buttons"></portal-target>
        </div>
      </div>

      <!-- FILTER -->
      <reports-filter
        :acl="baseAcl"
        :profile="profile"
        :filters="filtersRef"
        :number-of-results="reportsPagination?.total"
        :number-of-vacation-days="reportsPagination?.total_vacation_days"
        @apply-filters="(filters) => applyFilters(filters)"
      />

      <div class="card">
        <reports-table
          :reports-page="reportsPage"
          :current-page="currentPage"
          :user-to-be-edited="user"
          :user-roles="userRoles"
          :loading-table="loadingTable"
          :cost-centers="costCenters"
          :reports-pagination="reportsPagination"
          :page-size="pageSizeRef"
          @apply-sorting="(newSorting) => applySorting(newSorting)"
          @get-reports-page="(params) => getReportsPage(params)"
          @destroy-report="(reportId) => handleDestroyReport(reportId)"
          @update-user-report="(updatedReport) => handleUpdateReport(updatedReport)"
          @update-expense-report="(updatedReport) => handleUpdateReport(updatedReport)"
          @handle-edit-user-report-modal-visible="handleEditUserReportModalVisible"
          @get-cost-centers="() => getCostCenters()"
          @store-recurring-job="handleStoreRecurringJob"
        />
      </div>
    </div>
  </a-layout-content>
</template>

<script setup lang="ts">
import { ref, getCurrentInstance, computed, onBeforeMount } from "vue";
import {
  apiGetReports,
  apiGetSubprojectReports,
  apiDestroyReport,
  apiUpdateReport,
} from "../_utils/api";
import { apiGetUser, apiGetUserRoles } from "@/modules/users/_utils/api";
import { useBookmarkedFiltersStore } from "@/modules/bookmarked_filters/_store";
import { BaseAclInterface } from "@/modules/authentication/types";
import { ProfileInterface } from "@/modules/authentication/types";
import { useAuthenticationStore } from "@/modules/authentication/_store";
import { useRecurringJobsStore } from "@/modules/recurring_jobs/_store";
import ReportsFilter from "../_components/ReportsFilter.vue";
import ReportsTable from "../_components/ReportsTable.vue";
import { PaginationInterface } from "@/common/types";
import { RoleInterface } from "../../roles/types";
import { ReportsFilterInterface } from "../types";
import { downloadCsv } from "@/utils";
import useMixin from "@/useMixin";
import { useCostCentersStore } from "@/modules/cost_centers/_store";
import { CostCenterInterface } from "@/modules/cost_centers/types";

// Pinia
const authenticationStore = useAuthenticationStore();
const costCentersStore = useCostCentersStore();
const recurringJobsStore = useRecurringJobsStore();
const bookmarkedFiltersStore = useBookmarkedFiltersStore();
const profile = computed<ProfileInterface | undefined>(() => authenticationStore.profile);
const baseAcl = computed<BaseAclInterface>(() => authenticationStore.baseAcl);
const costCenters = computed<Array<CostCenterInterface>>(() => costCentersStore.cost_centers);
const getCostCenters = () => costCentersStore.getCostCenters();

// Mixins
const { qsDecode, loadTablePageSize, syncRouteParams, persistTablePageSize } = useMixin();

// Instances
const instance = getCurrentInstance();
const $route = instance?.proxy?.$route;
const $message = instance?.proxy?.$message;

// Data properties
const reportsPagination = ref<PaginationInterface | undefined>(undefined);
const user = ref<ProfileInterface | undefined>(undefined);
const filtersRef = ref<ReportsFilterInterface>({});
const userRoles = ref<Array<RoleInterface>>([]);
const downloadingCsv = ref<boolean>(false);
const loadingTable = ref<boolean>(false);
const reportsPage = ref<Array<any>>([]);
const pageSizeRef = ref<number>(25);
const currentPage = ref<number>(1);
const sortingRef = ref<object>({});
const showSubprojectReportDownload = ref<boolean>(false);

// Lifecycle hooks
onBeforeMount(() => {
  bookmarkedFiltersStore.getUserBookmarkedFilters(profile.value?.id as number);
  getReportsPagefromURL();
});

// Component methods
const setShowSubprojectDowload = (): void => {
  showSubprojectReportDownload.value =
    filtersRef.value.project_id?.length === 1 &&
    filtersRef.value.types?.indexOf("time-report") !== -1 &&
    filtersRef.value.types?.length === 1;
};

const getReportsPagefromURL = (url: any = $route?.query): void => {
  const { page, filters, sorting, pageSize } = qsDecode(url);

  pageSizeRef.value = loadTablePageSize();

  if (page) currentPage.value = parseInt(page);
  if (filters) filtersRef.value = filters;
  if (sorting) sortingRef.value = sorting;
  if (pageSize) pageSizeRef.value = parseInt(pageSize);
  getReportsPage({ page: currentPage.value });
};

const getReportsPage = ({
  page,
  pageSize = pageSizeRef.value,
  filters = filtersRef.value,
  sorting = sortingRef.value,
}: {
  page: number;
  pageSize?: number;
  filters?: object;
  sorting?: object;
}) => {
  loadingTable.value = true;
  currentPage.value = page;
  pageSizeRef.value = pageSize;
  const vacation_summary = enableVacationSummary.value || undefined;

  syncRouteParams(
    {
      page: currentPage.value,
      pageSize: pageSizeRef.value,
      filters: filtersRef.value,
      sorting: sortingRef.value,
    },
    instance
  );
  persistTablePageSize(pageSize);

  apiGetReports({ page, page_size: pageSize, ...filters, ...sorting, vacation_summary })
    .then(({ data }: any) => {
      currentPage.value = page;
      reportsPage.value = data.data;
      reportsPagination.value = data.meta;
    })
    .then(() => (loadingTable.value = false));
  setShowSubprojectDowload();
};

const applyFilters = (filters: object): any => {
  filtersRef.value = filters;
  currentPage.value = 1;
  syncRouteParams(
    {
      page: currentPage.value,
      pageSize: pageSizeRef.value,
      filters: filtersRef.value,
      sorting: sortingRef.value,
    },
    instance
  );
  getReportsPage({ page: currentPage.value });
};

const applySorting = (sorting: object): void => {
  sortingRef.value = sorting;
  syncRouteParams(
    {
      page: currentPage.value,
      pageSize: pageSizeRef.value,
      filters: filtersRef.value,
      sorting: sortingRef.value,
    },
    instance
  );
  getReportsPage({ page: currentPage.value });
};

const handleDownloadCsv = (): void => {
  downloadingCsv.value = true;
  apiGetReports(filtersRef.value, { Accept: "text/csv" })
    .then((res: any) => {
      downloadCsv(res.data, "reports", !!Object.keys(filtersRef.value).length);
    })
    .catch((err: any) => $message?.error("Couldn't download CSV file!", 3))
    .finally(() => (downloadingCsv.value = false));
};

const handleDownloadSubprojectCSV = (): void => {
  downloadingCsv.value = true;
  apiGetSubprojectReports(filtersRef.value, { Accept: "text/csv" })
    .then((res: any) => {
      downloadCsv(res.data, "subprojectreports", !!Object.keys(filtersRef.value).length);
    })
    .catch((err: any) => $message?.error("Couldn't download CSV file!", 3))
    .finally(() => (downloadingCsv.value = false));
};

const handleUpdateReport = (updatedReport: any): void => {
  apiUpdateReport(updatedReport.id, updatedReport)
    .then((res: any) => {
      if ("meta" in res.data && res.data.meta?.vacation_split_count > 0) {
        const warningMessage = ref<String>("Report updated successfully!");
        res.data.data.vacation_split_count = res.data.meta.vacation_split_count;
        if (updatedReport.type == "sick-report") {
          warningMessage.value += " An existing vacation report has been modified.";
        } else if (updatedReport.type == "vacation-report") {
          warningMessage.value += " The report has been split into two or more separate reports";
        }
        getReportsPage({ page: currentPage.value });
        $message?.warning(warningMessage.value, 3);
      } else if ("meta" in res.data && "prepaid_days_message" in res.data.meta) {
        res.data.data.prepaid_message = res.data.meta.prepaid_days_message;
        getReportsPage({ page: currentPage.value });
        $message?.success("Report updated successfully!", 3);
      } else {
        getReportsPage({ page: currentPage.value });
        $message?.success("Report updated successfully!", 3);
      }
    })
    .catch(() => $message?.error("Couldn't update report!", 3));
};

const handleDestroyReport = (reportId: number): void => {
  apiDestroyReport(reportId)
    .then(() => getReportsPage({ page: currentPage.value }))
    .then(() => $message?.success("Report deleted successfully!", 3))
    .catch(() => $message?.error("Couldn't delete report!", 3));
};

const handleEditUserReportModalVisible = (report: any): void => {
  apiGetUser(report.user.id).then((res: any) => (user.value = res.data.data));
  apiGetUserRoles(report.user.id).then((res: any) => (userRoles.value = res.data.data));
};

const handleStoreRecurringJob = (recurringJob: any): void => {
  recurringJobsStore
    .storeRecurringJob(recurringJob)
    .then(() => $message?.success("Recurring expense report added successfully!", 3))
    .catch(() => $message?.error("Couldn't create recurring expense report!", 3));
};

const enableVacationSummary = computed(() => {
  const { types, user_id } = filtersRef.value;
  return Array.isArray(types) && types.includes("vacation-report") && user_id?.length === 1;
});
</script>
