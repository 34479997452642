<template>
  <div class="d-flex justify-content-between align-items-center mb-4">
    <!-- PROJECT NAME AND CLIENT -->
    <div>
      <h2 class="m-0">{{ project.name }}</h2>
      <p v-if="project.client">
        <router-link
          :to="{ name: 'client', params: { id: project.client.id } }"
          class="text-uppercase text-grey_dark m-0"
          >{{ project.client.name }}</router-link
        >
      </p>
    </div>

    <!-- ACTIONS -->
    <div>
      <a-dropdown
        v-if="
          acl.isAdmin || acl.isAccountManager || acl.isFinancial || acl.isCr || acl.isProjectManager
        "
        :trigger="['click']"
      >
        <a class="ant-dropdown-link d-flex align-items-center" href="#">
          <span class="d-none d-lg-block mr-1">Actions</span>
          <a-icon type="down" class="d-none d-lg-block" />
          <a-icon type="down-circle-o" class="d-block d-lg-none" />
        </a>

        <template #overlay>
          <a-menu @click="onActionClick">
            <a-menu-item
              v-if="project.status === 'active' && (acl.isAdmin || acl.isFinancial)"
              key="0"
            >
              <a-icon type="clock-circle-o" class="pr-1" />New Expense
            </a-menu-item>
            <a-menu-item key="1"> <a-icon type="user" class="pr-1" />New Role </a-menu-item>
            <a-menu-divider />
            <a-menu-item v-if="project.editable" key="2">
              <a-icon type="edit" class="pr-1" />Edit
            </a-menu-item>
            <a-menu-item v-if="project.deletable" key="3">
              <a-icon type="delete" class="pr-1" />Delete
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
    </div>

    <!-- ADD EXPENSE-REPORT MODAL -->
    <add-expense-report-modal
      :visible="addExpenseReportModalVisible"
      :user="currentUser"
      :cost-centers="costCenters"
      :project="project"
      @get-cost-centers="() => $emit('get-cost-centers')"
      @store-report="(newReport) => handleStoreExpenseReport(newReport)"
      @close="addExpenseReportModalVisible = false"
    />

    <!-- ADD PROJECT ROLE MODAL -->
    <add-project-role-modal
      :visible="editProjectRoleModalVisible"
      :acl="acl"
      :users="users"
      :project="project"
      :subprojects="subprojects"
      :skills="skills"
      :cost-centers="costCenters"
      @close="editProjectRoleModalVisible = false"
      @get-users="$emit('get-users')"
      @get-skills="$emit('get-skills')"
      @get-cost-centers="$emit('get-cost-centers')"
      @store-project-role="(newRole) => $emit('store-project-role', newRole)"
      @get-subprojects-page="$emit('get-subprojects-page', project.id)"
    />

    <!-- EDIT PROJECT MODAL -->
    <edit-project-modal
      :users="users"
      :visible="editProjectModalVisible"
      :project="project"
      :clients="clients"
      :acl="acl"
      @close="editProjectModalVisible = false"
      @get-users="$emit('get-users')"
      @get-clients="$emit('get-clients')"
      @update-project="(updatedProject) => $emit('update-project', updatedProject)"
    />

    <!-- DELETE PROJECT MODAL -->
    <a-modal
      :visible="deleteProjectModalVisible"
      title="Are you sure you want to delete this project and all its associations?"
      ok-text="Yes"
      ok-type="danger"
      cancel-text="No"
      @ok="() => handleDeleteProject()"
      @cancel="deleteProjectModalVisible = false"
    >
      <h3 class="mb-0">Associations:</h3>
      <p v-for="entity in project.associations" :key="entity.name" class="mb-0">
        <strong>{{ entity.name }}:</strong>
        {{ entity.count }}
      </p>
    </a-modal>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import AddExpenseReportModal from "../../reports/_components/AddExpenseReportModal.vue";
import { ProfileInterface, BaseAclInterface } from "@/modules/authentication/types";
import AddProjectRoleModal from "../_components/AddProjectRoleModal.vue";
import EditProjectModal from "../_components/EditProjectModal.vue";
import { CostCenterInterface } from "@/modules/cost_centers/types";
import { MinimalClientInterface } from "@/modules/clients/types";
import { MinimalUserInterface } from "@/modules/users/types";
import { ProjectInterface, NewSubprojectInterface } from "@/modules/projects/types";
import { SkillInterface } from "@/modules/skills/types";

// Props
const props = defineProps({
  project: { type: Object as () => ProjectInterface, default: undefined },
  users: { type: Array as () => Array<MinimalUserInterface>, default: () => [] },
  skills: { type: Array as () => Array<SkillInterface>, default: () => [] },
  currentUser: { type: Object as () => ProfileInterface, default: undefined },
  clients: { type: Array as () => Array<MinimalClientInterface>, default: () => [] },
  costCenters: { type: Array as () => Array<CostCenterInterface>, default: () => [] },
  acl: { type: Object as () => BaseAclInterface, default: undefined },
  subprojects: { type: Array as () => Array<NewSubprojectInterface>, default: () => [] },
});

// Emits
const emits = defineEmits(["store-expense-report", "destroy-project"]);

// Data properties
const addExpenseReportModalVisible = ref<boolean>(false);
const editProjectRoleModalVisible = ref<boolean>(false);
const editProjectModalVisible = ref<boolean>(false);
const deleteProjectModalVisible = ref<boolean>(false);

// Class methods
const onActionClick = ({ key }: any): void => {
  switch (key) {
    case "0":
      addExpenseReportModalVisible.value = true;
      break;
    case "1":
      editProjectRoleModalVisible.value = true;
      break;
    case "2":
      editProjectModalVisible.value = true;
      break;
    case "3":
      deleteProjectModalVisible.value = true;
      break;
  }
};

const handleStoreExpenseReport = (newReport: any): void => {
  emits("store-expense-report", newReport);
  addExpenseReportModalVisible.value = false;
};

const handleDeleteProject = (): void => {
  deleteProjectModalVisible.value = false;
  emits("destroy-project", props.project.id);
};
</script>
