<template>
  <a-modal
    :visible="visible"
    :destroy-on-close="true"
    title="Add Repository"
    ok-text="Add"
    @ok="() => handleAddProjectRepository()"
    @cancel="$emit('close')"
  >
    <a-form layout="horizontal">
      <a-select
        show-search
        placeholder="Select a repository"
        option-filter-prop="children"
        :loading="loading"
        @change="(r) => (newRepo.name = r)"
      >
        <a-select-option
          v-for="repo in repositories"
          :key="repo.full_name"
          :value="repo.full_name"
          >{{ repo.full_name }}</a-select-option
        >
      </a-select>
    </a-form>
  </a-modal>
</template>

<script setup lang="ts">
import { ref, watch, toRef } from "vue";
import { MinimalRepositoryInterface } from "../types";

// Props
const props = defineProps({
  repositories: { type: Array as () => Array<{ full_name: string }>, default: () => [] },
  visible: { type: Boolean, default: false },
  loading: { type: Boolean, default: false },
});

// Emits
const emits = defineEmits(["get-repositories", "add-repository", "close"]);

// Data properties
const newRepo = ref<MinimalRepositoryInterface>({ name: null });

// Watchers
watch(toRef(props, "visible"), (newProp, oldProp) => {
  if (newProp && !oldProp) {
    // Initialize all fields
    newRepo.value.name = null;

    // Load repositories
    emits("get-repositories");
  }
});

// Component methods
const handleAddProjectRepository = (): void => {
  emits("add-repository", newRepo.value);
  emits("close");
};

const closeModal = (): void => {
  emits("close");
};
</script>
