import RecurringPayments from "./_views/RecurringPayments.vue";
import { RouteInterface } from "@/common/types";

export default [
  {
    path: "recurring-payments",
    component: RecurringPayments,
    name: "recurring-payments",
    meta: { auth: true },
  },
] as Array<RouteInterface>;
