import Colors from "@/assets/scss/_colors.module.scss";
export const getStatusTagColor = (status: string) => {
  switch (status) {
    case "unprocessed":
      return Colors.yellow;
    case "succeeded":
      return Colors.green;
    case "failed":
      return Colors.red;
    case "inactive":
      return Colors.grey;
    default:
      return "";
  }
};
