<template>
  <a-form-item
    :label-col="{ span: 8 }"
    :wrapper-col="{ span: 16 }"
    :label="label"
    :validate-status="error ? 'error' : ''"
    :help="error"
  >
    <a-select
      :value="value"
      :mode="mode"
      :placeholder="placeholder"
      :allow-clear="allowClear"
      class="w-100"
      show-search
      option-filter-prop="children"
      @change="handleChange"
    >
      <a-select-option
        v-for="option in options"
        :key="option[optionKey]"
        :value="option[optionValue]"
        >{{
          displayPropFunc !== undefined ? displayPropFunc(option) : option[displayProp]
        }}</a-select-option
      >
    </a-select>
  </a-form-item>
</template>

<script setup lang="ts">
const props = defineProps({
  value: { type: [String, Number, Array, Object], default: undefined },
  label: { type: String, default: undefined },
  error: { type: String, default: undefined },
  placeholder: { type: String, default: undefined },
  allowClear: { type: Boolean, default: false },
  optionKey: { type: String, default: "id" },
  optionValue: { type: String, default: "id" },
  displayProp: { type: String, default: undefined },
  displayPropFunc: { type: Function, default: undefined },
  mode: { type: String, default: undefined },
  options: { type: Array<any>, default: () => [] },
});

const emit = defineEmits(["input"]);

const handleChange = (id: string): void => {
  emit("input", id);
};
</script>
