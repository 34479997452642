<template>
  <div class="card">
    <empty-resource-table v-if="!clientsPage[0] && !loadingTable" resource="clients" />

    <a-table
      v-if="loadingTable || clientsPage[0]"
      :columns="clientsColumns"
      :data-source="clientsPage"
      :pagination="false"
      :loading="loadingTable"
      :scroll="{ x: 400 }"
      :row-key="(client) => client.id"
      @change="(pagination, filters, sorter) => handleChange(pagination, filters, sorter)"
    >
      <template #name="name, client">
        <span class="d-flex align-items-center">
          <router-link :to="{ name: 'client', params: { id: client.id } }">{{ name }}</router-link>
        </span>
      </template>

      <template #status="text, client">
        <span class="d-flex align-items-center">
          <a-tag v-if="client.status == 'active'" :color="Colors.green">ACTIVE</a-tag>
          <a-tag v-else :color="Colors.orange">INACTIVE</a-tag>
        </span>
      </template>

      <template #secrecy="text, client">
        <span class="d-flex align-items-center">
          <div style="min-width: 35px">
            <a-tag
              v-if="client.secrecy_mention_client_spoken"
              :color="Colors.cyan"
              class="text-black font-weight-bold"
              >M</a-tag
            >
          </div>
          <div style="min-width: 35px">
            <a-tag v-if="client.secrecy_client_is_official_written" :color="Colors.blue_darker"
              >O</a-tag
            >
          </div>
          <div style="min-width: 35px">
            <a-tag v-if="client.secrecy_ok_show_client_logo" :color="Colors.blue_darkest">L</a-tag>
          </div>
        </span>
      </template>
    </a-table>

    <a-pagination
      :current="currentPage"
      :total="clientsPagination ? clientsPagination.total : 1"
      :show-size-changer="true"
      :page-size="pageSize"
      :page-size-options="['10', '25', '50', '100']"
      size="small"
      class="d-flex justify-content-end mt-2"
      @change="(page, pageSize) => handleGetClientsPage(page, pageSize)"
      @showSizeChange="(pageNumber, newPageSize) => handleGetClientsPage(pageNumber, newPageSize)"
    />

    <div class="d-flex mt-1 flex-wrap">
      <div class="mr-4 my-1">
        <a-tag :color="Colors.cyan" class="text-black font-weight-bold">M</a-tag>Mention as client,
        spoken
      </div>
      <div class="mr-4 my-1">
        <a-tag :color="Colors.blue_darker">O</a-tag>Official as client, written
      </div>
      <div class="mr-4 my-1">
        <a-tag :color="Colors.blue_darkest">L</a-tag>Show their logo on website
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import EmptyResourceTable from "@/modules/common/_components/EmptyResourceTable.vue";
import { PaginationInterface } from "@/common/types";
import { clientsColumns } from "../_utils/tables";
import { ClientInterface } from "../types";
import Colors from "@/assets/scss/_colors.module.scss";

// Props
const props = defineProps({
  clientsPage: { type: Array as () => Array<ClientInterface>, default: () => [] },
  loadingTable: { type: Boolean as () => boolean, default: false },
  currentPage: { type: Number as () => number, default: 1 },
  pageSize: { type: Number as () => number, default: 25 },
  clientsPagination: { type: Object as () => PaginationInterface, default: () => ({}) },
});

// Emits
const emits = defineEmits(["get-clients-page", "apply-sorting"]);

const handleGetClientsPage = (page: number, pageSize: number) => {
  emits("get-clients-page", { page, pageSize });
};

const handleChange = (pagination: PaginationInterface, filters: any, sorter: any) => {
  emits("apply-sorting", {
    sort: sorter.columnKey,
    order: sorter.columnKey ? (sorter.order === "descend" ? "desc" : "asc") : undefined,
  });
};
</script>
