<template>
  <div>
    <a-table
      :columns="columns"
      :loading="loadingSummary"
      :data-source="subprojectsSummary"
      :pagination="false"
      :scroll="{ x: true }"
      :row-key="(subproject) => subproject.name"
    >
      <template #footer>
        <strong>Total:</strong>
        {{ subprojectTotalHours }}h
      </template>
    </a-table>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";

// Props
const props = defineProps({
  subprojectsSummary: { type: Array as () => Array<any>, default: () => [] },
  loadingSummary: { type: Boolean, default: false },
});

// Data properties
const columns = ref<Array<any>>([
  {
    title: "Subproject",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Total hours",
    dataIndex: "total",
    key: "total",
  },
  {
    title: "Total cost",
    dataIndex: "total_cost_cents",
    key: "total_cost",
    customRender: (text: number, record: { total_cost_currency: string }) => {
      if (!text) {
        return "";
      }
      return (text / 100).toString() + " " + record.total_cost_currency;
    },
  },
]);

// Computed properties
const subprojectTotalHours = computed((): number => {
  let total = 0;
  for (let subproject of props.subprojectsSummary) {
    total += subproject.total;
  }
  return total;
});
</script>
