<template>
  <!-- REPORTS FILTER -->
  <div>
    <div v-if="filterPanelVisible" class="card mb-3">
      <a-form layout="vertical">
        <bookmarked-filters-selection
          :bookmarked-filters="bookmarkedFilters"
          :filter-is-empty="filterIsEmpty"
          bookmark-type="Reports"
          class="ml-3"
          @selected="(bookmarkFilterUrl) => handleCustomFilterSelected(bookmarkFilterUrl)"
          @create-bookmark-filter="handleCreateBookmarkFilter"
          @update-bookmark-filter="handleUpdateBookmarkFilter"
          @delete-bookmark-filter="handleDeleteBookmarkFilter"
        />
        <a-row>
          <a-col :lg="11" class="ml-3">
            <SkNameSelect
              v-model="newFilter.user_id"
              label="Users"
              :label-col-size="10"
              mode="multiple"
              placeholder="Select users"
              :allow-clear="true"
              :options="usersCompactList"
              @change="applyFilters"
            />

            <SkNameSelect
              v-model="newFilter.project_id"
              label="Projects"
              :label-col-size="10"
              mode="multiple"
              placeholder="Select projects"
              :allow-clear="true"
              :options="projectsCompactList"
              @change="applyFilters"
            />

            <a-form-item
              :label-col="{ span: 10 }"
              :wrapper-col="{ span: 16 }"
              label="Date"
              class="pb-0 mb-0"
            >
              <a-date-picker
                :value="parseDate(newFilter.start_date)"
                :disabled-date="(current) => disabledStartDate(current)"
                placeholder="Start Date"
                class="w-100 mb-2"
                @change="(date) => onDateChange(date, 'start_date')"
              />
              <a-date-picker
                :value="parseDate(newFilter.end_date)"
                :disabled-date="(current) => disabledEndDate(current)"
                placeholder="End Date"
                class="w-100 mb-2"
                @change="(date) => onDateChange(date, 'end_date')"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="11" class="ml-3">
            <a-form>
              <a-form-item label="Types">
                <a-checkbox-group
                  :value="newFilter.types"
                  @change="(types) => updateTypeFilter(types)"
                >
                  <a-checkbox class="ml-2 mt-2 d-block" :value="ReportType.EXPENSE">
                    Expense Reports
                  </a-checkbox>
                  <a-checkbox class="mt-2 d-block" :value="ReportType.TIME">
                    Time Reports
                  </a-checkbox>
                  <a-checkbox class="mt-2 d-block" :value="ReportType.VACATION">
                    Vacation Reports
                  </a-checkbox>
                  <a-checkbox class="mt-2 d-block" :value="ReportType.ABSENCE">
                    Absence Reports
                  </a-checkbox>
                  <a-checkbox class="mt-2 d-block" :value="ReportType.SICK">
                    Sick Reports
                  </a-checkbox>
                  <a-checkbox class="mt-2 d-block" :value="ReportType.PARENTAL">
                    Parental leave Reports
                  </a-checkbox>
                </a-checkbox-group>
              </a-form-item>
            </a-form>
          </a-col>
        </a-row>
      </a-form>
      <span class="d-flex justify-content-end">
        <span>
          <strong>Results:</strong>
          {{ numberOfResults }}
        </span>
      </span>

      <div v-if="numberOfVacationDays && newFilter.user_id.length == 1">
        <span class="d-flex justify-content-end mt-3">
          <span>
            <strong>Total vacation days:</strong>
            {{ numberOfVacationDays }}
          </span>
        </span>
      </div>
    </div>
    <portal to="reports-filter-buttons">
      <a-button
        v-if="filterPanelVisible"
        class="mr-2"
        icon="close"
        type="danger"
        @click="() => clearFilters()"
      />
      <a-button
        v-show="filterPanelVisible"
        class="mr-2"
        type="primary"
        icon="up"
        @click="() => toggleFilterPanel()"
      />
      <a-button
        v-if="!filterPanelVisible"
        class="mr-2"
        type="primary"
        icon="filter"
        @click="() => toggleFilterPanel()"
      />
    </portal>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, getCurrentInstance } from "vue";
import BookmarkedFiltersSelection from "../../bookmarked_filters/_components/BookmarkedFiltersSelection.vue";
import { BaseAclInterface, ProfileInterface } from "../../authentication/types";
import { BookmarkedFilterInterface } from "@/modules/bookmarked_filters/types";
import { getUsersCompactList, getProjectsAndClientsCompactList } from "@/api";
import { MinimalProjectInterface } from "@/modules/projects/types";
import { ReportsFilterInterface, ReportType } from "../types";
import { MinimalUserInterface } from "@/modules/users/types";
import useMixin from "@/useMixin";
import moment from "@/date";
import {
  apiGetUserBookmarkedFilters,
  apiStoreUserBookmarkedFilter,
  apiUpdateUserBookmarkedFilter,
  apiDestroyUserBookmarkedFilter,
} from "@/modules/bookmarked_filters/_utils/api";
import useFilters from "@/useFilters";

// Props
const props = defineProps({
  acl: { type: Object as () => BaseAclInterface, default: undefined },
  filters: { type: Object as () => ReportsFilterInterface, default: undefined },
  profile: { type: Object as () => ProfileInterface, default: undefined },
  numberOfResults: { type: Number, default: undefined },
  numberOfVacationDays: { type: Number, default: undefined },
});

// Emits
const emits = defineEmits(["apply-filters"]);

// Mixins
const { qsDecode, setObject } = useMixin();

// Filters
const { parseDate } = useFilters();

// Instances
const instance = getCurrentInstance();
const $message = instance?.proxy.$message;

// Data properties
const filterPanelVisible = ref<boolean>(false);
const usersCompactList = ref<Array<MinimalUserInterface>>([]);
const projectsCompactList = ref<Array<MinimalProjectInterface>>([]);
const newFilter = ref<ReportsFilterInterface>({
  types: [],
  user_id: [],
  project_id: [],
  start_date: "",
  end_date: "",
});
const bookmarkedFilters = ref<Array<BookmarkedFilterInterface>>([]);

// Computed Properties
const filterIsEmpty = computed((): boolean => {
  return Boolean(
    newFilter.value.types &&
      newFilter.value.user_id &&
      newFilter.value.project_id &&
      newFilter.value.types.length === 0 &&
      newFilter.value.user_id.length === 0 &&
      newFilter.value.project_id.length === 0 &&
      newFilter.value.start_date === "" &&
      newFilter.value.end_date === ""
  );
});

// Watchers
watch(filterPanelVisible, (newProp, oldProp) => {
  if (newProp && !oldProp) {
    handleGetBookmarkedFilters();
    handleGetUsersCompactList();
    handleGetProjectsCompactList();
    setFilter(props.filters);
  }
});

// Component Methods
const toggleFilterPanel = (): void => {
  filterPanelVisible.value = !filterPanelVisible.value;
};

const handleGetUsersCompactList = (): void => {
  getUsersCompactList().then((users) => {
    usersCompactList.value = users;
  });
};

const handleGetProjectsCompactList = (): void => {
  getProjectsAndClientsCompactList().then((projects) => {
    projectsCompactList.value = projects.map((project) => {
      return {
        id: project.id,
        name: `${project.client.name} / ${project.name}`,
      };
    });
  });
};

const handleCustomFilterSelected = (bookmarkFilterUrl: string): void => {
  handleClearReportsFilter();
  if (bookmarkFilterUrl) {
    emits("apply-filters", qsDecode(bookmarkFilterUrl).filters);
  } else {
    applyFilters();
  }
};

const updateTypeFilter = (types_filters: any): void => {
  newFilter.value.types = types_filters;
  applyFilters();
};

const applyFilters = (): void => {
  emits("apply-filters", filterIsEmpty.value ? {} : newFilter.value);
};

const clearFilters = (): void => {
  handleClearReportsFilter();
  emits("apply-filters", {});
};

const disabledStartDate = (startValue: any): boolean => {
  const endValue = moment(newFilter.value.end_date).endOf("day");
  if (!startValue || !endValue) {
    return false;
  }
  return startValue.valueOf() >= endValue.valueOf();
};

const disabledEndDate = (endValue: any): boolean => {
  const startValue = moment(newFilter.value.start_date);
  if (!endValue || !startValue) {
    return false;
  }
  return startValue.valueOf() >= endValue.valueOf();
};

const onDateChange = (date: any, type: string): void => {
  if (type === "start_date") {
    newFilter.value.start_date = date == null ? "" : date.format("YYYY-MM-DD");
  } else if (type === "end_date") {
    newFilter.value.end_date = date == null ? "" : date.format("YYYY-MM-DD");
  }
  applyFilters();
};

const handleClearReportsFilter = (): void => {
  newFilter.value = {
    types: [],
    user_id: [],
    project_id: [],
    start_date: "",
    end_date: "",
  };
};

const setFilter = (filter: any): void => {
  if (filter === undefined) {
    return;
  }
  newFilter.value = setObject(newFilter.value, filter);
};

const handleGetBookmarkedFilters = (): void => {
  apiGetUserBookmarkedFilters(props.profile.id).then(
    ({ data }: any) => (bookmarkedFilters.value = data.data)
  );
};

const handleCreateBookmarkFilter = (bookmarkFilter: any): void => {
  apiStoreUserBookmarkedFilter(props.profile.id, bookmarkFilter)
    .then(({ data }: any) => bookmarkedFilters.value.push(data.data))
    .then(() => $message?.success("New filter created successfully!", 3))
    .catch(() => $message?.error("Couldn't create new filter!", 3));
};

const handleUpdateBookmarkFilter = (bookmarkFilterId: number, bookmarkFilter: any): void => {
  apiUpdateUserBookmarkedFilter(props.profile.id, bookmarkFilterId, bookmarkFilter)
    .then(
      ({ data }: any) =>
        (bookmarkedFilters.value = bookmarkedFilters.value.map((f: any) =>
          f.id === data.data.id ? data.data : f
        ))
    )
    .then(() => $message?.success("Filter updated successfully!", 3))
    .catch(() => $message?.error("Couldn't update filter!", 3));
};

const handleDeleteBookmarkFilter = (bookmarkFilterId: number): void => {
  apiDestroyUserBookmarkedFilter(props.profile.id, bookmarkFilterId)
    .then(
      () =>
        (bookmarkedFilters.value = bookmarkedFilters.value.filter(
          (f: any) => f.id !== bookmarkFilterId
        ))
    )
    .then(() => $message?.success("Filter removed successfully!", 3))
    .catch(() => $message?.error("Couldn't remove filter!", 3));
};
</script>
