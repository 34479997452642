import Colors from "@/assets/scss/_colors.module.scss";

export function receiptStatusLabel(status: string): string {
  switch (status) {
    case "uploading":
      return "UPLOADING";
    case "declined":
      return "DECLINED";
    case "pending":
      return "PENDING APPROVAL";
    case "approved":
      return "APPROVED";
    case "approved_pending_payment":
      return "APPROVED";
    default:
      return "";
  }
}

export function receiptStatusColor(status: string): string {
  switch (status) {
    case "uploading":
      return Colors.cyan;
    case "declined":
      return Colors.red;
    case "pending":
      return Colors.yellow;
    case "approved":
      return Colors.green;
    case "approved_pending_payment":
      return Colors.green;
    default:
      return "";
  }
}
