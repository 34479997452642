import { MoneyInterface } from "@/common/types";
import { MinimalProjectInterface } from "../projects/types";
import { MinimalUserInterface } from "../users/types";

export interface RoleInterface {
  id: number;
  end_date: string;
  start_date: string;
  title: string;
  cost_center_id: number;
  description?: string;
  description_status: RoleDescriptionStatus;
  project: any; // TODO add project interface
  user: any; // TODO add user interface
  skills: Array<any>; // TODO add skill interface
  associations: Array<RoleAssociationsInterface>;
  editable: boolean;
  deletable: boolean;
  hourly_rate: MoneyInterface | null;
  future_hourly_rate: MoneyInterface;
  future_hourly_rate_date: string | null;
  notice_period: number;
  workload: number;
  agreement_link: string | null;
  automatic_time_reporting: boolean;
  extension_status?: string;
  disable_cv?: boolean;
}

export interface RoleSmallInterface {
  id: number;
  title: string;
  description: string;
  start_date: string;
  end_date: string;
  workload: string;
  project: MinimalProjectInterface;
  user: MinimalUserInterface;
}

export enum RoleDescriptionStatus {
  MISSING = "missing",
  PENDING = "pending",
  PRESENT = "present",
}

export enum RoleStatus {
  INCOMPLETE = "incomplete",
  COMPLETE = "complete",
}

export interface RoleAssociationsInterface {
  name: string;
  count: number;
}

export interface RolesFilterInterface {
  user_id?: Array<number>;
  client_id?: Array<number>;
  project_id?: Array<number>;
  start_date?: string;
  end_date?: string;
  role_description_status?: string;
  inactive_users?: string;
  extension_statuses?: string;
  disable_cv?: boolean;
}

export interface RoleExtensionInterface {
  end_date: string;
  hourly_rate_cents: number;
  hourly_rate_currency: string;
  workload: number;
  notice_period: number;
  initiated_by: string;
  pipedrive_id: number;
  comment?: string;
  role_id?: number;
  id?: number;
  hourly_rate?: MoneyInterface;
  role?: RoleInterface;
  colleague_accepted?: boolean;
  pending?: boolean;
  created_by_user?: MinimalUserInterface;
  future_hourly_rate_date?: string;
}

export interface CreateRoleExtensionArgumentsInterface {
  roleId: number;
  newRoleExtension: RoleExtensionInterface;
}

export interface EditRoleExtensionArgumentsInterface {
  updatedRoleExtension: RoleExtensionInterface;
}

export const INITIATED_BY_OPTIONS = [
  { id: "client", formatted_name: "Client" },
  { id: "colleague", formatted_name: "Colleague" },
  { id: "cr", formatted_name: "Client Relation" },
];

export function convertInitiatedByToFormattedName(initiatedBy: string): string {
  let initiatedByOption = INITIATED_BY_OPTIONS.find((i) => i.id == initiatedBy);
  if (initiatedByOption) return initiatedByOption.formatted_name;
  throw new Error(`Unknown id '${initiatedBy}' for 'initiated_by'`);
}
