<template>
  <div v-show="visible">
    <h3>Gravatar</h3>

    <a-spin size="large" :spinning="checking" class="d-flex justify-content-center" />
    <div v-show="checking">
      <p class="d-flex justify-content-center mt-4">
        Checking to see if a Gravatar is linked to your email address…
      </p>
    </div>

    <div v-show="gravatarExists !== null && gravatarExists && !checking">
      <a-icon type="check-circle" class="mr-1" />
      All good!
      <div class="picture-wrapper">
        <img :src="getUserGravatarURL()" />
      </div>
    </div>

    <div v-show="gravatarExists !== null && !gravatarExists && !checking">
      <p>It seems like you haven't set up Gravatar yet.</p>
      <p>
        Head on over to
        <a href="https://en.gravatar.com/" target="_blank" rel="noreferrer">Gravatar</a> to create
        one.
      </p>
    </div>

    <a-button
      v-show="gravatarExists !== null && !gravatarExists && !checking"
      type="primary"
      class="mt-2"
      @click="() => checkIfGravatarExists()"
    >
      Check again
    </a-button>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from "vue";
import { Md5 } from "ts-md5";

// Props
const props = defineProps({
  visible: { type: Boolean, default: false },
  email: { type: String, default: undefined },
  shouldReload: { type: Boolean, default: false },
});

// Emits
const emits = defineEmits(["update"]);

// Data Properties
const checking = ref<boolean>(false);
const gravatarExists = ref<boolean | null>(null);

// Lifecycle hooks
onMounted(() => {
  checkIfGravatarExists(false);
});

// Watchers
watch(
  () => props.visible,
  (newValue) => {
    if (newValue && props.shouldReload) {
      checkIfGravatarExists();
    }
  },
  { immediate: true }
);

// Class methods
const checkIfGravatarExists = async (wait: boolean = true) => {
  checking.value = true;

  if (wait) {
    // wait 1 second, for better UX
    await new Promise((resolve) => setTimeout(resolve, 1000));
  }

  fetch(getUserGravatarURL())
    .then((response) => {
      if (response.status === 404) {
        throw new Error();
      }
      gravatarExists.value = true;
    })
    .catch(() => {
      gravatarExists.value = false;
    })
    .finally(() => {
      checking.value = false;
      emits("update", gravatarExists.value);
    });
};

const getUserGravatarURL = (size: number = 256): string => {
  const hash = new Md5().appendStr(props.email).end();
  return `https://gravatar.com/avatar/${hash}?d=404&s=${size}`;
};
</script>

<style lang="scss" scoped>
.picture-wrapper {
  display: flex;
  justify-content: center;

  img {
    border-radius: 100%;
    height: 256px;
  }
}
</style>
