import { RouteInterface } from "@/common/types";
import Reports from "./_views/Reports.vue";

export default [
  {
    path: "reports",
    component: Reports,
    name: "reports",
    meta: { auth: true },
  },
] as Array<RouteInterface>;
