<template>
  <a-layout-content class="my-4 mx-3">
    <div v-if="team" class="container">
      <!-- HEADER -->
      <team-header
        :team="team"
        :users="usersCompactList"
        :acl="acl"
        @get-users="() => handleGetUsersCompactList()"
        @update-team="(updatedTeam) => handleUpdateTeam(updatedTeam)"
        @destroy-team="(teamId) => handleDestroyTeam(teamId)"
      />

      <!-- TEAM MEMBERS TABLE -->
      <div class="card mt-4">
        <div class="flex-center-between">
          <h2>Members</h2>
        </div>

        <!-- MEMBERS TABLE -->
        <a-table
          :row-key="(user) => user.id"
          :columns="membersColumns"
          :data-source="[team.leader, ...team.users]"
          :pagination="false"
          :scroll="{ x: 500 }"
        >
          <template #name="name, user">
            <span class="d-flex align-items-center">
              <span class="min-w-8 mr-2">
                <a-avatar :src="user.gravatar" />
              </span>
              <router-link :to="{ name: 'user', params: { id: user.id } }">{{ name }}</router-link>
              <a-icon v-if="user.id === team.leader.id" class="ml-2 text-yellow" type="star" />
            </span>
          </template>
        </a-table>
      </div>
    </div>

    <!-- EDIT TEAM MODAL -->
    <edit-team-modal
      :visible="editTeamModalVisible"
      :team="team"
      :users="usersCompactList"
      @close="editTeamModalVisible = false"
      @get-users="handleGetUsersCompactList"
      @update-team="handleUpdateTeam"
    />
  </a-layout-content>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, getCurrentInstance } from "vue";
import { BaseAclInterface, ProfileInterface } from "@/modules/authentication/types";
import { apiGetTeam, apiUpdateTeam, apiDestroyTeam } from "../_utils/api";
import { useAuthenticationStore } from "@/modules/authentication/_store";
import { TeamInterface, TeamPayloadInterface } from "../types";
import EditTeamModal from "../_components/EditTeamModal.vue";
import { MinimalUserInterface } from "@/modules/users/types";
import TeamHeader from "../_components/TeamHeader.vue";
import { membersColumns } from "../_utils/tables";
import { getUsersCompactList } from "@/api";

// Pinia
const authenticationStore = useAuthenticationStore();
const profile = computed<ProfileInterface | undefined>(() => authenticationStore.profile);
const baseAcl = computed<BaseAclInterface>(() => authenticationStore.baseAcl);

// Instances
const instance = getCurrentInstance();
const $route = instance?.proxy.$route;
const $router = instance?.proxy.$router;
const $message = instance?.proxy.$message;

// Data properties
const usersCompactList = ref<Array<MinimalUserInterface>>([]);
const team = ref<TeamInterface | undefined>(undefined);
const editTeamModalVisible = ref<boolean>(false);

// Computed properties
const isTeamLeader = computed((): boolean => {
  return Boolean(team.value && profile.value?.id === team.value.leader.id);
});

// Combine base access control level (ACL) with team leader
const acl = computed((): BaseAclInterface & { isTeamLeader: boolean } => {
  return { ...baseAcl.value, isTeamLeader: isTeamLeader.value };
});

// Lifecycle hooks
onMounted(() => {
  apiGetTeam(parseInt($route?.params.id as string)).then(
    ({ data }: any) => (team.value = data.data)
  );
});

// Class methods
const handleDestroyTeam = (teamId: number): void => {
  apiDestroyTeam(teamId)
    .then(() => (team.value = undefined))
    .then(() => {
      $message?.success("Team removed successfully!", 3);
      $router?.push({ name: "teams" });
    })
    .catch(() => $message?.error("Couldn't remove team!", 3));
};

const handleUpdateTeam = (updatedTeam: TeamPayloadInterface): void => {
  if (!team.value) return;
  apiUpdateTeam(team.value.id, updatedTeam)
    .then(({ data }: any) => (team.value = data.data))
    .then(() => $message?.success("Team updated successfully!", 3))
    .catch(() => $message?.error("Couldn't updated team!", 3));
};

const handleGetUsersCompactList = (): void => {
  getUsersCompactList().then(
    (users: Array<MinimalUserInterface>) => (usersCompactList.value = users)
  );
};
</script>
