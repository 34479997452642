import { RouteInterface } from "@/common/types";
import JobPostings from "./_views/JobPostings.vue";

export default [
  {
    path: "postings",
    component: JobPostings,
    name: "job-postings",
    meta: { auth: true },
  },
] as Array<RouteInterface>;
