<template>
  <a-modal :visible="visible" :destroy-on-close="true" title="Edit Receipt" @cancel="closeModal">
    <!-- RECEIPT MODAL STEPS -->
    <a-steps
      :current="currentStep"
      size="small"
      class="px-3 pb-4 d-none d-lg-flex"
      direction="horizontal"
    >
      <a-step title="Info">
        <template #icon>
          <a-icon type="form" />
        </template>
      </a-step>
      <a-step title="Financial">
        <template #icon>
          <a-icon type="credit-card" />
        </template>
      </a-step>
      <a-step title="Attachments">
        <template #icon>
          <a-icon type="paper-clip" />
        </template>
      </a-step>
    </a-steps>

    <!-- INFO STEP -->
    <a-form v-show="currentStep === 0" data-vv-scope="step-info" layout="horizontal">
      <SkTextarea
        v-model="receipt.description"
        v-validate="'required|max:1024'"
        :error="$validator.errors.first('step-info.description')"
        :auto-size="{ minRows: 2 }"
        label="Description"
        placeholder="Receipt refers to..."
        data-vv-name="description"
      />

      <SkSelect
        v-model="receipt.category_ids"
        v-validate="'required'"
        :error="$validator.errors.first('step-info.categories')"
        :options="categories"
        label="Categories"
        mode="multiple"
        placeholder="Please select categories"
        data-vv-name="categories"
        display-prop="name"
      />

      <SkSelect
        v-if="representation"
        v-model="receipt.participant_ids"
        :options="users"
        label="Internal"
        mode="multiple"
        placeholder="SK participants"
        display-prop="name"
      />

      <a-form-item
        v-if="representation"
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
        label="External"
      >
        <a-select
          :token-separators="[',']"
          :default-value="receipt.external_participants ?? undefined"
          mode="tags"
          placeholder="External participants"
          @change="(tags) => onTagsChange(tags, 'external_participants')"
        />
      </a-form-item>
    </a-form>

    <!-- FINANCIAL STEP -->
    <a-form v-show="currentStep === 1" data-vv-scope="step-financial" layout="horizontal">
      <SkMoney
        v-model="receipt.amount_cents"
        v-validate="'required|decimal|min_value:0'"
        :error="$validator.errors.first('step-financial.amount')"
        :currency="receipt.amount_currency"
        data-vv-name="amount"
        label="Amount"
        placeholder="Amount"
        @set-currency="(v) => (receipt.amount_currency = v)"
      />

      <a-form-item
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
        :validate-status="$validator.errors.first('step-financial.cost_center_id') ? 'error' : ''"
        :help="$validator.errors.first('step-financial.cost_center_id')"
        label="Cost center"
      >
        <a-select
          v-model="receipt.cost_center_id"
          v-validate="'required'"
          :default-value="receipt.cost_center_id"
          placeholder="Select cost center"
          data-vv-name="cost_center_id"
          data-vv-as="cost center"
        >
          <a-select-option v-for="cc in costCenters" :key="cc.id">
            {{ cc.name }} ({{ cc.manager.name }})
          </a-select-option>
        </a-select>
      </a-form-item>

      <div class="ml-2 text-black">
        <p class="font-italic flex-center">Examples of common uses:</p>
        <p v-for="cc in costCenters" :key="cc.id">
          {{ cc.name }}: <span class="font-weight-lighter">{{ cc.description }}</span>
        </p>
      </div>
    </a-form>

    <!-- ATTACHMENTS STEP -->
    <a-form v-show="currentStep === 2" data-vv-scope="step-attachments" layout="horizontal">
      <a-list :data-source="attachments" bordered size="small">
        <template #header>
          <div>Saved attachments</div>
        </template>
        <template #renderItem="item, index">
          <a-list-item>
            <template #actions>
              <a-icon type="delete" @click="moveAttachmentToDeleted(index)"></a-icon>
            </template>
            <a :href="item.url">{{ item.file_name }} - {{ item.created_at }}</a>
          </a-list-item>
        </template>
      </a-list>
      <a-list :data-source="deletedAttachments" bordered size="small" class="mt-1">
        <template #header>
          <div>Attachments to delete</div>
        </template>
        <template #renderItem="item, index">
          <a-list-item>
            <template #actions>
              <a-icon type="undo" @click="undoDeleteAttachment(index)"></a-icon>
            </template>
            <a :href="item.url">{{ item.file_name }} - {{ item.created_at }}</a>
          </a-list-item>
        </template>
      </a-list>
      <a-form-item
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
        :validate-status="$validator.errors.first('step-attachments.attachments') ? 'error' : ''"
        :help="$validator.errors.first('step-attachments.attachments')"
        label="Receipt image"
      >
        <a-upload
          :file-list="fileList"
          :before-upload="beforeUpload"
          :remove="handleRemove"
          action="//jsonplaceholder.typicode.com/posts/"
        >
          <a-button> <a-icon type="upload" /> Select File </a-button>
        </a-upload>
      </a-form-item>
    </a-form>

    <!-- CUSTOM FOOTER TO ADVANCE STEPS -->
    <template #footer>
      <div class="d-flex justify-content-between">
        <div><a-button @click="closeModal">Cancel</a-button></div>

        <div>
          <a-button v-show="currentStep !== 0" @click="prev">Previous</a-button>
          <a-button v-show="currentStep === 0" type="primary" @click="goToFinancialStep">
            Next
          </a-button>
          <a-button v-show="currentStep === 1" type="primary" @click="goToAttachmentsStep">
            Next
          </a-button>
          <a-button v-show="currentStep === 2" type="primary" @click="processReceipt">
            Save
          </a-button>
        </div>
      </div>
    </template>
  </a-modal>
</template>

<script setup lang="ts">
import { ref, computed, watch, toRef, getCurrentInstance } from "vue";
import { ReceiptInterface, ReceiptAttachmentInterface } from "../types";
import { MinimalUserInterface } from "@/modules/users/types";
import useMixin from "@/useMixin";

// Props
const props = defineProps({
  visible: { type: Boolean, default: false },
  categories: { type: Array as () => Array<any>, default: () => [] },
  users: { type: Array as () => Array<MinimalUserInterface>, default: () => [] },
  costCenters: { type: Array as () => Array<any>, default: () => [] },
  originalReceipt: { type: Object as () => ReceiptInterface, default: undefined },
  originalAttachments: {
    type: Array as () => Array<ReceiptAttachmentInterface>,
    default: () => [],
  },
  deleteAttachment: { type: Function, default: () => {} },
});

// Emits
const emits = defineEmits([
  "get-cost-centers",
  "get-categories",
  "update-receipt",
  "get-users",
  "close",
]);

// Mixins
const { setObject, toMoneyCents } = useMixin();

// Instances
const instance = getCurrentInstance();
const $validator = instance?.proxy.$validator;

// Data properties
const receipt = ref<any>({
  id: undefined,
  user: undefined,
  description: undefined,
  category_ids: undefined,
  participant_ids: undefined,
  external_participants: undefined,
  amount_currency: undefined,
  amount_cents: undefined,
  cost_center_id: undefined,
});
const currentStep = ref<number>(0);
const fileList = ref<Array<any>>([]);
const encodedList = ref<Array<any>>([]);
const attachments = ref<Array<ReceiptAttachmentInterface>>([]);
const deletedAttachments = ref<Array<ReceiptAttachmentInterface>>([]);

// Computed values
// Returns true if at least one selected categories is flagged as representation.
const representation = computed((): boolean => {
  let isRepresentation = false;
  if (receipt.value.category_ids) {
    props.categories.forEach((category) => {
      if (category.representation && receipt.value.category_ids.indexOf(category.id) >= 0) {
        isRepresentation = true;
      }
    });
  }
  return isRepresentation;
});

// Watchers
watch(toRef(props, "visible"), (newProp, oldProp) => {
  if (newProp && !oldProp) {
    emits("get-categories");
    emits("get-cost-centers");
    emits("get-users");

    receipt.value = setObject(receipt.value, props.originalReceipt);
    attachments.value = [...props.originalAttachments];
    receipt.value.category_ids = props.originalReceipt.categories.map((c: any) => parseInt(c.id));
    receipt.value.cost_center_id = props.originalReceipt.cost_center.id;

    if (props.originalReceipt.participants) {
      receipt.value.participant_ids = props.originalReceipt.participants.map((p: any) =>
        parseInt(p.id)
      );
    }

    if (receipt.value.external_participants) {
      receipt.value.external_participants = receipt.value.external_participants.split(", ");
    }

    fileList.value = [];
    encodedList.value = [];

    receipt.value.amount_cents = props.originalReceipt.amount
      ? props.originalReceipt.amount.value / 100
      : 0;
    receipt.value.amount_currency = props.originalReceipt.amount
      ? props.originalReceipt.amount.currency_code
      : "SEK";
  }
});

// Component methods
const closeModal = (): void => {
  $validator?.errors.clear();
  currentStep.value = 0;
  emits("close");
};

const onTagsChange = (tags: any, field: any): void => {
  receipt.value[field] = tags;
};

const prev = (): void => {
  currentStep.value--;
};

const goToFinancialStep = (): void => {
  $validator?.errors.clear();

  if (!representation.value && receipt.value.participant_ids) {
    // Clear participants if no selected category is representation and participants is filled
    receipt.value.participant_ids = undefined;
  }

  $validator?.validateAll("step-info").then((result) => {
    if (result) {
      currentStep.value++;
    }
  });
};

const goToAttachmentsStep = (): void => {
  $validator?.validateAll("step-financial").then((result) => {
    if (result) {
      currentStep.value++;
    }
  });
};

const handleRemove = (file: any): void => {
  const index = fileList.value.indexOf(file);
  const newFileList = fileList.value.slice();
  newFileList.splice(index, 1);
  fileList.value = newFileList;
};

const beforeUpload = (file: any): boolean => {
  $validator?.errors.clear();
  fileList.value = [...fileList.value, file];
  return false;
};

const processReceipt = (): void => {
  $validator?.errors.clear();

  $validator?.validateAll("step-attachments").then((result) => {
    if (fileList.value.length + attachments.value.length < 1) {
      $validator?.errors.add({
        field: "step-attachments.attachments",
        msg: "A receipt requires at least one attachment.",
      });
      return;
    }
    // If last step and validation pass...
    if (result) {
      // Join all external participants in single string
      if (receipt.value.external_participants) {
        receipt.value.external_participants = receipt.value.external_participants.join(", ");
      }

      receipt.value.amount_cents = toMoneyCents(receipt.value.amount_cents);
      deleteAttachments().then(() => {
        // Asyncronously process uploaded files.
        processFiles();
      });
    }
  });
};

const deleteAttachments = async (): Promise<any> => {
  var deletes = [];
  for (const attachment of deletedAttachments.value) {
    deletes.push(props.deleteAttachment(attachment.id));
  }

  await Promise.all(deletes);

  deletedAttachments.value = [];
};

const processFiles = async (): Promise<any> => {
  // Use iteration protocol to achieve asynchronism
  for (const file of fileList.value) {
    await getBase64(file);
  }

  // This lines are executed only when the asynchronous loop is completed.
  receipt.value.attachments = encodedList.value;
  emits("update-receipt", receipt.value);
  currentStep.value = 0;
  emits("close");
};

const getBase64 = (file: any): Promise<any> => {
  return new Promise((resolve, reject) => {
    // Instance reader and parse file.
    const reader = new FileReader();
    reader.readAsDataURL(file);

    // On success put the base64 encoded string in global encodedList.
    reader.onload = () => {
      if (reader.result) {
        encodedList.value.push({
          filename: file.name,
          content_type: file.type,
          data: (reader.result as string).split(",")[1],
        });
        resolve((reader.result as string).split(",")[1]);
      }
    };

    // Handle error!
    reader.onerror = (error) => reject(error);
  });
};

const moveAttachmentToDeleted = (index: number): void => {
  let removed = attachments.value.splice(index, 1);
  deletedAttachments.value.push(removed[0]);
};

const undoDeleteAttachment = (index: number): void => {
  let removed = deletedAttachments.value.splice(index, 1);
  attachments.value.push(removed[0]);
};
</script>
