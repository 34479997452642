<template>
  <a-modal
    :visible="visible"
    title="Select project for invoice creation"
    destroy-on-close
    @ok="navigateToInvoice"
    @cancel="closeModal"
  >
    <a-form>
      <a-form-item
        :validate-status="$validator.errors.first('project') ? 'error' : ''"
        :help="$validator.errors.first('project')"
        label="Projects"
      >
        <a-select
          v-model="newInvoice.projectId"
          v-validate="'required'"
          class="w-100"
          allow-clear
          show-search
          data-vv-name="project"
          placeholder="Select project"
          option-filter-prop="children"
        >
          <a-select-option
            v-for="project in projectsWithClientsCompactList"
            :key="project.id.toString()"
          >
            {{ project.client.name }} - {{ project.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script setup lang="ts">
import { ref, watch, toRef, getCurrentInstance } from "vue";
import { MinimalProjectInterfaceWithMinimalClient } from "@/modules/projects/types";
import { getProjectsAndClientsCompactList } from "@/api";

// Props
const props = defineProps({
  visible: { type: Boolean, default: false },
});

// Emits
const emits = defineEmits(["navigate-to-invoice", "close"]);

// Instances
const instance = getCurrentInstance();
const $validator = instance?.proxy.$validator;

// Data properties
const projectsWithClientsCompactList = ref<Array<MinimalProjectInterfaceWithMinimalClient>>([]);
const newInvoice = ref<any>({
  projectId: undefined,
});

// Watchers
watch(toRef(props, "visible"), (newProp: boolean, oldProp: boolean) => {
  if (newProp && !oldProp) {
    handleGetProjectsCompactList();
  }
});

const handleGetProjectsCompactList = (): void => {
  getProjectsAndClientsCompactList().then(
    (projects) => (projectsWithClientsCompactList.value = projects)
  );
};

// Component methods
const navigateToInvoice = (): void => {
  $validator?.validateAll().then(() => {
    emits("navigate-to-invoice", newInvoice.value.projectId);
  });
};

const closeModal = (): void => {
  $validator?.errors.clear();
  emits("close");
};
</script>
