<template>
  <div>
    <a-row type="flex" justify="end" class="mb-2">
      <a-button type="primary" @click="addCharacteristicModalVisible = true">
        Add characteristic
      </a-button>
    </a-row>
    <a-row type="flex">
      <a-card class="mb-2" type="flex" justify="space-between" align="middle">
        <a-popover
          v-for="characteristic in characteristics"
          :key="characteristic.id"
          trigger="click"
          class="mb-2"
        >
          <template #content>
            <a-icon
              type="delete"
              class="m-2 action-danger"
              @click="confirmDeleteCharacteristic(characteristic)"
              >Delete</a-icon
            >
            <a-icon
              type="edit"
              class="m-2 action-default"
              @click="handleEditCharacteristic(characteristic)"
              >Edit</a-icon
            >
          </template>
          <a-tag>{{ characteristic.title }}</a-tag>
        </a-popover>
      </a-card>
    </a-row>

    <add-characteristic-modal
      :visible="addCharacteristicModalVisible"
      @close="addCharacteristicModalVisible = false"
      @store-characteristic="
        (newCharacteristic) => $emit('store-characteristic', newCharacteristic)
      "
    />
    <edit-characteristic-modal
      :visible="editCharacteristicModalVisible"
      :characteristic="editCharacteristic"
      @close="editCharacteristicModalVisible = false"
      @update-characteristic="
        (updatedCharacteristic) => $emit('update-characteristic', updatedCharacteristic)
      "
    />
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import EditCharacteristicModal from "./EditCharacteristicModal.vue";
import AddCharacteristicModal from "./AddCharacteristicModal.vue";

// Props
const props = defineProps({
  characteristics: { type: Array as () => Array<any>, default: () => [] },
});

// Emits
const emits = defineEmits(["destroy-characteristic"]);

// Data Properties
const editCharacteristic = ref<any>(null);
const editCharacteristicModalVisible = ref<boolean>(false);
const addCharacteristicModalVisible = ref<boolean>(false);

// Class Methods
const handleEditCharacteristic = (characteristic: any) => {
  editCharacteristic.value = characteristic;
  editCharacteristicModalVisible.value = true;
};

const confirmDeleteCharacteristic = (characteristic: any) => {
  const mod = window.confirm("Are you sure you want to delete this characteristic?");
  if (mod) {
    emits("destroy-characteristic", characteristic.id);
  }
};
</script>
