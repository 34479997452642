import {
  KnowledgeDomainPayloadInterface,
  CharacteristicPayloadInterface,
  CoursePayloadInterface,
} from "../types";
import axios from "@/network";

export function apiGetKnowledgeDomains() {
  const url = "/knowledge_domains";
  return axios.get(url);
}

export function apiStoreKnowledgeDomain(newKnowledgeDomain: KnowledgeDomainPayloadInterface) {
  const url = "/knowledge_domains";
  const payload = { knowledge_domain: newKnowledgeDomain };
  return axios.post(url, payload);
}

export function apiUpdateKnowledgeDomain(updatedKnowledgeDomain: KnowledgeDomainPayloadInterface) {
  const url = `/knowledge_domains/${updatedKnowledgeDomain.id}`;
  const payload = { knowledge_domain: updatedKnowledgeDomain };
  return axios.patch(url, payload);
}

export function apiDestroyKnowledgeDomain(knowledgeDomainId: number) {
  const url = `/knowledge_domains/${knowledgeDomainId}`;
  return axios.delete(url);
}

export function apiStoreKnowledgeDomainSkill(knowledgeDomainSkill: any) {
  const url = `/knowledge_domains/${knowledgeDomainSkill.knowledgeDomainId}/skills`;
  const payload = {
    knowledge_domain_skill: { skill_id: knowledgeDomainSkill.skillId },
  };
  return axios.post(url, payload);
}

export function apiDestroyKnowledgeDomainSkill(
  knowledgeDomainId: number,
  knowledgeDomainSkillId: number
) {
  const url = `/knowledge_domains/${knowledgeDomainId}/skills/${knowledgeDomainSkillId}`;
  return axios.delete(url);
}

export function apiGetCourses() {
  const url = "/courses";
  return axios.get(url);
}

export function apiStoreCourse(newCourse: CoursePayloadInterface) {
  const url = "/courses";
  const payload = { course: newCourse };
  return axios.post(url, payload);
}

export function apiUpdateCourse(updatedCourse: CoursePayloadInterface) {
  const url = `/courses/${updatedCourse.id}`;
  const payload = { course: updatedCourse };
  return axios.patch(url, payload);
}

export function apiDestroyCourse(courseId: number) {
  const url = `/courses/${courseId}`;
  return axios.delete(url);
}

export function apiGetCharacteristics() {
  const url = "/characteristics";
  return axios.get(url);
}

export function apiStoreCharacteristic(newCharacteristic: CharacteristicPayloadInterface) {
  const url = "/characteristics";
  const payload = { characteristic: newCharacteristic };
  return axios.post(url, payload);
}

export function apiUpdateCharacteristic(updatedCharacteristic: CharacteristicPayloadInterface) {
  const url = `/characteristics/${updatedCharacteristic.id}`;
  const payload = { characteristic: updatedCharacteristic };
  return axios.patch(url, payload);
}

export function apiDestroyCharacteristic(characteristicId: number) {
  const url = `/characteristics/${characteristicId}`;
  return axios.delete(url);
}
