<template>
  <div v-show="visible">
    <h3>Profile</h3>

    <p>This step consists in filling out some useful information about yourself.</p>
    <p>Head on over to your profile and fill out any missing fields.</p>

    <div v-for="field in profileFields" :key="field.name">
      <div :class="showMissingFieldErrors && !field.completed && !field.optional ? 'text-red' : ''">
        <a-icon v-if="!field.completed" type="close-circle" class="ml-3 mr-2" />
        <a-icon v-else type="check-circle" class="ml-3 mr-2" />
        {{ field.name }} <em>{{ field.optional ? "(optional)" : "" }}</em>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from "vue";
import { ProfileInterface } from "@/modules/authentication/types";
import { ProfileField } from "../_utils/types";

// Props
const props = defineProps({
  visible: { type: Boolean, default: false },
  user: { type: Object as () => ProfileInterface, default: () => ({}) },
  showMissingFieldErrors: { type: Boolean, default: false },
});

// Emits
const emits = defineEmits(["reload-user", "update"]);

// Data Properties
const profileFields = ref<Array<ProfileField>>([]);

// Lifecycle hooks
onMounted(() => {
  emits("reload-user");
});

// Watchers
watch(
  () => props.user,
  (newProp) => {
    if (newProp) {
      setupProfileInformation();
      updateStepCompleteness();
    }
  }
);

// Class methods
const setupProfileInformation = (): void => {
  profileFields.value = [];
  profileFields.value.push({
    name: "Address",
    optional: false,
    completed: checkFieldPresence(props.user.address),
  });
  profileFields.value.push({
    name: "Phone number",
    optional: false,
    completed: checkFieldPresence(props.user.phone_number),
  });
  profileFields.value.push({
    name: "Date of birth",
    optional: true,
    completed: checkFieldPresence(props.user.birth_date),
  });
  profileFields.value.push({
    name: "Bitbucket",
    optional: true,
    completed: checkFieldPresence(props.user.bitbucket),
  });
  profileFields.value.push({
    name: "GitHub",
    optional: true,
    completed: checkFieldPresence(props.user.github),
  });
  profileFields.value.push({
    name: "Skype",
    optional: true,
    completed: checkFieldPresence(props.user.skype),
  });
  profileFields.value.push({
    name: "Private email",
    optional: true,
    completed: checkFieldPresence(props.user.private_email),
  });
  profileFields.value.push({
    name: "T-shirt size",
    optional: true,
    completed: checkFieldPresence(props.user.tshirt_size),
  });
};

const checkFieldPresence = (field: string | null): boolean => {
  return (field?.length ?? 0) > 0;
};

const checkIfAllFieldsAreFilledIn = (): boolean => {
  let allFieldsFilledIn: boolean = true;

  if (profileFields.value) {
    for (let field of profileFields.value) {
      allFieldsFilledIn = allFieldsFilledIn && (field.completed || field.optional);
    }
  } else {
    allFieldsFilledIn = false;
  }

  return allFieldsFilledIn;
};

const updateStepCompleteness = (): void => {
  const isComplete = checkIfAllFieldsAreFilledIn();
  emits("update", isComplete);
};
</script>
