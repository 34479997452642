<template>
  <div class="card">
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h2 class="mb-3">Profile Description</h2>
      <div class="d-flex justify-content-end">
        <a-tooltip v-if="editEnabled && (acl.isOwner || acl.isAdmin)">
          <template #title> Edit profile description </template>
          <a-icon type="edit" class="action-default" @click="editingProfileDescription = true" />
        </a-tooltip>
      </div>
    </div>

    <a
      v-if="!disableDescriptionBot && (editingProfileDescription || !user.profile_description)"
      class="d-flex mb-3"
      :href="getDescriptionBotLink()"
      target="_blank"
      >Get help writing a description</a
    >

    <!-- EDITABLE TEXT AREA -->
    <edit-text-area
      v-if="editingProfileDescription"
      :text="user.profile_description"
      :reset-text="user.profile_description"
      @save="(text) => handleSaveProfileDescription(text)"
      @cancel="editingProfileDescription = false"
    />

    <div v-else>
      <!-- PARSED PROFILE DESCRIPTION -->
      <p v-if="user.profile_description" class="mb-3">{{ user.profile_description }}</p>
      <p v-if="!user.profile_description" class="d-flex justify-content-center my-3">
        <em>No description</em>
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import { ProfileInterface, BaseAclInterface } from "@/modules/authentication/types";
import EditTextArea from "../../common/_components/EditTextArea.vue";

const disableDescriptionBot = import.meta.env.VITE_DISABLE_DESCRIPTION_BOT;
// Props
const props = defineProps({
  user: { type: Object as () => ProfileInterface, default: undefined },
  acl: { type: Object as () => BaseAclInterface, default: undefined },
  editEnabled: { type: Boolean, default: true },
});

// Emits
const emits = defineEmits(["update-profile"]);

// Data Properties
const editingProfileDescription = ref<boolean>(false);
const newProfile = ref<any>({
  profile_description: "",
});

// Life-cycle Hooks
onMounted(() => {
  newProfile.value.profile_description = props.user.profile_description;
});

const handleSaveProfileDescription = (description: string): void => {
  editingProfileDescription.value = false;
  newProfile.value.profile_description = description;
  emits("update-profile", newProfile.value);
};

const getDescriptionBotLink = (): string => {
  return `https://role-describer.ai.skry.se/?user_id=${props.user.id}&embed=true`;
};
</script>
