<template>
  <div>
    <div v-if="filterPanelVisible" class="card mb-2">
      <a-form layout="vertical">
        <bookmarked-filters-selection
          :bookmarked-filters="bookmarkedFilters"
          :filter-is-empty="filterIsEmpty"
          bookmark-type="RecurringJobs"
          class="ml-3"
          @selected="(bookmarkFilterUrl) => handleCustomFilterSelected(bookmarkFilterUrl)"
          @create-bookmark-filter="handleCreateBookmarkFilter"
          @update-bookmark-filter="handleUpdateBookmarkFilter"
          @delete-bookmark-filter="handleDeleteBookmarkFilter"
        />
      </a-form>

      <a-form layout="vertical">
        <a-row class="mb-2 ml-3">
          <a-col :lg="11">
            <a-form-item
              :label-col="{ span: 10 }"
              :wrapper-col="{ span: 16 }"
              label="Date"
              class="pb-0 mb-0"
            >
              <a-date-picker
                :value="parseDate(newFilter.start_date)"
                placeholder="Start Date"
                class="w-100 mb-2"
                @change="(date) => onDateChange(date, 'start_date')"
              />
              <a-date-picker
                :value="parseDate(newFilter.end_date)"
                :disabled-date="(current) => disabledEndDate(current)"
                placeholder="End Date"
                class="w-100 mb-2"
                @change="(date) => onDateChange(date, 'end_date')"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="11">
            <a-form-item :label-col="{ span: 10 }" :wrapper-col="{ span: 16 }" label="Status">
              <a-select
                v-model="newFilter.status"
                allow-clear
                mode="multiple"
                placeholder="Select status"
                @change="applyFilters"
              >
                <a-select-option
                  v-for="(status, index) in statuses"
                  :key="index.toString()"
                  :value="status"
                >
                  {{ status.toUpperCase() }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row type="flex" class="ml-3">
          <a-col :lg="11">
            <a-form-item :label-col="{ span: 10 }" :wrapper-col="{ span: 16 }" label="Title">
              <a-input v-model="newFilter.title" placeholder="Search title" @change="searchTitle" />
            </a-form-item>
          </a-col>

          <a-col :lg="11">
            <a-form-item :label-col="{ span: 10 }" :wrapper-col="{ span: 16 }" label="Type">
              <a-select
                v-model="newFilter.type"
                allow-clear
                mode="multiple"
                placeholder="Select type"
                @change="applyFilters"
              >
                <a-select-option
                  v-for="(type, index) in types"
                  :key="index.toString()"
                  :value="type"
                  class="text-capitalize"
                >
                  <span class="text-capitalize">{{ type.split("-").join(" ") }}</span>
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>

      <a-row type="flex" justify="end">
        <span>
          <strong>Results:</strong>
          {{ numberOfResults }}
        </span>
      </a-row>
    </div>

    <portal to="recurring-jobs-filter-buttons">
      <a-button
        v-if="filterPanelVisible"
        class="mr-2"
        icon="close"
        type="danger"
        @click="() => clearFilters()"
      />
      <a-button
        v-show="filterPanelVisible"
        class="mr-2"
        type="primary"
        icon="up"
        @click="() => toggleFilterPanel()"
      />
      <a-button
        v-if="!filterPanelVisible"
        class="mr-2"
        type="primary"
        icon="filter"
        @click="() => toggleFilterPanel()"
      />
    </portal>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, getCurrentInstance } from "vue";
import BookmarkedFiltersSelection from "../../bookmarked_filters/_components/BookmarkedFiltersSelection.vue";
import { BookmarkedFilterInterface } from "@/modules/bookmarked_filters/types";
import { ProfileInterface } from "../../authentication/types";
import { ReccuringJobsFilterInterface } from "../types";
import {
  apiGetUserBookmarkedFilters,
  apiStoreUserBookmarkedFilter,
  apiUpdateUserBookmarkedFilter,
  apiDestroyUserBookmarkedFilter,
} from "@/modules/bookmarked_filters/_utils/api";
import useMixin from "@/useMixin";
import moment from "@/date";
import useFilters from "@/useFilters";

// Props
const props = defineProps({
  filters: { type: Object as () => ReccuringJobsFilterInterface, default: undefined },
  profile: { type: Object as () => ProfileInterface, default: undefined },
  numberOfResults: { type: Number, default: 0 },
  types: { type: Array as () => Array<string>, default: () => [] },
});

// Emits
const emits = defineEmits(["apply-filters"]);

// Mixins
const { qsDecode, setObject } = useMixin();

// Filters
const { parseDate } = useFilters();

// Instances
const instance = getCurrentInstance();
const $message = instance?.proxy.$message;

// Data Properties
const filterPanelVisible = ref<boolean>(false);
const newFilter = ref<ReccuringJobsFilterInterface>({
  title: undefined,
  start_date: undefined,
  end_date: undefined,
  type: [],
  status: undefined,
});
const statuses = ref<Array<string>>(["unprocessed", "succeeded", "failed", "inactive"]);
const bookmarkedFilters = ref<Array<BookmarkedFilterInterface>>([]);

// Computed Properties
const filterIsEmpty = computed(() => {
  return (
    newFilter.value.title === undefined &&
    newFilter.value.start_date === undefined &&
    newFilter.value.end_date === undefined &&
    !newFilter.value.type?.length &&
    newFilter.value.status === undefined
  );
});

// Watchers
watch(filterPanelVisible, (newProp, oldProp) => {
  if (newProp && !oldProp) {
    handleGetBookmarkedFilters();
    setFilter(props.filters);
  }
});

// Component Methods
const toggleFilterPanel = (): void => {
  filterPanelVisible.value = !filterPanelVisible.value;
};

const handleCustomFilterSelected = (bookmarkFilterUrl: string): void => {
  handleClearRecurringJobsFilter();
  if (bookmarkFilterUrl) {
    emits("apply-filters", qsDecode(bookmarkFilterUrl).props.filters);
  } else {
    applyFilters();
  }
};

const applyFilters = () => {
  emits("apply-filters", filterIsEmpty.value ? {} : newFilter.value);
};

const disabledEndDate = (endValue: any): boolean => {
  const startValue =
    typeof newFilter.value.start_date == "string" ? moment(newFilter.value.start_date) : moment("");
  if (!endValue || !startValue) {
    return false;
  }
  return startValue.valueOf() >= endValue.valueOf();
};

const onDateChange = (date: any, type: string): void => {
  if (type === "start_date") {
    newFilter.value.start_date = date == undefined ? undefined : date.format("YYYY-MM-DD");
  } else if (type === "end_date") {
    newFilter.value.end_date = date == undefined ? undefined : date.format("YYYY-MM-DD");
  }
  applyFilters();
};

const onCheckboxChange = (field: string, event: any): void => {
  newFilter.value[field] = event.target.checked ? true : undefined;
  applyFilters();
};

const searchTitle = ({ target: { value } }: { target: { value: any } }): void => {
  if (value === "") newFilter.value.title = undefined;
  applyFilters();
};

const clearFilters = (): void => {
  handleClearRecurringJobsFilter();
  emits("apply-filters", {});
};

const handleClearRecurringJobsFilter = (): void => {
  newFilter.value = {
    title: undefined,
    start_date: undefined,
    end_date: undefined,
    type: [],
    status: undefined,
  };
};

const setFilter = (filter: any): void => {
  if (filter === undefined) return;
  newFilter.value = setObject(newFilter.value, filter);
};

const handleGetBookmarkedFilters = (): void => {
  apiGetUserBookmarkedFilters(props.profile.id).then(
    ({ data }: any) => (bookmarkedFilters.value = data.data)
  );
};

const handleCreateBookmarkFilter = (bookmarkFilter: any): void => {
  apiStoreUserBookmarkedFilter(props.profile.id, bookmarkFilter)
    .then(({ data }: any) => bookmarkedFilters.value.push(data.data))
    .then(() => $message?.success("New filter created successfully!", 3))
    .catch(() => $message?.error("Couldn't create new filter!", 3));
};

const handleUpdateBookmarkFilter = (bookmarkFilterId: number, bookmarkFilter: any): void => {
  apiUpdateUserBookmarkedFilter(props.profile.id, bookmarkFilterId, bookmarkFilter)
    .then(
      ({ data }: any) =>
        (bookmarkedFilters.value = bookmarkedFilters.value.map((f: any) =>
          f.id === data.data.id ? data.data : f
        ))
    )
    .then(() => $message?.success("Filter updated successfully!", 3))
    .catch(() => $message?.error("Couldn't update filter!", 3));
};

const handleDeleteBookmarkFilter = (bookmarkFilterId: number) => {
  apiDestroyUserBookmarkedFilter(props.profile.id, bookmarkFilterId)
    .then(
      () =>
        (bookmarkedFilters.value = bookmarkedFilters.value.filter(
          (f: any) => f.id !== bookmarkFilterId
        ))
    )
    .then(() => $message?.success("Filter removed successfully!", 3))
    .catch(() => $message?.error("Couldn't remove filter!", 3));
};
</script>
