import { LinkPayloadInterface } from "../types";
import axios from "@/network";

export function apiGetLinks() {
  const url = "/useful_links";
  return axios.get(url);
}

export function apiStoreLink(newLink: LinkPayloadInterface) {
  const url = "/useful_links";
  const payload = { useful_link: newLink };
  return axios.post(url, payload);
}

export function apiDestroyLink(linkId: number) {
  const url = `/useful_links/${linkId}`;
  return axios.delete(url);
}
