import { parseDate, toHumanDate, durationBetweenDates } from "./filters";

export default function useFilters() {
  parseDate;
  toHumanDate;
  durationBetweenDates;

  return {
    parseDate,
    toHumanDate,
    durationBetweenDates,
  };
}
