<template>
  <div class="card justify-content-center m-2 mt-3">
    <v-date-picker
      ref="calendar"
      v-model="date"
      class="calendar"
      :attributes="attributes"
      :first-day-of-week="2"
      :disabled-dates="disabledDays"
      :timezone="timezone"
      is-expanded
      is-required
      @dayclick="handleDayClick"
      @update:to-page="(data) => handleNewMonth(data)"
    />

    <a-list :data-source="dailyReportEvents" size="small" bordered class="mt-3">
      <template #header>
        <strong>{{ `${formatDate(selectedDay)} Reports` }}</strong>
      </template>
      <template #renderItem="report">
        <a-list-item v-if="eventOnSelectedDate(report)">
          <div class="flex-center-between">
            <div
              v-if="report.type == ReportType.TIME"
              style="background-color: #38a169; width: 5px; height: 5px; border-radius: 5px"
            ></div>
            <div
              v-if="report.type == ReportType.SICK"
              style="background-color: #ae28e2; width: 5px; height: 5px; border-radius: 5px"
            ></div>
            <div
              v-if="report.type == ReportType.PARENTAL"
              style="background-color: #fdbfc6; width: 5px; height: 5px; border-radius: 5px"
            ></div>
            <div
              v-if="report.type == ReportType.ABSENCE || report.type == ReportType.VACATION"
              style="background-color: #f9e75e; width: 5px; height: 5px; border-radius: 5px"
            ></div>
            <div
              v-if="report.type == PseudoReportType.LOCKING"
              style="background-color: #e53e3e; width: 5px; height: 5px; border-radius: 5px"
            ></div>
            <div class="ml-2 mr-0">{{ report.label }}</div>
          </div>
        </a-list-item>
      </template>
    </a-list>

    <a-list v-if="reportsSummary" :data-source="reportsSummary" size="small" bordered class="mt-3">
      <template #header>
        <strong>Month at a glance</strong>
      </template>
      <template #renderItem="item">
        <a-list-item>
          <div v-if="item.item === 'sick'" class="flex-center-between w-100">
            <div>
              <div>Sick leave</div>
            </div>
            <a-tag :color="Colors.purple" class="mr-0">
              {{ item.amount }} day{{ item.amount > 1 ? "s" : "" }}
            </a-tag>
          </div>
          <div v-else-if="item.item === 'vacation'" class="flex-center-between w-100">
            <div>
              <div>Vacation</div>
            </div>
            <a-tag :color="Colors.lemon" class="mr-0">
              <div style="color: black">{{ item.amount }} day{{ item.amount > 1 ? "s" : "" }}</div>
            </a-tag>
          </div>
          <div v-else-if="item.item === 'absence'" class="flex-center-between w-100">
            <div>
              <div>Absence</div>
            </div>
            <a-tag :color="Colors.lemon" class="mr-0">
              <div style="color: black">{{ item.amount }} day{{ item.amount > 1 ? "s" : "" }}</div>
            </a-tag>
          </div>
          <div v-else-if="item.item === 'parental'" class="flex-center-between w-100">
            <div>
              <div>Parental leave</div>
            </div>
            <a-tag :color="Colors.solitude" class="mr-0">
              <div style="color: black">{{ item.amount }} day{{ item.amount > 1 ? "s" : "" }}</div>
            </a-tag>
          </div>
          <div v-else-if="item.item === 'public-holiday'" class="flex-center-between w-100">
            <div>
              <div>Public holidays</div>
            </div>
            <a-tag :color="Colors.red" class="mr-0">
              {{ item.amount }} day{{ item.amount > 1 ? "s" : "" }}
            </a-tag>
          </div>
          <div v-else class="w-100">
            <div class="flex-center-between">
              <p>{{ item.item.title }} ({{ item.project.name }})</p>
              <div class="mr-2">
                {{ formatHours(item.amount) }}
                {{ item.expected !== undefined ? `/ ${formatHours(item.expected)}` : "" }}
              </div>
            </div>
            <p class="clientText">{{ item.project.client.name }}</p>
          </div>
        </a-list-item>
      </template>
    </a-list>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, computed } from "vue";
import { computePublicHolidayEndDate, computeNbBusinessDays } from "../_utils/public-holidays";
import { ProfileInterface } from "@/modules/authentication/types";
import { computeExpectedHours } from "../_utils/expected-hours";
import { RoleSmallInterface } from "@/modules/roles/types";
import Colors from "@/assets/scss/_colors.module.scss";
import {
  ReportInterface,
  CalendarDate,
  CalendarHighlightInterface,
  ReportType,
  CalendarInfoInterface,
  DailyReportEvents,
  PseudoReportType,
  VCalendar,
  PublicHolidayInterface,
  ReportPayloadInterface,
} from "../types";
import moment from "@/date";
import { Moment } from "moment";

// Props
const props = defineProps({
  user: { type: Object as () => ProfileInterface, default: undefined },
  calendarReports: { type: Map, default: () => [] },
  publicHolidays: { type: Map, default: () => [] },
  selectedDay: { type: String, default: undefined },
});

// Emits
const emits = defineEmits(["get-month", "day-clicked"]);

// Data properties
const monthKey = ref<any>(moment().format("YYYY.M"));
const date = ref(moment().format("YYYY-MM-DD"));
const calendar = ref<VCalendar | null>(null);
const timezone = ref("UTC");

// Watchers
watch(
  () => props.selectedDay,
  (newProp) => {
    date.value = newProp;
    calendar.value?.focusDate(newProp);
  }
);

// Computed properties
const attributes = computed((): Array<any> => {
  return [
    // Today
    {
      key: "today",
      content: {
        style: {
          color: "black",
          "font-weight": "700 !important",
        },
      },
      dates: new Date(),
    },
    // Formatting for locked days and last locking date
    ...lockedDays.value,

    // Formatting for weekends
    ...weekends.value,

    // Formatting for days with reports information
    ...reportEvents.value,

    // Formatting for public holidays
    ...publicHolidayEvents.value,
  ];
});

const disabledDays = computed(() => {
  return lockingDate.value
    ? {
        end: new Date(lockingDate.value),
      }
    : false;
});

const reportEvents = computed((): Array<CalendarInfoInterface> => {
  let monthReports = props.calendarReports.get(monthKey.value) as any;
  if (monthReports === undefined) return [];

  return [
    ...monthReports?.map((r: ReportInterface) => {
      const info: CalendarInfoInterface = {
        dates: reportDates(r),
        highlight: reportHighlight(r),
      };

      if (r.type === ReportType.SICK) {
        info.content = { style: { color: "white" } };
        const isOnSameDayAsSelectedDay = moment(props.selectedDay).isSame(
          moment(r.start_date),
          "date"
        );
        const monthPublicHolidays = props.publicHolidays.get(monthKey.value) as any;
        const isHiddenByPublicHoliday =
          monthPublicHolidays &&
          monthPublicHolidays.some(
            (ph: PublicHolidayInterface) =>
              new Date(ph.date).getTime() === new Date(r.start_date!).getTime()
          );
        if (isOnSameDayAsSelectedDay || isHiddenByPublicHoliday) {
          info.dot = { color: "purple" };
        }
      }

      if (r.type === ReportType.TIME) {
        info.dot = { color: "green" };
      }

      if (r.type === ReportType.EXPENSE) {
        return;
      }

      return info;
    }),
  ];
});

const weekends = computed((): Array<CalendarInfoInterface> => {
  return [
    {
      dates: { weekdays: [1, 7] },
      content: { style: { color: "black", opacity: 0.4 } },
    },
  ];
});

const publicHolidayEvents = computed((): Array<CalendarInfoInterface> => {
  let monthPublicHolidays = props.publicHolidays.get(monthKey.value) as any;
  if (monthPublicHolidays === undefined) return [];

  return [
    ...monthPublicHolidays.map((ph: PublicHolidayInterface) => {
      const info: CalendarInfoInterface = {
        dates:
          ph.duration > 1
            ? { start: new Date(ph.date), end: computePublicHolidayEndDate(ph) }
            : new Date(ph.date),
        highlight: {
          color: "red",
          style: {
            borderColor: Colors.red,
            backgroundColor: Colors.red,
            borderWidth: "2px",
            borderTopStyle: "solid",
          },
        },
        content: { style: { color: "white" } },
      };
      return info;
    }),
  ];
});

const dailyReportEvents = computed((): Array<DailyReportEvents> => {
  let monthReports = props.calendarReports.get(monthKey.value) as any;
  if (monthReports === undefined) return [];

  let dailyEvents = [
    ...monthReports?.map((r: ReportInterface) => {
      const info = {
        type: r.type,
        date: {
          start: new Date(r.start_date ?? ""),
          end: r.end_date ? new Date(r?.end_date ?? "") : undefined,
        },
        label: reportLabel(r),
      };
      if (r.type == ReportType.EXPENSE) return;
      return info;
    }),
  ].filter((el) => el);
  dailyEvents.push({
    type: "locking",
    date: { start: new Date(lockingDate.value) },
    label: "Latest locking date",
  });
  return dailyEvents;
});

const lockedDays = computed((): Array<any> => {
  if (!hasLockingDate.value) return [];

  return [
    {
      highlight: {
        style: {
          backgroundColor: Colors.grey,
          borderColor: Colors.grey,
          borderWidth: "2px",
          borderStyle: "solid",
        },
      },
      dates: [
        {
          end: new Date(lockingDate.value),
        },
      ],
    },
    {
      dot: { color: "red" },
      dates: new Date(lockingDate.value),
    },
  ];
});

const hasLockingDate = computed((): any => {
  return props.user && props.user.report_locking_date;
});

const lockingDate = computed((): any => {
  return hasLockingDate.value ? props.user.report_locking_date : undefined;
});

const reportsSummary = computed((): any => {
  interface ReportSummary {
    item: string;
    amount: number;
    expected?: number;
  }

  if (!props.calendarReports.has(monthKey.value)) return [];
  const reports = props.calendarReports.get(monthKey.value) as any;
  let summaryMap = new Map();
  let sickSummary: ReportSummary | undefined;
  let vacationSummary: ReportSummary | undefined;
  let absenceSummary: ReportSummary | undefined;
  let parentalSummary: ReportSummary | undefined;
  let publicHolidaySummary: ReportSummary | undefined;

  const monthlyPublicHolidays = (props.publicHolidays.get(monthKey.value) as any) || [];

  reports.forEach((report: ReportInterface) => {
    if (report.type === ReportType.TIME) {
      // Add role to map if not there yet.
      if (report.role != undefined && !summaryMap.has(report.role.id)) {
        summaryMap.set(report.role.id, {
          item: { ...report.role },
          project: { ...report.project },
          amount: 0,
        });
      }

      // Update role data in map.
      if (report.role) {
        let item = summaryMap.get(report.role.id);
        item.amount += report.amount;
        item.expected = computeExpectedHoursForRole(
          report.role,
          reports,
          monthlyPublicHolidays as PublicHolidayInterface[]
        );
      }
    } else if (report.type === ReportType.SICK) {
      if (!sickSummary) sickSummary = { item: "sick", amount: 0 };
      sickSummary.amount += 1;
    } else if (report.type === ReportType.VACATION) {
      if (!vacationSummary) vacationSummary = { item: "vacation", amount: 0 };
      vacationSummary.amount += computeNbMonthlyBusinessDays(report);
    } else if (report.type === ReportType.ABSENCE) {
      if (!absenceSummary) absenceSummary = { item: "absence", amount: 0 };
      absenceSummary.amount += computeNbMonthlyBusinessDays(report);
    } else if (report.type === ReportType.PARENTAL) {
      if (!parentalSummary) parentalSummary = { item: "parental", amount: 0 };
      parentalSummary.amount += computeNbMonthlyBusinessDays(report);
    }
  });

  monthlyPublicHolidays.forEach((ph: PublicHolidayInterface) => {
    if (!publicHolidaySummary) {
      publicHolidaySummary = { item: "public-holiday", amount: 0 };
    }
    publicHolidaySummary.amount += ph.duration;
  });

  // Cast to array and sort by amount roles summary
  let timeSummary = Array.from(summaryMap.values()).sort((a, b) => b.amount - a.amount);

  // If there are sick/parental/vacation/absence reports add to array
  if (sickSummary) timeSummary.unshift(sickSummary);
  if (parentalSummary) timeSummary.unshift(parentalSummary);
  if (vacationSummary) timeSummary.unshift(vacationSummary);
  if (absenceSummary) timeSummary.unshift(absenceSummary);
  if (publicHolidaySummary) timeSummary.unshift(publicHolidaySummary);

  return timeSummary;
});

// Component Methods
const handleNewMonth = (object: { year: number; month: number }): void => {
  const objectKey = moment({ year: object.year, month: object.month - 1 }).format("YYYY.M");
  monthKey.value = objectKey;
  emits("get-month", objectKey);
};

const handleDayClick = (e: any): void => {
  emits("day-clicked", formatDate(e.date));
};

const reportLabel = (r: ReportInterface): string => {
  switch (r.type) {
    case ReportType.TIME:
      if (r.role && r.comment)
        return `[${r.amount}h] ${r.role.title} (${r.project.name})  ${r.project.client.name} "${r.comment}"`;

      if (r.role)
        return `[${r.amount}h] ${r.role.title} (${r.project.name})  ${r.project.client.name}`;
      return "";
    case ReportType.VACATION:
      return "Vacation";
    case ReportType.ABSENCE:
      return "Absence";
    case ReportType.SICK:
      return `Sick leave`;
    case ReportType.PARENTAL:
      return `Parental leave`;
    default:
      return "";
  }
};

const reportHighlight = (r: ReportInterface): CalendarHighlightInterface | undefined => {
  switch (r.type) {
    case ReportType.VACATION:
      return {
        color: "yellow",
        style: {
          borderColor: Colors.lemon,
          backgroundColor: Colors.lemon,
          borderWidth: "2px",
          borderTopStyle: "solid",
        },
      };
    case ReportType.ABSENCE:
      return {
        color: "yellow",
        style: {
          borderColor: Colors.lemon,
          backgroundColor: Colors.lemon,
          borderWidth: "2px",
          borderTopStyle: "solid",
        },
      };
    case ReportType.SICK:
      return {
        color: "purple",
        style: {
          borderColor: Colors.purple,
          backgroundColor: Colors.purple,
          borderWidth: "2px",
          borderTopStyle: "solid",
        },
      };
    case ReportType.PARENTAL:
      return {
        color: "solitude",
        style: {
          borderColor: Colors.solitude,
          backgroundColor: Colors.solitude,
          borderWidth: "2px",
          borderTopStyle: "solid",
        },
      };
  }
};

const reportDates = (report: ReportInterface): CalendarDate => {
  // If is a vacation/absence report add dots for whole interval otherwise
  // only on the specific date
  return report.type === ReportType.VACATION ||
    report.type === ReportType.ABSENCE ||
    report.type === ReportType.PARENTAL
    ? {
        start: new Date(report.start_date ? report.start_date : ""),
        end: typeof report.end_date == "string" ? new Date(report.end_date) : "",
      }
    : new Date(report.start_date ? report.start_date : "");
};

const eventOnSelectedDate = (report: DailyReportEvents): boolean => {
  return report.date.end
    ? moment(props.selectedDay).isBetween(report.date.start, report.date.end, "day", "[]")
    : moment(props.selectedDay).isSame(report.date.start, "D");
};

const formatHours = (h: number): string => {
  return `${h} hr${h > 1 ? "s" : ""}`;
};

const formatDate = (date: Moment | string): string => {
  // Convert to UTC to keep consistent with calendar
  return moment(date).utc().format("YYYY-MM-DD");
};

const computeExpectedHoursForRole = (
  role: RoleSmallInterface,
  reports: Array<ReportInterface>,
  monthlyPublicHolidays: Array<PublicHolidayInterface>
): number | undefined => {
  if (parseFloat(role.workload) < 0.1) {
    return undefined;
  }

  const today = new Date();
  let start = new Date(monthKey.value);
  let end: Date;

  if (start.getFullYear() === today.getFullYear() && start.getMonth() === today.getMonth()) {
    end = today;
  } else if (start < today) {
    end = new Date(start.getFullYear(), start.getMonth() + 1, 0);
  } else {
    return undefined;
  }

  if (new Date(role.start_date) > start) {
    start = new Date(role.start_date);
  }
  if (new Date(role.end_date) < end) {
    end = new Date(role.end_date);
  }

  return computeExpectedHours(
    start,
    end,
    parseFloat(role.workload),
    reports,
    monthlyPublicHolidays
  );
};

const computeNbMonthlyBusinessDays = (report: ReportPayloadInterface): number => {
  let monthPublicHolidays = props.publicHolidays.get(monthKey.value) as any;
  if (!report.start_date || !report.end_date || monthPublicHolidays === undefined) {
    return 0;
  }

  const start_of_month = new Date(monthKey.value);
  const end_of_month = new Date(start_of_month.getFullYear(), start_of_month.getMonth() + 1, 0);

  let report_start = new Date(report.start_date);
  let report_end = new Date(report.end_date);

  report_start = report_start < start_of_month ? start_of_month : report_start;
  report_end = end_of_month < report_end ? end_of_month : report_end;

  return computeNbBusinessDays(report_start, report_end, monthPublicHolidays);
};
</script>

<style lang="scss" scoped>
::v-deep .vc-day.is-not-in-month span {
  opacity: 0.3 !important;
  pointer-events: inherit !important;
}

::v-deep .vc-day-content.is-disabled {
  opacity: 0.3 !important;
  background-color: transparent !important;
  color: black !important;
}
::v-deep .vc-day-content.is-disabled:hover {
  background-color: transparent !important;
}
::v-deep .vc-day-content.is-disabled:focus {
  background-color: transparent !important;
}
::v-deep .vc-day-content:focus {
  background-color: transparent !important;
}
::v-deep .vc-day-content:hover {
  background-color: transparent !important;
  border-radius: 2px !important;
}
::v-deep .vc-day-content {
  font-weight: 400 !important;
}
.vc-container.vc-is-expanded {
  border: none !important;
}
::v-deep .vc-title {
  font-weight: 400 !important;
}
::v-deep .vc-title:hover {
  opacity: 1 !important;
}
::v-deep .vc-weekday {
  font-weight: 500 !important;
}
::v-deep .vc-bars {
  width: 30% !important;
}
</style>
