import { RouteInterface } from "@/common/types";
import QuizTags from "./_views/QuizTags.vue";

export default [
  {
    path: "quiz_tags",
    component: QuizTags,
    name: "tags",
    meta: { auth: true },
  },
] as Array<RouteInterface>;
