<template>
  <a-modal
    :visible="visible"
    :confirm-loading="false"
    :width="800"
    :body-style="{ padding: 0 }"
    title="Edit CV"
    ok-text="Update"
    @cancel="() => $emit('close')"
    @ok="validate({ key: 'updateCv' })"
  >
    <a-row v-if="loadingData" type="flex" justify="center" class="modal-body my-5">
      <a-icon type="loading" />
    </a-row>

    <a-row v-else type="flex">
      <a-col :span="6">
        <a-menu v-model="menu" mode="inline" @click="validate">
          <a-menu-item v-for="step in steps" :key="step.i.toString()">
            <a-icon :type="step.icon" />
            <span>{{ step.title }}</span>
          </a-menu-item>
        </a-menu>
      </a-col>
      <a-col :span="18">
        <div class="modal-body">
          <GeneralStep
            v-if="currentStep === steps.general.i"
            :cv="updatedCv"
            :step="steps.general.i"
            :cv-data="cvData"
            @cv-change="handleChange"
            @go-to-step="goToStep"
          />
          <KnowledgeDomainStep
            v-if="currentStep === steps.knowledge.i"
            :cv="updatedCv"
            :step="steps.knowledge.i"
            :cv-data="cvData"
            @cv-change="handleChange"
            @go-to-step="goToStep"
          />
          <SkillCategoryStep
            v-if="currentStep === steps.skillCategory.i"
            :cv="updatedCv"
            :step="steps.skillCategory.i"
            :cv-data="cvData"
            @cv-change="handleChange"
            @go-to-step="goToStep"
          />
          <SkillStep
            v-if="currentStep === steps.skill.i"
            :cv="updatedCv"
            :step="steps.skill.i"
            :cv-data="cvData"
            @cv-change="handleChange"
            @go-to-step="goToStep"
          />
          <ProjectStep
            v-if="currentStep === steps.project.i"
            :cv="updatedCv"
            :step="steps.project.i"
            :cv-data="cvData"
            @cv-change="handleChange"
            @go-to-step="goToStep"
          />
          <EducationStep
            v-if="currentStep === steps.education.i"
            :cv="updatedCv"
            :step="steps.education.i"
            :cv-data="cvData"
            @cv-change="handleChange"
            @go-to-step="goToStep"
          />
          <CourseStep
            v-if="currentStep === steps.course.i"
            :cv="updatedCv"
            :step="steps.course.i"
            :cv-data="cvData"
            @cv-change="handleChange"
            @go-to-step="goToStep"
          />
          <BrandingStep
            v-if="currentStep === steps.branding.i"
            :cv="updatedCv"
            :step="steps.branding.i"
            @cv-change="handleChange"
            @go-to-step="goToStep"
          />
        </div>
      </a-col>
    </a-row>

    <template #footer>
      <a-row type="flex" justify="space-between">
        <a-col>
          <a-button @click="() => $emit('close')">Cancel</a-button>
        </a-col>

        <a-col>
          <a-button
            :class="valid ? '' : 'ant-btn-danger'"
            @click="validate({ key: 'showDefaultCv' })"
          >
            Show Default
          </a-button>
          <a-button :class="valid ? '' : 'ant-btn-danger'" @click="validate({ key: 'previewCv' })">
            Preview
          </a-button>
          <a-button
            v-if="acl && (acl.isAdmin || acl.isCr || acl.isHr)"
            :class="valid ? '' : 'ant-btn-danger'"
            @click="validate({ key: 'updateCv' })"
          >
            Save as Default
          </a-button>
        </a-col>
      </a-row>
    </template>
  </a-modal>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import GeneralStep from "./GeneralStep.vue";
import KnowledgeDomainStep from "./KnowledgeDomainStep.vue";
import SkillStep from "./SkillStep.vue";
import ProjectStep from "./ProjectStep.vue";
import CourseStep from "./CourseStep.vue";
import BrandingStep from "./BrandingStep.vue";
import EducationStep from "./EducationStep.vue";
import { SkillCategoryInterface } from "../../skills/types";
import { CharacteristicInterface, CourseInterface } from "../../consultant_profiles/types";
import {
  CvDataInterface,
  CvInterface,
  RoleCvInterface,
  SkillCvInterface,
  WorkExperienceCvInterface,
} from "../types";
import { EducationInterface } from "../../users/types";
import { BaseAclInterface } from "../../authentication/types";
import { emitter } from "@/mitt";
import SkillCategoryStep from "@/modules/cv/_components/SkillCategoryStep.vue";

// Props
const props = defineProps({
  cv: { type: Object as () => CvInterface, default: undefined },
  cvData: { type: Object as () => CvDataInterface, default: undefined },
  acl: { type: Object as () => BaseAclInterface, default: undefined },
  visible: { type: Boolean, default: false },
  loading: { type: Boolean, default: false },
  loadingData: { type: Boolean, default: false },
});

// Emits
const emits = defineEmits(["show-default-cv", "update-preview-cv", "update-cv"]);

// Data Properties
const valid = ref<boolean>(true);
const currentStep = ref<number>(0);
const menu = ref<Array<string>>(["0"]);
const updatedCv = ref<any>({
  id: 0,
  first_title: "",
  second_title: "",
  background: "",
  motivation: "",
  characteristics: [],
  knowledge_domains: [],
  skill_categories: [],
  work_experiences: [],
  skills: [],
  roles: [],
  courses: [],
  educations: [],
});

const steps = {
  general: {
    i: 0,
    icon: "form",
    title: "General",
  },
  knowledge: {
    i: 1,
    icon: "file-text",
    title: "Knowledge Domains",
  },
  skillCategory: {
    i: 2,
    icon: "container",
    title: "Skill Categories",
  },
  skill: {
    i: 3,
    icon: "tool",
    title: "Skills",
  },
  project: {
    i: 4,
    icon: "rocket",
    title: "Projects",
  },
  education: {
    i: 5,
    icon: "bank",
    title: "Educations",
  },
  course: {
    i: 6,
    icon: "book",
    title: "Courses",
  },
  branding: {
    i: 7,
    icon: "picture",
    title: "Branding",
  },
};

// Watchers
watch(
  () => props.visible,
  (newProp, oldProp) => {
    if (newProp && !oldProp) {
      // Initialize all fields
      updatedCv.value = JSON.parse(JSON.stringify(props.cv));
      updatedCv.value.location = props.cv.location || "";
      updatedCv.value.characteristics = updatedCv.value.characteristics.map(
        (characteristic: CharacteristicInterface) => characteristic.id
      );
      updatedCv.value.courses = updatedCv.value.courses.map((course: CourseInterface) => course.id);
      updatedCv.value.knowledge_domains = updatedCv.value.knowledge_domains.map((kd: any) => ({
        knowledge_domain_id: kd.id ? kd.id : kd.knowledge_domain_id,
        level: kd.level,
      }));
      updatedCv.value.skill_categories = updatedCv.value.skill_categories.map(
        (cat: SkillCategoryInterface) => ({ skill_category_id: cat.id })
      );
      updatedCv.value.roles = updatedCv.value.roles.map((role: RoleCvInterface) => ({
        role_id: role.id,
        hide_client_name: role.hide_client_name,
        custom_role_title: role.custom_role_title,
        custom_client_alias: role.custom_client_alias,
      }));
      updatedCv.value.work_experiences = updatedCv.value.work_experiences.map(
        (exp: WorkExperienceCvInterface) => ({
          work_experience_id: exp.id,
          custom_role_title: exp.custom_role_title,
        })
      );
      updatedCv.value.educations = updatedCv.value.educations.map((edu: EducationInterface) => ({
        education_id: edu.id,
      }));
      updatedCv.value.skills = updatedCv.value.skills.map((skill: SkillCvInterface) => ({
        ...skill,
        skill_id: skill.id ? skill.id : skill.skill_id,
        highlight: skill.highlight,
      }));
    }
  }
);

const validate = ({ key }: { key: string }): void => {
  valid.value = false;
  emitter.emit(`validate-cv-step-${currentStep.value}`, key);
  setTimeout(() => {
    menu.value = [currentStep.value.toString()]; // Dont let ant design set the menu just yet, w8 for validation
  }, 5);
};

const handleChange = (key: string, value: any): void => {
  updatedCv.value[key] = value;
};

const goToStep = (key: string): void => {
  valid.value = true;
  if (key === "showDefaultCv") {
    emits("show-default-cv");
  } else if (key === "previewCv") {
    emits("update-preview-cv", updatedCv.value);
  } else if (key === "updateCv") {
    emits("update-cv", updatedCv.value);
  } else {
    currentStep.value = parseInt(key);
    menu.value = [key]; // Here we can set the menu because validation was good
  }
};
</script>

<style scoped>
.ant-modal-body {
  padding: 0 !important;
}
.ant-menu-item {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.ant-menu-inline {
  height: 100%;
}
.modal-body {
  padding: 1rem;
}
</style>
