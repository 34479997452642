<template>
  <a-modal
    :visible="visible"
    :destroy-on-close="true"
    title="Edit Role"
    ok-text="Save"
    @ok="handleUpdateProjectRole"
    @cancel="closeModal"
  >
    <!-- INFO TAB -->
    <a-tabs default-active-key="0">
      <a-tab-pane key="0">
        <template #tab>
          <span>
            <a-badge v-show="$validator.errors.any('tab-info')" status="error" />
            Info
          </span>
        </template>

        <a-form data-vv-scope="tab-info" layout="horizontal">
          <a-form-item :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }" label="User">
            <strong>{{ roleUserName }}</strong>
          </a-form-item>
          <SkInput
            v-model="newProjectRole.title"
            v-validate="'required|max:255'"
            :error="$validator.errors.first('step-info.title')"
            data-vv-name="title"
            label="Title"
            placeholder="Role title"
          />

          <SkInput
            v-if="acl.isAdmin || acl.isCr"
            v-model="newProjectRole.agreement_link"
            :error="$validator.errors.first('step-info.agreement_link')"
            data-vv-name="agreement_link"
            label="Agreement Link"
            placeholder="Role agreement link"
          />

          <a-form-item
            :label-col="{ span: 8 }"
            :wrapper-col="{ span: 16 }"
            :validate-status="$validator.errors.first('tab-info.start_date') ? 'error' : ''"
            :help="$validator.errors.first('tab-info.start_date')"
            label="Start date"
          >
            <a-date-picker
              v-validate="'required'"
              :value="parseDate(newProjectRole.start_date)"
              class="w-100"
              data-vv-name="start_date"
              data-vv-as="start date"
              @change="(d) => onDateChange(d, 'start_date')"
            />
          </a-form-item>

          <a-form-item
            :label-col="{ span: 8 }"
            :wrapper-col="{ span: 16 }"
            :validate-status="$validator.errors.first('tab-info.end_date') ? 'error' : ''"
            :help="$validator.errors.first('tab-info.end_date')"
            label="End date"
          >
            <a-date-picker
              v-validate="'required'"
              :value="parseDate(newProjectRole.end_date)"
              class="w-100"
              data-vv-name="end_date"
              data-vv-as="end date"
              @change="(d) => onDateChange(d, 'end_date')"
            />
          </a-form-item>

          <SkInput
            v-model="newProjectRole.notice_period"
            v-validate="'required|integer|min_value:0'"
            :error="$validator.errors.first('step-info.notice_period')"
            data-vv-name="notice_period"
            data-vv-as="notice period"
            label="Notice Period"
            placeholder="Notice period in days"
          />

          <SkInput
            v-model="newProjectRole.workload"
            v-validate="'required|integer|min_value:0'"
            :error="$validator.errors.first('step-info.workload')"
            data-vv-name="workload"
            label="Workload"
            placeholder="Workload in percentages"
            addon-after="%"
          />

          <a-form-item :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }" label="Description">
            <a-textarea v-model="newProjectRole.description" :auto-size="{ minRows: 3 }" />
            <a
              v-if="!disableDescriptionBot && props.role"
              :href="getDescriptionBotLink()"
              target="_blank"
              >Get help writing a description</a
            >
          </a-form-item>

          <a-form-item
            v-if="acl.isAdmin"
            :label-col="{ span: 8 }"
            :wrapper-col="{ span: 16 }"
            label="Hide in CV selection"
          >
            <a-checkbox v-model="newProjectRole.disable_cv" />
          </a-form-item>
          <a-form-item :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }" label="Time reporting">
            <div v-if="!(subprojects.length > 0)">
              <a-radio-group
                v-if="acl.isAdmin"
                v-model="newProjectRole.automatic_time_reporting"
                button-style="solid"
                @change="(el) => (newProjectRole.automatic_time_reporting = el.target.value)"
              >
                <a-radio-button :value="false">Manual</a-radio-button>
                <a-radio-button :value="true">Automatic</a-radio-button>
              </a-radio-group>
              <a-tag v-else color="blue">
                {{ newProjectRole.automatic_time_reporting ? "Automatic" : "Manual" }}
              </a-tag>
            </div>
            <div v-else>
              <span>Must be manual for projects with subprojects</span>
            </div>
          </a-form-item>
        </a-form>
      </a-tab-pane>

      <!-- FINANCIAL TAB -->
      <a-tab-pane v-if="acl.isAdmin || acl.isFinancial || acl.isProjectManager || acl.isCr" key="1">
        <template #tab>
          <span>
            <a-badge v-show="$validator.errors.any('tab-financial')" status="error" />
            Financial
          </span>
        </template>
        <a-form data-vv-scope="tab-financial" layout="horizontal">
          <SkMoney
            v-model="newProjectRole.hourly_rate_cents"
            v-validate="'required|decimal|min_value:0'"
            :error="$validator.errors.first('step-financial.hourly_rate')"
            :currency="newProjectRole.hourly_rate_currency"
            data-vv-name="hourly_rate"
            data-vv-as="hourly rate"
            label="Hourly rate"
            placeholder="Hourly rate"
            @set-currency="(v) => (newProjectRole.hourly_rate_currency = v)"
          />

          <SkMoney
            v-model="newProjectRole.future_hourly_rate_cents"
            v-validate="'required|decimal|min_value:0'"
            :error="$validator.errors.first('step-financial.future_hourly_rate')"
            :currency="newProjectRole.future_hourly_rate_currency"
            data-vv-name="future_hourly_rate"
            data-vv-as="future hourly rate"
            label="Future Hourly rate"
            placeholder="Future Hourly rate"
            @set-currency="(v) => (newProjectRole.future_hourly_rate_currency = v)"
          />

          <a-form-item
            :label-col="{ span: 8 }"
            :wrapper-col="{ span: 16 }"
            label="Future hourly date"
          >
            <a-date-picker
              :value="parseDate(newProjectRole.future_hourly_rate_date)"
              class="w-100"
              @change="(d) => onDateChange(d, 'future_hourly_rate_date')"
            />
          </a-form-item>

          <a-form-item
            :label-col="{ span: 8 }"
            :wrapper-col="{ span: 16 }"
            :validate-status="
              $validator.errors.first('tab-financial.cost_center_id') ? 'error' : ''
            "
            :help="$validator.errors.first('tab-financial.cost_center_id')"
            label="Cost center"
          >
            <a-select
              v-model="newProjectRole.cost_center_id"
              v-validate="'required'"
              :default-value="newProjectRole.cost_center_id"
              placeholder="Select cost center"
              data-vv-name="cost_center_id"
              data-vv-as="cost center"
            >
              <a-select-option v-for="cc in costCenters" :key="cc.id">
                {{ cc.name }} ({{ cc.manager.name }})
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-form>
      </a-tab-pane>

      <!-- SKILLS TAB -->
      <a-tab-pane key="2">
        <template #tab>
          <span>
            <a-badge v-show="$validator.errors.any('tab-skills')" status="error" />
            Skills
          </span>
        </template>
        <a-form data-vv-scope="tab-skills" layout="horizontal">
          <a-form-item :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }" label="Skills">
            <a-select
              v-model="newProjectRole.skill_ids"
              mode="multiple"
              show-search
              placeholder="Select skills"
              option-filter-prop="children"
            >
              <a-select-option
                v-for="s in skills"
                :key="s.id"
                :class="{ 'already-in': !!s.skill_id }"
              >
                {{ s.title }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-form>
      </a-tab-pane>
    </a-tabs>
    <a-modal
      :visible="showWarningVisible"
      ok-text="Yes, save"
      ok-type="danger"
      @ok="continueAfterWarning"
      @cancel="cancelAfterWarning"
    >
      <template #title>
        <span>
          <a-icon type="warning" class="mr-2" />
          Warning
        </span>
      </template>
      You are about to update a role for which an extension exists. This isn't recommended.
      <br />
      Are you sure you want to follow through with the update?
    </a-modal>
  </a-modal>
</template>

<script setup lang="ts">
import { ref, watch, toRef, getCurrentInstance } from "vue";
import { hasPendingExtension } from "../../roles/_utils/role-extension";
import { CostCenterInterface } from "@/modules/cost_centers/types";
import { BaseAclInterface } from "@/modules/authentication/types";
import { ProjectInterface, NewSubprojectInterface } from "../../projects/types";
import { SkillInterface } from "@/modules/skills/types";
import { RoleInterface } from "@/modules/roles/types";
import useMixin from "@/useMixin";
import useFilters from "@/useFilters";

const disableDescriptionBot = import.meta.env.VITE_DISABLE_DESCRIPTION_BOT;

// Props
const props = defineProps({
  visible: { type: Boolean, default: false },
  acl: { type: Object as () => BaseAclInterface, default: undefined },
  role: { type: Object as () => RoleInterface, default: undefined },
  skills: { type: Array as () => Array<SkillInterface>, default: () => [] },
  costCenters: { type: Array as () => Array<CostCenterInterface>, default: () => [] },
  project: { type: Object as () => ProjectInterface, default: undefined },
  subprojects: { type: Array as () => Array<NewSubprojectInterface>, default: () => [] },
});

// Emits
const emits = defineEmits([
  "get-skills",
  "get-cost-centers",
  "update-project-role",
  "close",
  "get-subprojects-page",
]);

// Mixins
const { setObject, toMoneyCents } = useMixin();

// Filters
const { parseDate } = useFilters();

// Instances
const instance = getCurrentInstance();
const $validator = instance?.proxy.$validator;

// Data properties
const newProjectRole = ref<any>({
  hourly_rate_cents: null,
  hourly_rate_currency: null,
  start_date: null,
  end_date: null,
  description: null,
  title: null,
  user_id: null,
  future_hourly_rate_cents: null,
  future_hourly_rate_currency: null,
  future_hourly_rate_date: null,
  id: null,
  skill_ids: null,
  project: {},
  notice_period: null,
  workload: null,
  cost_center_id: null,
  agreement_link: null,
  automatic_time_reporting: null,
  disable_cv: null,
});
const roleUserName = ref<string>("");
const showWarningVisible = ref<boolean>(false);

// Watchers
watch(toRef(props, "visible"), (newProp, oldProp) => {
  if (newProp && !oldProp) {
    // Load users
    emits("get-skills");
    emits("get-cost-centers");
    emits("get-subprojects-page", props.project.id);

    // Set fields to current values
    newProjectRole.value = setObject(newProjectRole.value, props.role);
    newProjectRole.value.id = props.role.id;
    newProjectRole.value.workload = Math.round(newProjectRole.value.workload * 10000) / 100;
    newProjectRole.value.hourly_rate_cents = props.role.hourly_rate
      ? props.role.hourly_rate.value / 100
      : 0;
    newProjectRole.value.hourly_rate_currency = props.role.hourly_rate
      ? props.role.hourly_rate.currency_code
      : "SEK";
    newProjectRole.value.future_hourly_rate_cents = props.role.future_hourly_rate
      ? props.role.future_hourly_rate.value / 100
      : 0;
    newProjectRole.value.future_hourly_rate_currency = props.role.future_hourly_rate
      ? props.role.future_hourly_rate.currency_code
      : "SEK";
    newProjectRole.value.skill_ids = props.role.skills.map((skill) => skill.id);
    newProjectRole.value.user_id = props.role.user ? props.role.user.id : undefined;
    newProjectRole.value.agreement_link = props.role.agreement_link;
    roleUserName.value = props.role.user.name;
    newProjectRole.value.automatic_time_reporting = props.role.automatic_time_reporting;
    newProjectRole.value.disable_cv = props.role.disable_cv;
  }
});

// Component methods
const onSelectChange = (field: string, value: any): void => {
  newProjectRole.value[field] = value;
};

const onDateChange = (date: any, attribute: string): void => {
  if (date === null) {
    newProjectRole.value[attribute] = null;
  } else {
    newProjectRole.value[attribute] = date.format("YYYY-MM-DD");
  }
};

const changeRatesToCents = (): void => {
  newProjectRole.value.hourly_rate_cents = toMoneyCents(newProjectRole.value.hourly_rate_cents);
  newProjectRole.value.future_hourly_rate_cents = toMoneyCents(
    newProjectRole.value.future_hourly_rate_cents
  );
};

const handleUpdateProjectRole = (): void => {
  $validator?.errors.clear();
  $validator?.validate().then((result: any) => {
    if (result) {
      if (hasPendingExtension(props.role)) {
        showWarningVisible.value = true;
      } else {
        finaliseUpdateProjectRole();
      }
    }
  });
};

const continueAfterWarning = (): void => {
  showWarningVisible.value = false;
  finaliseUpdateProjectRole();
};

const cancelAfterWarning = (): void => {
  showWarningVisible.value = false;
  emits("close");
};

const finaliseUpdateProjectRole = (): void => {
  changeRatesToCents();
  newProjectRole.value.workload = newProjectRole.value.workload / 100;
  emits("update-project-role", newProjectRole.value);
  emits("close");
};

const closeModal = (): void => {
  emits("close");
};

const getDescriptionBotLink = (): string => {
  return `https://role-describer.ai.skry.se/?role_id=${props.role.id}&embed=true`;
};
</script>
