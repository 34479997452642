import moment from "@/date";

export default function useMixin() {
  /**
   * Returns a modified gravatar url for a specified image size
   *
   * Main purpose:
   * Retriving a Gravatar with customized size
   *
   * @param {String} gravatar_url
   * @param {Number} desiredSize
   *
   * @returns {url}
   */
  function getUserGravatarURLwithSpecifiedSize(gravatar_url: string, desiredSize: number): string {
    return gravatar_url.replace(/(s=)[^]+/, "$1" + desiredSize);
  }

  /**
   * Returns a formatted Date String
   *
   * Main purpose:
   * CV
   *
   * @param {String|Date|Moment} date
   *
   * @returns {String} format: "MMM YYYY" (e.g. "Jan 2018")
   */
  function formatDateForCV(date: any): string {
    return moment(date).format("MMM YYYY");
  }

  /**
   * Returns true if the a value is an empty object, collection, map or set,
   * has no enumerable properties or is any type that is not considered a
   * collection.
   *
   * @param {Object|Collection|Map|Set} val
   *
   * @returns {Boolean}
   */
  function isEmpty(val: any): boolean {
    return val == null || !(Object.keys(val) || val).length;
  }

  /**
   * Returns true if the a value is a non empty object, collection, map or set,
   * has enumerable properties or is any type that is considered a collection.
   *
   * @param {Object|Collection|Map|Set} val
   *
   * @returns {Boolean}
   */
  function isNotEmpty(val: any): boolean {
    return Boolean(val != null && (Object.keys(val) || val).length);
  }

  /**
   * Check if all elements in an array are equal.
   *
   * @param {Array} arr
   */
  function allEqual(arr: Array<any>): boolean {
    return arr.every((val) => val === arr[0]);
  }

  /**
   * Take a number and return specified currency formatting.
   *
   * Main purpose:
   * Make currency user friendly/readable
   *
   * @param {Number} n
   * @param {currency} curr
   * @param {Locale} LanguageFormat
   * @param {String} currencyDisplay
   */
  function toCurrency(
    n: number,
    curr: string = "SEK",
    LanguageFormat: string = "sv",
    currencyDisplay = "code"
  ): string {
    return Intl.NumberFormat(LanguageFormat, {
      style: "currency",
      currency: curr,
      currencyDisplay: currencyDisplay,
    }).format(n);
  }

  /**
   * Formats a Money object as <value> <currency>
   *
   * @param {Object} money
   */
  function toMoney(money: any): string {
    return money ? `${money.formatted_value} ${money.currency_code}` : "-";
  }

  /**
   * Convert money values in decimal forms to cents (integers)
   *
   * @param {String|Number} moneyCents
   */
  function toMoneyCents(money: string | number): number {
    const moneyFloat = typeof money === "string" ? Number.parseFloat(money) : money;
    return Math.round(moneyFloat * 100);
  }

  /**
   * Take a floating point number between 0 and 1 and format it as percentage.
   *
   * @param {Float} value
   *
   * @returns {String} format: Percentage
   */
  function toPercentage(value: number): string {
    return `${Math.round(value * 10000) / 100}%`;
  }

  /**
   * Fill in the keys in a target object with values at the same key in an origin,
   * object. If the origin object is an empty string it fills all the values in,
   * the target object with undefined. This function returns a new object.
   *
   * @param {Object} targetObject
   * @param {Object} originObject
   *
   * @returns {Object}
   */
  function setObject(targetObject: any, originObject: any): any {
    const newObject = new Object();

    if (originObject === undefined) {
      for (const key in targetObject) {
        // @ts-ignore
        newObject[key] = undefined;
      }
    } else {
      for (const key in targetObject) {
        // @ts-ignore
        newObject[key] = originObject.hasOwnProperty(key) ? originObject[key] : targetObject[key];
      }
    }

    return newObject;
  }

  /**
   * Return the difference between 2 dates in a humanized format (e.g. "2 days ago").
   * Default end date is "today".
   *
   * @param {Moment|String|Number|Date|Array} startDate
   * @param {Moment|String|Number|Date|Array} endDate
   *
   * @returns {String} format: humanized (e.g. "2 days ago")
   */
  // @ts-ignore
  function humanizeTimeDiff(startDate: any, endDate: any = moment()): string {
    // @ts-ignore
    return moment(endDate).to(moment(startDate));
  }

  /**
   * Transform object in URI encoded query string.
   *
   * @param {Object} parameters
   *
   * @returns {String}
   */
  function qsEncode(parameters: any): string {
    // @ts-ignore
    return "?" + qs.stringify(parameters, { arrayFormat: "brackets" });
  }

  /**
   * Transform a URI encoded query string into an object.
   *
   * @param {String} queryString
   *
   * @returns {Object}
   */
  function qsDecode(queryString: string): any {
    if (typeof queryString === "string") {
      const questionMarkIndex = queryString.indexOf("?");
      queryString = questionMarkIndex >= 0 ? queryString.slice(questionMarkIndex) : queryString;
    }
    // @ts-ignore
    const qsObj = qs.parse(queryString, { ignoreQueryPrefix: true });
    convertStringArraysToNumberArrays(qsObj);
    return qsObj;
  }

  const convertStringArraysToNumberArrays = (qsObj: any): void => {
    if (qsObj.filters) {
      for (const [key, value] of Object.entries(qsObj.filters)) {
        if (Array.isArray(value) && value.every((v) => !isNaN(v))) {
          qsObj.filters[key] = value.map((v: string) => Number(v));
        }
      }
    }
  };

  /**
   * Replace current URL with updated one including different
   * serialized parameters.
   *
   * @param {Object} params
   * @param {Function} instance
   */
  const syncRouteParams = (params: any, instance: any): void => {
    const $route = instance?.proxy.$route;
    const $router = instance?.proxy.$router;

    $router?.replace({
      path: $route?.path + qsEncode(params),
      hash: $route?.hash,
    });
  };

  /**
   * Load from local storage the user preferred table page size.
   * Default to 25 items.
   *
   * @returns {Number}
   */
  function loadTablePageSize(): number {
    return parseInt(
      // @ts-ignore
      localStorage.getItem("sky-table-page-size") ? localStorage.getItem("sky-table-page-size") : 25
    );
  }

  /**
   * Persist preferred table page size to local storage.
   *
   * @param {Number} size
   */
  function persistTablePageSize(size: number): void {
    const pageSize = size || 25;
    localStorage.setItem("sky-table-page-size", pageSize.toString());
  }

  /**
   * Truncates a string up to a specified length.
   *
   * Determine if the string's length is greater than num.
   * Return the string truncated to the desired length,
   * with '...' appended to the end or the original string.
   *
   * @param {String} str
   * @param {Number} num
   */
  function truncateString(str: string, num: number): string {
    return str.length > num ? str.slice(0, num > 3 ? num - 3 : num) + "..." : str;
  }

  /**
   * Performs a deep comparison between two values to determine
   * if they are equivalent.
   *
   * @param {Object} a
   * @param {Object} b
   */
  function equals(a: any, b: any): boolean {
    if (a === b) return true;
    if (a instanceof Date && b instanceof Date) return a.getTime() === b.getTime();
    if (!a || !b || (typeof a !== "object" && typeof b !== "object")) return a === b;
    if (a === null || a === undefined || b === null || b === undefined) return false;
    if (a.prototype !== b.prototype) return false;
    let keys = Object.keys(a);
    if (keys.length !== Object.keys(b).length) return false;
    return keys.every((k) => equals(a[k], b[k]));
  }

  /**
   * Takes a transaction type and returns it in a form readable
   * for humans. E.g. 'plain_transaction' => 'Plain'
   *
   * @param {string} transactionType
   */
  function parseTransactionType(transactionType: string): string {
    const type = transactionType.replace("-transaction", "");
    return type.charAt(0).toUpperCase() + type.slice(1);
  }

  return {
    getUserGravatarURLwithSpecifiedSize,
    formatDateForCV,
    isEmpty,
    isNotEmpty,
    allEqual,
    toCurrency,
    toMoney,
    toMoneyCents,
    toPercentage,
    setObject,
    humanizeTimeDiff,
    qsEncode,
    qsDecode,
    syncRouteParams,
    loadTablePageSize,
    persistTablePageSize,
    truncateString,
    equals,
    parseTransactionType,
  };
}
