import { ReportType } from "@/modules/reports/types";
import axios from "@/network";

export function apiGetSchedule(params: any, headers = {}) {
  const url = "/week_schedule";
  return axios.get(url, { params, headers });
}

export function apiGetVacationReports(params: any) {
  const url = "/reports";
  return axios.get(url, {
    params: {
      ...params,
      types: [ReportType.VACATION, ReportType.PARENTAL],
    },
  });
}

export function apiGetKnowledgeDomains(userId: number) {
  const url = `/users/${userId}/knowledge_domains`;
  return axios.get(url, {
    params: {
      user_id: userId,
    },
  });
}
