<template>
  <a-modal
    :visible="visible"
    :destroy-on-close="true"
    :title="question ? question.title : ''"
    ok-text="Save"
    @ok="handleStoreQuizSubmission"
    @cancel="$emit('close')"
  >
    <p>{{ question?.question }}</p>
    <a-form data-vv-scope="update-quiz-submission">
      <a-form-item
        :validate-status="$validator.errors.first('update-quiz-submission.answer') ? 'error' : ''"
        :help="$validator.errors.first('update-quiz-submission.answer')"
      >
        <a-textarea
          v-model="quizSubmission.answer"
          v-validate="'required'"
          :auto-size="{ minRows: 2 }"
          data-vv-as="answer"
          data-vv-name="answer"
        />
      </a-form-item>
    </a-form>
    <p>Previous answer: {{ question?.quiz_submission.answer }}</p>
  </a-modal>
</template>

<script setup lang="ts">
import { ref, watch, toRef, getCurrentInstance } from "vue";
import { QuestionInterface } from "../types";

// Props
const props = defineProps({
  question: { type: Object as () => QuestionInterface, default: undefined },
  visible: { type: Boolean, default: false },
});

// Emits
const emits = defineEmits(["update-answer", "close"]);

// Instances
const instance = getCurrentInstance();
const $validator = instance?.proxy.$validator;

// Data properties
const quizSubmission = ref<any>({
  answer: undefined,
});

// Watchers
watch(toRef(props, "visible"), (newProp, oldProp) => {
  if (newProp && !oldProp) {
    quizSubmission.value = {
      answer: undefined,
    };
  }
});

// Component methods
const handleStoreQuizSubmission = (): void => {
  $validator?.validateAll("update-quiz-submission").then((result) => {
    if (result) {
      emits(
        "update-answer",
        props.question.id,
        props.question.quiz_submission.user_id,
        quizSubmission.value
      );
      emits("close");
    }
  });
};
</script>
