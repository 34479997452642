<template>
  <div class="card">
    <div class="d-flex justify-content-between">
      <h2>Overview</h2>

      <!-- EDIT/DELETE ICONS -->
      <div v-if="project.deletable" class="d-flex align-items-center">
        <div class="action-default" @click="editProjectModalVisible = true">
          <a-icon type="edit" class="pr-1" />Edit
        </div>
        <a-divider type="vertical" />
        <div class="action-danger" @click="deleteProjectModalVisible = true">
          <a-icon type="delete" class="pr-1" />Delete
        </div>
      </div>
    </div>

    <a-row class="d-flex align-items-center">
      <!-- PROJECT INFO -->
      <a-col :span="24" :lg="16" class="p-1">
        <div v-if="project.alias" class="desc-item">
          <span class="label">Alias:</span>
          <span class="value">{{ project.alias }}</span>
        </div>
        <div class="desc-item">
          <span class="label">Status:</span>
          <span class="value">
            <a-badge v-show="project.status === 'closed'" dot class="mr-1" />
            <a-badge
              v-show="project.status === 'active'"
              :number-style="{ backgroundColor: '#52c41a' }"
              dot
              class="mr-1"
            />
            {{ project.status }}
          </span>
        </div>
        <div v-if="project.project_type" class="desc-item">
          <span class="label">Project type:</span>
          <span v-if="project.project_type === 'sk_for_hire'" class="value">SK for hire</span>
          <span v-if="project.project_type === 'expert_for_hire'" class="value">
            Expert for hire
          </span>
        </div>
        <div v-if="project.start_date" class="desc-item d-flex d-lg-none">
          <span class="label">Start date:</span>
          <span class="value">{{ project.start_date }}</span>
        </div>
        <div v-if="project.completion_date" class="desc-item d-flex d-lg-none">
          <span class="label">Estimated completion date:</span>
          <span class="value">{{ project.completion_date }}</span>
        </div>
        <div v-if="project.public_description" class="desc-item overflow-visible">
          <span class="label">Project description:</span>
          <span class="value">{{ project.public_description }}</span>
        </div>
        <div v-if="project.internal_description" class="desc-item overflow-visible">
          <span class="label">Internal description:</span>
          <span class="value">{{ project.internal_description }}</span>
        </div>
        <div
          v-if="project.fortnox_id && (acl.isAdmin || acl.isFinancial)"
          class="desc-item overflow-visible"
        >
          <span class="label">Fortnox ID:</span>
          <span class="value">{{ project.fortnox_id }}</span>
        </div>
        <div
          v-if="project.invoicing_info && (acl.isAdmin || acl.isFinancial)"
          class="desc-item overflow-visible"
        >
          <span class="label">Invoicing information:</span>
          <span class="value">{{ project.invoicing_info }}</span>
        </div>
      </a-col>

      <!-- PROJECT COMPLETION PERCENTAGE -->
      <a-col
        :span="24"
        :lg="8"
        class="p-1 flex-column align-items-center justify-content-center d-none d-lg-flex"
      >
        <p v-if="project.completion_date">Days till completion: {{ daysLeft }}</p>
        <p>
          Start date:
          <strong>{{ project.start_date }}</strong>
        </p>
        <p v-if="project.completion_date">
          Completion date:
          <strong>{{ project.completion_date }}</strong>
        </p>
      </a-col>
    </a-row>

    <!-- EDIT PROJECT MODAL -->
    <edit-project-modal
      :visible="editProjectModalVisible"
      :users="users"
      :project="project"
      :clients="clients"
      :acl="acl"
      @close="editProjectModalVisible = false"
      @get-users="$emit('get-users')"
      @get-clients="$emit('get-clients')"
      @update-project="(project) => handleUpdateProject(project)"
    />

    <!-- DELETE PROJECT MODAL -->
    <a-modal
      :visible="deleteProjectModalVisible"
      title="Are you sure you want to delete this project and all its associations?"
      ok-text="Yes"
      ok-type="danger"
      cancel-text="No"
      @ok="() => handleDeleteProject()"
      @cancel="deleteProjectModalVisible = false"
    >
      <h3 class="mb-0">Associations:</h3>
      <p v-for="entity in project.associations" :key="entity.name" class="mb-0">
        <strong>{{ entity.name }}:</strong>
        {{ entity.count }}
      </p>
    </a-modal>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import EditProjectModal from "../_components/EditProjectModal.vue";
import { ProjectInterface } from "@/modules/projects/types";
import { BaseAclInterface } from "@/modules/authentication/types";
import { MinimalUserInterface } from "@/modules/users/types";
import { MinimalClientInterface } from "@/modules/clients/types";
import moment from "@/date";

// Props
const props = defineProps({
  project: { type: Object as () => ProjectInterface, default: undefined },
  users: { type: Array as () => Array<MinimalUserInterface>, default: () => [] },
  clients: { type: Array as () => Array<MinimalClientInterface>, default: () => [] },
  acl: { type: Object as () => BaseAclInterface, default: undefined },
});

// Emits
const emits = defineEmits(["destroy-project", "update-project"]);

// Data properties
const editProjectModalVisible = ref<boolean>(false);
const deleteProjectModalVisible = ref<boolean>(false);

// Computed properties
const daysLeft = computed((): string => {
  let days = moment(props.project.completion_date).diff(moment(), "days");
  if (days < 0) days = 0;
  return `${days} ${days === 1 ? "day" : "days"}`;
});

// Class methods
const handleDeleteProject = (): void => {
  deleteProjectModalVisible.value = false;
  emits("destroy-project", props.project.id);
};

const handleUpdateProject = (updatedProject: ProjectInterface): void => {
  emits("update-project", updatedProject);
  editProjectModalVisible.value = false;
};
</script>
