import { MinimalUserInterface } from "../users/types";

export interface CostCenterInterface {
  id: number;
  name: string;
  description?: string;
  billable: boolean;
  fortnox_cost_center_id?: string;
  manager: MinimalUserInterface;
  use_cases: Array<CostCenterUseCase>;
}

export interface CostCenterPayloadInterface {
  id: number;
  name: string;
  description?: string;
  billable: boolean;
  fortnox_cost_center_id?: string;
  manager_id: number;
  use_cases: Array<CostCenterUseCase>;
}

export enum CostCenterUseCase {
  DETACHEDTIME = "detached_time",
}

// Labels for the use case enums
export const costCenterUseCases = {
  [CostCenterUseCase.DETACHEDTIME]: "Time report (without project)",
};
