<template>
  <div>
    <div class="card">
      <!-- EDIT ICON -->
      <div
        v-if="acl.isOwner || acl.isAdmin"
        class="action-default d-flex justify-content-end align-items-center"
        @click="editUserModalVisible = true"
      >
        <a-icon type="edit" class="pr-1" />
        <span>Edit</span>
      </div>

      <a-row>
        <!-- PROFILE PICTURE & NAME -->
        <a-col :span="24" :lg="8" :md="8" class="d-flex justify-content-center">
          <div class="picture-wrapper">
            <img :src="getUserGravatarURLwithSpecifiedSize(user.gravatar, 500)" />
          </div>
        </a-col>

        <a-col :span="24" :lg="16" :md="16">
          <div class="d-flex justify-content-center justify-content-md-start">
            <h2>
              <strong>{{ user.name }}</strong>
            </h2>
          </div>

          <!-- ON VACATION? -->
          <div>
            <p class="d-flex align-items-center">
              <strong v-show="user.vacation">
                <ion-icon name="sunny" class="text-yellow" />On Vacation
              </strong>
            </p>
          </div>

          <a-row class="d-flex flex-wrap justify-content-center justify-content-md-start">
            <a-col :span="24" :lg="22">
              <a-row>
                <a-col v-if="user.email" :span="24" :lg="14" style="word-wrap: break-word">
                  <a-icon type="mail" class="mr-2" />
                  <a :href="`mailto:${user.email}`">
                    {{ user.email }}
                  </a>
                </a-col>
                <a-col v-if="user.private_email" :span="24" :lg="10" style="word-wrap: break-word">
                  <a-icon type="mail" class="mr-2" />
                  <a :href="`mailto:${user.private_email}`">
                    {{ user.private_email }}
                  </a>
                </a-col>
              </a-row>
              <a-row>
                <a-col v-if="user.skype" :span="24" :lg="14">
                  <a-icon type="skype" class="mr-2" />
                  <a :href="`skype:${user.skype}`">
                    {{ user.skype }}
                  </a>
                </a-col>
                <a-col v-if="user.phone_number" :span="24" :lg="10">
                  <a-icon type="phone" class="mr-2 mb-1" />
                  <a :href="`tel:${user.phone_number}`">
                    {{ user.phone_number }}
                  </a>
                </a-col>
              </a-row>
              <a-row>
                <a-col v-if="user.github" :span="24" :lg="14">
                  <ion-icon name="logo-github" class="anticon mr-2" />
                  <a :href="`https://github.com/${user.github}`" target="_blank" class="mb-1">
                    {{ user.github }}
                  </a>
                </a-col>
                <a-col v-if="user.bitbucket" :span="24" :lg="10">
                  <ion-icon name="logo-bitbucket" class="anticon mr-2" />
                  <a :href="`https://bitbucket.org/${user.bitbucket}`" target="_blank" class="mb-1">
                    {{ user.bitbucket }}
                  </a>
                </a-col>
              </a-row>
              <a-row>
                <a-col v-if="user.address" :span="24" :lg="14">
                  <a-icon type="environment" class="anticon mr-2" />
                  <span>{{ user.address }}</span>
                </a-col>
                <a-col
                  v-if="
                    user.birth_date && (acl.isCr || acl.isFinancial || acl.isAdmin || acl.isOwner)
                  "
                  :span="24"
                  :lg="10"
                >
                  <a-icon type="gift" class="anticon mr-2" />
                  <span>{{ user.birth_date }}</span>
                </a-col>
              </a-row>
              <a-row class="mt-2"
                ><a-col v-if="user.team" :span="24" :lg="10">
                  <p class="mb-1">
                    Team:
                    <strong>{{ user.team }}</strong>
                  </p>
                </a-col>
              </a-row>
              <a-row>
                <a-col v-if="acl.isInternal" :span="24" :lg="14">
                  <p class="mb-1">
                    Access role:
                    <a-tag v-for="role in user.roles" :key="role" class="mx-1">
                      {{ role == "default" ? "EMPLOYEE" : role.toUpperCase() }}
                    </a-tag>
                  </p>
                </a-col>
              </a-row>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </div>

    <!-- USER DESCRIPTION -->
    <div class="mt-2">
      <user-description :user="user" :acl="acl" @update-profile="handleUpdateProfile" />
    </div>

    <div class="mt-2">
      <user-knowledge-domains
        :user="user"
        :acl="acl"
        :knowledge-domains="knowledgeDomains"
        @get-knowledge-domains="$emit('get-knowledge-domains')"
        @store-knowledge-domain="(a) => $emit('store-experience', { a, type: 'knowledge_domains' })"
        @update-knowledge-domain="
          (a) => $emit('update-experience', { a, type: 'knowledge_domains' })
        "
        @destroy-knowledge-domain="
          (id) => $emit('destroy-experience', { id, type: 'knowledge_domains' })
        "
      />
    </div>

    <!-- EDUCATION -->
    <div class="mt-2">
      <user-educations
        :educations="user.educations"
        :skills="skills"
        :acl="acl"
        @store-education="(a) => $emit('store-experience', { a, type: 'educations' })"
        @update-education="(a) => $emit('update-experience', { a, type: 'educations' })"
        @destroy-education="(id) => $emit('destroy-experience', { id, type: 'educations' })"
        @get-skills="$emit('get-skills')"
      />
    </div>

    <!-- WORK EXPERIENCE -->
    <div class="mt-2">
      <user-jobs
        :jobs="user.work_experiences"
        :skills="skills"
        :acl="acl"
        :user="user"
        @store-job="(a) => $emit('store-experience', { a, type: 'work_experiences' })"
        @update-job="(a) => $emit('update-experience', { a, type: 'work_experiences' })"
        @destroy-job="(id) => $emit('destroy-experience', { id, type: 'work_experiences' })"
        @get-skills="$emit('get-skills')"
      />
    </div>

    <!-- MERITS -->
    <div class="mt-2">
      <user-merits
        :merits="user.merits"
        :acl="acl"
        @store-merit="(a) => $emit('store-experience', { a, type: 'merits' })"
        @update-merit="(a) => $emit('update-experience', { a, type: 'merits' })"
        @destroy-merit="(id) => $emit('destroy-experience', { id, type: 'merits' })"
      />
    </div>

    <edit-user-modal
      :user="user"
      :visible="editUserModalVisible"
      :acl="acl"
      @update-profile="(updatedUser) => handleUpdateProfile(updatedUser)"
      @close="editUserModalVisible = false"
    />
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { ProfileInterface, BaseAclInterface } from "@/modules/authentication/types";
import { KnowledgeDomainInterface } from "../../consultant_profiles/types";
import UserKnowledgeDomains from "./UserKnowledgeDomains.vue";
import UserDescription from "./UserDescription.vue";
import { SkillInterface } from "../../skills/types";
import UserEducations from "./UserEducations.vue";
import EditUserModal from "./EditUserModal.vue";
import UserMerits from "./UserMerits.vue";
import UserJobs from "./UserJobs.vue";
import useMixin from "@/useMixin";

// Props
const props = defineProps({
  knowledgeDomains: { type: Array as () => Array<KnowledgeDomainInterface>, default: () => [] },
  skills: { type: Array as () => Array<SkillInterface>, default: () => [] },
  user: { type: Object as () => ProfileInterface, default: undefined },
  acl: { type: Object as () => BaseAclInterface, default: undefined },
});

// Emits
const emits = defineEmits(["update-profile"]);

// Mixins
const { getUserGravatarURLwithSpecifiedSize } = useMixin();

// Data Properties
const editUserModalVisible = ref<boolean>(false);

// Component Methods
const handleUpdateProfile = (updatedUser: ProfileInterface): void => {
  editUserModalVisible.value = false;
  emits("update-profile", updatedUser);
};
</script>

<style lang="scss" scoped>
.picture-wrapper {
  margin-top: 20px;
  margin-bottom: 20px;

  img {
    border-radius: 100px;
    height: 100px;
  }
}
</style>
