<template>
  <div class="mb-3 p-2" :style="{ backgroundColor: '#F0F7FB', borderLeft: '4px solid #3498DB' }">
    <!-- eslint-disable vue/no-v-html -->
    <div v-html="DOMPurify.sanitize(description.content)" />

    <div
      class="mt-3 pt-2 d-flex flex-row align-items-center"
      :style="{ borderTop: '1px solid grey' }"
    >
      <a-icon type="clock-circle" class="mr-1 text-blue" />
      <em>
        Last updated
        {{ handleDurationBetweenDates(description.updated_at) }}
        ago
      </em>
      <span class="ml-auto">Created by {{ description.created_by.name }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { JobPostingDescriptionInterface } from "../types";
import DOMPurify from "dompurify";
import useFilters from "@/useFilters";
import moment from "@/date";
import { RFC_3339 } from "@/date";

// Props
const props = defineProps({
  description: { type: Object as () => JobPostingDescriptionInterface, default: undefined },
});

// Filters
const { durationBetweenDates } = useFilters();

// Component methods
const handleDurationBetweenDates = (time: string): string => {
  return durationBetweenDates(new Date(), moment(time, [moment.ISO_8601, RFC_3339]).toDate());
};
</script>
