<template>
  <a-modal
    :visible="visible"
    :destroy-on-close="true"
    title="Edit Budget"
    ok-text="Update"
    @ok="() => handleUpdateBudget()"
    @cancel="() => closeModal()"
  >
    <a-form data-vv-scope="updatedBudget" layout="horizontal">
      <SkInput
        v-model="updatedBudget.name"
        v-validate="'required|max:255'"
        :error="$validator.errors.first('updatedBudget.name')"
        label="Name"
        placeholder="Budget name"
        data-vv-name="name"
      />

      <SkInput
        v-if="updatedBudget.type === 'HourlyBudget'"
        v-model="updatedBudget.amount"
        v-validate="'required|decimal|min_value:0'"
        :error="$validator.errors.first('updatedBudget.amount')"
        :addon-after="'hours'"
        data-vv-name="amount"
        label="Budget amount"
        placeholder="Amount in hours"
      />

      <SkMoney
        v-if="updatedBudget.type === 'MoneyBudget'"
        v-model="updatedBudget.amount_cents"
        v-validate="'required|decimal|min_value:0'"
        :error="$validator.errors.first('updatedBudget.amount')"
        :currency="updatedBudget.amount_currency"
        data-vv-name="amount"
        label="Budget amount"
        placeholder="Amount"
        @set-currency="(v) => (updatedBudget.amount_currency = v)"
      />
    </a-form>

    <a-form-item
      :label-col="{ span: 8 }"
      :wrapper-col="{ span: 16 }"
      :validate-status="$validator.errors.first('updatedBudget.start_date') ? 'error' : ''"
      :error="$validator.errors.first('updatedBudget.start_date')"
      :help="$validator.errors.first('updatedBudget.start_date')"
      label="Start date"
    >
      <a-date-picker
        v-validate="'required'"
        :value="parseDate(updatedBudget.start_date)"
        :disabled-date="disabledDateForStartDate"
        class="w-100"
        allow-clear
        placeholder="Start date"
        data-vv-name="start_date"
        data-vv-as="start date"
        @change="(date) => onDateChange('start_date', date)"
      />
    </a-form-item>

    <a-form-item
      :label-col="{ span: 8 }"
      :wrapper-col="{ span: 16 }"
      :validate-status="$validator.errors.first('updatedBudget.end_date') ? 'error' : ''"
      :help="$validator.errors.first('updatedBudget.end_date')"
      label="End date"
    >
      <a-date-picker
        v-validate="'required'"
        :value="parseDate(updatedBudget.end_date)"
        :disabled-date="disabledDateForEndDate"
        class="w-100"
        allow-clear
        placeholder="End date"
        data-vv-name="end_date"
        data-vv-as="end date"
        @change="(date) => onDateChange('end_date', date)"
      />
    </a-form-item>

    <a-form-item
      :label-col="{ span: 8 }"
      :wrapper-col="{ span: 16 }"
      class="d-flex"
      label="Recurring"
    >
      <a-checkbox
        :checked="updatedBudget.recurring"
        class="ml-3"
        @change="() => (updatedBudget.recurring = !updatedBudget.recurring)"
      />
    </a-form-item>

    <SkSelect
      v-model="updatedBudget.role_ids"
      v-validate="'required'"
      :error="$validator.errors.first('updatedBudget.role_ids')"
      :options="roles"
      :display-prop-func="(item) => `${item.title} (${item.user.name})`"
      label="Roles"
      mode="multiple"
      placeholder="Please select roles"
      data-vv-name="type"
    />
  </a-modal>
</template>

<script setup lang="ts">
import { ref, watch, getCurrentInstance, toRefs } from "vue";
import { RoleInterface } from "../../roles/types";
import { ProfileInterface } from "@/modules/authentication/types";
import { ProjectInterface } from "../../projects/types";
import { BudgetPayloadInterface } from "../types";
import moment from "@/date";
import useMixin from "@/useMixin";
import { Moment } from "moment";
import useFilters from "@/useFilters";

// Props
const props = defineProps({
  roles: { type: Array as () => Array<RoleInterface>, default: () => [] },
  currentUser: { type: Object as () => ProfileInterface, default: () => ({}) },
  project: { type: Object as () => ProjectInterface, default: () => ({}) },
  budget: { type: Object, default: () => ({}) },
  visible: { type: Boolean, default: false },
});

// Emits
const emits = defineEmits(["update-budget", "close"]);

// Mixins
const { setObject, toMoneyCents } = useMixin();

// Filters
const { parseDate } = useFilters();

// Data Properties
const { currentUser, project } = toRefs(props);
const updatedBudget = ref<BudgetPayloadInterface>({
  user_id: currentUser.value.id,
  project_id: project.value ? project.value.id : undefined,
  id: undefined,
  role_ids: undefined,
  name: undefined,
  recurring: false,
  type: "MoneyBudget",
  amount: undefined,
  amount_currency: undefined,
  amount_cents: undefined,
  start_date: moment().format("YYYY-MM-DD"),
  end_date: undefined,
});

// Watchers
watch(
  () => props.visible,
  (newProp, oldProp) => {
    if (newProp && !oldProp) {
      updatedBudget.value = setObject(updatedBudget.value, props.budget);
      updatedBudget.value.role_ids = props.budget.roles.map((role: RoleInterface) => role.id);
      updatedBudget.value.amount_cents = props.budget.amount
        ? Math.abs(props.budget.amount.value / 100)
        : 0;
      updatedBudget.value.amount_currency = props.budget.amount
        ? props.budget.amount.currency_code
        : "SEK";
      updatedBudget.value.type =
        updatedBudget.value.type === "money-budget" ? "MoneyBudget" : "HourlyBudget";
    }
  }
);

// Class Methods
const onDateChange = (dateName: string, date: Moment): void => {
  updatedBudget.value[dateName] = date ? date.format("YYYY-MM-DD") : null;
};

const disabledDateForStartDate = (current: Moment): boolean => {
  return Boolean(
    current &&
      updatedBudget.value.end_date &&
      current > moment(updatedBudget.value.end_date).endOf("day")
  );
};

const disabledDateForEndDate = (current: Moment): boolean => {
  return Boolean(
    current &&
      updatedBudget.value.start_date &&
      current < moment(updatedBudget.value.start_date).startOf("day")
  );
};

const closeModal = (): void => {
  emits("close");
};

const changeAmountToCents = (): void => {
  updatedBudget.value.amount_cents = toMoneyCents(updatedBudget.value.amount_cents ?? 0);
};

const instance = getCurrentInstance();
const $validator = instance?.proxy?.$validator;
const handleUpdateBudget = (): void => {
  $validator?.validateAll("updatedBudget").then((result) => {
    // If last step and validation pass...
    if (result) {
      changeAmountToCents();
      updatedBudget.value.type =
        props.budget.type == "hourly-budget" ? "HourlyBudget" : "MoneyBudget";
      emits("update-budget", updatedBudget.value);
      emits("close");
    }
  });
};
</script>
