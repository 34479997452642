<template>
  <div>
    <div v-if="loading" align="left">
      <a-spin :delay="300" size="default" align="middle" />
    </div>

    <div v-else>
      <div v-if="latestRoleExtension != null">
        <a-row class="d-flex">
          <a-col :span="6" class="my-auto mr-auto">
            <strong>Latest extension:</strong>
          </a-col>
          <a-col>
            <a-button
              v-if="acl.isCr && latestRoleExtension.pending == true"
              class="mr-2"
              type="danger"
              @click="showDeleteRoleExtensionModal"
            >
              Delete extension
            </a-button>
            <a-button
              v-if="acl.isCr && latestRoleExtension.pending == true"
              class="mr-2"
              @click="showUpdateRoleExtensionModal"
            >
              Update
            </a-button>
            <a-button
              v-if="roleExtensionApprovedButNotApplied() && canApplyRoleExtension()"
              class="mr-2"
              type="primary"
              @click="showApplyRoleExtensionModal"
            >
              Apply
            </a-button>
            <a-button
              v-if="canMakeDecisionOnRoleExtension()"
              class="ml-3 mr-2"
              :disabled="!colleageCanRejectRoleExtension()"
              @click="() => handleDecideRoleExtension(false)"
            >
              Let's discuss
            </a-button>
            <a-button
              v-if="canMakeDecisionOnRoleExtension()"
              class="mr-2"
              type="primary"
              :disabled="!colleageCanApproveRoleExtension()"
              @click="() => handleDecideRoleExtension(true)"
            >
              Approve
            </a-button>
          </a-col>
        </a-row>

        <a-card style="margin-top: 10px">
          <a-row>
            <a-col :span="8">
              <strong class="mr-2">Created by:</strong>
              {{ latestRoleExtension.created_by_user?.name }}
            </a-col>

            <a-col :span="8">
              <strong class="mr-2">Initiated by:</strong>
              {{ getInitiatedByFormattedString() }}
            </a-col>

            <a-col v-if="latestRoleExtension.pipedrive_id" :span="8">
              <strong class="mr-2">PipeDrive ID:</strong>
              {{ latestRoleExtension.pipedrive_id }}
            </a-col>

            <a-col v-if="isEndDateModified()" :span="8">
              <strong class="mr-2">New end date:</strong>
              {{ latestRoleExtension.end_date }}
            </a-col>

            <a-col v-if="isHourlyRateModified()" :span="8">
              <strong class="mr-2">New hourly rate:</strong>
              {{ toMoney(latestRoleExtension.hourly_rate) }}
            </a-col>

            <a-col v-if="isHourlyRateDateModified()" :span="8">
              <strong class="mr-2">New hourly rate date:</strong>
              {{ latestRoleExtension.future_hourly_rate_date }}
            </a-col>

            <a-col v-if="isWorkloadModified()" :span="8">
              <strong class="mr-2">New workload:</strong>
              {{ latestRoleExtension.workload * 100 }}%
            </a-col>

            <a-col v-if="isNoticePeriodModified()" :span="8">
              <strong class="mr-2">New notice period:</strong>
              {{ latestRoleExtension.notice_period }}
            </a-col>

            <a-col v-if="latestRoleExtension.comment" :span="8">
              <strong class="mr-2">Comment:</strong>
              {{ latestRoleExtension.comment }}
            </a-col>
          </a-row>

          <a-row v-if="canMakeDecisionOnRoleExtension()">
            <a-col>
              <br />
              <em v-if="latestRoleExtension.colleague_accepted == true">
                <a-icon type="check-circle" class="mr-2" />
                <em v-if="isOwnRoleExtension()"> You have approved this extension </em>
                <em v-else> Colleague has approved this extension </em>
              </em>
              <em v-if="latestRoleExtension.colleague_accepted == false">
                <a-icon type="message" class="mr-2" />
                <em v-if="isOwnRoleExtension()">
                  CR has been notified you wish to further discuss this extension
                </em>
                <em v-else> Colleague wishes to further discuss this extension </em>
              </em>
              <em v-if="latestRoleExtension.colleague_accepted == null">
                <a-icon type="clock-circle" class="mr-2" />
                Colleague hasn't decided on this extension yet
              </em>
            </a-col>
          </a-row>
        </a-card>

        <!-- APPLY ROLE EXTENSION MODAL -->
        <apply-role-extension-modal
          :visible="applyRoleExtensionModalVisible"
          :role="currentRole"
          :role-extension="latestRoleExtension"
          @close="applyRoleExtensionModalVisible = false"
          @apply-role-extension="handleApplyRoleExtension"
        />

        <!-- UPDATE ROLE EXTENSION MODAL -->
        <update-role-extension-modal
          :visible="updateRoleExtensionModalVisible"
          :role="role"
          :role-extension="latestRoleExtension"
          @close="updateRoleExtensionModalVisible = false"
          @update-role-extension="handleUpdateRoleExtension"
          @reissue-role-extension="handleReissueRoleExtension"
        />

        <!-- DELETE ROLE EXTENSION MODAL -->
        <delete-role-extension-modal
          :visible="deleteRoleExtensionModalVisible"
          :role="role"
          :role-extension="latestRoleExtension"
          @close="deleteRoleExtensionModalVisible = false"
          @delete-role-extension="handleDeleteRoleExtension"
        />
      </div>

      <div v-else>
        <a-row>
          <a-col :span="6"> <strong>Latest extension:</strong> <em>None</em> </a-col>
        </a-row>
      </div>

      <div
        v-if="
          showCreateButton &&
          (latestRoleExtension == null || latestRoleExtension.pending == false) &&
          acl.isCr
        "
        class="mt-2"
      >
        <a-row>
          <a-col>
            <a-button
              type="primary"
              @click="
                () => {
                  showCreateRoleExtensionModal();
                }
              "
            >
              Create role extension
            </a-button>
          </a-col>
        </a-row>

        <!-- CREATE ROLE EXTENSION MODAL -->
        <create-role-extension-modal
          :visible="createRoleExtensionModalVisible"
          :role="role"
          @close="createRoleExtensionModalVisible = false"
          @create-role-extension="
            (roleId, newRoleExtension) => handleCreateRoleExtension(roleId, newRoleExtension)
          "
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, getCurrentInstance, toRefs } from "vue";
import { convertInitiatedByToFormattedName, RoleExtensionInterface, RoleInterface } from "../types";
import { BaseAclInterface, ProfileInterface } from "@/modules/authentication/types";
import UpdateRoleExtensionModal from "./UpdateRoleExtensionModal.vue";
import DeleteRoleExtensionModal from "./DeleteRoleExtensionModal.vue";
import CreateRoleExtensionModal from "./CreateRoleExtensionModal.vue";
import ApplyRoleExtensionModal from "./ApplyRoleExtensionModal.vue";
import useMixin from "@/useMixin";
import {
  apiApplyRoleExtension,
  apiCreateRoleExtension,
  apiDeleteRoleExtension,
  apiGetLatestRoleExtension,
  apiGetRole,
  apiUpdateRoleExtension,
} from "../_utils/api";

// Props
const props = defineProps({
  acl: { type: Object as () => BaseAclInterface, default: undefined },
  profile: { type: Object as () => ProfileInterface, default: undefined },
  role: { type: Object as () => RoleInterface, default: undefined },
  showCreateButton: { type: Boolean, default: undefined },
});

// Emits
const emits = defineEmits(["on-successful-change"]);

// Mixins
const { toMoney } = useMixin();

// Instances
const instance = getCurrentInstance();
const $message = instance?.proxy?.$message;

// Data Properties
const { role } = toRefs(props);
const loading = ref<boolean>(false);
const latestRoleExtension = ref<RoleExtensionInterface | undefined>(undefined);
const applyRoleExtensionModalVisible = ref<boolean>(false);
const updateRoleExtensionModalVisible = ref<boolean>(false);
const deleteRoleExtensionModalVisible = ref<boolean>(false);
const createRoleExtensionModalVisible = ref<boolean>(false);
const currentRole = ref<RoleInterface>(role.value);

// Lifecycle methods
onMounted(() => {
  refreshLatestRoleExtension();
});

// Component methods
const showApplyRoleExtensionModal = (): void => {
  apiGetRole(props.role.id)
    .then((res: any) => {
      currentRole.value = res.data.data;
      applyRoleExtensionModalVisible.value = true;
    })
    .catch(() => $message?.error("Couldn't fetch role. Try again!", 3));
};

const showUpdateRoleExtensionModal = (): void => {
  updateRoleExtensionModalVisible.value = true;
};

const showDeleteRoleExtensionModal = (): void => {
  deleteRoleExtensionModalVisible.value = true;
};

const showCreateRoleExtensionModal = (): void => {
  createRoleExtensionModalVisible.value = true;
};

const getInitiatedByFormattedString = (): string => {
  return convertInitiatedByToFormattedName(latestRoleExtension.value!.initiated_by);
};

const isEndDateModified = (): boolean => {
  return props.role.end_date != latestRoleExtension.value!.end_date;
};

const isHourlyRateModified = (): boolean => {
  return (
    props.role.hourly_rate?.value != latestRoleExtension.value!.hourly_rate?.value ||
    props.role.hourly_rate?.currency_code != latestRoleExtension.value!.hourly_rate?.currency_code
  );
};

const isHourlyRateDateModified = (): boolean => {
  return (
    !!latestRoleExtension.value!.future_hourly_rate_date &&
    props.role.future_hourly_rate_date != latestRoleExtension.value!.future_hourly_rate_date
  );
};

const isWorkloadModified = (): boolean => {
  return props.role.workload != latestRoleExtension.value!.workload;
};

const isNoticePeriodModified = (): boolean => {
  return props.role.notice_period != latestRoleExtension.value!.notice_period;
};

const roleExtensionApprovedButNotApplied = (): boolean => {
  return (
    latestRoleExtension.value!.colleague_accepted == true &&
    latestRoleExtension.value!.pending == true
  );
};

const canApplyRoleExtension = (): boolean => {
  return props.acl.isAdmin || props.acl.isCr;
};

const roleExtensionWasPutUpForDiscussion = (): boolean => {
  return latestRoleExtension.value!.colleague_accepted == false;
};

const colleageCanApproveRoleExtension = (): boolean => {
  return latestRoleExtension.value!.colleague_accepted != true;
};

const colleageCanRejectRoleExtension = (): boolean => {
  return latestRoleExtension.value!.colleague_accepted != false;
};

const isOwnRoleExtension = () => {
  return (
    latestRoleExtension.value!.role && latestRoleExtension.value!.role.user.id == props.profile.id
  );
};

const canMakeDecisionOnRoleExtension = () => {
  return isOwnRoleExtension() && latestRoleExtension.value!.pending;
};

const refreshLatestRoleExtension = (): void => {
  loading.value = true;
  apiGetLatestRoleExtension(props.role.id)
    .then((res: any) => {
      latestRoleExtension.value = res.data.data;
    })
    .catch((err) => {
      if (err.status !== 404) {
        $message?.error("Couldn't fetch latest role extension!", 3);
      }
      latestRoleExtension.value = undefined;
    })
    .finally(() => (loading.value = false));
};

const handleDecideRoleExtension = (approved: boolean): void => {
  if (latestRoleExtension.value) {
    latestRoleExtension.value.colleague_accepted = approved;

    apiUpdateRoleExtension(latestRoleExtension.value)
      .then(() => refreshLatestRoleExtension())
      .then(() => emits("on-successful-change"))
      .then(() => {
        let message =
          latestRoleExtension.value && latestRoleExtension.value.colleague_accepted
            ? "Successfully approved role extension!"
            : "CR will be notified that you wish to discuss this role extension further";
        $message?.success(message, 3);
      })
      .catch(() => $message?.error("Couldn't send decision for role extension!", 3));
  }
};

const handleUpdateRoleExtension = (updatedRoleExtension: RoleExtensionInterface): void => {
  apiUpdateRoleExtension(updatedRoleExtension)
    .then(() => refreshLatestRoleExtension())
    .then(() => emits("on-successful-change"))
    .then(() => $message?.success("Role extension updated successfully!", 3))
    .catch(() => $message?.error("Couldn't update role extension!", 3));
};

const handleReissueRoleExtension = (updatedRoleExtension: RoleExtensionInterface): void => {
  apiDeleteRoleExtension(updatedRoleExtension)
    .then(() => {
      apiCreateRoleExtension(updatedRoleExtension.role_id!, updatedRoleExtension)
        .then(() => refreshLatestRoleExtension())
        .then(() => emits("on-successful-change"))
        .then(() => $message?.success("Successfully re-issued role extension!", 3))
        .catch(() => $message?.error("Couldn't re-issue role extension!", 3));
    })
    .catch(() =>
      $message?.error("Couldn't re-issue role extension (destroyed but not re-created)!", 3)
    );
};

const handleCreateRoleExtension = (
  roleId: number,
  newRoleExtension: RoleExtensionInterface
): void => {
  apiCreateRoleExtension(roleId, newRoleExtension)
    .then(() => refreshLatestRoleExtension())
    .then(() => emits("on-successful-change"))
    .then(() => $message?.success("Role extension successfully created!", 3))
    .catch(() => $message?.error("Couldn't create role extension!", 3));
};

const handleApplyRoleExtension = (roleExtension: RoleExtensionInterface): void => {
  apiApplyRoleExtension(roleExtension)
    .then(() => refreshLatestRoleExtension())
    .then(() => emits("on-successful-change"))
    .then(() => $message?.success("Successfully applied role extension!", 3))
    .catch(() => $message?.error("Couldn't apply role extension!", 3));
};

const handleDeleteRoleExtension = (roleExtension: RoleExtensionInterface): void => {
  apiDeleteRoleExtension(roleExtension)
    .then(() => refreshLatestRoleExtension())
    .then(() => emits("on-successful-change"))
    .then(() => $message?.success("Successfully deleted role extension!", 3))
    .catch(() => $message?.error("Couldn't delete role extension!", 3));
};
</script>
