<template>
  <a-modal
    :visible="visible"
    :destroy-on-close="true"
    title="Add Skill Category"
    ok-text="Save"
    @ok="() => handleStoreSkillCategory()"
    @cancel="() => $emit('close')"
  >
    <a-form layout="horizontal">
      <SkInput
        v-model="newSkillCategory.title"
        v-validate="'required|max:255'"
        :error="$validator.errors.first('title')"
        data-vv-name="title"
        label="Title"
        placeholder="Skill category title"
      />
    </a-form>
  </a-modal>
</template>

<script setup lang="ts">
import { getCurrentInstance, ref, watch } from "vue";
import useMixin from "@/useMixin";

// Props
const props = defineProps({
  visible: { type: Boolean, default: false },
});

// Emits
const emits = defineEmits(["store-skill-category", "close"]);

// Mixins
const { setObject } = useMixin();

// Instances
const instance = getCurrentInstance();
const $validator = instance?.proxy.$validator;

// Data properties
const newSkillCategory = ref<any>({
  title: undefined,
});

// Watchers
watch(
  () => props.visible,
  (newProp, oldProp) => {
    if (newProp && !oldProp) {
      newSkillCategory.value = setObject(newSkillCategory, undefined);
    }
  }
);

// Class methods
const handleStoreSkillCategory = (): void => {
  $validator?.validateAll().then((result) => {
    if (result) {
      emits("store-skill-category", newSkillCategory.value);
      emits("close");
    }
  });
};
</script>
