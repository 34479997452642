<template>
  <div>
    <a-row type="flex" justify="space-between">
      <a-col>
        <h3>Skills</h3>
      </a-col>
      <a-col>
        Sort:
        <a class="ml-1" @click="sortAllSkills('custom')">custom</a>
        <a-divider type="vertical" />
        <a @click="sortAllSkills('alphabetical')">alphabetical</a>
        <a-divider type="vertical" />
        <a-input v-model="search" size="small" placeholder="Search..." class="search-field-width" />
        <a-divider type="vertical" />
        <a @click="setSkills([])">none</a>
        <a-divider type="vertical" />
        <a @click="setSkills(cvData?.user.skills ?? [])">users</a>
      </a-col>
    </a-row>

    <a-row type="flex">
      <draggable
        v-model="allSkills"
        class="cv-skills"
        :disabled="search !== ''"
        @change="handleSortOrderChange"
      >
        <a-tag
          v-for="skill in filteredSkills"
          :key="skill.id"
          :color="selected(skill) ? 'blue' : ''"
          class="mb-2"
          @click="handleSkillClick(skill)"
        >
          {{ skill.title }}
        </a-tag>
      </draggable>
    </a-row>

    <h4>Highlight specific skills</h4>
    <SkNameSelect
      v-model="highlightedSkillIds"
      label="Highlight skills"
      mode="multiple"
      placeholder="Select skills to highlight"
      :options="skills"
      option-attribute-name="title"
      @change="handleHighlightSkill"
    />
  </div>
</template>

<script setup lang="ts">
import { emitter } from "@/mitt";
import { ref, ComputedRef, computed, onMounted, onUnmounted, toRefs } from "vue";
import { SkillInterface } from "../../skills/types";
import { CvInterface, CvDataInterface, SkillCvInterface } from "../types";
import { sortSkillsByCustomSortOrder } from "@/modules/cv/_utils/sorting";
import draggable from "vuedraggable";

// Props
const props = defineProps({
  cv: { type: Object as () => CvInterface, default: undefined },
  cvData: { type: Object as () => CvDataInterface, default: undefined },
  step: { type: Number, default: -1 },
});

// Emits
const emits = defineEmits(["cv-change", "go-to-step"]);

// Data Properties
const { cv, cvData } = toRefs(props);
const skills = ref<Array<SkillCvInterface>>(cv?.value?.skills ?? []);
const allSkills = ref<Array<SkillInterface>>(cvData?.value?.skills ?? []);
const search = ref<string>("");
const highlightedSkillIds = ref<Array<number>>(
  cv?.value?.skills.filter((s) => s.highlight)?.map((s) => s.skill_id) ?? []
);

// Computed Properties
const nonHighlightedSkills: ComputedRef<Array<SkillInterface>> = computed(() => {
  return allSkills.value.filter((skill) => !highlightedSkillIds.value.includes(skill.id));
});

const filteredSkills: ComputedRef<Array<SkillInterface>> = computed(() => {
  if (search.value) {
    return nonHighlightedSkills.value.filter(
      (skill: SkillInterface) => skill.title.toLowerCase().indexOf(search.value.toLowerCase()) >= 0
    );
  } else {
    return nonHighlightedSkills.value;
  }
});

// Life-cycle Hooks
onMounted(() => {
  emitter.on(`validate-cv-step-${props.step}`, (nextStep) => validate(nextStep as string));

  // When opening the menu, let skills shown on the cv be in cv order above the skills not shown on the cv
  sortAllSkills("custom");
});

onUnmounted(() => {
  emitter.off(`validate-cv-step-${props.step}`, (nextStep) => validate(nextStep as string));
});

// Component methods
const validate = (nextStep: string) => {
  // Nothing to validate here
  emits("cv-change", "skills", skills.value);
  emits("go-to-step", nextStep);
};

const handleSkillClick = (skill: SkillInterface) => {
  skills.value = skills.value.some((sk) => sk.id === skill.id)
    ? skills.value.filter((sk) => sk.id !== skill.id)
    : [
        ...skills.value,
        {
          ...skill,
          skill_id: skill.id,
          highlight: props.cv?.skills.some((hsk) => hsk.id === skill.id && hsk.highlight),
        } as SkillCvInterface,
      ];

  emits("cv-change", "skills", skills.value);
};

const selected = (skill: SkillInterface) => {
  return skills.value.some((sk) => sk.id === skill.id);
};

const setSkills = (s: SkillInterface[]) => {
  skills.value = s.map(
    (sk) =>
      ({
        ...sk,
        skill_id: sk.id,
        highlight: props.cv?.skills.some((hsk) => hsk.id === sk.id && hsk.highlight),
      }) as SkillCvInterface
  );
  emits("cv-change", "skills", skills.value);
};

const handleHighlightSkill = () => {
  skills.value = skills.value.map((sk) => ({
    ...sk,
    highlight: highlightedSkillIds.value.some((s) => s === sk.id),
  }));
  allSkills.value = sortSkillsByCustomSortOrder(
    allSkills.value,
    skills.value,
    (awe) => awe.id,
    (cwe) => cwe.id
  );
  handleSortOrderChange();
  emits("cv-change", "skills", skills.value);
};

const sortAllSkills = (how: "custom" | "alphabetical") => {
  if (how === "alphabetical") {
    allSkills.value.sort((a, b) => a.title.localeCompare(b.title));
  }
  if (how === "custom") {
    allSkills.value = sortSkillsByCustomSortOrder(
      allSkills.value,
      skills.value,
      (awe) => awe.id,
      (cwe) => cwe.id
    );
  }
};

const handleSortOrderChange = () => {
  skills.value = sortSkillsByCustomSortOrder(
    skills.value,
    allSkills.value,
    (sk) => sk.id,
    (ask) => ask.id
  );
};
</script>

<style scoped>
.search-field-width {
  width: 100px;
  height: 20px;
}
</style>
