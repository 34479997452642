<template>
  <a-form-item
    :label-col="{ span: labelColSize }"
    :wrapper-col="{ span: wrapperColSize }"
    :label="label"
    :validate-status="error ? 'error' : ''"
    :help="error"
  >
    <a-select
      :value="value"
      :mode="mode"
      :placeholder="placeholder"
      :allow-clear="allowClear"
      class="w-100"
      show-search
      :options="searchCompatibleOptions"
      :filter-option="applyFuzzyFilter"
      :disabled="disabled"
      @change="handleChange"
    />
  </a-form-item>
</template>

<script setup lang="ts">
import { ref, onMounted, VNode, watch } from "vue";

interface SearchElement {
  label: string;
  key: number;
}

const props = defineProps({
  value: { type: [Number, Array], default: undefined },
  label: { type: String, default: "" },
  error: { type: String, default: "" },
  placeholder: { type: String, default: "" },
  labelColSize: { type: Number, default: 8 },
  wrapperColSize: { type: Number, default: 16 },
  allowClear: { type: Boolean, default: false },
  mode: { type: String, default: "" },
  options: { type: Array as () => Array<Record<string, any>>, default: () => [] },
  optionAttributeName: { type: String, default: "name" },
  disabled: { type: Boolean, default: false },
});

const emit = defineEmits(["input", "change"]);

const handleChange = (id: string): void => {
  emit("input", id);
  emit("change", id);
};

//Data properties
const searchCompatibleOptions = ref<SearchElement[]>([]);

//Lifecycle methods
onMounted(() => {
  updateSearchCompatibleList(props.options);
});

// Watchers
watch(
  () => props.options,
  (newOptions: Array<Record<string, any>>) => {
    updateSearchCompatibleList(newOptions);
  }
);

const applyFuzzyFilter = (inputValue: string, option: VNode): boolean => {
  if (option && option.componentOptions && option.componentOptions.children) {
    const inputValueSimplified = removeDiacritics(inputValue.toLowerCase());
    const listElementValue = option.componentOptions.children[0].text;
    if (listElementValue) {
      const listElementValueSimplified = removeDiacritics(listElementValue.toLowerCase());
      return (
        listElementValue.includes(inputValueSimplified) ||
        listElementValueSimplified.includes(inputValueSimplified)
      );
    }
  }
  return false;
};

const updateSearchCompatibleList = (list: Array<Record<string, any>>): void => {
  searchCompatibleOptions.value = list.map((elem: Record<string, any>) => {
    return {
      key: elem.id,
      label: elem[props.optionAttributeName],
    };
  });
};

const removeDiacritics = (val: string): string => {
  return val.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};
</script>
