<template>
  <div>
    <a-form layout="horizontal" :data-vv-scope="ReportType.DETACHEDTIME">
      <a-form-item
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
        :validate-status="$validator.errors.first('detached-time-report.amount') ? 'error' : ''"
        :help="$validator.errors.first('detached-time-report.amount')"
        label="Amount of hours"
      >
        <a-input-number
          v-model="newReport.amount"
          v-validate="'required|integer|min_value:1|max_value:24'"
          :min="0"
          data-vv-name="amount"
          placeholder="Hours worked"
          class="w-100"
        />
      </a-form-item>

      <SkInput
        v-model="hourlyRateInput"
        v-validate="'required|decimal|min_value:0'"
        :error="$validator.errors.first('detached-time-report.hourly_rate_input')"
        :addon-after="newReport.hourly_rate_currency"
        data-vv-name="hourly_rate_input"
        data-vv-as="hourly rate"
        label="Hourly rate"
        placeholder="Hourly rate"
        @set-currency="(v) => (newReport.rate_currency = v)"
      />

      <a-form-item
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
        :validate-status="$validator.errors.first('detached-time-report.start_date') ? 'error' : ''"
        :help="$validator.errors.first('detached-time-report.start_date')"
        label="Date"
      >
        <a-date-picker
          v-validate="'required'"
          :value="parseDate(newReport.start_date)"
          :disabled-date="disabledDateBeforeLastLock"
          data-vv-name="start_date"
          data-vv-as="date"
          class="w-100"
          @change="(d) => onDatesChange(d, 'start_date')"
        />
      </a-form-item>

      <a-form-item
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
        :validate-status="
          $validator.errors.first('detached-time-report.cost_center_id') ? 'error' : ''
        "
        :help="$validator.errors.first('detached-time-report.cost_center_id')"
        label="Cost center"
      >
        <a-select
          v-model="newReport.cost_center_id"
          v-validate="'required'"
          placeholder="Select a cost center"
          data-vv-name="cost_center_id"
          data-vv-as="cost center"
        >
          <a-select-option v-for="c in costCenters" :key="c.id" :value="c.id">
            {{ c.name }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
        label="Comment"
        :validate-status="$validator.errors.first('detached-time-report.comment') ? 'error' : ''"
        :help="$validator.errors.first('detached-time-report.comment')"
      >
        <a-textarea
          v-model="newReport.comment"
          v-validate="'required'"
          :auto-size="{ minRows: 2 }"
          data-vv-name="comment"
          data-vv-as="comment"
        />
      </a-form-item>
    </a-form>

    <div class="d-flex justify-content-center mt-4">
      <a-button type="primary" style="width: 50%" @click="handleStoreReport()">Submit</a-button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, onMounted, onUnmounted, getCurrentInstance, toRefs } from "vue";
import { EmploymentTypes, ProfileInterface } from "@/modules/authentication/types";
import { ReportPayloadInterface, ReportInterface, ReportType } from "../types";
import { CostCenterInterface } from "@/modules/cost_centers/types";
import useMixin from "@/useMixin";
import moment from "@/date";
import useFilters from "@/useFilters";
import { emitter } from "@/mitt";

// Props
const props = defineProps({
  user: { type: Object as () => ProfileInterface, default: undefined },
  costCenters: { type: Array as () => Array<CostCenterInterface>, default: () => [] },
  reports: { type: Map<String, Array<ReportInterface>>, default: () => [] },
  lastReportedHourlyRate: { type: Object, default: undefined },
  selectedDay: { type: String, default: undefined },
  reportToCopyFrom: { type: Object as () => ReportInterface, default: undefined },
});

// Emits
const emits = defineEmits(["day-selected", "store-report"]);

// Mixins
const { setObject } = useMixin();

// Filters
const { parseDate } = useFilters();

// Instances
const instance = getCurrentInstance();
const $confirm = instance?.proxy.$confirm;
const $validator = instance?.proxy.$validator;

// Data Properties
const { user, selectedDay } = toRefs(props);
const newReport = ref<ReportPayloadInterface>({
  user_id: user.value.id,
  amount: undefined,
  hourly_rate_cents: 0,
  hourly_rate_currency: "SEK",
  start_date: moment(selectedDay.value).format("YYYY-MM-DD"),
  end_date: undefined,
  comment: undefined,
  cost_center_id: undefined,
  type: ReportType.DETACHEDTIME,
});
const hourlyRateInput = ref<number>(0);

// Watchers
watch(hourlyRateInput, (newProp) => {
  if (newProp) {
    newReport.value.hourly_rate_cents = Number((newProp * 100).toFixed(0));
  }
});

watch(
  () => props.reportToCopyFrom,
  (newProp) => {
    copyFromReport(newProp);
  }
);

onMounted(() => {
  emitter.on("selected-date", (date) => handleSelectedDate(date));
  if (props.reportToCopyFrom) {
    copyFromReport(props.reportToCopyFrom);
  }
});

onUnmounted(() => {
  emitter.off("selected-date", (date) => handleSelectedDate(date));
});

// Component Methods
const copyFromReport = (newProp: ReportInterface) => {
  if (newProp && newProp.user && newProp.cost_center) {
    newReport.value.user_id = newProp.user.id;
    newReport.value.amount = newProp.amount;
    newReport.value.hourly_rate_cents = newProp.hourly_rate.value;
    newReport.value.hourly_rate_currency = newProp.hourly_rate.currency;
    newReport.value.comment = newProp.comment;
    newReport.value.cost_center_id = newProp.cost_center.id;
    newReport.value.type = newProp.type;
    hourlyRateInput.value = (newProp.hourly_rate.value ?? 0) / 100;
  }
};

const handleSelectedDate = (date: any): void => {
  if (!disabledDateBeforeLastLock(date)) {
    newReport.value.start_date = moment(date).format("YYYY-MM-DD");
  }
};

const onDatesChange = (date: any, attribute: string): void => {
  newReport.value[attribute] = date ? date.format("YYYY-MM-DD") : undefined;

  if (attribute === "start_date") {
    emits("day-selected", newReport.value[attribute]);
  }
};

const confirmWeeklyRestHours = (): Promise<any> => {
  return new Promise<void>((resolve, reject) => {
    if (displayRestConfirmationModal()) {
      $confirm?.({
        title: "Remember to respect the weekly rest",
        content: "You need at least 36 consecutive work free hours every week.",
        okText: "Proceed",
        cancelText: "Cancel",
        onOk: () => resolve(),
        onCancel: () => reject(),
      });
    } else {
      resolve();
    }
  });
};

const handleStoreReport = (): void => {
  $validator?.validateAll(ReportType.DETACHEDTIME).then((result) => {
    if (result) {
      confirmWeeklyRestHours()
        .then(() => {
          if (typeof newReport.value.hourly_rate_cents == "number") {
            emitReportAndClearFields();
          }
        })
        .catch(() => {});
    }
  });
};

const emitReportAndClearFields = (): void => {
  emits("store-report", newReport.value);
  const previous_date = newReport.value.start_date;

  newReport.value = setObject(newReport.value, undefined);
  newReport.value.hourly_rate_cents = props.lastReportedHourlyRate
    ? props.lastReportedHourlyRate.value
    : 0;
  newReport.value.hourly_rate_currency = props.lastReportedHourlyRate
    ? props.lastReportedHourlyRate.currency_code
    : "SEK";
  newReport.value.user_id = props.user?.id;
  newReport.value.type = ReportType.DETACHEDTIME;
  newReport.value.start_date = previous_date;

  if (previous_date != newReport.value.start_date && newReport.value.start_date)
    emits("day-selected", newReport.value.start_date);
};

const disabledDateBeforeLastLock = (current: any): boolean => {
  return current && current <= moment(props.user?.report_locking_date).endOf("day");
};

const displayRestConfirmationModal = (): boolean => {
  const isWeekend = [6, 0].includes(moment(newReport.value.start_date!).day());
  return (
    newReport.value.type === ReportType.TIME &&
    isWeekend &&
    props.user?.employment_type != EmploymentTypes.HOURLY
  );
};
</script>
