<template>
  <a-layout-content class="my-4 mx-3">
    <div class="container">
      <h2>Job Postings</h2>

      <div class="d-flex flex-row">
        <div v-if="!selectedJobPosting || !isSmallWindow" class="job-posting-list">
          <job-posting-board
            @load-more-new-job-postings="() => getNewJobPostings(true)"
            @load-more-ongoing-job-postings="() => getOngoingJobPostings(true)"
          />
        </div>
        <div v-if="selectedJobPosting" class="job-posting-detail">
          <job-posting-details />
        </div>
      </div>
    </div>
  </a-layout-content>
</template>

<script setup lang="ts">
import { ref, onMounted, getCurrentInstance, computed } from "vue";
import { useRoute, useRouter } from "vue-router/composables";
import JobPostingDetails from "../_components/JobPostingDetails.vue";
import JobPostingBoard from "../_components/JobPostingBoard.vue";
import { useJobPostingsStore } from "@/modules/job_postings/_store";
import { JobPostingInterface } from "../types";
import { apiGetJobPosting } from "../_utils/api";

// Pinia
const jobPostingsStore = useJobPostingsStore();
const selectedJobPosting = computed<JobPostingInterface | undefined>(
  () => jobPostingsStore.selectedJobPosting
);
const newJobPostings = computed<JobPostingInterface[]>(() => jobPostingsStore.newJobPostings);
const ongoingJobPostings = computed<JobPostingInterface[]>(
  () => jobPostingsStore.ongoingJobPostings
);
const getNewJobPostings = (param: boolean) => jobPostingsStore.getNewJobPostings(param);
const getOngoingJobPostings = (param: boolean) => jobPostingsStore.getOngoingJobPostings(param);

// Instances
const route = useRoute();
const router = useRouter();
const instance = getCurrentInstance();
const $message = instance?.proxy.$message;

// Data properties
const isSmallWindow = ref<boolean>(false);

// Lifecycle methods
onMounted(async () => {
  isSmallWindow.value = determineIsSmallWindow();

  await Promise.all([
    jobPostingsStore.getNewJobPostings(),
    jobPostingsStore.getOngoingJobPostings(),
  ])
    .then(preselectJobPosting)
    .catch(() => $message?.error("Couldn't fetch job postings!", 3));
});

const preselectJobPosting = (): void => {
  if (route.query.id === undefined) {
    return;
  }
  const id = Number.parseInt(route.query.id);

  const jobPosting = [...ongoingJobPostings.value, ...newJobPostings.value].find(
    (jp) => jp.id === id
  );
  if (jobPosting !== undefined) {
    jobPostingsStore.showSelectedJobPostingDetails(jobPosting, router);
    return;
  }

  apiGetJobPosting(id).then(({ data }) => {
    jobPostingsStore.showSelectedJobPostingDetails(data, router);
  });
};

// Component methods
const determineIsSmallWindow = (): boolean => {
  return window.innerWidth <= 1100;
};
</script>

<style lang="css" scoped>
.job-posting-list {
  width: 45%;
  word-break: normal;
  overflow-wrap: anywhere;
}

.job-posting-detail {
  width: 50%;
  margin-left: 16px;
  flex-grow: 1;
}

@media (max-width: 1100px) {
  .job-posting-list {
    width: 100%;
  }

  .job-posting-detail {
    width: 100%;
    margin-left: 0;
  }
}
</style>
