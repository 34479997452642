<template>
  <a-layout-content class="mx-3 my-4">
    <div class="container">
      <a-tabs default-active-key="overview" @change="tabChange">
        <a-tab-pane key="overview" tab="Overview">
          <skill-overview v-if="activeTab === 'overview'" />
        </a-tab-pane>

        <a-tab-pane key="categories" tab="Categories">
          <skill-categories v-if="activeTab === 'categories'" />
        </a-tab-pane>
      </a-tabs>
    </div>
  </a-layout-content>
</template>

<script setup lang="ts">
// Component methods
import { onBeforeMount, ref } from "vue";
import SkillOverview from "@/modules/skills/_components/SkillOverview.vue";
import SkillCategories from "@/modules/skills/_components/SkillCategories.vue";
import { SkillInterface } from "@/modules/skills/types";
import { apiGetSkills } from "@/modules/skills/_utils/api";

const activeTab = ref<tabOptions>("overview");
const skills = ref<Array<SkillInterface>>([]);

// Lifecycle hooks
onBeforeMount(() => {
  apiGetSkills().then(({ data }: any) => (skills.value = data.data));
});

type tabOptions = "overview" | "categories";

const tabChange = (key: tabOptions): void => {
  activeTab.value = key;
};
</script>
