<template>
  <a-layout-content class="m-4">
    <div class="container">
      <div class="d-flex justify-content-between">
        <h2>CVs</h2>
        <portal-target name="cvs-filter-buttons"></portal-target>
      </div>

      <!-- CVS FILTER -->
      <cvs-filter
        :profile="profile"
        :filters="filtersRef"
        :number-of-results="cvsPagination?.total"
        @apply-filters="(filters) => applyFilters(filters)"
      />

      <!-- CVS TABLE -->
      <div class="card">
        <empty-resource-table v-if="!cvsPage[0] && !loading" resource="CVs" />
        <a-table
          v-if="cvsPage[0] || loading"
          :columns="cvsColumnsRef"
          :data-source="cvsPage"
          :pagination="false"
          :loading="loading"
          :scroll="{ x: 1000 }"
          :row-key="(cv) => cv.id"
          @change="(pagination, filters, sorter) => handleTableChange(pagination, filters, sorter)"
        >
          <template #cvName="name, cv">
            <span class="d-flex align-items-center">
              <a-avatar :src="cv.gravatar" size="large" class="mr-2" />
              <router-link :to="{ name: 'cv', params: { id: cv.id } }">
                {{ truncateString(name, 100) }}
              </router-link>
              <a-badge v-if="cv.background" class="ml-2" status="success" />
              <a-badge v-else class="ml-2" status="warning" />
            </span>
          </template>

          <template #cvUpdatedAt="updated_at">
            <span>
              {{ printCvUpdatedDate(updated_at) }}
            </span>
          </template>
        </a-table>
      </div>

      <!-- PAGINATION -->
      <a-row type="flex" justify="end" class="mt-2">
        <a-pagination
          v-if="loading || cvsPage.length > 0"
          :current="currentPage"
          :total="cvsPagination?.total"
          :show-size-changer="true"
          :page-size="pageSizeRef"
          :page-size-options="['16', '32', '64', '96']"
          size="small"
          @change="(newPage, newSize) => getCvsPage({ page: newPage, pageSize: newSize })"
          @showSizeChange="(newPage, newSize) => getCvsPage({ page: newPage, pageSize: newSize })"
        />
      </a-row>
    </div>
  </a-layout-content>
</template>

<script lang="ts">
import { defineComponent, ref, computed, getCurrentInstance, onBeforeMount } from "vue";
import EmptyResourceTable from "../../common/_components/EmptyResourceTable.vue";
import { PaginationInterface, TableColumnInterface } from "@/common/types";
import { useAuthenticationStore } from "@/modules/authentication/_store";
import { CvInterface, CvsFilterInterface } from "../types";
import CvsFilter from "../_components/CvsFilter.vue";
import { cvsColumns } from "../_utils/tables";
import { apiGetCvs } from "../_utils/api";
import useMixin from "@/useMixin";
import { ProfileInterface } from "@/modules/authentication/types";

export default defineComponent({
  components: {
    EmptyResourceTable,
    CvsFilter,
  },
  // Router guards
  beforeRouteEnter(to: any, from: any, next: any) {
    const authenticationStore = useAuthenticationStore();
    authenticationStore.isInternal ? next() : next({ name: "not-found" });
  },
  setup() {
    // Pinia
    const authenticationStore = useAuthenticationStore();
    const profile = computed<ProfileInterface | undefined>(() => authenticationStore.profile);

    // Mixins
    const { qsDecode, formatDateForCV, truncateString, syncRouteParams } = useMixin();

    // Data properties
    const cvsPagination = ref<PaginationInterface | undefined>(undefined);
    const cvsColumnsRef = ref<Array<TableColumnInterface>>(cvsColumns);
    const filtersRef = ref<CvsFilterInterface>({ active: true });
    const cvsPage = ref<Array<CvInterface>>([]);
    const cvs = ref<Array<CvInterface>>([]);
    const pageSizeRef = ref<number>(10);
    const loading = ref<boolean>(false);
    const currentPage = ref<number>(1);
    const sortingRef = ref<any>({});

    // Instance
    const instance = getCurrentInstance();
    const $route = instance?.proxy.$route;

    // Life-cycle Hooks
    onBeforeMount(() => {
      getCvsPageFromUrl();
    });

    // Component Methods
    const getCvsPage = ({
      page,
      pageSize = pageSizeRef.value,
      filters = filtersRef.value,
      sorting = sortingRef.value,
    }: {
      page: any;
      pageSize?: number;
      filters?: any;
      sorting?: any;
    }) => {
      loading.value = true;
      currentPage.value = page;
      pageSizeRef.value = pageSize;
      syncRouteParams(
        {
          page: currentPage.value,
          pageSize: pageSizeRef.value,
          filters: filtersRef.value,
          sorting: sortingRef.value,
        },
        instance
      );

      apiGetCvs({
        page,
        page_size: pageSize,
        ...filters,
        ...sorting,
      }).then((data: any) => {
        loading.value = false;
        cvs.value = data.data.data;
        cvsPage.value = data.data.data;
        cvsPagination.value = data.data.meta;
      });
    };

    const getCvsPageFromUrl = (url: any = $route?.query) => {
      const { page, filters, sorting, pageSize } = qsDecode(url);

      if (page) currentPage.value = parseInt(page);
      if (filters) filtersRef.value = filters;
      if (sorting) sortingRef.value = sorting;
      if (pageSize) pageSizeRef.value = parseInt(pageSize);

      getCvsPage({ page: currentPage.value });
    };

    const applyFilters = (filters: any): void => {
      filtersRef.value = filters;
      currentPage.value = 1;
      syncRouteParams(
        {
          page: currentPage.value,
          pageSize: pageSizeRef.value,
          filters: filtersRef.value,
          sorting: sortingRef.value,
        },
        instance
      );
      getCvsPage({ page: currentPage.value });
    };

    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
      sortingRef.value = {
        sort: sorter.columnKey,
        order: sorter.columnKey ? (sorter.order === "descend" ? "desc" : "asc") : undefined,
      };

      syncRouteParams(
        {
          page: currentPage.value,
          pageSize: pageSizeRef.value,
          filters: filtersRef.value,
          sorting: sortingRef.value,
        },
        instance
      );
      getCvsPage({ page: currentPage.value });
    };

    const printCvUpdatedDate = (updated_at: string) => {
      if (updated_at) return formatDateForCV(updated_at);
      else return "-";
    };

    return {
      profile,
      filtersRef,
      cvsPagination,
      applyFilters,
      cvsPage,
      loading,
      cvsColumnsRef,
      handleTableChange,
      truncateString,
      printCvUpdatedDate,
      currentPage,
      pageSizeRef,
      getCvsPage,
    };
  },
});
</script>
