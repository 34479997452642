import Router from "vue-router";

const { NavigationFailureType, isNavigationFailure } = Router;

// Monkey patch vue-router to avoid NavigationDuplicated errors when same path is used during push/replace

function handleNavigationDuplicated(err) {
  if (!isNavigationFailure(err, NavigationFailureType.duplicated)) {
    throw err;
  }
}

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onComplete, onAbort) {
  if (onComplete || onAbort) {
    return originalPush.call(this, location, onComplete, onAbort);
  }

  return originalPush.call(this, location).catch(handleNavigationDuplicated);
};

const originalReplace = Router.prototype.replace;
Router.prototype.replace = function replace(location, onComplete, onAbort) {
  if (onComplete || onAbort) {
    return originalReplace.call(this, location, onComplete, onAbort);
  }

  return originalReplace.call(this, location).catch(handleNavigationDuplicated);
};
