<template>
  <div>
    <!-- HEADER -->
    <div class="d-flex justify-content-between ml-4">
      <h2>Skill Categories</h2>
      <a-button icon="plus" type="primary" @click="addSkillCategoryModalVisible = true" />
    </div>

    <!-- LIST OF SKILLS AS CARDS -->
    <a-row type="flex" justify="center">
      <a-col v-for="skillCategory in skillCategories" :key="skillCategory.id" :span="24" :lg="12">
        <div class="skills-card mb-1 ml-4" style="height: 95%">
          <span class="d-flex justify-content-between">
            <h4>
              <strong>
                {{ skillCategory.title }}
              </strong>
            </h4>
            <div>
              <a-icon
                type="edit"
                class="action-default"
                @click="() => handleEditSkillCategoryModalVisible(skillCategory)"
              />
              <a-divider v-if="baseAcl.isAdmin" type="vertical" />
              <a-icon
                v-if="baseAcl.isAdmin"
                type="delete"
                class="action-danger"
                @click="() => showDeleteSkillCategoryConfirm(skillCategory)"
              />
            </div>
          </span>
          <a-row type="flex">
            <a-tag v-for="skill in skillCategory.skills" :key="skill.id" :color="''" class="mb-2">
              {{ skill.title }}
            </a-tag>
          </a-row>
        </div>
      </a-col>
    </a-row>

    <!-- ADD NEW SKILL MODAL -->
    <add-skill-category-modal
      :visible="addSkillCategoryModalVisible"
      @get-skill-categories="$emit('get-skill-categories')"
      @store-skill-category="(newSkillCategory) => handleStoreSkillCategory(newSkillCategory)"
      @close="addSkillCategoryModalVisible = false"
    />

    <!-- EDIT SKILL MODAL -->
    <edit-skill-category-modal
      :visible="editSkillCategoryModalVisible"
      :skill-category="skillCategoryToEdit"
      :skills="skills"
      @update-skill-category="
        (updatedSkillCategory) => handleUpdateSkillCategory(updatedSkillCategory)
      "
      @close="editSkillCategoryModalVisible = false"
    />
  </div>
</template>

<script setup lang="ts">
import {
  apiDestroySkillCategory,
  apiGetSkillCategories,
  apiGetSkills,
  apiStoreSkillCategory,
  apiUpdateSkillCategory,
} from "@/modules/skills/_utils/api";
import { computed, getCurrentInstance, onBeforeMount, ref } from "vue";
import {
  SkillCategoryInterface,
  SkillCategoryPayloadInterface,
  SkillInterface,
} from "@/modules/skills/types";
import AddSkillCategoryModal from "@/modules/skills/_components/AddSkillCategoryModal.vue";
import EditSkillCategoryModal from "@/modules/skills/_components/EditSkillCategoryModal.vue";
import { useAuthenticationStore } from "@/modules/authentication/_store";
import { BaseAclInterface } from "@/modules/authentication/types";

// Pinia
const authenticationStore = useAuthenticationStore();
const baseAcl = computed<BaseAclInterface>(() => authenticationStore.baseAcl);

// Instances
const instance = getCurrentInstance();
const $message = instance?.proxy.$message;
const $confirm = instance?.proxy.$confirm;

// Data properties
const skillCategories = ref<Array<SkillCategoryInterface>>([]);
const skillCategoryToEdit = ref<SkillCategoryPayloadInterface | undefined>(undefined);
const addSkillCategoryModalVisible = ref<boolean>(false);
const editSkillCategoryModalVisible = ref<boolean>(false);
const skills = ref<Array<SkillInterface>>([]);

// Lifecycle hooks
onBeforeMount(() => {
  apiGetSkillCategories().then(({ data }: any) => (skillCategories.value = data.data));
  apiGetSkills().then(({ data }: any) => (skills.value = data.data));
});

// Component methods
const handleStoreSkillCategory = (newSkillCategory: SkillCategoryPayloadInterface): void => {
  apiStoreSkillCategory(newSkillCategory)
    .then(({ data }: any) => skillCategories.value.push(data.data))
    .then(() => $message?.success("Skill successfully created!", 3))
    .catch(() => $message?.error("Couldn't create skill!", 3));
};

const handleEditSkillCategoryModalVisible = (skillCategory: SkillCategoryInterface): void => {
  skillCategoryToEdit.value = {
    ...skillCategory,
    skills: skillCategory.skills.map((sk) => ({ skill_id: sk.id })),
  };
  editSkillCategoryModalVisible.value = true;
};

const handleUpdateSkillCategory = (updatedSkillCategory: SkillCategoryPayloadInterface): void => {
  apiUpdateSkillCategory(updatedSkillCategory)
    .then(
      ({ data }: any) =>
        (skillCategories.value = skillCategories.value.map((s) =>
          s.id === data.data.id ? data.data : s
        ))
    )
    .then(() => $message?.success("Skill category successfully updated!", 3))
    .catch(() => $message?.error("Couldn't update skill category!", 3));
};

const handleDestroySkillCategory = (skillCategoryId: number): void => {
  apiDestroySkillCategory(skillCategoryId)
    .then(
      () => (skillCategories.value = skillCategories.value.filter((s) => s.id !== skillCategoryId))
    )
    .then(() => $message?.success("Skill category successfully deleted!", 3))
    .catch(() => $message?.error("Couldn't delete skill category!", 3));
};

const showDeleteSkillCategoryConfirm = (skillCategory: SkillCategoryInterface): void => {
  const mod = $confirm?.({
    title: `Are you sure you want to delete the skill category '${skillCategory.title}'?`,
    okText: "Yes",
    okType: "danger",
    cancelText: "No",
    onOk() {
      handleDestroySkillCategory(skillCategory.id);
      mod?.destroy();
    },
  });
};
</script>

<style scoped lang="scss"></style>
