import { TableColumnInterface } from "@/common/types";

export const budgetsColumns: Array<TableColumnInterface> = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    scopedSlots: { customRender: "name" },
  },
  {
    title: "Interval",
    dataIndex: "start_date",
    key: "date",
    scopedSlots: { customRender: "interval" },
  },
  {
    title: "Budget",
    dataIndex: "amount",
    key: "amount",
    scopedSlots: { customRender: "amount" },
  },
  {
    title: "Progress",
    dataIndex: "total_spent_amount",
    key: "total_spent_amount",
    width: 300,
    scopedSlots: { customRender: "progress" },
  },
  {
    title: "Actions",
    key: "actions",
    scopedSlots: { customRender: "actions" },
  },
];
