<template>
  <a-modal
    :visible="visible"
    :destroy-on-close="true"
    title="Edit Profile"
    ok-text="Save"
    @ok="() => handleUpdateProfile()"
    @cancel="() => $emit('close')"
  >
    <a-form layout="horizontal">
      <a-form-item
        :label-col="{ span: 7 }"
        :wrapper-col="{ span: 17 }"
        :validate-status="$validator.errors.first('phone_number') ? 'error' : ''"
        :help="$validator.errors.first('phone_number')"
        label="Phone Number"
      >
        <a-input
          v-model="newProfile.phone_number"
          v-validate="'max:64'"
          data-vv-name="phone_number"
          data-vv-as="phone number"
          placeholder="+46 918273645"
        />
      </a-form-item>

      <a-form-item
        :label-col="{ span: 7 }"
        :wrapper-col="{ span: 17 }"
        :validate-status="$validator.errors.first('address') ? 'error' : ''"
        label="Address"
        help="e.g. Vasagatan 1, 111 20, Stockholm"
      >
        <a-input
          v-model="newProfile.address"
          v-validate="'max:255'"
          data-vv-name="address"
          data-vv-as="address"
          placeholder="Address"
        />
      </a-form-item>

      <a-form-item
        v-if="acl.isAdmin || acl.isOwner"
        :label-col="{ span: 7 }"
        :wrapper-col="{ span: 17 }"
        :validate-status="$validator.errors.first('birth_date') ? 'error' : ''"
        :help="$validator.errors.first('birth_date')"
        label="Date of birth"
      >
        <a-date-picker
          :value="parseDate(newProfile.birth_date)"
          :disabled-date="disabledFutureDate"
          data-vv-name="birth_date"
          data-vv-as="birth date"
          class="w-100"
          @change="(date) => onDateChange(date, 'birth_date')"
        />
      </a-form-item>

      <a-form-item
        :label-col="{ span: 7 }"
        :wrapper-col="{ span: 17 }"
        :validate-status="$validator.errors.first('bitbucket') ? 'error' : ''"
        :help="$validator.errors.first('bitbucket')"
        label="Bitbucket"
      >
        <a-input
          v-model="newProfile.bitbucket"
          v-validate="'max:255'"
          data-vv-name="bitbucket"
          data-vv-as="Bitbucket"
          placeholder="Bitbucket username"
        />
      </a-form-item>

      <a-form-item
        :label-col="{ span: 7 }"
        :wrapper-col="{ span: 17 }"
        :validate-status="$validator.errors.first('github') ? 'error' : ''"
        :help="$validator.errors.first('github')"
        label="GitHub"
      >
        <a-input
          v-model="newProfile.github"
          v-validate="'max:255'"
          data-vv-name="github"
          data-vv-as="GitHub"
          placeholder="GitHub username"
        />
      </a-form-item>

      <a-form-item
        :label-col="{ span: 7 }"
        :wrapper-col="{ span: 17 }"
        :validate-status="$validator.errors.first('skype') ? 'error' : ''"
        :help="$validator.errors.first('skype')"
        label="Skype"
      >
        <a-input
          v-model="newProfile.skype"
          v-validate="'max:255'"
          data-vv-name="skype"
          data-vv-as="Skype"
          placeholder="Skype name"
        />
      </a-form-item>

      <a-form-item
        :label-col="{ span: 7 }"
        :wrapper-col="{ span: 17 }"
        :validate-status="$validator.errors.first('private_email') ? 'error' : ''"
        :help="$validator.errors.first('private_email')"
        label="Private email"
      >
        <a-input
          v-model="newProfile.private_email"
          v-validate="'max:255'"
          data-vv-name="private_email"
          data-vv-as="Private email"
          placeholder="Private email"
        />
      </a-form-item>

      <a-form-item :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }" label="Team">
        <span v-if="user.team" class="ant-form-text">{{ user.team }}</span>
        <span v-if="!user.team" class="ant-form-text">
          <em>not part of a team</em>
        </span>
      </a-form-item>

      <a-form-item
        :label-col="{ span: 7 }"
        :wrapper-col="{ span: 17 }"
        label="T-shirt Size"
        help="Optional to provide, you may withdraw consent of this data by removing this at any time!"
      >
        <a-select v-model="newProfile.tshirt_size" placeholder="Select a t-shirt size">
          <a-select-option value="-">-</a-select-option>
          <a-select-option value="xs">XS</a-select-option>
          <a-select-option value="s">S</a-select-option>
          <a-select-option value="m">M</a-select-option>
          <a-select-option value="l">L</a-select-option>
          <a-select-option value="xl">XL</a-select-option>
          <a-select-option value="2xl">2XL</a-select-option>
          <a-select-option value="3xl">3XL</a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item
        :label-col="{ span: 7 }"
        :wrapper-col="{ span: 17 }"
        :validate-status="$validator.errors.first('expected_workload') ? 'error' : ''"
        :help="$validator.errors.first('expected_workload')"
        label="Expected Workload"
      >
        <a-input
          v-model="newProfile.expected_workload"
          v-validate="'integer|min_value:0|max_value:100'"
          addon-after="%"
          data-vv-name="expected_workload"
          data-vv-as="expected workload"
        />
      </a-form-item>

      <a-form-item
        v-if="acl.isAdmin"
        :label-col="{ span: 7 }"
        :wrapper-col="{ span: 17 }"
        :validate-status="$validator.errors.first('start_date') ? 'error' : ''"
        :help="$validator.errors.first('start_date')"
        label="Start date"
      >
        <a-date-picker
          v-validate="'required'"
          :value="parseDate(newProfile.start_date)"
          data-vv-name="start_date"
          data-vv-as="start date"
          class="w-100"
          @change="(date) => onDateChange(date, 'start_date')"
        />
      </a-form-item>

      <a-form-item
        v-if="acl.isAdmin"
        :label-col="{ span: 7 }"
        :wrapper-col="{ span: 17 }"
        label="End date"
      >
        <a-date-picker
          :value="parseDate(newProfile.end_date)"
          :disabled-date="disabledDateBeforeStart"
          data-vv-name="end_date"
          data-vv-as="end date"
          class="w-100"
          @change="(date) => onDateChange(date, 'end_date')"
        />
      </a-form-item>

      <a-form-item
        v-if="acl.isAdmin"
        :label-col="{ span: 7 }"
        :wrapper-col="{ span: 17 }"
        label="Role"
      >
        <a-select
          v-model="newProfile.roles"
          placeholder="User roles for access control"
          mode="multiple"
        >
          <a-select-option value="default">Default</a-select-option>
          <a-select-option value="financial">Financial</a-select-option>
          <a-select-option value="admin">Admin</a-select-option>
          <a-select-option value="cr">CR</a-select-option>
          <a-select-option value="project_manager">Project manager</a-select-option>
          <a-select-option value="alumni">Alumni</a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script setup lang="ts">
import { ref, watch, getCurrentInstance } from "vue";
import { BaseAclInterface, ProfileInterface } from "../../authentication/types";
import useMixin from "@/useMixin";
import moment from "@/date";

// Props
const props = defineProps({
  user: { type: Object as () => ProfileInterface, default: undefined },
  acl: { type: Object as () => BaseAclInterface, default: undefined },
  visible: { type: Boolean, default: false },
});

// Emits
const emits = defineEmits(["update-profile", "close"]);

// Mixins
const { setObject } = useMixin();

// Instances
const instance = getCurrentInstance();
const $validator = instance?.proxy.$validator;

// Data Properties
const newProfile = ref<any>({
  phone_number: undefined,
  address: undefined,
  birth_date: undefined,
  tshirt_size: undefined,
  bitbucket: undefined,
  github: undefined,
  skype: undefined,
  private_email: undefined,
  start_date: undefined,
  end_date: undefined,
  roles: undefined,
  expected_workload: undefined,
});

// Watchers
watch(
  () => props.visible,
  (newProp, oldProp) => {
    if (newProp && !oldProp) {
      // Set fields to profile current values
      newProfile.value = setObject(newProfile.value, props.user);
      castFieldToPercentage("expected_workload");
    }
  }
);

// Component methods
const parseDate = (date: string): any => {
  return date ? moment(date) : undefined;
};

const handleUpdateProfile = (): void => {
  $validator?.validateAll().then((result) => {
    if (result) {
      // Undefined values are not serialized as query parameters
      if (!newProfile.value.start_date) newProfile.value.start_date = "";
      if (!newProfile.value.end_date) newProfile.value.end_date = "";
      if (!newProfile.value.birth_date) newProfile.value.birth_date = "";
      castFieldToDecimal("expected_workload");

      emits("update-profile", newProfile.value);
      emits("close");
    }
  });
};

const onDateChange = (value: any, attribute: string): void => {
  newProfile.value[attribute] = value ? value.format("YYYY-MM-DD") : undefined;
};

const disabledDateBeforeStart = (current: any): any => {
  return (
    current &&
    newProfile.value.start_date &&
    current < moment(newProfile.value.start_date).startOf("day")
  );
};

const disabledFutureDate = (current: any): any => {
  return current && current > new Date();
};

const castFieldToPercentage = (field: string): void => {
  if (newProfile.value[field]) {
    newProfile.value[field] = Math.round(newProfile.value[field] * 100);
  } else {
    newProfile.value[field] = 0.0;
  }
};

const castFieldToDecimal = (field: string): void => {
  if (newProfile.value[field]) {
    newProfile.value[field] = newProfile.value[field] / 100;
  } else {
    newProfile.value[field] = 0.0;
  }
};
</script>
