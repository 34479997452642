<template>
  <div>
    <div v-if="filterPanelVisible" class="card mb-3">
      <a-row>
        <a-col :lg="12">
          <a-form>
            <SkNameSelect
              v-model="newFilter.created_by"
              label="Question creator"
              :label-col-size="8"
              :wrapper-col-size="12"
              mode="multiple"
              placeholder="Select users"
              :allow-clear="true"
              :options="usersCompactList"
              @change="applyFilters"
            />

            <SkNameSelect
              v-model="newFilter.quiz_tags"
              label="Tags"
              :label-col-size="8"
              :wrapper-col-size="12"
              mode="multiple"
              placeholder="Select tags"
              :allow-clear="true"
              :options="quizTagsList"
              @change="applyFilters"
            />
          </a-form>
        </a-col>

        <a-col :lg="12">
          <a-form>
            <a-form-item :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }" label="Status">
              <a-checkbox-group
                :value="newFilter.answered_status"
                @change="(answered_status) => updateAnsweredStatusFilter(answered_status)"
              >
                <a-checkbox class="ml-2 mt-2 d-block" value="answered">Answered</a-checkbox>
                <a-checkbox class="mt-2 d-block" value="unanswered">Not answered</a-checkbox>
              </a-checkbox-group>
            </a-form-item>
          </a-form>

          <a-form>
            <a-form-item :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }" label="Your answer">
              <a-checkbox-group
                :value="newFilter.correct_status"
                @change="(correct_status) => updateCorrectStatusFilter(correct_status)"
              >
                <a-checkbox class="ml-2 mt-2 d-block" value="correct">Correct</a-checkbox>
                <a-checkbox class="mt-2 d-block" value="incorrect">Incorrect</a-checkbox>
              </a-checkbox-group>
            </a-form-item>
          </a-form>
        </a-col>
      </a-row>
    </div>
    <portal to="quiz-filter-buttons">
      <a-button
        v-if="filterPanelVisible"
        class="mr-2"
        icon="close"
        type="danger"
        @click="() => clearFilters()"
      />
      <a-button
        v-show="filterPanelVisible"
        class="mr-2"
        type="primary"
        icon="up"
        @click="() => toggleFilterPanel()"
      />
      <a-button
        v-if="!filterPanelVisible"
        class="mr-2"
        type="primary"
        icon="filter"
        @click="() => toggleFilterPanel()"
      />
    </portal>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch } from "vue";
import { apiGetQuizTags } from "@/modules/quiz_tags/_utils/api";
import { QuizTagInterface } from "@/modules/quiz_tags/types";
import { MinimalUserInterface } from "@/modules/users/types";
import { QuestionsFilterInterface } from "../types";
import { getUsersCompactList } from "@/api";
import useMixin from "@/useMixin";

// Props
const props = defineProps({
  visible: { type: Boolean, default: false },
  filters: { type: Object as () => QuestionsFilterInterface, default: undefined },
  numberOfResults: { type: Number, default: 0 },
});

// Emits
const emits = defineEmits(["apply-filters"]);

// Mixins
const { setObject } = useMixin();

// Data properties
const filterPanelVisible = ref<boolean>(false);
const usersCompactList = ref<Array<MinimalUserInterface>>([]);
const quizTagsList = ref<Array<QuizTagInterface>>([]);

const newFilter = ref<QuestionsFilterInterface>({
  answered_status: [],
  correct_status: [],
  quiz_tags: [],
  created_by: [],
});

// Computed properties
const filterIsEmpty = computed((): boolean => {
  return Boolean(
    newFilter.value.created_by &&
      newFilter.value.quiz_tags &&
      newFilter.value.correct_status &&
      newFilter.value.answered_status &&
      newFilter.value.created_by.length === 0 &&
      newFilter.value.quiz_tags.length === 0 &&
      newFilter.value.correct_status.length === 0 &&
      newFilter.value.answered_status.length === 0
  );
});

// Watchers
watch(filterPanelVisible, (newProp, oldProp) => {
  if (newProp && !oldProp) {
    handleGetUsersCompactList();
    handleGetQuizTagsList();
    newFilter.value = setObject(newFilter.value, props.filters);
    applyFilters();
  }
});

// Component methods
const toggleFilterPanel = (): void => {
  filterPanelVisible.value = !filterPanelVisible.value;
};

const setFilter = (filter: QuestionsFilterInterface): void => {
  if (filter === undefined) {
    return;
  }
  newFilter.value = setObject(newFilter.value, filter);
};

const updateCorrectStatusFilter = (status_filters: Array<string>): void => {
  newFilter.value.correct_status = status_filters;
  applyFilters();
};

const updateAnsweredStatusFilter = (answered_status_filters: Array<string>): void => {
  newFilter.value.answered_status = answered_status_filters;
  applyFilters();
};

const handleGetUsersCompactList = (): void => {
  getUsersCompactList().then((users) => {
    usersCompactList.value = users;
  });
};

const handleGetQuizTagsList = (): void => {
  apiGetQuizTags().then((quizTags: any) => {
    quizTagsList.value = quizTags.data.data;
  });
};

const applyFilters = (): void => {
  emits("apply-filters", newFilter.value);
};

const clearFilters = (): void => {
  handleClearQuestionsFilter();
  emits("apply-filters", {});
};

const handleClearQuestionsFilter = (): void => {
  newFilter.value = {
    answered_status: [],
    correct_status: [],
    quiz_tags: [],
    created_by: [],
  };
};
</script>
