<template>
  <div class="card">
    <receipts-view
      ref="receiptsview"
      :acl="acl"
      :receipts-filter="userReceiptsFilter"
      :receipts-columns="userReceiptsColumns"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, watch, toRefs } from "vue";
import { BaseAclInterface } from "@/modules/authentication/types";
import ReceiptsView from "../_components/ReceiptsView.vue";
import { userReceiptsColumns } from "../_utils/tables";
import { ReceiptsFilterInterface } from "../types";

// Props
const props = defineProps({
  acl: { type: Object as () => BaseAclInterface, default: undefined },
  userId: { type: Number, default: undefined },
  tab: { type: String, default: undefined },
});

// Data properties
const { userId } = toRefs(props);
const userReceiptsFilter = ref<ReceiptsFilterInterface>({ user_id: [userId.value] });
const receiptsview = ref<InstanceType<typeof ReceiptsView>>();

// Watchers
watch(
  () => props.tab,
  (newProp, oldProp) => {
    if (newProp === "receipts" && oldProp !== "receipts") {
      receiptsview.value?.refreshReceipts();
    }
  }
);
</script>
