<template>
  <div v-if="receiptsPagination">
    <!-- HEADER -->
    <div class="d-flex justify-content-between">
      <h2>{{ title }}</h2>
      <div class="d-flex">
        <a-button
          v-if="bulkUpdateReceiptsButtonVisible"
          type="default"
          class="mr-4"
          @click="bulkUpdateReceiptsModalVisible = true"
        >
          Bulk update
        </a-button>
        <portal-target name="receipts-filter-buttons"></portal-target>
        <a-button
          v-if="acl.isInternal"
          type="primary"
          icon="plus"
          class="ml-2"
          @click="addReceiptModalVisible = true"
        />
      </div>
    </div>

    <!-- RECEIPTS FILTER -->
    <receipts-filter
      v-if="allowFiltering"
      :profile="profile"
      :filters="{ ...filtersRef, ...receiptsFilter }"
      :number-of-results="receiptsPagination.total"
      :total-amount-per-currency="receiptsPagination.total_amount_per_currency"
      :unchangeable-filter="receiptsFilter"
      @apply-filters="applyFilters"
    />

    <!-- RECEIPTS TABLE -->
    <div :class="allowFiltering ? 'card' : ''">
      <receipts-table
        :receipts-pagination="receiptsPagination"
        :receipts="receiptsPage"
        :receipts-columns="receiptsColumns"
        :current-page="currentPage"
        :loading-table="loadingTable"
        :acl="acl"
        :users="usersCompactList"
        :categories="categories"
        :cost-centers="costCenters"
        :page-size="pageSizeRef"
        :bulk-update-receipts-modal-visible="bulkUpdateReceiptsModalVisible"
        @apply-sorting="(newSorting) => applySorting(newSorting)"
        @get-receipts-page="handleGetReceiptsPage"
        @get-categories="handleGetReceiptsCategories"
        @get-cost-centers="getCostCenters"
        @get-users="handleGetUsersCompactList"
        @update-receipt="(updatedReceipt) => handleUpdateReceipt(updatedReceipt)"
        @bulk-update-receipts="
          (receiptIds, newStatus) => handleBulkUpdateReceipts(receiptIds, newStatus)
        "
        @set-bulk-update-receipts-button="(value) => (bulkUpdateReceiptsButtonVisible = value)"
        @close-bulk-update-receipts-modal="bulkUpdateReceiptsModalVisible = false"
        @store-transaction="(newTransaction) => handleStoreTransaction(newTransaction)"
      />
    </div>

    <!-- CREATE NEW RECEIPT MODAL -->
    <add-receipt-modal
      :visible="addReceiptModalVisible"
      :users="usersCompactList"
      :categories="categories"
      :cost-centers="costCenters"
      @store-receipt="handleStoreReceipt"
      @get-users="handleGetUsersCompactList"
      @get-categories="handleGetReceiptsCategories"
      @get-cost-centers="getCostCenters"
      @close="addReceiptModalVisible = false"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, getCurrentInstance, computed, onBeforeMount } from "vue";
import { ReceiptInterface, ReceiptPayloadInterface, ReceiptsFilterInterface } from "../types";
import { PaginationInterface, TableColumnInterface } from "../../../common/types";
import { ProfileInterface } from "@/modules/authentication/types";
import { BaseAclInterface } from "@/modules/authentication/types";
import AddReceiptModal from "../_components/AddReceiptModal.vue";
import { useAuthenticationStore } from "@/modules/authentication/_store";
import ReceiptsFilter from "../_components/ReceiptsFilter.vue";
import { MinimalUserInterface } from "@/modules/users/types";
import ReceiptsTable from "../_components/ReceiptsTable.vue";
import { getUsersCompactList } from "@/api";
import useMixin from "@/useMixin";
import {
  apiGetReceipts,
  apiStoreUserReceipt,
  apiUpdateUserReceipt,
  apiGetReceiptsCategories,
  apiBulkUpdateReceipts,
} from "../_utils/api";
import { useCostCentersStore } from "@/modules/cost_centers/_store";
import { CostCenterInterface } from "@/modules/cost_centers/types";
import { useTransactionsStore } from "@/modules/transactions/_store";

export default defineComponent({
  components: {
    ReceiptsTable,
    ReceiptsFilter,
    AddReceiptModal,
  },
  // Redirect external users to dashboard view
  beforeRouteEnter(to: any, from: any, next: any) {
    const authenticationStore = useAuthenticationStore();
    authenticationStore.isInternal ? next() : next({ name: "dashboard" });
  },
  // Props
  props: {
    receiptsColumns: { type: Array as () => Array<TableColumnInterface>, default: undefined },
    receiptsFilter: { type: Object as () => ReceiptsFilterInterface, default: undefined },
    acl: { type: Object as () => BaseAclInterface, default: undefined },
    allowFiltering: { type: Boolean, default: false },
    title: { type: String, default: "Receipts" },
  },
  setup(props, { expose }) {
    // Pinia
    const authenticationStore = useAuthenticationStore();
    const costCentersStore = useCostCentersStore();
    const transactionsStore = useTransactionsStore();
    const profile = computed<ProfileInterface | undefined>(() => authenticationStore.profile);
    const costCenters = computed<Array<CostCenterInterface>>(() => costCentersStore.cost_centers);
    const getCostCenters = () => costCentersStore.getCostCenters();

    // Mixins
    const { qsDecode, loadTablePageSize, syncRouteParams } = useMixin();

    // Instances
    const instance = getCurrentInstance();
    const $route = instance?.proxy.$route;
    const $message = instance?.proxy.$message;

    // Data properties
    const loadingTable = ref<boolean>(true);
    const addReceiptModalVisible = ref<boolean>(false);
    const usersCompactList = ref<Array<MinimalUserInterface>>([]);
    const filtersRef = ref<ReceiptsFilterInterface>({});
    const sortingRef = ref<any>({ sort: "created_at", order: "desc" });
    const categories = ref<Array<any>>([]);
    const receiptsPage = ref<Array<ReceiptInterface>>([]);
    const receiptsPagination = ref<PaginationInterface | null>(null);
    const currentPage = ref<number>(1);
    const pageSizeRef = ref<number>(25);
    const bulkUpdateReceiptsButtonVisible = ref<boolean>(false);
    const bulkUpdateReceiptsModalVisible = ref<boolean>(false);

    // Lifecycle hooks
    onBeforeMount(() => {
      getReceiptsPageFromURL();
    });

    // Component methods
    const getReceiptsPageFromURL = (url: any = $route?.query): void => {
      const { page, filters, sorting, pageSize } = qsDecode(url);

      pageSizeRef.value = loadTablePageSize();

      if (page) currentPage.value = parseInt(page);
      if (filters) filtersRef.value = filters;
      if (sorting) sortingRef.value = sorting;
      if (pageSize) pageSizeRef.value = parseInt(pageSize);

      handleGetReceiptsPage({
        page: currentPage.value,
        pageSize: pageSizeRef.value,
      });
    };

    const handleGetReceiptsPage = ({
      page,
      pageSize = pageSizeRef.value,
      filters = filtersRef.value,
      sorting = sortingRef.value,
    }: {
      page: any;
      pageSize?: number;
      filters?: object;
      sorting?: object;
    }): void => {
      currentPage.value = parseInt(page);
      filtersRef.value = filters;
      sortingRef.value = sorting;
      pageSizeRef.value = pageSize;
      loadingTable.value = true;

      if (props.allowFiltering) {
        syncRouteParams(
          {
            page: currentPage.value,
            pageSize: pageSizeRef.value,
            filters: filtersRef.value,
            sorting: sortingRef.value,
          },
          instance
        );
      }

      apiGetReceipts({
        page,
        page_size: pageSize,
        ...{ ...filters, ...props.receiptsFilter },
        ...sorting,
      }).then((res: any) => {
        loadingTable.value = false;
        receiptsPage.value = res.data.data;
        receiptsPagination.value = res.data.meta;
      });
    };

    const applyFilters = (filters: any): void => {
      filtersRef.value = filters;
      currentPage.value = 1;

      syncRouteParams(
        {
          page: currentPage.value,
          pageSize: pageSizeRef.value,
          filters: filtersRef.value,
          sorting: sortingRef.value,
        },
        instance
      );
      refreshReceipts();
    };

    const applySorting = (sorting: any): void => {
      sortingRef.value = sorting;

      syncRouteParams(
        {
          page: currentPage.value,
          pageSize: pageSizeRef.value,
          filters: filtersRef.value,
          sorting: sortingRef.value,
        },
        instance
      );
      refreshReceipts();
    };

    const handleStoreReceipt = (newReceipt: ReceiptPayloadInterface): void => {
      loadingTable.value = true;
      apiStoreUserReceipt(profile.value?.id as number, newReceipt)
        .then(() => refreshReceipts())
        .then(() => $message?.success("Receipt stored successfully!", 3))
        .catch(() => $message?.error("Couldn't store receipt!", 3))
        .finally(() => (loadingTable.value = false));
    };

    const handleUpdateReceipt = (payload: ReceiptInterface): void => {
      loadingTable.value = true;
      apiUpdateUserReceipt(payload)
        .then(() => refreshReceipts())
        .then(() => $message?.success("Receipt updated successfully!", 3))
        .catch(() => $message?.error("Couldn't update receipt!", 3))
        .finally(() => (loadingTable.value = false));
    };

    const handleBulkUpdateReceipts = (receiptIds: Array<number>, newStatus: string): void => {
      loadingTable.value = true;
      apiBulkUpdateReceipts(receiptIds, newStatus)
        .then((res: any) => {
          const nbReceiptsUpdated = res.data.nb_receipts_updated;
          if (nbReceiptsUpdated !== receiptIds.length) {
            $message?.error(
              `Only ${nbReceiptsUpdated} out of ${receiptIds.length} receipts updated successfully!`,
              3
            );
          }
        })
        .then(() => refreshReceipts())
        .then(() => $message?.success("Receipts updated successfully!", 3))
        .catch(() => $message?.error("Couldn't update receipts!", 3))
        .finally(() => (loadingTable.value = false));
    };

    const handleStoreTransaction = (newTransaction: any): void => {
      transactionsStore
        .storeTransaction(newTransaction)
        .then(() => $message?.success("Transaction created successfully!", 3))
        .catch(() => $message?.error("Couldn't create transaction!", 3));
    };

    const handleGetUsersCompactList = (): void => {
      getUsersCompactList().then((users) => (usersCompactList.value = users));
    };

    const handleGetReceiptsCategories = (): void => {
      apiGetReceiptsCategories().then((res: any) => (categories.value = res.data.data));
    };

    const refreshReceipts = (): void => {
      handleGetReceiptsPage({ page: currentPage.value });
    };

    expose({ refreshReceipts, currentPage });

    return {
      bulkUpdateReceiptsButtonVisible,
      bulkUpdateReceiptsModalVisible,
      handleGetReceiptsCategories,
      handleGetUsersCompactList,
      handleBulkUpdateReceipts,
      handleStoreTransaction,
      addReceiptModalVisible,
      handleGetReceiptsPage,
      handleUpdateReceipt,
      handleStoreReceipt,
      receiptsPagination,
      usersCompactList,
      getCostCenters,
      receiptsPage,
      applyFilters,
      loadingTable,
      applySorting,
      currentPage,
      costCenters,
      pageSizeRef,
      categories,
      filtersRef,
      profile,
    };
  },
});
</script>
