<template>
  <div id="app"><router-view /></div>
</template>

<script setup lang="ts">
import { onMounted } from "vue";
import { purgeTokens } from "@/utils";
import { useHead } from "@unhead/vue";

onMounted(() => {
  purgeTokens();
});

useHead({
  title: "Skynet 3.0 - Skry",
});
</script>
