<template>
  <a-modal
    :visible="visible"
    :destroy-on-close="true"
    title="Lock Reports"
    ok-text="Lock"
    @cancel="$emit('close')"
  >
    <p>
      You will no longer be able to create or edit reports before or on this date. Make sure all
      reports up to and including this date is reported.
    </p>
    <strong>This action cannot be reverted!</strong>

    <a-form v-if="!nothingToLock" layout="horizontal" class="mt-4">
      <a-form-item
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
        :validate-status="errors.first('report_locking_date') ? 'error' : ''"
        :help="errors.first('report_locking_date')"
        label="Lock up to date"
      >
        <a-date-picker
          :disabled-date="(current) => disabledDate(current)"
          :value="parseDate(reportLockingDate)"
          class="w-100"
          @change="(date) => onDateChange(date)"
        >
          <template #dateRender="current, today">
            <div :style="getLockedStyle(current, today)" class="ant-calendar-date">
              {{ current.date() }}
            </div>
          </template>
        </a-date-picker>
      </a-form-item>
      <div v-if="lockSummary">
        Since the last locking date <b>{{ user.report_locking_date }}</b> (excluded) you have
        reported <b>{{ lockSummary.total_reported_hours }} hours</b> out of the
        <b>{{ formatExpectedHours(lockSummary.total_expected_hours) }} hours</b>
        expected.
      </div>
      <a-checkbox
        v-if="!reportedAndExpectedHoursMatch()"
        class="mt-2"
        :checked="acceptedMismatch"
        @change="() => toggleAcceptedMismatch()"
        >I confirm the above is correct and wish to proceed</a-checkbox
      >
    </a-form>

    <div v-if="nothingToLock" class="text-center mt-3">
      <a-icon type="check-circle" class="mr-2 text-green" />
      <span>You locked your reports up to the current date. Good job pal!</span>
    </div>

    <template #footer>
      <a-button
        v-if="!nothingToLock"
        class="btn-warning"
        icon="lock"
        type="primary"
        :disabled="!reportedAndExpectedHoursMatch() && !acceptedMismatch"
        @click="() => handleLockReports()"
        >Lock</a-button
      >
      <a-button v-if="nothingToLock" type="default" @click="$emit('close')">Close</a-button>
    </template>
  </a-modal>
</template>

<script setup lang="ts">
import { ref, computed, watch, getCurrentInstance } from "vue";
import Colors from "@/assets/scss/_colors.module.scss";
import { ProfileInterface } from "../../authentication/types";
import moment from "@/date";
import { Moment } from "moment";
import { addDays } from "@/utils";
import { apiGetLockSummary } from "@/modules/users/_utils/api";
import useFilters from "@/useFilters";

interface LockSummaryInterface {
  total_reported_hours: number;
  total_expected_hours: number;
}

// Props
const props = defineProps({
  user: { type: Object as () => ProfileInterface, default: () => ({}) },
  visible: { type: Boolean, default: false },
});

// Emits
const emits = defineEmits(["lock-user-reports", "close"]);

// Filters
const { parseDate } = useFilters();

// Instances
const instance = getCurrentInstance();
const $validator = instance?.proxy.$validator;

// Data Properties
const reportLockingDate = ref<string | undefined>(undefined);
const lockSummary = ref<LockSummaryInterface | null>(null);

// Computed properties
const nothingToLock = computed((): boolean => {
  return props.user && moment(props.user.report_locking_date).isSame(moment(), "day");
});

const reportedAndExpectedHoursMatch = (): boolean => {
  return (lockSummary.value?.total_reported_hours ?? 0) -
    (lockSummary.value?.total_expected_hours ?? 0) ===
    0
    ? true
    : false;
};
const acceptedMismatch = ref<boolean>(false);

// Watchers
watch(
  () => props.visible,
  (newProp, oldProp) => {
    if (newProp && !oldProp) {
      const lastLockingDate = props.user.report_locking_date;
      const prevEndOfMonth = moment().subtract(1, "month").endOf("month").format("YYYY-MM-DD");
      const today = moment().format("YYYY-MM-DD");
      reportLockingDate.value = lastLockingDate < prevEndOfMonth ? prevEndOfMonth : today;
    }
  },
  { immediate: true }
);

watch(reportLockingDate, () => {
  lockSummary.value = null;
  if (!props.user.report_locking_date || !reportLockingDate.value) {
    return;
  }

  const start = addDays(new Date(props.user.report_locking_date), 1).toISOString().split("T")[0];
  const end = reportLockingDate.value;

  apiGetLockSummary(props.user.id, start, end).then((res) => {
    lockSummary.value = res.data;
  });
});
watch([acceptedMismatch, reportLockingDate], () => {}, { immediate: true });

// Class methods
const onDateChange = (date: any) => {
  reportLockingDate.value = date ? date.format("YYYY-MM-DD") : undefined;
};

const toggleAcceptedMismatch = (): void => {
  acceptedMismatch.value = !acceptedMismatch.value;
};

const handleLockReports = () => {
  $validator?.validateAll().then((result) => {
    if (result) {
      emits("lock-user-reports", reportLockingDate.value);
      emits("close");
    }
  });
};

const disabledDate = (current: Moment) => {
  const inTheFuture = current && current > moment().endOf("day");
  const beforLastLockingDate =
    current && current <= moment(props.user.report_locking_date).endOf("day");

  return inTheFuture || beforLastLockingDate;
};

const getLockedStyle = (current: Moment) => {
  const style = { backgroundColor: "", color: "" };
  if (current <= moment(props.user.report_locking_date).endOf("day")) {
    style.backgroundColor = Colors.yellow;
    style.color = Colors.white;
  }
  return style;
};

const formatExpectedHours = (expectedHours: number): string => {
  if (expectedHours % 1 === 0) {
    return `${expectedHours}`;
  } else {
    return `~${Math.floor(expectedHours)}`;
  }
};
</script>
