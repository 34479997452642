import axios from "@/network";

export function apiGetClients(params: any, headers = {}) {
  const url = "/clients";
  return axios.get(url, { params, headers });
}

export function apiGetClient(clientId: number) {
  const url = `/clients/${clientId}`;
  return axios.get(url);
}

export function apiStoreClient(newClient: any) {
  const url = "/clients";
  const payload = { client: newClient };
  return axios.post(url, payload);
}

export function apiUpdateClient(clientId: number, updatedClient: any) {
  const url = `/clients/${clientId}`;
  const payload = { client: updatedClient };
  return axios.patch(url, payload);
}

export function apiDestroyClient(clientId: number) {
  const url = `/clients/${clientId}`;
  return axios.delete(url);
}
