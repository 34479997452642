<template>
  <div>
    <empty-resource-table v-if="!roles[0] && !loadingTable" resource="roles" />

    <a-table
      v-if="loadingTable || roles[0]"
      :columns="rolesColumns"
      :data-source="roles"
      :loading="loadingTable"
      :pagination="false"
      :expand-row-by-click="true"
      :scroll="{ x: 1000 }"
      :row-key="(role) => role.id"
      @change="(pagination, filters, sorter) => handleChange(pagination, filters, sorter)"
    >
      <template #user="name, r">
        <span class="d-flex align-items-center">
          <a-avatar :src="r.user.gravatar" class="min-width-avatar mr-2" />
          <router-link :to="{ name: 'user', params: { id: r.user.id } }" @click.native.stop>
            {{ name }}
          </router-link>
        </span>
      </template>

      <template #project="projectName, r">
        <span class="d-flex flex-column">
          <small>{{ r.project.client.name }}</small>
          <router-link
            :to="{ name: 'project', params: { projectId: r.project.id } }"
            @click.native.stop
          >
            {{ projectName }}
          </router-link>
        </span>
      </template>

      <template #rate="rate">
        <span>{{ rate ? toMoney(rate) : "-" }}</span>
      </template>

      <template #workload="workload">
        <span class="d-flex flex-column">
          {{ toPercentage(workload) }}
        </span>
      </template>

      <template #descriptionStatus="status, r">
        <span class="d-flex align-items-center">
          <a-tag
            v-if="r.description_status === RoleDescriptionStatus.PRESENT"
            :color="Colors.green"
            class="default-cursor"
            >PRESENT</a-tag
          >
          <a-tag
            v-if="r.description_status === RoleDescriptionStatus.PENDING"
            :color="Colors.yellow"
            class="default-cursor"
            >PENDING</a-tag
          >
          <a-tag
            v-if="r.description_status === RoleDescriptionStatus.MISSING"
            :color="Colors.orange"
            class="default-cursor"
            >MISSING</a-tag
          >
          <a-tooltip>
            <template #title>
              This role won't show up when editing {{ r.user.name }}'s CV
            </template>
            <a-icon v-if="r.disable_cv" type="eye-invisible" />
          </a-tooltip>
        </span>
      </template>

      <template #extensionStatus="r">
        <span>
          <a-tag
            v-if="r.extension_status === 'no_pending'"
            :color="extensionStatusColorRef[r.extension_status]"
            class="default-cursor"
          >
            NO PENDING
          </a-tag>
          <a-tag
            v-if="r.extension_status === 'approved'"
            :color="extensionStatusColorRef[r.extension_status]"
            class="default-cursor"
          >
            APPROVED
          </a-tag>
          <a-tag
            v-if="r.extension_status === 'to_be_discussed'"
            :color="extensionStatusColorRef[r.extension_status]"
            class="default-cursor"
          >
            TO BE DISCUSSED
          </a-tag>
          <a-tag
            v-if="r.extension_status === 'waiting'"
            :color="extensionStatusColorRef[r.extension_status]"
            class="default-cursor"
          >
            WAITING
          </a-tag>
        </span>
      </template>

      <template #actions="r">
        <span>
          <role-actions
            :role="r"
            :profile="profile"
            @show-edit-role-modal="() => showEditRoleModal(r)"
            @show-delete-role-modal="() => showDeleteRoleModal(r)"
          />
        </span>
      </template>

      <template #expandedRowRender="r">
        <div>
          <a-row>
            <a-col :span="8">
              <strong class="mr-2">Project:</strong>
              <router-link :to="{ name: 'project', params: { projectId: r.project.id } }">
                {{ r.project.name }}
              </router-link>
            </a-col>

            <a-col :span="8">
              <strong class="mr-4">Hourly rate:</strong>
              {{ toMoney(r.hourly_rate) }}
            </a-col>

            <a-col :span="8">
              <strong class="mr-2">Start date:</strong>
              {{ r.start_date }}
            </a-col>
          </a-row>

          <a-row class="mt-1">
            <a-col :span="8">
              <strong class="mr-3">Client:</strong>
              <router-link :to="{ name: 'client', params: { id: r.project.client.id } }">
                {{ r.project.client.name }}
              </router-link>
            </a-col>

            <a-col :span="8">
              <strong class="mr-2">Project status:</strong>
              <a-tag
                :color="r.project.status === 'active' ? Colors.green : Colors.orange"
                class="default-cursor"
              >
                {{ r.project.status.toUpperCase() }}
              </a-tag>
            </a-col>

            <a-col :span="8">
              <strong class="mr-3">End date:</strong>
              {{ r.end_date }}
            </a-col>
          </a-row>

          <a-row v-if="r.skills && r.skills[0]" class="mt-2">
            <a-col :span="22">
              <strong class="mr-2">Skills:</strong>
              <span v-for="skill in r.skills" :key="skill.id">
                <a-tag class="default-cursor">{{ skill.title }}</a-tag>
              </span>
            </a-col>
          </a-row>

          <a-row class="mt-2">
            <a-col :span="22">
              <strong class="mr-2">Description:</strong>
              <span v-if="r.description_status === RoleDescriptionStatus.PRESENT">
                {{ r.description }}
              </span>
              <span v-else>
                <em>Description is {{ r.description_status }}</em>
              </span>
            </a-col>
          </a-row>

          <a-row class="mt-2">
            <a-col :span="22">
              <strong class="mr-2">Agreement Link:</strong>
              <span v-if="r.agreement_link">
                <a :href="`${r.agreement_link}`" target="_blank" rel="noopener noreferrer">
                  {{ r.agreement_link }}
                </a>
              </span>
              <em v-else>No agreement link available</em>
            </a-col>
          </a-row>

          <a-row>
            <a-col :span="20">
              <role-actions
                :role="r"
                :profile="profile"
                :with-title="true"
                @show-edit-role-modal="() => showEditRoleModal(r)"
                @show-delete-role-modal="() => showDeleteRoleModal(r)"
              />
            </a-col>
          </a-row>

          <a-row class="mt-2">
            <a-col :span="20">
              <latest-role-extension-overview
                :profile="profile"
                :acl="acl"
                :role="r"
                :show-create-button="true"
                @on-successful-change="$emit('refresh-role', r.id)"
              />
            </a-col>
          </a-row>
        </div>
      </template>
    </a-table>

    <a-pagination
      v-if="rolesPagination && (loadingTable || roles[0])"
      :current="currentPage"
      :total="rolesPagination.total"
      :show-size-changer="true"
      :page-size="pageSize"
      :page-size-options="['10', '25', '50', '100']"
      size="small"
      class="d-flex justify-content-end mt-2"
      @change="(page, newPageSize) => $emit('get-roles-page', { page, pageSize: newPageSize })"
      @showSizeChange="
        (pageNumber, newPageSize) =>
          $emit('get-roles-page', { page: pageNumber, pageSize: newPageSize })
      "
    />

    <!-- EDIT PROJECT ROLE MODAL -->
    <edit-project-role-modal
      :visible="editRoleModalVisible"
      :acl="acl"
      :skills="skills"
      :cost-centers="costCenters"
      :role="role"
      :project="role ? role.project : undefined"
      :subprojects="subprojects"
      @close="editRoleModalVisible = false"
      @get-skills="$emit('get-skills')"
      @get-subprojects-page="(projectId) => $emit('get-subprojects-page', projectId)"
      @get-cost-centers="$emit('get-cost-centers')"
      @update-project-role="(updatedRole) => $emit('update-project-role', updatedRole)"
    />

    <!-- DELETE ROLE MODAL -->
    <delete-role-modal
      :visible="deleteRoleModalVisible"
      :role="role"
      @close="deleteRoleModalVisible = false"
      @destroy-role="(projectId, roleId) => $emit('destroy-user-role', projectId, roleId)"
    />
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import EditProjectRoleModal from "../../projects/_components/EditProjectRoleModal.vue";
import { RolesFilterInterface, RoleInterface, RoleDescriptionStatus } from "../types";
import EmptyResourceTable from "@/modules/common/_components/EmptyResourceTable.vue";
import { BaseAclInterface, ProfileInterface } from "@/modules/authentication/types";
import DeleteRoleModal from "../../projects/_components/DeleteRoleModal.vue";
import LatestRoleExtensionOverview from "./LatestRoleExtensionOverview.vue";
import { extensionStatusColor } from "../_utils/role-extension";
import Colors from "@/assets/scss/_colors.module.scss";
import { PaginationInterface, TableColumnInterface } from "@/common/types";
import { rolesColumns as defaultRolesColumns } from "../_utils/tables";
import RoleActions from "./RoleActions.vue";
import useMixin from "@/useMixin";
import { ProjectInterface, NewSubprojectInterface } from "../../projects/types";

// Props
const props = defineProps({
  rolesPagination: { type: Object as () => PaginationInterface, default: undefined },
  profile: { type: Object as () => ProfileInterface, default: undefined },
  roles: { type: Array as () => Array<RoleInterface>, default: () => [] },
  acl: { type: Object as () => BaseAclInterface, default: undefined },
  costCenters: { type: Array as () => Array<any>, default: () => [] },
  skills: { type: Array as () => Array<any>, default: () => [] },
  loadingTable: { type: Boolean, default: false },
  currentPage: { type: Number, default: 1 },
  pageSize: { type: Number, default: 25 },
  rolesColumns: {
    type: Array as () => Array<TableColumnInterface>,
    default: () => defaultRolesColumns,
  },
  subprojects: { type: Array as () => Array<NewSubprojectInterface>, default: () => [] },
  project: { type: Object as () => ProjectInterface, default: undefined },
});

// Emits
const emits = defineEmits(["apply-sorting", "update-project-role", "get-subprojects-page"]);

// Mixins
const { toMoney, toPercentage } = useMixin();

// Data properties
const editRoleModalVisible = ref<boolean>(false);
const deleteRoleModalVisible = ref<boolean>(false);
const role = ref<any>(null);
const extensionStatusColorRef = ref<{ [key: string]: any }>(extensionStatusColor);

// Class methods
const handleChange = (
  pagination: PaginationInterface,
  filters: RolesFilterInterface,
  sorter: any
): void => {
  emits("apply-sorting", {
    sort: sorter.columnKey,
    order: sorter.columnKey ? (sorter.order === "descend" ? "desc" : "asc") : undefined,
  });
};

const showEditRoleModal = (r: any): void => {
  role.value = r;
  editRoleModalVisible.value = true;
};

const showDeleteRoleModal = (r: RoleInterface): void => {
  role.value = r;
  deleteRoleModalVisible.value = true;
};
</script>

<style scoped>
.min-width-avatar {
  min-width: 32px;
}
</style>
