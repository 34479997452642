import { RouteInterface } from "@/common/types";
import Receipts from "./_views/Receipts.vue";
import Receipt from "./_views/Receipt.vue";

export default [
  {
    path: "receipts",
    component: Receipts,
    name: "receipts",
    meta: { auth: true },
  },
  {
    path: "receipts/:id",
    component: Receipt,
    name: "receipt",
    meta: { auth: true },
  },
] as Array<RouteInterface>;
