import { defineStore, acceptHMRUpdate } from "pinia";
import { RoleExtensionInterface, RoleInterface } from "@/modules/roles/types";
import { apiGetRoles, apiUpdateRole } from "@/modules/roles/_utils/api";
import { PaginationInterface } from "@/common/types";

const headers = { "Cache-Control": "no-cache" };

export interface StateInterface {
  roles: Map<number, any>;
  rolesPagination: PaginationInterface | undefined;
  roleExtensions: Array<RoleExtensionInterface>;
}

export const useRolesStore = defineStore("roles", {
  state: (): StateInterface => {
    return {
      roles: new Map(),
      rolesPagination: undefined,
      roleExtensions: new Array(),
    };
  },
  actions: {
    getRolesByPage(params: any) {
      return new Promise((resolve, reject) => {
        apiGetRoles(params, headers)
          .then((res: any) => {
            this.setRoles({ page: params.page, payload: res.data.data });
            this.setRolesPagination(res.data.meta);
            resolve(res.data.data);
          })
          .catch((err: any) => {
            reject(err);
          });
      });
    },

    updateRole({ projectId, roleId, updatedRole }: any) {
      return new Promise<void>((resolve, reject) => {
        apiUpdateRole(projectId, roleId, updatedRole)
          .then(() => {
            resolve();
          })
          .catch((err: any) => {
            reject(err);
          });
      });
    },

    clearRoles() {
      return new Promise<void>((resolve) => {
        this.roles = new Map();
        resolve();
      });
    },

    setRoles({ page, payload }: { page: number; payload: Array<RoleInterface> }): void {
      let map = new Map();
      this.roles.forEach((v: number, k: any) => map.set(k, v));
      map.set(page, payload);
      this.roles = map;
    },

    setRolesPagination(meta: PaginationInterface) {
      this.rolesPagination = meta;
    },

    setRoleExtensions(roleExtensions: Array<RoleExtensionInterface>) {
      this.roleExtensions = roleExtensions;
    },
  },
});

// HMR
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useRolesStore, import.meta.hot));
}
