<template>
  <div v-if="rolesPage">
    <div class="d-flex justify-content-between w-100 mb-2">
      <h2>{{ title }}</h2>
      <portal-target name="roles-filter-buttons"></portal-target>
    </div>

    <roles-filter
      v-if="allowFiltering"
      :profile="profile"
      :filters="{ ...filtersRef, ...rolesFilter }"
      :number-of-results="rolesPagination?.total"
      :unchangeable-filter="rolesFilter"
      @apply-filters="(newFilter) => applyFilters(newFilter)"
    />

    <div :class="allowFiltering ? 'card' : ''">
      <roles-table
        :roles="rolesPage"
        :current-page="currentPage"
        :page-size="pageSizeRef"
        :roles-pagination="rolesPagination"
        :acl="acl"
        :profile="profile"
        :loading-table="loadingTable"
        :skills="skills"
        :cost-centers="costCenters"
        :roles-columns="rolesColumns"
        :project="project"
        :subprojects="subprojects"
        @apply-sorting="(newSorting) => applySorting(newSorting)"
        @get-roles-page="(params) => getRolesPage(params)"
        @refresh-role="(roleId) => refreshRole(roleId)"
        @get-skills="getSkills"
        @get-cost-centers="getCostCenters"
        @update-project-role="(updatedRole) => handleUpdateRole(updatedRole)"
        @destroy-user-role="(projectId, roleId) => handleDestroyRole(projectId, roleId)"
        @get-subprojects-page="(projectId) => $emit('get-subprojects-page', projectId)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onBeforeMount, computed, getCurrentInstance } from "vue";
import { BaseAclInterface, ProfileInterface } from "@/modules/authentication/types";
import { PaginationInterface, TableColumnInterface } from "@/common/types";
import { useBookmarkedFiltersStore } from "@/modules/bookmarked_filters/_store";
import { useAuthenticationStore } from "@/modules/authentication/_store";
import { apiDestroyUserRole } from "@/modules/users/_utils/api";
import { RoleInterface, RolesFilterInterface } from "../types";
import RolesFilter from "./RolesFilter.vue";
import { apiGetRole } from "../_utils/api";
import RolesTable from "./RolesTable.vue";
import useMixin from "@/useMixin";
import { useCostCentersStore } from "@/modules/cost_centers/_store";
import { CostCenterInterface } from "@/modules/cost_centers/types";
import { useRolesStore } from "@/modules/roles/_store";
import { useSkillsStore } from "@/modules/skills/_store";
import { ProjectInterface, NewSubprojectInterface } from "@/modules/projects/types";

// Props
const props = defineProps({
  rolesColumns: { type: Array as () => Array<TableColumnInterface>, default: undefined },
  rolesFilter: { type: Object as () => RolesFilterInterface, default: undefined },
  acl: { type: Object as () => BaseAclInterface, default: undefined },
  allowFiltering: { type: Boolean, default: false },
  title: { type: String, default: "Roles" },
  subprojects: { type: Array as () => Array<NewSubprojectInterface>, default: () => [] },
  project: { type: Object as () => ProjectInterface, default: undefined },
});

// Emits
const emits = defineEmits(["role-refreshed"]);

// Pinia
const authenticationStore = useAuthenticationStore();
const costCentersStore = useCostCentersStore();
const bookmarkedFiltersStore = useBookmarkedFiltersStore();
const rolesStore = useRolesStore();
const skillsStore = useSkillsStore();
const profile = computed<ProfileInterface | undefined>(() => authenticationStore.profile);
const costCenters = computed<Array<CostCenterInterface>>(() => costCentersStore.cost_centers);
const getCostCenters = () => costCentersStore.getCostCenters();
const rolesPagination = computed<PaginationInterface | undefined>(() => rolesStore.rolesPagination);
const skills = computed(() => skillsStore.skills);
const getSkills = () => skillsStore.getSkills();

// Mixins
const { loadTablePageSize, qsDecode, syncRouteParams, persistTablePageSize } = useMixin();

// Instances
const instance = getCurrentInstance();
const $route = instance?.proxy?.$route;
const $message = instance?.proxy?.$message;

// Data properties
const rolesPage = ref<Array<RoleInterface>>([]);
const loadingTable = ref<boolean>(false);
const currentPage = ref<number>(1);
const pageSizeRef = ref<number>(25);
const filtersRef = ref<RolesFilterInterface>({});
const sortingRef = ref<object>({});

// Life-cycle hooks
onBeforeMount(() => {
  bookmarkedFiltersStore.getUserBookmarkedFilters(profile.value?.id as number);
  getRolesPagefromURL();
});

// Component methods
const getRolesPagefromURL = (url: any = $route?.query): void => {
  const { page, filters, sorting, pageSize } = qsDecode(url);

  pageSizeRef.value = loadTablePageSize();

  if (page) currentPage.value = parseInt(page);
  if (filters) filtersRef.value = filters;
  if (sorting) sortingRef.value = sorting;
  if (pageSize) pageSizeRef.value = parseInt(pageSize);

  refreshRoles();
};

const getRolesPage = ({
  page,
  pageSize = pageSizeRef.value,
  filters = filtersRef.value,
  sorting = sortingRef.value,
}: {
  page: any;
  pageSize?: number;
  filters?: RolesFilterInterface;
  sorting?: object;
}): void => {
  loadingTable.value = true;
  currentPage.value = page;
  pageSizeRef.value = pageSize;

  if (props.allowFiltering) {
    syncRouteParams(
      {
        page: currentPage.value,
        pageSize: pageSizeRef.value,
        filters: filtersRef.value,
        sorting: sortingRef.value,
      },
      instance
    );
  }
  persistTablePageSize(pageSize);
  rolesStore
    .getRolesByPage({
      page,
      page_size: pageSize,
      ...{ ...filters, ...props.rolesFilter },
      ...sorting,
    })
    .then((results: any) => {
      loadingTable.value = false;
      rolesPage.value = results;
    });
};

const applyFilters = (filters: RolesFilterInterface): void => {
  filtersRef.value = filters;
  currentPage.value = 1;
  syncRouteParams(
    {
      page: currentPage.value,
      pageSize: pageSizeRef.value,
      filters: filtersRef.value,
      sorting: sortingRef.value,
    },
    instance
  );
  refreshRoles();
};

const applySorting = (sorting: object): void => {
  sortingRef.value = sorting;

  syncRouteParams(
    {
      page: currentPage.value,
      pageSize: pageSizeRef.value,
      filters: filtersRef.value,
      sorting: sortingRef.value,
    },
    instance
  );
  refreshRoles();
};

const handleUpdateRole = (updatedRole: any): void => {
  rolesStore
    .updateRole({
      projectId: updatedRole.project ? updatedRole.project.id : updatedRole.projectId,
      roleId: updatedRole.id,
      updatedRole,
    })
    .then(() => refreshRole(updatedRole.id))
    .then(() => $message?.success("Role updated successfully!", 3))
    .catch(() => $message?.error("Couldn't update role!", 3));
};

const handleDestroyRole = (projectId: number, roleId: number): void => {
  apiDestroyUserRole(projectId, roleId)
    .then(() => refreshRoles())
    .then(() => $message?.success("Role deleted successfully!", 3))
    .catch(() => $message?.error("Couldn't delete role!", 3));
};

const refreshRole = (roleId: number): void => {
  apiGetRole(roleId)
    .then((res) => {
      rolesPage.value = rolesPage.value.map((r) => (r.id === roleId ? res.data.data : r));
    })
    .finally(() => emits("role-refreshed"));
};

const refreshRoles = (): void => {
  getRolesPage({ page: currentPage.value });
};

defineExpose({ refreshRoles, currentPage });
</script>
