<template>
  <a-layout-content class="my-4 mx-3">
    <div v-if="client" class="container">
      <!-- HEADER -->
      <client-header
        :client="client"
        :users="usersCompactList"
        :acl="acl"
        :profile="profile"
        :client-associations="client.associations"
        @update-client="(updatedClient) => handleUpdateClient(updatedClient)"
        @destroy-client="(clientId) => handleDestroyClient(clientId)"
        @get-users="() => handleGetUsersCompactList()"
        @store-client-project="(newProject) => handleStoreClientProject(newProject)"
      />

      <!-- PROJECTS TABLE -->
      <projects-view
        ref="projectsview"
        :acl="acl"
        :projects-filter="clientProjectsFilter"
        :projects-columns="clientProjectsColumns"
        :allow-download="true"
        :allow-filtering="true"
      />
    </div>
  </a-layout-content>
</template>

<script setup lang="ts">
import { ref, computed, getCurrentInstance, onMounted, watch } from "vue";
import { BaseAclInterface, ProfileInterface } from "@/modules/authentication/types";
import { apiUpdateClient, apiDestroyClient, apiGetClient } from "../_utils/api";
import { clientProjectsColumns } from "@/modules/projects/_utils/tables";
import ProjectsView from "../../projects/_components/ProjectsView.vue";
import { apiStoreProject } from "@/modules/projects/_utils/api";
import { ProjectsFilterInterface } from "../../projects/types";
import ClientHeader from "../_components/ClientHeader.vue";
import { MinimalUserInterface } from "../../users/types";
import { getUsersCompactList } from "@/api";
import { useAuthenticationStore } from "@/modules/authentication/_store";
import { useRoute, useRouter } from "vue-router/composables";

// Pinia
const authenticationStore = useAuthenticationStore();
const profile = computed<ProfileInterface | undefined>(() => authenticationStore.profile);
const baseAcl = computed<BaseAclInterface>(() => authenticationStore.baseAcl);

// Data properties
const clientProjectsFilter = ref<ProjectsFilterInterface | undefined>(undefined);
const usersCompactList = ref<Array<MinimalUserInterface>>([]);
const projectsview = ref<InstanceType<typeof ProjectsView>>();
const client = ref<any>(null);

// Instances
const route = useRoute();
const router = useRouter();
const instance = getCurrentInstance();
const $message = instance?.proxy.$message;

// Computed properties
// Determine if authenticated user is the account manager
const isAccountManager = computed<boolean>(() => {
  return client.value.account_manager && client.value.account_manager.id === profile.value?.id;
});

// Combine base access control level (ACL) with account manager
const acl = computed<BaseAclInterface & { isAccountManager: boolean }>(() => {
  return { ...baseAcl.value, isAccountManager: isAccountManager.value };
});

// Watchers
watch(route, () => {
  if (client.value && parseInt(route.params.clientId as string) != client.value.id) {
    apiGetClient(parseInt(route.params.id)).then(({ data }: any) => {
      client.value = data.data;
      clientProjectsFilter.value = { client_id: [data.data.id] };
      projectsview.value?.refreshProjects();
    });
  }
});

// Lifecycle hooks
onMounted(() => {
  if (route.params.id !== undefined) {
    apiGetClient(parseInt(route.params.id)).then(({ data }: any) => {
      client.value = data.data;
      clientProjectsFilter.value = { client_id: [data.data.id] };
    });
  }
});

const handleUpdateClient = (updatedClient: any): void => {
  apiUpdateClient(client.value.id, updatedClient)
    .then(({ data }: any) => (client.value = data.data))
    .then(() => $message?.success("Client updated successfully!", 3))
    .catch(() => $message?.error("Couldn't update client!", 3));
};

const handleDestroyClient = (clientId: number): void => {
  apiDestroyClient(clientId)
    .then(() => (client.value = null))
    .then(() => {
      $message?.success("Client removed successfully!", 3);
      router.push({ name: "clients" });
    })
    .catch(() => $message?.error("Couldn't remove client!", 3));
};

const handleStoreClientProject = (newProject: any): void => {
  apiStoreProject(newProject)
    .then(() => projectsview.value?.refreshProjects())
    .then(() => $message?.success("Project created successfully!", 3))
    .catch(() => $message?.error("Couldn't create project!", 3));
};

const handleGetUsersCompactList = (): void => {
  getUsersCompactList().then((users) => (usersCompactList.value = users));
};
</script>
