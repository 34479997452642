<template>
  <a-layout-content class="my-4 mx-3">
    <div class="container">
      <!-- HEADER -->
      <div class="d-flex justify-content-between">
        <h2>Teams</h2>
        <a-button v-if="baseAcl.isAdmin" type="primary" ghost @click="storeTeamModalVisible = true">
          Create Team
        </a-button>
      </div>

      <!-- LIST OF TEAMS AS CARDS -->
      <a-row type="flex" justify="center" align="top">
        <a-col v-for="team in teams" :key="team.id" :span="24" :lg="12">
          <div class="p-2">
            <router-link
              :to="{ name: 'team', params: { id: team.id } }"
              tag="div"
              class="card p-4 team-card"
            >
              <a-avatar class="mb-4 bg-blue text-white" size="large">
                {{ team.name.charAt(0) }}
              </a-avatar>
              <h2 class="mb-3">{{ team.name }}</h2>
              <span>
                <strong>{{ team.leader.name }}</strong>
              </span>
              <span>
                Number of members:
                <strong>{{ team.users_count }}</strong>
              </span>
            </router-link>
          </div>
        </a-col>
      </a-row>

      <!-- CREATE NEW TEAM MODAL -->
      <add-team-modal
        :visible="storeTeamModalVisible"
        :users="usersCompactList"
        @close="storeTeamModalVisible = false"
        @get-users="() => handleGetUsersCompactList()"
        @store-team="(newTeam) => handleStoreTeam(newTeam)"
      />
    </div>
  </a-layout-content>
</template>

<script setup lang="ts">
import { ref, computed, getCurrentInstance, onBeforeMount } from "vue";
import { BaseAclInterface } from "@/modules/authentication/types";
import { useAuthenticationStore } from "@/modules/authentication/_store";
import { TeamInterface, TeamPayloadInterface } from "../types";
import { MinimalUserInterface } from "@/modules/users/types";
import AddTeamModal from "../_components/AddTeamModal.vue";
import { apiGetTeams, apiStoreTeam } from "../_utils/api";
import { getUsersCompactList } from "@/api";

// Pinia
const authenticationStore = useAuthenticationStore();
const baseAcl = computed<BaseAclInterface>(() => authenticationStore.baseAcl);

// Instances
const instance = getCurrentInstance();
const $message = instance?.proxy.$message;

// Data properties
const usersCompactList = ref<Array<MinimalUserInterface>>([]);
const storeTeamModalVisible = ref<boolean>(false);
const teams = ref<Array<TeamInterface>>([]);

// Lifecycle hooks
onBeforeMount(() => {
  apiGetTeams().then(({ data }: any) => (teams.value = data.data));
});

// Class methods
const handleStoreTeam = (newTeam: TeamPayloadInterface): void => {
  apiStoreTeam(newTeam)
    .then(({ data }: any) => teams.value.push(data.data))
    .then(() => $message?.success("Team created successfully!", 3))
    .catch(() => $message?.error("Couldn't create team!", 3));
};

const handleGetUsersCompactList = (): void => {
  getUsersCompactList().then((users) => (usersCompactList.value = users));
};
</script>

<style lang="scss" scoped>
.team-card {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
