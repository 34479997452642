<template>
  <a-card v-if="receipt">
    <a-row>
      <div class="d-flex justify-content-between">
        <h3>Receipt</h3>

        <div>
          <a
            v-if="receipt.pdf"
            :href="receipt.pdf"
            target="_blank"
            title="Download receipt PDF"
            class="mr-4"
          >
            <strong>
              <a-icon type="download" />
            </strong>
          </a>
          <em v-else>no pdf available</em>
          <a title="Copy receipt link to clipboard">
            <strong>
              <a-icon type="link" class="ml-2" @click="copyToClipboard" />
            </strong>
          </a>
        </div>
      </div>
    </a-row>

    <a-divider />

    <a-row>
      <a-col :span="6" class="d-flex justify-content-end">
        <b class="mr-4">By:</b>
      </a-col>
      <a-col :span="16">
        <router-link :to="{ name: 'user', params: { id: receipt.user.id } }">
          {{ receipt.user.name }}
        </router-link>
      </a-col>
    </a-row>

    <a-row class="mt-4">
      <a-col :span="6" class="d-flex justify-content-end">
        <b class="mr-4">Total Amount:</b>
      </a-col>
      <a-col :span="16">{{ toMoney(receipt.amount) }}</a-col>
    </a-row>

    <a-row class="mt-4">
      <a-col :span="6" class="d-flex justify-content-end">
        <b class="mr-4">Submission date:</b>
      </a-col>
      <a-col :span="16">{{ receipt.created_at }}</a-col>
    </a-row>

    <a-row class="mt-4">
      <a-col :span="6" class="d-flex justify-content-end">
        <b class="mr-4">Cost center:</b>
      </a-col>
      <a-col :span="16">{{ receipt.cost_center.name }}</a-col>
    </a-row>

    <a-row v-if="receipt.participants.length > 0" class="mt-4">
      <a-col :span="6" class="d-flex justify-content-end">
        <b class="mr-4">Internal Participants:</b>
      </a-col>
      <a-col :span="16">
        <span v-for="(participant, index) in receipt.participants" :key="participant.id">
          <router-link :to="{ name: 'user', params: { id: participant.id } }">
            {{ participant.name }}
          </router-link>
          {{ index === receipt.participants.length - 1 ? "" : ", " }}
        </span>
      </a-col>
    </a-row>

    <a-row v-if="receipt.external_participants" class="mt-4">
      <a-col :span="6" class="d-flex justify-content-end">
        <b class="mr-4">External Participants:</b>
      </a-col>
      <a-col :span="16">
        <span
          v-for="(participant, index) in receipt.external_participants.split(', ')"
          :key="`${participant}-${index}`"
        >
          {{ participant }}
          {{ index === receipt.external_participants.split(", ").length - 1 ? "" : ", " }}
        </span>
      </a-col>
    </a-row>

    <a-row class="mt-4">
      <a-col :span="6" class="d-flex justify-content-end">
        <b class="mr-4">Category:</b>
      </a-col>
      <a-col :span="16">
        <span v-for="(category, index) in receipt.categories" :key="category.id">
          {{ category.name }}{{ index === receipt.categories.length - 1 ? "" : ", " }}
        </span>
      </a-col>
    </a-row>

    <a-row class="mt-4">
      <a-col :span="6" class="d-flex justify-content-end">
        <b class="mr-4">Description:</b>
      </a-col>
      <a-col :span="16">{{ receipt.description }}</a-col>
    </a-row>

    <a-row class="mt-4">
      <a-col :span="6" class="d-flex justify-content-end">
        <b class="mr-4">Status:</b>
      </a-col>
      <a-col :span="16">
        <a-tag :color="receiptStatusColor(receipt.status)" class="default-cursor">
          <span>{{ receiptStatusLabel(receipt.status) }}</span>
        </a-tag>
        <a-tag
          v-if="receipt.status === 'approved_pending_payment' || receipt.status === 'approved'"
          :color="
            receipt.status === 'approved'
              ? receiptStatusColor('approved')
              : receiptStatusColor('pending')
          "
          class="default-cursor"
        >
          <span>{{ receipt.status === "approved" ? "PAID OUT" : "PENDING PAYMENT" }}</span>
        </a-tag>
      </a-col>
    </a-row>
    <a-row v-if="receipt.paid_out_at" class="mt-4">
      <a-col :span="6" class="d-flex justify-content-end">
        <b class="mr-4">Paid out date:</b>
      </a-col>
      <a-col :span="16">{{ receipt.paid_out_at }}</a-col>
    </a-row>

    <a-row v-if="acl?.isFinancial" class="mt-4">
      <a-col :span="6" class="d-flex justify-content-end">
        <b class="mr-4">Transaction:</b>
      </a-col>
      <a-col v-if="receipt.transaction" :span="16">
        {{
          receipt.transaction.amount.formatted_value +
          " " +
          receipt.transaction.amount.currency_code +
          " (" +
          receipt.transaction.status +
          ")"
        }}
      </a-col>
      <a-col v-else :span="16">
        <p>No transaction</p>
      </a-col>
    </a-row>

    <a-row v-if="receipt.comment && !acl?.isAdmin && !acl?.isFinancial" class="mt-5">
      <a-card>
        <a-row>
          <a-col :span="5" class="d-flex justify-content-end">
            <b v-if="receipt.comment" class="mr-4">Comment:</b>
          </a-col>
          <a-col :span="14">
            <span>{{ receipt.comment }}</span>
          </a-col>
        </a-row>
      </a-card>
    </a-row>

    <a-row v-if="acl?.isAdmin || acl?.isFinancial" class="mt-5">
      <a-col :span="6" class="d-flex justify-content-end">
        <b class="mr-4">Comment:</b>
      </a-col>
      <a-col :span="18">
        <a-textarea v-model="comment" :auto-size="{ minRows: 2 }" placeholder="Leave a comment" />
      </a-col>
    </a-row>

    <a-row v-if="acl?.isAdmin || acl?.isFinancial" class="mt-2 d-flex justify-content-end">
      <a-button class="mr-2" type="danger" @click="() => handleSetReceiptStatus('declined')">
        Decline
      </a-button>
      <a-button
        v-if="receipt.status !== 'approved' && receipt.status !== 'approved_pending_payment'"
        class="mr-4"
        type="primary"
        @click="() => handleSetReceiptStatus('approved_pending_payment')"
      >
        Approve
      </a-button>
      <a-button
        v-if="receipt.status === 'approved_pending_payment'"
        class="mr-4"
        type="primary"
        @click="() => handleSetReceiptStatus('approved')"
      >
        Mark as paid
      </a-button>
      <a-button
        v-if="receipt.status === 'approved'"
        class="mr-4"
        type="danger"
        @click="() => handleSetReceiptStatus('approved_pending_payment')"
      >
        Mark as unpaid
      </a-button>
      <a-button
        v-if="acl.isFinancial"
        class="mr-4"
        type="primary"
        @click="addExpenseReportModalVisible = true"
      >
        Create expense report
      </a-button>
      <a-button
        v-if="
          acl?.isFinancial &&
          (receipt.status === 'approved' || receipt.status === 'approved_pending_payment')
        "
        type="primary"
        @click="addTransactionModalVisible = true"
      >
        Add Transaction
      </a-button>
    </a-row>

    <!-- ADD TRANSACTION MODAL -->
    <add-transaction-receipt-modal
      :visible="addTransactionModalVisible"
      :receipt="receipt"
      @store-transaction="(transaction) => $emit('store-transaction', transaction)"
      @close="addTransactionModalVisible = false"
    />
    <add-expense-report-modal
      :user="receipt.user"
      :cost-centers="costCenters"
      :visible="addExpenseReportModalVisible"
      :date="receipt.created_at"
      :amount="receipt.amount"
      :description="receipt.description"
      @get-cost-centers="() => getCostCenters()"
      @store-report="(newReport) => handleStoreExpenseReport(newReport)"
      @close="addExpenseReportModalVisible = false"
    />
  </a-card>
  <div v-else>
    <em>No receipt found</em>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, getCurrentInstance, toRefs } from "vue";
import AddExpenseReportModal from "@/modules/reports/_components/AddExpenseReportModal.vue";
import AddTransactionReceiptModal from "./AddTransactionReceiptModal.vue";
import { BaseAclInterface } from "@/modules/authentication/types";
import { receiptStatusLabel } from "../_utils/utils";
import { receiptStatusColor } from "../_utils/utils";
import { ReceiptInterface } from "../types";
import useMixin from "@/useMixin";
import { useCostCentersStore } from "@/modules/cost_centers/_store";
import { CostCenterInterface } from "@/modules/cost_centers/types";
import { useReportsStore } from "@/modules/reports/_store";

// Props
const props = defineProps({
  receipt: { type: Object as () => ReceiptInterface, default: undefined },
  acl: { type: Object as () => BaseAclInterface, default: undefined },
});

// Emits
const emits = defineEmits(["update-receipt"]);

// Pinia
const costCentersStore = useCostCentersStore();
const reportsStore = useReportsStore();
const costCenters = computed<Array<CostCenterInterface>>(() => costCentersStore.cost_centers);
const getCostCenters = () => costCentersStore.getCostCenters();

// Mixins
const { toMoney } = useMixin();

// Instances
const instance = getCurrentInstance();
const $message = instance?.proxy.$message;

// Data properties
const { receipt } = toRefs(props);
const comment = ref<string | null>(receipt.value ? receipt.value.comment : null);
const addTransactionModalVisible = ref<boolean>(false);
const addExpenseReportModalVisible = ref<boolean>(false);

// Component methods
const handleSetReceiptStatus = (status: string): void => {
  emits("update-receipt", {
    user: { id: props.receipt.user.id },
    id: props.receipt.id,
    status: status,
    comment: comment.value,
  });
};

const copyToClipboard = (): void => {
  const receiptUrl = `${window.location.origin}/dashboard/receipts/${props.receipt.id}`;
  navigator.clipboard
    .writeText(receiptUrl)
    .then(() => $message?.success(`Receipt link copied to clipboard: ${receiptUrl}`, 3));
};

const handleStoreExpenseReport = (newExpenseReport: any) => {
  reportsStore
    .storeReport(newExpenseReport)
    .then(() => $message?.success("Report stored successfully!", 3))
    .catch(() => $message?.error("Couldn't store report!", 3))
    .finally(() => (addExpenseReportModalVisible.value = false));
};
</script>
