<template>
  <div>
    <!-- HEADER -->
    <div class="d-flex justify-content-between ml-4">
      <h2>Skills</h2>
      <a-button icon="plus" type="primary" @click="addSkillModalVisible = true" />
    </div>

    <!-- LIST OF SKILLS AS CARDS -->
    <a-row type="flex" justify="center">
      <a-col v-for="skill in skills" :key="skill.id" :span="24" :lg="12">
        <div class="skills-card mb-1 ml-4" style="height: 95%">
          <span class="d-flex justify-content-between">
            <h4 class="clickable">
              <strong @click="() => handleViewSkillOverviewModalVisible(skill)">
                {{ skill.title }}
              </strong>
            </h4>
            <div>
              <a-icon
                type="edit"
                class="action-default"
                @click="() => handleEditSkillModalVisible(skill)"
              />
              <a-divider v-if="baseAcl.isAdmin" type="vertical" />
              <a-icon
                v-if="baseAcl.isAdmin"
                type="delete"
                class="action-danger"
                @click="() => showDeleteSkillConfirm(skill.id)"
              />
            </div>
          </span>
          <h4 v-if="skill.description">{{ skill.description }}</h4>
        </div>
      </a-col>
    </a-row>

    <!-- ADD NEW SKILL MODAL -->
    <add-skill-modal
      :visible="addSkillModalVisible"
      @get-skills="$emit('get-skills')"
      @store-skill="(newSkill) => handleStoreSkill(newSkill)"
      @close="addSkillModalVisible = false"
    />

    <!-- EDIT SKILL MODAL -->
    <edit-skill-modal
      :visible="editSkillModalVisible"
      :skill="skillToEdit"
      @update-skill="(newSkill) => handleUpdateSkill(newSkill)"
      @close="editSkillModalVisible = false"
    />

    <!-- VIEW SKILL OVERVIEW MODAL -->
    <list-skill-overview-modal
      :visible="listSkillOverviewModalVisible"
      :skill="skillToView"
      :skill-users="skillUsersToView"
      @close="listSkillOverviewModalVisible = false"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, getCurrentInstance, computed, onBeforeMount } from "vue";
import ListSkillOverviewModal from "../_components/ListSkillOverviewModal.vue";
import { BaseAclInterface } from "@/modules/authentication/types";
import { SkillInterface, SkillPayloadInterface } from "../types";
import { useAuthenticationStore } from "@/modules/authentication/_store";
import EditSkillModal from "../_components/EditSkillModal.vue";
import AddSkillModal from "../_components/AddSkillModal.vue";
import {
  apiGetSkills,
  apiStoreSkill,
  apiUpdateSkill,
  apiDestroySkill,
  apiGetSkillUsers,
} from "../_utils/api";

// Pinia
const authenticationStore = useAuthenticationStore();
const baseAcl = computed<BaseAclInterface>(() => authenticationStore.baseAcl);

// Instances
const instance = getCurrentInstance();
const $message = instance?.proxy.$message;
const $confirm = instance?.proxy.$confirm;

// Data properties
const skillToEdit = ref<SkillInterface | undefined>(undefined);
const skillToView = ref<SkillInterface | undefined>(undefined);
const listSkillOverviewModalVisible = ref<boolean>(false);
const editSkillModalVisible = ref<boolean>(false);
const addSkillModalVisible = ref<boolean>(false);
const skillUsersToView = ref<Array<any>[]>([]);
const skills = ref<Array<SkillInterface>>([]);

// Lifecycle hooks
onBeforeMount(() => {
  apiGetSkills().then(({ data }: any) => (skills.value = data.data));
});

// Component methods
const handleStoreSkill = (newSkill: SkillPayloadInterface): void => {
  apiStoreSkill(newSkill)
    .then(({ data }: any) => skills.value.push(data.data))
    .then(() => $message?.success("Skill successfully created!", 3))
    .catch(() => $message?.error("Couldn't create skill!", 3));
};

const handleEditSkillModalVisible = (skill: SkillInterface): void => {
  skillToEdit.value = skill;
  editSkillModalVisible.value = true;
};

const handleViewSkillOverviewModalVisible = (skill: SkillInterface): void => {
  skillToView.value = skill;
  listSkillOverviewModalVisible.value = true;
  skillUsersToView.value = [];

  if (skillToView.value != null)
    apiGetSkillUsers(skillToView.value.id).then(
      (res: any) => (skillUsersToView.value = res.data.data)
    );
};

const handleUpdateSkill = (updatedSkill: SkillPayloadInterface): void => {
  apiUpdateSkill(updatedSkill)
    .then(
      ({ data }: any) =>
        (skills.value = skills.value.map((s) => (s.id === data.data.id ? data.data : s)))
    )
    .then(() => $message?.success("Skill successfully updated!", 3))
    .catch(() => $message?.error("Couldn't update skill!", 3));
};

const handleDestroySkill = (skillId: number): void => {
  apiDestroySkill(skillId)
    .then(() => (skills.value = skills.value.filter((s) => s.id !== skillId)))
    .then(() => $message?.success("Skill successfully deleted!", 3))
    .catch(() => $message?.error("Couldn't delete skill!", 3));
};

const showDeleteSkillConfirm = (skillId: number): void => {
  const mod = $confirm?.({
    title: "Are you sure you want to delete this skill?",
    okText: "Yes",
    okType: "danger",
    cancelText: "No",
    onOk() {
      handleDestroySkill(skillId);
      mod?.destroy();
    },
  });
};
</script>

<style scoped lang="scss"></style>
