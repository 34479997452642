<template>
  <div v-if="acl.isAdmin || acl.isFinancial || acl.isOwner || acl.isCr">
    <div class="card">
      <div class="d-flex justify-content-between">
        <h2>User Financial Details</h2>

        <!-- EDIT ICONS -->
        <div
          v-if="acl.isAdmin || acl.isFinancial || user.id === profile.id"
          class="d-flex align-items-start"
        >
          <div class="action-default" @click="editFinancialModalVisible = true">
            <a-icon type="edit" class="pr-1" />Edit
          </div>
        </div>
      </div>

      <!-- Row 1 -->
      <a-row type="flex" justify="space-around" class="text-center mt-2">
        <a-col :span="7">
          <div v-if="user.employment_type">
            <p>
              <strong>Salary model:</strong>
            </p>
            <p>
              {{
                user.employment_type === "fixed"
                  ? "Fixed salary"
                  : user.employment_type === "variable"
                    ? "Variable salary"
                    : user.employment_type === "hourly"
                      ? "Hourly salary"
                      : "Subcontractor"
              }}
            </p>
          </div>
        </a-col>

        <a-col :span="7">
          <div v-if="user.bank">
            <p>
              <strong>Bank:</strong>
            </p>
            <p>{{ user.bank }}</p>
          </div>
        </a-col>

        <a-col :span="7">
          <div v-if="user.employment_type === 'fixed'">
            <p>
              <VPopover offset="5" placement="right">
                <!-- popover target (for the events and position) -->
                <strong> Available vacation days: </strong>
                <a-icon type="info-circle" />
                <!-- the content of the popover -->
                <template #popover>
                  <div class="popover">
                    <p>
                      These days were earned last year and are now available to take as paid
                      vacation this year.
                    </p>
                    <p>
                      Note that a maximum of 5 days can be saved to next year and any additional
                      days available at the end of the year will be paid out as salary.
                    </p>
                  </div>
                </template>
              </VPopover>
            </p>
            <p>
              {{ user.vacation_days }} this year
              <br />
              {{ user.vacation_days_saved }} saved
            </p>
          </div>
          <div v-else-if="user.vacation_percent">
            <p>
              <strong>Vacation:</strong>
            </p>
            <p>{{ toPercentage(user.vacation_percent) }}</p>
          </div>
        </a-col>
      </a-row>

      <!-- Row 2 -->
      <a-row type="flex" justify="space-around" class="text-center">
        <a-col :span="7">
          <div v-if="user.fortnox_cost_center_id">
            <p>
              <strong>Fortnox cost-center ID:</strong>
            </p>
            <p>{{ user.fortnox_cost_center_id }}</p>
          </div>
        </a-col>

        <a-col :span="7">
          <div v-if="user.salary_account_number">
            <p>
              <strong>Salary account number:</strong>
            </p>
            <p>{{ user.salary_account_number }}</p>
          </div>
        </a-col>

        <a-col :span="7">
          <div v-if="user.salary && user.employment_type === 'fixed'">
            <p>
              <VPopover offset="5" placement="right">
                <!-- popover target (for the events and position) -->
                <strong> Prepaid vacation days: </strong>
                <a-icon type="info-circle" />
                <!-- the content of the popover -->
                <template #popover>
                  <div class="popover">
                    <p>
                      These days were utilised for paid vacation at a time when they were not yet
                      earned.
                    </p>
                    <p>
                      They will be offset towards earned days if you end your employment or change
                      to a variable salary model.
                    </p>
                  </div>
                </template>
              </VPopover>
            </p>
            <p>{{ user.vacation_days_prepaid }}</p>
          </div>
        </a-col>
      </a-row>

      <!-- Row 3 -->
      <a-row type="flex" justify="space-around" class="text-center mt-2">
        <a-col :span="7">
          <div v-if="user.income_tax_percent">
            <p>
              <strong>Income tax:</strong>
            </p>
            <p>{{ toPercentage(user.income_tax_percent) }}</p>
          </div>
        </a-col>

        <a-col :span="7">
          <div v-if="user.employment_type === 'fixed'">
            <div v-if="user.salary && user.employment_type === 'fixed'">
              <p>
                <strong>Salary amount:</strong>
              </p>
              <p>{{ toCurrency(user.salary) }}</p>
            </div>
          </div>
          <div v-else-if="user.employment_type !== 'fixed'">
            <div v-if="user.payroll_tax_percent">
              <p>
                <strong>Payroll tax:</strong>
              </p>
              <p>{{ toPercentage(user.payroll_tax_percent) }}</p>
            </div>
          </div>
        </a-col>

        <a-col :span="7">
          <div v-if="user.salary && user.employment_type === 'fixed'">
            <p>
              <VPopover offset="5" placement="right">
                <!-- popover target (for the events and position) -->
                <strong> Earned vacation days: </strong>
                <a-icon type="info-circle" />
                <!-- the content of the popover -->
                <template #popover>
                  <div class="popover">
                    <p>
                      These days are the total vacation days accrued since your employment started.
                    </p>
                    <p>Some of these belong to the next calendar year and are not yet available.</p>
                  </div>
                </template>
              </VPopover>
            </p>
            <p>{{ earnedVacationDays }}</p>
          </div>
        </a-col>
      </a-row>

      <!-- Row 4 -->
      <a-row type="flex" justify="space-around" class="text-center">
        <a-col :span="7">
          <div v-if="user.employment_type === 'fixed'">
            <div v-if="user.payroll_tax_percent">
              <p>
                <strong>Payroll tax:</strong>
              </p>
              <p>{{ toPercentage(user.payroll_tax_percent) }}</p>
            </div>
          </div>
        </a-col>

        <a-col :span="7" />

        <a-col :span="7">
          <div v-if="user.salary && user.employment_type === 'fixed'">
            <p>
              <VPopover offset="5" placement="right">
                <!-- popover target (for the events and position) -->
                <strong> Vacation days per year: </strong>
                <a-icon type="info-circle" />
                <!-- the content of the popover -->
                <template #popover>
                  <div class="popover">
                    <p>Total number of vacation days you have per year.</p>
                  </div>
                </template>
              </VPopover>
            </p>
            <p>{{ user.vacation_days_yearly }}</p>
          </div>
        </a-col>
      </a-row>

      <!-- EDIT FINANCIAL MODAL -->
      <edit-financial-modal
        :visible="editFinancialModalVisible"
        :user="user"
        :acl="acl"
        @close="editFinancialModalVisible = false"
        @update-user="(updatedUser) => $emit('update-user', updatedUser)"
      />
    </div>

    <div class="card mt-4">
      <receipts-view
        ref="receiptsview"
        :acl="acl"
        :receipts-filter="userReceiptsFilter"
        :receipts-columns="userReceiptsColumns"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, toRefs } from "vue";
import { BaseAclInterface, ProfileInterface } from "../../authentication/types";
import ReceiptsView from "../../receipts/_components/ReceiptsView.vue";
import { userReceiptsColumns } from "../../receipts/_utils/tables";
import { ReportsFilterInterface } from "@/modules/reports/types";
import EditFinancialModal from "./EditFinancialModal.vue";
import { PaginationInterface } from "@/common/types";
import useMixin from "@/useMixin";
import moment from "@/date";

// Props
const props = defineProps({
  receiptsPagination: { type: Object as () => PaginationInterface, default: undefined },
  users: { type: Array as () => Array<ProfileInterface>, default: () => [] },
  profile: { type: Object as () => ProfileInterface, default: undefined },
  paymentOptions: { type: Array as () => Array<any>, default: () => [] },
  user: { type: Object as () => ProfileInterface, default: undefined },
  receiptsPage: { type: Array as () => Array<any>, default: () => [] },
  acl: { type: Object as () => BaseAclInterface, default: undefined },
  categories: { type: Array as () => Array<any>, default: () => [] },
  currencies: { type: Array as () => Array<any>, default: () => [] },
  currentReceiptsPage: { type: Number, default: 1 },
  loadingTable: { type: Boolean, default: false },
  tab: { type: String, default: undefined },
  pageSize: { type: Number, default: 25 },
});

// Mixins
const { toPercentage, toCurrency } = useMixin();

// Data Properties
const { user } = toRefs(props);
const editFinancialModalVisible = ref<boolean>(false);
const userReceiptsFilter = ref<ReportsFilterInterface>({ user_id: [user.value.id] });
const receiptsview = ref<InstanceType<typeof ReceiptsView>>();

// Computed Properties
const earnedVacationDays = computed(() => {
  if (!props.user) return 0;
  const daysSinceStart = moment().diff(props.user.start_date, "days");
  return Math.ceil((daysSinceStart * props.user.vacation_days_yearly) / 365);
});

// Component methods
const handleRefreshReceipts = () => {
  receiptsview.value?.refreshReceipts();
};

defineExpose({ handleRefreshReceipts });
</script>

<style lang="scss" scoped>
.popover {
  max-width: 200px;
  color: rgb(69, 69, 69);
  text-align: center;
  background-color: rgb(255, 255, 255) !important;
  border-radius: 0.25rem;
  box-shadow: 5px 5px rgb(187, 187, 187);
  border: 1px solid rgb(65, 65, 65);
  padding: 5px;
  box-shadow: 2px 7px 5px #888888;
}
</style>
