<template>
  <a-layout-content class="my-4 mx-3">
    <div v-if="user != null && acl != null" class="container">
      <a-tabs :active-key="tab" @change="handleTabChanged">
        <!-- GENERAL INFO -->
        <a-tab-pane key="info" tab="Profile Info">
          <info-tab
            :acl="acl"
            :user="user"
            :knowledge-domains="allKnowledgeDomains"
            :skills="skills"
            @store-experience="handleStoreExperience"
            @update-experience="handleUpdateExperience"
            @destroy-experience="handleDestroyExperience"
            @update-profile="handleUpdateProfile"
            @get-knowledge-domains="handleGetKnowledgeDomains"
            @get-skills="handleGetSkills"
          />
        </a-tab-pane>

        <!-- PROJECT ROLES -->
        <a-tab-pane key="roles" tab="Roles" class="card">
          <roles-view
            ref="rolesview"
            title="Project Roles"
            :acl="acl"
            :roles-filter="userRolesFilter"
            :roles-columns="myRolesColumns"
          />
        </a-tab-pane>

        <!-- SKILLS AND INTERESTS -->
        <a-tab-pane key="skills" tab="Skills & Hobbies">
          <skills-tab
            :acl="acl"
            :user-skills="userSkills"
            :skills="skills"
            :interests="interests"
            :user="user"
            @store-user-skill="handleStoreUserSkill"
            @update-user-skill="handleUpdateUserSkill"
            @get-skills="handleGetSkills"
            @get-interests="handleGetInterests"
            @destroy-user-skill="handleDestroyUserSkill"
            @update-interests="(interest) => handleUpdateInterests(interest)"
          />
        </a-tab-pane>

        <!-- FINANCIAL DATA AND RECEIPTS -->
        <a-tab-pane
          v-if="acl.isFinancial || acl.isAdmin || acl.isOwner || acl.isCr"
          key="financial"
          tab="Financial"
        >
          <financial-tab
            ref="financialtab"
            :user="user"
            :profile="profile"
            :acl="acl"
            :tab="tab"
            :loading-table="loadingTable"
            :payment-options="paymentOptions"
            @update-user="handleUpdateProfile"
          />
        </a-tab-pane>
      </a-tabs>
    </div>
  </a-layout-content>
</template>

<script setup lang="ts">
import { ref, watch, onMounted, getCurrentInstance, computed } from "vue";
import { ProfileInterface, BaseAclInterface } from "../../authentication/types";
import { apiGetKnowledgeDomains } from "../../consultant_profiles/_utils/api";
import { KnowledgeDomainInterface } from "../../consultant_profiles/types";
import RolesView from "@/modules/roles/_components/RolesView.vue";
import { useAuthenticationStore } from "@/modules/authentication/_store";
import { myRolesColumns } from "@/modules/roles/_utils/tables";
import { RolesFilterInterface } from "@/modules/roles/types";
import FinancialTab from "../_components/FinancialTab.vue";
import SkillsTab from "../_components/SkillsTab.vue";
import { SkillInterface } from "../../skills/types";
import InfoTab from "../_components/InfoTab.vue";
import {
  apiGetUser,
  apiUpdateUser,
  apiStoreExperience,
  apiUpdateExperience,
  apiDestroyExperience,
  apiUpdateUserInterests,
  apiGetInterests,
} from "../_utils/api";
import {
  apiGetUserSkills,
  apiStoreUserSkill,
  apiUpdateUserSkill,
  apiDestroyUserSkill,
  apiGetSkills,
} from "@/modules/skills/_utils/api";
import { useRoute, useRouter } from "vue-router/composables";

// Pinia
const authenticationStore = useAuthenticationStore();
const profile = computed<ProfileInterface | undefined>(() => authenticationStore.profile);
const baseAcl = computed<BaseAclInterface>(() => authenticationStore.baseAcl);

// Instances
const route = useRoute();
const router = useRouter();
const instance = getCurrentInstance();
const $message = instance?.proxy.$message;

// Data Properties
const userRolesFilter = ref<RolesFilterInterface | undefined>(undefined);
const allKnowledgeDomains = ref<Array<KnowledgeDomainInterface>>([]);
const financialtab = ref<InstanceType<typeof FinancialTab>>();
const user = ref<ProfileInterface | undefined>(undefined);
const rolesview = ref<InstanceType<typeof RolesView>>();
const userSkills = ref<Array<SkillInterface>>([]);
const skills = ref<Array<SkillInterface>>([]);
const paymentOptions = ref<any | null>(null);
const loadingTable = ref<boolean>(false);
const interests = ref<any>(null);
const tab = ref<string>("info");

// Computed properties
const isOwner = computed((): boolean => {
  return Boolean(user.value && user.value.id === profile.value?.id);
});

const acl = computed((): BaseAclInterface & { isOwner: boolean } => {
  return { ...baseAcl.value, isOwner: isOwner.value };
});

// Watchers
watch(route, async () => {
  if (user.value && parseInt(route.params.id as string) != user.value.id) {
    user.value = undefined;
    await apiGetUser(parseInt(route.params.id as string)).then((res: any) => {
      user.value = res.data.data;
      userRolesFilter.value = { user_id: [res.data.data.id] };
    });
  }
  changeTab(route.hash ? route.hash : "#info");
});

// Life-cycle Hooks
onMounted(() => {
  apiGetUser(parseInt(route.params.id as string)).then((res: any) => {
    user.value = res.data.data;
    changeTab(route.hash as string);
    userRolesFilter.value = { user_id: [res.data.data.id] };
  });
});

// Component methods
const changeTab = (hash: string): void => {
  if (hash) {
    const t = hash.substring(1);
    if (t != tab.value) {
      handleTabChanged(t);
    }
  }
};

const isTabValid = (tab: string): boolean => {
  const validTabs = ["info"];
  if (acl.value.isInternal) {
    validTabs.push("roles");
    validTabs.push("skills");
  }
  if (user.value != null)
    if (
      acl.value.isFinancial ||
      acl.value.isAdmin ||
      acl.value.isCr ||
      profile.value?.id === user.value.id
    ) {
      validTabs.push("financial");
    }
  return validTabs.includes(tab);
};

const handleTabChanged = (t: string): void => {
  if (!isTabValid(t)) {
    t = "";
  }
  switch (t) {
    case "roles":
      rolesview.value?.refreshRoles();
      break;
    case "financial":
      if (isOwner.value) {
        loadingTable.value = true;
      }
      financialtab.value?.handleRefreshReceipts();
      break;
    case "skills":
      if (isOwner.value) {
        apiGetUserSkills(profile.value?.id as number).then(
          (res: any) => (userSkills.value = res.data.data)
        );
      } else {
        if (user.value != null)
          apiGetUserSkills(user.value.id).then((res: any) => (userSkills.value = res.data.data));
      }
      break;
  }
  tab.value = t;
  router.replace(t ? `#${t}` : "");
};

const handleGetUserProfile = () => {
  if (user.value != null)
    apiGetUser(user.value.id).then((res: any) => (user.value = res.data.data));
};

const handleUpdateProfile = (updatedUser: ProfileInterface): void => {
  if (user.value === null) return;
  apiUpdateUser(user.value?.id as number, updatedUser)
    .then((res: any) => (user.value = res.data.data))
    .then(() => $message?.success("Profile successfully updated!", 3))
    .catch(() => $message?.error("Couldn't update the profile!", 3));
};

// Experiences: knowledge domains, education, work experiences & merits
const handleStoreExperience = ({ a, type }: { a: any; type: any }): void => {
  if (user.value === null) return;
  apiStoreExperience(type, user.value?.id as number, a)
    .then(() => handleGetUserProfile())
    .then(() => $message?.success("Experience successfully created!", 3))
    .catch(() => $message?.error("Couldn't create experience!", 3));
};

const handleUpdateExperience = ({ a, type }: { a: any; type: any }): void => {
  if (user.value === null) return;
  apiUpdateExperience(type, user.value?.id as number, a.id, a)
    .then(() => handleGetUserProfile())
    .then(() => $message?.success("Experience successfully updated!", 3))
    .catch(() => $message?.error("Couldn't update experience!", 3));
};

const handleDestroyExperience = ({ id, type }: { id: number; type: any }): void => {
  if (user.value === null) return;
  apiDestroyExperience(type, user.value?.id as number, id)
    .then(() => handleGetUserProfile())
    .then(() => $message?.success("Experience successfully removed!", 3))
    .catch(() => $message?.error("Couldn't remove experience!", 3));
};

const handleUpdateInterests = (updatedInterests: any): void => {
  if (user.value === null) return;
  apiUpdateUserInterests(user.value?.id as number, updatedInterests)
    .then(() => handleGetUserProfile())
    .then(() => $message?.success("Interests successfully updated!", 3))
    .catch(() => $message?.error("Couldn't update interests!", 3));
};

const handleStoreUserSkill = (newUserSkill: SkillInterface): void => {
  if (user.value === null) return;
  apiStoreUserSkill(user.value?.id as number, newUserSkill)
    .then(() => handleGetUserProfile())
    .then(() => {
      if (user.value != null)
        apiGetUserSkills(user.value.id).then((res: any) => (userSkills.value = res.data.data));
    })
    .then(() => $message?.success("User skill successfully added!", 3))
    .catch(() => $message?.error("Couldn't add user skill!", 3));
};

const handleUpdateUserSkill = (updatedUserSkill: SkillInterface): void => {
  if (user.value === null) return;
  apiUpdateUserSkill(user.value?.id as number, updatedUserSkill.id, updatedUserSkill)
    .then(() => handleGetUserProfile())
    .then(() => {
      if (user.value != null)
        apiGetUserSkills(user.value.id).then((res: any) => (userSkills.value = res.data.data));
    })
    .then(() => $message?.success("User skill successfully updated!", 3))
    .catch(() => $message?.error("Couldn't update user skill!", 3));
};

const handleDestroyUserSkill = (userSkill: SkillInterface): void => {
  if (user.value === null) return;
  apiDestroyUserSkill(user.value?.id as number, userSkill.id)
    .then(() => handleGetUserProfile())
    .then(() => {
      if (user.value != null)
        apiGetUserSkills(user.value.id).then((res: any) => (userSkills.value = res.data.data));
    })
    .then(() => $message?.success("User skill successfully removed!", 3))
    .catch(() => $message?.error("Couldn't remove user skill!", 3));
};

const handleGetSkills = (): void => {
  apiGetSkills().then((res: any) => (skills.value = res.data.data));
};

const handleGetKnowledgeDomains = (): void => {
  apiGetKnowledgeDomains().then((res: any) => (allKnowledgeDomains.value = res.data.data));
};

const handleGetInterests = () => {
  apiGetInterests().then((res: any) => (interests.value = res.data.data));
};
</script>
