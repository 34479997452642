<template>
  <a-layout-content class="my-4 mx-3">
    <div class="container">
      <!-- HEADER -->
      <div class="d-flex justify-content-between">
        <h2>Audits</h2>
      </div>

      <a-form layout="horizontal">
        <a-form-item :label-col="{ span: 8 }" :wrapper-col="{ span: 8 }" label="Resource">
          <a-select v-model="queryParams.auditable_type" placeholder="Select a resource">
            <a-select-option value="User">User</a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item :label-col="{ span: 8 }" :wrapper-col="{ span: 8 }" label="Item">
          <a-select v-model="queryParams.auditable_id" placeholder="Select an item">
            <a-select-option v-for="i in resourceList" :key="i.id">
              {{ i[resourceAttribute] }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item class="d-flex justify-content-center">
          <a-button :loading="loading" type="primary" @click="() => handleGetAudit()"
            >Search</a-button
          >
        </a-form-item>
      </a-form>

      <a-timeline class="mt-4">
        <a-timeline class="mt-4">
          <a-timeline-item v-for="t in auditTrail" :key="t.id">
            [{{ new Date(t.created_at).toLocaleString("sv-SE") }}] Action
            <strong>{{ t.action }}</strong> by
            <strong>{{ t.user ? t.user.name : "Heroku CLI" }}</strong>
            <br />Audited changes:
            <ul>
              <li v-for="c in Object.keys(t.audited_changes)" :key="c">
                <samp
                  >{{ c }}: {{ t.audited_changes[c]?.[0] }} -> {{ t.audited_changes[c]?.[1] }}</samp
                >
              </li>
            </ul>
          </a-timeline-item>
        </a-timeline>
      </a-timeline>
    </div>
  </a-layout-content>
</template>

<script lang="ts">
import { watch, defineComponent, ref } from "vue";
import { getUsersCompactList } from "@/api";
import { apiGetAudit } from "../_utils/api";
import { useAuthenticationStore } from "@/modules/authentication/_store";
import { MinimalUserInterface } from "../../users/types";

export default defineComponent({
  beforeRouteEnter(to: any, from: any, next: any): void {
    const authenticationStore = useAuthenticationStore();
    authenticationStore.isAdmin ? next() : next({ name: "dashboard" });
  },

  setup() {
    // Data properties
    const queryParams = ref<any>({
      auditable_type: undefined,
      auditable_id: undefined,
    });
    const resourceList = ref<MinimalUserInterface[]>([]);
    const resourceAttribute = ref<string>("");
    const auditTrail = ref<Array<any>>([]);
    const loading = ref<Boolean>(false);

    // Class methods
    const handleSelectResource = (resource: string): void => {
      if (resource === "User") {
        resourceAttribute.value = "name";
        getUsersCompactList().then((users) => {
          resourceList.value = users;
        });
      }
    };

    const handleGetAudit = (): void => {
      loading.value = true;
      apiGetAudit(queryParams.value)
        .then(({ data }: any) => (auditTrail.value = data.data))
        .finally(() => (loading.value = false));
    };

    watch(
      () => queryParams.value.auditable_type,
      (newValue) => {
        if (newValue) {
          handleSelectResource(newValue);
        }
      }
    );

    return {
      loading,
      queryParams,
      resourceList,
      auditTrail,
      resourceAttribute,
      handleSelectResource,
      handleGetAudit,
    };
  },
});
</script>

<style lang="scss" scoped>
samp {
  background-color: white;
}
</style>
