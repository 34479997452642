<template>
  <a-layout :style="{ minHeight: '100vh' }">
    <div :class="{ backdrop: !sidebarCollapsed }" @click="() => toggleSidebarCollapse()" />

    <!-- SIDEBAR COMPONENT -->
    <sidebar
      :acl="baseAcl"
      :sidebar-collapsed="sidebarCollapsed"
      @sidebar-clicked="handleSidebarClick"
    />

    <a-layout>
      <!-- NAVBAR COMPONENT -->
      <navbar />

      <!-- MAIN CONTENT -->
      <router-view />
    </a-layout>
  </a-layout>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { useAuthenticationStore } from "@/modules/authentication/_store";
import { BaseAclInterface } from "@/modules/authentication/types";
import Sidebar from "@/modules/common/_components/Sidebar.vue";
import Navbar from "@/modules/common/_components/Navbar.vue";
import { useCommonStore } from "@/modules/common/_store";
import { getJwtToken } from "@/network";

export default defineComponent({
  components: {
    Sidebar,
    Navbar,
  },
  // Load profile before entering the route of this component. We can assume we
  // are logged in since a guard 'beforeEach' on the global Vue router instance
  // redirects unauthenticated users to login view.
  beforeRouteEnter(to: any, from: any, next: any): void {
    const jwtToken = getJwtToken();
    if (jwtToken) {
      const profileId: number = jwtToken["user_id"];
      const authenticationStore = useAuthenticationStore();
      authenticationStore.getProfile(profileId).then(() => next());
    }
  },
  setup() {
    // Pinia
    const authenticationStore = useAuthenticationStore();
    const commonStore = useCommonStore();
    const baseAcl = computed<BaseAclInterface>(() => authenticationStore.baseAcl);
    const sidebarCollapsed = computed<boolean>(() => commonStore.sidebarCollapsed);
    const toggleSidebarCollapse = () => commonStore.toggleSidebarCollapse();

    // Component Methods
    const handleSidebarClick = (): void => {
      // @ts-ignore
      if (device.mobile() && !sidebarCollapsed.value) {
        toggleSidebarCollapse();
      }
    };

    return {
      sidebarCollapsed,
      baseAcl,
      toggleSidebarCollapse,
      handleSidebarClick,
    };
  },
});
</script>

<style lang="scss" scoped>
.backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #3d4852;
  opacity: 0.3;
  z-index: 3;
}
</style>
