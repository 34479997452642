<template>
  <div>
    <empty-resource-table v-if="!reportsPage[0] && !loadingTable" resource="reports" />

    <a-table
      v-if="loadingTable || reportsPage[0]"
      :columns="reportsColumns"
      :data-source="reportsPage"
      :loading="loadingTable"
      :pagination="false"
      :expand-row-by-click="true"
      :scroll="{ x: 1000 }"
      :row-key="(report) => report.id"
      @change="(pagination, filters, sorter) => handleChange(pagination, filters, sorter)"
    >
      <template #user="userName, report">
        <span class="d-flex align-items-center">
          <span class="min-w-8 mr-2">
            <a-avatar :src="report.user.gravatar" />
          </span>
          <router-link :to="{ name: 'user', params: { id: report.user.id } }" @click.native.stop>
            {{ userName }}
          </router-link>
        </span>
      </template>

      <template #type="type">
        <span>
          <a-badge :class="getReportBadgeClass(type)" status="default" />
          <span>{{ parseReportType(type) }}</span>
          <a-icon v-if="type === ReportType.TIME" class="ml-2" type="tag-o" />
        </span>
      </template>

      <template #project="projectName, report">
        <span class="d-flex flex-column">
          <small v-if="report.type === ReportType.TIME">{{ report.project.client.name }}</small>
          <small v-if="report.type === ReportType.TIME">{{ report.project.name }}</small>

          {{ report.role?.title }}
        </span>
      </template>

      <template #date="date, report">
        <span>
          <span>{{ date }}</span>
          <span v-if="report.end_date">{{ ` - ${report.end_date}` }}</span>
          <a-icon
            v-if="
              report.type !== ReportType.VACATION &&
              report.type !== ReportType.EXPENSE &&
              report.type !== ReportType.PARENTAL
            "
            :class="`${report.locked ? 'text-yellow' : 'text-red'} ml-2`"
            :type="report.locked ? 'lock' : 'unlock'"
          />
        </span>
      </template>

      <template #actions="text, report">
        <span>
          <a-icon
            v-show="report.editable"
            type="edit"
            class="action-default"
            @click.stop="() => handleEditReportModalVisible(report)"
          />
          <a-icon v-if="!report.editable" type="edit" class="text-grey" />

          <a-divider type="vertical" />

          <a-icon
            v-if="report.type === ReportType.EXPENSE"
            type="reload"
            class="action-default"
            @click.stop="handleAddRecurringJobModalVisible(report)"
          />
          <a-divider v-if="report.type === ReportType.EXPENSE" type="vertical" />

          <a-icon
            v-if="report.deletable"
            type="delete"
            class="action-danger"
            @click.stop="() => showDeleteReportConfirm(report.id)"
          />
          <a-icon v-if="!report.deletable" type="delete" class="text-grey" />
        </span>
      </template>

      <template #expandedRowRender="report">
        <div>
          <a-row v-if="report.type === ReportType.TIME || report.type === ReportType.DETACHEDTIME">
            <a-col v-if="report.type === ReportType.TIME" :span="12">
              <a-col :span="6">
                <p>
                  <strong>Project:</strong>
                </p>
                <p>
                  <strong>Role:</strong>
                </p>
                <a-row>
                  <a-col v-if="report.subproject_reports && report.subproject_reports.length"
                    ><strong>Subprojects:</strong></a-col
                  >
                </a-row>
              </a-col>
              <a-col :span="18">
                <p>
                  <router-link
                    :to="{
                      name: 'project',
                      params: { projectId: report.project.id },
                    }"
                  >
                    {{ report.project.name }}
                  </router-link>
                </p>
                <p>{{ report.role.title }}</p>
                <a-row v-for="sr in report.subproject_reports" :key="sr.id">
                  <a-col>
                    <span>
                      <!-- Dot character -->
                      &#x2022;
                      {{ sr.name }}
                    </span>
                    <span> ({{ sr.amount }}h) </span>
                  </a-col>
                </a-row>
              </a-col>
            </a-col>

            <a-col :span="12">
              <a-col :span="6">
                <p>
                  <strong>Logged time:</strong>
                </p>
                <p>
                  <strong>Hourly rate:</strong>
                </p>
                <p>
                  <strong>Amount:</strong>
                </p>
                <p>
                  <strong>Cost center:</strong>
                </p>
              </a-col>
              <a-col :span="18">
                <p>{{ report.amount }}hrs</p>
                <p>{{ toMoney(report.hourly_rate) }}</p>
                <p>{{ toMoney(report.total_cost) }}</p>
                <p>{{ report.cost_center.name }}</p>
              </a-col>
            </a-col>
          </a-row>

          <a-row v-else-if="report.type === ReportType.EXPENSE">
            <a-col :span="12">
              <a-col :span="6">
                <p>
                  <strong>Title:</strong>
                </p>
                <p>
                  <strong>Project:</strong>
                </p>
              </a-col>
              <a-col :span="18">
                <p>{{ report.title }}</p>
                <p>
                  <router-link
                    :to="{
                      name: 'project',
                      params: { projectId: report.project.id },
                    }"
                  >
                    {{ report.project.name }}
                  </router-link>
                </p>
              </a-col>
            </a-col>

            <a-col :span="12">
              <a-col :span="3">
                <p>
                  <strong>Cost:</strong>
                </p>
              </a-col>
              <a-col :span="21">
                <p>{{ toMoney(report.cost) }}</p>
              </a-col>
            </a-col>
          </a-row>

          <a-row v-if="report.type === ReportType.PARENTAL">
            <a-col :span="3">
              <p>
                <strong>Parental Leave:</strong>
              </p>
            </a-col>
            <a-col :span="17">{{ formatPercentage(report.parental_percentage) }}</a-col>
          </a-row>

          <a-row :class="report.type === ReportType.TIME ? 'mt-4' : ''">
            <a-col :span="3">
              <p>
                <strong v-if="report.comment">Comment:</strong>
                <em v-else>No comment</em>
              </p>
            </a-col>
            <a-col :span="17">{{ report.comment }}</a-col>
          </a-row>
        </div>
      </template>
    </a-table>

    <a-pagination
      v-if="reportsPagination && (loadingTable || reportsPage[0])"
      :current="currentPage"
      :total="reportsPagination.total"
      :show-size-changer="true"
      :page-size="pageSize"
      :page-size-options="['10', '25', '50', '100']"
      size="small"
      class="d-flex justify-content-end mt-3"
      @change="(page, pageSize) => handleGetReportsPage(page, pageSize, true)"
      @showSizeChange="
        (pageNumber, newPageSize) => handleGetReportsPage(pageNumber, newPageSize, true)
      "
    />

    <!-- EDIT USER-REPORT MODAL -->
    <edit-user-report-modal
      :report="reportToBeEdited"
      :user="userToBeEdited"
      :visible="editUserReportModalVisible"
      :user-roles="userRoles"
      :cost-centers="costCenters"
      :user-vacation-and-absence-reports="userVacationAndAbsenceReports"
      :user-parental-reports="userParentalReports"
      @get-cost-centers="() => $emit('get-cost-centers')"
      @update-report="(updatedReport) => $emit('update-user-report', updatedReport)"
      @close="editUserReportModalVisible = false"
    />

    <!-- EDIT EXPENSE-REPORT MODAL -->
    <edit-expense-report-modal
      :expense-report="reportToBeEdited"
      :cost-centers="costCenters"
      :visible="editExpenseReportModalVisible"
      @get-cost-centers="() => $emit('get-cost-centers')"
      @update-report="(updatedReport) => $emit('update-expense-report', updatedReport)"
      @close="editExpenseReportModalVisible = false"
    />

    <add-recurring-job-modal
      :visible="addRecurringJobModalVisible"
      :entity="recurringJobExpenseReport"
      :default-description="recurringJobDescription"
      type="recurring-expense-report"
      @store-recurring-job="handleStoreRecurringJob"
      @close="addRecurringJobModalVisible = false"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, getCurrentInstance } from "vue";
import AddRecurringJobModal from "@/modules/recurring_jobs/_components/AddRecurringJobModal.vue";
import EmptyResourceTable from "@/modules/common/_components/EmptyResourceTable.vue";
import { ProfileInterface, BaseAclInterface } from "@/modules/authentication/types";
import EditExpenseReportModal from "../_components/EditExpenseReportModal.vue";
import { CostCenterInterface } from "@/modules/cost_centers/types";
import { PaginationInterface, TableColumnInterface } from "../../../common/types";
import EditUserReportModal from "./EditUserReportModal.vue";
import { ReportInterface, ReportType } from "../types";
import { RoleInterface } from "@/modules/roles/types";
import { reportsColumns as defaultReportsColumns } from "../_utils/tables";
import { apiGetReports } from "../_utils/api";
import useMixin from "@/useMixin";
import moment from "moment";

// Props
const props = defineProps({
  reportsPagination: { type: Object as () => PaginationInterface, default: undefined },
  costCenters: { type: Array as () => Array<CostCenterInterface>, default: () => [] },
  reportsPage: { type: Array as () => Array<ReportInterface>, default: () => [] },
  userReports: { type: Array as () => Array<ReportInterface>, default: () => [] },
  userToBeEdited: { type: Object as () => ProfileInterface, default: undefined },
  userRoles: { type: Array as () => Array<RoleInterface>, default: () => [] },
  acl: { type: Object as () => BaseAclInterface, default: undefined },
  projects: { type: Array as () => Array<any>, default: () => [] },
  loadingTable: { type: Boolean, default: false },
  currentPage: { type: Number, default: 1 },
  pageSize: { type: Number, default: 25 },
  reportsColumns: {
    type: Array as () => Array<TableColumnInterface>,
    default: () => defaultReportsColumns,
  },
});

// Emits
const emits = defineEmits([
  "handle-edit-user-report-modal-visible",
  "store-recurring-job",
  "get-reports-page",
  "destroy-report",
  "apply-sorting",
]);

// Mixins
const { toMoney } = useMixin();

// Instance
const instance = getCurrentInstance();
const $confirm = instance?.proxy.$confirm;

// Data Properties
const editUserReportModalVisible = ref<boolean>(false);
const editExpenseReportModalVisible = ref<boolean>(false);
const reportToBeEdited = ref<ReportInterface | undefined>(undefined);
const recurringJobExpenseReport = ref<any>(undefined);
const addRecurringJobModalVisible = ref<boolean>(false);
const recurringJobDescription = ref<any>(undefined);
const userVacationAndAbsenceReports = ref<Array<ReportInterface>>([]);
const userParentalReports = ref<Array<ReportInterface>>([]);

// Class methods
const handleChange = (pagination: any, filters: any, sorter: any): void => {
  emits("apply-sorting", {
    sort: sorter.columnKey,
    order: sorter.columnKey ? (sorter.order === "descend" ? "desc" : "asc") : undefined,
  });
};

const handleEditReportModalVisible = (report: ReportInterface): void => {
  if (!report || !report.user) {
    return;
  }

  apiGetReports({
    user_id: [report.user.id],
    start_date: moment().subtract(2, "M").format("YYYY-MM-DD"),
    types: [ReportType.VACATION, ReportType.ABSENCE],
  }).then((res: any) => (userVacationAndAbsenceReports.value = res.data.data));

  apiGetReports({
    user: [report.user.id],
    start_date: moment().subtract(2, "M").format("YYYY-MM-DD"),
    types: [ReportType.PARENTAL],
  }).then((res: any) => (userParentalReports.value = res.data.data));

  reportToBeEdited.value = report;

  if (report.type === ReportType.EXPENSE) {
    editExpenseReportModalVisible.value = true;
  } else {
    emits("handle-edit-user-report-modal-visible", report);
    editUserReportModalVisible.value = true;
  }
};

const showDeleteReportConfirm = (reportId: number): void => {
  const mod = $confirm?.({
    title: "Are you sure you want to delete this Report?",
    okText: "Yes",
    okType: "danger",
    cancelText: "No",
    onOk() {
      emits("destroy-report", reportId);
      mod?.destroy();
    },
  });
};

const handleGetReportsPage = (page: any, pageSize: number, reportsSummary: boolean): void => {
  emits("get-reports-page", { page, pageSize, reportsSummary });
};

const parseReportType = (reportType: string): string => {
  let type = reportType.replace("-report", "");
  if (reportType === ReportType.DETACHEDTIME) {
    type = "time report (without project)";
  }
  return type.charAt(0).toUpperCase() + type.slice(1);
};

const getReportBadgeClass = (reportType: string): string => {
  // prettier-ignore
  switch (reportType) {
      case ReportType.TIME: return "badge-time";
      case ReportType.VACATION: return "badge-vacation";
      case ReportType.SICK: return "badge-sick";
      case ReportType.EXPENSE: return "expense-report";
      default:
        return ReportType.TIME
    }
};

const handleAddRecurringJobModalVisible = (report: ReportInterface): void => {
  recurringJobDescription.value =
    "--Expense Report--" +
    `\nClient: ${report.project.client.name}` +
    `\nProject: ${report.project.name}` +
    `\nUser: ${report.user ? report.user.name : ""}` +
    `\nCost: ${toMoney(report.cost)}` +
    `\nComment: ${report.comment}` +
    "\n----------";
  recurringJobExpenseReport.value = report;
  addRecurringJobModalVisible.value = true;
};

const handleStoreRecurringJob = (recurringJob: any): void => {
  emits("store-recurring-job", recurringJob);
  addRecurringJobModalVisible.value = false;
};

const formatPercentage = (percentage: number): string => {
  return `${percentage * 100}%`;
};
</script>
