<template>
  <a-layout-content v-if="invoice" style="margin: 24px 16px">
    <div class="container">
      <div class="d-flex justify-content-between align-items-center mb-4">
        <div class="w-100 d-flex align-items-center justify-content-between">
          <router-link
            :to="{ name: 'project', params: { projectId: invoice.project.id } }"
            tag="h3"
            class="m-0 action-default"
          >
            <a-icon type="arrow-left" class="mr-2" />Back
          </router-link>
          <h2 class="m-0 ml-2">Invoice SK#{{ invoice.id }}</h2>
          <h2 style="color: #f0f2f5" />
          <!-- placeholder to keep title balanced -->
        </div>

        <!-- ACTIONS -->
        <div>
          <a-dropdown :trigger="['click']">
            <a class="ant-dropdown-link d-flex align-items-center" href="#">
              <span class="d-none d-lg-block mr-1">Actions</span>
              <a-icon type="down" class="d-none d-lg-block" />
              <a-icon type="down-circle-o" class="d-block d-lg-none" />
            </a>

            <template #overlay>
              <a-menu>
                <a-menu-item
                  v-if="!invoice.fortnox_id && !fortnox_id"
                  @click="() => (sendToFortnoxModalVisible = true)"
                >
                  <a-icon type="key" class="pr-1" />Send to FORTNOX
                </a-menu-item>
                <a-menu-item
                  v-if="invoice.type === 'RequestedInvoice'"
                  @click="() => (editInvoiceModalVisible = true)"
                >
                  <a-icon type="edit" class="pr-1" />Edit
                </a-menu-item>
                <a-menu-item @click="() => (deleteInvoiceModalVisible = true)">
                  <a-icon type="delete" class="pr-1" />Delete
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </div>
      </div>

      <!-- INVOICE SUMMARY -->
      <div class="card mt-4">
        <a-row class="mb-3">
          <a-col :span="24" :lg="12" class="mt-2">
            <a-icon type="rocket" class="pr-1" />Project:
            <strong>{{ invoice.project.name }} ({{ invoice.project.client.name }})</strong>
          </a-col>
          <a-col :span="24" :lg="12" class="mt-2">
            <a-icon type="user" class="pr-1" />Created by
            <strong>{{ invoice.user.name }}</strong> on
            <strong>{{ invoice.date }}</strong>
          </a-col>
        </a-row>

        <a-row class="mb-3">
          <a-col v-if="invoice.total_reports_hours" :span="24" :lg="12" class="mt-2">
            <a-icon type="clock-circle-o" class="pr-1" />Total hours reported:
            <strong>{{ invoice.total_reports_hours }} hour(s)</strong>
          </a-col>
          <a-col v-else :span="24" :lg="12" class="mt-2">
            <a-icon type="clock-circle-o" class="pr-1" />Status:
            <a-tag v-if="invoice.status === 'requested'" color="orange">REQUESTED</a-tag>
            <a-tag v-else-if="invoice.status === 'approved'" color="green">APPROVED</a-tag>
            <a-tag v-else-if="invoice.status === 'rejected'" color="red">REJECTED</a-tag>
            <a-tag v-else color="blue">GENERATED</a-tag>
          </a-col>
          <a-col :span="24" :lg="12" class="mt-2">
            <a-icon type="wallet" class="pr-1" />Total amount:
            <strong>
              {{
                invoice.type === "RequestedInvoice"
                  ? toMoney(invoice.total_invoice_items_amount)
                  : toMoney(invoice.total_reports_amount)
              }}
            </strong>
          </a-col>
        </a-row>

        <a-row v-if="invoice.fortnox_id || fortnox_id" class="mb-3">
          <a-col :span="24" :lg="24" class="mt-2">
            <a-icon type="key" class="pr-1" />Recorded in FORTNOX with ID
            <strong>{{ invoice.fortnox_id ? invoice.fortnox_id : fortnox_id }}</strong>
          </a-col>
        </a-row>

        <a-row v-if="invoice.comment" class="mb-3">
          <a-col :span="24" :lg="24" class="mt-2">
            <a-icon type="file-text" class="pr-1" />Comment:
            <span>
              <em>{{ invoice.comment }}</em>
            </span>
          </a-col>
        </a-row>
      </div>

      <!-- ROLES TABLE -->
      <div v-if="invoice.expense_reports || invoice.roles" class="card mt-4">
        <div class="d-flex justify-content-between">
          <h2>Breakdown of Roles</h2>
        </div>

        <a-table
          :columns="outerInvoiceRolesColumns"
          :data-source="invoice.roles"
          :scroll="{ x: 800 }"
          :pagination="false"
          :row-key="(invoice) => invoice.id"
          class="roles-nested-table"
        >
          <template #role="title, role">
            <span class="d-flex align-items-center">
              <a-avatar :src="role.user.gravatar" size="small" class="mr-2" />
              <span>
                {{ title }} (
                <router-link :to="{ name: 'user', params: { id: role.user.id } }">{{
                  role.user.name
                }}</router-link
                >)
              </span>
            </span>
          </template>

          <template #total_role_hours="totalRoleHours">
            <span>
              {{ totalRoleHours ? `${totalRoleHours} hour(s)` : "-" }}
            </span>
          </template>

          <template #total_value="totalValue">
            <span>
              {{ totalValue ? toMoney(totalValue) : "-" }}
            </span>
          </template>

          <!-- REPORTS IN ROLE TABLE -->
          <template #expandedRowRender="record">
            <a-table
              :columns="innerInvoiceRolesColumns"
              :data-source="record.reports"
              :row-key="(report) => report.id"
              :pagination="false"
            >
              <template #role_hours="roleHours">
                <span>
                  {{ roleHours ? `${roleHours} hour(s)` : "-" }}
                </span>
              </template>

              <template #rate="hourly_rate">
                <span>
                  {{ hourly_rate ? toMoney(hourly_rate) : "-" }}
                </span>
              </template>

              <template #value="value, report">
                <span>{{ toMoney(report.total_cost) }}</span>
              </template>
            </a-table>
          </template>
        </a-table>

        <!-- EXPENSES TABLE -->
        <div class="d-flex justify-content-between mt-5">
          <h2>Expense Reports</h2>
        </div>

        <a-table
          :columns="expenseReportsColumns"
          :data-source="invoice.expense_reports"
          :scroll="{ x: 600 }"
          :row-key="(report) => report.id"
          :pagination="false"
        >
          <template #role="title, role">
            <span class="d-flex align-items-center">
              <a-avatar :src="role.user.gravatar" size="small" class="mr-2" />
              <span>
                {{ title }} (
                <router-link :to="{ name: 'user', params: { id: role.user.id } }">
                  {{ role.user.name }} </router-link
                >)
              </span>
            </span>
          </template>

          <template #value="value">
            <span>{{ value ? toMoney(value) : "-" }}</span>
          </template>
        </a-table>
      </div>

      <!-- ITEMS TABLE -->
      <div v-if="invoice.invoice_items" class="card mt-4">
        <div class="d-flex justify-content-between">
          <h2>Invoice Lines</h2>
        </div>
        <a-table
          :columns="invoiceRequestItemsTableColumns"
          :data-source="invoice.invoice_items"
          :pagination="false"
          :scroll="{ x: 400 }"
          :row-key="(invoiceItem) => invoiceItem.id"
        >
          <template #description="description, item">
            <small>{{ getCostCenterDataById(item.cost_center_id) }}</small>
            <div class="d-flex align-items-center">{{ description }}</div>
          </template>
          <template #unitCost="text, item">
            <span>{{ toMoney(item.unit_cost) }}</span>
          </template>

          <template #totalCost="text, item">
            <span>{{ toMoney(item.total_cost) }}</span>
          </template>
        </a-table>
      </div>
    </div>

    <send-invoice-fortnox
      :invoice="invoice"
      :visible="sendToFortnoxModalVisible"
      @send-to-fortnox="(payload) => handleSendToFortnox(payload)"
      @close="sendToFortnoxModalVisible = false"
    />

    <edit-invoice-modal
      :invoice="invoice"
      :visible="editInvoiceModalVisible"
      @update-invoice="(updatedInvoice) => handelUpdateInvoice(updatedInvoice)"
      @close="editInvoiceModalVisible = false"
    />

    <!-- DELETE INVOICE MODAL -->
    <a-modal
      :visible="deleteInvoiceModalVisible"
      :title="`Are you sure you want to delete invoice SK#${invoice.id} and all its associations?`"
      ok-text="Yes"
      ok-type="danger"
      cancel-text="No"
      @ok="() => handleDestroyInvoice()"
      @cancel="() => (deleteInvoiceModalVisible = false)"
    >
      <h3 class="mb-0">Associations:</h3>
      <p v-for="entity in invoice.associations" :key="entity.name" class="mb-0">
        <strong>{{ entity.name }}:</strong>
        {{ entity.count }}
      </p>
    </a-modal>
  </a-layout-content>
</template>

<script lang="ts">
import { defineComponent, ref, computed, getCurrentInstance, onMounted } from "vue";
import SendInvoiceFortnox from "../_components/SendInvoiceFortnox.vue";
import EditInvoiceModal from "../_components/EditInvoiceModal.vue";
import { CostCenterInterface } from "@/modules/cost_centers/types";
import { useAuthenticationStore } from "@/modules/authentication/_store";
import { useCostCentersStore } from "@/modules/cost_centers/_store";
import useMixin from "@/useMixin";
import { invoiceRequestItemsTableColumns } from "../_utils/tables";
import { expenseReportsColumns } from "@/modules/reports/_utils/tables";
import { outerInvoiceRolesColumns, innerInvoiceRolesColumns } from "@/modules/roles/_utils/tables";
import {
  apiGetProjectInvoice,
  apiUpdateProjectInvoice,
  apiDestroyProjectInvoice,
  apiSendInvoiceToFortnox,
} from "../_utils/api";

export default defineComponent({
  components: {
    SendInvoiceFortnox,
    EditInvoiceModal,
  },
  beforeRouteEnter(to: any, from: any, next: any): void {
    const authenticationStore = useAuthenticationStore();
    const costCentersStore = useCostCentersStore();
    if (!authenticationStore.isInternal) next({ name: "dashboard" });
    costCentersStore.getCostCenters().then(() => next());
  },
  beforeRouteUpdate(to: any, from: any, next: any): void {
    const costCentersStore = useCostCentersStore();
    costCentersStore.getCostCenters().then(() => next());
  },
  setup() {
    // Pinia
    const costCentersStore = useCostCentersStore();
    const costCenters = computed<Array<CostCenterInterface>>(() => costCentersStore.cost_centers);

    // Mixins
    const { toMoney } = useMixin();

    // Instances
    const instance = getCurrentInstance();
    const $route = instance?.proxy.$route;
    const $router = instance?.proxy.$router;
    const $message = instance?.proxy.$message;

    // Data properties
    const sendToFortnoxModalVisible = ref<boolean>(false);
    const editInvoiceModalVisible = ref<boolean>(false);
    const deleteInvoiceModalVisible = ref<boolean>(false);
    const fortnox_id = ref<string | null>(null);
    const invoice = ref<any | null>(null);

    // Lifecycle hooks
    onMounted(() => {
      const projectId = parseInt($route?.params.projectId as string);
      const invoiceId = parseInt($route?.params.invoiceId as string);
      apiGetProjectInvoice(projectId, invoiceId).then(
        ({ data }: any) => (invoice.value = data.data)
      );
    });

    // Component methods
    const getCostCenterDataById = (id: number): string => {
      const cc = costCenters.value.find((cc: any) => cc.id === id);
      return `${cc?.name} (${cc?.manager.name})`;
    };

    const handelUpdateInvoice = (updatedInvoice: any): void => {
      apiUpdateProjectInvoice(invoice.value.project.id, invoice.value.id, updatedInvoice)
        .then(({ data }: any) => (invoice.value = data.data))
        .then(() => $message?.success("Invoice updated successfully!", 3))
        .catch(() => $message?.error("Couldn't update invoice!", 3));
    };

    const handleDestroyInvoice = (): void => {
      deleteInvoiceModalVisible.value = false;
      apiDestroyProjectInvoice(invoice.value.project.id, invoice.value.id)
        .then(() => (invoice.value = null))
        .then(() => {
          $message?.success("Invoice removed successfully!", 3);
          $router?.push({
            name: "project",
            params: { projectId: invoice.value.project.id },
          });
        })
        .catch(() => $message?.error("Couldn't remove invoice!", 3));
    };

    const handleSendToFortnox = (payload: any): void => {
      apiSendInvoiceToFortnox(invoice.value.project.id, invoice.value.id, {
        fortnox_invoice: payload,
      })
        .then((res: any) => (fortnox_id.value = res.fortnox_id))
        .then(() => $message?.success("Invoice sent to FORTNOX successfully!", 3))
        .catch(() => $message?.error("Couldn't send invoice to FORTNOX!", 3));
    };

    return {
      invoice,
      fortnox_id,
      sendToFortnoxModalVisible,
      editInvoiceModalVisible,
      deleteInvoiceModalVisible,
      toMoney,
      outerInvoiceRolesColumns,
      innerInvoiceRolesColumns,
      expenseReportsColumns,
      getCostCenterDataById,
      invoiceRequestItemsTableColumns,
      handleSendToFortnox,
      handelUpdateInvoice,
      handleDestroyInvoice,
    };
  },
});
</script>

<style lang="scss" scoped>
.roles-nested-table .ant-table-expanded-row > td:last-child {
  padding: 0 48px 0 8px;
}

.roles-nested-table .ant-table-expanded-row > td:last-child .ant-table-thead th {
  border-bottom: 1px solid #e9e9e9;
}

.roles-nested-table .ant-table-expanded-row > td:last-child .ant-table-thead th:first-child {
  padding-left: 0;
}

.roles-nested-table .ant-table-expanded-row > td:last-child .ant-table-row td:first-child {
  padding-left: 0;
}

.roles-nested-table .ant-table-expanded-row .ant-table-row:last-child td {
  border: none;
}

.roles-nested-table .ant-table-expanded-row .ant-table-thead > tr > th {
  background: none;
}

.roles-nested-table .table-operation a:not(:last-child) {
  margin-right: 24px;
}

.roles-nested-table .ant-table-expanded-row:hover > td {
  background: #fbfbfb;
}
</style>
