<template>
  <div>
    <a-form layout="horizontal" :data-vv-scope="ReportType.SICK">
      <a-form-item
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
        :validate-status="$validator.errors.first('sick-report.start_date') ? 'error' : ''"
        :help="$validator.errors.first('sick-report.start_date')"
        label="Date"
      >
        <a-date-picker
          v-validate="'required'"
          :value="parseDate(newReport.start_date)"
          class="w-100"
          data-vv-name="start_date"
          data-vv-as="date"
          @change="(d) => onDatesChange(d, 'start_date')"
        />
      </a-form-item>

      <a-form-item :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }" label="VAB">
        <a-checkbox :checked="newReport.vab" @change="newReport.vab = !newReport.vab" />
      </a-form-item>

      <a-form-item :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }" label="Comment">
        <a-textarea v-model="newReport.comment" :auto-size="{ minRows: 2 }" />
      </a-form-item>
    </a-form>

    <div class="d-flex justify-content-center mt-4">
      <a-button type="primary" style="width: 50%" @click="handleStoreReport()">Submit</a-button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { getCurrentInstance, ref, onMounted, onUnmounted, toRefs } from "vue";
import { ProfileInterface } from "@/modules/authentication/types";
import { ReportPayloadInterface, ReportType } from "../types";
import useMixin from "@/useMixin";
import moment from "@/date";
import useFilters from "@/useFilters";
import { emitter } from "@/mitt";

// Props
const props = defineProps({
  user: { type: Object as () => ProfileInterface, default: undefined },
  selectedDay: { type: String, default: undefined },
});

// Emits
const emits = defineEmits(["day-selected", "store-report"]);

// Mixins
const { setObject } = useMixin();

// Filters
const { parseDate } = useFilters();

// Instances
const instance = getCurrentInstance();
const $validator = instance?.proxy.$validator;
const $confirm = instance?.proxy?.$confirm;

// Data Properties
const { user, selectedDay } = toRefs(props);
const newReport = ref<ReportPayloadInterface>({
  user_id: user.value.id,
  start_date: moment(selectedDay.value).format("YYYY-MM-DD"),
  comment: undefined,
  vab: undefined,
  type: ReportType.SICK,
});

// Life-cycle Hooks
onMounted(() => {
  emitter.on("show-sick-overlap-warning", (data) =>
    showConfirmSickModal(
      (data as { report: ReportPayloadInterface; warning: string })
        .report as ReportPayloadInterface,
      (data as { report: ReportPayloadInterface; warning: string }).warning as string
    )
  );
  emitter.on("selected-date", (date) => handleSelectedDate(date));
});

onUnmounted(() => {
  emitter.off("show-sick-overlap-warning", (data) =>
    showConfirmSickModal(
      (data as { report: ReportPayloadInterface; warning: string })
        .report as ReportPayloadInterface,
      (data as { report: ReportPayloadInterface; warning: string }).warning as string
    )
  );
  emitter.off("selected-date", (date) => handleSelectedDate(date));
});
// Component Methods
const handleSelectedDate = (date: any): void => {
  newReport.value.start_date = moment(date).format("YYYY-MM-DD");
};

const onDatesChange = (date: any, attribute: string): void => {
  newReport.value[attribute] = date ? date.format("YYYY-MM-DD") : undefined;

  if (attribute === "start_date") {
    emits("day-selected", newReport.value[attribute]);
  }
};

const handleStoreReport = (): void => {
  $validator?.validateAll(ReportType.SICK).then((result) => {
    if (result) {
      emitReportAndClearFields();
    }
  });
};

const emitReportAndClearFields = (): void => {
  emits("store-report", newReport.value);
  const previous_date = newReport.value.start_date;

  newReport.value = setObject(newReport.value, undefined);
  newReport.value.user_id = props.user?.id;
  newReport.value.type = ReportType.SICK;
  newReport.value.start_date = previous_date;
};

const showConfirmSickModal = (report: ReportPayloadInterface, warning: string) => {
  newReport.value = setObject(newReport.value, report);
  $confirm?.({
    title: "Creating a sick report on a vacation day",
    content: warning,
    okText: "Continue",
    onOk() {
      newReport.value.suppress_warnings = true;
      emitReportAndClearFields();
    },
  });
};
</script>
