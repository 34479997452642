import { TableColumnInterface } from "@/common/types";

const userColumn: TableColumnInterface = {
  title: "User",
  dataIndex: "user.name",
  key: "user",
  scopedSlots: { customRender: "user" },
};

const amountColumn: TableColumnInterface = {
  title: "Amount",
  dataIndex: "amount",
  key: "amount",
  scopedSlots: { customRender: "amount" },
};

const statusColumn: TableColumnInterface = {
  title: "Status",
  dataIndex: "status",
  key: "status",
  scopedSlots: { customRender: "status" },
};

const createdColumn: TableColumnInterface = {
  title: "Made at",
  key: "created_at",
  dataIndex: "created_at",
  sorter: true,
};

const paidOutAtColumn: TableColumnInterface = {
  title: "Paid out",
  key: "paid_out_at",
  dataIndex: "paid_out_at",
  sorter: true,
};

const actionsColumn: TableColumnInterface = {
  title: "Actions",
  key: "actions",
  scopedSlots: { customRender: "actions" },
};

export const receiptsColumns: Array<TableColumnInterface> = [
  userColumn,
  amountColumn,
  statusColumn,
  createdColumn,
  paidOutAtColumn,
  actionsColumn,
];

export const userReceiptsColumns: Array<TableColumnInterface> = [
  userColumn,
  amountColumn,
  statusColumn,
  createdColumn,
  paidOutAtColumn,
  actionsColumn,
];
