import { SkillInterface } from "@/modules/consultant_profiles/types";
import { SkillCvInterface } from "../types";

export const sortByCustomSortOrder = <A, B>(
  toBeSorted: Array<A>,
  sortOrder: Array<B>,
  toBeSortedIndex: (obj: A) => number,
  sortOrderIndex: (obj: B) => number
): Array<A> => {
  return toBeSorted.sort((a, b) => {
    const aIndex = sortOrder.findIndex((obj: B) => sortOrderIndex(obj) === toBeSortedIndex(a));
    const bIndex = sortOrder.findIndex((obj: B) => sortOrderIndex(obj) === toBeSortedIndex(b));

    if (aIndex === -1 && bIndex === -1) return 0;
    if (aIndex === -1) return 1;
    if (bIndex === -1) return -1;

    return aIndex - bIndex;
  });
};

export const sortSkillsByCustomSortOrder = (
  toBeSorted: Array<SkillInterface>,
  sortOrder: Array<SkillCvInterface>,
  toBeSortedIndex: (obj: SkillInterface) => number,
  sortOrderIndex: (obj: SkillCvInterface) => number
): Array<SkillInterface> => {
  return toBeSorted.sort((a, b) => {
    const aIndex = sortOrder.findIndex(
      (obj: SkillCvInterface) => sortOrderIndex(obj) === toBeSortedIndex(a)
    );
    const bIndex = sortOrder.findIndex(
      (obj: SkillCvInterface) => sortOrderIndex(obj) === toBeSortedIndex(b)
    );

    if (aIndex === -1 && bIndex === -1) return 0;
    if (aIndex === -1) return 1;
    if (bIndex === -1) return -1;
    if (sortOrder[aIndex].highlight && !sortOrder[bIndex].highlight) return -1;
    if (!sortOrder[aIndex].highlight && sortOrder[bIndex].highlight) return 1;
    return aIndex - bIndex;
  });
};
