<template>
  <div class="card">
    <a-row type="flex" justify="space-between" align="middle">
      <h2>Knowledge Domains</h2>
      <span
        v-if="acl.isOwner || acl.isAdmin"
        class="action-default"
        @click="openAddKnowledgeDomain"
      >
        <a-icon type="plus" class="mr-2" />Add
      </span>
    </a-row>

    <div v-if="user.knowledge_domains.length > 0">
      <a-list
        :data-source="user.knowledge_domains"
        :split="false"
        item-layout="vertical"
        size="small"
      >
        <template #renderItem="uKd">
          <a-list-item class="d-flex justify-content-between flex-column flex-lg-row pb-0">
            <a-col :span="22">
              <a-row>
                <h3>
                  <span class="text-capitalize">{{ uKd.level }}</span>
                  in
                  {{ uKd.knowledge_domain.title }}
                </h3>
              </a-row>
              <a-row>
                <p>{{ uKd.knowledge_domain[`description_${uKd.level}`] }}</p>
              </a-row>
            </a-col>
            <a-col v-if="acl.isOwner || acl.isAdmin">
              <a-tooltip>
                <template #title> Edit knowledge domain information </template>
                <a-icon
                  type="edit"
                  class="action-default"
                  @click="openEditKnowledgeDomainModal(uKd)"
                />
              </a-tooltip>
              <a-divider type="vertical" />
              <a-tooltip>
                <template #title> Delete knowledge domain </template>
                <a-icon
                  type="delete"
                  class="action-danger"
                  @click="showDeleteKnowledgeDomainModal(uKd)"
                />
              </a-tooltip>
            </a-col>
          </a-list-item>
        </template>
      </a-list>
    </div>

    <add-user-knowledge-domain-modal
      v-if="(acl.isOwner || acl.isAdmin) && knowledgeDomains"
      :user="user"
      :knowledge-domains="knowledgeDomains"
      :visible="addKnowledgeDomainVisible"
      @close="addKnowledgeDomainVisible = false"
      @store-knowledge-domain="handleStoreKnowledgeDomain"
    />

    <edit-user-knowledge-domain-modal
      v-if="acl.isOwner || acl.isAdmin"
      :user-kd="userKd"
      :visible="editKnowledgeDomainVisible"
      @close="editKnowledgeDomainVisible = false"
      @update-knowledge-domain="handleUpdateKnowledgeDomain"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, getCurrentInstance } from "vue";
import { BaseAclInterface, ProfileInterface } from "@/modules/authentication/types";
import { KnowledgeDomainInterface } from "@/modules/consultant_profiles/types";
import EditUserKnowledgeDomainModal from "./EditUserKnowledgeDomainModal.vue";
import AddUserKnowledgeDomainModal from "./AddUserKnowledgeDomainModal.vue";
import { showDeleteExperienceModal } from "../_utils/experience";

// Props
const props = defineProps({
  knowledgeDomains: { type: Array as () => Array<KnowledgeDomainInterface>, default: () => [] },
  user: { type: Object as () => ProfileInterface, default: undefined },
  acl: { type: Object as () => BaseAclInterface, default: undefined },
});

// Emits
const emits = defineEmits([
  "get-knowledge-domains",
  "store-knowledge-domain",
  "update-knowledge-domain",
  "destroy-knowledge-domain",
]);

// Instances
const instance = getCurrentInstance();

// Data Properties
const addKnowledgeDomainVisible = ref<boolean>(false);
const editKnowledgeDomainVisible = ref<boolean>(false);
const userKd = ref<any>(null);

// Component methods
const openAddKnowledgeDomain = (): void => {
  emits("get-knowledge-domains");
  addKnowledgeDomainVisible.value = true;
};

const openEditKnowledgeDomainModal = (uKd: any): void => {
  userKd.value = uKd;
  editKnowledgeDomainVisible.value = true;
};

const handleStoreKnowledgeDomain = (newKnowledgeDomain: any): void => {
  addKnowledgeDomainVisible.value = false;
  emits("store-knowledge-domain", newKnowledgeDomain);
};

const handleUpdateKnowledgeDomain = (updatedKnowledgeDomain: any): void => {
  editKnowledgeDomainVisible.value = false;
  emits("update-knowledge-domain", updatedKnowledgeDomain);
};

const showDeleteKnowledgeDomainModal = (userKd: any): void => {
  showDeleteExperienceModal(instance?.proxy.$confirm, userKd, "knowledge domain", () => {
    emits("destroy-knowledge-domain", userKd.id);
  });
};
</script>
