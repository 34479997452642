import {
  addDays,
  computeDayDifference,
  getDayDateRange,
  getDayDateRangeV2,
  isWeekendDay,
  isWeekendDayV2,
} from "@/utils";
import { PublicHolidayInterface } from "../types";
import { Moment } from "moment";
import moment from "@/date";

export function mergePublicHolidays(
  publicHolidays: Array<PublicHolidayInterface>
): Array<PublicHolidayInterface> {
  return publicHolidays
    .map((ph: PublicHolidayInterface) => {
      return { ...ph, duration: 1 };
    })
    .reduce((acc: Array<PublicHolidayInterface>, ph: PublicHolidayInterface, index: number) => {
      if (acc.length > 0) {
        const prevPh: PublicHolidayInterface = acc.slice(-1)[0];
        const prevPhCurrentEndDate = computePublicHolidayEndDate(prevPh);
        const dayDifference = computeDayDifference(prevPhCurrentEndDate, new Date(ph.date));

        if (dayDifference === 1) {
          return [...acc.slice(0, -1), { ...prevPh, duration: prevPh.duration + 1 }];
        }
      }
      return [...acc, ph];
    }, []);
}

export function computePublicHolidayEndDate(ph: PublicHolidayInterface): Date {
  if (ph.duration < 1) {
    return new Date(ph.date);
  }
  return addDays(new Date(ph.date), ph.duration - 1);
}

export function computePublicHolidayEndDateV2(ph: PublicHolidayInterface): Moment {
  const date = moment(ph.date);
  if (ph.duration < 1) {
    return date;
  }
  date.add(ph.duration - 1, "days");
  return date;
}

export function computeNbBusinessDays(
  start: Date,
  end: Date,
  publicHolidays: Array<PublicHolidayInterface>
): number {
  const publicHolidayDateTimes = publicHolidays
    .flatMap((ph: PublicHolidayInterface) =>
      getDayDateRange(new Date(ph.date), computePublicHolidayEndDate(ph))
    )
    .map((d: Date) => d.getTime());

  start = new Date(start);
  end = new Date(end);

  const nbDays = computeDayDifference(start, end) + 1;
  const nbNonBusinessDays = getDayDateRange(start, end).filter(
    (d) => isWeekendDay(d) || publicHolidayDateTimes.includes(d.getTime())
  ).length;

  return nbDays - nbNonBusinessDays;
}

export function computeNbBusinessDaysV2(
  start: string | Moment,
  end: string | Moment,
  publicHolidays: Array<PublicHolidayInterface>
): number {
  const publicHolidayDateTimes = publicHolidays
    .flatMap((ph: PublicHolidayInterface) =>
      getDayDateRangeV2(moment(ph.date), computePublicHolidayEndDateV2(ph))
    )
    .map((d: Moment) => d.format("YYYY-MM-DD"));

  let startMoment = moment(start);
  let endMoment = moment(end);

  const nbDays = endMoment.diff(startMoment, "days") + 1;
  const nbNonBusinessDays = getDayDateRangeV2(startMoment, endMoment).filter(
    (d) => isWeekendDayV2(d) || publicHolidayDateTimes.includes(d.format("YYYY-MM-DD"))
  ).length;

  return nbDays - nbNonBusinessDays;
}
