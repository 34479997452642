<template>
  <a-layout-content class="my-4 mx-3">
    <div class="container">
      <!-- HEADER -->
      <div class="d-flex justify-content-between">
        <h2>Clients</h2>
        <div class="d-flex">
          <!-- BUTTONS CSV|CLEAR|TOGGLE-FILTER|ADD| -->
          <a-button
            :loading="downloadingCsv"
            class="mr-2"
            icon="download"
            type="default"
            @click="() => handleDownloadCsv()"
          />
          <portal-target name="clients-filter-buttons"></portal-target>
          <a-button
            v-if="baseAcl.isAdmin || baseAcl.isFinancial || baseAcl.isCr"
            class="ml-2"
            type="primary"
            icon="plus"
            @click="storeClientModalVisible = true"
          />
        </div>
      </div>

      <clients-filter
        :profile="profile"
        :filters="filtersRef"
        :number-of-results="clientsPagination?.total"
        @apply-filters="(filters) => applyFilters(filters)"
      />

      <clients-table
        :clients-page="clientsPage"
        :current-page="currentPage"
        :clients-pagination="clientsPagination"
        :loading-table="loadingTable"
        :page-size="pageSizeRef"
        @apply-sorting="(newSorting) => applySorting(newSorting)"
        @get-clients-page="(params) => getClientsPage(params)"
      />

      <!-- CREATE NEW CLIENT MODAL -->
      <add-client-modal
        :users="usersCompactList"
        :visible="storeClientModalVisible"
        @close="storeClientModalVisible = false"
        @get-users="() => handleGetUsersCompactList()"
        @store-client="(newClient) => handleStoreClient(newClient)"
      />
    </div>
  </a-layout-content>
</template>

<script setup lang="ts">
import { ref, computed, getCurrentInstance, onBeforeMount } from "vue";
import { BaseAclInterface, ProfileInterface } from "@/modules/authentication/types";
import { useAuthenticationStore } from "@/modules/authentication/_store";
import AddClientModal from "../_components/AddClientModal.vue";
import { apiGetClients, apiStoreClient } from "../_utils/api";
import { MinimalUserInterface } from "@/modules/users/types";
import ClientsFilter from "../_components/ClientsFilter.vue";
import ClientsTable from "../_components/ClientsTable.vue";
import { PaginationInterface } from "@/common/types";
import { getUsersCompactList } from "@/api";
import { ClientInterface } from "../types";
import { downloadCsv } from "@/utils";
import useMixin from "@/useMixin";

// Pinia
const authenticationStore = useAuthenticationStore();
const profile = computed<ProfileInterface | undefined>(() => authenticationStore.profile);
const baseAcl = computed<BaseAclInterface>(() => authenticationStore.baseAcl);

// Mixins
const { qsDecode, syncRouteParams, persistTablePageSize, loadTablePageSize } = useMixin();

// Data properties
const clientsPagination = ref<PaginationInterface | undefined>(undefined);
const usersCompactList = ref<Array<MinimalUserInterface>>([]);
const storeClientModalVisible = ref<boolean>(false);
const clientsPage = ref<Array<ClientInterface>>([]);
const downloadingCsv = ref<boolean>(false);
const loadingTable = ref<boolean>(false);
const currentPage = ref<number>(1);
const pageSizeRef = ref<number>(25);
const filtersRef = ref<object>({});
const sortingRef = ref<object>({});

// Instances
const instance = getCurrentInstance();
const $route = instance?.proxy.$route;
const $message = instance?.proxy.$message;

// Lifecycle hooks
onBeforeMount(() => {
  getClientsPagefromURL();
});

const getClientsPagefromURL = (url: any = $route?.query): void => {
  const { page, filters, sorting, pageSize } = qsDecode(url);

  pageSizeRef.value = loadTablePageSize();

  if (page) currentPage.value = parseInt(page);
  if (filters) filtersRef.value = filters;
  if (sorting) sortingRef.value = sorting;
  if (pageSize) pageSizeRef.value = parseInt(pageSize);

  getClientsPage({ page: currentPage.value });
};

const getClientsPage = ({
  page,
  pageSize = pageSizeRef.value,
  filters = filtersRef.value,
  sorting = sortingRef.value,
}: {
  page: number;
  pageSize?: number;
  filters?: object;
  sorting?: object;
}): void => {
  loadingTable.value = true;
  currentPage.value = page;
  pageSizeRef.value = pageSize;

  syncRouteParams(
    {
      page: currentPage.value,
      pageSize: pageSizeRef.value,
      filters: filtersRef.value,
      sorting: sortingRef.value,
    },
    instance
  );
  persistTablePageSize(pageSize);

  apiGetClients({ page, page_size: pageSize, ...filters, ...sorting })
    .then(({ data }: any) => {
      currentPage.value = page;
      clientsPage.value = data.data;
      clientsPagination.value = data.meta;
    })
    .then(() => (loadingTable.value = false));
};

const handleStoreClient = (newClient: any): void => {
  apiStoreClient(newClient)
    .then(() => getClientsPage({ page: currentPage.value }))
    .then(() => $message?.success("Client added successfully!", 3))
    .catch((err: any) => $message?.error("Couldn't add client!", 3));
};

const applyFilters = (filters: object): void => {
  filtersRef.value = filters;
  currentPage.value = 1;
  syncRouteParams(
    {
      page: currentPage.value,
      pageSize: pageSizeRef.value,
      filters: filtersRef.value,
      sorting: sortingRef.value,
    },
    instance
  );
  getClientsPage({ page: currentPage.value });
};

const applySorting = (sorting: object): void => {
  sortingRef.value = sorting;
  syncRouteParams(
    {
      page: currentPage.value,
      pageSize: pageSizeRef.value,
      filters: filtersRef.value,
      sorting: sortingRef.value,
    },
    instance
  );
  getClientsPage({ page: currentPage.value });
};

const handleDownloadCsv = (): void => {
  downloadingCsv.value = true;

  apiGetClients(filtersRef.value, { Accept: "text/csv" })
    .then((res: any) => downloadCsv(res.data, "clients", !!Object.keys(filtersRef.value).length))
    .catch((err: any) => $message?.error("Couldn't download CSV file!", 3))
    .finally(() => (downloadingCsv.value = false));
};

const handleGetUsersCompactList = (): void => {
  getUsersCompactList().then((users: any) => (usersCompactList.value = users));
};
</script>
