import { RouteInterface } from "@/common/types";
import Audits from "./_views/Audits.vue";

export default [
  {
    path: "audits",
    component: Audits,
    name: "audits",
    meta: { auth: true },
  },
] as Array<RouteInterface>;
