<template>
  <div v-show="filterPanelVisible" class="card mb-2">
    <a-form layout="vertical">
      <bookmarked-filters-selection
        :bookmarked-filters="bookmarkedFilters"
        :filter-is-empty="filterIsEmpty"
        bookmark-type="Cvs"
        class="ml-3"
        @selected="(bookmarkFilterUrl) => handleCustomFilterSelected(bookmarkFilterUrl)"
        @create-bookmark-filter="handleCreateBookmarkFilter"
        @update-bookmark-filter="handleUpdateBookmarkFilter"
        @delete-bookmark-filter="handleDeleteBookmarkFilter"
      />
      <a-row>
        <a-col :lg="11" class="ml-3">
          <SkNameSelect
            v-model="newFilter.cv_id"
            label="CVs"
            :label-col-size="10"
            mode="multiple"
            placeholder="Select CVs"
            :allow-clear="true"
            :options="cvsCompactList"
            @change="applyFilters"
          />
        </a-col>
      </a-row>
    </a-form>

    <a-form>
      <a-row>
        <a-col :lg="11" class="ml-3">
          <a-form-item label="Users">
            <a-checkbox :checked="newFilter.active" @change="(t) => onCheckboxChange('active', t)"
              >Active</a-checkbox
            >
            <a-checkbox
              :checked="newFilter.inactive"
              @change="(t) => onCheckboxChange('inactive', t)"
              >Inactive</a-checkbox
            >
          </a-form-item>
        </a-col>

        <a-col :lg="11" class="ml-3">
          <a-form-item label="CVs">
            <a-checkbox
              :checked="newFilter.cv_exists"
              @change="(t) => onCheckboxChange('cv_exists', t)"
              >Exists</a-checkbox
            >
            <a-checkbox
              :checked="newFilter.cv_not_exists"
              @change="(t) => onCheckboxChange('cv_not_exists', t)"
              >Not Exists</a-checkbox
            >
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>

    <a-row class="d-flex justify-content-end">
      <span>
        <strong>Results:</strong>
        {{ numberOfResults }}
      </span>
    </a-row>

    <portal to="cvs-filter-buttons">
      <a-button
        v-if="filterPanelVisible"
        class="mr-2"
        icon="close"
        type="danger"
        @click="() => clearFilters()"
      />
      <a-button
        v-show="filterPanelVisible"
        class="mr-2"
        type="primary"
        icon="up"
        @click="() => toggleFilterPanel()"
      />
      <a-button
        v-if="!filterPanelVisible"
        class="mr-2"
        type="primary"
        icon="filter"
        @click="() => toggleFilterPanel()"
      />
    </portal>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, getCurrentInstance } from "vue";
import BookmarkedFiltersSelection from "../../bookmarked_filters/_components/BookmarkedFiltersSelection.vue";
import useMixin from "@/useMixin";
import { getCvsCompactList } from "@/api";
import { MinimalCvInterface, CvsFilterInterface } from "../types";
import {
  apiUpdateUserBookmarkedFilter,
  apiStoreUserBookmarkedFilter,
  apiGetUserBookmarkedFilters,
  apiDestroyUserBookmarkedFilter,
} from "@/modules/bookmarked_filters/_utils/api";
import { BookmarkedFilterInterface } from "@/modules/bookmarked_filters/types";
import { ProfileInterface } from "@/modules/authentication/types";

// Props
const props = defineProps({
  profile: { type: Object as () => ProfileInterface, default: undefined },
  filters: { type: Object as () => CvsFilterInterface, default: undefined },
  numberOfResults: { type: Number, default: 0 },
});

// Emits
const emits = defineEmits(["apply-filters"]);

// Mixins
const { qsDecode, setObject } = useMixin();

// Data Properties
const filterPanelVisible = ref<boolean>(false);
const cvsCompactList = ref<Array<MinimalCvInterface>>([]);

const newFilter = ref<any>({
  cv_id: [],
  active: undefined,
  inactive: undefined,
  cv_exists: undefined,
  cv_not_exists: undefined,
});

const bookmarkedFilters = ref<Array<BookmarkedFilterInterface>>([]);

const filterIsEmpty = computed(() => {
  return (
    newFilter.value.cv_id.length === 0 &&
    newFilter.value.active === undefined &&
    newFilter.value.inactive === undefined &&
    newFilter.value.cv_exists === undefined &&
    newFilter.value.cv_not_exists === undefined
  );
});

watch(filterPanelVisible, (newProp, oldProp) => {
  if (newProp && !oldProp) {
    handleGetCvsCompactList();
    handleGetBookmarkedFilters();
    setFilter(props.filters);
  }
});

// Class Methods
const toggleFilterPanel = (): void => {
  filterPanelVisible.value = !filterPanelVisible.value;
};

const handleGetCvsCompactList = () => {
  getCvsCompactList().then((cvs) => {
    cvsCompactList.value = cvs;
  });
};

const handleCustomFilterSelected = (bookmarkFilterUrl: string): void => {
  handleClearCvsFilter();
  if (bookmarkFilterUrl) {
    emits("apply-filters", qsDecode(bookmarkFilterUrl).filters);
  } else {
    applyFilters();
  }
};

const applyFilters = (): void => {
  emits("apply-filters", filterIsEmpty.value ? {} : newFilter.value);
};

const clearFilters = (): void => {
  handleClearCvsFilter();
  emits("apply-filters", {});
};

const onCheckboxChange = (field: string, event: any): void => {
  newFilter.value[field] = event.target.checked ? true : undefined;
  applyFilters();
};

const handleClearCvsFilter = (): void => {
  newFilter.value = {
    cv_id: [],
    active: undefined,
    inactive: undefined,
    cv_exists: undefined,
    cv_not_exists: undefined,
  };
};

const setFilter = (filter: any): void => {
  if (filter === undefined) {
    return;
  }
  newFilter.value = setObject(newFilter.value, filter);
  if (newFilter.value.active) {
    newFilter.value.active = !!newFilter.value.active; // Cast from string to boolean
  }
  if (newFilter.value.inactive) {
    newFilter.value.inactive = !!newFilter.value.inactive; // Cast from string to boolean
  }
  if (newFilter.value.cv_exists) {
    newFilter.value.cv_exists = !!newFilter.value.cv_exists; // Cast from string to boolean
  }
  if (newFilter.value.cv_not_exists) {
    newFilter.value.cv_not_exists = !!newFilter.value.cv_not_exists; // Cast from string to boolean
  }
};

const handleGetBookmarkedFilters = (): void => {
  apiGetUserBookmarkedFilters(props.profile.id).then(
    ({ data }: any) => (bookmarkedFilters.value = data.data)
  );
};

const instance = getCurrentInstance();
const $message = instance?.proxy.$message;
const handleCreateBookmarkFilter = (bookmarkFilter: any): void => {
  apiStoreUserBookmarkedFilter(props.profile.id, bookmarkFilter)
    .then(({ data }: any) => bookmarkedFilters.value.push(data.data))
    .then(() => $message?.success("New filter created successfully!", 3))
    .catch(() => $message?.error("Couldn't create new filter!", 3));
};

const handleUpdateBookmarkFilter = (bookmarkFilterId: number, bookmarkFilter: any): void => {
  apiUpdateUserBookmarkedFilter(props.profile.id, bookmarkFilterId, bookmarkFilter)
    .then(
      ({ data }: any) =>
        (bookmarkedFilters.value = bookmarkedFilters.value.map((f: any) =>
          f.id === data.data.id ? data.data : f
        ))
    )
    .then(() => $message?.success("Filter updated successfully!", 3))
    .catch(() => $message?.error("Couldn't update filter!", 3));
};

const handleDeleteBookmarkFilter = (bookmarkFilterId: number) => {
  apiDestroyUserBookmarkedFilter(props.profile.id, bookmarkFilterId)
    .then(
      () =>
        (bookmarkedFilters.value = bookmarkedFilters.value.filter(
          (f: any) => f.id !== bookmarkFilterId
        ))
    )
    .then(() => $message?.success("Filter removed successfully!", 3))
    .catch(() => $message?.error("Couldn't remove filter!", 3));
};
</script>
