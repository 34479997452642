<template>
  <a-modal
    :visible="visible"
    :destroy-on-close="true"
    :client="client"
    title="Edit Client"
    ok-text="Update"
    @ok="() => handleUpdateClient()"
    @cancel="$emit('close')"
  >
    <a-form layout="horizontal">
      <SkInput
        v-model="updatedClient.name"
        v-validate="'required|max:255'"
        :error="errors.first('name')"
        data-vv-name="name"
        label="Name"
        placeholder="Client name"
      />

      <SkInput
        v-model="updatedClient.alias"
        v-validate="'required|max:255'"
        :error="errors.first('alias')"
        data-vv-name="alias"
        label="Alias"
        placeholder="Client alias"
      />

      <SkInput
        v-model="updatedClient.fortnox_id"
        v-validate="'max:255'"
        :error="errors.first('fortnox_id')"
        data-vv-name="fortnox_id"
        data-vv-as="Fortnox ID"
        label="Fortnox ID"
        placeholder="Fortnox ID"
      />

      <SkInput
        v-model="updatedClient.registration_number"
        v-validate="'max:255'"
        :error="errors.first('registration_number')"
        data-vv-name="registration_number"
        data-vv-as="registration number"
        label="Registration nr."
        placeholder="Registration number"
      />

      <SkNameSelect
        v-model="updatedClient.account_manager_id"
        label="Account manager"
        placeholder="Select an account manager"
        :options="users"
      />

      <a-form-item :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }" label="Secrecy level">
        <a-checkbox-group
          :default-value="value_compare"
          @change="(states) => onSecrecyChange(states)"
        >
          <a-row>
            <a-col :span="24">
              <a-checkbox value="secrecy_mention_client_spoken"
                >Mention as client, spoken</a-checkbox
              >
            </a-col>

            <a-col :span="24">
              <a-checkbox value="secrecy_client_is_official_written"
                >Official as client, written</a-checkbox
              >
            </a-col>

            <a-col :span="24">
              <a-checkbox value="secrecy_ok_show_client_logo"
                >Show their logo on our website</a-checkbox
              >
            </a-col>
          </a-row>
        </a-checkbox-group>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script setup lang="ts">
import { ref, watch, getCurrentInstance } from "vue";
import { MinimalUserInterface } from "@/modules/users/types";
import { ClientInterface } from "../types";

// Props
const props = defineProps({
  users: { type: Array as () => Array<MinimalUserInterface>, default: () => [] },
  visible: { type: Boolean as () => boolean, default: false },
  client: { type: Object as () => ClientInterface, default: () => ({}) },
});

// Emits
const emits = defineEmits(["get-users", "update-client", "close"]);

// Instances
const instance = getCurrentInstance();
const $validator = instance?.proxy?.$validator;

// Data properties
const updatedClient = ref<any>({
  id: undefined,
  name: undefined,
  alias: undefined,
  fortnox_id: undefined,
  registration_number: undefined,
  account_manager_id: undefined,
  account_manager: undefined,
  secrecy_client_is_official_written: undefined,
  secrecy_mention_client_spoken: undefined,
  secrecy_ok_show_client_logo: undefined,
  projects: undefined, // Needed since otherwise mutation "removes" projects after edit
});

const value_compare = ref<Array<string>>([
  "secrecy_mention_client_spoken",
  "secrecy_client_is_official_written",
  "secrecy_ok_show_client_logo",
]);

// Watchers
watch(
  () => props.visible,
  (newProp: boolean, oldProp: boolean) => {
    if (newProp && !oldProp) {
      updatedClient.value = { ...updatedClient.value, ...props.client };
      updatedClient.value.account_manager_id = props.client?.account_manager?.id || undefined;

      // Set checkbox to stored values
      setInitialChecks();

      // Load users
      emits("get-users");
    }
  }
);

const onSecrecyChange = (list: Array<string>) => {
  // Reset all secrecy level checkboxes
  updatedClient.value.secrecy_client_is_official_written = false;
  updatedClient.value.secrecy_mention_client_spoken = false;
  updatedClient.value.secrecy_ok_show_client_logo = false;

  // Select only checkboxes present in event payload
  list.forEach((el) => (updatedClient.value[el] = true));
};

const setInitialChecks = () => {
  value_compare.value[0] = updatedClient.value.secrecy_mention_client_spoken
    ? "secrecy_mention_client_spoken"
    : "not_checked";

  value_compare.value[1] = updatedClient.value.secrecy_client_is_official_written
    ? "secrecy_client_is_official_written"
    : "not_checked";

  value_compare.value[2] = updatedClient.value.secrecy_ok_show_client_logo
    ? "secrecy_ok_show_client_logo"
    : "not_checked";
};

const handleUpdateClient = (): void => {
  $validator?.validateAll().then((result) => {
    if (result) {
      emits("update-client", updatedClient.value);
      emits("close");
    }
  });
};
</script>
