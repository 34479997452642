<template>
  <a-modal
    :visible="visible"
    :destroy-on-close="true"
    title="Edit Team"
    ok-text="Save"
    @ok="() => handleUpdateTeam()"
    @cancel="$emit('close')"
  >
    <a-form layout="horizontal">
      <SkInput
        v-model="newTeam.name"
        v-validate="'required|max:255'"
        :error="$validator.errors.first('team_name')"
        data-vv-name="team_name"
        data-vv-as="team name"
        label="Name"
        placeholder="Team name"
      />

      <SkNameSelect
        v-model="newTeam.team_leader_id"
        v-validate="'required'"
        :error="$validator.errors.first('team_leader')"
        data-vv-name="team_leader"
        data-vv-as="team leader"
        label="Team leader"
        placeholder="Select a team leader"
        :options="users"
      />

      <SkNameSelect
        v-model="newTeam.user_ids"
        mode="multiple"
        label="Team members"
        placeholder="Select team members"
        :options="users"
      />
    </a-form>
  </a-modal>
</template>

<script setup lang="ts">
import { getCurrentInstance, ref, watch } from "vue";
import { MinimalUserInterface } from "@/modules/users/types";
import { TeamInterface } from "../types";
import useMixin from "@/useMixin";

// Props
const props = defineProps({
  users: { type: Array as () => Array<MinimalUserInterface>, default: () => [] },
  visible: { type: Boolean, default: false },
  team: { type: Object as () => TeamInterface, default: undefined },
});

// Emits
const emits = defineEmits(["get-users", "update-team", "close"]);

// Mixins
const { setObject } = useMixin();

// Instances
const instance = getCurrentInstance();
const $validator = instance?.proxy.$validator;

// Data properties
const newTeam = ref<any>({
  name: undefined,
  team_leader_id: undefined,
  user_ids: undefined,
});

// Watchers
watch(
  () => props.visible,
  (newProp, oldProp) => {
    if (newProp && !oldProp) {
      // Initialize all fields
      newTeam.value = setObject(newTeam.value, props.team);
      newTeam.value.team_leader_id = props.team.leader.id;
      newTeam.value.user_ids = props.team.users.map((u: MinimalUserInterface) => u.id);

      // Load users
      emits("get-users");
    }
  }
);

// Class methods
const handleUpdateTeam = (): void => {
  $validator?.validateAll().then((result) => {
    if (result) {
      emits("update-team", newTeam.value);
      emits("close");
    }
  });
};
</script>
