import axios from "@/network";
import { RoleExtensionInterface } from "@/modules/roles/types";

export function apiGetRoles(params: any, headers: Record<string, string> = {}) {
  const url = "/roles";
  return axios.get(url, { params, headers });
}

export function apiGetRole(roleId: number) {
  const url = `/roles/${roleId}`;
  return axios.get(url);
}

export function apiUpdateRole(projectId: number, roleId: number, updatedRole: any) {
  const url = `/projects/${projectId}/roles/${roleId}`;
  const payload = { role: updatedRole };
  return axios.patch(url, payload);
}

export function apiGetRoleExtensions(params: Record<string, any> = {}): Promise<Object> {
  const url = "/role_extensions";
  return axios.get(url, { params });
}

export function apiGetLatestRoleExtension(role_id: number): Promise<Object> {
  const url = `/roles/${role_id}/extensions/latest`;
  return axios.get(url);
}

export function apiCreateRoleExtension(
  roleId: number,
  newRoleExtension: RoleExtensionInterface
): Promise<Object> {
  const url = `/roles/${roleId}/extensions`;
  return axios.post(url, newRoleExtension);
}

export function apiUpdateRoleExtension(
  updatedRoleExtension: RoleExtensionInterface
): Promise<Object> {
  const url = `/role_extensions/${updatedRoleExtension.id}`;
  return axios.patch(url, updatedRoleExtension);
}

export function apiApplyRoleExtension(roleExtension: RoleExtensionInterface): Promise<Object> {
  const url = `/role_extensions/${roleExtension.id}/apply`;
  return axios.post(url);
}

export function apiDeleteRoleExtension(roleExtension: RoleExtensionInterface): Promise<Object> {
  const url = `/role_extensions/${roleExtension.id}`;
  return axios.delete(url);
}
