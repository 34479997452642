<template>
  <div class="card">
    <div class="d-flex mb-3 justify-content-between align-items-center">
      <h2 class="m-0">Merits</h2>
      <span
        v-if="acl.isOwner || acl.isAdmin"
        class="action-default d-flex justify-content-end align-items-center"
        @click="openAddUserMeritModal"
      >
        <a-icon type="plus" class="mr-2" />Add
      </span>
    </div>

    <!-- LIST OF MERITS -->
    <div v-if="merits.length > 0">
      <a-list :data-source="merits" :split="false" item-layout="vertical" size="small">
        <template #renderItem="merit">
          <a-list-item>
            <div class="mb-3">
              <span class="d-flex justify-content-between">
                <h3 class="m-0">{{ merit.title }}</h3>
                <div v-if="acl.isOwner || acl.isAdmin">
                  <a-tooltip>
                    <template #title> Edit merit information </template>
                    <a-icon
                      type="edit"
                      class="action-default"
                      @click="openEditUserMeritModal(merit)"
                    />
                  </a-tooltip>
                  <a-divider type="vertical" />
                  <a-tooltip>
                    <template #title> Delete merit </template>
                    <a-icon
                      type="delete"
                      class="action-danger"
                      @click="showDeleteMeritModal(merit)"
                    />
                  </a-tooltip>
                </div>
              </span>
              <p v-if="merit.description">{{ merit.description }}</p>
            </div>
          </a-list-item>
        </template>
      </a-list>
    </div>

    <!-- ADD MERITS MODAL -->
    <a-modal
      v-model="addUserMeritModalVisible"
      :destroy-on-close="true"
      title="Add Merit"
      ok-text="Save"
      @ok="handleStoreMerit"
    >
      <a-form layout="horizontal" data-vv-scope="add-merit">
        <a-form-item
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
          :validate-status="$validator.errors.first('add-merit.title') ? 'error' : ''"
          :help="$validator.errors.first('add-merit.title')"
          label="Title"
        >
          <a-input
            v-model="newMerit.title"
            v-validate="'required|max:255'"
            placeholder="Title of the merit"
            data-vv-name="title"
          />
        </a-form-item>

        <a-form-item
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
          :validate-status="$validator.errors.first('add-merit.description') ? 'error' : ''"
          :help="$validator.errors.first('add-merit.description')"
          label="Description"
        >
          <a-textarea
            v-model="newMerit.description"
            v-validate="'required|max:1024'"
            :rows="2"
            placeholder="Merit description"
            data-vv-name="description"
          />
        </a-form-item>
      </a-form>
    </a-modal>

    <!-- EDIT MERITS MODAL -->
    <a-modal
      v-model="editUserMeritModalVisible"
      :destroy-on-close="true"
      title="Edit Merit"
      ok-text="Save"
      @ok="handleUpdateMerit"
    >
      <a-form layout="horizontal" data-vv-scope="edit-merit">
        <a-form-item
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
          :validate-status="$validator.errors.first('edit-merit.title') ? 'error' : ''"
          :help="$validator.errors.first('edit-merit.title')"
          label="Title"
        >
          <a-input
            v-model="newMerit.title"
            v-validate="'required|max:255'"
            placeholder="Title of the merit"
            data-vv-name="title"
          />
        </a-form-item>

        <a-form-item
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
          :validate-status="$validator.errors.first('edit-merit.description') ? 'error' : ''"
          :help="$validator.errors.first('edit-merit.description')"
          label="Description"
        >
          <a-textarea
            v-model="newMerit.description"
            v-validate="'required|max:1024'"
            :rows="2"
            placeholder="Merit description"
            data-vv-name="description"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script setup lang="ts">
import { ref, getCurrentInstance } from "vue";
import { ProfileInterface, BaseAclInterface } from "@/modules/authentication/types";
import { showDeleteExperienceModal } from "../_utils/experience";
import useMixin from "@/useMixin";

// Props
const props = defineProps({
  user: { type: Object as () => ProfileInterface, default: undefined },
  acl: { type: Object as () => BaseAclInterface, default: undefined },
  merits: { type: Array as () => Array<any>, default: () => [] },
});

// Emits
const emits = defineEmits(["store-merit", "update-merit", "destroy-merit"]);

// Mixins
const { setObject } = useMixin();

// Instances
const instance = getCurrentInstance();
const $validator = instance?.proxy.$validator;

// Data Properties
const newMerit = ref<any>({
  id: undefined,
  title: undefined,
  description: undefined,
});
const addUserMeritModalVisible = ref<boolean>(false);
const editUserMeritModalVisible = ref<boolean>(false);

// Component methods
const openAddUserMeritModal = (): void => {
  $validator?.errors.clear();
  newMerit.value = setObject(newMerit.value, undefined);
  addUserMeritModalVisible.value = true;
};

const handleStoreMerit = (): void => {
  $validator?.validateAll("add-merit").then((result) => {
    if (result) {
      emits("store-merit", newMerit.value);
      addUserMeritModalVisible.value = false;
    }
  });
};

const openEditUserMeritModal = (merit: any): void => {
  $validator?.errors.clear();
  newMerit.value = setObject(newMerit.value, merit);
  editUserMeritModalVisible.value = true;
};

const handleUpdateMerit = (): void => {
  $validator?.validateAll("edit-merit").then((result) => {
    if (result) {
      emits("update-merit", newMerit.value);
      editUserMeritModalVisible.value = false;
    }
  });
};

const showDeleteMeritModal = (merit: any): void => {
  showDeleteExperienceModal(instance?.proxy.$confirm, merit, "merit", () => {
    emits("destroy-merit", merit.id);
  });
};
</script>
