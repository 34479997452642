<template>
  <div>
    <div v-if="filterPanelVisible" class="card mb-2">
      <a-form layout="vertical">
        <bookmarked-filters-selection
          :bookmarked-filters="bookmarkedFilters"
          :filter-is-empty="filterIsEmpty"
          bookmark-type="WeekSchedule"
          class="ml-3"
          @selected="(bookmarkFilterUrl) => handleCustomFilterSelected(bookmarkFilterUrl)"
          @create-bookmark-filter="handleCreateBookmarkFilter"
          @update-bookmark-filter="handleUpdateBookmarkFilter"
          @delete-bookmark-filter="handleDeleteBookmarkFilter"
        />
        <a-row>
          <a-col :lg="11" class="ml-3">
            <SkNameSelect
              v-model="newFilter.user_id"
              label="Users"
              :label-col-size="10"
              mode="multiple"
              placeholder="Select users"
              :allow-clear="true"
              :options="usersCompactList"
              @change="applyFilters"
            />
          </a-col>

          <a-col :lg="11">
            <a-form-item
              :label-col="{ span: 10 }"
              :wrapper-col="{ span: 16 }"
              label="Employment type"
            >
              <a-select
                v-model="newFilter.employment_type"
                mode="multiple"
                show-search
                placeholder="Search employment types"
                option-filter-prop="children"
                @change="applyFilters"
              >
                <a-select-option
                  v-for="(name, key) in {
                    fixed: 'Fixed salary',
                    variable: 'Variable salary',
                    hourly: 'Hourly salary',
                    subcontractor: 'Subcontractor',
                  }"
                  :key="key"
                >
                  {{ name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row>
          <a-col :lg="11" class="ml-3">
            <a-form-item :label-col="{ span: 10 }" :wrapper-col="{ span: 16 }">
              <template #label>
                <div>
                  <span class="mr-2">Minimum workload</span>
                  <a-tooltip>
                    <template #title>
                      The minimum workload for a role to be considered a main role
                    </template>
                    <a-icon type="question-circle" />
                  </a-tooltip>
                </div>
              </template>
              <a-input
                v-model="newFilter.min_workload"
                type="number"
                placeholder="Minimum workload"
                addon-after="%"
                @change="applyFilters"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="11">
            <a-form-item :label-col="{ span: 10 }" :wrapper-col="{ span: 16 }" class="mb-2">
              <template #label>
                <div>
                  <span class="mr-2">Extension status</span>
                  <a-tooltip>
                    <template #title>
                      When selecting multiple statuses, the filter is applied by <em>union</em>, not
                      <em>intersection</em>
                    </template>
                    <a-icon type="question-circle" />
                  </a-tooltip>
                </div>
              </template>
              <a-select
                v-model="newFilter.extension_statuses"
                mode="multiple"
                allow-clear
                placeholder="Select extension status"
                class="mb-2"
                @change="() => applyFilters()"
              >
                <a-select-option value="waiting">
                  <a-tag
                    :color="extensionStatusColor[ExtensionStatus.WAITING]"
                    class="default-cursor"
                  >
                    Waiting
                  </a-tag>
                </a-select-option>
                <a-select-option value="to_be_discussed">
                  <a-tag
                    :color="extensionStatusColor[ExtensionStatus.TO_BE_DISCUSSED]"
                    class="default-cursor"
                  >
                    To be discussed
                  </a-tag>
                </a-select-option>
                <a-select-option value="approved">
                  <a-tag
                    :color="extensionStatusColor[ExtensionStatus.APPROVED]"
                    class="default-cursor"
                  >
                    Approved
                  </a-tag>
                </a-select-option>
              </a-select>

              <SkNameSelect
                v-model="newFilter.responsible_cr_user_id"
                :wrapper-col-size="24"
                mode="multiple"
                placeholder="Select responsible CR user"
                :allow-clear="true"
                :options="responsibleCRUsersCompactList"
                :disabled="newFilter.extension_statuses?.length == 0"
                @change="applyFilters"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>

      <a-row class="d-flex justify-content-end">
        <span>
          <strong>Results:</strong>
          {{ numberOfResults }}
        </span>
      </a-row>
    </div>
    <portal to="week-schedule-filter-buttons">
      <a-button
        v-if="filterPanelVisible"
        class="mr-2"
        icon="close"
        type="danger"
        @click="() => clearFilters()"
      />
      <a-button
        v-show="filterPanelVisible"
        class="mr-2"
        type="primary"
        icon="up"
        @click="() => toggleFilterPanel()"
      />
      <a-button
        v-if="!filterPanelVisible"
        class="mr-2"
        type="primary"
        icon="filter"
        @click="() => toggleFilterPanel()"
      />
    </portal>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, getCurrentInstance } from "vue";
import BookmarkedFiltersSelection from "../../bookmarked_filters/_components/BookmarkedFiltersSelection.vue";
import { ExtensionStatus, extensionStatusColor } from "@/modules/roles/_utils/role-extension";
import { BookmarkedFilterInterface } from "@/modules/bookmarked_filters/types";
import { ProfileInterface } from "../../authentication/types";
import { MinimalUserInterface } from "../../users/types";
import { WeekScheduleFilterInterface } from "../types";
import { getUsersCompactList } from "@/api";
import useMixin from "@/useMixin";
import {
  apiUpdateUserBookmarkedFilter,
  apiStoreUserBookmarkedFilter,
  apiDestroyUserBookmarkedFilter,
} from "@/modules/bookmarked_filters/_utils/api";

// Props
const props = defineProps({
  filters: { type: Object as () => WeekScheduleFilterInterface, default: undefined },
  profile: { type: Object as () => ProfileInterface, default: undefined },
  numberOfResults: { type: Number, default: 0 },
});

// Emits
const emits = defineEmits(["apply-filters"]);

// Mixins
const { setObject, qsDecode } = useMixin();

// Instances
const instance = getCurrentInstance();
const $message = instance?.proxy.$message;

// Data properties
const responsibleCRUsersCompactList = ref<Array<MinimalUserInterface>>([]);
const bookmarkedFilters = ref<Array<BookmarkedFilterInterface>>([]);
const usersCompactList = ref<Array<MinimalUserInterface>>([]);
const filterPanelVisible = ref<boolean>(false);
const newFilter = ref<WeekScheduleFilterInterface>({
  user_id: [],
  min_workload: undefined,
  employment_type: [],
  extension_statuses: [],
  responsible_cr_user_id: undefined,
});

// Computed properties
const filterIsEmpty = computed((): boolean => {
  return Boolean(
    newFilter.value.user_id?.length == 0 &&
      newFilter.value.min_workload == null &&
      newFilter.value.employment_type?.length == 0 &&
      newFilter.value.extension_statuses?.length == 0 &&
      newFilter.value.responsible_cr_user_id == undefined
  );
});

// Watchers
watch(filterPanelVisible, (newProp, oldProp) => {
  if (newProp && !oldProp) {
    handleGetUsers();
    handleGetCRUsersCompactList();
    setFilter(props.filters);
  }
});

// Component methods
const toggleFilterPanel = (): void => {
  filterPanelVisible.value = !filterPanelVisible.value;
};

const handleCustomFilterSelected = (bookmarkFilterUrl: string): void => {
  handleClearScheduleFilter();
  bookmarkFilterUrl ? emits("apply-filters", qsDecode(bookmarkFilterUrl).filters) : applyFilters();
};

const handleGetUsers = (): void => {
  getUsersCompactList().then((users) => {
    usersCompactList.value = users;
  });
};

const handleGetCRUsersCompactList = (): void => {
  getUsersCompactList({ only_cr_users: true }).then((crUsers) => {
    responsibleCRUsersCompactList.value = crUsers;
  });
};

const applyFilters = (): void => {
  if ((newFilter.value.extension_statuses ?? []).length == 0)
    newFilter.value.responsible_cr_user_id = undefined;

  emits("apply-filters", filterIsEmpty.value ? {} : newFilter.value);
};

const clearFilters = (): void => {
  handleClearScheduleFilter();
  emits("apply-filters", {});
};

const handleClearScheduleFilter = (): void => {
  newFilter.value = {
    user_id: [],
    min_workload: undefined,
    employment_type: [],
    extension_statuses: [],
    responsible_cr_user_id: undefined,
  };
};

const setFilter = (filter: any): void => {
  if (filter === undefined) return;
  newFilter.value = setObject(newFilter.value, filter);
};

const handleCreateBookmarkFilter = (bookmarkFilter: any): void => {
  apiStoreUserBookmarkedFilter(props.profile.id, bookmarkFilter)
    .then(({ data }: any) => bookmarkedFilters.value.push(data.data))
    .then(() => $message?.success("New filter created successfully!", 3))
    .catch(() => $message?.error("Couldn't create new filter!", 3));
};

const handleUpdateBookmarkFilter = (bookmarkFilterId: number, bookmarkFilter: any): void => {
  apiUpdateUserBookmarkedFilter(props.profile.id, bookmarkFilterId, bookmarkFilter)
    .then(
      ({ data }: any) =>
        (bookmarkedFilters.value = bookmarkedFilters.value.map((f: any) =>
          f.id === data.data.id ? data.data : f
        ))
    )
    .then(() => $message?.success("Filter updated successfully!", 3))
    .catch(() => $message?.error("Couldn't update filter!", 3));
};

const handleDeleteBookmarkFilter = (bookmarkFilterId: number) => {
  apiDestroyUserBookmarkedFilter(props.profile.id, bookmarkFilterId)
    .then(
      () =>
        (bookmarkedFilters.value = bookmarkedFilters.value.filter(
          (f: any) => f.id !== bookmarkFilterId
        ))
    )
    .then(() => $message?.success("Filter removed successfully!", 3))
    .catch(() => $message?.error("Couldn't remove filter!", 3));
};
</script>
