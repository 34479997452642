<template>
  <a-modal
    :visible="visible"
    :destroy-on-close="true"
    title="Add Link"
    ok-text="Add"
    @ok="() => handleStoreLink()"
    @cancel="() => $emit('close')"
  >
    <a-form>
      <SkInput
        v-model="newLink.title"
        v-validate="'required|max:255'"
        :error="$validator.errors.first('title')"
        data-vv-name="title"
        label="Title"
        placeholder="Link title"
      />

      <SkInput
        v-model="newLink.link"
        v-validate="'required|url:require_protocol'"
        :error="$validator.errors.first('link')"
        data-vv-name="link"
        label="Full URL"
        placeholder="Link URL"
      />
    </a-form>
  </a-modal>
</template>

<script setup lang="ts">
import { getCurrentInstance, ref, watch } from "vue";
import useMixin from "@/useMixin";

// Props
const props = defineProps({
  visible: { type: Boolean, default: false },
});

// Emits
const emits = defineEmits(["store-link", "close"]);

// Mixins
const { setObject } = useMixin();

// Instances
const instance = getCurrentInstance();
const $validator = instance?.proxy.$validator;

// Data properties
const newLink = ref<any>({
  title: undefined,
  link: undefined,
});

// Watchers
watch(
  () => props.visible,
  (newProp, oldProp) => {
    if (newProp && !oldProp) {
      newLink.value = setObject(newLink.value, undefined);
    }
  }
);

// Component methods
const handleStoreLink = (): void => {
  $validator?.validateAll().then((result) => {
    if (result) {
      emits("store-link", newLink.value);
      emits("close");
    }
  });
};
</script>
