<template>
  <a-table
    :columns="roleExtensionColumns"
    :data-source="roleExtensions"
    :pagination="false"
    :loading="loadingTable"
    :row-key="(roleExtension) => roleExtension.id"
    :expand-row-by-click="true"
    size="middle"
  >
    <template #roleExtensionProject="roleExtension">
      <span>
        <router-link
          :to="{ name: 'project', params: { projectId: roleExtension.role.project.id } }"
        >
          {{ roleExtension.role.project.name }}
        </router-link>
      </span>
    </template>

    <template #roleExtensionRoleTitle="roleExtension">
      <span>
        {{ roleExtension.role.title }}
      </span>
    </template>

    <template #roleExtensionCreatedByUser="created_by_user">
      <span>
        <router-link :to="{ name: 'user', params: { id: created_by_user.id } }">
          {{ created_by_user.name }}
        </router-link>
      </span>
    </template>

    <template #roleExtensionPipeDriveId="pipedrive_id">
      <span>
        <a v-if="pipedrive_id" :href="getPipedriveLink(pipedrive_id)" target="_blank" @click.stop>
          {{ pipedrive_id }}
        </a>
        <span v-else>-</span>
      </span>
    </template>

    <template #roleExtensionStatus="roleExtension">
      <span>
        <a-tag
          v-if="roleExtension.colleague_accepted == true"
          :color="extensionStatusColor[ExtensionStatus.APPROVED]"
          class="default-cursor"
          >Approved</a-tag
        >
        <a-tag
          v-if="roleExtension.colleague_accepted == false"
          :color="extensionStatusColor[ExtensionStatus.TO_BE_DISCUSSED]"
          class="default-cursor"
          >To be discussed</a-tag
        >
        <a-tag
          v-if="roleExtension.colleague_accepted == null"
          :color="extensionStatusColor[ExtensionStatus.WAITING]"
          class="default-cursor"
          >Waiting</a-tag
        >
      </span>
    </template>

    <template #expandedRowRender="roleExtension">
      <span>
        <latest-role-extension-overview
          :acl="acl"
          :profile="profile"
          :role="roleExtension.role"
          @on-successful-change="$emit('on-role-extension-list-change')"
        />
      </span>
    </template>
  </a-table>
</template>

<script setup lang="ts">
import LatestRoleExtensionOverview from "../../roles/_components/LatestRoleExtensionOverview.vue";
import { ExtensionStatus, extensionStatusColor } from "@/modules/roles/_utils/role-extension";
import { BaseAclInterface, ProfileInterface } from "@/modules/authentication/types";
import { RoleExtensionInterface } from "../../roles/types";
import { roleExtensionColumns } from "../_utils/tables";

// Props
const props = defineProps({
  roleExtensions: { type: Array as () => Array<RoleExtensionInterface>, default: undefined },
  profile: { type: Object as () => ProfileInterface, default: undefined },
  acl: { type: Object as () => BaseAclInterface, default: undefined },
  loadingTable: { type: Boolean, default: undefined },
});

// Component methods
const getPipedriveLink = (pipedriveId: number) => {
  return `https://skry.pipedrive.com/v1/deals/${pipedriveId}`;
};
</script>
