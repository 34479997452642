<template>
  <svg width="90" height="90" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#skry-svg-logo)">
      <path
        v-if="showS"
        id="skry-svg-s"
        class="letter"
        d="M54.4504 25.5977H29.5037H25.6419V29.4234V51.1433H0.699097V54.969H25.6419H29.5037V51.1433V29.4234H54.4504V25.5977Z"
        :fill="color"
      />
      <path
        v-if="showY"
        id="skry-svg-y"
        class="letter"
        d="M85.4947 83.3451L62.4847 60.2423L59.7504 62.9473L83.0825 85.7575H34.8037V89.5833H89.3565V89.5795V85.7575V60.2081H85.4947V83.3451Z"
        :fill="color"
      />
      <path
        v-if="showK"
        id="skry-svg-k"
        class="letter"
        d="M66.0052 51.1433L89.322 28.3445L86.5915 25.6395L63.5662 48.7536V0.925903H59.7043V54.9691H59.7082H63.5662H89.3565V51.1433H66.0052Z"
        :fill="color"
      />
      <path
        v-if="showR"
        id="skry-svg-r"
        class="letter"
        d="M54.4504 60.2081H29.5076H25.6458V64.0339V89.5833H29.5076V64.0339H54.4504V60.2081Z"
        :fill="color"
      />
    </g>
    <defs>
      <clipPath id="skry-svg-logo">
        <rect
          width="88.6574"
          height="88.6574"
          fill="white"
          transform="translate(0.699097 0.925903)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script setup lang="ts">
import { computed } from "vue";

const props = defineProps({
  letters: { type: String, default: "skry" },
  theme: { type: String, default: "turquoise" },
});

const showS = computed<boolean>(() => props.letters.includes("s"));
const showK = computed<boolean>(() => props.letters.includes("k"));
const showR = computed<boolean>(() => props.letters.includes("r"));
const showY = computed<boolean>(() => props.letters.includes("y"));
const color = computed<string>(() => {
  switch (props.theme) {
    case "purple":
      return "hsl(257, 84%, 80%)";
    case "red":
      return "hsl(0, 84%, 80%)";
    case "yellow":
      return "hsl(51, 82%, 80%)";
    case "green":
      return "hsl(110, 77%, 81%)";
    case "blue":
      return "hsla(198, 77%, 80%, 0.6)";
    case "black":
      return "hsla(0, 0%, 100%, 0.15)";
    case "turquoise":
    default:
      return "hsl(168, 86%, 81%)";
  }
});
</script>
