<template>
  <a-modal
    :visible="visible"
    :destroy-on-close="true"
    title="Add Skill"
    ok-text="Save"
    @ok="() => handleStoreSkill()"
    @cancel="() => $emit('close')"
  >
    <a-form layout="horizontal">
      <SkInput
        v-model="newSkill.title"
        v-validate="'required|max:255'"
        :error="$validator.errors.first('title')"
        data-vv-name="title"
        label="Title"
        placeholder="Skill title"
      />

      <SkTextarea
        v-model="newSkill.description"
        label="Description"
        placeholder="Skill description"
      />
    </a-form>
  </a-modal>
</template>

<script setup lang="ts">
import { getCurrentInstance, ref, watch } from "vue";
import useMixin from "@/useMixin";

// Props
const props = defineProps({
  visible: { type: Boolean, default: false },
});

// Emits
const emits = defineEmits(["store-skill", "close"]);

// Mixins
const { setObject } = useMixin();

// Instances
const instance = getCurrentInstance();
const $validator = instance?.proxy.$validator;

// Data properties
const newSkill = ref<any>({
  title: undefined,
  description: undefined,
});

// Watchers
watch(
  () => props.visible,
  (newProp, oldProp) => {
    if (newProp && !oldProp) {
      newSkill.value = setObject(newSkill, undefined);
    }
  }
);

// Class methods
const handleStoreSkill = (): void => {
  $validator?.validateAll().then((result) => {
    if (result) {
      emits("store-skill", newSkill.value);
      emits("close");
    }
  });
};
</script>
