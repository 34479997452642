import { defineStore, acceptHMRUpdate } from "pinia";
import { apiGetRoleExtensions } from "@/modules/roles/_utils/api";
import { PaginationInterface } from "@/common/types";
import {
  apiGetSchedule,
  apiGetVacationReports,
  apiGetKnowledgeDomains,
} from "@/modules/week_schedule/_utils/api";

export interface StateInterface {
  schedule: Map<number, any>;
  schedulePagination: PaginationInterface | undefined;
  vacationReports: Array<any>;
  roleExtensions: Array<any>;
  knowledgeDomains: Array<any>;
}

export const useWeekScheduleStore = defineStore("week_schedule", {
  state: (): StateInterface => {
    return {
      schedule: new Map(),
      schedulePagination: undefined,
      vacationReports: [],
      roleExtensions: [],
      knowledgeDomains: [],
    };
  },
  actions: {
    getScheduleByPage(params: any) {
      return new Promise((resolve, reject) => {
        apiGetSchedule(params)
          .then(({ data }: any) => {
            this.setSchedule({
              page: params.page,
              payload: data.data,
            });
            this.setSchedulePagination(data.meta);
            resolve(data.data);
          })
          .catch((err: any) => {
            reject(err);
          });
      });
    },

    getVacationReports(params: any): any {
      return new Promise((resolve, reject) => {
        apiGetVacationReports(params)
          .then(({ data }: any) => {
            this.setScheduleVacationReports({
              payload: data.data,
            });
            resolve(data.data);
          })
          .catch((err: any) => {
            reject(err);
          });
      });
    },

    getUserRoleExtensions({ userId, extension_statuses }: any): any {
      return new Promise((resolve, reject) => {
        apiGetRoleExtensions({
          role_user_id: userId,
          extension_statuses: extension_statuses,
          pending: true,
        })
          .then(({ data }: any) => {
            this.setScheduleRoleExtensions({
              payload: data.data,
            });
            resolve(data.data);
          })
          .catch((err: any) => {
            reject(err);
          });
      });
    },

    getKnowledgeDomains(params: any): any {
      return new Promise((resolve, reject) => {
        apiGetKnowledgeDomains(params)
          .then(({ data }: any) => {
            this.setScheduleKnowledgeDomains({
              payload: data.data,
            });
            resolve(data.data);
          })
          .catch((err: any) => {
            reject(err);
          });
      });
    },

    setSchedule({ page, payload }: { page: number; payload: any }): void {
      this.schedule = this.schedule.set(page, payload);
    },

    setSchedulePagination(meta: any): void {
      this.schedulePagination = meta;
    },

    setScheduleVacationReports({ payload }: { payload: any }): void {
      this.vacationReports = payload;
    },

    setScheduleRoleExtensions({ payload }: { payload: any }): void {
      this.roleExtensions = payload;
    },

    setScheduleKnowledgeDomains({ payload }: { payload: any }): void {
      this.knowledgeDomains = payload;
    },
  },
});

// HMR
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useWeekScheduleStore, import.meta.hot));
}
