import { RouteInterface } from "@/common/types";
import Skills from "./_views/Skills.vue";

export default [
  {
    path: "skills",
    component: Skills,
    name: "skills",
    meta: { auth: true },
  },
  {
    path: "skill_categories",
    component: Skills,
    name: "skill_categories",
    meta: { auth: true },
  },
] as Array<RouteInterface>;
