<template>
  <a-modal
    :visible="visible"
    :destroy-on-close="true"
    title="Delete Role Extension"
    ok-text="Delete extension"
    ok-type="danger"
    @ok="handleDeleteRoleExtension"
    @cancel="$emit('close')"
  >
    <h4 v-if="roleExtension">
      Once you delete this role extension, you won't be able to reopen it.
      <br />
      You will have to create a new one entirely.
    </h4>
  </a-modal>
</template>

<script setup lang="ts">
import { getCurrentInstance } from "vue";
import { RoleExtensionInterface } from "@/modules/roles/types";

// Props
const props = defineProps({
  visible: { type: Boolean, default: false },
  roleExtension: { type: Object as () => RoleExtensionInterface, default: undefined },
});

// Emits
const emits = defineEmits(["delete-role-extension", "close"]);

// Instances
const instance = getCurrentInstance();
const $validator = instance?.proxy?.$validator;

// Class methods
const handleDeleteRoleExtension = (): void => {
  $validator?.errors.clear();
  emits("delete-role-extension", props.roleExtension);
  emits("close");
};
</script>
