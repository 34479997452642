import moment from "@/date";
import { ReportInterface, ReportPayloadInterface } from "../types";

export function disabledStartDates(
  current: any,
  currentReport: ReportPayloadInterface,
  existingUserReports: Array<ReportInterface>,
  canEditPastDates: boolean = false
): boolean {
  current = moment(current);
  if (!canEditPastDates && current.isBefore(moment(), "d")) {
    return true;
  }

  if (currentReport.end_date) {
    let reportsBetweenCurrentAndEndDate = existingUserReports.filter((r: ReportInterface) => {
      return (
        r.id != currentReport.id &&
        moment(r.end_date).isSameOrAfter(current) &&
        moment(r.end_date).isSameOrBefore(currentReport.end_date)
      );
    });
    return reportsBetweenCurrentAndEndDate.length > 0 || current.isAfter(currentReport.end_date);
  }

  if (isDayAlreadyReported(current, existingUserReports)) {
    return true;
  }

  return false;
}

export function disabledEndDates(
  current: any,
  currentReport: ReportPayloadInterface,
  existingUserReports: Array<ReportInterface>,
  canEditPastDates: boolean = false
): boolean {
  if (!currentReport.start_date) {
    return (
      (!canEditPastDates && current.isBefore(moment(), "d")) ||
      (currentReport.end_date && current.isAfter(currentReport.end_date))
    );
  }

  if (currentReport.start_date && current.isBefore(currentReport.start_date)) {
    return true;
  }

  if (currentReport.start_date) {
    let reportsBetweenStartDateAndCurrent = existingUserReports.filter((r: ReportInterface) => {
      return (
        r.id != currentReport.id &&
        moment(r.start_date).isSameOrAfter(currentReport.start_date) &&
        moment(r.start_date).isSameOrBefore(current)
      );
    });
    return (
      reportsBetweenStartDateAndCurrent.length > 0 || current.isBefore(currentReport.start_date)
    );
  }

  return disabledStartDates(current, currentReport, existingUserReports);
}

function isDayAlreadyReported(day: any, existingReports: Array<ReportInterface>): boolean {
  let existingReportedDays = getExisitingReportedDays(existingReports);
  return existingReportedDays.has(day.format("YYYY-MM-DD"));
}

function getExisitingReportedDays(existingReports: Array<ReportInterface>): Set<string> {
  let days: Set<string> = new Set();
  existingReports.forEach((r: ReportInterface) => {
    for (var d = moment(r.start_date!); d <= moment(r.end_date!); d.add(1, "d")) {
      days.add(d.format("YYYY-MM-DD"));
    }
  });
  return days;
}
