<template>
  <div>
    <div v-show="filterPanelVisible" class="card mb-3">
      <a-form layout="vertical">
        <a-row>
          <a-col span="8">
            <a-form-item label="Role">
              <a-select
                v-model="newFilter.role_id"
                mode="multiple"
                show-search
                placeholder="Select role"
                option-filter-prop="children"
                @change="applyFilters"
              >
                <a-select-option v-for="role in getUnfilteredRoles()" :key="role.id.toString()">
                  {{ `${role.title} (${role.name})` }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col span="8" class="mx-4">
            <a-form-item label="Cost center">
              <a-select
                v-model="newFilter.cost_center_id"
                mode="multiple"
                show-search
                placeholder="Select cost center"
                option-filter-prop="children"
                @change="applyFilters"
              >
                <a-select-option v-for="costCenter in costCenters" :key="costCenter.id.toString()">
                  {{ `${costCenter.name}` }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-form-item :wrapped-col="{ span: 6 }" label="Date">
            <a-date-picker
              :value="parseDate(newFilter.start_date)"
              :disabled-date="(date) => disabledStartDate(date)"
              placeholder="Start date"
              class="mb-2"
              @change="(date) => onDateChange(date, 'start_date')"
            />
            <a-col>
              <a-date-picker
                :value="parseDate(newFilter.end_date)"
                :disabled-date="(date) => disabledEndDate(date)"
                placeholder="End date"
                @change="(date) => onDateChange(date, 'end_date')"
              />
            </a-col>
          </a-form-item>
        </a-row>
      </a-form>

      <a-row class="d-flex justify-content-end">
        <span>
          <strong>Results:</strong>
          {{ numberOfResults ? numberOfResults : 0 }}
        </span>
      </a-row>
    </div>
    <portal to="reports-summary-filter-buttons">
      <a-button
        v-if="filterPanelVisible"
        class="mr-2"
        icon="close"
        type="danger"
        @click="() => clearFilters()"
      />
      <a-button
        v-show="filterPanelVisible"
        class="mr-2"
        type="primary"
        icon="up"
        @click="() => toggleFilterPanel()"
      />
      <a-button
        v-if="!filterPanelVisible"
        class="mr-2"
        type="primary"
        icon="filter"
        @click="() => toggleFilterPanel()"
      />
    </portal>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { ReportsSummaryFilterInterface } from "@/modules/reports/types";
import { RoleReportsSummaryInterface } from "@/modules/reports/types";
import { CostCenterInterface } from "@/modules/cost_centers/types";
import { Moment } from "moment";
import moment from "@/date";
import useFilters from "@/useFilters";

// Props
const props = defineProps({
  numberOfResults: { type: Number, default: undefined },
  roles: { type: Array as () => Array<RoleReportsSummaryInterface>, default: () => [] },
  costCenters: { type: Array as () => Array<CostCenterInterface>, default: () => [] },
});

// Emits
const emits = defineEmits(["apply-filters", "clear-filters"]);

// Filters
const { parseDate } = useFilters();

// Data properties
const filterPanelVisible = ref<boolean>(false);
const unfilteredRoles = ref<Array<RoleReportsSummaryInterface>>([]);

const newFilter = ref<ReportsSummaryFilterInterface>({
  role_id: [],
  cost_center_id: [],
  start_date: "",
  end_date: "",
});

// Computed properties
const filterIsEmpty = computed((): boolean => {
  return Boolean(
    newFilter.value.role_id &&
      newFilter.value.role_id.length === 0 &&
      newFilter.value.cost_center_id &&
      newFilter.value.cost_center_id.length === 0 &&
      newFilter.value.start_date === "" &&
      newFilter.value.end_date === ""
  );
});

// Component methods
const getUnfilteredRoles = () => {
  if (unfilteredRoles.value.length === 0) {
    unfilteredRoles.value = props.roles;
  }
  return unfilteredRoles.value;
};

const toggleFilterPanel = (): void => {
  filterPanelVisible.value = !filterPanelVisible.value;
};

const applyFilters = (): void => {
  emits("apply-filters", filterIsEmpty.value ? {} : newFilter.value);
};

const clearFilters = (): void => {
  emits("clear-filters", {});
};

const disabledStartDate = (startValue: Moment): boolean => {
  const endValue = moment(newFilter.value.end_date).endOf("day");
  if (!startValue || !endValue) {
    return false;
  }
  return startValue.valueOf() >= endValue.valueOf();
};

const disabledEndDate = (endValue: Moment): boolean => {
  const startValue = moment(newFilter.value.start_date);
  if (!endValue || !startValue) {
    return false;
  }
  return startValue.valueOf() >= endValue.valueOf();
};

const onDateChange = (date: any, type: string): void => {
  if (type === "start_date") {
    newFilter.value.start_date = date == null ? "" : date.format("YYYY-MM-DD");
  } else if (type === "end_date") {
    newFilter.value.end_date = date == null ? "" : date.format("YYYY-MM-DD");
  }
  applyFilters();
};

const handleClearReportsFilter = (): void => {
  newFilter.value = {
    role_id: [],
    start_date: "",
    end_date: "",
  };
};
</script>
