<template>
  <div>
    <a-row type="flex" justify="end" class="mb-2">
      <a-button type="primary" @click="addCourseModalVisible = true">Add Course</a-button>
    </a-row>

    <a-row :gutter="10" type="flex">
      <a-col v-for="course in courses" :key="course.id" :xl="8" :lg="12" :md="24">
        <a-card class="mb-2">
          <a-row type="flex" justify="space-between" align="middle">
            <h3>{{ course.title }}</h3>

            <div>
              <a-icon type="edit" class="action-default" @click.stop="handleEditCourse(course)" />
              <a-icon
                type="delete"
                class="ml-2 action-danger"
                @click.stop="confirmDeleteCourse(course)"
              />
            </div>
          </a-row>
          <a-row>
            <p>{{ course.description }}</p>
          </a-row>
        </a-card>
      </a-col>
    </a-row>

    <add-course-modal
      :visible="addCourseModalVisible"
      @close="addCourseModalVisible = false"
      @store-course="(newCourse) => $emit('store-course', newCourse)"
    />

    <edit-course-modal
      :visible="editCourseModalVisible"
      :course="editCourse"
      @close="editCourseModalVisible = false"
      @update-course="(updatedCourse) => $emit('update-course', updatedCourse)"
    />
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import EditCourseModal from "./EditCourseModal.vue";
import AddCourseModal from "./AddCourseModal.vue";

// Props
const props = defineProps({
  courses: { type: Array as () => Array<any>, default: () => [] },
});

// Emits
const emits = defineEmits(["destroy-course"]);

// Data Properties
const editCourse = ref<any>(null);
const editCourseModalVisible = ref<boolean>(false);
const addCourseModalVisible = ref<boolean>(false);

// Class Methods
const handleEditCourse = (course: any) => {
  editCourse.value = course;
  editCourseModalVisible.value = true;
};

const confirmDeleteCourse = (course: any) => {
  const mod = window.confirm("Are you sure you want to delete this course?");
  if (mod) {
    emits("destroy-course", course.id);
  }
};
</script>
