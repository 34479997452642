import axios from "@/network";

export function apiGetReceipts(params: any) {
  const url = "/receipts";
  return axios.get(url, { params });
}

export function apiGetUserReceipts(userId: number, page: number, page_size: number) {
  const url = `/users/${userId}/receipts`;
  return axios.get(url, { params: { page, page_size } });
}

export function apiStoreUserReceipt(userId: number, newReceipt: any) {
  const url = `/users/${userId}/receipts`;
  const payload = { receipt: newReceipt };
  return axios.post(url, payload);
}

export function apiUpdateUserReceipt(updatedReceipt: any) {
  const url = `users/${updatedReceipt.user.id}/receipts/${updatedReceipt.id}`;
  const payload = { receipt: updatedReceipt };
  return axios.patch(url, payload);
}

export function apiBulkUpdateReceipts(receiptIds: Array<number>, newStatus: string) {
  const url = "/receipts/bulk_update";
  const payload = { ids: receiptIds, new_status: newStatus };
  return axios.post(url, payload);
}

export function apiGetReceiptsCategories() {
  const url = "/categories";
  return axios.get(url);
}

export function apiGetReceiptsCurrencies() {
  const url = "/currencies";
  return axios.get(url);
}

export function apiGetReceipt(receiptId: number) {
  const url = `/receipts/${receiptId}`;
  return axios.get(url);
}

export function apiGetReceiptAttachments(receiptId: number) {
  const url = `/receipts/${receiptId}/attachments`;
  return axios.get(url);
}

export function apiDeleteReceiptAttachment(receiptId: number, attachmentId: number) {
  const url = `/receipts/${receiptId}/attachments/${attachmentId}`;
  return axios.delete(url);
}
