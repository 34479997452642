<template>
  <a-modal
    :visible="visible"
    :destroy-on-close="true"
    :title="'Create new Transaction'"
    ok-text="Create"
    @ok="() => handleStoreTransaction()"
    @cancel="() => $emit('close')"
  >
    <a-form layout="horizontal" data-vv-scope="newTransaction">
      <a-form-item
        :label-col="{ span: 7 }"
        :wrapper-col="{ span: 12 }"
        :validate-status="$validator.errors.first('newTransaction.user') ? 'error' : ''"
        :help="$validator.errors.first('newTransaction.user')"
        label="User"
      >
        {{ receipt.user.name }}
      </a-form-item>

      <a-form-item
        :label-col="{ span: 7 }"
        :wrapper-col="{ span: 12 }"
        :validate-status="$validator.errors.first('newTransaction.amount_cents') ? 'error' : ''"
        :help="$validator.errors.first('newTransaction.amount_cents')"
        label="Amount"
      >
        <a-input
          v-model="newTransaction.amount_cents"
          v-validate="'required'"
          :addon-after="newTransaction.amount_currency"
          data-vv-name="amount"
        >
          <template #addonBefore>
            <a-select v-model="recordedAs" default-value="negative" style="width: 50px">
              <a-select-option value="negative">-</a-select-option>
              <a-select-option value="positive">+</a-select-option>
            </a-select>
          </template>
        </a-input>
      </a-form-item>

      <a-form-item
        :label-col="{ span: 7 }"
        :wrapper-col="{ span: 12 }"
        :validate-status="$validator.errors.first('newTransaction.made-at') ? 'error' : ''"
        :help="$validator.errors.first('newTransaction.made-at')"
        label="Made at"
      >
        <a-date-picker
          v-validate="'required'"
          :value="parseDate(newTransaction.made_at)"
          placeholder="Date"
          class="w-100"
          data-vv-name="made_at"
          data-vv-as="date"
          @change="(date) => onDateChange(date, 'made_at')"
        />
      </a-form-item>

      <a-form-item :label-col="{ span: 7 }" :wrapper-col="{ span: 16 }" label="Status">
        <a-radio-group
          :value="newTransaction.status"
          @change="(el) => (newTransaction.status = el.target.value)"
        >
          <a-radio-button value="pending">Pending</a-radio-button>
          <a-radio-button value="paid">Paid</a-radio-button>
        </a-radio-group>
      </a-form-item>

      <a-form-item
        :label-col="{ span: 7 }"
        :wrapper-col="{ span: 12 }"
        :validate-status="$validator.errors.first('newTransaction.description') ? 'error' : ''"
        :help="$validator.errors.first('newTransaction.description')"
        label="Description"
      >
        <a-textarea
          v-model="newTransaction.description"
          v-validate="'required'"
          :auto-size="{ minRows: 3 }"
          data-vv-name="description"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script setup lang="ts">
import { ref, watch, getCurrentInstance, toRefs } from "vue";
import { ProfileInterface } from "@/modules/authentication/types";
import { apiGetUser } from "../../users/_utils/api";
import { ReceiptInterface } from "../types";
import useMixin from "@/useMixin";
import moment from "@/date";
import useFilters from "@/useFilters";

// Props
const props = defineProps({
  visible: { type: Boolean, default: false },
  receipt: { type: Object as () => ReceiptInterface, default: undefined },
});

// Emits
const emits = defineEmits(["store-transaction", "close"]);

// Mixins
const { toMoneyCents, setObject } = useMixin();

// Filters
const { parseDate } = useFilters();

// Instances
const instance = getCurrentInstance();
const $validator = instance?.proxy.$validator;

// Data properties
const { receipt } = toRefs(props);
const recordedAs = ref<string>("negative");
const newTransaction = ref<any>({
  type: "receipt-transaction",
  user_id: receipt.value.user.id,
  amount_cents: undefined,
  amount_currency: "SEK",
  made_at: moment().format("YYYY-MM-DD"),
  status: "pending",
  description: undefined,
  receipt_id: receipt.value.id,
});
const user = ref<ProfileInterface | undefined>(undefined);

// Watchers
watch(
  () => props.visible,
  (newProp, oldProp) => {
    if (newProp && !oldProp) {
      newTransaction.value.user_id = props.receipt.user.id;
      newTransaction.value.amount_cents = undefined;
      newTransaction.value.made_at = moment().format("YYYY-MM-DD");
      newTransaction.value.status = "pending";
      newTransaction.value.description = undefined;
      newTransaction.value.receipt_id = props.receipt.id;

      handleGetUser();
    }
  }
);

// Component methods
const handleGetUser = () => {
  apiGetUser(props.receipt.user.id).then((res: any) => {
    user.value = res.data.data;
    if (user.value) newTransaction.value.amount_currency = user.value.skp_currency;
  });
};

const onDateChange = (value: any, attribute: string): void => {
  newTransaction.value[attribute] = value ? value.format("YYYY-MM-DD") : undefined;
};

const changeAmountToCents = (): void => {
  newTransaction.value.amount_cents = toMoneyCents(newTransaction.value.amount_cents);
};

const handleStoreTransaction = (): any => {
  $validator?.validateAll("newTransaction").then((result) => {
    if (result) {
      changeAmountToCents();
      // Check sign of amount and convert it to the correct one
      if (recordedAs.value === "negative") {
        newTransaction.value.amount_cents = -newTransaction.value.amount_cents;
      }
      emits("store-transaction", newTransaction.value);
      emits("close");
      newTransaction.value = setObject(newTransaction.value, undefined);
      newTransaction.value.type = "receipt-transaction";
      newTransaction.value.user_id = props.receipt.user.id;
      newTransaction.value.made_at = moment().format("YYYY-MM-DD");
      newTransaction.value.status = "pending";
      newTransaction.value.receipt_id = props.receipt.id;
    }
  });
};
</script>
