<template>
  <a-layout-content class="my-4 mx-3">
    <div class="container">
      <div class="d-flex justify-content-between">
        <h2>Vacation Years</h2>
        <portal-target name="vacation-years-filter-buttons"></portal-target>
      </div>
      <vacation-years-filter
        :number-of-results="vacationYears?.length ?? 0"
        @apply-filters="applyFilters"
      />
      <div class="card mb-3">
        <a-row>
          <a-col>
            <h3>
              <b>{{ selectedYear }}</b>
            </h3>
          </a-col>
        </a-row>
        <a-row>
          <a-col>
            <a-table
              :data-source="vacationYears"
              :scroll="{ x: 1000 }"
              :pagination="false"
              :columns="vacationYearLabels"
            >
              <span slot="user" slot-scope="user" class="d-flex align-items-center">
                <span class="min-w-8 mr-2">
                  <a-avatar :src="user.gravatar" />
                </span>
                {{ user.name }}
              </span>
              <template #edit="vacationYear">
                <span>
                  <a-icon
                    type="edit"
                    class="action-default"
                    @click.stop="() => handleEditVacationYearModalVisible(vacationYear)"
                  />
                </span>
              </template>
            </a-table>
          </a-col>
        </a-row>
      </div>

      <!-- EDIT VACATION YEAR MODAL -->
      <edit-vacation-year-modal
        :vacation-year="vacationYearToBeEdited"
        :visible="editVacationYearModalVisible"
        @close="editVacationYearModalVisible = false"
        @update-vacation-year="handleUpdateVacationYear"
      />
    </div>
  </a-layout-content>
</template>

<script setup lang="ts">
import { onBeforeMount, ref, getCurrentInstance } from "vue";
import { UserVacationYearInterface, VacationYearsFilterInterface } from "../types";
import VacationYearsFilter from "../_components/VacationYearsFilter.vue";
import moment from "@/date";
import { apiGetAllUserVacationYears, apiUpdateUserVacationYear } from "../_utils/api";
import EditVacationYearModal from "../_components/EditVacationYearModal.vue";

const editVacationYearModalVisible = ref<boolean>(false);
const vacationYearToBeEdited = ref<UserVacationYearInterface | undefined>(undefined);
const selectedYear = ref<number>(moment().year());
const vacationYears = ref<Array<UserVacationYearInterface>>();
let _allVacationYears: Array<UserVacationYearInterface> = [];
let _filters: VacationYearsFilterInterface = {
  year: moment().year(),
};

// Instances
const instance = getCurrentInstance();
const $message = instance?.proxy.$message;

const vacationYearLabels = [
  {
    title: "User",
    dataIndex: "user",
    key: "user",
    scopedSlots: { customRender: "user" },
  },
  {
    title: "Earned vacation days",
    dataIndex: "vacation_days_earned",
    key: "vacation_days_earned",
    align: "center",
  },
  {
    title: "Paid vacation days",
    dataIndex: "vacation_days_paid",
    key: "vacation_days_paid",
    align: "center",
  },
  {
    title: "Reported",
    dataIndex: "vacation_days_reported",
    key: "vacation_days_reported",
    align: "center",
  },
  {
    title: "Vacation days saved",
    dataIndex: "vacation_days_saved",
    key: "vacation_days_saved",
    align: "center",
  },
  {
    title: "Saved days used",
    dataIndex: "vacation_days_saved_used",
    key: "vacation_days_saved_used",
    align: "center",
  },
  {
    title: "Paid out",
    dataIndex: "paid_out_vacation_days",
    key: "paid_out_vacation_days",
    align: "center",
  },
  {
    title: "Prepaid",
    dataIndex: "vacation_days_prepaid",
    key: "vacation_days_prepaid",
    align: "center",
  },
  {
    title: "Edit",
    key: "edit",
    align: "center",
    scopedSlots: { customRender: "edit" },
  },
];

const isObjectDifferent = (a: any, b: any): boolean => {
  if (Object.keys(a).length !== Object.keys(b).length) {
    return true;
  }
  for (const prop in a) {
    if (!b.hasOwnProperty(prop) || a[prop] !== b[prop]) {
      return true;
    }
  }
  return false;
};

const applyFilters = (filters: VacationYearsFilterInterface) => {
  if (isObjectDifferent(filters, _filters)) {
    fetchVacationYears(filters).then((fetchedYears) => {
      _allVacationYears = fetchedYears;
      vacationYears.value = formatVacationYears(_allVacationYears);
    });
    if (filters.year) {
      selectedYear.value = filters.year;
    }
    _filters = { ...filters };
  } else {
    vacationYears.value = formatVacationYears(_allVacationYears);
  }
};

const formatVacationYears = (allVacationYears: Array<UserVacationYearInterface>) => {
  return allVacationYears.map((_vacationYear) => ({
    ..._vacationYear,
    vacation_days_saved_used: _vacationYear.vacation_days_saved_used ?? "n/a",
    paid_out_vacation_days: _vacationYear.paid_out_vacation_days ?? "n/a",
  }));
};

const fetchVacationYears = async (
  filters: VacationYearsFilterInterface
): Promise<Array<UserVacationYearInterface>> => {
  return (await apiGetAllUserVacationYears(filters)).data.data;
};

const loadVacationYears = async (filters: VacationYearsFilterInterface) => {
  _allVacationYears = await fetchVacationYears(filters);
  vacationYears.value = formatVacationYears(_allVacationYears);
};

const handleEditVacationYearModalVisible = (vacationYear: UserVacationYearInterface): void => {
  if (!vacationYear) {
    return;
  }
  vacationYearToBeEdited.value = vacationYear;
  editVacationYearModalVisible.value = true;
};

const handleUpdateVacationYear = (updatedVacationYear: UserVacationYearInterface): void => {
  if (updatedVacationYear) {
    apiUpdateUserVacationYear(updatedVacationYear)
      .then(() => {
        loadVacationYears(_filters);
      })
      .then(() => $message?.success("Vacation year updated successfully!", 3))
      .catch(() => $message?.error("Couldn't update vacation year!", 3));
  }
};

onBeforeMount(() => {
  _filters.year = moment().year();
  loadVacationYears(_filters);
});
</script>

<style lang="scss" scoped></style>
