<template v-if="jobPosting">
  <div
    class="d-flex flex-row align-items-center mb-2 px-3 pt-2 pb-1 job-posting-card"
    :class="{ checked: selected }"
    @click="handleShowSelectedJobPostingDetails"
  >
    <div class="d-flex flex-column">
      <strong class>{{ jobPosting.title }}</strong>
      <span class="mt-1">
        <em>{{ jobPosting.organisation }}</em>
      </span>
      <div class="mt-2 d-flex flex-row align-items-center">
        <a-icon type="plus-circle" class="mr-1 text-green" />
        <span>
          {{ handleDurationBetweenDates(jobPosting.created_at) }}
          ago
        </span>
      </div>
    </div>
    <a-icon
      type="right-circle"
      class="ml-auto"
      :theme="`${jobPosting.has_description ? 'twoTone' : 'outlined'}`"
    />
  </div>
</template>

<script setup lang="ts">
import { getCurrentInstance } from "vue";
import { useRouter } from "vue-router/composables";
import { useJobPostingsStore } from "@/modules/job_postings/_store";
import { JobPostingInterface } from "../types";
import useFilters from "@/useFilters";
import moment from "@/date";
import { RFC_3339 } from "@/date";

// Props
const props = defineProps({
  jobPosting: { type: Object as () => JobPostingInterface, default: undefined },
  selected: { type: Boolean, default: undefined },
});

// Pinia
const jobPostingsStore = useJobPostingsStore();

// Filters
const { durationBetweenDates } = useFilters();

// Instances
const router = useRouter();
const instance = getCurrentInstance();
const $message = instance?.proxy.$message;

// Component methods
const handleShowSelectedJobPostingDetails = () => {
  jobPostingsStore
    .showSelectedJobPostingDetails(props.jobPosting, router)
    .catch(() => $message?.error("Couldn't load the details of this job posting!", 3));
};

const handleDurationBetweenDates = (time: string): string => {
  return durationBetweenDates(new Date(), moment(time, [moment.ISO_8601, RFC_3339]).toDate());
};
</script>

<style lang="css" scoped>
.job-posting-card {
  border-radius: 10px;
  background-color: white;
  border: 2px solid #00000000;
  box-shadow:
    0 1px 3px 0 rgb(0 0 0 / 0.1),
    0 1px 2px -1px rgb(0 0 0 / 0.1);
  cursor: pointer;
}

.job-posting-card:hover {
  background-color: #fafafa;
}

.checked {
  border: 2px solid #5095f8;
}
</style>
