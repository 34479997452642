<template>
  <!-- WEEK SCHEDULE USERS TABLE -->
  <div class="card">
    <empty-resource-table v-if="!schedulePage[0] && !loadingTable" resource="week schedule" />

    <a-table
      v-if="schedulePage[0] || loadingTable"
      :columns="scheduleColumns"
      :data-source="schedulePage"
      :pagination="false"
      :loading="loadingTable"
      :row-key="(user) => user.id"
      :expand-row-by-click="true"
      :scroll="{ x: true }"
      @change="(pagination, filters, sorter) => handleChange(pagination, filters, sorter)"
      @expand="handleRowExpand"
      @expandedRowsChange="handleExpandedRowsChange"
    >
      <template #scheduleName="name, user">
        <span class="d-flex align-items-center">
          <span class="min-w-8 mr-2">
            <a-avatar :src="user.gravatar" />
          </span>
          <router-link :to="{ name: 'user', params: { id: user.id } }">
            {{ name }}
          </router-link>
        </span>
      </template>

      <template #scheduleMainRole="main_role">
        <span>
          <span v-if="main_role">
            {{ main_role.title + " at " + main_role.client_name }} ({{
              toPercentage(main_role.workload)
            }})
          </span>
          <span v-else> N/A </span>
        </span>
      </template>

      <template #scheduleUtilisation="utilisation, user">
        <span>
          {{
            user.expected_workload == 0
              ? "N/A"
              : toPercentage(user.workload / user.expected_workload)
          }}
        </span>
      </template>

      <template #scheduleExpectedWorkload="expected_workload">
        <span>
          {{ toPercentage(expected_workload) }}
        </span>
      </template>

      <template #scheduleMainRoleEndDate="user">
        <span v-if="user.main_role">
          {{ formatAvailableFromDate(user.main_role.end_date) }}
        </span>
      </template>

      <template #scheduleExtensionStatus="user">
        <span>
          <a-tag
            v-if="user.nb_pending_extensions > 0"
            :color="extensionStatusColor[ExtensionStatus.PENDING]"
            class="default-cursor"
          >
            Pending extension{{ user.nb_pending_extensions == 1 ? "" : "s" }}
          </a-tag>
          <a-tag
            v-else
            :color="extensionStatusColor[ExtensionStatus.NO_PENDING]"
            class="default-cursor"
          >
            No pending extension
          </a-tag>
          <a-dropdown
            v-if="
              (acl.isCr || acl.isProjectManager) && user.roles_with_no_pending_extensions.length > 0
            "
            placement="bottomRight"
          >
            <a-button type="primary" size="small" @click="(event) => event.stopPropagation()">
              <a-icon type="plus" />
            </a-button>
            <template #overlay>
              <a-menu>
                <a-menu-item-group style="color: #bfbfbb">
                  Create new extension for role
                  <a-menu-item
                    v-for="r in user.roles_with_no_pending_extensions"
                    :key="r.id"
                    class="mt-1"
                    @click="() => showCreateRoleExtensionModal(user.id, r.id)"
                  >
                    {{ r.project.client.name }} / {{ r.project.name }} / {{ r.title }}
                  </a-menu-item>
                </a-menu-item-group>
              </a-menu>
            </template>
          </a-dropdown>
        </span>
      </template>

      <template #expandedRowRender="user">
        <span>
          <h3>Knowledge domains</h3>
          <week-schedule-knowledge-domain-list
            :knowledge-domains="knowledgeDomains[user.id]"
            :loading="loadingKnowledgeDomains[user.id]"
          />
          <a-divider />
          <h3 class="mt-4">Pending role extensions</h3>
          <week-schedule-role-extension-list
            :acl="acl"
            :profile="profile"
            :role-extensions="roleExtensions[user.id]"
            :loading-table="loadingRoleExtensions[user.id]"
            @on-role-extension-list-change="() => onRoleExtensionChange(user.id)"
          />
          <a-divider />
          <h3 class="mt-4">Planned vacation</h3>
          <week-schedule-vacation-list
            :vacation-reports="vacationReports[user.id]"
            :loading-table="loadingVacationReports[user.id]"
          />
        </span>
      </template>
    </a-table>

    <a-pagination
      v-if="loadingTable || schedulePage[0]"
      :current="currentPage"
      :total="schedulePagination ? schedulePagination.total : 1"
      :show-size-changer="true"
      :page-size="pageSize"
      :page-size-options="['10', '25', '50', '100']"
      size="small"
      class="d-flex justify-content-end mt-2"
      @change="(newPage, newPageSize) => handleGetSchedulePage(newPage, newPageSize)"
      @showSizeChange="(pageNumber, newSize) => handleGetSchedulePage(pageNumber, newSize)"
    />

    <!-- CREATE ROLE EXTENSION MODAL -->
    <create-role-extension-modal
      :visible="createRoleExtensionModalVisible"
      :role="role"
      @close="createRoleExtensionModalVisible = false"
      @create-role-extension="handleCreateRoleExtension"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, getCurrentInstance, watch } from "vue";
import { ExtensionStatus, extensionStatusColor } from "@/modules/roles/_utils/role-extension";
import CreateRoleExtensionModal from "../../roles/_components/CreateRoleExtensionModal.vue";
import EmptyResourceTable from "@/modules/common/_components/EmptyResourceTable.vue";
import { BaseAclInterface, ProfileInterface } from "@/modules/authentication/types";
import WeekScheduleKnowledgeDomainList from "./WeekScheduleKnowledgeDomainList.vue";
import { WeekScheduleFilterInterface, WeekScheduleUserInterface } from "../types";
import WeekScheduleRoleExtensionList from "./WeekScheduleRoleExtensionList.vue";
import { RoleExtensionInterface, RoleInterface } from "@/modules/roles/types";
import WeekScheduleVacationList from "./WeekScheduleVacationList.vue";
import { apiCreateRoleExtension } from "@/modules/roles/_utils/api";
import { useWeekScheduleStore } from "@/modules/week_schedule/_store";
import { apiGetUserRole } from "@/modules/users/_utils/api";
import { ReportInterface } from "../../reports/types";
import { PaginationInterface } from "@/common/types";
import { scheduleColumns } from "../_utils/tables";
import useMixin from "@/useMixin";
import moment from "@/date";

// Props
const props = defineProps({
  schedulePage: { type: Array as () => Array<WeekScheduleUserInterface>, default: () => [] },
  schedulePagination: { type: Object as () => PaginationInterface, default: undefined },
  filters: { type: Object as () => WeekScheduleFilterInterface, default: undefined },
  profile: { type: Object as () => ProfileInterface, default: undefined },
  acl: { type: Object as () => BaseAclInterface, default: undefined },
  loadingTable: { type: Boolean, default: false },
  currentPage: { type: Number, default: 1 },
  pageSize: { type: Number, default: 25 },
});

// Emits
const emits = defineEmits(["get-schedule-page", "apply-sorting"]);

// Pinia
const weekScheduleStore = useWeekScheduleStore();

// Mixins
const { toPercentage } = useMixin();

// Instances
const instance = getCurrentInstance();
const $set = instance?.proxy?.$set;
const $message = instance?.proxy?.$message;

// Data properties
const roleExtensions = ref<{ [key: number]: Array<RoleExtensionInterface> }>({});
const vacationReports = ref<{ [key: number]: Array<ReportInterface> }>({});
const loadingKnowledgeDomains = ref<{ [key: number]: boolean }>({});
const loadingVacationReports = ref<{ [key: number]: boolean }>({});
const loadingRoleExtensions = ref<{ [key: number]: boolean }>({});
const knowledgeDomains = ref<{ [key: number]: Array<any> }>({});
const createRoleExtensionModalVisible = ref<boolean>(false);
const role = ref<RoleInterface | undefined>(undefined);
const expandedRowKeys = ref<number[]>([]);

// Watchers
watch(
  () => props.filters,
  () => {
    expandedRowKeys.value.forEach((id) => handleGetRoleExtensions(id));
  }
);

// Component methods
const handleGetSchedulePage = (page: number, pageSize: number): void => {
  emits("get-schedule-page", { page, pageSize });
};

const handleChange = (pagination: any, filters: any, sorter: any): void => {
  emits(
    "apply-sorting",
    sorter.order
      ? {
          sort: sorter.columnKey,
          order: sorter.columnKey ? (sorter.order === "descend" ? "desc" : "asc") : undefined,
        }
      : null
  );
};

const handleRowExpand = (expanded: boolean, user: WeekScheduleUserInterface): void => {
  if (expanded) {
    handleGetKnowledgeDomains(user.id);
    handleGetRoleExtensions(user.id);
    handleGetVacationReports(user.id);
  }
};

const handleExpandedRowsChange = (expandedRows: any): void => {
  expandedRowKeys.value = expandedRows;
};

const showCreateRoleExtensionModal = (userId: number, roleId: number): void => {
  role.value = undefined;
  apiGetUserRole(userId, roleId)
    .then((res: any) => {
      role.value = res.data.data;
      createRoleExtensionModalVisible.value = true;
    })
    .catch(() => $message?.error("Could not fetch role", 3));
};

const handleGetKnowledgeDomains = async (userId: number): Promise<void> => {
  $set?.(loadingKnowledgeDomains.value, userId, true);
  return weekScheduleStore
    .getKnowledgeDomains(userId)
    .then((knowledgeDomains: Array<any>) => {
      $set?.(knowledgeDomains.values, userId, knowledgeDomains);
    })
    .catch(() => $message?.error("Could not fetch knowledge domains", 3))
    .finally(() => {
      $set?.(loadingKnowledgeDomains.value, userId, false);
    });
};

const handleGetRoleExtensions = async (userId: number): Promise<void> => {
  $set?.(loadingRoleExtensions.value, userId, true);
  let extension_statuses = props.filters.extension_statuses;
  return weekScheduleStore
    .getUserRoleExtensions({ userId, extension_statuses })
    .then((extensions: Array<RoleExtensionInterface>) => {
      $set?.(roleExtensions.value, userId, extensions);
    })
    .catch(() => $message?.error("Could not fetch role extensions", 3))
    .finally(() => {
      $set?.(loadingRoleExtensions.value, userId, false);
    });
};

const handleGetVacationReports = async (userId: number): Promise<void> => {
  $set?.(loadingVacationReports.value, userId, true);
  return weekScheduleStore
    .getVacationReports({
      user_id: userId,
      start_date: moment().format("YYYY-MM-DD"),
    })
    .then((reports: ReportInterface) => {
      $set?.(vacationReports.value, userId, reports);
    })
    .catch(() => $message?.error("Could not fetch vacation reports", 3))
    .finally(() => {
      $set?.(loadingVacationReports.value, userId, false);
    });
};

const handleCreateRoleExtension = (
  roleId: number,
  newRoleExtension: RoleExtensionInterface
): void => {
  apiCreateRoleExtension(roleId, newRoleExtension)
    .then(() => $message?.success("Role extension successfully created!", 3))
    .catch(() => $message?.error("Couldn't create role extension!", 3));
};

const onRoleExtensionChange = (userId: number): void => {
  handleGetSchedulePage(props.currentPage, props.pageSize);
  handleGetRoleExtensions(userId);
};

const formatAvailableFromDate = (date?: string): string => {
  if (!date) {
    return "N/A";
  } else if (moment(date).isSameOrBefore(moment.now())) {
    return "Now";
  }
  return moment(date).format("YYYY-MM-DD");
};
</script>
