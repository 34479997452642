<template>
  <a-layout-content class="mx-3 my-4">
    <div class="container">
      <div class="d-flex justify-content-between ml-4">
        <h2>Tags</h2>
        <div v-if="baseAcl.isAdmin">
          <a-button icon="plus" type="primary" @click="addQuizTagModalVisible = true" />
        </div>
      </div>

      <a-row type="flex" justify="center">
        <a-col v-for="quizTag in quizTags" :key="quizTag.id" :span="24" :lg="12">
          <div class="skills-card mb-1 ml-4" style="height: 95%">
            <span class="d-flex justify-content-between">
              <h4>
                <strong>{{ quizTag.name }}</strong>
              </h4>
              <div v-if="baseAcl.isAdmin">
                <a-icon
                  type="edit"
                  class="action-default"
                  @click="() => handleEditQuizTagModalVisible(quizTag)"
                />
                <a-divider type="vertical" />
                <a-icon
                  type="delete"
                  class="action-danger"
                  @click="() => showDeleteQuizTagConfirm(quizTag.id)"
                />
              </div>
            </span>
          </div>
        </a-col>
      </a-row>

      <add-quiz-tag-modal
        :visible="addQuizTagModalVisible"
        @get-quiz-tags="$emit('get-quiz-tags')"
        @store-quiz-tag="(newQuizTag) => handleStoreQuizTag(newQuizTag)"
        @close="addQuizTagModalVisible = false"
      />

      <edit-quiz-tag-modal
        :visible="editQuizTagModalVisible"
        :quiz-tag="quizTagToEdit"
        @update-quiz-tag="(newQuizTag) => handleUpdateQuizTag(newQuizTag)"
        @close="editQuizTagModalVisible = false"
      />
    </div>
  </a-layout-content>
</template>

<script lang="ts">
import { defineComponent, ref, computed, getCurrentInstance, onBeforeMount } from "vue";
import { QuizTagInterface, QuizTagPayloadInterface } from "../types";
import EditQuizTagModal from "../_components/EditQuizTagModal.vue";
import { BaseAclInterface } from "@/modules/authentication/types";
import AddQuizTagModal from "../_components/AddQuizTagModal.vue";
import {
  apiGetQuizTags,
  apiStoreQuizTag,
  apiUpdateQuizTag,
  apiDestroyQuizTag,
} from "../_utils/api";
import { useAuthenticationStore } from "@/modules/authentication/_store";

export default defineComponent({
  components: {
    AddQuizTagModal,
    EditQuizTagModal,
  },
  // Route Guards
  beforeRouteEnter(to: any, from: any, next: any) {
    const authenticationStore = useAuthenticationStore();
    authenticationStore.isAdmin ? next() : next({ name: "dashboard" });
  },
  setup() {
    // Pinia
    const authenticationStore = useAuthenticationStore();
    const baseAcl = computed<BaseAclInterface>(() => authenticationStore.baseAcl);

    // Instances
    const instance = getCurrentInstance();
    const $message = instance?.proxy?.$message;
    const $confirm = instance?.proxy?.$confirm;

    // Data properties
    const addQuizTagModalVisible = ref<boolean>(false);
    const editQuizTagModalVisible = ref<boolean>(false);
    const quizTagToEdit = ref<QuizTagInterface | undefined>(undefined);
    const quizTags = ref<Array<QuizTagInterface>>([]);

    // Lifecycle hooks
    onBeforeMount(() => {
      apiGetQuizTags().then(({ data }: any) => (quizTags.value = data.data));
    });

    // Component methods
    const handleStoreQuizTag = (newQuizTag: QuizTagPayloadInterface): void => {
      apiStoreQuizTag(newQuizTag)
        .then(({ data }: any) => quizTags.value.push(data.data))
        .then(() => $message?.success("Tag successfully created!", 3))
        .catch(() => $message?.error("Couldn't create tag!", 3));
    };

    const handleEditQuizTagModalVisible = (quizTag: QuizTagInterface): void => {
      quizTagToEdit.value = quizTag;
      editQuizTagModalVisible.value = true;
    };

    const handleUpdateQuizTag = (updatedQuizTag: QuizTagPayloadInterface): void => {
      apiUpdateQuizTag(updatedQuizTag)
        .then(
          ({ data }: any) =>
            (quizTags.value = quizTags.value.map((s) => (s.id === data.data.id ? data.data : s)))
        )
        .then(() => $message?.success("Tag successfully updated!", 3))
        .catch(() => $message?.error("Couldn't update tag!", 3));
    };

    const handleDestroyQuizTag = (quizTagId: number): void => {
      apiDestroyQuizTag(quizTagId)
        .then(() => (quizTags.value = quizTags.value.filter((s) => s.id !== quizTagId)))
        .then(() => $message?.success("Tag successfully deleted!", 3))
        .catch(() => $message?.error("Couldn't delete tag!", 3));
    };

    const showDeleteQuizTagConfirm = (quizTagId: number): void => {
      const mod = $confirm?.({
        title: "Are you sure you want to delete this tag?",
        okText: "Yes",
        okType: "danger",
        cancelText: "No",
        onOk() {
          handleDestroyQuizTag(quizTagId);
          mod?.destroy();
        },
      });
    };
    return {
      baseAcl,
      addQuizTagModalVisible,
      quizTags,
      handleEditQuizTagModalVisible,
      showDeleteQuizTagConfirm,
      handleStoreQuizTag,
      editQuizTagModalVisible,
      quizTagToEdit,
      handleUpdateQuizTag,
    };
  },
});
</script>
