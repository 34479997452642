import RequestInvoice from "./_views/RequestInvoice.vue";
import NewInvoice from "./_views/NewInvoice.vue";
import { RouteInterface } from "@/common/types";
import Invoices from "./_views/Invoices.vue";
import Invoice from "./_views/Invoice.vue";

export default [
  {
    path: "projects/:projectId/invoices/new",
    component: NewInvoice,
    name: "new-invoice",
    meta: { auth: true },
  },
  {
    path: "projects/:projectId/invoices/request",
    component: RequestInvoice,
    name: "request-invoice",
    meta: { auth: true },
  },
  {
    path: "projects/:projectId/invoices/:invoiceId",
    component: Invoice,
    name: "invoice",
    meta: { auth: true },
  },
  {
    path: "invoices",
    component: Invoices,
    name: "invoices",
    meta: { auth: true },
  },
] as Array<RouteInterface>;
