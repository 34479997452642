import { defineStore, acceptHMRUpdate } from "pinia";
import { StateInterface, BaseAclInterface, ProfileInterface } from "@/modules/authentication/types";
import { getProfile } from "@/modules/authentication/_utils/api";
import { removeToken } from "@/network";

export const useAuthenticationStore = defineStore("authentication", {
  state: (): StateInterface => {
    return {
      profile: undefined,
      meta: {},
    };
  },
  getters: {
    isAdmin(state: StateInterface): boolean {
      return Boolean(state.profile && state.profile.roles && state.profile.roles.includes("admin"));
    },

    isFinancial(state: StateInterface): boolean {
      return Boolean(
        state.profile && state.profile.roles && state.profile.roles.includes("financial")
      );
    },

    isCR(state: StateInterface): boolean {
      return Boolean(state.profile && state.profile.roles && state.profile.roles.includes("cr"));
    },

    isProjectManager(state: StateInterface): boolean {
      return Boolean(
        state.profile && state.profile.roles && state.profile.roles.includes("project_manager")
      );
    },

    isDefault(state: StateInterface): boolean {
      return Boolean(
        state.profile && state.profile.roles && state.profile.roles.includes("default")
      );
    },

    isHR(state: StateInterface): boolean {
      return Boolean(state.profile && state.profile.roles && state.profile.roles.includes("hr"));
    },

    isInternal(state: StateInterface): boolean {
      return (
        this.isAdmin ||
        this.isFinancial ||
        this.isDefault ||
        this.isCR ||
        this.isProjectManager ||
        this.isHR
      );
    },

    baseAcl(state: StateInterface): BaseAclInterface {
      return {
        isAdmin: this.isAdmin,
        isFinancial: this.isFinancial,
        isDefault: this.isDefault,
        isInternal: this.isInternal,
        isCr: this.isCR,
        isProjectManager: this.isProjectManager,
        isHr: this.isHR,
      };
    },
  },
  actions: {
    getProfile(profileId: number) {
      return new Promise((resolve, reject) => {
        getProfile(profileId)
          .then((res: any) => {
            this.setProfile(res.data.data);
            this.setProfileMeta(res.data.data);
            resolve(res.data.data);
          })
          .catch((err: any) => {
            reject(err);
          });
      });
    },

    logout() {
      return new Promise((resolve, reject) => {
        removeToken();
        resolve(undefined);
      });
    },

    setProfile(profile: ProfileInterface) {
      this.profile = profile;
    },

    setProfileMeta(meta: any) {
      this.meta = meta;
    },
  },
});

// HMR
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuthenticationStore, import.meta.hot));
}
