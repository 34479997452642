<template>
  <a-layout-content class="my-5">
    <div class="container">
      <div class="d-flex justify-content-between">
        <h2>Recurring Payments</h2>

        <span>
          <portal-target class="mr-2" name="recurring-jobs-filter-buttons"></portal-target>
        </span>
      </div>

      <recurring-jobs-filter
        :profile="profile"
        :filters="filtersRef"
        :number-of-results="recurringJobsPagination?.total"
        :types="types"
        @apply-filters="(newFilters) => applyFilters(newFilters)"
      />

      <recurring-jobs-list
        :recurring-jobs="recurringJobsPage"
        :recurring-jobs-pagination="recurringJobsPagination"
        :current-page="currentPage"
        :loading-table="loadingTable"
        :page-size="pageSizeRef"
        @get-recurring-jobs-page="(params) => getRecurringJobsPage(params)"
        @apply-sorting="applySorting"
        @update-recurring-job="handleUpdateRecurringJob"
        @destroy-recurring-job="handleDestroyRecurringJob"
      />
    </div>
  </a-layout-content>
</template>

<script lang="ts">
import { defineComponent, ref, computed, getCurrentInstance, onBeforeMount } from "vue";
import { RecurringJobPayloadInterface, ReccuringJobsFilterInterface } from "../types";
import RecurringJobsFilter from "../_components/RecurringJobsFilter.vue";
import RecurringJobsList from "../_components/RecurringJobsList.vue";
import { useRecurringJobsStore } from "@/modules/recurring_jobs/_store";
import { useAuthenticationStore } from "@/modules/authentication/_store";
import { ProfileInterface } from "../../authentication/types";
import { PaginationInterface } from "@/common/types";
import useMixin from "@/useMixin";

export default defineComponent({
  components: {
    RecurringJobsFilter,
    RecurringJobsList,
  },
  // Route Guards
  // Redirect external users to dashboard view
  beforeRouteEnter(to: any, from: any, next: any) {
    const authenticationStore = useAuthenticationStore();
    authenticationStore.isFinancial || authenticationStore.isAdmin
      ? next()
      : next({ name: "dashboard" });
  },
  setup() {
    // Pinia
    const authenticationStore = useAuthenticationStore();
    const recurringJobsStore = useRecurringJobsStore();
    const profile = computed<ProfileInterface | undefined>(() => authenticationStore.profile);
    const recurringJobsPagination = computed<PaginationInterface | undefined>(
      () => recurringJobsStore.recurring_jobs_pagination
    );

    // Mixins
    const { qsDecode, loadTablePageSize, syncRouteParams, persistTablePageSize } = useMixin();

    // Instances
    const instance = getCurrentInstance();
    const $route = instance?.proxy.$route;
    const $message = instance?.proxy.$message;

    // Data Properties
    const types = ref<Array<string>>(["recurring-transaction", "recurring-expense-report"]);
    const filtersRef = ref<ReccuringJobsFilterInterface>({
      type: ["recurring-transaction", "recurring-expense-report"],
    });
    const recurringJobsPage = ref<Array<any>>([]);
    const loadingTable = ref<boolean>(false);
    const pageSizeRef = ref<number>(25);
    const currentPage = ref<number>(1);
    const sortingRef = ref<any>({});

    // Life-cycle Hooks
    onBeforeMount(() => {
      getRecurringJobsPageFromURL();
    });

    // Component methods
    const getRecurringJobsPageFromURL = (url: any = $route?.query): void => {
      const { page, filters, sorting, pageSize } = qsDecode(url);

      pageSizeRef.value = loadTablePageSize();

      if (page) currentPage.value = parseInt(page);
      if (filters) filtersRef.value = filters;
      if (sorting) sortingRef.value = sorting;
      if (pageSize) pageSizeRef.value = parseInt(pageSize);

      getRecurringJobsPage({ page: currentPage.value });
    };

    const getRecurringJobsPage = ({
      page,
      pageSize = pageSizeRef.value,
      filters = filtersRef.value,
      sorting = sortingRef.value,
    }: {
      page: number;
      pageSize?: number;
      filters?: any;
      sorting?: any;
    }): void => {
      loadingTable.value = true;
      currentPage.value = page;
      pageSizeRef.value = pageSize;

      syncRouteParams(
        {
          page: currentPage.value,
          pageSize: pageSizeRef.value,
          filters: filtersRef.value,
          sorting: sortingRef.value,
        },
        instance
      );
      persistTablePageSize(pageSize);

      recurringJobsStore
        .getRecurringJobsByPage({
          page,
          page_size: pageSizeRef.value,
          ...filters,
          ...sorting,
        })
        .then((results: any) => {
          loadingTable.value = false;
          recurringJobsPage.value = results;
        });
    };

    const applyFilters = (filters: any): void => {
      filtersRef.value = filters;
      currentPage.value = 1;

      syncRouteParams(
        {
          page: currentPage.value,
          pageSize: pageSizeRef.value,
          filters: filtersRef.value,
          sorting: sortingRef.value,
        },
        instance
      );
      getRecurringJobsPage({ page: currentPage.value });
    };

    const applySorting = (sorting: any): void => {
      sortingRef.value = sorting;
    };

    const clearAndGetRecurringJobs = (): void => {
      recurringJobsStore
        .clearRecurringJobs()
        .then(() => getRecurringJobsPage({ page: currentPage.value }));
    };

    const handleUpdateRecurringJob = (recurringJob: RecurringJobPayloadInterface): void => {
      recurringJobsStore
        .updateRecurringJob(recurringJob)
        .then(() => {
          clearAndGetRecurringJobs();
          $message?.success("Recurring job added successfully", 3);
        })
        .catch(() => $message?.error("Couldn't add recurring job!", 3));
    };

    const handleDestroyRecurringJob = (recurringJobId: number): void => {
      recurringJobsStore
        .destroyRecurringJob(recurringJobId)
        .then(() => {
          clearAndGetRecurringJobs();
          $message?.success("Recurring job was deleted successfully", 3);
        })
        .catch(() => $message?.error("Couldn't delete recurring job!", 3));
    };
    return {
      profile,
      filtersRef,
      recurringJobsPagination,
      types,
      applyFilters,
      recurringJobsPage,
      currentPage,
      loadingTable,
      pageSizeRef,
      getRecurringJobsPage,
      applySorting,
      handleUpdateRecurringJob,
      handleDestroyRecurringJob,
    };
  },
});
</script>
