<template>
  <a-layout-content class="m-5">
    <div class="container">
      <a-row type="flex" justify="space-between" class="mb-2">
        <h2>Cost Centers</h2>
        <a-button type="primary" @click="addCostCenterModalVisible = true">
          Add Cost center
        </a-button>
      </a-row>

      <a-row :gutter="16" type="flex">
        <a-col v-for="costCenter in costCenters" :key="costCenter.id" :span="12" class="mb-4">
          <a-card>
            <a-row :gutter="16" type="flex">
              <a-col :span="22">
                <h3>{{ costCenter.name }}</h3>
              </a-col>

              <a-col :span="2">
                <a-avatar :src="costCenter.manager.gravatar" size="small" />
              </a-col>
            </a-row>
            <p>{{ costCenter.description }}</p>

            <a-row type="flex" justify="space-between">
              <div class="d-flex align-items-center">
                <a-icon type="dollar" />
                <p class="my-0 ml-1 mr-2">Billable:</p>
                <a-tag v-if="costCenter.billable" :color="Colors.green">TRUE</a-tag>
                <a-tag v-else :color="Colors.red">FALSE</a-tag>
              </div>
              <div v-if="costCenter.use_cases?.length" class="d-flex align-items-center">
                <a-icon type="filter" />
                <p class="my-0 ml-1 mr-2">Use cases:</p>
                <a-tag
                  v-for="use_case in costCenter.use_cases"
                  :key="use_case"
                  :color="Colors.blue"
                  >{{ costCenterUseCases[use_case] }}</a-tag
                >
              </div>
              <div class="d-flex align-items-center">
                <a-icon
                  type="edit"
                  class="mr-2 action-default"
                  @click="showEditCostCenterModal(costCenter)"
                />
              </div>
            </a-row>
          </a-card>
        </a-col>
      </a-row>

      <add-cost-center-modal
        :visible="addCostCenterModalVisible"
        :users="usersCompactList"
        @close="addCostCenterModalVisible = false"
        @store-cost-center="handleStoreCostCenter"
      />

      <edit-cost-center-modal
        :visible="editCostCenterModalVisible"
        :users="usersCompactList"
        :cost-center="editCostCenter"
        @close="editCostCenterModalVisible = false"
        @update-cost-center="handleEditCostCenter"
      />
    </div>
  </a-layout-content>
</template>

<script setup lang="ts">
import Colors from "@/assets/scss/_colors.module.scss";
import { ref, computed, onMounted, getCurrentInstance } from "vue";
import { CostCenterInterface, CostCenterPayloadInterface, costCenterUseCases } from "../types";
import EditCostCenterModal from "../_components/EditCostCenterModal.vue";
import AddCostCenterModal from "../_components/AddCostCenterModal.vue";
import { useCostCentersStore } from "@/modules/cost_centers/_store";
import { MinimalUserInterface } from "../../users/types";
import { getUsersCompactList } from "@/api";

// Pinia
const costCentersStore = useCostCentersStore();
const costCenters = computed<Array<CostCenterInterface>>(() => costCentersStore.cost_centers);

// Data properties
const addCostCenterModalVisible = ref<boolean>(false);
const editCostCenterModalVisible = ref<boolean>(false);
const usersCompactList = ref<Array<MinimalUserInterface>>([]);
const editCostCenter = ref<CostCenterInterface | undefined>(undefined);

const instance = getCurrentInstance();
const $message = instance?.proxy.$message;

// Lifecycle hooks
onMounted(() => {
  costCentersStore.getCostCenters();
  getUsersCompactList().then((users) => (usersCompactList.value = users));
});

// Component methods
const handleStoreCostCenter = (newCostCenter: CostCenterPayloadInterface) => {
  costCentersStore
    .storeCostCenter(newCostCenter)
    .then(() => $message?.success("Cost center added successfully!", 3))
    .catch(() => $message?.error("Couldn't add cost center!", 3));
};

const handleEditCostCenter = (updatedCostCenter: CostCenterPayloadInterface) => {
  costCentersStore
    .updateCostCenter(updatedCostCenter)
    .then(() => $message?.success("Cost center updated successfully!", 3))
    .catch(() => $message?.error("Couldn't update cost center!", 3));
};

const showEditCostCenterModal = (costCenter: CostCenterInterface) => {
  editCostCenter.value = costCenter;
  editCostCenterModalVisible.value = true;
};
</script>
