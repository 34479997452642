import ConsultantProfiles from "./_views/ConsultantProfiles";

export default [
  {
    path: "consultant-profiles",
    component: ConsultantProfiles,
    name: "consultant-profiles",
    meta: { auth: true },
  },
];
