import { SkillCategoryPayloadInterface, SkillPayloadInterface } from "../types";
import axios from "@/network";

export function apiGetSkills() {
  const url = "/skills";
  return axios.get(url);
}

export function apiStoreSkill(newSkill: SkillPayloadInterface) {
  const url = "/skills";
  const payload = { skill: newSkill };
  return axios.post(url, payload);
}

export function apiUpdateSkill(updatedSkill: SkillPayloadInterface) {
  const url = `/skills/${updatedSkill.id}`;
  const payload = { skill: updatedSkill };
  return axios.patch(url, payload);
}

export function apiDestroySkill(skillId: number) {
  const url = `/skills/${skillId}`;
  return axios.delete(url);
}

export function apiGetSkillUsers(skillId: number) {
  const url = `/skills/${skillId}/users`;
  return axios.get(url);
}

export function apiGetUserSkills(userId: number) {
  const url = `/users/${userId}/skills`;
  return axios.get(url);
}

export function apiStoreUserSkill(userId: number, newUserSkill: any) {
  const url = `/users/${userId}/skills`;
  const payload = { skill_user: newUserSkill };
  return axios.post(url, payload);
}

export function apiUpdateUserSkill(userId: number, userSkillId: number, updatedUserSkill: any) {
  const url = `/users/${userId}/skills/${userSkillId}`;
  const payload = { skill_user: updatedUserSkill };
  return axios.patch(url, payload);
}

export function apiDestroyUserSkill(userId: number, userSkillId: number) {
  const url = `/users/${userId}/skills/${userSkillId}`;
  return axios.delete(url);
}

export function apiGetSkillCategories() {
  const url = "/skill_categories";
  return axios.get(url);
}

export function apiStoreSkillCategory(newSkillCategory: SkillCategoryPayloadInterface) {
  const url = "/skill_categories";
  const payload = { skill_category: newSkillCategory };
  return axios.post(url, payload);
}

export function apiUpdateSkillCategory(updatedSkillCategory: SkillCategoryPayloadInterface) {
  const url = `/skill_categories/${updatedSkillCategory.id}`;
  const payload = { skill_category: updatedSkillCategory };
  return axios.patch(url, payload);
}

export function apiDestroySkillCategory(skillCategoryId: number) {
  const url = `/skill_categories/${skillCategoryId}`;
  return axios.delete(url);
}
