import { TableColumnInterface } from "@/common/types";

export const detailedTransactionColumns: Array<TableColumnInterface> = [
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    scopedSlots: { customRender: "amount" },
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    scopedSlots: { customRender: "status" },
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    scopedSlots: { customRender: "type" },
  },
  {
    title: "Made at",
    dataIndex: "made_at",
    sorter: true,
    key: "made_at",
    scopedSlots: { customRender: "made_at" },
  },
];

export const transactionsColumns: Array<TableColumnInterface> = [
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    scopedSlots: { customRender: "amount" },
  },
  {
    title: "User",
    dataIndex: "user",
    key: "user",
    scopedSlots: { customRender: "user" },
  },
  {
    title: "Made at",
    dataIndex: "made_at",
    sorter: true,
    key: "made_at",
    scopedSlots: { customRender: "made_at" },
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    scopedSlots: { customRender: "type" },
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    scopedSlots: { customRender: "status" },
  },
];
