<template>
  <!-- USERS FILTER -->
  <div>
    <div v-if="filterPanelVisible" class="card mb-3">
      <a-form layout="vertical">
        <bookmarked-filters-selection
          :bookmarked-filters="bookmarkedFilters"
          :filter-is-empty="filterIsEmpty"
          bookmark-type="Users"
          class="ml-3"
          @selected="(bookmarkFilterUrl) => handleCustomFilterSelected(bookmarkFilterUrl)"
          @create-bookmark-filter="handleCreateBookmarkFilter"
          @update-bookmark-filter="handleUpdateBookmarkFilter"
          @delete-bookmark-filter="handleDeleteBookmarkFilter"
        />
        <a-row>
          <a-col :lg="11" class="ml-3">
            <SkNameSelect
              v-model="newFilter.user_id"
              label="Users"
              :label-col-size="10"
              mode="multiple"
              placeholder="Select users"
              :allow-clear="true"
              :options="usersCompactList"
              @change="applyFilters"
            />

            <SkNameSelect
              v-model="newFilter.skill_id"
              label="Skills"
              :label-col-size="10"
              mode="multiple"
              placeholder="Select skills"
              :allow-clear="true"
              :options="skillsCompactList"
              option-attribute-name="title"
              @change="applyFilters"
            />

            <SkNameSelect
              v-model="newFilter.team_id"
              label="Teams"
              :label-col-size="10"
              mode="multiple"
              placeholder="Select teams"
              :allow-clear="true"
              :options="teamsCompactList"
              @change="applyFilters"
            />

            <a-form-item :label-col="{ span: 10 }" :wrapper-col="{ span: 16 }" label="User role">
              <a-select
                v-model="newFilter.user_role_id"
                mode="multiple"
                show-search
                placeholder="Select user role"
                option-filter-prop="children"
                @change="applyFilters"
              >
                <a-select-option
                  v-for="role in userPermissions"
                  :key="role.id.toString()"
                  class="text-capitalize"
                >
                  {{ role.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :lg="11" class="ml-3">
            <a-form-item
              :label-col="{ span: 10 }"
              :wrapper-col="{ span: 16 }"
              label="Lock date before"
            >
              <a-date-picker
                :value="parseDate(newFilter.report_locking_date_before)"
                :disabled-date="(date) => disabledDate(date)"
                placeholder="Last lock date before"
                class="w-100"
                @change="(date) => onDateChange('report_locking_date_before', date)"
              />
            </a-form-item>

            <a-form-item
              :label-col="{ span: 10 }"
              :wrapper-col="{ span: 16 }"
              label="Lock date after"
            >
              <a-date-picker
                :value="parseDate(newFilter.report_locking_date_after)"
                :disabled-date="(date) => disabledDate(date)"
                placeholder="Last lock date after"
                class="w-100"
                @change="(date) => onDateChange('report_locking_date_after', date)"
              />
            </a-form-item>

            <a-form-item
              :label-col="{ span: 10 }"
              :wrapper-col="{ span: 16 }"
              label="Profile description status"
            >
              <a-select
                v-model="newFilter.profile_description_status"
                allow-clear
                placeholder="Select profile description status"
                @change="() => applyFilters()"
              >
                <a-select-option :value="ProfileDescriptionStatus.MISSING">
                  <a-tag :color="Colors.orange">Missing</a-tag>
                </a-select-option>
                <a-select-option :value="ProfileDescriptionStatus.PENDING">
                  <a-tag :color="Colors.yellow">Pending</a-tag>
                </a-select-option>
                <a-select-option :value="ProfileDescriptionStatus.PRESENT">
                  <a-tag :color="Colors.green">Present</a-tag>
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>

      <a-form>
        <a-row>
          <a-col :lg="11" class="ml-3">
            <a-form-item v-if="acl.isAdmin || acl.isFinancial" label="Status">
              <a-checkbox-group
                :value="newFilter.employment_status"
                @change="(value) => onCheckboxChange('employment_status', value)"
              >
                <a-checkbox value="active">Active</a-checkbox>
                <a-checkbox value="inactive">Inactive</a-checkbox>
              </a-checkbox-group>
            </a-form-item>
          </a-col>

          <a-col :lg="11" class="ml-3">
            <a-form-item v-if="acl.isAdmin || acl.isFinancial" label="Employment type">
              <a-checkbox-group
                :value="newFilter.employment_type"
                @change="(value) => onCheckboxChange('employment_type', value)"
              >
                <a-checkbox value="fixed">Fixed Salary</a-checkbox>
                <a-checkbox value="variable">Variable Salary</a-checkbox>
                <a-checkbox value="hourly">Hourly Salary</a-checkbox>
                <a-checkbox value="subcontractor">Subcontractor</a-checkbox>
              </a-checkbox-group>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <span class="d-flex justify-content-end">
        <span>
          <strong>Results:</strong>
          {{ numberOfResults ? numberOfResults : "0" }}
        </span>
      </span>
    </div>
    <portal to="users-filter-buttons">
      <a-button
        v-if="filterPanelVisible"
        class="mr-2"
        icon="close"
        type="danger"
        @click="() => clearFilters()"
      />
      <a-button
        v-show="filterPanelVisible"
        class="mr-2"
        type="primary"
        icon="up"
        @click="() => toggleFilterPanel()"
      />
      <a-button
        v-if="!filterPanelVisible"
        class="mr-2"
        type="primary"
        icon="filter"
        @click="() => toggleFilterPanel()"
      />
    </portal>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, getCurrentInstance } from "vue";
import BookmarkedFiltersSelection from "../../bookmarked_filters/_components/BookmarkedFiltersSelection.vue";
import { ProfileInterface, BaseAclInterface } from "@/modules/authentication/types";
import { BookmarkedFilterInterface } from "@/modules/bookmarked_filters/types";
import { MinimalUserInterface, UsersFilterInterface } from "../types";
import { apiGetTeams } from "@/modules/teams/_utils/api";
import Colors from "@/assets/scss/_colors.module.scss";
import { apiGetSkills } from "../../skills/_utils/api";
import { apiGetUserPermissions } from "../_utils/api";
import { ProfileDescriptionStatus } from "../types";
import { SkillInterface } from "../../skills/types";
import { TeamInterface } from "../../teams/types";
import { getUsersCompactList } from "@/api";
import useMixin from "@/useMixin";
import moment from "@/date";
import {
  apiGetUserBookmarkedFilters,
  apiStoreUserBookmarkedFilter,
  apiUpdateUserBookmarkedFilter,
  apiDestroyUserBookmarkedFilter,
} from "@/modules/bookmarked_filters/_utils/api";
import useFilters from "@/useFilters";

// Props
const props = defineProps({
  filters: { type: Object as () => UsersFilterInterface, default: undefined },
  profile: { type: Object as () => ProfileInterface, default: undefined },
  acl: { type: Object as () => BaseAclInterface, default: undefined },
  numberOfResults: { type: Number, default: undefined },
  visible: { type: Boolean, default: false },
});

// Emits
const emits = defineEmits(["apply-filters"]);

// Mixins
const { qsDecode, setObject } = useMixin();

// Filters
const { parseDate } = useFilters();

// Instances
const instance = getCurrentInstance();
const $message = instance?.proxy.$message;

// Data Properties
const bookmarkedFilters = ref<Array<BookmarkedFilterInterface>>([]);
const usersCompactList = ref<Array<MinimalUserInterface>>([]);
const skillsCompactList = ref<Array<SkillInterface>>([]);
const teamsCompactList = ref<Array<TeamInterface>>([]);
const filterPanelVisible = ref<boolean>(false);
const userPermissions = ref<Array<any>>([]);
const newFilter = ref<UsersFilterInterface>({
  user_id: [],
  employment_status: [],
  employment_type: [],
  skill_id: [],
  profile_description_status: undefined,
  team_id: [],
  user_role_id: [],
  report_locking_date_before: undefined,
  report_locking_date_after: undefined,
});

// Computed Properties
const filterIsEmpty = computed(() => {
  return (
    newFilter.value.user_id &&
    newFilter.value.employment_status &&
    newFilter.value.employment_type &&
    newFilter.value.skill_id &&
    newFilter.value.team_id &&
    newFilter.value.user_role_id &&
    newFilter.value.user_id.length === 0 &&
    newFilter.value.employment_status.length === 0 &&
    newFilter.value.employment_type.length === 0 &&
    newFilter.value.skill_id.length === 0 &&
    newFilter.value.team_id.length === 0 &&
    newFilter.value.user_role_id.length === 0 &&
    newFilter.value.profile_description_status === undefined &&
    newFilter.value.report_locking_date_before === undefined &&
    newFilter.value.report_locking_date_after === undefined
  );
});

// Watchers
watch(filterPanelVisible, (newProp, oldProp) => {
  if (newProp && !oldProp) {
    handleGetTeams();
    handleGetSkills();
    handleGetUserPermissions();
    handleGetUsersCompactList();
    handleGetBookmarkedFilters();
    setFilter(props.filters);
  }
});

// Component Methods
const toggleFilterPanel = () => {
  filterPanelVisible.value = !filterPanelVisible.value;
};

const handleGetUsersCompactList = (): void => {
  getUsersCompactList().then((users) => {
    usersCompactList.value = users;
  });
};

const handleGetTeams = () => {
  apiGetTeams().then(({ data }: { data: any }) => {
    teamsCompactList.value = data.data;
  });
};

const handleGetUserPermissions = (): void => {
  apiGetUserPermissions().then((res: any) => (userPermissions.value = res.data.data));
};

const handleGetSkills = (): void => {
  apiGetSkills().then((res: any) => {
    skillsCompactList.value = res.data.data;
  });
};

const handleCustomFilterSelected = (bookmarkFilterUrl: any): void => {
  handleClearUsersFilter();
  if (bookmarkFilterUrl) {
    emits("apply-filters", qsDecode(bookmarkFilterUrl).filters);
  } else {
    applyFilters();
  }
};

const onCheckboxChange = (field: string, value: any): void => {
  newFilter.value[field] = value;
  applyFilters();
};

const applyFilters = (): void => {
  emits("apply-filters", filterIsEmpty.value ? {} : newFilter.value);
};

const clearFilters = (): void => {
  handleClearUsersFilter();
  emits("apply-filters", {});
};

const disabledDate = (current: any): any => {
  return current && current > moment().endOf("day");
};

const onDateChange = (field: string, value: any): void => {
  newFilter.value[field] = value ? value.format("YYYY-MM-DD") : undefined;
  applyFilters();
};

const handleClearUsersFilter = (): void => {
  newFilter.value = {
    user_id: [],
    employment_status: [],
    employment_type: [],
    skill_id: [],
    profile_description_status: undefined,
    team_id: [],
    user_role_id: [],
    report_locking_date_before: undefined,
    report_locking_date_after: undefined,
  };
};

const setFilter = (filter: any): void => {
  newFilter.value = setObject(newFilter.value, filter);
};

const handleGetBookmarkedFilters = (): void => {
  apiGetUserBookmarkedFilters(props.profile.id).then(
    ({ data }: any) => (bookmarkedFilters.value = data.data)
  );
};

const handleCreateBookmarkFilter = (bookmarkFilter: any): void => {
  apiStoreUserBookmarkedFilter(props.profile.id, bookmarkFilter)
    .then(({ data }: any) => bookmarkedFilters.value.push(data.data))
    .then(() => $message?.success("New filter created successfully!", 3))
    .catch(() => $message?.error("Couldn't create new filter!", 3));
};

const handleUpdateBookmarkFilter = (bookmarkFilterId: number, bookmarkFilter: any): void => {
  apiUpdateUserBookmarkedFilter(props.profile.id, bookmarkFilterId, bookmarkFilter)
    .then(
      ({ data }: any) =>
        (bookmarkedFilters.value = bookmarkedFilters.value.map((f: any) =>
          f.id === data.data.id ? data.data : f
        ))
    )
    .then(() => $message?.success("Filter updated successfully!", 3))
    .catch(() => $message?.error("Couldn't update filter!", 3));
};

const handleDeleteBookmarkFilter = (bookmarkFilterId: number) => {
  apiDestroyUserBookmarkedFilter(props.profile.id, bookmarkFilterId)
    .then(
      () =>
        (bookmarkedFilters.value = bookmarkedFilters.value.filter(
          (f: any) => f.id !== bookmarkFilterId
        ))
    )
    .then(() => $message?.success("Filter removed successfully!", 3))
    .catch(() => $message?.error("Couldn't remove filter!", 3));
};
</script>
