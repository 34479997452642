import { RouteInterface } from "@/common/types";
import Teams from "./_views/Teams.vue";
import Team from "./_views/Team.vue";

export default [
  {
    path: "teams/:id",
    component: Team,
    name: "team",
    meta: { auth: true },
  },
  {
    path: "teams",
    component: Teams,
    name: "teams",
    meta: { auth: true },
  },
] as Array<RouteInterface>;
