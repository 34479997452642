<template>
  <a-modal
    :visible="visible"
    :destroy-on-close="true"
    title="Add Skill"
    ok-text="Save"
    @ok="handleStoreUserSkill"
    @cancel="$emit('close')"
  >
    <a-form layout="horizontal">
      <a-form-item
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
        :validate-status="$validator.errors.first('skill') ? 'error' : ''"
        :help="$validator.errors.first('skill')"
        label="Skill"
      >
        <a-select
          v-model="newUserSkill.skill_id"
          v-validate="'required'"
          placeholder="Select a skill"
          show-search
          option-filter-prop="children"
          data-vv-name="skill"
        >
          <a-select-option
            v-for="s in skills"
            :key="s.id"
            :value="s.id"
            :class="{ 'already-in': !!userSkills.some((skill) => skill.skill.id === s.id) }"
          >
            {{ s.title }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
        :validate-status="$validator.errors.first('level') ? 'error' : ''"
        :help="$validator.errors.first('level')"
        label="Level"
      >
        <a-select
          v-model="newUserSkill.level"
          v-validate="'required'"
          placeholder="Select a level"
          data-vv-name="level"
        >
          <a-select-option value="0">I don't know it</a-select-option>
          <a-select-option value="1">I have basic knowledge of it</a-select-option>
          <a-select-option value="2">I have used it in few situations</a-select-option>
          <a-select-option value="3">I have extensively used it</a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" label="Growth">
        <a-checkbox v-model="newUserSkill.grow">I want to learn more</a-checkbox>
      </a-form-item>

      <a-form-item :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" label="Passion">
        <a-checkbox v-model="newUserSkill.preferred">I enjoy to work with this</a-checkbox>
      </a-form-item>
    </a-form>
    <p>
      * The skills in highlighted in
      <span class="red-text-bold">red</span> are already part of a user skills.
    </p>
  </a-modal>
</template>

<script setup lang="ts">
import { getCurrentInstance, ref, watch } from "vue";
import { SkillInterface } from "../../skills/types";
import useMixin from "@/useMixin";

// Props
const props = defineProps({
  skills: { type: Array as () => Array<SkillInterface>, default: () => [] },
  userSkills: { type: Array as () => Array<any>, default: () => [] },
  visible: { type: Boolean, default: false },
});

// Emits
const emits = defineEmits(["get-skills", "store-user-skill", "close"]);

// Mixins
const { setObject } = useMixin();

// Instances
const instance = getCurrentInstance();
const $validator = instance?.proxy.$validator;

// Data Properties
const newUserSkill = ref<any>({
  skill_id: undefined,
  level: undefined,
  grow: undefined,
  preferred: undefined,
});

// Watchers
watch(
  () => props.visible,
  (newProp, oldProp) => {
    if (newProp && !oldProp) {
      // Set fields to profile current values
      newUserSkill.value = setObject(newUserSkill.value, undefined);
      newUserSkill.value.grow = false;
      newUserSkill.value.preferred = false;

      emits("get-skills");
    }
  }
);

// Component methods
const handleStoreUserSkill = (): void => {
  $validator?.validateAll().then((result) => {
    if (result) {
      newUserSkill.value.skill_id = parseInt(newUserSkill.value.skill_id);
      emits("store-user-skill", newUserSkill.value);
      emits("close");
    }
  });
};
</script>
