<template>
  <a-modal
    :visible="visible"
    :destroy-on-close="true"
    title="Add Question"
    ok-text="Add"
    @ok="() => handleStoreQuestion()"
    @cancel="$emit('close')"
  >
    <a-form layout="horizontal">
      <SkInput
        v-model="newQuestion.title"
        v-validate="'required|max:255'"
        :error="$validator.errors.first('title')"
        data-vv-name="title"
        label="Title"
        placeholder="Title"
      />

      <SkTextarea
        v-model="newQuestion.question"
        v-validate="'required'"
        :error="$validator.errors.first('question')"
        :auto-size="{ minRows: 2 }"
        data-vv-name="question"
        label="Question"
        placeholder="Question"
      />

      <SkInput
        v-model="newQuestion.answer"
        v-validate="'required'"
        :error="$validator.errors.first('answer')"
        data-vv-name="answer"
        label="Answer"
        placeholder="Answer"
      />

      <a-form-item :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }" label="Onboarding">
        <a-switch v-model="newQuestion.onboarding"></a-switch>
      </a-form-item>

      <SkSelect
        v-model="newQuestion.quiz_tag_ids"
        :options="quizTags"
        label="Tags"
        mode="multiple"
        placeholder="Please select tags"
        display-prop="name"
      />
    </a-form>
  </a-modal>
</template>

<script setup lang="ts">
import { ref, watch, toRef, getCurrentInstance } from "vue";
import { QuizTagInterface } from "@/modules/quiz_tags/types";
import { QuestionPayloadInterface } from "../types";
import useMixin from "@/useMixin";

// Props
const props = defineProps({
  visible: { type: Boolean, default: false },
  quizTags: { type: Array as () => Array<QuizTagInterface>, default: () => [] },
});

// Emits
const emits = defineEmits(["get-quiz-tags", "store-question", "close"]);

// Mixins
const { setObject } = useMixin();

// Instances
const instance = getCurrentInstance();
const $validator = instance?.proxy.$validator;

// Data properties
const newQuestion = ref<QuestionPayloadInterface>({
  title: undefined,
  question: undefined,
  answer: undefined,
  quiz_tag_ids: [],
  onboarding: false,
});

// Watchers
watch(toRef(props, "visible"), (newProp, oldProp) => {
  if (newProp && !oldProp) {
    emits("get-quiz-tags");

    // Initialize all fields
    newQuestion.value = setObject(newQuestion.value, undefined);
    newQuestion.value.quiz_tag_ids = [];
    newQuestion.value.onboarding = false;
  }
});

// Class methods
const handleStoreQuestion = (): void => {
  $validator?.validateAll().then((result) => {
    if (result) {
      emits("store-question", newQuestion.value);
      emits("close");
    }
  });
};
</script>
