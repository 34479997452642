<template>
  <a-modal
    :visible="visible"
    :destroy-on-close="true"
    title="Edit Skill"
    ok-text="Save"
    @ok="() => handleUpdateSkillCategory()"
    @cancel="() => $emit('close')"
  >
    <a-form layout="horizontal">
      <SkInput
        v-model="updatedSkillCategory.title"
        v-validate="'required|max:255'"
        :error="$validator.errors.first('title')"
        data-vv-name="title"
        label="Title"
        placeholder="Skill title"
      />
      <SkNameSelect
        v-model="selectedSkillIds"
        label="Category skills"
        mode="multiple"
        placeholder="Select skills for category"
        :options="props.skills"
        option-attribute-name="title"
        @change="handleUpdateSkills"
      />
    </a-form>
  </a-modal>
</template>

<script setup lang="ts">
import { getCurrentInstance, ref, watch } from "vue";
import { SkillCategoryPayloadInterface, SkillInterface } from "../types";
import useMixin from "@/useMixin";

// Props
const props = defineProps({
  visible: { type: Boolean, default: false },
  skillCategory: { type: Object as () => SkillCategoryPayloadInterface, default: undefined },
  skills: { type: Array as () => SkillInterface[], default: undefined },
});

// Emits
const emits = defineEmits(["update-skill-category", "close"]);

// Mixins
const { setObject } = useMixin();

// Instances
const instance = getCurrentInstance();
const $validator = instance?.proxy.$validator;

// Data properties
const updatedSkillCategory = ref<SkillCategoryPayloadInterface>({
  id: undefined,
  title: undefined,
  skills: [],
});
const selectedSkillIds = ref<Array<number>>(
  props.skillCategory?.skills?.map((sk) => sk.skill_id) ?? []
);

// Watchers
watch(
  () => props.visible,
  (newProp, oldProp) => {
    if (newProp && !oldProp) {
      updatedSkillCategory.value = setObject(updatedSkillCategory.value, props.skillCategory);
      selectedSkillIds.value = props.skillCategory.skills?.map((sk) => sk.skill_id) ?? [];
    }
  }
);

// Class methods
const handleUpdateSkillCategory = (): void => {
  $validator?.validateAll().then((result) => {
    if (result) {
      emits("update-skill-category", updatedSkillCategory.value);
      emits("close");
    }
  });
};

const handleUpdateSkills = (): void => {
  updatedSkillCategory.value.skills = selectedSkillIds.value.map((sk) => ({ skill_id: sk }));
};
</script>
