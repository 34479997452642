<template>
  <a-modal
    :visible="visible"
    :confirm-loading="loading"
    :width="600"
    title="Create CV"
    @cancel="() => $emit('close')"
  >
    <a-row v-if="loadingData" type="flex" justify="center">
      <a-icon type="loading" />
    </a-row>

    <div v-else>
      <a-steps
        :current="currentStep"
        size="small"
        direction="horizontal"
        label-placement="vertical"
        class="mb-2"
      >
        <a-step title="General">
          <template #icon>
            <a-icon type="form" />
          </template>
        </a-step>
        <a-step title="Knowledge Domains">
          <template #icon>
            <a-icon type="file-text" />
          </template>
        </a-step>
        <a-step title="Skill Categories">
          <template #icon>
            <a-icon type="container" />
          </template>
        </a-step>
        <a-step title="Skills">
          <template #icon>
            <a-icon type="tool" />
          </template>
        </a-step>
        <a-step title="Projects">
          <template #icon>
            <a-icon type="rocket" />
          </template>
        </a-step>
        <a-step title="Educations">
          <template #icon>
            <a-icon type="bank" />
          </template>
        </a-step>
        <a-step title="Certifications / Courses">
          <template #icon>
            <a-icon type="book" />
          </template>
        </a-step>
      </a-steps>

      <GeneralStep
        v-if="currentStep === steps.general"
        :cv="newCv"
        :step="steps.general"
        :cv-data="cvData"
        @cv-change="handleChange"
        @go-to-step="goToStep"
      />
      <KnowledgeDomainStep
        v-if="currentStep === steps.knowledge"
        :cv="newCv"
        :step="steps.knowledge"
        :cv-data="cvData"
        @cv-change="handleChange"
        @go-to-step="goToStep"
      />
      <SkillCategoryStep
        v-if="currentStep === steps.skillCategory"
        :cv="newCv"
        :step="steps.skillCategory"
        :cv-data="cvData"
        @cv-change="handleChange"
        @go-to-step="goToStep"
      />
      <SkillStep
        v-if="currentStep === steps.skill"
        :cv="newCv"
        :step="steps.skill"
        :cv-data="cvData"
        @cv-change="handleChange"
        @go-to-step="goToStep"
      />
      <ProjectStep
        v-if="currentStep === steps.project"
        :cv="newCv"
        :step="steps.project"
        :cv-data="cvData"
        @cv-change="handleChange"
        @go-to-step="goToStep"
      />
      <EducationStep
        v-if="currentStep === steps.education"
        :cv="newCv"
        :step="steps.education"
        :cv-data="cvData"
        @cv-change="handleChange"
        @go-to-step="goToStep"
      />
      <CourseStep
        v-if="currentStep === steps.course"
        :cv="newCv"
        :step="steps.course"
        :cv-data="cvData"
        @cv-change="handleChange"
        @go-to-step="goToStep"
      />
    </div>

    <template #footer>
      <a-row type="flex" justify="space-between">
        <a-col>
          <a-button @click="() => $emit('close')">Cancel</a-button>
        </a-col>

        <a-col>
          <a-button
            v-if="currentStep !== steps.general"
            :class="valid ? '' : 'ant-btn-danger'"
            @click="goToStep(currentStep - 1)"
          >
            Previous
          </a-button>
          <a-button
            v-if="currentStep !== steps.course"
            :class="valid ? '' : 'ant-btn-danger'"
            @click="validate(currentStep + 1)"
          >
            Next
          </a-button>
          <a-button
            v-else-if="currentStep === steps.course"
            :class="valid ? '' : 'ant-btn-danger'"
            @click="previewCv"
          >
            Preview
          </a-button>
          <a-button
            v-if="currentStep === steps.course && canManageCv"
            :class="valid ? '' : 'ant-btn-danger'"
            @click="addCv"
          >
            Save
          </a-button>
        </a-col>
      </a-row>
    </template>
  </a-modal>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import GeneralStep from "./GeneralStep.vue";
import KnowledgeDomainStep from "./KnowledgeDomainStep.vue";
import SkillStep from "./SkillStep.vue";
import ProjectStep from "./ProjectStep.vue";
import CourseStep from "./CourseStep.vue";
import EducationStep from "./EducationStep.vue";
import { CvInterface, CvDataInterface } from "../types";
import { emitter } from "@/mitt";
import SkillCategoryStep from "@/modules/cv/_components/SkillCategoryStep.vue";

// Props
const props = defineProps({
  cv: { type: Object as () => CvInterface, default: () => ({}) },
  cvData: { type: Object as () => CvDataInterface, default: () => ({}) },
  visible: { type: Boolean, default: false },
  loading: { type: Boolean, default: false },
  loadingData: { type: Boolean, default: false },
  canManageCv: { type: Boolean, default: false },
});

// Emits
const emits = defineEmits(["update-cv", "update-preview-cv"]);

// Data Properties
const currentStep = ref<number>(0);
const newCv = ref<any>({
  id: 0,
  first_title: "",
  second_title: "",
  location: "",
  background: "",
  motivation: "",
  characteristics: [],
  knowledge_domains: [],
  skill_categories: [],
  skills: [],
  roles: [],
  work_experiences: [],
  courses: [],
  contact_email: "collaboration@skry.se",
  contact_phone: "",
  educations: [],
});
const steps = ref<any>({
  general: 0,
  knowledge: 1,
  skillCategory: 2,
  skill: 3,
  project: 4,
  education: 5,
  course: 6,
});
const valid = ref<boolean>(true);

// Life-cycle Hooks
onMounted(() => {
  newCv.value.id = props.cv.id;
});

// Component methods
const handleChange = (key: string, value: any): void => {
  newCv.value[key] = value;
};

const validate = (nextStep: number): void => {
  valid.value = false;

  emitter.emit(`validate-cv-step-${currentStep.value}`, nextStep);
};

const goToStep = (step: number): void => {
  valid.value = true;
  currentStep.value = step;
};

const addCv = (): void => {
  emits("update-cv", newCv.value);
};

const previewCv = (): void => {
  emits("update-preview-cv", newCv.value);
};
</script>
