<template>
  <a-modal
    :visible="visible"
    :destroy-on-close="true"
    :width="500"
    title="Save Days"
    @cancel="() => closeModal()"
  >
    <a-row>
      <h4>Days left this year: {{ props.daysLeft }}</h4>
    </a-row>
    <a-row>
      <h4>Days that you can save: {{ maxDaysToSave }}</h4>
    </a-row>

    <a-row justify="center">
      <a-col :span="8">
        <h4>Choose an amount</h4>
      </a-col>
      <a-col>
        <a-select @change="handleChange">
          <a-select-option v-for="option in options" :key="option.index" :value="option">{{
            option
          }}</a-select-option>
        </a-select>
      </a-col>
    </a-row>
    <template #footer>
      <div class="d-flex justify-content-between">
        <div>
          <a-button type="primary" @click="saveAndCloseModal">Save</a-button>
        </div>
      </div>
    </template>
  </a-modal>
</template>

<script setup lang="ts">
import { ref, watch, getCurrentInstance, toRefs } from "vue";
import { ProfileInterface } from "@/modules/authentication/types";
import { apiUpdateUserSavedVacationDays } from "@/modules/users/_utils/api";

// Props
const props = defineProps({
  visible: { type: Boolean, default: false },
  user: { type: Object as () => ProfileInterface, default: undefined },
  daysLeft: { type: Number, default: null },
  maxDaysToSave: { type: Number, default: null },
});

const { maxDaysToSave } = toRefs(props);

// Emits
const emits = defineEmits(["close", ref<Number>]);

// Instances
const instance = getCurrentInstance();
const $message = instance?.proxy.$message;

// Data properties
const chosenNumberOfDays = ref<number>(0);
const options = ref<Array<Number>>([]);
const currentYear = new Date().getFullYear();

watch(maxDaysToSave, () => {
  options.value = [];
  for (let index = 0; index <= maxDaysToSave?.value; index++) {
    options.value.push(index);
  }
});

// Class methods
const closeModal = (): void => {
  emits("close", null);
};

const saveAndCloseModal = (): void => {
  handleUpdateSavedVacationDays(chosenNumberOfDays.value);
  emits("close", chosenNumberOfDays);
};

const handleChange = (choice: number) => {
  chosenNumberOfDays.value = choice;
};

const handleUpdateSavedVacationDays = (days: number): void => {
  if (props.user === null || days === null) {
    return;
  }

  apiUpdateUserSavedVacationDays(props.user.id, currentYear, days)
    .then(() => $message?.success("Succesfully updated saved vacation days", 3))
    .catch(() => $message?.error("Couldn't update saved vacation days", 3));
};
</script>
