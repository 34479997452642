<template>
  <a-layout-content class="my-4 mx-3">
    <div v-if="project" class="container">
      <div class="flex-center-between mb-4">
        <div class="w-100 flex-center-between">
          <router-link
            :to="{ name: 'project', params: { projectId: project.id } }"
            tag="h3"
            class="m-0 action-default"
          >
            <a-icon type="arrow-left" class="mr-2" />Back
          </router-link>

          <h2 class="m-0 ml-2">
            New invoice for
            <strong>{{ project.name }}</strong>
          </h2>

          <a-button type="primary" @click="() => confirmStoreInvoice()">Create</a-button>
        </div>
      </div>

      <div class="card mt-4">
        <div class="d-flex justify-content-between mb-3">
          <h2 class="m-0">Summary</h2>
        </div>

        <a-row>
          <a-col :span="12" class="p-1">
            <div class="desc-item">
              <span class="label">Total logged time:</span>
              <span class="value">{{ totalAmount }} hour(s)</span>
            </div>
          </a-col>

          <a-col :span="12" class="p-1">
            <div class="desc-item">
              <span class="label">Total value:</span>
              <a-col class="ml-4">
                <div v-if="totalCostMap.size > 0">
                  <a-row
                    v-for="[currency, totalCost] in totalCostMap"
                    :key="currency"
                    class="text-right"
                  >
                    {{ totalCost / 100 + ",00 " + currency }}
                  </a-row>
                </div>
                <div v-else>-</div>
              </a-col>
            </div>
          </a-col>
        </a-row>

        <a-table
          v-if="rolesMap.size > 0"
          :columns="invoiceSummaryRolesColumns"
          :data-source="Array.from(rolesMap.values())"
          :pagination="false"
          :row-key="(invoice) => invoice.id"
          :scroll="{ x: 600 }"
        >
          <template #role="title, role">
            <span class="d-flex align-items-center">
              <span class="min-w-8 mr-2">
                <a-avatar :src="role.user.gravatar" size="small" />
              </span>
              <span>
                {{ role.role.title }} (
                <router-link :to="{ name: 'user', params: { id: role.user.id } }">
                  {{ role.user.name }} </router-link
                >)
              </span>
            </span>
          </template>

          <template #amount="hours">
            <span>
              {{ hours ? `${hours} hour(s)` : "-" }}
            </span>
          </template>

          <template #value="totalValue, role">
            <span>{{ totalValue / 100 + ",00 " + role.cost_currency }}</span>
          </template>
        </a-table>
      </div>

      <!-- REPORTS TABLE -->
      <div class="card mt-4">
        <div class="d-flex justify-content-between mb-3">
          <h2 class="m-0">Reports</h2>
        </div>

        <a-table
          :columns="invoiceReportsColumns"
          :data-source="reports"
          :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
          :scroll="{ x: 600 }"
          :row-key="(invoice) => invoice.id"
        >
          <template #type="type, report">
            <span class="d-flex align-items-center">
              <a-tag v-if="type === ReportType.TIME" :color="Colors.blue_darker">
                {{ type.replace("-", " ") }}
              </a-tag>
              <a-icon
                v-if="type === ReportType.TIME && !report.locked"
                class="text-red mr-2"
                type="unlock"
              />
              <a-tag v-if="type === ReportType.EXPENSE" :color="Colors.blue">
                {{ type.replace("-", " ") }}
              </a-tag>

              <a-icon v-if="report.cost_center && report.cost_center.billable" type="dollar" />
            </span>
          </template>

          <template #role="role, report">
            <span v-if="report.type === ReportType.TIME" class="d-flex align-items-center">
              <span class="min-w-8 mr-2">
                <a-avatar :src="report.user.gravatar" size="small" />
              </span>
              <span class="flex flex-col">
                <div>
                  {{ role }}
                </div>
                <router-link :to="{ name: 'user', params: { id: report.user.id } }">
                  {{ report.user.name }}
                </router-link>
              </span>
            </span>

            <span v-if="report.type === ReportType.EXPENSE" class="d-flex align-items-center">
              <a-avatar :src="report.user.gravatar" size="small" class="mr-2" />
              <router-link :to="{ name: 'user', params: { id: report.user.id } }">
                {{ report.user.name }}
              </router-link>
            </span>
          </template>

          <template #amount="amount">
            <span>{{ amount ? `${amount} hour(s)` : "-" }}</span>
          </template>

          <template #hourly="text">
            <span>{{ text ? toMoney(text) : "-" }}</span>
          </template>

          <template #comment="text, report">
            <div v-if="report.comment">
              <a-popover v-if="report.comment.length > COMMENT_MAX_LENGTH">
                <template #content>
                  <p style="max-width: 300px; max-height: 200px; overflow-y: scroll">
                    {{ report.comment }}
                  </p>
                </template>
                <div>
                  {{ report.comment.substring(0, COMMENT_MAX_LENGTH) + "…" }}
                </div>
              </a-popover>
              <div v-else>{{ report.comment }}</div>
            </div>
          </template>
        </a-table>
      </div>
    </div>
  </a-layout-content>
</template>

<script setup lang="ts">
import { ref, getCurrentInstance, computed, watch, onMounted } from "vue";
import { invoiceReportsColumns } from "@/modules/reports/_utils/tables";
import { invoiceSummaryRolesColumns } from "@/modules/roles/_utils/tables";
import { apiGetProject } from "@/modules/projects/_utils/api";
import { ProjectInterface } from "@/modules/projects/types";
import { apiStoreProjectInvoice } from "../_utils/api";
import Colors from "@/assets/scss/_colors.module.scss";
import { ReportType } from "@/modules/reports/types";
import useMixin from "@/useMixin";
import { useInvoicesStore } from "@/modules/invoices/_store";

// Pinia
const invoicesStore = useInvoicesStore();
const reports = computed(() => invoicesStore.reports);

// Mixins
const { toMoney } = useMixin();

// Instances
const instance = getCurrentInstance();
const $route = instance?.proxy.$route;
const $router = instance?.proxy.$router;
const $error = instance?.proxy.$error;
const $confirm = instance?.proxy.$confirm;
const $message = instance?.proxy.$message;

// Data properties
const selectedRowKeys = ref<Array<any>>([]);
const totalCostMap = ref<Map<string, number>>(new Map());
const totalAmount = ref<number>(0);
const rolesMap = ref<Map<number, any>>(new Map());
const project = ref<ProjectInterface | null>(null);
const COMMENT_MAX_LENGTH = ref<number>(50);

// Watchers
watch(reports, () => {
  onSelectChange([]);
  preselect();
});

// Lifecycle hooks
onMounted(() => {
  apiGetProject(parseInt($route?.params.projectId as string)).then(
    (res: any) => (project.value = res.data.data)
  );
  invoicesStore.getReportsForInvoice(parseInt($route?.params.projectId as string));
});

// Component methods
const onSelectChange = (s: Array<any>): void => {
  selectedRowKeys.value = s;
  computeInvoiceSummary();
};

const preselect = (): void => {
  reports.value.forEach((r: any) => {
    if ((r.locked && r.cost_center && r.cost_center.billable) || r.type === ReportType.EXPENSE) {
      selectedRowKeys.value.push(r.id);
    }
  });
  computeInvoiceSummary();
};

const computeInvoiceSummary = (): void => {
  totalCostMap.value = new Map();
  totalAmount.value = 0;
  rolesMap.value = new Map();

  selectedRowKeys.value.forEach((v) => {
    // Get report for selected row.
    let report = reports.value.find((r: any) => r.id === v);

    // If is expense report simply add cost and return.
    if (report.type === ReportType.EXPENSE) {
      incrementTotalCostMapValueBy(report.cost.currency_code, report.cost.value);
      return;
    }

    // If is time report continue and start by computing cost.
    totalAmount.value += report.amount;
    incrementTotalCostMapValueBy(report.total_cost.currency_code, report.total_cost.value);

    // Add role to map if not there yet.
    if (!rolesMap.value.has(report.role.id)) {
      rolesMap.value.set(report.role.id, {
        role: { ...report.role },
        user: { ...report.user },
        amount: 0,
        cost: 0,
        cost_currency: "",
      });
    }

    // Update role data in map.
    let role = rolesMap.value.get(report.role.id);
    role.amount += parseInt(report.amount);
    role.cost += report.total_cost.value;
    role.cost_currency = report.total_cost.currency_code;
  });
};

const incrementTotalCostMapValueBy = (key: string, increment: number): void => {
  if (!totalCostMap.value.has(key)) {
    totalCostMap.value.set(key, 0);
  }
  totalCostMap.value.set(key, totalCostMap.value.get(key)! + increment);
};

const confirmStoreInvoice = (): void => {
  if (selectedRowKeys.value.length < 1) {
    storeEmptyInvoiceError();
    return;
  }

  const mod = $confirm?.({
    title: `Are you sure you want to create a new invoice to ${project.value?.client.name} for the project ${project.value?.name}?`,
    okText: "Yes",
    cancelText: "No",
    onOk() {
      if (project.value) {
        apiStoreProjectInvoice(project.value.id, {
          report_ids: selectedRowKeys.value,
          status: "generated",
          type: "ReportedInvoice",
        })
          .then((res: any) => {
            $message?.success("Invoice created successfully!", 3);
            mod?.destroy();
            if (project.value) {
              $router?.push({
                name: "invoice",
                params: {
                  projectId: project.value?.id + "",
                  invoiceId: res.data.data.id,
                },
              });
            }
          })
          .catch(() => $message?.error("Couldn't create invoice!", 3));
      }
    },
  });
};

const storeEmptyInvoiceError = () => {
  $error?.({
    title: "The invoice you are trying to create does not contain any report!",
  });
};
</script>
