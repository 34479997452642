<template>
  <a-layout-content class="my-4 mx-3">
    <div class="container">
      <!-- HEADER -->
      <div class="d-flex justify-content-between">
        <h2>Users</h2>
        <div class="d-flex">
          <a-button
            v-if="baseAcl.isAdmin || baseAcl.isFinancial"
            :loading="downloadingCsv"
            class="mr-2"
            icon="download"
            type="default"
            @click="() => handleDownloadCsv()"
          />
          <portal-target class="mr-2" name="users-filter-buttons"></portal-target>
        </div>
      </div>

      <!-- FILTER -->
      <users-filter
        :acl="baseAcl"
        :profile="profile"
        :filters="filtersRef"
        :number-of-results="usersPagination ? usersPagination.total : 1"
        @get-skills="() => getSkills()"
        @get-teams="() => handleGetTeams()"
        @get-user-permissions="() => handleGetUserPermissions()"
        @get-users="() => handleGetUsersCompactList()"
        @apply-filters="(filters) => applyFilters(filters)"
      />

      <!-- USERS TABLE -->
      <div class="card">
        <a-table
          :columns="usersColumns"
          :data-source="usersPage"
          :loading="loadingTable"
          :pagination="false"
          :scroll="{ x: 600 }"
          :row-key="(user) => user.id"
          @change="
            (cPagination, cFilters, cSorter) => handleTableChange(cPagination, cFilters, cSorter)
          "
        >
          <template #name="name, u">
            <span class="d-flex align-items-center">
              <span class="min-w-8 mr-2">
                <a-avatar :src="u.gravatar" />
              </span>
              <router-link :to="{ name: 'user', params: { id: u.id } }">{{ name }}</router-link>
            </span>
          </template>

          <template #lock_date="text, u">
            <span class="d-flex align-items-center">
              <a-icon type="sorter" />
              {{ u.lock_date }}
            </span>
          </template>

          <template #workload="workload">
            <span class="d-flex align-items-center">
              {{ toPercentage(workload) }}
            </span>
          </template>

          <template #status="text, u">
            <span class="d-flex align-items-center">
              <span v-if="u.on_vacation">
                <ion-icon name="sunny" class="mr-2 text-yellow" />
              </span>
              <a-icon
                v-if="dateDiff(u) || u.report_locking_date === null"
                :color="Colors.blue_darker"
                type="unlock"
                class="mr-2 text-red"
              />
            </span>
          </template>

          <template #profile_description_status="text, u">
            <span class="d-flex align-items-center">
              <a-tag
                v-if="u.profile_description_status === ProfileDescriptionStatus.PRESENT"
                :color="Colors.green"
                class="default-cursor"
              >
                PRESENT
              </a-tag>
              <a-tag
                v-if="u.profile_description_status === ProfileDescriptionStatus.PENDING"
                :color="Colors.yellow"
                class="default-cursor"
              >
                PENDING
              </a-tag>
              <a-tag
                v-if="u.profile_description_status === ProfileDescriptionStatus.MISSING"
                :color="Colors.orange"
                class="default-cursor"
              >
                MISSING
              </a-tag>
            </span>
          </template>

          <template #action="text, u">
            <span>
              <a-icon
                type="info-circle"
                class="action-default"
                @click="() => handleShowShortDetailModal(u)"
              />
            </span>
          </template>
        </a-table>

        <a-pagination
          :current="currentPage"
          :total="usersPagination ? usersPagination.total : 1"
          :show-size-changer="true"
          :page-size="pageSizeRef"
          :page-size-options="['10', '25', '50', '100']"
          size="small"
          class="d-flex justify-content-end mt-2"
          @change="(newPage, newSize) => handleGetUsersPage({ page: newPage, pageSize: newSize })"
          @showSizeChange="
            (newPage, newSize) => handleGetUsersPage({ page: newPage, pageSize: newSize })
          "
        />

        <!-- ICONS LEGEND -->
        <div class="d-flex mt-1 flex-wrap">
          <div class="mr-4 my-1"><ion-icon name="sunny" class="text-yellow mr-2" />On Vacation</div>
          <div class="mr-4 my-1">
            <a-icon :color="Colors.blue_darker" type="unlock" class="text-red mr-2" />
            Critical Time since last locked Reports
          </div>
        </div>
      </div>

      <!-- SHOW SMALL USER DETAIL CARD -->
      <user-short-detail-modal
        :visible="userShortDetailModalVisible"
        :user="user"
        @close="userShortDetailModalVisible = false"
      />
    </div>
  </a-layout-content>
</template>

<script lang="ts">
import { defineComponent, ref, getCurrentInstance, computed, onBeforeMount } from "vue";
import { ProfileInterface, BaseAclInterface } from "../../authentication/types";
import UserShortDetailModal from "../_components/UserShortDetailModal.vue";
import { ProfileDescriptionStatus, UsersFilterInterface } from "../types";
import { useAuthenticationStore } from "@/modules/authentication/_store";
import { PaginationInterface } from "../../../common/types";
import UsersFilter from "../_components/UsersFilter.vue";
import Colors from "@/assets/scss/_colors.module.scss";
import { usersColumns } from "../_utils/tables";
import { apiGetUsers } from "../_utils/api";
import { downloadCsv } from "@/utils";
import useMixin from "@/useMixin";
import moment from "@/date";
import { useBookmarkedFiltersStore } from "@/modules/bookmarked_filters/_store";

export default defineComponent({
  components: { UserShortDetailModal, UsersFilter },
  // Route Guards
  beforeRouteEnter(to: any, from: any, next: any) {
    const authenticationStore = useAuthenticationStore();
    authenticationStore.isInternal ? next() : next({ name: "dashboard" });
  },
  setup() {
    // Pinia
    const authenticationStore = useAuthenticationStore();
    const bookmarkedFiltersStore = useBookmarkedFiltersStore();
    const profile = computed<ProfileInterface | undefined>(() => authenticationStore.profile);
    const baseAcl = computed<BaseAclInterface>(() => authenticationStore.baseAcl);

    // Mixins
    const { qsDecode, loadTablePageSize, syncRouteParams, persistTablePageSize, toPercentage } =
      useMixin();

    // Instances
    const instance = getCurrentInstance();
    const $route = instance?.proxy?.$route;
    const $message = instance?.proxy?.$message;

    // Data Properties
    const filtersRef = ref<UsersFilterInterface>({ employment_status: ["active"] });
    const usersPagination = ref<PaginationInterface | null>(null);
    const user = ref<ProfileInterface | undefined>(undefined);
    const userShortDetailModalVisible = ref<boolean>(false);
    const usersPage = ref<Array<ProfileInterface>>([]);
    const downloadingCsv = ref<boolean>(false);
    const loadingTable = ref<boolean>(false);
    const currentPage = ref<number>(1);
    const pageSizeRef = ref<number>(25);
    const sortingRef = ref<any>({});

    // Life-cycle Hooks
    onBeforeMount(() => {
      bookmarkedFiltersStore.getUserBookmarkedFilters(profile.value?.id as number);
      getUserPageFromURL();
    });

    // Component Methods
    const getUserPageFromURL = (url: any = $route?.query): void => {
      const { page, filters, sorting, pageSize } = qsDecode(url);

      pageSizeRef.value = loadTablePageSize();

      if (page) currentPage.value = parseInt(page);
      if (filters) filtersRef.value = filters;
      if (sorting) sortingRef.value = sorting;
      if (pageSize) pageSizeRef.value = parseInt(pageSize);

      handleGetUsersPage({ page: currentPage.value });
    };

    const handleGetUsersPage = ({
      page,
      pageSize = pageSizeRef.value,
      filters = filtersRef.value,
      sorting = sortingRef.value,
    }: {
      page: number;
      pageSize?: number;
      filters?: any;
      sorting?: any;
    }) => {
      loadingTable.value = true;
      currentPage.value = page;
      pageSizeRef.value = pageSize;

      syncRouteParams(
        {
          page: currentPage.value,
          pageSize: pageSizeRef.value,
          filters: filtersRef.value,
          sorting: sortingRef.value,
        },
        instance
      );
      persistTablePageSize(pageSize);

      apiGetUsers({
        page,
        page_size: pageSize,
        ...filters,
        ...sorting,
      }).then((results: any) => {
        loadingTable.value = false;
        usersPage.value = results.data.data;
        usersPagination.value = results.data.meta;
      });
    };

    const applyFilters = (filters: any) => {
      filtersRef.value = filters;
      currentPage.value = 1;

      syncRouteParams(
        {
          page: currentPage.value,
          pageSize: pageSizeRef.value,
          filters: filtersRef.value,
          sorting: sortingRef.value,
        },
        instance
      );
      handleGetUsersPage({ page: currentPage.value });
    };

    const handleShowShortDetailModal = (u: ProfileInterface): void => {
      user.value = u;
      userShortDetailModalVisible.value = true;
    };

    const handleDownloadCsv = (): void => {
      downloadingCsv.value = true;

      apiGetUsers(filtersRef.value, { Accept: "text/csv" })
        .then((res: any) => downloadCsv(res.data, "users", !!Object.keys(filtersRef.value).length))
        .catch((err: any) => $message?.error("Couldn't download CSV file!", 3))
        .finally(() => (downloadingCsv.value = false));
    };

    const dateDiff = (user: ProfileInterface): boolean => {
      var now = moment();
      var lock_date = user.report_locking_date;
      return now.diff(lock_date, "days") > 7;
    };

    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
      sortingRef.value = {
        sort: sorter.columnKey,
        order: sorter.columnKey ? (sorter.order === "descend" ? "desc" : "asc") : undefined,
      };

      syncRouteParams(
        {
          page: currentPage.value,
          pageSize: pageSizeRef.value,
          filters: filtersRef.value,
          sorting: sortingRef.value,
        },
        instance
      );
      handleGetUsersPage({ page: currentPage.value });
    };

    return {
      userShortDetailModalVisible,
      handleShowShortDetailModal,
      ProfileDescriptionStatus,
      handleGetUsersPage,
      handleTableChange,
      handleDownloadCsv,
      usersPagination,
      downloadingCsv,
      toPercentage,
      loadingTable,
      usersColumns,
      applyFilters,
      currentPage,
      pageSizeRef,
      filtersRef,
      usersPage,
      dateDiff,
      baseAcl,
      profile,
      Colors,
      user,
    };
  },
});
</script>
