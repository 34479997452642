<template>
  <a-modal
    :visible="visible"
    :destroy-on-close="true"
    :width="760"
    title="Skynet onboarding"
    @cancel="() => closeModal()"
  >
    <!-- CREATE STEPS -->
    <a-steps :current="currentStep" size="small" label-placement="vertical" class="mb-2">
      <a-step :title="stepsInformation[0].title" :status="stepsInformation[0].status">
        <template #icon>
          <a-icon type="rocket" />
        </template>
        <template #description>
          <p :class="currentStep === 0 ? 'current-step' : ''" />
        </template>
      </a-step>
      <a-step :title="stepsInformation[1].title" :status="stepsInformation[1].status">
        <template #icon>
          <a-icon type="user" />
        </template>
        <template #description>
          <p :class="currentStep === 1 ? 'current-step' : ''" />
        </template>
      </a-step>
      <a-step :title="stepsInformation[2].title" :status="stepsInformation[2].status">
        <template #icon>
          <a-icon type="profile" />
        </template>
        <template #description>
          <p :class="currentStep === 2 ? 'current-step' : ''" />
        </template>
      </a-step>
      <a-step :title="stepsInformation[3].title" :status="stepsInformation[3].status">
        <template #icon>
          <a-icon type="idcard" />
        </template>
        <template #description>
          <p :class="currentStep === 3 ? 'current-step' : ''" />
        </template>
      </a-step>
      <a-step :title="stepsInformation[4].title" :status="stepsInformation[4].status">
        <template #icon>
          <a-icon type="clock-circle" />
        </template>
        <template #description>
          <p :class="currentStep === 4 ? 'current-step' : ''" />
        </template>
      </a-step>
      <a-step :title="stepsInformation[5].title" :status="stepsInformation[5].status">
        <template #icon>
          <a-icon type="file-done" />
        </template>
        <template #description>
          <p :class="currentStep === 5 ? 'current-step' : ''" />
        </template>
      </a-step>
    </a-steps>

    <!-- WELCOME STEP -->
    <welcome-step
      :visible="currentStep === 0"
      :user-name="user ? user.name : ''"
      @update="onWelcomeStepUpdate"
    />

    <!-- GRAVATAR STEP -->
    <gravatar-step
      :visible="currentStep === 1"
      :email="user ? user.email : ''"
      :should-reload="!stepsInformation[1].visited"
      @update="onGravatarUpdate"
    />

    <!-- CAREER STEP -->
    <career-step
      :visible="currentStep === 2"
      :user="user"
      :base-acl="acl"
      :show-missing-field-errors="stepsInformation[2].visited"
      @reload-user="$emit('reload-user')"
      @update="onCareerUpdate"
    />

    <!-- PROFILE STEP -->
    <profile-step
      :visible="currentStep === 3"
      :user="user"
      :show-missing-field-errors="stepsInformation[3].visited"
      @reload-user="$emit('reload-user')"
      @update="onProfileUpdate"
    />

    <!-- TIME REPORT STEP -->
    <time-report-step
      :visible="currentStep === 4"
      :user="user"
      :should-reload="!stepsInformation[4].visited"
      @update="onTimeReportUpdate"
    />

    <!-- SUMMARY STEP -->
    <summary-step
      :visible="currentStep === 5"
      :steps-information="stepsInformation"
      @update="onSummaryUpdate"
    />

    <!-- CUSTOM FOOTER TO ADVANCE STEPS -->
    <template #footer>
      <div class="d-flex justify-content-between">
        <div>
          <a-button @click="closeModal">Close</a-button>
        </div>

        <div>
          <a-button v-show="currentStep !== 0" @click="prev">Previous</a-button>
          <a-button v-show="currentStep < stepsInformation.length - 1" type="primary" @click="next"
            >Next</a-button
          >
          <a-button
            v-show="
              currentStep === stepsInformation.length - 1 &&
              stepsInformation.every((i) => i.completed)
            "
            type="primary"
            @click="handleOnboardingFinish"
            >Finish</a-button
          >
        </div>
      </div>
    </template>
  </a-modal>
</template>

<script setup lang="ts">
import { ref, watch, getCurrentInstance } from "vue";
import { BaseAclInterface, ProfileInterface } from "@/modules/authentication/types";
import { apiUpdateUser } from "@/modules/users/_utils/api";
import TimeReportStep from "./TimeReportStep.vue";
import { StepInformation } from "../_utils/types";
import GravatarStep from "./GravatarStep.vue";
import ProfileStep from "./ProfileStep.vue";
import SummaryStep from "./SummaryStep.vue";
import WelcomeStep from "./WelcomeStep.vue";
import CareerStep from "./CareerStep.vue";

// Props
const props = defineProps({
  visible: { type: Boolean, default: false },
  user: { type: Object as () => ProfileInterface, default: undefined },
  acl: { type: Object as () => BaseAclInterface, default: undefined },
});

// Emits
const emits = defineEmits(["close"]);

// Instances
const instance = getCurrentInstance();
const $message = instance?.proxy.$message;

// Data Properties
const currentStep = ref<number>(0);
const stepsInformation = ref<Array<StepInformation>>([
  {
    title: "Welcome",
    status: "process",
    visited: false,
    completed: false,
  },
  {
    title: "Gravatar",
    status: "wait",
    visited: false,
    completed: false,
  },
  {
    title: "Career",
    status: "wait",
    visited: false,
    completed: false,
  },
  {
    title: "Profile",
    status: "wait",
    visited: false,
    completed: false,
  },
  {
    title: "Time Report",
    status: "wait",
    visited: false,
    completed: false,
  },
  {
    title: "Summary",
    status: "wait",
    visited: false,
    completed: false,
  },
]);

// Watchers
watch(
  () => props.visible,
  (newProp) => {
    if (newProp) {
      if (hasStepsInformationStoredLocally()) {
        loadLocalStepsInformation();
      }
      setCurrentStep();
    }
  },
  { immediate: true }
);

// Class methods
const hasStepsInformationStoredLocally = (): boolean => {
  return localStorage.currentStep && localStorage.stepsInformation;
};

const loadLocalStepsInformation = (): void => {
  const localStepsInformation = JSON.parse(localStorage.stepsInformation);
  stepsInformation.value.forEach((step, index) => {
    step.visited = localStepsInformation[index].visited;
    step.status = localStepsInformation[index].status;
    step.completed = localStepsInformation[index].completed;
  });
};

const setCurrentStep = (): void => {
  const firstStepWithError = stepsInformation.value.find((info) => info.status === "error");
  if (firstStepWithError) {
    currentStep.value = stepsInformation.value.indexOf(firstStepWithError);
    return;
  }

  const processStep = stepsInformation.value.find((info) => info.status === "process");
  if (processStep) {
    currentStep.value = stepsInformation.value.indexOf(processStep);
    return;
  }

  const nextWaitStep = stepsInformation.value.find((info) => info.status === "wait");
  if (nextWaitStep) {
    currentStep.value = stepsInformation.value.indexOf(nextWaitStep);
    stepsInformation.value[currentStep.value].status = "process";
    return;
  }

  currentStep.value = stepsInformation.value.length - 1;
};

const prev = (): void => {
  updateCurrentStep();
  currentStep.value--;
  updateLocalStorage();
};

const next = (): void => {
  updateCurrentStep();
  currentStep.value++;
  if (stepsInformation.value[currentStep.value].status === "wait") {
    stepsInformation.value[currentStep.value].status = "process";
  }
  updateLocalStorage();
};

const updateCurrentStep = (): void => {
  stepsInformation.value[currentStep.value].visited = true;
  stepsInformation.value[currentStep.value].status = stepsInformation.value[currentStep.value]
    .completed
    ? "finish"
    : "error";
};

const updateLocalStorage = () => {
  localStorage.setItem("currentStep", currentStep.value.toString());
  localStorage.setItem("stepsInformation", JSON.stringify(stepsInformation.value));
};

const deleteLocalStorage = () => {
  localStorage.removeItem("currentStep");
  localStorage.removeItem("stepsInformation");
};

const onWelcomeStepUpdate = (): void => {
  stepsInformation.value[0].completed = true;
};

const onGravatarUpdate = (isComplete: boolean): void => {
  updateStatus(1, isComplete);
  stepsInformation.value[1].completed = isComplete;
};

const onCareerUpdate = (isComplete: boolean): void => {
  updateStatus(2, isComplete);
  stepsInformation.value[2].completed = isComplete;
};

const onProfileUpdate = (isComplete: boolean): void => {
  updateStatus(3, isComplete);
  stepsInformation.value[3].completed = isComplete;
};

const onTimeReportUpdate = (isComplete: boolean): void => {
  updateStatus(4, isComplete);
  stepsInformation.value[4].completed = isComplete;
};

const onSummaryUpdate = (): void => {
  if (stepsInformation.value[5].visited && !stepsInformation.value[5].completed) {
    stepsInformation.value[5].status = "finish";
  }

  stepsInformation.value[5].completed = true;
};

const updateStatus = (step: number, isComplete: boolean): void => {
  if (stepsInformation.value[step].visited) {
    if (!stepsInformation.value[step].completed && isComplete) {
      stepsInformation.value[step].status = "finish";
    } else if (stepsInformation.value[step].completed && !isComplete) {
      stepsInformation.value[step].status = "error";
    }
  }
};

const handleOnboardingFinish = (): void => {
  const user = props.user!;
  user.onboarding_completed = true;
  apiUpdateUser(user.id, user)
    .then(() => {
      $message?.success("Successfully completed Skynet onboarding!", 3);
      deleteLocalStorage();
    })
    .catch(() => {
      $message?.error("Couldn't update user 'onboarding_completed' attribute!", 3);
    })
    .finally(() => closeModal());
};

const closeModal = (): void => {
  stepsInformation.value[currentStep.value].visited = true;
  updateLocalStorage();
  emits("close");
};
</script>

<style>
@keyframes FadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.current-step {
  padding-top: 5px;
  border-bottom: 1px solid blue;
  animation-name: FadeIn;
  animation-duration: 0.5s;
  animation-play-state: running;
  animation-fill-mode: forwards;
}
</style>
