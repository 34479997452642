import { ModalConfirm, ModalOptions } from "ant-design-vue/types/modal";

export function showDeleteExperienceModal(
  confirm: (options: ModalOptions) => ModalConfirm,
  experience: { title: string },
  experienceType: string,
  onOkCallback: () => void
): void {
  const mod = confirm({
    title: `Are you sure you want to remove the ${experienceType} ${
      experience ? experience.title : ""
    }?`,
    okText: "Yes",
    okType: "danger",
    cancelText: "No",
    onOk() {
      onOkCallback();
      mod.destroy();
    },
  });
}
