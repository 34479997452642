<template>
  <a-modal
    :visible="visible"
    :destroy-on-close="true"
    title="Edit Expense Report"
    ok-text="Save"
    @ok="() => handleUpdateExpenseReport()"
    @cancel="() => $emit('close')"
  >
    <a-form layout="horizontal" :data-vv-scope="ReportType.EXPENSE">
      <a-form-item
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
        :validate-status="$validator.errors.first('expense-report.title') ? 'error' : ''"
        :help="$validator.errors.first('expense-report.title')"
        label="Title"
      >
        <a-input
          v-model="updatedReport.title"
          v-validate="'required'"
          data-vv-name="title"
          placeholder="Title"
          class="w-100"
        />
      </a-form-item>

      <a-form-item :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }" label="Project">
        <strong>{{ updatedReport.project_name }}</strong>
      </a-form-item>

      <a-form-item :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }" label="User">
        <strong>{{ updatedReport.user_name }}</strong>
      </a-form-item>

      <SkMoney
        v-model="updatedReport.cost_cents"
        v-validate="'required|decimal|min_value:0'"
        :error="$validator.errors.first('expense-report.cost')"
        :currency="updatedReport.cost_currency"
        data-vv-name="cost"
        label="Cost"
        placeholder="Cost"
        @set-currency="(v) => (updatedReport.cost_currency = v)"
      />

      <a-form-item
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
        :validate-status="$validator.errors.first('expense-report.date') ? 'error' : ''"
        :help="$validator.errors.first('expense-report.date')"
        label="Date"
      >
        <a-date-picker
          v-validate="'required'"
          :value="parseDate(updatedReport.start_date)"
          data-vv-name="date"
          @change="(date) => onDateChange(date)"
        />
      </a-form-item>

      <a-form-item :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }" label="Cost center">
        <a-select
          v-model="updatedReport.cost_center_id"
          placeholder="Select a cost center"
          show-search
          option-filter-prop="children"
        >
          <a-select-option v-for="c in costCenters" :key="c.id" :value="c.id">
            {{ c.name }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }" label="Comment">
        <a-textarea v-model="updatedReport.comment" :auto-size="{ minRows: 2 }" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script setup lang="ts">
import { ref, watch, getCurrentInstance } from "vue";
import { ReportPayloadInterface, ReportInterface, ReportType } from "../types";
import { CostCenterInterface } from "../../cost_centers/types";
import useMixin from "@/useMixin";
import useFilters from "@/useFilters";

// Props
const props = defineProps({
  costCenters: { type: Array as () => Array<CostCenterInterface>, default: () => [] },
  expenseReport: { type: Object as () => ReportInterface, default: undefined },
  visible: { type: Boolean, default: false },
});

// Emits
const emits = defineEmits(["get-cost-centers", "update-report", "close"]);

// Mixins
const { setObject, toMoneyCents } = useMixin();

// Filters
const { parseDate } = useFilters();

// Instances
const instance = getCurrentInstance();
const $validator = instance?.proxy.$validator;

// Data properties
const updatedReport = ref<ReportPayloadInterface>({
  id: undefined,
  hourly_rate_cents: undefined,
  hourly_rate_currency: undefined,
  cost_cents: undefined,
  cost_currency: undefined,
  start_date: undefined,
  comment: undefined,
  title: undefined,
  user_name: undefined,
  project_id: undefined,
  project_name: undefined,
  cost_center_id: undefined,
  type: ReportType.EXPENSE,
});

// Watchers
watch(
  () => props.visible,
  (newProp, oldProp) => {
    if (newProp && !oldProp) {
      // Initialize all fields
      updatedReport.value = setObject(updatedReport.value, props.expenseReport);
      updatedReport.value.hourly_rate_cents = props.expenseReport.hourly_rate
        ? props.expenseReport.hourly_rate.value / 100
        : 0;
      updatedReport.value.hourly_rate_currency = props.expenseReport.hourly_rate
        ? props.expenseReport.hourly_rate.currency_code
        : "SEK";
      updatedReport.value.cost_cents = props.expenseReport.cost
        ? props.expenseReport.cost.value / 100
        : 0;
      updatedReport.value.cost_currency = props.expenseReport.cost
        ? props.expenseReport.cost.currency_code
        : "SEK";
      if (props.expenseReport.user) updatedReport.value.user_name = props.expenseReport.user.name;
      updatedReport.value.project_id = props.expenseReport.project.id;
      updatedReport.value.project_name = props.expenseReport.project.name;
      updatedReport.value.cost_center_id = props.expenseReport.cost_center
        ? props.expenseReport.cost_center.id
        : undefined;
      emits("get-cost-centers");
    }
  }
);

// Class Methods
const changeCostToCents = (): void => {
  updatedReport.value.cost_cents = toMoneyCents(updatedReport.value.cost_cents ?? 0);
};

const handleUpdateExpenseReport = (): void => {
  $validator?.validateAll(ReportType.EXPENSE).then((result) => {
    if (result) {
      changeCostToCents();
      emits("update-report", updatedReport.value);
      emits("close");
    }
  });
};

const onDateChange = (date: any): void => {
  updatedReport.value.start_date = date ? date.format("YYYY-MM-DD") : undefined;
};
</script>
