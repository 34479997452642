<template>
  <a-modal
    :visible="visible"
    :destroy-on-close="true"
    @cancel="$emit('close')"
    @ok="handleStoreCourse"
  >
    <h3>Create a new course</h3>

    <a-form layout="horizontal">
      <a-form-item
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
        :validate-status="errors.first('title') ? 'error' : ''"
        :help="errors.first('title')"
        label="Title"
      >
        <a-input
          v-model="newCourse.title"
          v-validate="'required|max:255'"
          placeholder="Course title"
          data-vv-name="title"
        />
      </a-form-item>

      <a-form-item
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
        :validate-status="errors.first('description') ? 'error' : ''"
        :help="errors.first('description')"
        label="Description"
      >
        <a-textarea
          v-model="newCourse.description"
          v-validate="'required'"
          :auto-size="{ minRows: 3 }"
          data-vv-name="description"
          placeholder="Description of course"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script setup lang="ts">
import { ref, watch, getCurrentInstance } from "vue";
import useMixin from "@/useMixin";

// Props
const props = defineProps({
  visible: { type: Boolean, default: false },
});

// Emits
const emits = defineEmits(["store-course", "close"]);

// Mixins
const { setObject } = useMixin();

const newCourse = ref<any>({
  title: undefined,
  description: undefined,
});

// Watchers
watch(
  () => props.visible,
  (newProp, oldProp) => {
    if (newProp && !oldProp) {
      newCourse.value = setObject(newCourse.value, undefined);
    }
  }
);

// Class Methods
const instance = getCurrentInstance();
const $validator = instance?.proxy.$validator;
const handleStoreCourse = (): void => {
  $validator?.validateAll().then((result) => {
    if (result) {
      emits("store-course", newCourse.value);
      emits("close");
    }
  });
};
</script>
