<template>
  <div>
    <empty-resource-table v-if="!invoices[0] && !loadingTable" resource="invoices" />

    <a-table
      v-if="loadingTable || invoices[0]"
      :columns="invoicesColumns"
      :data-source="invoices"
      :loading="loadingTable"
      :pagination="false"
      :scroll="{ x: 1000 }"
      :row-key="(invoice) => invoice.id"
      @change="(pagination, filters, sorter) => handleChange(pagination, filters, sorter)"
    >
      <template #code="code, invoice">
        <span class="d-flex align-items-center">
          <router-link
            :to="{
              name: 'invoice',
              params: { projectId: invoice.project.id, invoiceId: invoice.id },
            }"
          >
            SK#{{ code }}
          </router-link>
        </span>
      </template>

      <template #project="project">
        <span class="d-flex flex-column">
          <small>{{ project.client.name }}</small>
          <router-link :to="{ name: 'project', params: { projectId: project.id } }">
            {{ project.name }}
          </router-link>
        </span>
      </template>

      <template #user="name, invoice">
        <span class="d-flex align-items-center">
          <a-avatar :src="invoice.user.gravatar" size="small" class="mr-2" />
          {{ name }}
        </span>
      </template>

      <template #status="text">
        <span>
          <a-tag v-if="text === 'requested'" :color="Colors.yellow">REQUESTED</a-tag>
          <a-tag v-else-if="text === 'approved'" :color="Colors.green">APPROVED</a-tag>
          <a-tag v-else-if="text === 'rejected'" :color="Colors.red">REJECTED</a-tag>
          <a-tag v-else :color="Colors.blue">GENERATED</a-tag>
        </span>
      </template>

      <template #hours="hours, invoice">
        <span>
          {{ invoice.type === "ReportedInvoice" ? `${hours}h` : "-" }}
        </span>
      </template>

      <template #value="value, invoice">
        <span>
          {{
            invoice.type === "ReportedInvoice"
              ? toMoney(invoice.total_reports_amount)
              : toMoney(invoice.total_invoice_items_amount)
          }}
        </span>
      </template>

      <template #actions="text, record">
        <span class="d-flex align-items-center">
          <a-icon type="delete" class="action-danger" @click="showDeleteInvoiceModal(record)" />
        </span>
      </template>
    </a-table>

    <a-pagination
      v-if="invoicesPagination && (loadingTable || invoices[0])"
      :current="currentPage"
      :total="invoicesPagination ? invoicesPagination.total : 1"
      :show-size-changer="true"
      :page-size="pageSize"
      :page-size-options="['10', '25', '50', '100']"
      size="small"
      class="d-flex justify-content-end mt-2"
      @change="(page, pageSize) => handleGetInvoicesPage(page, pageSize)"
      @showSizeChange="(page, newPageSize) => handleGetInvoicesPage(page, newPageSize)"
    />

    <!-- DELETE INVOICE MODAL -->
    <a-modal
      :visible="deleteInvoiceModalVisible"
      :title="`Are you sure you want to delete invoice SK#${invoiceToDelete?.id} and all its associations?`"
      ok-text="Yes"
      ok-type="danger"
      cancel-text="No"
      @ok="() => handleDeleteInvoice()"
      @cancel="() => (deleteInvoiceModalVisible = false)"
    >
      <h3 class="mb-0">Associations:</h3>
      <p v-for="entity in invoiceToDelete?.associations" :key="entity.name" class="mb-0">
        <strong>{{ entity.name }}:</strong>
        {{ entity.count }}
      </p>
    </a-modal>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import Colors from "@/assets/scss/_colors.module.scss";
import EmptyResourceTable from "@/modules/common/_components/EmptyResourceTable.vue";
import { ProfileInterface } from "../../authentication/types";
import { invoicesColumns as defaultInvoicesColumns } from "../_utils/tables";
import { PaginationInterface, TableColumnInterface } from "@/common/types";
import useMixin from "@/useMixin";

// Props
const props = defineProps({
  invoices: { type: Array as () => Array<any>, default: () => [] },
  loadingTable: { type: Boolean, default: false },
  currentPage: { type: Number, default: 1 },
  pageSize: { type: Number, default: 25 },
  invoicesPagination: { type: Object as () => PaginationInterface, default: undefined },
  profile: { type: Object as () => ProfileInterface, default: undefined },
  invoicesColumns: {
    type: Array as () => Array<TableColumnInterface>,
    default: () => defaultInvoicesColumns,
  },
});

// Emits
const emits = defineEmits(["apply-sorting", "get-invoices-page", "destroy-invoice"]);

// Mixins
const { toMoney } = useMixin();

// Data properties
const deleteInvoiceModalVisible = ref<boolean>(false);
const invoiceToDelete = ref<any>();

// Component methods
const handleChange = (pagination: any, filters: any, sorter: any) => {
  emits("apply-sorting", {
    sort: sorter.columnKey,
    order: sorter.columnKey ? (sorter.order === "descend" ? "desc" : "asc") : undefined,
  });
};

const handleGetInvoicesPage = (page: number, pageSize: number): void => {
  emits("get-invoices-page", { page, pageSize });
};

const showDeleteInvoiceModal = (i: any): void => {
  invoiceToDelete.value = i;
  deleteInvoiceModalVisible.value = true;
};

const handleDeleteInvoice = (): void => {
  deleteInvoiceModalVisible.value = false;
  emits("destroy-invoice", invoiceToDelete.value);
};
</script>
