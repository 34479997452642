<template>
  <div>
    <a-row type="flex" justify="space-between">
      <a-col>
        <h3>Certifications / Courses</h3>
      </a-col>
      <a-col>
        <a @click="setCourses([])">none</a>
        <a-divider type="vertical" />
        <a @click="setCourses(cvData.user.courses.map((course) => course.id))">users</a>
      </a-col>
    </a-row>

    <a-row type="flex">
      <a-tag
        v-for="course in cvData.courses"
        :key="course.id"
        :color="selected(course) ? 'blue' : ''"
        class="mb-2"
        @click="handleCourseClick(course)"
      >
        {{ course.title }}
      </a-tag>
    </a-row>
  </div>
</template>

<script setup lang="ts">
import { emitter } from "@/mitt";
import { ref, onMounted, onUnmounted, toRefs } from "vue";
import { CourseInterface } from "../../consultant_profiles/types";
import { CvInterface, CvDataInterface } from "../types";

// Props
const props = defineProps({
  cv: { type: Object as () => CvInterface, default: undefined },
  cvData: { type: Object as () => CvDataInterface, default: undefined },
  step: { type: Number, default: -1 },
});

// Emits
const emits = defineEmits(["cv-change", "go-to-step"]);

// Data Properties
const { cv } = toRefs(props);
const courses = ref<Array<CourseInterface>>(cv.value.courses);

// Life-cycle Hooks
onMounted(() => {
  emitter.on(`validate-cv-step-${props.step}`, (nextStep) => validate(nextStep as number));
});

onUnmounted(() => {
  emitter.off(`validate-cv-step-${props.step}`, (nextStep) => validate(nextStep as number));
});

const validate = (nextStep: number): void => {
  // Nothing to validate here
  emits("cv-change", "courses", courses.value);
  emits("go-to-step", nextStep);
};
const handleCourseClick = (course: any): void => {
  courses.value = courses.value.some((id) => id === course.id)
    ? courses.value.filter((id) => id !== course.id)
    : [...courses.value, course.id];

  emits("cv-change", "courses", courses.value);
};

const selected = (course: any) => {
  return courses.value.some((id) => id === course.id);
};

const setCourses = (c: Array<CourseInterface>) => {
  courses.value = c;
  emits("cv-change", "courses", courses.value);
};
</script>
