<template>
  <div>
    <a-form layout="horizontal" :data-vv-scope="ReportType.TIME">
      <a-form-item
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
        :validate-status="$validator.errors.first('time-report.role_id') ? 'error' : ''"
        :help="$validator.errors.first('time-report.role_id')"
        label="Project role"
      >
        <a-select
          v-model="newReport.role_id"
          v-validate="'required'"
          :dropdown-match-select-width="false"
          placeholder="Select a project role"
          show-search
          option-filter-prop="children"
          data-vv-name="role_id"
          data-vv-as="project role"
          @change="(id) => onSelectedRoleChange(id)"
        >
          <a-select-option v-for="r in activeUserRoles" :key="r.id" :value="r.id">
            {{ `${r.title} (${r.project.name}) ${r.project.client.name}` }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
        :validate-status="$validator.errors.first('time-report.amount') ? 'error' : ''"
        :help="$validator.errors.first('time-report.amount')"
        label="Amount of hours"
      >
        <a-input-number
          v-model="newReport.amount"
          v-validate="'required|integer|min_value:1|max_value:24'"
          :min="0"
          data-vv-name="amount"
          placeholder="Hours worked"
          class="w-100"
        />
      </a-form-item>

      <SkInput
        v-model="hourlyRateInput"
        v-validate="'required|decimal|min_value:0'"
        :error="$validator.errors.first('time-report.hourly_rate_input')"
        :addon-after="newReport.hourly_rate_currency"
        data-vv-name="hourly_rate_input"
        data-vv-as="hourly rate"
        label="Hourly rate"
        placeholder="Hourly rate"
        @set-currency="(v) => (newReport.rate_currency = v)"
      />

      <a-form-item
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
        :validate-status="$validator.errors.first('time-report.start_date') ? 'error' : ''"
        :help="$validator.errors.first('time-report.start_date')"
        label="Date"
      >
        <a-date-picker
          v-validate="'required'"
          :value="parseDate(newReport.start_date)"
          :disabled-date="disabledDateBeforeLastLock"
          data-vv-name="start_date"
          data-vv-as="date"
          class="w-100"
          @change="(d) => onDatesChange(d, 'start_date')"
        />
      </a-form-item>

      <a-form-item
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
        :validate-status="$validator.errors.first('time-report.cost_center_id') ? 'error' : ''"
        :help="$validator.errors.first('time-report.cost_center_id')"
        label="Cost center"
      >
        <a-select
          v-model="newReport.cost_center_id"
          v-validate="'required'"
          placeholder="Select a cost center"
          data-vv-name="cost_center_id"
          data-vv-as="cost center"
        >
          <a-select-option v-for="c in costCenters" :key="c.id" :value="c.id">
            {{ c.name }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }" label="Comment">
        <a-textarea v-model="newReport.comment" :auto-size="{ minRows: 2 }" />
      </a-form-item>

      <a-row v-if="subprojectsCollections === undefined" type="flex" justify="center" :gutter="16">
        <a-col><a-icon type="loading" /></a-col>
        <a-col><span>Loading subprojects...</span></a-col>
      </a-row>
      <a-form-item v-else-if="subprojectsCollection && subprojectsCollection.subprojects.length">
        <a-row :gutter="[48, 16]">
          <a-col :span="12">Categorize your time:</a-col>
          <a-col :span="12" style="text-align: right">Hours worked</a-col>
        </a-row>
        <a-row v-for="sp in subprojectsCollection.subprojects" :key="sp.name">
          <a-col :xs="16" :sm="18" :md="19" :lg="16" :xl="18">
            <a-row type="flex" justify="start">
              <a-checkbox v-model="sp.isChecked" :value="sp.name" class="w-100">{{
                sp.name
              }}</a-checkbox>
            </a-row>
          </a-col>
          <a-col :xs="8" :sm="6" :md="5" :lg="8" :xl="6">
            <a-row type="flex" justify="end" align="middle" :gutter="16">
              <a-col :span="6" align="end">
                <a-icon
                  type="plus"
                  @click="
                    sp.amount++;
                    sp.isChecked = true;
                  "
                />
              </a-col>
              <a-col :span="18" align="end">
                <a-input-number v-model="sp.amount" :disabled="!sp.isChecked" :min="0" :max="24" />
              </a-col>
            </a-row>
          </a-col>
        </a-row>
        <a-row type="flex" justify="center">
          <span v-if="hoursLeft >= 0"
            ><b>{{ hoursLeft }}h</b> remaining</span
          >
          <a-alert
            v-else
            type="warning"
            show-icon
            class="mt-2"
            :message="`You have categorized ${-hoursLeft} hours too many.`"
          />
        </a-row>
      </a-form-item>
    </a-form>

    <div class="d-flex justify-content-center mt-4">
      <a-button
        type="primary"
        :disabled="hoursLeft < 0"
        style="width: 50%"
        @click="handleStoreReport()"
        >Submit</a-button
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import * as Sentry from "@sentry/browser";
import { ref, watch, onMounted, onUnmounted, getCurrentInstance, toRefs, computed } from "vue";
import { EmploymentTypes, ProfileInterface } from "@/modules/authentication/types";
import { ReportPayloadInterface, ReportInterface, ReportType } from "../types";
import { CostCenterInterface } from "@/modules/cost_centers/types";
import { RoleInterface } from "@/modules/roles/types";
import { SubprojectInterface } from "@/modules/projects/types";
import { apiGetAllSubprojects } from "@/modules/projects/_utils/api";
import useMixin from "@/useMixin";
import moment from "@/date";
import useFilters from "@/useFilters";
import { emitter } from "@/mitt";

// Props
const props = defineProps({
  user: { type: Object as () => ProfileInterface, default: undefined },
  project: { type: Object, default: undefined },
  costCenters: { type: Array as () => Array<CostCenterInterface>, default: () => [] },
  userRoles: { type: Array as () => Array<RoleInterface>, default: () => [] },
  reports: { type: Map<String, Array<ReportInterface>>, default: () => [] },
  lastReportedRoleId: { type: Number, default: undefined },
  lastReportedHourlyRate: { type: Object, default: undefined },
  selectedDay: { type: String, default: undefined },
  reportToCopyFrom: { type: Object as () => ReportInterface, default: undefined },
});

// Emits
const emits = defineEmits(["day-selected", "store-report"]);

// Mixins
const { setObject, toCurrency, toMoney } = useMixin();

// Filters
const { parseDate } = useFilters();

// Instances
const instance = getCurrentInstance();
const $confirm = instance?.proxy.$confirm;
const $validator = instance?.proxy.$validator;

// Data Properties
const { user, lastReportedRoleId, selectedDay } = toRefs(props);
const newReport = ref<ReportPayloadInterface>({
  user_id: user.value.id,
  role_id: lastReportedRoleId.value,
  amount: undefined,
  hourly_rate_cents: 0,
  hourly_rate_currency: "SEK",
  start_date: moment(selectedDay.value).format("YYYY-MM-DD"),
  end_date: undefined,
  comment: undefined,
  cost_center_id: undefined,
  subproject_reports: undefined,
  type: ReportType.TIME,
});
const activeUserRoles = ref<Array<RoleInterface>>([]);
const hourlyRateInput = ref<number>(0);
const hoursLeft = computed(() => {
  let amount;
  if (typeof newReport.value.amount === "number") {
    amount = newReport.value.amount;
  } else {
    amount = 0;
  }
  return (
    amount -
    subprojectsCollection.value.subprojects
      .map((sp, i) => (sp.isChecked ? sp.amount : 0))
      .reduce((a, b) => a + b, 0)
  );
});
interface SubprojectReport {
  id: number;
  name: string;
  isChecked: boolean;
  amount: number;
  startDate?: string;
  endDate?: string;
}
interface SubprojectCollection {
  roleId: number;
  subprojects: Array<SubprojectReport>;
}
const subprojectsCollections = ref<Array<SubprojectCollection> | undefined>(undefined);
const subprojectsCollection = computed<SubprojectCollection>({
  get() {
    const foundCollection = subprojectsCollections.value?.find(
      (sp: SubprojectCollection) => sp.roleId === newReport.value.role_id
    ) || { roleId: -1, subprojects: [] };

    const collection = {
      ...foundCollection,
      subprojects: foundCollection.subprojects.filter((s) => {
        const today =
          newReport.value.start_date !== undefined
            ? moment(newReport.value.start_date).startOf("day")
            : undefined;
        const start = s.startDate !== undefined ? moment(s.startDate).startOf("day") : undefined;
        const end = s.endDate !== undefined ? moment(s.endDate).startOf("day") : undefined;

        if (today === undefined) {
          return false;
        }

        const started = start === undefined || start.isSameOrBefore(today);
        const ended = end !== undefined && today.isAfter(end);

        return started && !ended;
      }),
    };

    return collection;
  },
  set(sp: SubprojectCollection) {
    // subprojectsCollection has to be assigned to a collection listed in subprojectsCollections
    const index = subprojectsCollections.value!.findIndex(
      (sp) => sp.roleId === newReport.value.role_id
    );
    subprojectsCollections.value![index] = sp;
  },
});

// Watchers
watch(
  () => props.lastReportedRoleId,
  (newProp) => {
    if (newProp) {
      const roleId = newProp.valueOf();
      newReport.value.role_id = roleId;
      onSelectedRoleChange(roleId);
    }
  }
);

watch(hourlyRateInput, (newProp) => {
  if (newProp) {
    newReport.value.hourly_rate_cents = Number((newProp * 100).toFixed(0));
  }
});

watch(
  () => props.reportToCopyFrom,
  (newProp) => {
    copyFromReport(newProp);
  }
);

watch(activeUserRoles, () => {
  updateSubprojects();
});

onMounted(() => {
  emitter.on("selected-date", (date) => handleSelectedDate(date));
  activeUserRoles.value = getActiveUserRolesForDate(props.userRoles, props.selectedDay);
  handleUserRoleCostCenter();
  if (props.lastReportedRoleId) {
    newReport.value.role_id = props.lastReportedRoleId;
    onSelectedRoleChange(props.lastReportedRoleId);
  }
  if (props.reportToCopyFrom) {
    copyFromReport(props.reportToCopyFrom);
  }

  emitter.on("show-time-overlap-warning", handleShowTimeOverlapWarningEvent);

  updateSubprojects();
});

onUnmounted(() => {
  emitter.off("selected-date", (date) => handleSelectedDate(date));
  emitter.off("show-time-overlap-warning", handleShowTimeOverlapWarningEvent);
});

// Component Methods
const copyFromReport = (newProp: ReportInterface) => {
  if (newProp && newProp.user && newProp.role && newProp.cost_center) {
    newReport.value.user_id = newProp.user.id;
    newReport.value.role_id = newProp.role.id;
    newReport.value.amount = newProp.amount;
    newReport.value.hourly_rate_cents = newProp.hourly_rate.value;
    newReport.value.hourly_rate_currency = newProp.hourly_rate.currency;
    newReport.value.comment = newProp.comment;
    newReport.value.cost_center_id = newProp.cost_center.id;
    newReport.value.type = newProp.type;
    hourlyRateInput.value = (newProp.hourly_rate.value ?? 0) / 100;
  }
};

const updateSubprojects = () => {
  if (activeUserRoles.value) {
    fetchSubprojects(activeUserRoles.value).then((collections) => {
      subprojectsCollections.value = collections;
    });
  }
};

const fetchSubprojects = async (roles: RoleInterface[]) => {
  let subprojects: SubprojectInterface[];
  try {
    subprojects = (await apiGetAllSubprojects(roles.map((r) => r.project.id))).data.data;
  } catch (err) {
    instance?.proxy.$message.error(`Failed to fetch subprojects.`, 3);
    Sentry.captureException(err);
    return;
  }

  const collections: SubprojectCollection[] = roles.map((role) => ({
    subprojects: subprojects
      .filter((sp: SubprojectInterface) => sp.project.id === role.project.id && sp.name !== null)
      .map(
        (sp: SubprojectInterface): SubprojectReport => ({
          id: sp.id,
          name: sp.name,
          isChecked: false,
          amount: 0,
          startDate: sp.start_date ?? undefined,
          endDate: sp.end_date ?? undefined,
        })
      ),
    roleId: role.id,
  }));

  return collections;
};

const getActiveUserRolesForDate = (
  userRoles: Array<RoleInterface>,
  date: any
): Array<RoleInterface> => {
  const selectedDate = moment(date);
  return userRoles.filter((role) => {
    return (
      selectedDate.isSameOrAfter(role.start_date, "day") &&
      (!role.end_date || selectedDate.isSameOrBefore(role.end_date, "day"))
    );
  });
};

// Prefill hourly rate when role is selected.
// Fallback on project hourly rate.
const onSelectedRoleChange = (id: number): void => {
  const role = activeUserRoles.value.find((r) => r.id === id);
  if (role == undefined) {
    newReport.value.role_id = undefined;
    newReport.value.hourly_rate_cents = undefined;
    newReport.value.hourly_rate_currency = undefined;
  } else {
    if (role.hourly_rate) {
      newReport.value.hourly_rate_cents = role.hourly_rate.value;
      newReport.value.hourly_rate_currency = role.hourly_rate.currency_code;
      hourlyRateInput.value = role.hourly_rate.value / 100;
    }
    newReport.value.cost_center_id = role.cost_center_id;
  }
};

function handleShowTimeOverlapWarningEvent(data: {
  report: ReportPayloadInterface;
  warning: string;
}) {
  showConfirmTimeOverlapModal(data.report as ReportPayloadInterface, data.warning as string);
}

const handleSelectedDate = (date: any): void => {
  if (!disabledDateBeforeLastLock(date)) {
    activeUserRoles.value = getActiveUserRolesForDate(props.userRoles, date);
    onSelectedRoleChange(newReport.value.role_id || -1);
    newReport.value.start_date = moment(date).format("YYYY-MM-DD");
  }
};

const handleUserRoleCostCenter = (): void => {
  const role = props.userRoles.find((r) => r.id === newReport.value.role_id);
  newReport.value.cost_center_id = role ? role.cost_center_id : undefined;
};

const onDatesChange = (date: any, attribute: string): void => {
  newReport.value[attribute] = date ? moment(date).format("YYYY-MM-DD") : undefined;

  if (attribute === "start_date") {
    emits("day-selected", newReport.value[attribute]);
  }
};

const confirmWeeklyRestHours = (): Promise<any> => {
  return new Promise<void>((resolve, reject) => {
    if (displayRestConfirmationModal()) {
      $confirm?.({
        title: "Remember to respect the weekly rest",
        content: "You need at least 36 consecutive work free hours every week.",
        okText: "Proceed",
        cancelText: "Cancel",
        onOk: () => resolve(),
        onCancel: () => reject(),
      });
    } else {
      resolve();
    }
  });
};

const handleStoreReport = (): void => {
  if (subprojectsCollection.value.subprojects.length > 0) {
    newReport.value.subproject_reports = subprojectsCollection.value.subprojects
      .filter((sp) => sp.isChecked && sp.amount > 0)
      .map((sp) => ({
        subproject_id: sp.id,
        name: sp.name,
        amount: sp.amount,
      }));
  }
  $validator?.validateAll(ReportType.TIME).then((result) => {
    if (result) {
      confirmWeeklyRestHours()
        .then(() => {
          if (
            typeof newReport.value.role_id == "number" &&
            typeof newReport.value.hourly_rate_cents == "number"
          ) {
            const latestReportForRole = getLatestReportForRole(newReport.value.role_id);
            let isNewRate =
              latestReportForRole &&
              parseInt(latestReportForRole.hourly_rate.value) !==
                newReport.value.hourly_rate_cents &&
              newReport.value.type === ReportType.TIME;
            isNewRate && latestReportForRole
              ? showConfirmRateModal(latestReportForRole)
              : emitReportAndClearFields();
          }
        })
        .catch(() => {});
    }
  });
};

const showConfirmRateModal = (latestReport: ReportInterface): void => {
  if (newReport.value.hourly_rate_cents)
    $confirm?.({
      title: `You are about to report with a new hourly rate (${toCurrency(
        (newReport.value.hourly_rate_cents ?? 0) / 100,
        newReport.value.hourly_rate_currency ?? "SEK"
      )}) for this role. The last reported hourly rate for this role was ${toMoney(
        latestReport.hourly_rate
      )} (${latestReport.start_date}). Please make sure that the date and rate are correct.`,
      okText: "Submit",
      cancelText: "Cancel",
      onOk() {
        emitReportAndClearFields();
      },
    });
};

const emitReportAndClearFields = (): void => {
  emits("store-report", newReport.value);
  const previous_date = newReport.value.start_date;

  newReport.value = setObject(newReport.value, undefined);
  newReport.value.hourly_rate_cents = props.lastReportedHourlyRate
    ? props.lastReportedHourlyRate.value
    : 0;
  newReport.value.hourly_rate_currency = props.lastReportedHourlyRate
    ? props.lastReportedHourlyRate.currency_code
    : "SEK";
  newReport.value.user_id = props.user?.id;
  newReport.value.type = ReportType.TIME;
  newReport.value.role_id = props.lastReportedRoleId;
  newReport.value.start_date = previous_date;

  subprojectsCollections.value?.forEach((collection: SubprojectCollection) =>
    collection.subprojects.forEach((subproject: SubprojectReport) => {
      subproject.isChecked = false;
      subproject.amount = 0;
    })
  );

  const role = props.userRoles.find((r) => r.id === newReport.value.role_id);
  if (role != null) newReport.value.cost_center_id = role.cost_center_id;

  if (previous_date != newReport.value.start_date && newReport.value.start_date)
    emits("day-selected", newReport.value.start_date);
};

const getLatestReportForRole = (roleId: number): ReportInterface | undefined => {
  // This only loops through the months fetched by the calendar. If the latest report for the role is not in the
  // fetched calendar months there will be no rate-change-confirmation-modal.
  let latestReportForRole = undefined;
  for (const month of props.reports.entries()) {
    for (const report of month[1]) {
      if (
        report.type === ReportType.TIME &&
        report.role &&
        report.role.id === roleId &&
        (latestReportForRole === undefined ||
          (report.start_date &&
            latestReportForRole.start_date &&
            Date.parse(report.start_date) >= Date.parse(latestReportForRole.start_date)))
      ) {
        // Report is a time-report AND report is for the role AND
        // (latestReportForRole hasn't been set OR a more recent report has been made)
        latestReportForRole = report;
      }
    }
  }
  return latestReportForRole;
};

const disabledDateBeforeLastLock = (current: any): boolean => {
  return current && current <= moment(props.user?.report_locking_date).endOf("day");
};

const displayRestConfirmationModal = (): boolean => {
  const isWeekend = [6, 0].includes(moment(newReport.value.start_date!).day());
  return (
    newReport.value.type === ReportType.TIME &&
    isWeekend &&
    props.user?.employment_type != EmploymentTypes.HOURLY
  );
};

const showConfirmTimeOverlapModal = (report: ReportPayloadInterface, warning: string) => {
  newReport.value = setObject(newReport.value, report);
  $confirm?.({
    title: "Creating a time report on a vacation day",
    content: warning,
    okText: "Continue",
    onOk() {
      newReport.value.suppress_warnings = true;
      emitReportAndClearFields();
    },
  });
};
</script>
