import axios from "@/network";

export function apiGetRecurringJobs(params: any, headers = {}): any {
  const url = "/recurring_jobs";
  return axios.get(url, { params, headers });
}

export function apiStoreRecurringJob(newRecurringJob: any): any {
  const url = "/recurring_jobs";
  const payload = { recurring_job: newRecurringJob };
  return axios.post(url, payload);
}

export function apiUpdateRecurringJob(updatedRecurringJob: any): any {
  const url = `/recurring_jobs/${updatedRecurringJob.id}`;
  const payload = { recurring_job: updatedRecurringJob };
  return axios.patch(url, payload);
}

export function apiDestroyRecurringJob(recurringJobId: number): any {
  const url = `/recurring_jobs/${recurringJobId}`;
  return axios.delete(url);
}
