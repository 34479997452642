<template>
  <a-layout-content class="my-4 mx-3">
    <div class="container">
      <receipt-details
        :receipt="receipt"
        :acl="baseAcl"
        @update-receipt="(updatedReceipt) => handleUpdateReceipt(updatedReceipt)"
        @store-transaction="(transaction) => handleStoreTransaction(transaction)"
      />
    </div>
  </a-layout-content>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, getCurrentInstance } from "vue";
import { apiUpdateUserReceipt, apiGetReceipt } from "../_utils/api";
import { BaseAclInterface } from "@/modules/authentication/types";
import { useAuthenticationStore } from "@/modules/authentication/_store";
import ReceiptDetails from "../_components/ReceiptDetails.vue";
import { ReceiptInterface } from "../types";
import { useTransactionsStore } from "@/modules/transactions/_store";

// Pinia
const authenticationStore = useAuthenticationStore();
const transactionsStore = useTransactionsStore();
const baseAcl = computed<BaseAclInterface>(() => authenticationStore.baseAcl);

// Instances
const instance = getCurrentInstance();
const $route = instance?.proxy?.$route;
const $message = instance?.proxy?.$message;

// Data properties
const receipt = ref<ReceiptInterface | undefined>(undefined);

onMounted(() => {
  apiGetReceipt(parseInt($route?.params.id as string)).then((res: any) => {
    receipt.value = res.data.data;
  });
});

// Component methods
const handleStoreTransaction = (newTransaction: any): void => {
  transactionsStore
    .storeTransaction(newTransaction)
    .then(() => {
      $message?.success("Transaction created successfully!", 3);
    })
    .catch(() => $message?.error("Couldn't create transaction!", 3));
};

const handleUpdateReceipt = (updatedReceipt: ReceiptInterface): void => {
  apiUpdateUserReceipt(updatedReceipt)
    .then(() => $message?.success("Receipt updated successfully!", 3))
    .catch(() => $message?.error("Couldn't update receipt!", 3));
};
</script>
