import axios from "@/network";

export function apiGetInvoices(params: any) {
  const url = "/invoices";
  return axios.get(url, { params });
}

export function apiGetProjectInvoices(projectId: number, params: any) {
  const url = `/projects/${projectId}/invoices`;
  return axios.get(url, { params });
}

export function apiGetProjectInvoice(projectId: number, invoiceId: number) {
  const url = `/projects/${projectId}/invoices/${invoiceId}`;
  return axios.get(url);
}

// Gets all reports that can be added to an invoice.
export function apiCreateProjectInvoice(projectId: number) {
  const url = `/projects/${projectId}/invoices/new`;
  return axios.get(url);
}

export function apiStoreProjectInvoice(projectId: number, newInvoice: any) {
  const url = `/projects/${projectId}/invoices`;
  const payload = { invoice: newInvoice };
  return axios.post(url, payload);
}

export function apiUpdateProjectInvoice(projectId: number, invoiceId: number, updatedInvoice: any) {
  const url = `/projects/${projectId}/invoices/${invoiceId}`;
  return axios.patch(url, updatedInvoice);
}

export function apiDestroyProjectInvoice(projectId: number, invoiceId: number) {
  const url = `/projects/${projectId}/invoices/${invoiceId}`;
  return axios.delete(url);
}

export function apiSendInvoiceToFortnox(projectId: number, invoiceId: number, payload: any) {
  const url = `/projects/${projectId}/invoices/${invoiceId}/fortnox`;
  return axios.post(url, payload);
}
