export interface MinimalUserInterface {
  id: number;
  name: string;
  email: string;
  gravatar: string;
  team_id?: number;
  [key: string]: any;
}

export interface UsersFilterInterface {
  [key: string]: any;
  user_id?: Array<number>;
  team_id?: Array<number>;
  skill_id?: Array<number>;
  user_role_id?: Array<number>;
  employment_status?: Array<string>;
  employment_type?: Array<string>;
  profile_description_status?: ProfileDescriptionStatus;
  report_locking_date_before?: string;
  report_locking_date_after?: string;
}

export enum ProfileDescriptionStatus {
  MISSING = "missing",
  PENDING = "pending",
  PRESENT = "present",
}

export interface WorkExperienceInterface {
  id: number;
  company_name: string;
  title: string;
  start_date: string;
  end_date: string;
  description?: string;
  skill_ids?: Array<number>;
  url_link?: string;
}

export interface EducationInterface {
  id: number;
  university_name: string;
  title: string;
  start_date: string;
  end_date: string;
  description: string;
  skill_ids?: Array<number>;
}
