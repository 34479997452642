<template>
  <a-modal
    :visible="visible"
    :destroy-on-close="true"
    title="Edit Skill"
    ok-text="Save"
    @ok="handleUpdateUserSkill"
    @cancel="$emit('close')"
  >
    <a-form layout="horizontal">
      <a-form-item :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" label="Skill">
        <strong>{{ skillTitle }}</strong>
      </a-form-item>

      <a-form-item
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
        :validate-status="$validator.errors.first('level') ? 'error' : ''"
        :help="$validator.errors.first('level')"
        label="Level"
      >
        <a-select
          v-model="newUserSkill.level"
          v-validate="'required'"
          placeholder="Select a level"
          data-vv-name="level"
        >
          <a-select-option value="0">I don't know it</a-select-option>
          <a-select-option value="1">I have basic knowledge of it</a-select-option>
          <a-select-option value="2">I have used it in few situations</a-select-option>
          <a-select-option value="3">I have extensively used it</a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" label="Growth">
        <a-checkbox v-model="newUserSkill.grow">I want to learn more</a-checkbox>
      </a-form-item>

      <a-form-item :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" label="Passion">
        <a-checkbox v-model="newUserSkill.preferred">I enjoy to work with this</a-checkbox>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script setup lang="ts">
import { ref, watch, getCurrentInstance } from "vue";
import useMixin from "@/useMixin";

// Props
const props = defineProps({
  userSkill: { type: Object, default: undefined },
  visible: { type: Boolean, default: false },
});

// Emits
const emits = defineEmits(["update-user-skill", "close"]);

// Mixins
const { setObject } = useMixin();

// Instances
const instance = getCurrentInstance();
const $validator = instance?.proxy.$validator;

// Data Properties
const newUserSkill = ref<any>({
  id: undefined,
  skill_id: undefined,
  level: undefined,
  grow: undefined,
  preferred: undefined,
});
const skillTitle = ref<string | undefined>(undefined);

// Watchers
watch(
  () => props.visible,
  (newProp, oldProp) => {
    if (newProp && !oldProp) {
      if (newProp && !oldProp) {
        // Set fields to skill current values
        newUserSkill.value = setObject(newUserSkill.value, props.userSkill);
        skillTitle.value = props.userSkill.skill.title;
      }
    }
  }
);

// Component methods
const handleUpdateUserSkill = (): void => {
  $validator?.errors.clear();
  $validator?.validateAll().then((result) => {
    if (result) {
      newUserSkill.value.skill_id = parseInt(newUserSkill.value.skill_id);
      emits("update-user-skill", newUserSkill.value);
      emits("close");
    }
  });
};
</script>
