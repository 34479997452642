import { MoneyInterface } from "@/common/types";
import { CostCenterInterface } from "../cost_centers/types";
import { ProfileInterface } from "../authentication/types";
import { RoleSmallInterface } from "../roles/types";
import Vue from "vue";

export enum ReportType {
  VACATION = "vacation-report",
  ABSENCE = "absence-report",
  PARENTAL = "parental-report",
  SICK = "sick-report",
  TIME = "time-report",
  EXPENSE = "expense-report",
  DETACHEDTIME = "detached-time-report",
}

export interface StateInterface {
  reports: Map<number, any>;
  reportsSummary: Array<any>;
  subprojectsSummary: Array<any>;
  calendarReports: Map<String, Array<ReportInterface>>;
  publicHolidays: Map<String, Array<PublicHolidayInterface>>;
  cost_centers: Array<any>;
  reportsPagination: object;
}

export interface ReportInterface {
  [Symbol.iterator](): IterableIterator<ReportInterface>;
  [key: string]: any;
  id: number | undefined;
  title: string | undefined;
  comment: string | undefined;
  project: any | undefined;
  deletable: boolean | undefined;
  editable: boolean | undefined;
  locked: boolean | undefined;
  start_date: string | undefined;
  end_date: string | undefined;
  type: string | undefined;
  vab: boolean | undefined;
  parental_percentage: number | undefined;
  role: RoleSmallInterface | undefined;
  cost?: MoneyInterface | undefined;
  user: ProfileInterface | undefined;
  amount?: MoneyInterface | number | undefined;
  cost_center: CostCenterInterface | undefined;
  total_cost?: MoneyInterface | undefined;
  subproject_reports?: Array<SubprojectReportInterface> | undefined;
}

export interface ReportPayloadInterface {
  [key: string]: any;
  id?: number | undefined;
  user_id?: number | undefined;
  role_id?: number | undefined;
  cost_center_id?: number | undefined;
  project_id?: string | undefined;
  project_name?: string | undefined;
  amount?: MoneyInterface | number | undefined;
  hourly_rate_cents?: number | undefined;
  hourly_rate_currency?: string | undefined;
  cost_cents?: number | undefined;
  cost_currency?: string | undefined;
  date?: string | undefined;
  start_date: string | undefined;
  end_date?: string | undefined;
  comment?: string | undefined;
  type: string | undefined;
  suppress_warnings?: boolean | undefined;
  title?: string | undefined;
  user_name?: string | undefined;
  parental_percentage?: number | undefined;
  vab?: boolean | undefined;
  subproject_reports?: Array<SubprojectReportPayloadInterface> | undefined;
}

export interface SubprojectReportInterface {
  id: number | undefined;
  subproject_id: number | undefined;
  name: string | undefined;
  amount: number | undefined;
}

export interface SubprojectReportPayloadInterface {
  id?: number | undefined;
  subproject_id: number | undefined;
  name: string | undefined;
  amount: number | undefined;
}

export interface ReportsFilterInterface {
  user_id?: Array<number>;
  project_id?: Array<number>;
  types?: Array<string>;
  start_date?: string;
  end_date?: string;
}

export interface ReportsSummaryFilterInterface {
  role_id?: Array<number>;
  cost_center_id?: Array<number>;
  start_date?: string;
  end_date?: string;
}

export interface RoleReportsSummaryInterface {
  id: number;
  name: string;
  title: string;
  total: number;
}

export interface SurveyQuestionInterface {
  id: number;
  text: string;
}

export interface SurveyPayloadInterface {
  comment: string;
}

export interface CalendarInfoInterface {
  dates: CalendarDate;
  highlight?: CalendarHighlightInterface;
  content?: {
    style: {
      color: string;
      opacity?: number;
    };
  };
  dot?: {
    color: string;
  };
}

export type CalendarDate =
  | Date
  | { start: Date; end: string | Date }
  | { weekdays: Number | Array<Number> };

export interface CalendarHighlightInterface {
  color: string;
  style: {
    borderColor: string;
    backgroundColor: string;
    borderWidth: string;
    borderTopStyle: string;
  };
}

export enum PseudoReportType {
  LOCKING = "locking",
}

export interface DailyReportEvents {
  type: ReportType | PseudoReportType | PublicHolidayInterface;
  label: string;
  date: { start: Date; end: null | Date };
}

export interface VCalendar extends Vue {
  focusDate: (date: Date) => void;
  move: (date: Date) => void;
}

export interface PublicHolidayInterface {
  date: string;
  duration: number;
}
