import WeekSchedule from "./_views/WeekSchedule.vue";
import { RouteInterface } from "@/common/types";

export default [
  {
    path: "schedule",
    component: WeekSchedule,
    name: "week-schedule",
    meta: { auth: true },
  },
] as Array<RouteInterface>;
