<template>
  <a-row>
    <a-col :lg="11">
      <a-form-item
        :label-col="{ span: 10 }"
        :wrapper-col="{ span: 16 }"
        :colon="false"
        label="Custom Filters"
      >
        <a-select
          v-model="selectedCustomFilterId"
          :colon="false"
          allow-clear
          show-search
          placeholder="Apply customized filter"
          option-filter-prop="children"
        >
          <a-select-option v-for="f in bookmarkedUserFilters" :key="f.id">
            {{ f?.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-col>
    <a-col :lg="11">
      <a-form-item
        v-if="!filterIsEmpty"
        :label-col="{ span: 10 }"
        :wrapper-col="{ span: 16 }"
        label="Organise Filters"
      >
        <div v-if="!updateCustomFilterMode && !createNewCustomFilterMode">
          <span v-if="selectedCustomFilterId">
            <a-button class="mr-2" @click="enterUpdateCustomFilterMode()">Update</a-button>
            <a-button type="danger" class="mr-2" @click="handleDeleteCustomFilter()">
              Delete
            </a-button>
          </span>
          <a-button type="primary" class="mb-2" @click="enterCreateCustomFilterMode()">
            New
          </a-button>
        </div>
        <div v-if="updateCustomFilterMode && newCustomFilter">
          <a-input v-model="newCustomFilter.name" class="mb-2" />
          <a-button @click="handleCancelUpdateCustomFilterMode()">Cancel</a-button>
          <a-button type="primary" class="ml-3" @click="handleUpdateCustomFilter()">Save</a-button>
        </div>
        <div v-if="createNewCustomFilterMode && newCustomFilter">
          <a-input
            v-model="newCustomFilter.name"
            placeholder="Enter a name of a filter"
            class="mb-2"
          />
          <a-button @click="handleCancelNewCustomFilterMode()">Cancel</a-button>
          <a-button type="primary" class="ml-3" @click="handleSaveCustomFilter()">Save</a-button>
        </div>
      </a-form-item>
    </a-col>
  </a-row>
</template>

<script setup lang="ts">
import { ref, computed, watch } from "vue";
import { BookmarkedFilterInterface, BookmarkedFilterPayloadInterface } from "../types";
import router from "@/router";

const props = defineProps({
  bookmarkedFilters: { type: Array<BookmarkedFilterInterface>, default: () => [] },
  filterIsEmpty: { type: Boolean, default: false },
  bookmarkType: { type: String, default: undefined },
});

const emits = defineEmits([
  "selected",
  "delete-bookmark-filter",
  "update-bookmark-filter",
  "create-bookmark-filter",
]);

// Data properties
const selectedCustomFilter = ref<BookmarkedFilterInterface | null | undefined>(undefined);
const selectedCustomFilterId = ref<number | undefined>(undefined);
const createNewCustomFilterMode = ref(false);
const updateCustomFilterMode = ref(false);
const newCustomFilter = ref<Partial<BookmarkedFilterPayloadInterface | undefined>>({
  id: undefined,
  name: undefined,
  filter_url: undefined,
});

// Computed properties
const bookmarkedUserFilters = computed(() => {
  return props.bookmarkedFilters.filter(
    (f: BookmarkedFilterInterface) => f.bookmark_type === props.bookmarkType
  );
});

// Class methods
const handleCustomFilterSelected = (filterId: number): void => {
  if (filterId) {
    selectedCustomFilter.value = props.bookmarkedFilters.find(
      (f: BookmarkedFilterInterface) => f.id === filterId
    );
    newCustomFilter.value = setObject(newCustomFilter.value, selectedCustomFilter.value);
    if (selectedCustomFilter.value) emits("selected", selectedCustomFilter.value.filter_url);
  } else {
    resetNewCustomFilter();
    emits("selected", undefined);
  }
};

const enterUpdateCustomFilterMode = (): void => {
  updateCustomFilterMode.value = true;
  newCustomFilter.value = setObject(newCustomFilter.value || {}, selectedCustomFilter.value || {});
  newCustomFilter.value!.id = selectedCustomFilterId.value;
};

const handleDeleteCustomFilter = (): void => {
  emits("delete-bookmark-filter", selectedCustomFilterId.value);
  selectedCustomFilterId.value = undefined;
  resetNewCustomFilter();
};

const handleCancelUpdateCustomFilterMode = (): void => {
  resetNewCustomFilter();
};

const handleUpdateCustomFilter = (): void => {
  newCustomFilter.value!.filter_url = router.currentRoute.fullPath;
  emits("update-bookmark-filter", newCustomFilter.value);
  resetNewCustomFilter();
};

const handleSaveCustomFilter = (): void => {
  newCustomFilter.value!.filter_url = router.currentRoute.fullPath;
  newCustomFilter.value!.bookmark_type = props.bookmarkType;
  emits("create-bookmark-filter", newCustomFilter);
  resetNewCustomFilter();
};

const enterCreateCustomFilterMode = (): void => {
  createNewCustomFilterMode.value = true;
};

const handleCancelNewCustomFilterMode = (): void => {
  resetNewCustomFilter();
};

const resetNewCustomFilter = (): void => {
  createNewCustomFilterMode.value = false;
  updateCustomFilterMode.value = false;
  newCustomFilter.value = {
    name: null,
    filter_url: null,
  };
};

// Helper function to copy properties from source to target object
const setObject = <T,>(target: T, source: Partial<T>): T => {
  return Object.assign({}, target, source);
};

//Watch
watch(selectedCustomFilterId, (filterId) => {
  if (filterId !== null) {
    handleCustomFilterSelected(filterId as number);
  }
});
</script>
