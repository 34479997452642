<template>
  <div>
    <div v-if="filterPanelVisible" class="card mb-3">
      <a-form layout="vertical">
        <bookmarked-filters-selection
          :bookmarked-filters="bookmarkedFilters"
          :filter-is-empty="filterIsEmpty"
          bookmark-type="Projects"
          class="ml-3"
          @create-bookmark-filter="handleCreateBookmarkFilter"
          @update-bookmark-filter="handleUpdateBookmarkFilter"
          @delete-bookmark-filter="handleDeleteBookmarkFilter"
        />
        <a-row>
          <a-col :lg="11" class="ml-3">
            <SkNameSelect
              v-model="newFilter.project_id"
              label="Projects"
              :label-col-size="10"
              mode="multiple"
              placeholder="Select projects"
              :allow-clear="true"
              :options="projectsCompactList"
              :disabled="unchangeableFilter && unchangeableFilter.project_id !== undefined"
              @change="applyFilters"
            />

            <a-form-item :label-col="{ span: 10 }" :wrapper-col="{ span: 16 }" label="Type">
              <a-select
                v-model="newFilter.project_type"
                allow-clear
                placeholder="Select type"
                :disabled="unchangeableFilter && unchangeableFilter.project_type !== undefined"
                @change="applyFilters"
              >
                <a-select-option value="sk_for_hire">SK for hire</a-select-option>
                <a-select-option value="expert_for_hire">Expert for hire</a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item :label-col="{ span: 10 }" :wrapper-col="{ span: 16 }" label="Status">
              <a-select
                v-model="newFilter.status"
                allow-clear
                placeholder="Select status"
                :disabled="unchangeableFilter && unchangeableFilter.status !== undefined"
                @change="applyFilters"
              >
                <a-select-option value="active">Active</a-select-option>
                <a-select-option value="pending">Pending</a-select-option>
                <a-select-option value="closed">Closed</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :lg="11" class="ml-3">
            <SkNameSelect
              v-model="newFilter.user_id"
              label="Users"
              :label-col-size="10"
              mode="multiple"
              placeholder="Select users"
              :allow-clear="true"
              :options="usersCompactList"
              :disabled="unchangeableFilter && unchangeableFilter.user_id !== undefined"
              @change="applyFilters"
            />

            <SkNameSelect
              v-model="newFilter.client_id"
              label="Clients"
              :label-col-size="10"
              mode="multiple"
              placeholder="Select clients"
              :allow-clear="true"
              :options="clientsCompactList"
              :disabled="unchangeableFilter && unchangeableFilter.client_id !== undefined"
              @change="applyFilters"
            />

            <a-form-item class="d-flex filter-checkbox-sm-width" label="Editable">
              <a-checkbox
                v-model="newFilter.editable"
                false-value="undefined"
                class="ml-3"
                :disabled="unchangeableFilter && unchangeableFilter.editable !== undefined"
                @change="applyFilters"
              />
            </a-form-item>

            <a-form-item class="d-flex filter-checkbox-sm-width" label="Pending hours">
              <a-checkbox
                v-model="newFilter.pending_hours"
                false-value="undefined"
                class="ml-3"
                :disabled="unchangeableFilter && unchangeableFilter.pending_hours !== undefined"
                @change="applyFilters"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>

      <a-row class="d-flex justify-content-end">
        <span>
          <strong>Results:</strong>
          {{ numberOfResults }}
        </span>
      </a-row>
    </div>
    <portal to="projects-filter-buttons">
      <a-button
        v-if="filterPanelVisible"
        class="mr-2"
        icon="close"
        type="danger"
        @click="() => clearFilters()"
      />
      <a-button
        v-show="filterPanelVisible"
        class="mr-2"
        type="primary"
        icon="up"
        @click="() => toggleFilterPanel()"
      />
      <a-button
        v-if="!filterPanelVisible"
        class="mr-2"
        type="primary"
        icon="filter"
        @click="() => toggleFilterPanel()"
      />
    </portal>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, computed, getCurrentInstance } from "vue";
import BookmarkedFiltersSelection from "../../bookmarked_filters/_components/BookmarkedFiltersSelection.vue";
import { getProjectsCompactList, getClientsCompactList, getUsersCompactList } from "@/api";
import { BookmarkedFilterInterface } from "@/modules/bookmarked_filters/types";
import { MinimalProjectInterface, ProjectsFilterInterface } from "../types";
import { MinimalClientInterface } from "@/modules/clients/types";
import { ProfileInterface } from "../../authentication/types";
import { MinimalUserInterface } from "@/modules/users/types";
import {
  apiGetUserBookmarkedFilters,
  apiStoreUserBookmarkedFilter,
  apiUpdateUserBookmarkedFilter,
  apiDestroyUserBookmarkedFilter,
} from "@/modules/bookmarked_filters/_utils/api";
import useMixin from "@/useMixin";

// Props
const props = defineProps({
  visible: { type: Boolean, default: true },
  filters: { type: Object as () => ProjectsFilterInterface, default: undefined },
  profile: { type: Object as () => ProfileInterface, default: undefined },
  numberOfResults: { type: Number, default: 0 },
  unchangeableFilter: { type: Object as () => ProjectsFilterInterface, default: undefined },
});

// Emits
const emits = defineEmits(["apply-filters"]);

// Mixins
const { qsDecode, setObject } = useMixin();

// Instances
const instance = getCurrentInstance();
const $message = instance?.proxy.$message;

// Data properties
const filterPanelVisible = ref<boolean>(false);
const projectsCompactList = ref<Array<MinimalProjectInterface>>([]);
const clientsCompactList = ref<Array<MinimalClientInterface>>([]);
const usersCompactList = ref<Array<MinimalUserInterface>>([]);
const newFilter = ref<ProjectsFilterInterface>({
  project_id: [],
  project_type: undefined,
  status: undefined,
  user_id: [],
  client_id: [],
  editable: undefined,
  pending_hours: undefined,
});
const bookmarkedFilters = ref<Array<BookmarkedFilterInterface>>([]);

// Computed properties
const filterIsEmpty = computed((): boolean => {
  return Boolean(
    newFilter.value.project_id &&
      newFilter.value.client_id &&
      newFilter.value.pending_hours &&
      newFilter.value.project_id.length === 0 &&
      newFilter.value.client_id.length === 0 &&
      newFilter.value.project_type === undefined &&
      newFilter.value.status === undefined &&
      newFilter.value.user_id === undefined &&
      newFilter.value.editable !== true &&
      newFilter.value.pending_hours !== true
  );
});

// Watchers
watch(filterPanelVisible, (newProp, oldProp) => {
  if (newProp && !oldProp) {
    handleGetProjectsCompactList();
    handleGetUsersCompactList();
    handleGetClientsCompactList();
    setFilter(props.filters);
  }
});

// Component methods
const toggleFilterPanel = (): void => {
  filterPanelVisible.value = !filterPanelVisible.value;
};

const handleGetProjectsCompactList = (): void => {
  getProjectsCompactList().then((projects) => {
    projectsCompactList.value = projects;
  });
};

const handleGetClientsCompactList = (): void => {
  getClientsCompactList().then((clients) => {
    clientsCompactList.value = clients;
  });
};

const handleGetUsersCompactList = (): void => {
  getUsersCompactList().then((users) => {
    usersCompactList.value = users;
  });
};

const handleCustomFilterSelected = (bookmarkFilterUrl: string): void => {
  handleClearProjectsFilter();
  if (bookmarkFilterUrl) {
    emits("apply-filters", qsDecode(bookmarkFilterUrl).filters);
  } else {
    applyFilters();
  }
};

const applyFilters = (): void => {
  emits("apply-filters", filterIsEmpty.value ? {} : newFilter.value);
};

const clearFilters = (): void => {
  handleClearProjectsFilter();
  emits("apply-filters", {});
};

const handleClearProjectsFilter = (): void => {
  newFilter.value = {
    project_type: null,
    status: null,
    user_id: [],
    project_id: [],
    client_id: [],
    editable: null,
    pending: null,
  };
  newFilter.value = { ...props.unchangeableFilter };
};

const setFilter = (filter: any): void => {
  if (filter === undefined) {
    return;
  }
  newFilter.value = setObject(newFilter.value, filter);

  if (filter.editable === "false") {
    newFilter.value.editable = false;
  } else if (filter.editable === "true") {
    newFilter.value.editable = true;
  }

  if (filter.pending_hours === "false") {
    newFilter.value.pending_hours = false;
  } else if (filter.pending_hours === "true") {
    newFilter.value.pending_hours = true;
  }
};

const handleGetBookmarkedFilters = (): void => {
  apiGetUserBookmarkedFilters(props.profile.id).then(
    ({ data }: any) => (bookmarkedFilters.value = data.data)
  );
};

const handleCreateBookmarkFilter = (bookmarkFilter: any): void => {
  apiStoreUserBookmarkedFilter(props.profile.id, bookmarkFilter)
    .then(({ data }: any) => bookmarkedFilters.value.push(data.data))
    .then(() => $message?.success("New filter created successfully!", 3))
    .catch(() => $message?.error("Couldn't create new filter!", 3));
};

const handleUpdateBookmarkFilter = (bookmarkFilterId: number, bookmarkFilter: any): void => {
  apiUpdateUserBookmarkedFilter(props.profile.id, bookmarkFilterId, bookmarkFilter)
    .then(
      ({ data }: any) =>
        (bookmarkedFilters.value = bookmarkedFilters.value.map((f: any) =>
          f.id === data.data.id ? data.data : f
        ))
    )
    .then(() => $message?.success("Filter updated successfully!", 3))
    .catch(() => $message?.error("Couldn't update filter!", 3));
};

const handleDeleteBookmarkFilter = (bookmarkFilterId: number) => {
  apiDestroyUserBookmarkedFilter(props.profile.id, bookmarkFilterId)
    .then(
      () =>
        (bookmarkedFilters.value = bookmarkedFilters.value.filter(
          (f: any) => f.id !== bookmarkFilterId
        ))
    )
    .then(() => $message?.success("Filter removed successfully!", 3))
    .catch(() => $message?.error("Couldn't remove filter!", 3));
};
</script>
