<template>
  <a-layout-content class="my-4 mx-3">
    <div class="container">
      <div class="d-flex justify-content-between">
        <h2>Transactions</h2>
        <div class="d-flex">
          <a-button
            v-if="bulkUpdateTransactionsButtonVisible"
            type="default"
            class="mr-4"
            @click="bulkUpdateTransactionsModalVisible = true"
          >
            Bulk update
          </a-button>
          <a-button
            :loading="downloadingCsv"
            class="mr-2"
            icon="download"
            type="default"
            @click="handleDownloadCsv"
          />
          <portal-target class="mr-2" name="transactions-filter-buttons"></portal-target>
          <a-button
            v-if="baseAcl.isFinancial"
            class="ml-2"
            icon="plus"
            type="primary"
            @click="addTransactionModalVisible = true"
          />
        </div>
      </div>

      <transactions-filter
        :number-of-results="transactionsPagination?.total"
        :aggregated-amount="aggregatedAmount"
        :vacational-amount="vacationalAmount"
        :for-transactions="true"
        :profile="profile"
        :filters="filtersRef"
        @apply-filters="(filters) => applyFilters(filters)"
      />

      <add-transaction-modal
        :visible="addTransactionModalVisible"
        :users="usersCompactList"
        :user="user"
        :receipts="receiptsCompactList"
        @get-user="(userId) => handleGetUser(userId)"
        @get-users="() => handleGetUsersCompactList()"
        @get-receipts="handleGetReceiptsCompactList"
        @store-transaction="(newTransaction) => handleStoreTransaction(newTransaction)"
        @close="addTransactionModalVisible = false"
      />

      <div class="card">
        <transactions-table
          :transactions-page="transactionsPage"
          :current-page="currentPage"
          :users="usersCompactList"
          :user="user"
          :with-actions-column="baseAcl.isFinancial"
          :loading-table="loadingTable"
          :transactions-pagination="transactionsPagination"
          :receipts="receiptsCompactList"
          :page-size="pageSizeRef"
          :bulk-update-transactions-modal-visible="bulkUpdateTransactionsModalVisible"
          @get-user="(userId) => handleGetUser(userId)"
          @get-users="() => handleGetUsersCompactList()"
          @apply-sorting="(newSorting) => applySorting(newSorting)"
          @get-transactions-page="(params) => getTransactionsPage(params)"
          @destroy-transaction="(transactionId) => handleDestroyTransaction(transactionId)"
          @update-transaction="(transaction) => handleUpdateTransaction(transaction)"
          @bulk-update-transactions="
            (transactionIds, newStatus) => handleBulkUpdateTransactions(transactionIds, newStatus)
          "
          @set-bulk-update-transactions-button="
            (value) => (bulkUpdateTransactionsButtonVisible = value)
          "
          @close-bulk-update-transactions-modal="bulkUpdateTransactionsModalVisible = false"
          @get-receipts="() => handleGetReceiptsCompactList"
          @update-receipt="(updatedReceipt) => handleUpdateReceipt(updatedReceipt)"
          @store-transaction="(newTransaction) => handleStoreTransaction(newTransaction)"
          @store-recurring-job="(newRecurringJob) => handleStoreRecurringJob(newRecurringJob)"
        />
      </div>
    </div>
  </a-layout-content>
</template>

<script lang="ts">
import { defineComponent, ref, getCurrentInstance, computed, onBeforeMount } from "vue";
import { BaseAclInterface, ProfileInterface } from "@/modules/authentication/types";
import AddTransactionModal from "../_components/AddTransactionModal.vue";
import TransactionsFilter from "../_components/TransactionsFilter.vue";
import TransactionsTable from "../_components/TransactionsTable.vue";
import { apiUpdateUserReceipt } from "@/modules/receipts/_utils/api";
import { getUsersCompactList, getReceiptsCompactList } from "@/api";
import { useAuthenticationStore } from "@/modules/authentication/_store";
import { ReceiptInterface } from "@/modules/receipts/types";
import { TransactionsFilterInterface } from "../types";
import { PaginationInterface } from "@/common/types";
import { apiGetUser } from "../../users/_utils/api";
import { downloadCsv } from "@/utils";
import useMixin from "@/useMixin";
import {
  apiGetTransactions,
  apiUpdateTransaction,
  apiDestroyTransaction,
  apiStoreTransaction,
  apiBulkUpdateTransactions,
} from "../_utils/api";
import { useBookmarkedFiltersStore } from "@/modules/bookmarked_filters/_store";
import { useRecurringJobsStore } from "@/modules/recurring_jobs/_store";

export default defineComponent({
  components: { AddTransactionModal, TransactionsTable, TransactionsFilter },
  // Redirect external users to dashboard view
  beforeRouteEnter(to: any, from: any, next: any) {
    const authenticationStore = useAuthenticationStore();
    authenticationStore.isFinancial || authenticationStore.isAdmin
      ? next()
      : next({ name: "dashboard" });
  },
  setup() {
    // Pinia
    const authenticationStore = useAuthenticationStore();
    const bookmarkedFiltersStore = useBookmarkedFiltersStore();
    const recurringJobsStore = useRecurringJobsStore();
    const profile = computed<ProfileInterface | undefined>(() => authenticationStore.profile);
    const baseAcl = computed<BaseAclInterface>(() => authenticationStore.baseAcl);

    // Mixins
    const { qsDecode, loadTablePageSize, syncRouteParams, persistTablePageSize } = useMixin();

    // Instances
    const instance = getCurrentInstance();
    const $route = instance?.proxy?.$route;
    const $message = instance?.proxy?.$message;

    // Data properties
    const transactionsPagination = ref<PaginationInterface | undefined>(undefined);
    const filtersRef = ref<TransactionsFilterInterface>({ currency: "SEK" });
    const bulkUpdateTransactionsButtonVisible = ref<boolean>(false);
    const bulkUpdateTransactionsModalVisible = ref<boolean>(false);
    const receiptsCompactList = ref<Array<ReceiptInterface>>([]);
    const user = ref<ProfileInterface | undefined>(undefined);
    const addTransactionModalVisible = ref<boolean>(false);
    const usersCompactList = ref<Array<any>>([]);
    const transactionsPage = ref<Array<any>>([]);
    const aggregatedSummary = ref<any | {}>({});
    const downloadingCsv = ref<boolean>(false);
    const loadingTable = ref<boolean>(false);
    const pageSizeRef = ref<number>(25);
    const currentPage = ref<number>(1);
    const sortingRef = ref<object>({});

    // Computed properties
    const aggregatedAmount = computed((): number => {
      return aggregatedSummary.value.aggregated_amount;
    });

    const vacationalAmount = computed((): number => {
      return aggregatedSummary.value.vacational_amount;
    });

    // Lifecycle hooks
    onBeforeMount(() => {
      bookmarkedFiltersStore.getUserBookmarkedFilters(profile.value?.id as number);
      getTransactionsPageFromURL();
    });

    // Class methods
    const handleGetUser = (userId: number): void => {
      apiGetUser(userId).then((res: any) => (user.value = res.data.data));
    };

    const getTransactionsPageFromURL = (url: any = $route?.query): void => {
      const { page, filters, sorting, pageSize } = qsDecode(url);

      pageSizeRef.value = loadTablePageSize();

      if (page) currentPage.value = parseInt(page);
      if (filters) filtersRef.value = filters;
      if (sorting) sortingRef.value = sorting;
      if (pageSize) pageSizeRef.value = parseInt(pageSize);

      getTransactionsPage({ page: currentPage.value });
    };

    const getTransactionsPage = ({
      page,
      pageSize = pageSizeRef.value,
      filters = filtersRef.value,
      sorting = sortingRef.value,
    }: {
      page: any;
      pageSize?: number;
      filters?: object;
      sorting?: object;
    }): void => {
      loadingTable.value = true;
      currentPage.value = page;
      pageSizeRef.value = pageSize;

      syncRouteParams(
        {
          page: currentPage.value,
          pageSize: pageSizeRef.value,
          filters: filtersRef.value,
          sorting: sortingRef.value,
        },
        instance
      );
      persistTablePageSize(pageSize);

      apiGetTransactions({ page, page_size: pageSize, ...filters, ...sorting })
        .then(({ data }: any) => {
          currentPage.value = page;
          transactionsPage.value = data.data;
          transactionsPagination.value = data.meta;
          aggregatedSummary.value = data.meta.aggregated_summary;
        })
        .then(() => (loadingTable.value = false));
    };

    const applyFilters = (filters: TransactionsFilterInterface): any => {
      filtersRef.value = filters;
      currentPage.value = 1;
      syncRouteParams(
        {
          page: currentPage.value,
          pageSize: pageSizeRef.value,
          filters: filtersRef.value,
          sorting: sortingRef.value,
        },
        instance
      );
      getTransactionsPage({ page: currentPage.value });
    };

    const applySorting = (sorting: object): any => {
      sortingRef.value = sorting;
      syncRouteParams(
        {
          page: currentPage.value,
          pageSize: pageSizeRef.value,
          filters: filtersRef.value,
          sorting: sortingRef.value,
        },
        instance
      );
      getTransactionsPage({ page: currentPage.value });
    };

    const handleStoreTransaction = (newTransaction: any): void => {
      apiStoreTransaction(newTransaction)
        .then(() => getTransactionsPage({ page: currentPage.value }))
        .then(() => {
          addTransactionModalVisible.value = false;
          $message?.success("Transaction created successfully!", 3);
        })
        .catch(() => $message?.error("Couldn't create transaction!", 3));
    };

    const handleUpdateTransaction = (updatedTransaction: any): void => {
      apiUpdateTransaction(updatedTransaction.id, updatedTransaction)
        .then(() => getTransactionsPage({ page: currentPage.value }))
        .then(() => $message?.success("Transaction updated successfully!", 3))
        .catch(() => $message?.error("Couldn't update transaction!", 3));
    };

    const handleBulkUpdateTransactions = (
      transactionIds: Array<number>,
      newStatus: string
    ): void => {
      apiBulkUpdateTransactions(transactionIds, newStatus)
        .then((res: any) => {
          const nbTransactionsUpdated = res.data.nb_transactions_updated;
          if (nbTransactionsUpdated !== transactionIds.length) {
            $message?.error(
              `Only ${nbTransactionsUpdated} out of ${transactionIds.length} transactions updated successfully!`,
              3
            );
          }
        })
        .then(() => getTransactionsPage({ page: currentPage.value }))
        .then(() => $message?.success("Transactions updated successfully!", 3))
        .catch(() => $message?.error("Couldn't update transactions!", 3));
    };

    const handleDestroyTransaction = (transactionId: number): any => {
      apiDestroyTransaction(transactionId)
        .then(() => getTransactionsPage({ page: currentPage.value }))
        .then(() => $message?.success("Transaction deleted successfully!", 3))
        .catch(() => $message?.error("Couldn't delete transaction!", 3));
    };

    const handleGetUsersCompactList = (): void => {
      getUsersCompactList().then((users) => (usersCompactList.value = users));
    };

    const handleGetReceiptsCompactList = (userId: number): void => {
      getReceiptsCompactList({ only_usable: true, user_id: userId }).then(
        (receipts) => (receiptsCompactList.value = receipts)
      );
    };

    const handleUpdateReceipt = (updatedReceipt: ReceiptInterface): void => {
      apiUpdateUserReceipt(updatedReceipt)
        .then(() => getTransactionsPage({ page: currentPage.value }))
        .then(() => $message?.success("Receipt updated successfully!", 3))
        .catch(() => $message?.error("Couldn't update receipt!", 3));
    };

    const handleDownloadCsv = (): void => {
      downloadingCsv.value = true;

      apiGetTransactions(filtersRef.value, { Accept: "text/csv" })
        .then((res: any) =>
          downloadCsv(res.data, "transactions", !!Object.keys(filtersRef.value).length)
        )
        .catch((err: any) => $message?.error("Couldn't download CSV file!", 3))
        .finally(() => (downloadingCsv.value = false));
    };

    const handleStoreRecurringJob = (newRecurringJob: any): void => {
      recurringJobsStore
        .storeRecurringJob(newRecurringJob)
        .then(() => $message?.success("Recurring transaction added successfully!", 3))
        .catch(() => $message?.error("Couldn't create recurring transaction!", 3));
    };

    return {
      bulkUpdateTransactionsButtonVisible,
      bulkUpdateTransactionsModalVisible,
      handleGetReceiptsCompactList,
      handleBulkUpdateTransactions,
      addTransactionModalVisible,
      handleGetUsersCompactList,
      handleDestroyTransaction,
      handleUpdateTransaction,
      handleStoreRecurringJob,
      handleStoreTransaction,
      transactionsPagination,
      receiptsCompactList,
      getTransactionsPage,
      handleUpdateReceipt,
      handleDownloadCsv,
      aggregatedAmount,
      vacationalAmount,
      usersCompactList,
      transactionsPage,
      downloadingCsv,
      handleGetUser,
      applyFilters,
      loadingTable,
      applySorting,
      currentPage,
      pageSizeRef,
      filtersRef,
      baseAcl,
      profile,
      user,
    };
  },
});
</script>
