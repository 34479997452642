<template>
  <div class="card">
    <projects-view
      ref="projectsview"
      :acl="acl"
      :projects-filter="userProjectsFilter"
      :projects-columns="myProjectsColumns"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, watch, toRefs } from "vue";
import { BaseAclInterface } from "@/modules/authentication/types";
import ProjectsView from "../_components/ProjectsView.vue";
import { myProjectsColumns } from "../_utils/tables";
import { ProjectsFilterInterface } from "../types";

// Props
const props = defineProps({
  acl: { type: Object as () => BaseAclInterface, default: undefined },
  userId: { type: Number, default: undefined },
  tab: { type: String, default: undefined },
});

// Data properties
const { userId } = toRefs(props);
const userProjectsFilter = ref<ProjectsFilterInterface>({ user_id: [userId.value] });
const projectsview = ref<InstanceType<typeof ProjectsView>>();

// Watchers
watch(
  () => props.tab,
  (newProp, oldProp) => {
    if (newProp === "projects" && oldProp !== "projects") {
      projectsview.value?.refreshProjects();
    }
  }
);
</script>
