import { isAuthenticated } from "@/network";
import Router from "vue-router";
import "./monkey";
import routes from "./routes";
import Vue from "vue";
import { useCommonStore } from "@/modules/common/_store";

Vue.use(Router);

// Create Vue router instance with the given routes (routes.js).
// - Use HTML5 History Mode
// - Scroll to top when access a new view
const router = new Router({
  routes,
  mode: "history",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

// Guard routes that require authentication against unauthenticated users.
// Redirect unauthenticated users to login page. In order to define if a route
// should be protected we use the 'meta.auth' field (see routes.js).
router.beforeEach(function (to, _, next) {
  const commonStore = useCommonStore();
  commonStore.resetSearchMode();
  commonStore.resetSearchQuery();
  if (to.meta && to.meta.auth !== undefined) {
    if (to.meta.auth && !isAuthenticated()) {
      // Redirect to login route if target requires auth but user is not authenticated
      return next({ name: "login" });
    } else if (!to.meta.auth && isAuthenticated()) {
      // Redirect to dashboard route if target does not require auth but user is authenticated
      return next({ name: "dashboard" });
    }
  }

  return next();
});

export default router;
