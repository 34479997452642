import { defineStore, acceptHMRUpdate } from "pinia";
import { SkillInterface } from "../types";
import {
  apiGetSkills,
  apiGetUserSkills,
  apiStoreUserSkill,
  apiUpdateUserSkill,
  apiDestroyUserSkill,
} from "../_utils/api";

export interface StateInterface {
  user_skills: Array<any>;
  skills: Array<SkillInterface>;
}

export const useSkillsStore = defineStore("skills", {
  state: (): StateInterface => {
    return {
      user_skills: [],
      skills: [],
    };
  },
  actions: {
    getSkills() {
      return new Promise((resolve, reject) => {
        apiGetSkills()
          .then((res: any) => {
            this.setSkills(res.data.data);
            resolve(res.data.data);
          })
          .catch((err: any) => {
            reject(err);
          });
      });
    },

    getUserSkills(userId: number) {
      return new Promise((resolve, reject) => {
        apiGetUserSkills(userId)
          .then((res: any) => {
            this.setUserSkills(res.data.data);
            resolve(res.data.data);
          })
          .catch((err: any) => {
            reject(err);
          });
      });
    },

    storeUserSkill({ userId, newUserSkill }: any) {
      return new Promise((resolve, reject) => {
        apiStoreUserSkill(userId, newUserSkill)
          .then((res: any) => {
            this.addUserSkill(res.data.data);
            resolve(res.data.data);
          })
          .catch((err: any) => {
            reject(err);
          });
      });
    },

    updateUserSkill({ userId, userSkillId, updatedUserSkill }: any) {
      return new Promise((resolve, reject) => {
        apiUpdateUserSkill(userId, userSkillId, updatedUserSkill)
          .then((res: any) => {
            this.handleUpdateUserSkill(res.data.data);
            resolve(res.data.data);
          })
          .catch((err: any) => {
            reject(err);
          });
      });
    },

    destroyUserSkill({ userId, userSkillId }: any) {
      return new Promise<void>((resolve, reject) => {
        apiDestroyUserSkill(userId, userSkillId)
          .then(() => {
            this.removeUserSkill(userSkillId);
            resolve();
          })
          .catch((err: any) => {
            reject(err);
          });
      });
    },

    setUserSkills(userSkills: any) {
      this.user_skills = userSkills;
    },

    addUserSkill(newUserSkill: any) {
      this.user_skills = [...this.user_skills, newUserSkill];
    },

    handleUpdateUserSkill(updatedUserSkill: any) {
      this.user_skills = this.user_skills.map((s) => {
        return s.id === updatedUserSkill.id ? updatedUserSkill : s;
      });
    },

    removeUserSkill(userSkillId: number) {
      this.user_skills = this.user_skills.filter((s) => s.id !== userSkillId);
    },

    setSkills(skills: Array<SkillInterface>) {
      this.skills = skills;
    },
  },
});

// HMR
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSkillsStore, import.meta.hot));
}
