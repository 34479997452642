<template>
  <a-layout-content class="my-4 mx-3">
    <div v-if="project" class="container">
      <!-- HEADER -->
      <project-header
        :project="project"
        :subprojects="subprojectsPage"
        :users="usersCompactList"
        :current-user="profile"
        :cost-centers="costCenters"
        :clients="clientsCompactList"
        :acl="acl"
        :skills="skills"
        @store-expense-report="handleStoreExpenseReport"
        @update-project="handleUpdateProject"
        @store-project-role="handleStoreProjectRole"
        @destroy-project="handleDestroyProject"
        @request-invoice="handleRequestInvoice"
        @get-users="handleGetUsersCompactList"
        @get-skills="getSkills"
        @get-clients="handleGetClientsCompactList"
        @get-cost-centers="() => getCostCenters()"
        @get-subprojects-page="handleGetSubprojectsPage"
      />

      <!-- TABS -->
      <a-tabs :active-key="tab" @change="handleTabChanged">
        <a-tab-pane key="overview" tab="Overview">
          <overview-tab
            :project="project"
            :users="usersCompactList"
            :clients="clientsCompactList"
            :acl="acl"
            @get-users="handleGetUsersCompactList"
            @get-clients="handleGetClientsCompactList"
            @update-project="handleUpdateProject"
            @destroy-project="handleDestroyProject"
          />
        </a-tab-pane>

        <a-tab-pane key="roles" tab="Roles" class="card">
          <roles-view
            ref="rolesview"
            title="Project Roles"
            :subprojects="subprojectsPage"
            :project="project"
            :acl="acl"
            :roles-filter="projectRolesFilter"
            :roles-columns="projectRolesColumns"
            @get-subprojects-page="handleGetSubprojectsPage"
          />
        </a-tab-pane>

        <a-tab-pane v-if="acl.isInternal" key="reports" tab="Reports">
          <reports-tab
            :acl="acl"
            :reports-page="reportsPage"
            :page-size="reportsPageSize"
            :current-page="currentPage"
            :reports-summary="reportsSummary"
            :subprojects-summary="subprojectsSummary"
            :users="usersCompactList"
            :current-user="profile"
            :project="project"
            :cost-centers="costCenters"
            :user-to-be-edited="user"
            :user-roles="userRoles"
            :loading-table="loadingTable"
            :loading-summary="loadingSummary"
            :reports-pagination="reportsPagination"
            :filters="filters"
            @get-reports-page="(params) => handleGetReportsPage(params)"
            @handle-destroy-report="handleDestroyReport"
            @store-expense-report="handleStoreExpenseReport"
            @handle-update-report="handleUpdateReport"
            @handle-edit-user-report-modal-visible="handleEditUserReportModalVisible"
            @get-cost-centers="() => getCostCenters()"
            @apply-filters="applyFilters"
            @clear-filters="clearFilters"
            @store-recurring-job="handleStoreRecurringJob"
          />
        </a-tab-pane>

        <a-tab-pane v-if="acl.isFinancial || acl.isCr" key="invoices" tab="Invoices" class="card">
          <invoices-view
            ref="invoicesview"
            title="Project invoices"
            :acl="baseAcl"
            :project-id="project.id"
            :invoices-columns="projectInvoicesColumns"
            @destroy-invoice="handleDestroyInvoice"
          />
        </a-tab-pane>

        <a-tab-pane
          v-if="
            acl.isAdmin || acl.isFinancial || acl.isCr || acl.isProjectManager || isAccountManager
          "
          key="budgets"
          tab="Budgets"
        >
          <budgets-tab
            :current-user="profile"
            :project="project"
            :budgets="budgetsPage"
            :cost-centers-report="costCentersReport"
            :loading-table="loadingTable"
            :budgets-pagination="budgetsPagination"
            :page-size="budgetsPageSize"
            @store-budget="handleStoreBudget"
            @update-budget="handleUpdateBudget"
            @destroy-budget="handleDestroyBudget"
            @get-budgets-page="handleGetProjectBudgetsPage"
          />
        </a-tab-pane>

        <a-tab-pane key="github" tab="GitHub">
          <github-tab :project="project" />
        </a-tab-pane>

        <a-tab-pane key="subprojects" tab="Subprojects">
          <subprojects-tab
            :acl="acl"
            :project="project"
            :subprojects="subprojectsPage"
            @store-subproject="handleStoreSubproject"
            @update-subproject="handleUpdateSubproject"
            @destroy-subproject="handleDestroySubproject"
            @get-subprojects-page="handleGetSubprojectsPage"
          />
        </a-tab-pane>
      </a-tabs>
    </div>
  </a-layout-content>
</template>

<script setup lang="ts">
import { ref, getCurrentInstance, computed, ComputedRef, watch, onMounted } from "vue";
import { ProjectInterface, ProjectPayloadInterface, CostCenterItemInterface } from "../types";
import { useAuthenticationStore } from "@/modules/authentication/_store";
import { ProfileInterface, BaseAclInterface } from "@/modules/authentication/types";
import { RoleInterface, RolesFilterInterface } from "@/modules/roles/types";
import { apiGetUser, apiGetUserRoles } from "@/modules/users/_utils/api";
import { projectRolesColumns } from "@/modules/roles/_utils/tables";
import { projectInvoicesColumns } from "@/modules/invoices/_utils/tables";
import { CostCenterInterface } from "@/modules/cost_centers/types";
import { apiStoreProjectInvoice } from "../../invoices/_utils/api";
import { getUsersCompactList, getClientsCompactList } from "@/api";
import RolesView from "@/modules/roles/_components/RolesView.vue";
import ProjectHeader from "../_components/ProjectHeader.vue";
import { MinimalUserInterface } from "@/modules/users/types";
import { PaginationInterface } from "../../../common/types";
import { useCostCentersStore } from "@/modules/cost_centers/_store";
import OverviewTab from "../_components/OverviewTab.vue";
import { SkillInterface } from "@/modules/skills/types";
import ReportsTab from "../_components/ReportsTab.vue";
import BudgetsTab from "../_components/BudgetsTab.vue";
import { ReportInterface } from "../../reports/types";
import GithubTab from "../_components/GithubTab.vue";
import SubprojectsTab from "../_components/SubprojectsTab.vue";
import { NewSubprojectInterface } from "../types";
import {
  apiDestroyProjectBudget,
  apiUpdateProjectBudget,
  apiStoreProjectBudget,
  apiGetProjectBudgets,
  apiGetProjectCostCenterReport,
} from "../../budgets/_utils/api";
import {
  apiGetProject,
  apiUpdateProject,
  apiDestroyProject,
  apiStoreProjectRole,
  apiStoreSubproject,
} from "../_utils/api";
import {
  apiGetSubprojects,
  apiGetSubproject,
  apiUpdateSubproject,
  apiDestroySubproject,
} from "@/modules/projects/_utils/api";
import { useReportsStore } from "@/modules/reports/_store";
import { useRecurringJobsStore } from "@/modules/recurring_jobs/_store";
import { useInvoicesStore } from "@/modules/invoices/_store";
import { useSkillsStore } from "@/modules/skills/_store";
import { useRoute, useRouter } from "vue-router/composables";
import InvoicesView from "@/modules/invoices/_components/InvoicesView.vue";
import { AxiosResponse } from "axios";

// Pinia
const authenticationStore = useAuthenticationStore();
const costCentersStore = useCostCentersStore();
const invoicesStore = useInvoicesStore();
const recurringJobStore = useRecurringJobsStore();
const reportsStore = useReportsStore();
const skillsStore = useSkillsStore();
const profile = computed<ProfileInterface | undefined>(() => authenticationStore.profile);
const baseAcl = computed<BaseAclInterface>(() => authenticationStore.baseAcl);
const costCenters = computed<Array<CostCenterInterface>>(() => costCentersStore.cost_centers);
const getCostCenters = () => costCentersStore.getCostCenters();
const reportsSummary = computed<Array<any>>(() => reportsStore.reportsSummary);
const subprojectsSummary = computed<Array<any>>(() => reportsStore.subprojectsSummary);
const reportsPagination = computed<any>(() => reportsStore.reportsPagination);
const skills = computed<Array<SkillInterface>>(() => skillsStore.skills);
const getSkills = () => skillsStore.getSkills();

// Instances
const route = useRoute();
const router = useRouter();
const instance = getCurrentInstance();
const $message = instance?.proxy?.$message;

// Data properties
const projectRolesFilter = ref<RolesFilterInterface | undefined>(undefined);
const budgetsPagination = ref<PaginationInterface | undefined>(undefined);
const costCentersReport = ref<Array<CostCenterItemInterface>>([]);
const usersCompactList = ref<Array<MinimalUserInterface>>([]);
const user = ref<ProfileInterface | undefined>(undefined);
const rolesview = ref<InstanceType<typeof RolesView>>();
const invoicesview = ref<InstanceType<typeof InvoicesView>>();
const reportsPage = ref<Array<ReportInterface>>([]);
const project = ref<ProjectInterface | null>(null);
const userRoles = ref<Array<RoleInterface>>([]);
const clientsCompactList = ref<Array<any>>([]);
const loadingSummary = ref<boolean>(false);
const loadingTable = ref<boolean>(false);
const reportsPageSize = ref<number>(25);
const budgetsPageSize = ref<number>(25);
const budgetsPage = ref<Array<any>>([]);
const tab = ref<string>("overview");
const currentPage = ref<number>(1);
const filters = ref<any>({});
const subprojectsPage = ref<Array<NewSubprojectInterface>>([]);

// Computed properties
// Combine base access control level (ACL) with project manager
const acl = computed((): BaseAclInterface & { isAccountManager: ComputedRef<boolean> } => {
  return { ...baseAcl.value, isAccountManager: isAccountManager };
});

// Determine if authenticated user is the project account manager
const isAccountManager = computed((): boolean => {
  return Boolean(
    project.value &&
      project.value.client.account_manager &&
      project.value.client.account_manager.id === profile.value?.id
  );
});

// Watchers
watch(route, () => {
  if (project.value && parseInt(route.params.projectId as string) != project.value.id) {
    apiGetProject(parseInt(route.params.projectId as string)).then((res: any) => {
      project.value = res.data.data;
    });
  }
  changeTab(route.hash ? route.hash : "#overview");
});

// Lifecycle hooks
onMounted(() => {
  const hash = route.hash;
  apiGetProject(parseInt(route.params.projectId as string)).then((res: any) => {
    project.value = res.data.data;
    changeTab(hash);
    projectRolesFilter.value = { project_id: [res.data.data.id] };
  });
});

// Component methods
const changeTab = (hash: any): void => {
  if (hash) {
    const t = hash.substring(1);
    if (tab.value !== t) {
      handleTabChanged(t);
    }
  }
};

const isTabValid = (tab: string): any => {
  const validTabs = ["overview", "roles", "github", "subprojects"];
  if (acl.value.isInternal) {
    validTabs.push("reports");
    validTabs.push("budgets");
  }
  if (acl.value.isFinancial || acl.value.isCr) {
    validTabs.push("invoices");
  }
  return validTabs.includes(tab);
};

const handleTabChanged = (t: string): void => {
  if (!isTabValid(t)) {
    t = "";
  }
  switch (t) {
    case "roles":
      rolesview.value?.refreshRoles();
      break;
    case "reports":
      handleGetReportsPage({
        page: 1,
        reportsSummary: true,
      });
      break;
    case "invoices":
      invoicesview.value?.refreshInvoices();
      break;
    case "budgets":
      {
        if (project.value) {
          handleGetProjectBudgetsPage({ page: 1 });
          handleGetProjectCostCenterReport();
        }
      }
      break;
  }
  tab.value = t;
  router.replace(t ? `#${t}` : "");
};

const handleGetReportsPage = ({
  page = 1,
  pageSize = 25,
  f = filters.value,
  reportsSummary,
}: {
  page: number;
  pageSize?: number;
  f?: any;
  reportsSummary?: boolean;
}): void => {
  reportsPageSize.value = pageSize;
  loadingTable.value = true;
  currentPage.value = page;
  if (reportsSummary) {
    loadingSummary.value = true;
  }
  if (project.value) {
    reportsStore
      .getReportsByPage({
        page,
        page_size: pageSize,
        project_id: project.value.id,
        ...f,
        reports_summary: reportsSummary,
        subprojects_summary: reportsSummary,
      })
      .then((res: any) => {
        loadingSummary.value = false;
        loadingTable.value = false;
        reportsPage.value = res;
      });
  }
};

const handleDestroyReport = (reportId: number): void => {
  reportsStore
    .destroyReport(reportId)
    .then(() => {
      reportsStore.clearReports();
      handleGetReportsPage({
        page: currentPage.value,
        reportsSummary: true,
      });
      $message?.success("Report deleted successfully!", 3);
    })
    .catch(() => {
      $message?.error("Couldn't delete report!", 3);
    });
};

const handleStoreExpenseReport = (newExpenseReport: any) => {
  reportsStore
    .storeReport(newExpenseReport)
    .then(() => {
      reportsStore.clearReports();
      handleGetReportsPage({
        page: currentPage.value,
      });
      $message?.success("Report stored successfully!", 3);
    })
    .catch(() => $message?.error("Couldn't store report!", 3));
};

const handleDestroyInvoice = (invoice: any): void => {
  if (project.value) {
    invoicesStore
      .destroyInvoice({
        projectId: project.value.id,
        invoiceId: invoice.id,
      })
      .then(() => invoicesview.value?.refreshInvoices())
      .then(() => $message?.success("Invoice removed successfully!", 3))
      .catch(() => $message?.error("Couldn't remove invoice!", 3));
  }
};

const handleUpdateReport = (updatedReport: any): void => {
  reportsStore
    .updateReport({
      reportId: updatedReport.id,
      updatedReport,
    })
    .then(() => {
      reportsStore.clearReports();
      handleGetReportsPage({
        page: currentPage.value,
        reportsSummary: true,
      });
      $message?.success("Report updated successfully!", 3);
    })
    .catch(() => $message?.error("Couldn't update report!", 3));
};

const handleEditUserReportModalVisible = (report: any): void => {
  apiGetUser(report.user.id).then((res: any) => {
    user.value = res.data.data;
  });
  apiGetUserRoles(report.user.id).then((res: any) => {
    userRoles.value = res.data.data;
  });
};

const handleUpdateProject = (updatedProject: ProjectPayloadInterface): void => {
  if (project.value) {
    apiUpdateProject(project.value.id, updatedProject)
      .then(() => {
        if (project.value) {
          apiGetProject(project.value.id).then((res: any) => (project.value = res.data.data));
        }
      })
      .then(() => $message?.success("Project updated successfully!", 3))
      .catch(() => $message?.error("Couldn't update project!", 3));
  }
};

const handleDestroyProject = (projectId: number): void => {
  apiDestroyProject(projectId)
    .then(() => {
      $message?.success("Project removed successfully!", 3);
      router.push({
        name: "projects",
      });
    })
    .catch(() => $message?.error("Couldn't remove project!", 3));
};

const handleStoreProjectRole = (newRole: RoleInterface): void => {
  if (project.value) {
    apiStoreProjectRole(project.value.id, newRole)
      .then(() => {
        if (project.value) {
          apiGetProject(project.value.id).then((res: any) => (project.value = res.data.data));
        }
      })
      .then(() => rolesview.value?.refreshRoles())
      .then(() => $message?.success("Project role created successfully!", 3))
      .catch(() => $message?.error("Couldn't create project role!", 3));
  }
};

const handleStoreRecurringJob = (recurringJob: any): void => {
  recurringJobStore
    .storeRecurringJob(recurringJob)
    .then(() => $message?.success("Recurring expense report added successfully!", 3))
    .catch(() => $message?.error("Couldn't create recurring expense report!", 3));
};

const handleRequestInvoice = (newInvoice: any): void => {
  if (project.value) {
    apiStoreProjectInvoice(project.value.id, newInvoice)
      .then(() => invoicesview.value?.refreshInvoices())
      .then(() => $message?.success("Invoice requested successfully!", 3))
      .catch(() => $message?.error("Couldn't request invoice!", 3));
  }
};

const handleGetClientsCompactList = (): void => {
  getClientsCompactList().then((clients) => (clientsCompactList.value = clients));
};

const handleGetUsersCompactList = (params: any): void => {
  getUsersCompactList(params).then((users) => (usersCompactList.value = users));
};

const clearFilters = (): void => {
  filters.value = {};
  handleGetReportsPage({
    page: currentPage.value,
    reportsSummary: true,
  });
};

const applyFilters = (f: any): void => {
  filters.value = f;
  handleGetReportsPage({
    page: currentPage.value,
    f: filters.value,
    reportsSummary: true,
  });
};

const handleGetProjectBudgetsPage = ({
  page = 1,
  pageSize = 25,
  f = filters.value,
}: {
  page: number;
  pageSize?: number;
  f?: any;
}) => {
  budgetsPageSize.value = pageSize;
  loadingTable.value = true;
  currentPage.value = page;
  if (project.value)
    apiGetProjectBudgets({
      page,
      page_size: pageSize,
      project_id: project.value.id,
      ...f,
    }).then((res: any) => {
      loadingTable.value = false;
      budgetsPage.value = res.data.data;
      budgetsPagination.value = res.data.meta;
    });
};

const handleGetProjectCostCenterReport = () => {
  if (project.value)
    apiGetProjectCostCenterReport(project.value.id).then((res: any) => {
      costCentersReport.value = res.data.data;
    });
};

const handleStoreBudget = (newBudget: any): void => {
  apiStoreProjectBudget(newBudget)
    .then(() => {
      handleGetProjectBudgetsPage({ page: currentPage.value });
      $message?.success("Budget stored successfully!", 3);
    })
    .catch(() => $message?.error("Couldn't store budget!", 3));
};

const handleDestroyBudget = (budgetId: number): void => {
  if (project.value)
    apiDestroyProjectBudget(project.value?.id as number, budgetId)
      .then(() => {
        handleGetProjectBudgetsPage({ page: currentPage.value });
        $message?.success("Budget removed successfully!", 3);
      })
      .catch(() => $message?.error("Couldn't remove budget!", 3));
};

const handleUpdateBudget = (updatedBudget: any): void => {
  apiUpdateProjectBudget(updatedBudget)
    .then(() => {
      handleGetProjectBudgetsPage({ page: currentPage.value });
      $message?.success("Budget updated successfully!", 3);
    })
    .catch(() => $message?.error("Couldn't update budget!", 3));
};

/* SUBPROJECTS */

const handleStoreSubproject = (newSubproject: NewSubprojectInterface): void => {
  if (project.value && project.value.id !== undefined) {
    const projectId = project.value.id;
    apiStoreSubproject(newSubproject, projectId)
      .then((res: AxiosResponse<{ data: NewSubprojectInterface }>) => {
        if (res.data.data.id !== null) {
          apiGetSubproject(projectId, res.data.data.id)
            .then(() => {
              handleGetSubprojectsPage(projectId);
              $message?.success("Subproject stored successfully!", 3);
            })
            .catch(() => $message?.error("Couldn't store subproject!", 3));
        }
      })
      .catch(() => $message?.error("Couldn't store subproject!", 3));
  }
};

const handleUpdateSubproject = (updatedSubproject: NewSubprojectInterface): void => {
  if (updatedSubproject !== null && project.value !== null && updatedSubproject.id !== null) {
    const projectId = project.value.id;
    const subprojectId = updatedSubproject.id;
    apiUpdateSubproject(updatedSubproject, projectId, subprojectId)
      .then(() => {
        apiGetSubproject(projectId, subprojectId).then((res: any) => {
          handleGetSubprojectsPage(projectId);
          const subprojectIndex = subprojectsPage.value.findIndex(
            (subproject: NewSubprojectInterface) => subproject.id === subprojectId
          );
          subprojectsPage.value[subprojectIndex] = res.data.data;
        });
      })
      .then(() => $message?.success("Subproject updated successfully!", 3))
      .catch(() => $message?.error("Couldn't update subproject!", 3));
  }
};

const handleDestroySubproject = (projectId: number, subprojectId: any): void => {
  apiDestroySubproject(projectId, subprojectId)
    .then(() => {
      handleGetSubprojectsPage(projectId);
      $message?.success("Subproject removed successfully!", 3);
    })
    .catch(() => $message?.error("Couldn't remove subproject!", 3));
};

const handleGetSubprojectsPage = (projectId: number): void => {
  loadingTable.value = true;
  apiGetSubprojects(projectId)
    .then((res: AxiosResponse<{ data: Array<NewSubprojectInterface> }>) => {
      loadingTable.value = false;
      subprojectsPage.value = res.data.data;
    })
    .catch(() => $message?.error("Couldn't show subproject!", 3));
};
</script>
