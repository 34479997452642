<template>
  <a-table
    :columns="vacationReportColumns"
    :data-source="vacationReports"
    :pagination="false"
    :loading="loadingTable"
    :row-key="(report) => report.id"
    size="middle"
  >
    <template #vacationType="type, report">
      <span>
        <a-badge :color="getReportBadge(type)" />
        {{ getReportLabel(type) }}
        <span v-if="type === ReportType.PARENTAL"> ({{ report.parental_percentage * 100 }}%) </span>
      </span>
    </template>
  </a-table>
</template>

<script setup lang="ts">
import { ReportInterface, ReportType } from "../../reports/types";
import { vacationReportColumns } from "../_utils/tables";

// Props
const props = defineProps({
  vacationReports: { type: Array as () => Array<ReportInterface>, default: undefined },
  loadingTable: { type: Boolean, default: undefined },
});

// Component methods
const getReportBadge = (type: string): string => {
  switch (type) {
    case ReportType.VACATION:
      return "blue";
    case ReportType.PARENTAL:
      return "lightgray";
  }
  return type;
};

const getReportLabel = (type: string): string => {
  switch (type) {
    case ReportType.VACATION:
      return "Vacation";
    case ReportType.PARENTAL:
      return "Parental leave";
  }
  return type;
};
</script>
