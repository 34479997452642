<template>
  <div class="card">
    <div class="d-flex justify-content-between w-100 mb-2">
      <h2>Subprojects</h2>

      <!-- CREATE BUDGET SHORTCUT ICON -->
      <div
        v-if="props.acl?.isAdmin || props.acl?.isProjectManager"
        class="action-default d-flex justify-content-end align-items-baseline"
        @click="showAddSubprojectModal"
      >
        <a-icon type="plus" class="pr-1" />
        <span>Create Subproject</span>
      </div>
    </div>

    <!-- SUBPROJECT TABLE -->
    <a-table
      :columns="subprojectsColumns"
      :data-source="props.subprojects"
      :row-key="'id'"
      :scroll="{ x: 1000 }"
      :loading="loadingTable"
      :pagination="false"
      :expand-row-by-click="true"
    >
      <template #name="name">
        <span>
          {{ name }}
        </span>
      </template>

      <template #start_date="start_date">
        <p v-if="start_date">{{ `${start_date}` }}</p>
        <p v-else>{{ "" }}</p>
      </template>

      <template #end_date="end_date">
        <p v-if="end_date">{{ `${end_date}` }}</p>
        <p v-else>{{ "" }}</p>
      </template>

      <template #expandedRowRender="s">
        <div>
          <a-row class="mt-2">
            <a-col :span="22">
              <strong class="mr-2">Description:</strong>
              <span v-if="s.description">
                {{ s.description }}
              </span>
              <span v-else>
                <em>Description is missing</em>
              </span>
            </a-col>
          </a-row>

          <a-row class="mt-2">
            <a-col :span="22">
              <strong class="mr-2">URL:</strong>
              <span v-if="s.link">
                <a :href="`${s.link}`" target="_blank" rel="noopener noreferrer">
                  {{ s.link }}
                </a>
              </span>
              <em v-else>No agreement link available</em>
            </a-col>
          </a-row>
        </div>
      </template>

      <template #actions="record">
        <span
          v-if="props.acl?.isAdmin || props.acl?.isProjectManager"
          class="d-flex align-items-center"
        >
          <a-icon type="edit" class="action-danger" @click="showEditSubprojectModal(record)" />
          <a-divider type="vertical" />
          <a-icon type="delete" class="action-danger" @click="handleDeleteSubproject(record)" />
          <!-- Record exists and looks right, delete later-->
        </span>
      </template>
    </a-table>

    <!-- ADD SUBPROJECT MODAL  -->
    <add-subproject-modal
      :visible="addSubprojectModalVisible"
      :project="project"
      @store-subproject="handleStoreSubproject"
      @close="addSubprojectModalVisible = false"
    />

    <!-- EDIT SUBPROJECT MODAL  -->
    <edit-subproject-modal
      :visible="editSubprojectModalVisible"
      :subproject="subproject"
      :project="project"
      @update-subproject="handleUpdateSubproject"
      @close="editSubprojectModalVisible = false"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, onBeforeMount, getCurrentInstance } from "vue";
import { ProjectInterface, NewSubprojectInterface } from "../../projects/types";
import AddSubprojectModal from "@/modules/projects/_components/AddSubprojectModal.vue";
import EditSubprojectModal from "@/modules/projects/_components/EditSubprojectModal.vue";
import { subprojectsColumns } from "../_utils/tables";
import { BaseAclInterface } from "@/modules/authentication/types";

// Props
const props = defineProps({
  subprojects: { type: Array as () => Array<NewSubprojectInterface>, default: () => [] },
  project: { type: Object as () => ProjectInterface, default: undefined },
  loadingTable: { type: Boolean, default: false },
  acl: { type: Object as () => BaseAclInterface, default: undefined },
});

// Emits
const emits = defineEmits([
  "store-subproject",
  "update-subproject",
  "get-subprojects-page",
  "destroy-subproject",
]);

// Instances
const instance = getCurrentInstance();
const $confirm = instance?.proxy.$confirm;

// Data properties
const addSubprojectModalVisible = ref<boolean>(false);
const editSubprojectModalVisible = ref<boolean>(false);
const deleteSubprojectModalVisible = ref<boolean>(false);
const subproject = ref<NewSubprojectInterface | undefined>(undefined);

// Hooks
onBeforeMount(() => {
  if (props.project) {
    emits("get-subprojects-page", props.project.id);
  }
});

// Component Methods
const showAddSubprojectModal = () => {
  addSubprojectModalVisible.value = true;
};

const showEditSubprojectModal = (s: NewSubprojectInterface): void => {
  subproject.value = s;
  editSubprojectModalVisible.value = true;
};

const showDeleteSubprojectModal = (subproject: NewSubprojectInterface): void => {
  subproject = subproject;
  deleteSubprojectModalVisible.value = true;
};

const handleStoreSubproject = (subproject: NewSubprojectInterface): void => {
  emits("store-subproject", subproject);
};

const handleDeleteSubproject = (record: any): void => {
  if (record.project) {
    const projectId = record.project.id;
    const mod = $confirm?.({
      title: "Are you sure you want to delete this subproject?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        emits("destroy-subproject", projectId, record.id);
        mod?.destroy();
      },
    });
  }
};

const handleUpdateSubproject = (updatedSubproject: NewSubprojectInterface): void => {
  emits("update-subproject", updatedSubproject);
};

const handleGetSubprojectsPage = (page: number): void => {
  emits("get-subprojects-page", { page });
};
</script>
