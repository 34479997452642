<template>
  <div class="card">
    <div class="d-flex justify-content-between w-100 mb-2">
      <h2>GitHub</h2>

      <div>
        <a-button icon="book" type="primary" @click="addProjectRepositoryModalVisible = true">
          Add Repository
        </a-button>

        <a-button
          icon="flag"
          type="default"
          class="ml-2"
          :disabled="!repositories.length"
          @click="addMilestoneModalVisible = true"
        >
          Create Milestone
        </a-button>
      </div>
    </div>

    <h3>
      Linked Repositories
      <a-spin v-if="loadingProjectRepositories" size="small" class="ml-2" />
    </h3>
    <div class="d-flex flex-wrap mb-2">
      <div
        v-for="repo in repositories"
        :key="repo.name"
        class="p-2 flex-1 d-flex w-100"
        style="width: 50%"
      >
        <div
          class="flex-1 w-100 d-flex flex-column justify-content-between p-3"
          style="border: 1px solid #cbd5e0; border-radius: 5px"
        >
          <div>
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <a-icon type="book" />
                <h3 class="mb-0 mx-2">
                  <a :href="repo.url" target="_blank" rel="noreferrer noopener">{{ repo.name }}</a>
                </h3>
                <a-tag v-if="repo.private">Private</a-tag>
                <a-tag v-if="repo.archived" color="orange">Archived</a-tag>
              </div>

              <div>
                <a-icon
                  type="delete"
                  class="action-danger"
                  @click="handleRemoveProjectRepository(repo.id)"
                />
              </div>
            </div>
            <small class="my-2">{{ repo.description }}</small>
            <div class="my-2 d-flex align-items-center">
              <a-icon class="mr-2" type="flag" />
              <div v-for="(ml, idx) in repo.milestones" :key="ml.title">
                <small>{{ `${idx != 0 ? "," : ""} ${ml.title}` }}</small>
              </div>
            </div>
          </div>

          <div class="mt-3 d-flex align-items-center">
            <div class="d-flex align-items-center mr-3">
              <div
                style="width: 10px; height: 10px; border-radius: 10px"
                :style="{ backgroundColor: handleGetLanguageColor(repo.language) }"
                class="mr-1"
              />
              {{ repo.language }}
            </div>
            <div class="d-flex align-items-center mr-3">
              <a-icon type="exclamation-circle" class="mr-1" />
              {{ repo.open_issues }}
            </div>
            <div class="d-flex align-items-center mr-3">
              <p class="mb-0">Updated {{ parseDate(repo.updated_at) }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- ADD REPOSITORY MODAL -->
    <add-project-repository-modal
      :repositories="allRepositories"
      :visible="addProjectRepositoryModalVisible"
      :loading="loadingRepositories"
      @close="addProjectRepositoryModalVisible = false"
      @get-repositories="() => handleGetAllRepositories()"
      @add-repository="(repo) => handleAddProjectRepository(repo)"
    />

    <!-- ADD MILESTONE MODAL -->
    <add-milestone-modal
      :repositories="repositories"
      :visible="addMilestoneModalVisible"
      @close="addMilestoneModalVisible = false"
      @create-milestone="(milestone) => handleCreateMilestone(milestone)"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import AddProjectRepositoryModal from "./AddProjectRepositoryModal.vue";
import AddMilestoneModal from "./AddMilestoneModal.vue";
// @ts-ignore
import GitHubColors from "github-colors";
import {
  MinimalRepositoryInterface,
  MinimalMilestoneInterface,
  ProjectInterface,
} from "@/modules/projects/types";
import moment from "@/date";
import {
  apiRemoveProjectRepository,
  apiGetProjectRepositories,
  apiAddProjectRepository,
  apiGetRepositories,
  apiCreateMilestone,
} from "../_utils/api";

// Props
const props = defineProps({
  project: { type: Object as () => ProjectInterface, default: undefined },
});

// Repositories
const repositories = ref<Array<{ name: string }>>([]);
const allRepositories = ref<Array<{ full_name: string }>>([]);
const addProjectRepositoryModalVisible = ref<boolean>(false);
const addMilestoneModalVisible = ref<boolean>(false);
const loadingRepositories = ref<boolean>(false);
const loadingProjectRepositories = ref<boolean>(false);

onMounted(() => {
  handleGetProjectRepositories();
});

const handleGetProjectRepositories = (): void => {
  loadingProjectRepositories.value = true;
  apiGetProjectRepositories(props.project.id).then(({ data }) => {
    repositories.value = data.data;
    loadingProjectRepositories.value = false;
  });
};

const handleAddProjectRepository = (newRepository: MinimalRepositoryInterface): void => {
  apiAddProjectRepository(props.project.id, newRepository).then(() =>
    handleGetProjectRepositories()
  );
};

const handleRemoveProjectRepository = (repositoryId: number): void => {
  apiRemoveProjectRepository(props.project.id, repositoryId).then(() =>
    handleGetProjectRepositories()
  );
};

const handleGetAllRepositories = (): void => {
  loadingRepositories.value = true;
  apiGetRepositories().then(({ data }) => {
    const projectRepos = repositories.value.map((r: any) => r.name);
    allRepositories.value = data.data.filter(
      (r: any) => !projectRepos.includes(r.full_name.split("/")[1])
    );
    loadingRepositories.value = false;
  });
};

const handleCreateMilestone = (milestone: MinimalMilestoneInterface): void => {
  apiCreateMilestone(props.project.id, milestone).then(() => handleGetProjectRepositories());
};

const handleGetLanguageColor = (language: string): string => {
  return GitHubColors.get(language)?.color;
};

const parseDate = (date: string): string => {
  return moment(date).fromNow();
};
</script>
