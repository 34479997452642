<template>
  <div>
    <div v-if="filterPanelVisible" class="card mb-3">
      <a-row>
        <a-col :lg="12">
          <a-form>
            <SkNameSelect
              v-if="baseAcl && baseAcl.isFinancial"
              v-model="newFilter.user_id"
              label="Users"
              :label-col-size="6"
              :wrapper-col-size="14"
              mode="multiple"
              placeholder="Select users"
              :allow-clear="true"
              :options="usersCompactList"
              :disabled="unchangeableFilter && unchangeableFilter.user_id !== undefined"
              @change="applyFilters"
            />

            <SkNameSelect
              v-model="newFilter.cost_center_id"
              label="Cost centers"
              :label-col-size="6"
              :wrapper-col-size="14"
              mode="multiple"
              placeholder="Select cost centers"
              :allow-clear="true"
              :options="costCentersCompactList"
              :disabled="unchangeableFilter && unchangeableFilter.cost_center_id !== undefined"
              @change="applyFilters"
            />

            <a-form-item
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 14 }"
              label="Dates"
              class="pb-0 mb-0"
            >
              <a-date-picker
                :value="parseDate(newFilter.start_date)"
                :disabled-date="(date) => disabledStartDate(date)"
                placeholder="Start date"
                class="w-100"
                :disabled="unchangeableFilter && unchangeableFilter.start_date !== undefined"
                @change="(d) => onDateChange(d, 'start_date')"
              />
              <a-date-picker
                :value="parseDate(newFilter.end_date)"
                :disabled-date="(date) => disabledEndDate(date)"
                placeholder="End date (optional)"
                class="w-100"
                :disabled="unchangeableFilter && unchangeableFilter.end_date !== undefined"
                @change="(d) => onDateChange(d, 'end_date')"
              />
            </a-form-item>
          </a-form>
        </a-col>

        <a-col :lg="12">
          <a-form>
            <a-form-item :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }" label="Status">
              <a-checkbox-group
                :value="newFilter.receipt_status"
                :disabled="unchangeableFilter && unchangeableFilter.receipt_status !== undefined"
                @change="(status) => updateStatusFilter(status)"
              >
                <a-checkbox class="ml-2 mt-2 d-block" value="pending">Pending</a-checkbox>
                <a-checkbox class="mt-2 d-block" value="uploading">Uploading</a-checkbox>
                <a-checkbox class="mt-2 d-block" value="approved">Approved (paid out)</a-checkbox>
                <a-checkbox class="mt-2 d-block" value="approved_pending_payment">
                  Approved (pending payment)
                </a-checkbox>
                <a-checkbox class="mt-2 d-block" value="declined">Declined</a-checkbox>
              </a-checkbox-group>
            </a-form-item>
          </a-form>
        </a-col>
      </a-row>

      <a-row v-if="advancedSearch && baseAcl.isFinancial" class="mt-3">
        <a-form>
          <a-form-item
            v-if="baseAcl && baseAcl.isFinancial"
            :label-col="{ span: 14 }"
            :wrapper-col="{ span: 4 }"
            label="Only show receipts that are approved and aren't part of a transaction"
          >
            <a-switch v-model="newFilter.only_usable" @change="applyFilters" />
          </a-form-item>
        </a-form>
      </a-row>

      <span
        :class="
          baseAcl && baseAcl.isFinancial
            ? 'd-flex justify-content-between mt-3'
            : 'd-flex justify-content-end mt-3'
        "
      >
        <a v-if="baseAcl && baseAcl.isFinancial" @click="advancedSearch = !advancedSearch">
          {{ advancedSearch ? "Show less" : "Advanced search" }}
        </a>
        <span>
          <strong>Results:</strong>
          {{ numberOfResults }}
        </span>
      </span>

      <div
        v-if="
          totalAmountPerCurrency && totalAmountPerCurrency.length > 0 && showTotalAmountPerCurrency
        "
      >
        <span class="d-flex justify-content-end mt-3">
          <strong>Total amount: </strong>
        </span>
        <span
          v-for="totalAmount in totalAmountPerCurrency"
          :key="totalAmount.currency_code"
          class="d-flex justify-content-end mt-3"
        >
          <span>
            {{ toCurrency(totalAmount.total_amount_in_cents / 100, totalAmount.amount_currency) }}
          </span>
        </span>
      </div>
    </div>
    <portal to="receipts-filter-buttons">
      <a-button
        v-if="filterPanelVisible"
        class="mr-2"
        icon="close"
        type="danger"
        @click="() => clearFilters()"
      />
      <a-button
        v-show="filterPanelVisible"
        class="mr-2"
        type="primary"
        icon="up"
        @click="() => toggleFilterPanel()"
      />
      <a-button
        v-if="!filterPanelVisible"
        class="mr-2"
        type="primary"
        icon="filter"
        @click="() => toggleFilterPanel()"
      />
    </portal>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch } from "vue";
import { CostCenterInterface } from "@/modules/cost_centers/types";
import { apiGetCostCenters } from "../../cost_centers/_utils/api";
import { useAuthenticationStore } from "@/modules/authentication/_store";
import { BaseAclInterface } from "../../authentication/types";
import { MinimalUserInterface } from "@/modules/users/types";
import { ReceiptsFilterInterface } from "../types";
import { getUsersCompactList } from "@/api";
import { Amount } from "@/common/types";
import useMixin from "@/useMixin";
import moment from "@/date";
import useFilters from "@/useFilters";

// Props
const props = defineProps({
  unchangeableFilter: { type: Object as () => ReceiptsFilterInterface, default: undefined },
  totalAmountPerCurrency: { type: Array as () => Array<Amount>, default: () => [] },
  filters: { type: Object as () => ReceiptsFilterInterface, default: undefined },
  numberOfResults: { type: Number, default: 0 },
  visible: { type: Boolean, default: false },
});

// Emits
const emits = defineEmits(["apply-filters"]);

// Pinia
const authenticationStore = useAuthenticationStore();
const baseAcl = computed<BaseAclInterface>(() => authenticationStore.baseAcl);

// Mixins
const { setObject, toCurrency } = useMixin();

// Filters
const { parseDate } = useFilters();

// Data properties
const costCentersCompactList = ref<Array<CostCenterInterface>>([]);
const usersCompactList = ref<Array<MinimalUserInterface>>([]);
const showTotalAmountPerCurrency = ref<boolean>(false);
const filterPanelVisible = ref<boolean>(false);
const advancedSearch = ref<boolean>(false);
const newFilter = ref<ReceiptsFilterInterface>({
  receipt_status: [],
  user_id: [],
  cost_center_id: [],
  start_date: "",
  end_date: "",
  only_usable: false,
});

// Watchers
watch(filterPanelVisible, (newProp, oldProp) => {
  if (newProp && !oldProp) {
    handleGetUsersCompactList();
    handleGetCostCenter();
    newFilter.value = setObject(newFilter.value, props.filters);
    applyFilters();
  }
});

watch(
  () => props.totalAmountPerCurrency,
  () => {
    showTotalAmountPerCurrency.value = areUsersOrStatusFiltersApplied();
  }
);

// Component methods
const toggleFilterPanel = (): void => {
  filterPanelVisible.value = !filterPanelVisible.value;
};

const updateStatusFilter = (status_filters: any): void => {
  newFilter.value.receipt_status = status_filters;
  applyFilters();
};

const handleGetUsersCompactList = (): void => {
  getUsersCompactList().then((users) => {
    usersCompactList.value = users;
  });
};

const handleGetCostCenter = (): void => {
  apiGetCostCenters().then((res: any) => {
    costCentersCompactList.value = res.data.data;
  });
};

const applyFilters = (): void => {
  emits("apply-filters", newFilter.value);
};

const clearFilters = (): void => {
  handleClearReceiptsFilter();
  emits("apply-filters", {});
};

const disabledStartDate = (startValue: any): boolean => {
  const endValue = moment(newFilter.value.end_date).endOf("day");
  if (!startValue || !endValue) {
    return false;
  }
  return startValue.valueOf() >= endValue.valueOf();
};

const disabledEndDate = (endValue: any): boolean => {
  const startValue = moment(newFilter.value.start_date);
  if (!endValue || !startValue) {
    return false;
  }
  return startValue.valueOf() >= endValue.valueOf();
};

const onDateChange = (date: any, type: string): void => {
  if (type === "start_date") {
    if (date === null) {
      newFilter.value.start_date = "";
      if (newFilter.value.end_date !== "") {
        newFilter.value.end_date = "";
      }
    } else {
      newFilter.value.start_date = date.format("YYYY-MM-DD");
    }
  } else if (type === "end_date") {
    if (date === null) {
      newFilter.value.end_date = "";
    } else {
      newFilter.value.end_date = date.format("YYYY-MM-DD");
    }
  }
  applyFilters();
};

const areUsersOrStatusFiltersApplied = (): boolean => {
  return (
    (newFilter.value.receipt_status !== undefined && newFilter.value.receipt_status.length > 0) ||
    (newFilter.value.user_id !== undefined && newFilter.value.user_id.length > 0)
  );
};

const handleClearReceiptsFilter = (): void => {
  newFilter.value = {
    receipt_status: [],
    user_id: [],
    cost_center_id: [],
    start_date: "",
    end_date: "",
    only_usable: false,
  };
  newFilter.value = { ...props.unchangeableFilter };
};
</script>
