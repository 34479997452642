import { RoleInterface } from "../types";
import moment from "@/date";

function endDateIsInThePast(role: RoleInterface): boolean {
  let endDate = new Date(role.end_date);
  let today = new Date();
  return endDate < today;
}

function disableAllPastDates(date: moment.Moment): boolean {
  let today = moment(new Date());
  return date < today;
}

function disableDatesUpUntilEndDateIncluded(date: moment.Moment, role: RoleInterface): boolean {
  let endDate = moment(new Date(role.end_date));
  return date.startOf("day") <= endDate;
}

export function disableDate(date: moment.Moment, role: RoleInterface): boolean {
  if (endDateIsInThePast(role)) return disableAllPastDates(date);
  else return disableDatesUpUntilEndDateIncluded(date, role);
}
