import Colors from "@/assets/scss/_colors.module.scss";
export function getStatusTagColor(status: string): string {
  switch (status) {
    case "active":
      return Colors.green;
    case "pending":
      return Colors.yellow;
    case "closed":
      return Colors.orange;
    default:
      return "";
  }
}
