import VacationYears from "./_views/VacationYears.vue";
import { RouteInterface } from "@/common/types";

export default [
  {
    path: "vacation-years",
    component: VacationYears,
    name: "vacation-years",
    meta: { auth: true },
  },
] as Array<RouteInterface>;
