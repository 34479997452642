import { RouteInterface } from "@/common/types";
import Login from "./_views/Login";

export default [
  {
    path: "/login",
    component: Login,
    name: "login",
    meta: { auth: false },
  },
] as Array<RouteInterface>;
