import { onMounted, onUnmounted, onUpdated, readonly, ref, Ref, watch } from "vue";
import { useRouter } from "vue-router/composables";
import { authenticate } from "../_utils/api";
import { AxiosResponse } from "axios";
import { setJwtToken } from "@/network";
import logo from "@/assets/img/skry-grey.png";

import { Button } from "ant-design-vue";

const createScriptTag = (): HTMLScriptElement => {
  const script: HTMLScriptElement = document.createElement("script");
  script.src = "https://accounts.google.com/gsi/client";
  script.async = true;
  script.defer = true;
  return script;
};

const loaded = ref(false);
const isLoading = ref(false);
const error = ref(false);
const subscriberCount = ref(0);
let scriptTag: HTMLScriptElement | null = null;

const initialize = () => {
  isLoading.value = true;
  scriptTag = createScriptTag();
  document.head.appendChild(scriptTag);

  scriptTag.onload = () => {
    isLoading.value = false;
    loaded.value = true;
  };

  scriptTag.onerror = () => {
    isLoading.value = false;
    error.value = true;
  };
};

watch(
  () => subscriberCount.value,
  (newCount, _oldCount) => {
    if (newCount > 0 && !loaded.value && !isLoading.value) {
      initialize();
    }
  }
);

export default ({}) => {
  const router = useRouter();

  const gapiLoading: Ref<boolean> = readonly(isLoading);
  const gapiLoaded: Ref<boolean> = readonly(loaded);
  const oauthLoading: Ref<boolean> = ref(false);

  const handleLoginOauth = () => {
    oauthLoading.value = true;

    const client = google.accounts.oauth2.initCodeClient({
      client_id: import.meta.env.VITE_OAUTH_GOOGLE_CLIENT_ID,
      scope:
        "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile",
      ux_mode: "popup",
      callback: (response: unknown) => {
        // https://developers.google.com/identity/oauth2/web/reference/js-reference#CodeResponse
        const tokenResponse = response as { code: string };
        return authenticate(
          tokenResponse.code,
          import.meta.env.VITE_OAUTH_GOOGLE_CLIENT_ID,
          window.location.origin
        ).then((resp: AxiosResponse<{ token: string }>) => {
          oauthLoading.value = false;

          setJwtToken(resp.data.token);
          router.push({ name: "dashboard" });
        });
      },
    });

    client.requestCode();
  };

  onMounted(() => {
    subscriberCount.value++;
  });

  onUpdated(() => {
    // Additional safe-guard when we open Login from re-direct (e.g. when token has expired)
    // In these cases we don't get the onMounted callback and miss out on initialization
    if (!gapiLoaded.value && !gapiLoading.value) {
      initialize();
    }
  });

  onUnmounted(() => {
    subscriberCount.value--;
  });

  return (
    <div class="bg-grey_light h-100">
      <div class="container h-100">
        <div class="auth-card h-100">
          <a-row>
            <a-col class="auth-wrapper">
              <a-card bordered={false}>
                <div class="auth-card">
                  <div class="mt-2 mb-4 text-center">
                    <img src={logo} width="50%" />
                  </div>
                  <h1>Skynet</h1>

                  <Button
                    disabled={!gapiLoaded.value}
                    loading={oauthLoading.value || gapiLoading.value}
                    type="primary"
                    class="my-4"
                    vOn:click={() => handleLoginOauth()}
                  >
                    Login with GSuite
                  </Button>
                </div>
              </a-card>
            </a-col>
          </a-row>
        </div>
      </div>
    </div>
  );
};
