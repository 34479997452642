<template>
  <div v-show="filterPanelVisible" class="card mb-3">
    <a-form layout="vertical">
      <bookmarked-filters-selection
        :bookmarked-filters="bookmarkedFilters"
        :filter-is-empty="filterIsEmpty"
        bookmark-type="Invoices"
        class="ml-3"
        @selected="handleCustomFilterSelected"
        @create-bookmark-filter="handleCreateBookmarkFilter"
        @update-bookmark-filter="handleUpdateBookmarkFilter"
        @delete-bookmark-filter="handleDeleteBookmarkFilter"
      />

      <a-row>
        <a-col :lg="11" class="ml-3">
          <SkNameSelect
            v-model="newFilter.project_id"
            label="Projects"
            :label-col-size="10"
            mode="multiple"
            placeholder="Select projects"
            :allow-clear="true"
            :options="projectsCompactList"
            :disabled="unchangeableFilter && unchangeableFilter.project_id !== undefined"
            @change="applyFilters"
          />
        </a-col>

        <a-col :lg="11" class="ml-3">
          <a-form-item
            :label-col="{ span: 10 }"
            :wrapper-col="{ span: 16 }"
            label="Date"
            class="pb-0 mb-0"
          >
            <a-date-picker
              :value="parseDate(newFilter.date)"
              placeholder="Date"
              class="w-100 mb-2"
              :disabled="unchangeableFilter && unchangeableFilter.date !== undefined"
              @change="applyDate"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :lg="11" class="ml-3">
          <a-form-item :label-col="{ span: 10 }" :wrapper-col="{ span: 16 }" label="Type">
            <a-select
              v-model="newFilter.invoice_type"
              placeholder="Select invoice type"
              :disabled="unchangeableFilter && unchangeableFilter.type !== undefined"
              @change="applyFilters"
            >
              <a-select-option :value="undefined">Any</a-select-option>
              <a-select-option value="ReportedInvoice">Reported Invoice</a-select-option>
              <a-select-option value="RequestedInvoice">Requested Invoice</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :lg="11" class="ml-3">
          <a-form-item :label-col="{ span: 10 }" :wrapper-col="{ span: 16 }" label="Status">
            <a-select
              v-model="newFilter.invoice_status"
              placeholder="Select invoice status"
              :disabled="unchangeableFilter && unchangeableFilter.status !== undefined"
              @change="() => applyFilters()"
            >
              <a-select-option :value="undefined">Any</a-select-option>
              <a-select-option value="generated">Generated</a-select-option>
              <a-select-option value="requested">Requested</a-select-option>
              <a-select-option value="approved">Approved</a-select-option>
              <a-select-option value="rejected">Rejected</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>

    <a-row class="d-flex justify-content-end">
      <span>
        <strong>Results:</strong>
        {{ numberOfResults }}
      </span>
    </a-row>

    <portal to="invoices-filter-buttons">
      <a-button
        v-if="isNotEmpty(filters)"
        class="mr-2"
        icon="close"
        type="danger"
        @click="() => clearFilters()"
      />
      <a-button
        v-if="filterPanelVisible"
        icon="up"
        type="primary"
        @click="() => toggleFilterPanel()"
      />
      <a-button
        v-if="!filterPanelVisible"
        icon="filter"
        type="primary"
        @click="() => toggleFilterPanel()"
      />
    </portal>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, getCurrentInstance } from "vue";
import BookmarkedFiltersSelection from "../../bookmarked_filters/_components/BookmarkedFiltersSelection.vue";
import { BookmarkedFilterInterface } from "@/modules/bookmarked_filters/types";
import { MinimalProjectInterface } from "@/modules/projects/types";
import { ProfileInterface } from "../../authentication/types";
import {
  apiStoreUserBookmarkedFilter,
  apiUpdateUserBookmarkedFilter,
  apiDestroyUserBookmarkedFilter,
  apiGetUserBookmarkedFilters,
} from "@/modules/bookmarked_filters/_utils/api";
import { getProjectsCompactList } from "@/api";
import useMixin from "@/useMixin";
import useFilters from "@/useFilters";
import { InvoicesFilterInterface } from "../types";

// Props
const props = defineProps({
  profile: { type: Object as () => ProfileInterface, default: undefined },
  numberOfResults: { type: Number, default: 0 },
  filters: { type: Object as () => InvoicesFilterInterface, default: undefined },
  unchangeableFilter: { type: Object as () => InvoicesFilterInterface, default: undefined },
});

// Emits
const emits = defineEmits(["apply-filters"]);

// Mixins
const { qsDecode, isNotEmpty, setObject } = useMixin();

// Filters
const { parseDate } = useFilters();

// Instances
const instance = getCurrentInstance();
const $message = instance?.proxy.$message;

// Data properties
const filterPanelVisible = ref<boolean>(false);
const projectsCompactList = ref<Array<MinimalProjectInterface>>([]);

const newFilter = ref<any>({
  project_id: [],
  date: undefined,
  invoice_type: undefined,
  invoice_status: undefined,
});

const bookmarkedFilters = ref<Array<BookmarkedFilterInterface>>([]);

// Computed properties
const filterIsEmpty = computed((): boolean => {
  return Boolean(
    newFilter.value.project_id.length === 0 &&
      newFilter.value.date === undefined &&
      newFilter.value.invoice_type === undefined &&
      newFilter.value.invoice_status === undefined
  );
});

// Watchers
watch(filterPanelVisible, (newProp, oldProp) => {
  if (newProp && !oldProp) {
    handleGetProjectsCompactList();
    handleGetBookmarkedFilters();
    setFilter(props.filters);
  }
});

// Component methods
const toggleFilterPanel = (): void => {
  filterPanelVisible.value = !filterPanelVisible.value;
};

const handleGetProjectsCompactList = (): void => {
  getProjectsCompactList().then((projects) => {
    projectsCompactList.value = projects;
  });
};

const handleCustomFilterSelected = (bookmarkFilterUrl: string): void => {
  handleClearInvoicesFilter();
  if (bookmarkFilterUrl) {
    emits("apply-filters", qsDecode(bookmarkFilterUrl).filters);
  } else {
    applyFilters();
  }
};

const applyDate = (date: any): void => {
  newFilter.value.date = date ? date.format("YYYY-MM-DD") : undefined;
  applyFilters();
};

const applyFilters = (): void => {
  emits("apply-filters", filterIsEmpty.value ? {} : newFilter.value);
};

const clearFilters = (): void => {
  handleClearInvoicesFilter();
  emits("apply-filters", {});
};

const handleClearInvoicesFilter = (): void => {
  newFilter.value = {
    project_id: [],
    date: undefined,
    invoice_type: undefined,
    invoice_status: undefined,
  };
  newFilter.value = { ...props.unchangeableFilter };
};

const setFilter = (filter: any) => {
  if (filter === undefined) {
    return;
  }
  newFilter.value = setObject(newFilter.value, filter);
};

const handleGetBookmarkedFilters = (): void => {
  apiGetUserBookmarkedFilters(props.profile.id).then(
    ({ data }: any) => (bookmarkedFilters.value = data.data)
  );
};

const handleCreateBookmarkFilter = (bookmarkFilter: any): void => {
  apiStoreUserBookmarkedFilter(props.profile.id, bookmarkFilter)
    .then(({ data }: any) => bookmarkedFilters.value.push(data.data))
    .then(() => $message?.success("New filter created successfully!", 3))
    .catch(() => $message?.error("Couldn't create new filter!", 3));
};

const handleUpdateBookmarkFilter = (bookmarkFilterId: number, bookmarkFilter: any): void => {
  apiUpdateUserBookmarkedFilter(props.profile.id, bookmarkFilterId, bookmarkFilter)
    .then(
      ({ data }: any) =>
        (bookmarkedFilters.value = bookmarkedFilters.value.map((f: any) =>
          f.id === data.data.id ? data.data : f
        ))
    )
    .then(() => $message?.success("Filter updated successfully!", 3))
    .catch(() => $message?.error("Couldn't update filter!", 3));
};

const handleDeleteBookmarkFilter = (bookmarkFilterId: number) => {
  apiDestroyUserBookmarkedFilter(props.profile.id, bookmarkFilterId)
    .then(
      () =>
        (bookmarkedFilters.value = bookmarkedFilters.value.filter(
          (f: any) => f.id !== bookmarkFilterId
        ))
    )
    .then(() => $message?.success("Filter removed successfully!", 3))
    .catch(() => $message?.error("Couldn't remove filter!", 3));
};
</script>
