<template>
  <div>
    <div class="card">
      <div class="d-flex justify-content-between">
        <h2>Skills</h2>
        <div
          v-if="acl.isOwner || acl.isAdmin"
          class="action-default d-flex align-items-center mb-2"
          @click="addUserSkillModalVisible = true"
        >
          <a-icon type="plus" class="pr-1" />
          <span>Add</span>
        </div>
      </div>

      <div v-if="userSkills.length > 0">
        <a-list :data-source="userSkills" bordered item-layout="vertical" size="small">
          <template #renderItem="item">
            <a-list-item>
              <div class="d-flex justify-content-between">
                <strong>{{ item.skill.title }}</strong>
                <span v-if="acl.isOwner || acl.isAdmin">
                  <a-icon
                    type="edit"
                    class="action-default"
                    @click="handleEditUserSkillModalVisible(item)"
                  />
                  <a-divider type="vertical" />
                  <a-icon
                    type="delete"
                    class="action-danger"
                    @click="showDeleteUserSkillConfirm(item)"
                  />
                </span>
              </div>

              <div class="d-flex justify-content-between mt-2 flex-wrap">
                <div>
                  <span>
                    Grow:
                    <a-icon
                      v-if="acl.isOwner || acl.isAdmin"
                      :theme="item.grow ? 'filled' : 'outlined'"
                      type="up-circle"
                      class="action-default ml-2 text-blue"
                      @click="
                        $emit('update-user-skill', {
                          id: item.id,
                          grow: !item.grow,
                        })
                      "
                    />
                    <a-icon
                      v-else
                      :theme="item.grow ? 'filled' : 'outlined'"
                      type="up-circle"
                      class="ml-2 text-blue"
                    />
                  </span>

                  <a-divider type="vertical" />

                  <span>
                    Preferred:
                    <a-icon
                      v-if="acl.isOwner || acl.isAdmin"
                      :theme="item.preferred ? 'filled' : 'outlined'"
                      type="heart"
                      class="action-danger ml-2 text-red"
                      @click="
                        $emit('update-user-skill', {
                          id: item.id,
                          preferred: !item.preferred,
                        })
                      "
                    />
                    <a-icon
                      v-else
                      :theme="item.preferred ? 'filled' : 'outlined'"
                      type="heart"
                      class="ml-2 text-red"
                    />
                  </span>
                </div>

                <div>
                  Level:
                  <a-icon
                    v-for="i in 3"
                    :key="i"
                    :theme="i <= parseInt(item.level) ? 'filled' : 'outlined'"
                    type="star"
                    class="ml-1 text-yellow"
                    @click="$emit('update-user-skill', { id: item.id, level: i })"
                  />
                </div>
              </div>
            </a-list-item>
          </template>
        </a-list>
      </div>
    </div>

    <add-user-skill-modal
      :skills="skills"
      :visible="addUserSkillModalVisible"
      :user-skills="userSkills"
      @close="addUserSkillModalVisible = false"
      @store-user-skill="(newUserSkill) => $emit('store-user-skill', newUserSkill)"
      @get-skills="$emit('get-skills')"
    />

    <edit-user-skill-modal
      :visible="editUserSkillModalVisible"
      :user-skill="userSkill"
      @close="editUserSkillModalVisible = false"
      @update-user-skill="(newUserSkill) => $emit('update-user-skill', newUserSkill)"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, getCurrentInstance } from "vue";
import { BaseAclInterface, ProfileInterface } from "@/modules/authentication/types";
import EditUserSkillModal from "./EditUserSkillModal.vue";
import AddUserSkillModal from "./AddUserSkillModal.vue";
import { SkillInterface } from "../../skills/types";

// Props
const props = defineProps({
  userSkills: { type: Array as () => Array<SkillInterface>, default: () => [] },
  skills: { type: Array as () => Array<SkillInterface>, default: () => [] },
  user: { type: Object as () => ProfileInterface, default: undefined },
  acl: { type: Object as () => BaseAclInterface, default: undefined },
});

// Emits
const emits = defineEmits(["destroy-user-skill"]);

// Instances
const instance = getCurrentInstance();
const $confirm = instance?.proxy.$confirm;

// Data Properties
const addUserSkillModalVisible = ref<boolean>(false);
const editUserSkillModalVisible = ref<boolean>(false);
const userSkill = ref<SkillInterface | undefined>(undefined);

// Class Methods
const handleEditUserSkillModalVisible = (skill: any): void => {
  userSkill.value = skill;
  editUserSkillModalVisible.value = true;
};

const showDeleteUserSkillConfirm = (userSkill: any): void => {
  const mod = $confirm?.({
    title: `Are you sure you want to remove the skill ${userSkill ? userSkill.skill.title : ""}?`,
    okText: "Yes",
    okType: "danger",
    cancelText: "No",
    onOk() {
      emits("destroy-user-skill", userSkill);
      mod?.destroy();
    },
  });
};
</script>
