import moment from "@/date";
import { Moment } from "moment";

// Decode a JWT string returning a Javascript object
export const jwtDecode = (token: string): Record<string, any> => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace("-", "+").replace("_", "/");
  return JSON.parse(window.atob(base64));
};

// This function removes unused authentication tokens from local storage.
export const purgeTokens = (): void => {
  for (var key in localStorage) {
    if (key.includes("vue-authenticate")) {
      localStorage.removeItem(key);
    }
  }
};

// Parse and download response returning a CSV payload.
export const downloadCsv = (csv: string, prefix: string, isFiltered: boolean = false): void => {
  const dateFormat = "YYYY-MM-DD";
  const link = document.createElement("a");
  link.href = `data:text/csv;charset=utf-8,${encodeURI(csv)}`;
  link.target = "_blank";
  link.download =
    `${prefix}_${moment().format(dateFormat)}` + `${isFiltered ? "_filtered" : ""}.csv`;
  link.click();
};

export const CURRENCIES = ["SEK", "EUR", "USD", "GBP"];

export function formatDatesDuration(
  start_date: string,
  end_date: string,
  min_duration: { days?: number; months?: number; years?: number } = {},
  clampEndDateToToday: boolean = false
): string {
  const today = moment();
  const providedEnd = moment(end_date, "YYYY-MM-DD", true);

  if ((!providedEnd.isValid() || providedEnd.isAfter(today)) && clampEndDateToToday) {
    end_date = today.format("YYYY-MM-DD");
  }

  const duration = moment.duration(moment(end_date).diff(start_date));
  const totalMonths = Math.round(duration.asMonths());
  let years = Math.floor(totalMonths / 12);
  let months = totalMonths % 12;
  let days = Math.round(duration.asDays());

  if (min_duration.days && days < min_duration.days) {
    days = min_duration.days;
  }

  if (min_duration.months && months < min_duration.months) {
    months = min_duration.months;
    days = 0;
  }

  if (min_duration.years && years < min_duration.years) {
    years = min_duration.years;
    months = 0;
    days = 0;
  }

  let builder = [];
  if (years > 0) builder.push(years === 1 ? `1 year` : `${years} years`);
  if (months > 0) builder.push(months === 1 ? `1 month` : `${months} months`);
  if (years == 0 && months == 0) builder.push(days === 1 ? `1 day` : `${days} days`);

  return builder.join(" ");
}

export function computeDayDifference(date1: Date, date2: Date): number {
  floorDays(date1);
  floorDays(date2);
  const dayInMillisecond = 1000 * 60 * 60 * 24;
  return Math.abs(new Date(date1).getTime() - new Date(date2).getTime()) / dayInMillisecond;
}

export function addDays(date: Date, nbDays: number): Date {
  const newDate = new Date(date); // copy Date object
  newDate.setDate(newDate.getDate() + nbDays);
  return newDate;
}

export function isWeekendDay(date: Date): boolean {
  return date.getDay() === 0 || date.getDay() === 6;
}

export function isWeekendDayV2(date: Moment): boolean {
  return date.isoWeekday() === 6 || date.isoWeekday() === 7;
}

export function getDayDateRange(start_date: Date, end_date: Date): Array<Date> {
  floorDays(start_date);
  floorDays(end_date);
  const range: Array<Date> = [];
  let curr = start_date;
  while (curr <= end_date) {
    range.push(curr);
    curr = addDays(curr, 1);
  }
  return range;
}

export function getDayDateRangeV2(start_date: Moment, end_date: Moment): Array<Moment> {
  floorDaysV2(start_date);
  floorDaysV2(end_date);
  const range: Array<Moment> = [];
  let curr = start_date;
  while (curr <= end_date) {
    range.push(curr);
    curr = moment(curr);
    curr.add(1, "days");
  }
  return range;
}

function floorDays(date: Date): void {
  date.setHours(0, 0, 0, 0);
}

function floorDaysV2(date: Moment): void {
  date.set("hour", 0);
  date.set("minute", 0);
  date.set("second", 0);
}

export function getMonthQueryKey(date: string | Date): string {
  const d = new Date(date);
  return `${d.getFullYear()}.${d.getMonth() + 1}`;
}
