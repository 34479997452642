<template>
  <div class="card">
    <div class="d-flex justify-content-between">
      <h2>Hobbies</h2>

      <!-- BUTTON AREA -->
      <div v-if="acl.isOwner || acl.isAdmin">
        <div v-if="inEditingMode">
          <a-button size="small" class="mr-2" @click="clearEditor">Cancel</a-button>
          <a-button type="primary" size="small" @click="handleUpdateInterests">Save</a-button>
        </div>

        <div
          v-else
          class="action-default d-flex justify-content-end align-items-center mb-2"
          @click="openEditor"
        >
          <a-icon type="edit" class="pr-1" />
          <span>Edit</span>
        </div>
      </div>
    </div>

    <div>
      <!-- SELECTION BOX (VISIBLE WHEN EDITING) -->
      <a-select
        v-if="inEditingMode"
        v-model="userInterests"
        :token-separators="[',']"
        allow-clear
        mode="tags"
        class="w-100"
      >
        <a-select-option
          v-for="interest in interests"
          :key="interest.id"
          :value="interest.description"
          :disabled="user.interests.includes(interest)"
          >{{ interest.description }}</a-select-option
        >
      </a-select>

      <!-- LIST OF INTERESTS AS BADGES -->
      <div v-if="!inEditingMode">
        <div v-if="user.interests.length > 0" class="my-3">
          <a-tag
            v-for="interest in user.interests"
            :key="interest.id"
            color="orange"
            class="mb-1"
            >{{ interest.description }}</a-tag
          >
        </div>

        <!-- MESSAGE WHEN EMPTY INTERESTS LIST -->
        <div v-else class="d-flex justify-content-center">
          <em>Hobbies not provided!</em>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { ProfileInterface, BaseAclInterface } from "@/modules/authentication/types";

// Props
const props = defineProps({
  user: { type: Object as () => ProfileInterface, default: undefined },
  acl: { type: Object as () => BaseAclInterface, default: undefined },
  interests: { type: Array as () => Array<any>, default: () => [] },
});

// Emits
const emits = defineEmits(["get-interests", "update-interests"]);

// Data Properties
const inEditingMode = ref<boolean>(false);
const userInterests = ref<Array<any>>([]);

// Class Methods
const openEditor = (): void => {
  emits("get-interests");
  userInterests.value = props.user.interests.map((interest: any) => interest.description);
  inEditingMode.value = true;
};

const clearEditor = (): void => {
  userInterests.value = props.user.interests.map((interest: any) => interest.description);
  inEditingMode.value = false;
};

const handleUpdateInterests = (): void => {
  let updatedInterests = userInterests.value.map((int) => {
    return { description: int };
  });

  emits("update-interests", updatedInterests);
  inEditingMode.value = false;
};
</script>
