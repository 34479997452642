import { RouteInterface } from "@/common/types";
import Cvs from "./_views/cvs.vue";
import Cv from "./_views/cv.vue";

export default [
  {
    path: "cvs",
    component: Cvs,
    name: "cvs",
    meta: { auth: true },
  },
  {
    path: "cvs/:id",
    component: Cv,
    name: "cv",
    meta: { auth: true },
  },
] as Array<RouteInterface>;
