import axios from "axios";
import { router } from "./main";
import { jwtDecode } from "./utils";

// Setup axios as HTTP request library
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.baseURL = import.meta.env.VITE_API_BASE_PATH;

const TOKEN_KEY = "skynet.token";

axios.interceptors.request.use((config) => {
  const token: string | null = localStorage.getItem(TOKEN_KEY);
  const authToken = token ? `Bearer ${token}` : null;

  if (authToken && config.headers) {
    config.headers.Authorization = authToken;
  }

  return config;
});

// Intercept all 401 and logout user
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      removeToken();
      router.push({ name: "login" });
    }
    return Promise.reject(error.response);
  }
);

export function isAuthenticated(): boolean {
  const token: string | null = localStorage.getItem(TOKEN_KEY);
  if (!token) {
    return false;
  }

  const jwt = jwtDecode(token);
  if (!jwt) {
    return false;
  }

  return !!jwt["user_id"];
}

export function setJwtToken(token: string) {
  localStorage.setItem(TOKEN_KEY, token);
}

export function getJwtToken(): Record<string, any> | null {
  const token: string | null = localStorage.getItem(TOKEN_KEY);
  if (!token) {
    return null;
  }

  return jwtDecode(token);
}

export function removeToken() {
  localStorage.removeItem(TOKEN_KEY);
}

export default axios;
