<template>
  <a-modal
    :visible="visible"
    :destroy-on-close="true"
    title="Add Client"
    ok-text="Add"
    @ok="() => handleStoreClient()"
    @cancel="$emit('close')"
  >
    <a-form layout="horizontal">
      <SkInput
        v-model="newClient.name"
        v-validate="'required|max:255'"
        :error="errors.first('name')"
        data-vv-name="name"
        label="Name"
        placeholder="Client name"
      />

      <SkInput
        v-model="newClient.alias"
        v-validate="'required|max:255'"
        :error="errors.first('alias')"
        data-vv-name="alias"
        label="Alias"
        placeholder="Client alias"
      />

      <SkInput
        v-model="newClient.fortnox_id"
        v-validate="'max:255'"
        :error="errors.first('fortnox_id')"
        data-vv-name="fortnox_id"
        data-vv-as="Fortnox ID"
        label="Fortnox ID"
        placeholder="Fortnox ID"
      />

      <SkInput
        v-model="newClient.registration_number"
        v-validate="'max:255'"
        :error="errors.first('registration_number')"
        data-vv-name="registration_number"
        data-vv-as="registration number"
        label="Registration nr."
        placeholder="Registration number"
      />

      <SkNameSelect
        v-model="newClient.account_manager_id"
        label="Account manager"
        placeholder="Select an account manager"
        :options="users"
      />

      <a-form-item :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }" label="Secrecy level">
        <a-checkbox-group @change="(states) => onSecrecyChange(states)">
          <a-row>
            <a-col :span="24">
              <a-checkbox value="secrecy_mention_client_spoken">
                Mention as client, spoken
              </a-checkbox>
            </a-col>
            <a-col :span="24">
              <a-checkbox value="secrecy_client_is_official_written">
                Official as client, written
              </a-checkbox>
            </a-col>
            <a-col :span="24">
              <a-checkbox value="secrecy_ok_show_client_logo">
                Show their logo on our website
              </a-checkbox>
            </a-col>
          </a-row>
        </a-checkbox-group>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script setup lang="ts">
import { ref, watch, getCurrentInstance } from "vue";
import { MinimalUserInterface } from "@/modules/users/types";
import useMixin from "@/useMixin";

// Props
const props = defineProps({
  users: { type: Array as () => Array<MinimalUserInterface>, default: () => [] },
  visible: { type: Boolean, default: false },
});

// Emits
const emits = defineEmits(["get-users", "store-client", "close"]);

// Mixins
const { setObject } = useMixin();

// Data properties
const newClient = ref<any>({
  name: undefined,
  alias: undefined,
  registration_number: undefined,
  fortnox_id: undefined,
  account_manager_id: undefined,
  secrecy_client_is_official_written: undefined,
  secrecy_mention_client_spoken: undefined,
  secrecy_ok_show_client_logo: undefined,
});

watch(
  () => props.visible,
  (newProp, oldProp) => {
    if (newProp && !oldProp) {
      // Initialize all fields
      newClient.value = setObject(newClient.value, undefined);
      newClient.value.secrecy_client_is_official_written = false;
      newClient.value.secrecy_mention_client_spoken = false;
      newClient.value.secrecy_ok_show_client_logo = false;

      // Load users
      emits("get-users");
    }
  }
);

const onSecrecyChange = (states: Array<string>): void => {
  // Reset all secrecy level checkboxes
  newClient.value.secrecy_client_is_official_written = false;
  newClient.value.secrecy_mention_client_spoken = false;
  newClient.value.secrecy_ok_show_client_logo = false;

  // Select only checkboxes present in event payload
  states.forEach((el) => (newClient.value[el] = true));
};

const instance = getCurrentInstance();
const $validator = instance?.proxy?.$validator;
const handleStoreClient = (): void => {
  $validator?.validateAll().then((result) => {
    if (result) {
      emits("store-client", newClient.value);
      emits("close");
    }
  });
};
</script>
