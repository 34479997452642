import { TableColumnInterface } from "@/common/types";
export const cvsColumns: Array<TableColumnInterface> = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    width: 300,
    sorter: true,
    scopedSlots: { customRender: "cvName" },
  },
  {
    title: "Last Generated",
    dataIndex: "updated_at",
    key: "updated_at",
    width: 300,
    sorter: true,
    scopedSlots: { customRender: "cvUpdatedAt" },
  },
];
