<template>
  <a-modal
    :visible="visible"
    :destroy-on-close="true"
    title="Create Milestone"
    ok-text="Add"
    @ok="handleCreateMilestone"
    @cancel="closeModal"
  >
    <a-form layout="horizontal">
      <SkInput
        v-model="milestone.title"
        v-validate="'required|max:255'"
        :error="$validator.errors.first('title')"
        data-vv-name="title"
        label="Title"
        placeholder="Milestone title"
      />

      <SkTextarea
        v-model="milestone.description"
        v-validate="'required|max:255'"
        :error="$validator.errors.first('description')"
        data-vv-name="description"
        label="Description"
        placeholder="Milestone description"
      />

      <a-form-item
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
        :validate-status="$validator.errors.first('repositories') ? 'error' : ''"
        :help="$validator.errors.first('repositories')"
        label="Repositories"
      >
        <a-select
          v-model="milestone.repositories"
          v-validate="'required'"
          data-vv-name="repositories"
          mode="multiple"
          show-search
          placeholder="Select repositories"
        >
          <a-select-option v-for="repo in repositoryNames" :key="repo">{{ repo }}</a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }" label="Due date (optional)">
        <a-date-picker
          :value="parseDate(milestone.due_on)"
          class="w-100"
          allow-clear
          @change="(date) => onDateChange(date)"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script setup lang="ts">
import { ref, watch, toRef, getCurrentInstance } from "vue";
import { MinimalMilestoneInterface } from "@/modules/projects/types";
import useFilters from "@/useFilters";

// Props
const props = defineProps({
  visible: { type: Boolean, default: false },
  repositories: { type: Array as () => Array<{ name: string }>, default: () => [] },
});

// Emits
const emits = defineEmits(["create-milestone", "close"]);

// Filters
const { parseDate } = useFilters();

// Instances
const instance = getCurrentInstance();
const $validator = instance?.proxy.$validator;

// Data properties
const milestone = ref<MinimalMilestoneInterface>({
  title: null,
  description: null,
  repositories: [],
  due_on: undefined,
});
const repositoryNames = ref<string[]>([]);

// Watchers
watch(toRef(props, "visible"), (newProp, oldProp) => {
  if (newProp && !oldProp) {
    repositoryNames.value = props.repositories.map((r) => r.name);

    // Initialize all fields
    milestone.value.title = null;
    milestone.value.description = null;
    milestone.value.repositories = [...repositoryNames.value];
    milestone.value.due_on = undefined;
  }
});

// Class methods
const onDateChange = (date: any): void => {
  milestone.value.due_on = date ? date.format("YYYY-MM-DD") : undefined;
};

const handleCreateMilestone = (): void => {
  $validator?.validateAll().then((result) => {
    if (result) {
      emits("create-milestone", milestone.value);
      emits("close");
    }
  });
};

const closeModal = (): void => {
  emits("close");
};
</script>
