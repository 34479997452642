<template>
  <a-form data-vv-scope="branding">
    <a-row>
      <a-col>
        <h3>Branding for Legacy CV</h3>
      </a-col>
    </a-row>
    <a-row type="flex" justify="start">
      <a-col>
        <img :src="logo ?? logoDataURL()" class="logo" />
      </a-col>
    </a-row>
    <a-row type="flex" justify="start">
      <a-col>
        <input type="file" accept="image/*" @change="uploadAsBase64" />
      </a-col>
      <a-col>
        <a-button @click="() => restoreLogo()">Restore</a-button>
      </a-col>
    </a-row>
    <a-row>
      <a-col>
        <small class="text-body-secondary"><em>Changes are not persistent</em></small>
      </a-col>
    </a-row>
  </a-form>
</template>

<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount, getCurrentInstance, toRefs } from "vue";
import { CvInterface } from "../types";
import { emitter } from "@/mitt";
import logoDataURL from "../_utils/logoData";

// Props
const props = defineProps({
  cv: { type: Object as () => CvInterface, default: undefined },
  step: { type: Number, default: -1 },
});

// Emits
const emits = defineEmits(["cv-change", "go-to-step"]);

// Instances
const instance = getCurrentInstance();
const $validator = instance?.proxy.$validator;

// Data Properties
const { cv } = toRefs(props);
const logo = ref<string>(cv.value.custom_logo || logoDataURL());

// Life-cycle Hooks
onMounted(() => {
  emitter.on(`validate-cv-step-${props.step}`, (e) => validate(e as string));
  if (!logo.value) {
    logo.value = props.cvLogo;
    emits("cv-change", "custom_logo", logo.value);
  }
});

onBeforeUnmount(() => {
  emitter.off(`validate-cv-step-${props.step}`, (e) => validate(e as string));
});

// Class Methods
const validate = (nextStep: string) => {
  $validator?.validateAll("branding").then((result) => {
    if (result) {
      emits("cv-change", "custom_logo", logo.value);
      emits("go-to-step", nextStep);
    }
  });
};

const uploadAsBase64 = (inputElement) => {
  const reader = new FileReader();
  reader.onloadend = () => {
    setLogo(reader.result);
  };
  reader.readAsDataURL(inputElement.target.files[0]);
};

const setLogo = (l: string) => {
  logo.value = l;
  emits("cv-change", "custom_logo", logo.value);
};

const restoreLogo = () => {
  logo.value = undefined;
  emits("cv-change", "custom_logo", null);
};
</script>
<style lang="scss" scoped>
.logo {
  width: 12em;
  margin-bottom: 1em;
}
</style>
