<template>
  <div class="card">
    <div class="d-flex mb-3 justify-content-between align-items-center">
      <h2 class="m-0">Education</h2>
      <span
        v-if="acl.isOwner || acl.isAdmin"
        class="action-default d-flex justify-content-end align-items-center"
        @click="openAddEducationModal"
      >
        <a-icon type="plus" class="mr-2" />Add
      </span>
    </div>

    <!-- LIST OF EDUCATIONS -->
    <div v-if="educations.length > 0">
      <a-list :data-source="educations" :split="false" item-layout="vertical" size="small">
        <template #renderItem="education">
          <a-list-item class="d-flex justify-content-between flex-column flex-lg-row">
            <div class="mb-1" style="width: 90%">
              <div class="d-flex justify-content-between flex-column flex-lg-row">
                <h3 class="m-0">{{ education.title }} at {{ education.university_name }}</h3>
                <span v-if="education.start_date">
                  <em>{{ formatDateForCV(education.start_date) }} - </em>
                  <em v-if="education.end_date">{{ formatDateForCV(education.end_date) }}</em>
                  <em v-else>ongoing</em>
                </span>
              </div>
              <span>{{ education.description }}</span>
            </div>
            <div v-if="acl.isOwner || acl.isAdmin" class="d-flex justify-content-end">
              <a-tooltip>
                <template #title> Edit education information </template>
                <a-icon
                  type="edit"
                  class="action-default"
                  @click="openEditEducationModal(education)"
                />
              </a-tooltip>
              <a-divider type="vertical" />
              <a-tooltip>
                <template #title> Delete education </template>
                <a-icon
                  type="delete"
                  class="action-danger"
                  @click="showDeleteEducationModal(education)"
                />
              </a-tooltip>
            </div>
          </a-list-item>
        </template>
      </a-list>
    </div>

    <!-- ADD EDUCATION MODAL -->
    <a-modal
      v-model="addEducationModalVisible"
      :destroy-on-close="true"
      title="Add Education"
      ok-text="Save"
      @ok="handleStoreEducation"
    >
      <a-form layout="horizontal" data-vv-scope="add-education">
        <a-form-item
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
          :validate-status="$validator.errors.first('add-education.university_name') ? 'error' : ''"
          :help="$validator.errors.first('add-education.university_name')"
          label="Institution"
        >
          <a-input
            v-model="newEducation.university_name"
            v-validate="'max:255'"
            data-vv-name="university_name"
            data-vv-as="university name"
            placeholder="Name of the institution"
          />
        </a-form-item>

        <a-form-item
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
          :validate-status="$validator.errors.first('add-education.title') ? 'error' : ''"
          :help="$validator.errors.first('add-education.title')"
          label="Title"
        >
          <a-input
            v-model="newEducation.title"
            v-validate="'required|max:255'"
            placeholder="Title of the education"
            data-vv-name="title"
          />
        </a-form-item>

        <a-form-item
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
          :validate-status="$validator.errors.first('add-education.description') ? 'error' : ''"
          :help="$validator.errors.first('add-education.description')"
          label="Description"
        >
          <a-textarea
            v-model="newEducation.description"
            v-validate="'required|max:1024'"
            :rows="2"
            placeholder="Description of the education"
            data-vv-name="description"
          />
        </a-form-item>

        <a-form data-vv-scope="tab-skills" layout="horizontal">
          <a-form-item :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" label="Skills">
            <a-select
              v-model="newEducation.skill_ids"
              mode="multiple"
              show-search
              placeholder="Select skills"
              option-filter-prop="children"
            >
              <a-select-option
                v-for="s in skills"
                :key="s.id"
                :class="{ 'already-in': !!s.skill_id }"
                >{{ s.title }}</a-select-option
              >
            </a-select>
          </a-form-item>
        </a-form>

        <a-form-item
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
          :validate-status="$validator.errors.first('add-education.start_date') ? 'error' : ''"
          :help="$validator.errors.first('add-education.start_date')"
          label="Start date"
        >
          <a-date-picker
            v-validate="'required'"
            :value="parseDate(newEducation.start_date)"
            :disabled-date="disableStartDate"
            data-vv-name="start_date"
            data-vv-as="start date"
            class="w-100"
            @change="(d) => onDateChange(d, 'start_date')"
          />
        </a-form-item>

        <a-form-item
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
          :validate-status="$validator.errors.first('add-education.end_date') ? 'error' : ''"
          :help="$validator.errors.first('add-education.end_date')"
          label="End date"
        >
          <a-date-picker
            :value="parseDate(newEducation.end_date)"
            :disabled-date="disableEndDate"
            data-vv-name="end_date"
            data-vv-as="end date"
            class="w-100"
            @change="(d) => onDateChange(d, 'end_date')"
          />
        </a-form-item>
      </a-form>
    </a-modal>

    <!-- EDIT EDUCATION MODAL -->
    <a-modal
      v-model="editEducationModalVisible"
      :destroy-on-close="true"
      title="Edit Education"
      ok-text="Save"
      @ok="handleUpdateEducation"
    >
      <a-form layout="horizontal" data-vv-scope="edit-education">
        <a-form-item
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
          :validate-status="
            $validator.errors.first('edit-education.university_name') ? 'error' : ''
          "
          :help="$validator.errors.first('edit-education.university_name')"
          label="Institution"
        >
          <a-input
            v-model="newEducation.university_name"
            v-validate="'max:255'"
            data-vv-name="university_name"
            data-vv-as="university name"
            placeholder="Name of the institution"
          />
        </a-form-item>

        <a-form-item
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
          :validate-status="$validator.errors.first('edit-education.title') ? 'error' : ''"
          :help="$validator.errors.first('edit-education.title')"
          label="Title"
        >
          <a-input
            v-model="newEducation.title"
            v-validate="'required|max:255'"
            placeholder="Title of the education"
            data-vv-name="title"
          />
        </a-form-item>

        <a-form-item
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
          :validate-status="$validator.errors.first('edit-education.description') ? 'error' : ''"
          :help="$validator.errors.first('edit-education.description')"
          label="Description"
        >
          <a-textarea
            v-model="newEducation.description"
            v-validate="'required|max:1024'"
            placeholder="Description of the education"
            data-vv-name="description"
          />
        </a-form-item>

        <a-form data-vv-scope="tab-skills" layout="horizontal">
          <a-form-item :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" label="Skills">
            <a-select
              v-model="newEducation.skill_ids"
              mode="multiple"
              show-search
              placeholder="Select skills"
              option-filter-prop="children"
            >
              <a-select-option
                v-for="s in skills"
                :key="s.id"
                :class="{ 'already-in': !!s.skill_id }"
              >
                {{ s.title }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-form>

        <a-form-item
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
          :validate-status="$validator.errors.first('edit-education.start_date') ? 'error' : ''"
          :help="$validator.errors.first('edit-education.start_date')"
          label="Start date"
        >
          <a-date-picker
            v-validate="'required'"
            :value="parseDate(newEducation.start_date)"
            :disabled-date="disableStartDate"
            data-vv-name="start_date"
            data-vv-as="start date"
            class="w-100"
            @change="(d) => onDateChange(d, 'start_date')"
          />
        </a-form-item>

        <a-form-item
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
          :validate-status="$validator.errors.first('edit-education.end_date') ? 'error' : ''"
          :help="$validator.errors.first('edit-education.end_date')"
          label="End date"
        >
          <a-date-picker
            :value="parseDate(newEducation.end_date)"
            :disabled-date="disableEndDate"
            class="w-100"
            @change="(d) => onDateChange(d, 'end_date')"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script setup lang="ts">
import { ref, getCurrentInstance, watch } from "vue";
import { BaseAclInterface } from "@/modules/authentication/types";
import { showDeleteExperienceModal } from "../_utils/experience";
import useMixin from "@/useMixin";
import moment from "@/date";
import useFilters from "@/useFilters";
import { SkillInterface } from "@/modules/skills/types";
import { EducationInterface } from "@/modules/users/types";

// Props
const props = defineProps({
  educations: { type: Array, default: () => [] },
  skills: { type: Array as () => Array<SkillInterface>, default: () => [] },
  acl: { type: Object as () => BaseAclInterface, default: undefined },
});

// Emits
const emits = defineEmits([
  "store-education",
  "update-education",
  "destroy-education",
  "get-skills",
]);

// Mixins
const { setObject, formatDateForCV } = useMixin();

// Filters
const { parseDate } = useFilters();

// Instances
const instance = getCurrentInstance();
const $validator = instance?.proxy.$validator;

// Data Properties
const newEducation = ref<EducationInterface>({
  id: 0,
  university_name: "",
  title: "",
  start_date: "",
  end_date: "",
  description: "",
  skill_ids: undefined,
});
const addEducationModalVisible = ref<boolean>(false);
const editEducationModalVisible = ref<boolean>(false);

// Watchers
watch(
  [addEducationModalVisible, editEducationModalVisible],
  ([addNewProp, editNewProp], [addOldProp, editOldProp]) => {
    if ((addNewProp && !addOldProp) || (editNewProp && !editOldProp)) {
      emits("get-skills");
    }
  }
);

// Component Methods
const openAddEducationModal = (): void => {
  $validator?.errors.clear();
  newEducation.value = setObject(newEducation.value, undefined);
  addEducationModalVisible.value = true;
};

const handleStoreEducation = (): void => {
  $validator?.validateAll("add-education").then((result) => {
    if (result) {
      emits("store-education", newEducation.value);
      addEducationModalVisible.value = false;
    }
  });
};

const openEditEducationModal = (education: any): void => {
  $validator?.errors.clear();
  newEducation.value = setObject(newEducation.value, education);
  editEducationModalVisible.value = true;
};

const handleUpdateEducation = (): void => {
  $validator?.validateAll("edit-education").then((result) => {
    if (result) {
      emits("update-education", newEducation.value);
      editEducationModalVisible.value = false;
    }
  });
};

const showDeleteEducationModal = (education: any): void => {
  showDeleteExperienceModal(instance?.proxy.$confirm, education, "education", () => {
    emits("destroy-education", education.id);
  });
};

const onDateChange = (value: any, attribute: string): void => {
  newEducation.value[attribute] = value ? value.format("YYYY-MM-DD") : null;
};

// Cannot have start_date in future or after end_date.
const disableStartDate = (current: any): any => {
  const today = moment().endOf("day");
  const isAfterEndDate =
    current &&
    newEducation.value.end_date &&
    current > moment(newEducation.value.end_date).endOf("day");
  const isInFuture = current && moment(current).isAfter(today);
  return isAfterEndDate || isInFuture;
};

// Cannot have end_date in future or before start_date.
const disableEndDate = (current: any): any => {
  const isBeforeStartDate =
    current &&
    newEducation.value.start_date &&
    current < moment(newEducation.value.start_date).startOf("day");
  return isBeforeStartDate;
};
</script>
