<template v-if="cv">
  <table class="table">
    <tbody>
      <tr>
        <td>
          <article v-if="cv">
            <section id="heading">
              <svg class="heading-circle" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                <circle cx="50" cy="50" r="50" />
              </svg>
              <div class="heading-titles">
                <h1 class="m-0">{{ cv.name }}</h1>
                <h3 class="m-0">{{ cv.first_title }}</h3>
                <h3 class="m-0">{{ cv.second_title }}</h3>
                <div v-if="showGithub && cv.github && cv.github.length > 1" class="mt-1">
                  <a-icon type="github" class="mr-1" />
                  <a :href="`https://www.github.com/${cv.github}`">
                    {{ cv.github }}
                  </a>
                </div>
              </div>
              <div class="heading-meta">
                <img class="logo" :src="logo" />
                <p />
                <span>Created: {{ dateString }}</span>
              </div>
            </section>

            <section id="background">
              <h2>Where {{ cv.name.split(" ")[0] }} excels</h2>

              <p v-for="p in cvBackground" :key="p">
                {{ p }}
              </p>

              <span
                v-for="characteristic in cv.characteristics"
                :key="characteristic.id"
                class="cv-badge cv-badge-raised"
              >
                {{ characteristic.title }}
              </span>
            </section>

            <section v-if="cvMotivation" id="motivation">
              <h2>Why {{ cv.name.split(" ")[0] }} would be a good candidate</h2>

              <p v-for="p in cvMotivation" :key="p">
                {{ p }}
              </p>
            </section>

            <section
              v-if="cv.knowledge_domains.length && showAreasOfExpertise"
              id="knowledge-domains"
            >
              <h2>Areas of Expertise</h2>
              <div v-for="domain in cv.knowledge_domains" :key="domain.id" class="cv-card-wrapper">
                <div class="cv-card">
                  <div class="cv-card-heading">
                    <h4>{{ domain.title }}</h4>
                    <span class="cv-badge cv-badge-rounded cv-badge-blue mt-3">
                      {{ domain.level }}
                    </span>
                  </div>
                  <div class="cv-card-divider" />
                  <p class="cv-card-body">{{ domain.description }}</p>
                </div>
              </div>
            </section>

            <section
              v-if="cv.skill_categories?.length && showSkillCategories"
              id="skill-categories"
            >
              <div class="cv-card-col-container">
                <div
                  v-for="category in cv.skill_categories"
                  :key="category.id"
                  class="cv-card-col-wrapper"
                >
                  <div class="cv-col-card">
                    <div class="cv-card-heading">
                      <h4>{{ category.title }}</h4>
                    </div>
                    <a-divider class="cv-card-divider" />
                    <div class="cv-card-body">
                      <div class="cv-skills">
                        <div
                          v-for="skill in category.skills"
                          :key="skill.id"
                          class="cv-skill"
                          :class="{
                            'cv-skill-highlight':
                              showSkillHighlights &&
                              cv.skills.find((s) => s.id === skill.id)?.highlight,
                          }"
                        >
                          {{ skill.title }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <div class="page-breaker"></div>

            <footer class="footer-page-bottom">
              <div class="d-flex flex-column justify-content-end">
                <p>Thank you for your time!</p>
                <p>For inquiries please contact:</p>
              </div>
              <div class="d-flex flex-column justify-content-end text-right">
                <p>{{ cv.contact_email }}</p>
                <p>{{ cv.contact_phone }}</p>
              </div>
            </footer>

            <header>
              <h3 class="m-0">{{ cv.name }}</h3>
              <img class="logo padding-reset" :src="logo" />
            </header>

            <section v-if="cv.skills?.length && !showSkillCategories" id="skills" class="">
              <h2>Languages, Tools, Frameworks</h2>
              <div class="cv-skills">
                <div
                  v-for="skill in cv.skills"
                  :key="skill.id"
                  class="cv-skill"
                  :class="{ 'cv-skill-highlight': showSkillHighlights && skill.highlight }"
                >
                  {{ skill.title }}
                </div>
              </div>
            </section>

            <section v-if="cv.roles.length || cv.work_experiences.length" id="projects">
              <h2 class="page-break-after-avoid">Selected projects</h2>
              <div
                v-for="role in (cv.roles || []).concat(cv.work_experiences || [])"
                :key="role.id"
                class="cv-card-wrapper"
              >
                <div class="cv-card">
                  <div class="cv-card-heading">
                    <h4>
                      {{
                        (role.client && !role.hide_client_name ? role.client : role.client_alias) ||
                        role.company_name
                      }}
                    </h4>
                    <p>{{ role.custom_role_title ?? role.title }}</p>
                    <p v-if="showDates">
                      {{ formatProjectDate(role.start_date, role.end_date) }}
                    </p>
                    <p v-if="showDuration">
                      {{ formatProjectDuration(role.start_date, role.end_date) }}
                    </p>
                  </div>
                  <div class="cv-card-divider" />
                  <div class="cv-card-body">
                    <p>{{ role.description }}</p>
                    <a
                      v-if="role.url_link"
                      :href="`${role.url_link}`"
                      target="_blank"
                      class="d-flex mb-1"
                    >
                      {{ role.url_link }}
                    </a>
                  </div>
                  <section v-if="showSkills" class="cv-card-meta">
                    <span
                      v-for="skill in role.skills"
                      :key="skill.id"
                      :class="{
                        'cv-badge-highlight':
                          showSkillHighlights &&
                          cv.skills.find((s) => s.id === skill.id)?.highlight,
                      }"
                      class="cv-badge cv-badge-rounded cv-badge-blue mt-2"
                    >
                      {{ skill.title }}
                    </span>
                  </section>
                </div>
              </div>
            </section>

            <section v-if="cv.courses.length" id="courses" class="unbreakable">
              <h2>Certifications / Courses</h2>
              <div class="cv-courses">
                <div v-for="course in cv.courses" :key="course.id" class="cv-card cv-course">
                  <div class="cv-card-body">
                    <h4>{{ course.title }}</h4>
                    <p>{{ course.description }}</p>
                  </div>
                </div>
              </div>
            </section>

            <!-- Added here as a padding to prevent bug with projects card not displaying in print mode -->
            <section v-else class="height-padding" />

            <footer class="footer-light">
              <div class="d-flex flex-column justify-content-end">
                <p>Thank you for your time!</p>
                <p>For inquiries please contact:</p>
              </div>
              <div class="d-flex flex-column justify-content-end text-right">
                <p>{{ cv.contact_email }}</p>
                <p>{{ cv.contact_phone }}</p>
              </div>
            </footer>
          </article>
        </td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <td class="tfooter"></td>
      </tr>
    </tfoot>
  </table>
</template>

<script setup lang="ts">
import { computed, ref, toRefs } from "vue";
import logoDataURL from "../../_utils/logoData";
import { CvInterface } from "../../types";
import moment from "@/date";

const props = defineProps({
  cv: { type: Object as () => CvInterface, default: undefined },
  showDates: { type: Boolean, default: undefined },
  showDuration: { type: Boolean, default: undefined },
  showSkillCategories: { type: Boolean, default: undefined },
  showAreasOfExpertise: { type: Boolean, default: undefined },
  showSkills: { type: Boolean, default: undefined },
  showGithub: { type: Boolean, default: undefined },
  cvBackground: { type: Array as () => string[], default: () => [] },
  cvMotivation: { type: Array as () => string[] | null, default: () => [] },
  showSkillHighlights: { type: Boolean, default: false },
  cvLogo: { type: String as () => string | null, default: () => null },
  formatProjectDate: {
    type: Function,
    default: (start: any, end: any) => String,
  },
  formatProjectDuration: {
    type: Function,
    default: (start: string, end: string) => String,
  },
});

const { cvLogo } = toRefs(props);

// Data properties
const logo = computed<string>(() => {
  if (cvLogo.value) {
    return cvLogo.value;
  } else {
    return logoDataURL();
  }
});
const dateString = ref<string>(moment().format("MMMM YYYY"));
</script>

<style lang="scss" scoped>
$background: #f4f4f4;
$accent: #e5bf00;
$text: #000000;
$text-faded: #7a7a7a;
$dark-gray: #22292f;
$default-padding: 3em;
$default-border-radius: 4px;
$default-border: 1px solid #ebebeb;
$heading-height: 12em;
$footer-height: 21mm;

@mixin shadow-border {
  border: $default-border;
  border-bottom-width: 1.5px;
}

@page {
  margin: 0;
  size: a4 portrait;
}

@media print {
  .page-breaker {
    page-break-before: always;
  }
}

.tfooter {
  height: $footer-height;
}

sidebar,
navbar,
.ant-layout-header {
  @media print {
    display: none;
  }
}

h1 {
  color: $text;
  font-size: 2.2em;
  font-weight: bold;
}

h2 {
  color: $text;
  font-size: 1.25em;
  font-weight: bold;
}

h3 {
  color: $text;
  font-size: 1.25em;
  font-weight: normal;

  h3 ~ & {
    margin-bottom: 0;
  }
}

h4 {
  color: $text;
  font-size: 1.15em;
  font-weight: bold;
}

.cv-link {
  color: grey;
  font-weight: normal;
}

.table {
  background-color: $background;

  @media print {
    position: absolute;
  }
}

article {
  position: relative;
  background: $background;
  color: $text;
  font-size: 12px;
  line-height: 1.5;
  overflow: hidden;

  @media not print {
    width: 210mm;
    margin: auto;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  }
}

section {
  padding: $default-padding $default-padding 0;

  &:last-of-type {
    padding-bottom: $default-padding;
  }

  &#heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: $heading-height;
    background: white;
    border-bottom: $default-border;
    border-bottom-width: 2px;
    padding: $default-padding $default-padding $default-padding $default-padding * 2.5;

    .heading-titles {
      display: flex;
      flex-direction: column;
    }

    .heading-meta {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      font-size: 0.9em;
      text-align: right;
      color: $text-faded;
    }

    .heading-circle {
      $radius: 4em;
      fill: $accent;
      position: absolute;
      top: calc(($heading-height - $radius * 2) / 2);
      left: -$radius;
      width: calc($radius * 2);
      height: calc($radius * 2);
    }
  }

  .unbreakable {
    break-inside: avoid;
  }

  .height-padding {
    height: 150px;
  }
}

.logo {
  width: 12em;
  max-height: 10em;
  object-fit: contain;
}

.page-break {
  page-break-before: always;
}

.page-break-after-avoid {
  page-break-after: avoid;
}

.cv-card-wrapper {
  &:not(:first-of-type) {
    padding-top: 0.5em;
    overflow: visible;
  }
}

.cv-card,
.cv-col-card {
  @include shadow-border;
  display: flex;
  flex-wrap: wrap;
  background: white;
  border-radius: $default-border-radius;
  height: 100%;

  @media print {
    page-break-inside: avoid;
  }

  &-heading {
    flex: 1;
    padding: 1.25em;
  }

  &-divider {
    border-left: $default-border;
    margin: 1.25em 0;
  }

  &-body {
    flex: 3;
    padding: 1.25em;
  }

  &-meta {
    flex-basis: 100%;
    padding: 0 1.25em 1.25em;
  }
}

.cv-col-card {
  display: block;
  &-heading {
    flex: 1;
    padding-left: 1.25em;
    padding-top: 1.25em;
  }
}

.cv-badge {
  display: inline-block;
  background: white;
  padding: 0.5em 1.25em;
  border-radius: $default-border-radius;

  &:not(:last-of-type) {
    margin-right: 1em;
  }

  &-block {
    display: block;
    text-align: center;
  }

  &-raised {
    @include shadow-border;
  }

  &-rounded {
    border-radius: 100px;
  }

  &-blue {
    background: rgba(0, 100, 255, 0.1);
    border: 0;
  }

  &-highlight {
    font-weight: bolder;
  }
}

.cv-skills {
  $gutter: 0.25em;
  display: flex;
  flex-wrap: wrap;
  margin: -$gutter;

  .cv-skill {
    @include shadow-border;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: calc(25% - #{$gutter * 2});
    background: white;
    border-radius: $default-border-radius;
    margin: $gutter;
    padding: 0.75em;
    font-weight: bold;
    text-align: center;
  }

  .cv-skill-highlight {
    font-weight: bolder;
    border: 1px solid $dark-gray;
  }
}

.cv-courses {
  $gutter: 0.25em;
  margin: -$gutter;

  .cv-course {
    display: inline-block;
    width: calc(50% - #{$gutter * 2});
    margin: $gutter;
  }
}

.padding-reset {
  height: calc(100% + $default-padding / 2);
}

header {
  $header-height: 16mm;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: $header-height;
  background: white;
  padding: 1em $default-padding;
  border-bottom: $default-border;
  border-bottom-width: 2px;
  font-size: 1.5em;
  img {
    height: 100%;
  }
}

footer {
  display: flex;
  justify-content: space-between;
  height: $footer-height;
  background: $dark-gray;
  color: white;
  border-top: $default-border;
  border-top-width: 2px;
  margin-top: $default-padding;
  padding: 1em $default-padding;

  &.footer-light {
    background: white;
    color: $text;
    @media print {
      position: fixed;
      top: (2 * 297mm) - $footer-height - 0.75mm;
      width: 100%;
      margin: 0;
    }
  }

  &.footer-page-bottom {
    @media print {
      position: fixed;
      top: 297mm - $footer-height - 0.75mm;
      width: 100%;
      margin: 0;
    }
  }

  p {
    font-size: 1.25em;
    margin-bottom: 0.25em;
  }
}

.cv-card-col-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.cv-card-col-wrapper {
  flex: 0 0 calc(50% - 10px);
}
</style>
