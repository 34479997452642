import { CostCenterPayloadInterface } from "../types";
import axios from "@/network";

export function apiGetCostCenters() {
  const url = "/cost_centers";
  return axios.get(url);
}

export function apiStoreCostCenter(newCostCenter: CostCenterPayloadInterface) {
  const url = "/cost_centers";
  const payload = { cost_center: newCostCenter };
  return axios.post(url, payload);
}

export function apiUpdateCostCenter(updatedCostCenter: CostCenterPayloadInterface) {
  const url = `/cost_centers/${updatedCostCenter.id}`;
  const payload = { cost_center: updatedCostCenter };
  return axios.patch(url, payload);
}
