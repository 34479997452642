<template>
  <a-modal
    :visible="visible"
    :destroy-on-close="true"
    title="Create Budget"
    @ok="() => handleStoreBudget()"
    @cancel="() => closeModal()"
  >
    <a-form data-vv-scope="newBudget" layout="horizontal">
      <SkInput
        v-model="newBudget.name"
        v-validate="'required|max:255'"
        :error="$validator.errors.first('newBudget.name')"
        label="Name"
        placeholder="Budget name"
        data-vv-name="name"
      />

      <SkSelect
        v-model="newBudget.type"
        v-validate="'required'"
        :error="$validator.errors.first('newBudget.type')"
        :options="types"
        label="Type"
        placeholder="Please select a type"
        data-vv-name="type"
        display-prop="name"
      />

      <SkInput
        v-if="newBudget.type === 'HourlyBudget'"
        v-model="newBudget.amount"
        v-validate="'required|decimal|min_value:0'"
        :error="$validator.errors.first('newBudget.amount')"
        :addon-after="'hours'"
        data-vv-name="amount"
        label="Budget amount"
        placeholder="Amount in hours"
      />

      <SkMoney
        v-if="newBudget.type === 'MoneyBudget'"
        v-model="newBudget.amount_cents"
        v-validate="'required|decimal|min_value:0'"
        :error="$validator.errors.first('newBudget.amount')"
        :currency="newBudget.amount_currency"
        data-vv-name="amount"
        label="Budget amount"
        placeholder="Amount"
        @set-currency="(v) => (newBudget.amount_currency = v)"
      />
    </a-form>

    <a-form-item
      :label-col="{ span: 8 }"
      :wrapper-col="{ span: 16 }"
      :validate-status="$validator.errors.first('newBudget.start_date') ? 'error' : ''"
      :error="$validator.errors.first('newBudget.start_date')"
      :help="$validator.errors.first('newBudget.start_date')"
      label="Start date"
    >
      <a-date-picker
        v-validate="'required'"
        :value="parseDate(newBudget.start_date)"
        :disabled-date="disabledDateForStartDate"
        class="w-100"
        allow-clear
        placeholder="Start date"
        data-vv-name="start_date"
        data-vv-as="start date"
        @change="(date) => onDateChange('start_date', date)"
      />
    </a-form-item>

    <a-form-item
      :label-col="{ span: 8 }"
      :wrapper-col="{ span: 16 }"
      :validate-status="$validator.errors.first('newBudget.end_date') ? 'error' : ''"
      :help="$validator.errors.first('newBudget.end_date')"
      label="End date"
    >
      <a-date-picker
        v-validate="'required'"
        :value="parseDate(newBudget.end_date)"
        :disabled-date="disabledDateForEndDate"
        class="w-100"
        allow-clear
        placeholder="End date"
        data-vv-name="end_date"
        data-vv-as="end date"
        @change="(date) => onDateChange('end_date', date)"
      />
    </a-form-item>

    <a-form-item
      :label-col="{ span: 8 }"
      :wrapper-col="{ span: 16 }"
      class="d-fle"
      label="Recurring"
    >
      <a-checkbox
        :value="newBudget.recurring"
        class="ml-3"
        @change="() => (newBudget.recurring = !newBudget.recurring)"
      />
    </a-form-item>

    <SkSelect
      v-model="newBudget.role_ids"
      v-validate="'required'"
      :error="$validator.errors.first('newBudget.role_ids')"
      :options="roles"
      :display-prop-func="(item) => `${item.title} (${item.user.name})`"
      label="Roles"
      mode="multiple"
      placeholder="Please select roles"
      data-vv-name="type"
    />
  </a-modal>
</template>

<script setup lang="ts">
import { ref, watch, getCurrentInstance, toRefs } from "vue";
import { RoleInterface } from "../../roles/types";
import { ProjectInterface } from "../../projects/types";
import { ProfileInterface } from "@/modules/authentication/types";
import { BudgetPayloadInterface } from "../types";
import moment from "@/date";
import { Moment } from "moment";
import useMixin from "@/useMixin";
import useFilters from "@/useFilters";

// Props
const props = defineProps<{
  roles: RoleInterface;
  currentUser: ProjectInterface;
  project: ProfileInterface;
  visible: Boolean;
}>();

// Emits
const emits = defineEmits(["store-budget", "close"]);

// Mixins
const { setObject, toMoneyCents } = useMixin();

// Filters
const { parseDate } = useFilters();

// Data properties
const { currentUser, project } = toRefs(props);

const newBudget = ref<BudgetPayloadInterface>({
  user_id: currentUser.value.id,
  project_id: project.value ? project.value.id : undefined,
  role_ids: undefined,
  name: undefined,
  recurring: false,
  type: "HourlyBudget",
  amount: undefined,
  amount_currency: undefined,
  amount_cents: undefined,
  start_date: moment().format("YYYY-MM-DD"),
  end_date: undefined,
});

const types = ref<Array<{ id: string; name: string }>>([
  {
    id: "HourlyBudget",
    name: "Hourly Budget",
  },
  {
    id: "MoneyBudget",
    name: "Money Budget",
  },
]);

// Watchers
watch(
  () => props.visible,
  (newProp, oldProp) => {
    if (newProp && !oldProp) {
      newBudget.value = setObject(newBudget.value, undefined);
      newBudget.value.user_id = props.currentUser.id;
      newBudget.value.project_id = props.project ? props.project.id : undefined;
      newBudget.value.type = "HourlyBudget";
      newBudget.value.recurring = false;
      newBudget.value.start_date = moment().format("YYYY-MM-DD");
      newBudget.value.amount_cents = 0;
      newBudget.value.amount_currency = "SEK";
    }
  }
);

// Class Methods
const onDateChange = (dateName: string, date: Moment): void => {
  newBudget.value[dateName] = date ? date.format("YYYY-MM-DD") : null;
};

const disabledDateForStartDate = (current: Moment): boolean => {
  return Boolean(
    current && newBudget.value.end_date && current > moment(newBudget.value.end_date).endOf("day")
  );
};

const disabledDateForEndDate = (current: Moment): boolean => {
  return Boolean(
    current &&
      newBudget.value.start_date &&
      current < moment(newBudget.value.start_date).startOf("day")
  );
};

const closeModal = (): void => {
  emits("close");
};

const changeAmountToCents = (): void => {
  newBudget.value.amount_cents = toMoneyCents(newBudget.value.amount_cents ?? 0);
};

const instance = getCurrentInstance();
const $validator = instance?.proxy?.$validator;
const handleStoreBudget = (): void => {
  $validator?.validateAll("newBudget").then((result) => {
    // If last step and validation pass...
    if (result) {
      changeAmountToCents();
      emits("store-budget", newBudget.value);
      emits("close");
    }
  });
};
</script>
