import { TeamPayloadInterface } from "../types";
import axios from "@/network";

export function apiGetTeams() {
  const url = "/teams";
  return axios.get(url);
}

export function apiGetTeam(teamId: number) {
  const url = `/teams/${teamId}`;
  return axios.get(url);
}

export function apiStoreTeam(newTeam: TeamPayloadInterface) {
  const url = "/teams";
  const payload = { team: newTeam };
  return axios.post(url, payload);
}

export function apiUpdateTeam(teamId: number, updatedTeam: TeamPayloadInterface) {
  const url = `/teams/${teamId}`;
  const payload = { team: updatedTeam };
  return axios.patch(url, payload);
}

export function apiDestroyTeam(teamId: number) {
  const url = `/teams/${teamId}`;
  return axios.delete(url);
}
