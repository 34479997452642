<template>
  <a-modal
    :visible="visible"
    :acl="acl"
    :destroy-on-close="true"
    title="Edit Project"
    ok-text="Save"
    @ok="handleUpdateProject"
    @cancel="() => $emit('close')"
  >
    <!-- EDIT PROJECT TABS -->
    <a-tabs default-active-key="0">
      <!-- GENERAL INFO TAB -->
      <a-tab-pane key="0">
        <template #tab>
          <span>
            <a-badge v-show="$validator.errors.any('tab-info')" status="error" />
            <a-icon type="form" />Info
          </span>
        </template>

        <a-form layout="horizontal" data-vv-scope="tab-info">
          <SkInput
            v-model="newProject.name"
            v-validate="'required|max:255'"
            :error="$validator.errors.first('tab-info.name')"
            data-vv-name="name"
            label="Name"
            placeholder="Project name"
          />

          <SkInput
            v-model="newProject.alias"
            v-validate="'max:255'"
            :error="$validator.errors.first('tab-info.alias')"
            data-vv-name="alias"
            label="Alias"
            placeholder="Project alias"
          />

          <SkNameSelect
            v-model="newProject.client_id"
            label="Client"
            placeholder="Select client"
            :options="clients"
          />

          <SkCheckbox
            v-if="project && project.hasOwnProperty('github_team_sync_enabled')"
            v-model="newProject.github_team_sync_enabled"
            label="GitHub Team sync"
          />

          <SkInput
            v-if="newProject.github_team_sync_enabled"
            v-model="newProject.github_team_name"
            v-validate="'required|max:100'"
            :error="$validator.errors.first('tab-info.github_team_name')"
            data-vv-name="github_team_name"
            data-vv-as="GitHub team name"
            label="GitHub team name"
            placeholder="GitHub team name"
          />
        </a-form>
      </a-tab-pane>

      <!-- DESCRIPTION TAB -->
      <a-tab-pane key="1">
        <template #tab>
          <span>
            <a-badge v-show="$validator.errors.any('tab-description')" status="error" />
            <a-icon type="file-text" />Description
          </span>
        </template>

        <a-form layout="horizontal" data-vv-scope="tab-description">
          <a-form-item
            :label-col="{ span: 8 }"
            :wrapper-col="{ span: 16 }"
            :validate-status="$validator.errors.first('tab-description.type') ? 'error' : ''"
            :help="$validator.errors.first('tab-description.type')"
            label="Type"
          >
            <a-select v-model="newProject.project_type" placeholder="Select a type">
              <a-select-option value="sk_for_hire">SK for hire</a-select-option>
              <a-select-option value="expert_for_hire">Expert for hire</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item
            :label-col="{ span: 8 }"
            :wrapper-col="{ span: 16 }"
            label="Internal description"
          >
            <a-textarea v-model="newProject.internal_description" :auto-size="{ minRows: 3 }" />
          </a-form-item>

          <a-form-item
            :label-col="{ span: 8 }"
            :wrapper-col="{ span: 16 }"
            label="Public description"
          >
            <a-textarea v-model="newProject.public_description" :auto-size="{ minRows: 3 }" />
          </a-form-item>
        </a-form>
      </a-tab-pane>

      <!-- FINANCIAL TAB -->
      <a-tab-pane v-if="project && project.hasOwnProperty('fortnox_id')" key="2">
        <template #tab>
          <span>
            <a-badge v-show="$validator.errors.any('tab-financial')" status="error" />
            <a-icon type="credit-card" />Financial
          </span>
        </template>
        <a-form layout="horizontal" data-vv-scope="tab-financial">
          <SkInput
            v-if="acl && (acl.isAdmin || acl.isFinancial)"
            v-model="newProject.fortnox_id"
            v-validate="'alpha_num|max:255'"
            :error="$validator.errors.first('tab-financial.fortnox_id')"
            data-vv-name="fortnox_id"
            data-vv-as="Fortnox ID"
            label="FORTNOX id"
            placeholder="FORTNOX id"
          />

          <a-form-item
            v-if="acl && (acl.isAdmin || acl.isFinancial)"
            :label-col="{ span: 8 }"
            :wrapper-col="{ span: 16 }"
            label="Invoicing information"
          >
            <a-textarea
              v-model="newProject.invoicing_info"
              :auto-size="{ minRows: 3 }"
              data-vv-name="invoicing_info"
              data-vv-as="invoicing info"
            />
          </a-form-item>
        </a-form>
      </a-tab-pane>
    </a-tabs>
  </a-modal>
</template>

<script setup lang="ts">
import { ref, watch, toRef, getCurrentInstance } from "vue";
import { MinimalUserInterface } from "@/modules/users/types";
import { MinimalClientInterface } from "@/modules/clients/types";
import { ProjectInterface, ProjectPayloadInterface } from "@/modules/projects/types";
import { BaseAclInterface } from "@/modules/authentication/types";
import useMixin from "@/useMixin";
import moment from "@/date";
import { Moment } from "moment";

// Props
const props = defineProps({
  users: { type: Array as () => Array<MinimalUserInterface>, default: () => [] },
  clients: { type: Array as () => Array<MinimalClientInterface>, default: () => [] },
  project: { type: Object as () => ProjectInterface, default: undefined },
  acl: { type: Object as () => BaseAclInterface, default: undefined },
  visible: { type: Boolean, default: false },
});

// Emits
const emits = defineEmits(["get-users", "get-clients", "update-project", "close"]);

// Mixins
const { setObject } = useMixin();

// Instances
const instance = getCurrentInstance();
const $validator = instance?.proxy.$validator;

// Data properties
const newProject = ref<ProjectPayloadInterface>({
  id: null,
  name: null,
  alias: null,
  budget: null,
  project_type: null,
  internal_description: null,
  public_description: null,
  client_id: null,
  account_manager_id: null,
  fortnox_id: null,
  invoicing_info: null,
  github_team_sync_enabled: undefined,
  github_team_name: undefined,
});

// Watchers
watch(toRef(props, "visible"), (newProp, oldProp) => {
  if (newProp && !oldProp) {
    // Load users & clients
    emits("get-users");
    emits("get-clients");

    // Set fields to current values
    newProject.value = setObject(newProject.value, props.project);
    newProject.value.client_id = props.project.client ? props.project.client.id : null;
  }
});

// Component methods
const onDateChange = (date: any, attribute: string): void => {
  newProject.value[attribute] = date ? date.format("YYYY-MM-DD") : null;
};

const handleUpdateProject = (): void => {
  $validator?.validate().then((result) => {
    if (result) {
      emits("update-project", newProject.value);
      emits("close");
    }
  });
};

const disabledDateBeforeStart = (current: Moment): boolean => {
  return (
    current &&
    newProject.value.start_date &&
    current < moment(newProject.value.start_date).startOf("day")
  );
};

const disabledDateAfterEnd = (current: Moment): boolean => {
  return (
    current &&
    newProject.value.completion_date &&
    current > moment(newProject.value.completion_date).endOf("day")
  );
};
</script>
