import { defineStore, acceptHMRUpdate } from "pinia";
import { StateInterface } from "@/modules/recurring_jobs/types";
import { RecurringJobPayloadInterface } from "@/modules/recurring_jobs/types";
import {
  apiGetRecurringJobs,
  apiStoreRecurringJob,
  apiUpdateRecurringJob,
  apiDestroyRecurringJob,
} from "@/modules/recurring_jobs/_utils/api";

export const useRecurringJobsStore = defineStore("recurring_jobs", {
  state: (): StateInterface => {
    return {
      recurring_jobs: new Map(),
      recurring_jobs_pagination: undefined,
    };
  },
  actions: {
    getRecurringJobsByPage(params: any): any {
      return new Promise((resolve, reject) => {
        apiGetRecurringJobs(params)
          .then((res: any) => {
            this.setRecurringJobs({ page: params.page, recurring_jobs: res.data.data });
            this.setRecurringJobsPagination(res.data.meta);
            resolve(res.data.data);
          })
          .catch((err: any) => {
            reject(err);
          });
      });
    },

    storeRecurringJob(newRecurringJob: RecurringJobPayloadInterface): any {
      return new Promise((resolve, reject) => {
        apiStoreRecurringJob(newRecurringJob)
          .then((res: any) => resolve(res.data.data))
          .catch((err: any) => {
            reject(err);
          });
      });
    },

    updateRecurringJob(updatedRecurringJob: RecurringJobPayloadInterface): any {
      return new Promise((resolve, reject) => {
        apiUpdateRecurringJob(updatedRecurringJob)
          .then((res: any) => resolve(res.data))
          .catch((err: any) => {
            reject(err);
          });
      });
    },

    clearRecurringJobs(): any {
      return new Promise<void>((resolve) => {
        this.recurring_jobs = new Map();
        resolve();
      });
    },

    destroyRecurringJob(recurringJobId: number): any {
      return new Promise((resolve, reject) => {
        apiDestroyRecurringJob(recurringJobId)
          .then((res: any) => resolve(res.data.data))
          .catch((err: any) => {
            reject(err);
          });
      });
    },

    setRecurringJobs({
      page,
      recurring_jobs,
    }: {
      page: any;
      recurring_jobs: Map<number, any>;
    }): void {
      this.recurring_jobs = this.recurring_jobs.set(page, recurring_jobs);
    },

    setRecurringJobsPagination(meta: any): void {
      this.recurring_jobs_pagination = meta;
    },
  },
});

// HMR
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useRecurringJobsStore, import.meta.hot));
}
