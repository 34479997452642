import axios from "@/network";

export function apiGetTransactions(params: any, headers = {}) {
  const url = "/transactions";
  return axios.get(url, { params, headers });
}

export function apiGetUserTransactions(userId: number, params: any, headers = {}) {
  const url = `users/${userId}/transactions/`;
  return axios.get(url, { params, headers });
}

export function apiStoreTransaction(newTransaction: any) {
  const url = "/transactions";
  const payload = { transaction: newTransaction };
  return axios.post(url, payload);
}

export function apiUpdateTransaction(transactionId: number, updatedTransaction: any) {
  const url = `/transactions/${transactionId}`;
  const payload = { transaction: updatedTransaction };
  return axios.patch(url, payload);
}

export function apiBulkUpdateTransactions(transactionIds: Array<number>, newStatus: string) {
  const url = "/transactions/bulk_update";
  const payload = { ids: transactionIds, new_status: newStatus };
  return axios.post(url, payload);
}

export function apiDestroyTransaction(transactionId: number) {
  const url = `/transactions/${transactionId}`;
  return axios.delete(url);
}

export function apiGetSkpOverview(userId: number) {
  const url = `users/${userId}/skp`;
  return axios.get(url);
}
