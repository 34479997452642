<template>
  <div>
    <div v-if="filterPanelVisible" class="card mb-3">
      <a-form layout="vertical">
        <bookmarked-filters-selection
          :bookmarked-filters="bookmarkedFilters"
          :filter-is-empty="filterIsEmpty"
          bookmark-type="Transactions"
          class="ml-3"
          @selected="(bookmarkFilterUrl) => handleCustomFilterSelected(bookmarkFilterUrl)"
          @create-bookmark-filter="handleCreateBookmarkFilter"
          @update-bookmark-filter="handleUpdateBookmarkFilter"
          @delete-bookmark-filter="handleDeleteBookmarkFilter"
        />
        <a-row>
          <a-col :lg="11" class="ml-3">
            <SkNameSelect
              v-model="newFilter.user_id"
              label="Users"
              :label-col-size="10"
              mode="multiple"
              placeholder="Select users"
              :allow-clear="true"
              :options="usersCompactList"
              @change="applyFilters"
            />

            <a-form-item :label-col="{ span: 10 }" :wrapper-col="{ span: 16 }" label="Status">
              <a-select
                v-model="newFilter.status"
                allow-clear
                placeholder="Select Status"
                @change="applyFilters()"
              >
                <a-select-option key="pending">PENDING</a-select-option>
                <a-select-option key="paid">PAID</a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item
              :label-col="{ span: 10 }"
              :wrapper-col="{ span: 16 }"
              label="Date"
              class="pb-0 mb-0"
            >
              <a-date-picker
                :value="parseDate(newFilter.start_date)"
                :disabled-date="(date) => disabledStartDate(date)"
                placeholder="Start Date"
                class="w-100 mb-2"
                @change="(d) => onDateChange(d, 'start_date')"
              />
              <a-date-picker
                :value="parseDate(newFilter.end_date)"
                :disabled-date="(date) => disabledEndDate(date)"
                placeholder="End Date"
                class="w-100 mb-2"
                @change="(d) => onDateChange(d, 'end_date')"
              />
            </a-form-item>

            <a-form-item
              v-if="forTransactions"
              :label-col="{ span: 10 }"
              :wrapper-col="{ span: 16 }"
              label="Currency"
            >
              <a-select
                v-model="newFilter.currency"
                show-search
                allow-clear
                placeholder="Select User"
                option-filter-prop="children"
                @change="applyFilters()"
              >
                <a-select-option v-for="c in currencies" :key="c">{{ c }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :lg="11" class="ml-3">
            <a-form-item label="Types">
              <a-checkbox-group
                :value="newFilter.types"
                @change="(types) => updateTypeFilter(types)"
              >
                <a-checkbox class="ml-2 mt-2 d-block" value="salary-transaction">
                  Salary Transaction
                </a-checkbox>
                <a-checkbox class="mt-2 d-block" value="receipt-transaction">
                  Receipt Transaction
                </a-checkbox>
                <a-checkbox class="mt-2 d-block" value="vacation-transaction">
                  Vacation Transaction
                </a-checkbox>
                <a-checkbox class="mt-2 d-block" value="plain-transaction">
                  Plain Transaction
                </a-checkbox>
              </a-checkbox-group>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <a-row v-if="forTransactions" class="mt-4 transaction-summary-small">
        <a-col :md="8" :sm="12" class="flex-center flex-column">
          <p>
            <strong>Amount:</strong>
          </p>
          <p>{{ toMoney(aggregatedAmount) }}</p>
        </a-col>
        <a-col :md="8" :sm="12" class="flex-center flex-column">
          <p>
            <strong>Vacation Amount:</strong>
          </p>
          <p>{{ toMoney(vacationalAmount) }}</p>
        </a-col>
        <a-col :md="8" :sm="24" class="flex-center flex-column">
          <p>
            <strong>Results:</strong>
          </p>
          <p>{{ numberOfResults ? numberOfResults : "0" }}</p>
        </a-col>
      </a-row>
      <a-row v-if="!forTransactions" class="d-flex justify-content-end">
        <span>
          <strong>Results:</strong>
          {{ numberOfResults ? numberOfResults : "0" }}
        </span>
      </a-row>
    </div>
    <portal to="transactions-filter-buttons">
      <a-button
        v-if="filterPanelVisible"
        class="mr-2"
        icon="close"
        type="danger"
        @click="() => clearFilters()"
      />
      <a-button
        v-show="filterPanelVisible"
        class="mr-2"
        type="primary"
        icon="up"
        @click="() => toggleFilterPanel()"
      />
      <a-button
        v-if="!filterPanelVisible"
        class="mr-2"
        type="primary"
        icon="filter"
        @click="() => toggleFilterPanel()"
      />
    </portal>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch, getCurrentInstance } from "vue";
import BookmarkedFiltersSelection from "../../bookmarked_filters/_components/BookmarkedFiltersSelection.vue";
import { BookmarkedFilterInterface } from "@/modules/bookmarked_filters/types";
import { ProfileInterface } from "../../authentication/types";
import { MinimalUserInterface } from "@/modules/users/types";
import { TransactionsFilterInterface } from "../types";
import { getUsersCompactList } from "@/api";
import { CURRENCIES } from "@/utils";
import useMixin from "@/useMixin";
import { Moment } from "moment";
import moment from "@/date";
import {
  apiGetUserBookmarkedFilters,
  apiStoreUserBookmarkedFilter,
  apiUpdateUserBookmarkedFilter,
  apiDestroyUserBookmarkedFilter,
} from "@/modules/bookmarked_filters/_utils/api";
import useFilters from "@/useFilters";

// Props
const props = defineProps({
  filters: { type: Object as () => TransactionsFilterInterface, default: undefined },
  aggregatedAmount: { type: Object as () => number, default: undefined },
  vacationalAmount: { type: Object as () => number, default: undefined },
  profile: { type: Object as () => ProfileInterface, default: undefined },
  forTransactions: { type: Boolean, default: false },
  numberOfResults: { type: Number, default: null },
});

// Emits
const emits = defineEmits(["apply-filters"]);

// Mixins
const { qsDecode, setObject, toMoney } = useMixin();

// Filters
const { parseDate } = useFilters();

// Instances
const instance = getCurrentInstance();
const $message = instance?.proxy.$message;

// Data properties
const bookmarkedFilters = ref<Array<BookmarkedFilterInterface>>([]);
const usersCompactList = ref<Array<MinimalUserInterface>>([]);
const currencies = ref<Array<string>>(CURRENCIES);
const filterPanelVisible = ref<boolean>(false);
const newFilter = ref<TransactionsFilterInterface>({
  user_id: [],
  status: undefined,
  types: [],
  start_date: undefined,
  end_date: undefined,
  currency: "SEK",
});

// Computed properties
const filterIsEmpty = computed((): boolean => {
  return Boolean(
    newFilter.value.types &&
      newFilter.value.user_id === undefined &&
      newFilter.value.types.length === 0 &&
      newFilter.value.status === undefined &&
      newFilter.value.start_date === "" &&
      newFilter.value.end_date === ""
  );
});

// Watchers
watch(filterPanelVisible, (newProp, oldProp) => {
  if (newProp && !oldProp) {
    // Load users
    handleGetUsersCompactList();
    handleGetBookmarkedFilters();
    setFilter(props.filters);
  }
});

// Component methods
const toggleFilterPanel = (): void => {
  filterPanelVisible.value = !filterPanelVisible.value;
};

const handleGetUsersCompactList = (): void => {
  getUsersCompactList().then((users) => {
    usersCompactList.value = users;
  });
};

const handleCustomFilterSelected = (bookmarkFilterUrl: string): void => {
  handleClearTransactionsFilter();
  if (bookmarkFilterUrl) {
    emits("apply-filters", qsDecode(bookmarkFilterUrl).filters);
  } else {
    applyFilters();
  }
};

const updateTypeFilter = (typesFilters: any): void => {
  newFilter.value.types = typesFilters;
  applyFilters();
};

const applyFilters = (): void => {
  emits("apply-filters", filterIsEmpty.value ? {} : newFilter.value);
};

const clearFilters = (): void => {
  handleClearTransactionsFilter();
  emits("apply-filters", {});
};

const disabledStartDate = (startValue: Moment): boolean => {
  const endValue = newFilter.value.end_date
    ? moment(newFilter.value.end_date).endOf("day")
    : moment("");
  if (!startValue || !endValue) {
    return false;
  }
  return startValue.valueOf() >= endValue.valueOf();
};

const disabledEndDate = (endValue: Moment): boolean => {
  const startValue = newFilter.value.start_date ? moment(newFilter.value.start_date) : moment("");
  if (!endValue || !startValue) {
    return false;
  }
  return startValue.valueOf() >= endValue.valueOf();
};

const onDateChange = (date: any, type: string): void => {
  if (type === "start_date") {
    newFilter.value.start_date = date == undefined ? "" : date.format("YYYY-MM-DD");
  } else if (type === "end_date") {
    newFilter.value.end_date = date == undefined ? "" : date.format("YYYY-MM-DD");
  }
  applyFilters();
};

const handleClearTransactionsFilter = (): void => {
  let currency = newFilter.value.currency ? newFilter.value.currency : "SEK";
  newFilter.value = {
    user_id: undefined,
    status: undefined,
    types: [],
    start_date: "",
    end_date: "",
    currency: currency,
  };
};

const setFilter = (filter: any): void => {
  if (filter === undefined) {
    return;
  }
  newFilter.value = setObject(newFilter.value, filter);
};

const handleGetBookmarkedFilters = (): void => {
  apiGetUserBookmarkedFilters(props.profile.id).then(
    ({ data }: any) => (bookmarkedFilters.value = data.data)
  );
};

const handleCreateBookmarkFilter = (bookmarkFilter: any): void => {
  apiStoreUserBookmarkedFilter(props.profile.id, bookmarkFilter)
    .then(({ data }: any) => bookmarkedFilters.value.push(data.data))
    .then(() => $message?.success("New filter created successfully!", 3))
    .catch(() => $message?.error("Couldn't create new filter!", 3));
};

const handleUpdateBookmarkFilter = (bookmarkFilterId: number, bookmarkFilter: any): void => {
  apiUpdateUserBookmarkedFilter(props.profile.id, bookmarkFilterId, bookmarkFilter)
    .then(
      ({ data }: any) =>
        (bookmarkedFilters.value = bookmarkedFilters.value.map((f: any) =>
          f.id === data.data.id ? data.data : f
        ))
    )
    .then(() => $message?.success("Filter updated successfully!", 3))
    .catch(() => $message?.error("Couldn't update filter!", 3));
};

const handleDeleteBookmarkFilter = (bookmarkFilterId: number) => {
  apiDestroyUserBookmarkedFilter(props.profile.id, bookmarkFilterId)
    .then(
      () =>
        (bookmarkedFilters.value = bookmarkedFilters.value.filter(
          (f: any) => f.id !== bookmarkFilterId
        ))
    )
    .then(() => $message?.success("Filter removed successfully!", 3))
    .catch(() => $message?.error("Couldn't remove filter!", 3));
};
</script>
