import { ProfileDescriptionStatus } from "../users/types";

export enum EmploymentTypes {
  FIXED = "fixed",
  VARIABLE = "variable",
  HOURLY = "hourly",
  SUBCONTRACTOR = "subcontractor",
}
export interface BaseAclInterface {
  isAdmin: boolean;
  isFinancial: boolean;
  isDefault: boolean;
  isInternal: boolean;
  isCr: boolean;
  isProjectManager: boolean;
  isHr: boolean;
  isOwner?: boolean;
}

export interface StateInterface {
  profile: ProfileInterface | undefined;
  meta: any;
}

export interface ProfileInterface {
  id: number;
  name: string;
  email: string;
  birth_date: string | null;
  start_date: string;
  end_date: string | null;
  phone_number: string | null;
  address: string;
  gravatar: string;
  on_vacation: boolean;
  roles: Array<string>;
  team: any;
  tshirt_size: string | null;

  profile_description?: string;
  profile_description_status: ProfileDescriptionStatus;
  onboarding_completed: boolean;
  onboarding_quiz_completed: boolean;

  github: string | null;
  bitbucket: string | null;
  skype: string | null;
  private_email: string | null;

  report_locking_date: string;
  employment_type: EmploymentTypes;
  salary: any;
  fixed_salary_start_date: string | null;
  vacation_days: number;
  vacation_days_prepaid: number;
  vacation_days_saved: number;
  vacation_days_yearly: number;
  vacation_percent: any;
  payroll_tax_percent: any;
  income_tax_percent: any;
  skp_currency: string;
  fortnox_id: any;
  fortnox_cost_center_id: any;
  bank: string;
  salary_account_number: string;
  expected_workload: number;

  cv: any;

  courses: Array<any>;
  characteristics: Array<any>;
  educations: Array<any>;
  merits: Array<any>;
  work_experiences: Array<any>;
  skills: Array<any>;
  interests: Array<any>;
  knowledge_domains: Array<any>;
}
