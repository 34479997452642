<template>
  <div class="cv-container">
    <table class="table">
      <tbody>
        <tr>
          <td>
            <article :class="`flow along-column theme-${theme} skill-theme-${skillTheme}`">
              <section id="heading">
                <div id="heading-background">
                  <div
                    v-if="branding.includes('skry background')"
                    id="skry-background"
                    class="flow from-right bg-logo-group"
                    :class="{ 'temp-large-bg': useLargePattern }"
                  >
                    <div
                      v-for="{ id, variants } in [
                        { id: 0, variants: ['skry', 'skry', 'skr'] },
                        { id: 1, variants: ['skry', 'skry', 'skry'] },
                        { id: 2, variants: ['skry', 'skry', 'skry'] },
                        { id: 3, variants: ['skry', 'skry', 'skr'] },
                        { id: 4, variants: ['sk'] },
                      ]"
                      :key="id"
                      class="flow along-column bg-logo-container"
                    >
                      <div v-for="(variant, i) in variants" :key="i" class="stacked-logo">
                        <StackedLogo :letters="variant" :theme="theme" />
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="rebranding && branding.includes('rebrand big')"
                    id="rebrand-big"
                    class="flow from-right rebranding"
                  >
                    <div>
                      <img :src="rebranding" />
                    </div>
                  </div>
                </div>
                <div v-if="showTriangle" id="heading-triangle" />
                <div v-if="cv" id="heading-content" class="content">
                  <div class="flow space-between">
                    <div>
                      <img
                        v-if="rebranding && branding.includes('rebrand left')"
                        class="logo"
                        :src="rebranding"
                      />
                      <img v-else-if="branding.includes('skry')" class="logo" :src="logo" />
                    </div>
                    <div>
                      <img
                        v-if="rebranding && branding.includes('rebrand right')"
                        class="logo"
                        :src="rebranding"
                      />
                    </div>
                  </div>
                  <div class="flow gap-6">
                    <div
                      v-if="showGravatar && cv && cv.gravatar && cv.gravatar.length > 1"
                      class="stack center-items"
                    >
                      <a-avatar
                        :src="cv.gravatar"
                        :size="128"
                        :shape="showRoundAvatar ? 'circle' : 'square'"
                      />
                    </div>
                    <div class="flow along-column gap-0-35">
                      <h2 class="name bold">{{ cv.name }}</h2>
                      <h3 class="title bold">
                        <span class="separating-dot">{{ cv.first_title }}</span>
                        <span v-if="!!cv.second_title" class="separating-dot">{{
                          cv.second_title
                        }}</span>
                      </h3>
                      <span v-if="cv.github && showGithub" class="hint">
                        <a
                          :href="`https://www.github.com/${cv.github}`"
                          target="_blank"
                          class="flow center-items gap-1"
                        >
                          <a-icon type="github" />
                          <span>{{ cv.github }}</span>
                        </a>
                      </span>
                      <span class="hint">{{ cv.location }}</span>
                    </div>
                  </div>
                  <div class="flow along-column gap-6">
                    <div>{{ cv.background }}</div>
                    <div class="flow wrap">
                      <div
                        v-for="{ id, title } in cv?.characteristics"
                        :key="id"
                        class="separating-dot bold"
                      >
                        {{ title }}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section
                v-if="showEducations && cv && !!cv?.educations && cv.educations.length"
                id="education"
                class="content"
              >
                <h2 class="section-title">Education</h2>
                <div class="flow along-column pad-12">
                  <div
                    v-for="education in cv.educations"
                    :key="education.id"
                    class="flow along-column gap-4"
                  >
                    <div>
                      <h3 class="bold slim">
                        {{ education.title }} at {{ education.university_name }}
                      </h3>
                      <span class="education-date hint">{{
                        formatProjectDate(education.start_date, education.end_date)
                      }}</span>
                    </div>
                    <span
                      v-if="
                        showEducationsDescription && cv && !!cv?.educations && cv.educations.length
                      "
                      class="education-description"
                      >{{ education.description }}</span
                    >
                    <div class="flow wrap gap-1-5">
                      <!-- TODO: Optimize :class when backend support is added -->
                      <div
                        v-for="skill in skillsToCvSkills(education.skills)"
                        :key="skill.id"
                        class="skill-card"
                        :class="{
                          bold: skill.highlight && highlight.includes('bold'),
                          'no-color': !skill.highlight && highlight.includes('color'),
                          raise: skill.highlight && highlight.includes('raise'),
                        }"
                      >
                        {{ skill.title }}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section v-if="cv" id="skills" class="content">
                <h2 class="section-title">Skills</h2>
                <div class="two-columns gap-6">
                  <div
                    v-for="{ id, title, skills } in cv?.skill_categories"
                    :key="id"
                    class="flow along-column gap-1-5"
                  >
                    <h3 class="bold">{{ title }}</h3>
                    <div class="flow wrap gap-1-5">
                      <!-- TODO: Optimize :class when backend support is added -->
                      <div
                        v-for="skill in skillsToCvSkills(skills)"
                        :key="skill.id"
                        class="skill-card"
                        :class="{
                          bold: skill.highlight && highlight.includes('bold'),
                          'no-color': !skill.highlight && highlight.includes('color'),
                          raise: skill.highlight && highlight.includes('raise'),
                        }"
                      >
                        {{ skill.title }}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section
                v-if="cv?.knowledge_domains.length && showAreasOfExpertise"
                id="knowledge-domains"
                class="content"
              >
                <h2 class="section-title">Areas of Expertise</h2>
                <div class="section-container gap-6">
                  <div v-for="domain in cv?.knowledge_domains" :key="domain.id" class="stack gap-4">
                    <div v-if="!!domain.level" class="flow space-between flex-start">
                      <h3 class="bold slim self-center">{{ domain.title }}</h3>
                      <span class="title-card">{{ domain.level }}</span>
                    </div>
                    <span>{{ domain.description }}</span>
                  </div>
                </div>
              </section>
              <section v-if="cv" id="projects" class="content">
                <h2 class="section-title">Selected Projects</h2>
                <div class="flow along-column pad-12">
                  <div
                    v-for="(exp, i) in experience"
                    :key="i"
                    class="flow along-column gap-4 break-avoiding"
                  >
                    <div>
                      <div class="flow space-between flex-start">
                        <h3 class="bold slim self-center">{{ exp.company_name }}</h3>
                        <span class="title-card">{{ exp.title }}</span>
                      </div>
                      <span v-if="!!showDates" class="hint separating-dot">{{
                        formatProjectDate(exp.start_date, exp.end_date)
                      }}</span>
                      <span v-if="!!showDuration" class="hint separating-dot">{{
                        formatProjectDuration(exp.start_date, exp.end_date)
                      }}</span>
                    </div>
                    <span>{{ exp.description }}</span>
                    <span v-if="!!exp.url_link">
                      <a :href="exp.url_link" target="_blank">{{ exp.url_link }}</a>
                    </span>
                    <div v-if="showSkills" class="flow wrap gap-1-5">
                      <div
                        v-for="skill in exp.skills"
                        :key="skill.id"
                        class="skill-card"
                        :class="{
                          bold: skill.highlight && highlight.includes('bold'),
                          'no-color': !skill.highlight && highlight.includes('color'),
                          raise: skill.highlight && highlight.includes('raise'),
                        }"
                      >
                        {{ skill.title }}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section v-if="cv?.courses.length" id="courses" class="content">
                <h2 class="section-title">Certifications / Courses</h2>
                <div class="section-container gap-6">
                  <div v-for="course in cv?.courses" :key="course.id" class="stack gap-4">
                    <h3 class="bold slim">{{ course.title }}</h3>
                    <!-- Course duration is here in design, but we do not currently store course duration information -->
                    <span>{{ course.description }}</span>
                  </div>
                </div>
              </section>
              <div v-if="cv" class="page-footer content">
                <div>
                  <span>{{ cv.contact_email }}</span>
                </div>
                <div>
                  <span>{{ new Date(Date.now()).toDateString() }}</span>
                </div>
              </div>
            </article>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td>
            <div class="page-footer-space"></div>
          </td>
        </tr>
      </tfoot>
    </table>
    <div id="cv-controls">
      <div class="flow along-column gap-1">
        <h1>Controls for Skry V2</h1>
        <a-select v-model="theme" size="small" class="mr-2 dropdown-select">
          <a-select-option v-for="color in themes" :key="color" :value="color">
            {{ color.charAt(0).toUpperCase() + color.slice(1) }} Header
          </a-select-option>
        </a-select>

        <a-select v-model="skillTheme" size="small" class="mr-2 dropdown-select">
          <a-select-option v-for="color in themes" :key="color" :value="color">
            {{ color.charAt(0).toUpperCase() + color.slice(1) }} Skills
          </a-select-option>
        </a-select>

        <a-select
          v-model="highlight"
          mode="tags"
          placeholder="Skill highlight"
          size="small"
          class="mr-2"
        >
          <a-select-option value="" disabled>Skill highlight</a-select-option>
          <a-select-option v-for="h in highlighting" :key="h" :value="h">
            {{ h.charAt(0).toUpperCase() + h.slice(1) }}
          </a-select-option>
        </a-select>

        <a-select v-model="branding" mode="tags" placeholder="Branding" size="small" class="mr-2">
          <a-select-option value="" disabled>Branding</a-select-option>
          <a-select-option v-for="b in brandingOptions" :key="b" :value="b">
            {{ b.charAt(0).toUpperCase() + b.slice(1) }}
          </a-select-option>
        </a-select>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from "vue";
import { cvUserDataInterface, SkillCvInterface } from "@/modules/cv/types";
import StackedLogo from "@/modules/cv/_components/StackedLogo.vue";
import { SkillInterface } from "@/modules/consultant_profiles/types";
import { SkillInterface as SkillInterface2 } from "@/modules/skills/types";
import { whiteLogoDataURL } from "@/modules/cv/_utils/logoData";

// Types
type Experience = {
  title: string;
  company_name: string;
  description: string;
  start_date: string;
  end_date: string;
  skills: Array<SkillCvInterface>;
  url_link?: string;
};

// Constants
const themes = ["turquoise", "purple", "red", "yellow", "green", "blue", "black"];
const highlighting = ["bold", "color", "raise"];
const brandingOptions = ["skry", "skry background", "rebrand left", "rebrand right", "rebrand big"];

const props = defineProps({
  cv: { type: Object as () => cvUserDataInterface, default: undefined },
  showDates: { type: Boolean, default: undefined },
  showDuration: { type: Boolean, default: undefined },
  showAreasOfExpertise: { type: Boolean, default: undefined },
  showSkills: { type: Boolean, default: undefined },
  showGithub: { type: Boolean, default: undefined },
  showGravatar: { type: Boolean, default: undefined },
  showEducations: { type: Boolean, default: undefined },
  showEducationsDescription: { type: Boolean, default: undefined },
  cvBackground: { type: Array as () => string[], default: () => [] },
  cvMotivation: { type: Array as () => string[] | null, default: () => [] },
  cvLogo: { type: String as () => string | undefined, default: () => undefined },
  formatProjectDate: {
    type: Function,
    default: (start: any, end: any) => String,
  },
  formatProjectDuration: {
    type: Function,
    default: (start: string, end: string) => String,
  },
});
const logo = computed<string>(() => whiteLogoDataURL());
const useLargePattern = computed<boolean>(() => {
  switch (theme.value) {
    case "black":
      return false;
    case "turquoise":
    case "purple":
    case "red":
    case "yellow":
    case "green":
    case "blue":
    default:
      return true;
  }
});
const showTriangle = computed<boolean>(() => {
  switch (theme.value) {
    case "black":
      return false;
    case "turquoise":
    case "purple":
    case "red":
    case "yellow":
    case "green":
    case "blue":
    default:
      return true;
  }
});
const showRoundAvatar = computed<boolean>(() => {
  switch (theme.value) {
    case "black":
      return false;
    case "turquoise":
    case "purple":
    case "red":
    case "yellow":
    case "green":
    case "blue":
    default:
      return true;
  }
});

const theme = ref<string>(themes[0]);
const skillTheme = ref<string>(themes[0]);
const highlight = ref<string[]>([]);
const branding = ref<string[]>([brandingOptions[0], brandingOptions[1]]);

const experience = computed<Experience[]>(() => {
  const projectExperience: Experience[] = props.cv.roles.map((role) => ({
    title: role.custom_role_title ?? role.title,
    company_name:
      role.custom_client_alias ||
      (role.client && !role.hide_client_name ? role.client : role.client_alias),
    description: role.description,
    start_date: role.start_date,
    end_date: role.end_date,
    skills: skillsToCvSkills(role.skills),
  }));

  const workExperience: Experience[] = props.cv.work_experiences.map((experience) => ({
    title: experience.custom_role_title ?? experience.title,
    company_name: experience.company_name,
    description: experience.description,
    start_date: experience.start_date,
    end_date: experience.end_date,
    skills: skillsToCvSkills(experience.skills),
    url_link: experience.url_link,
  }));

  return projectExperience.concat(workExperience);
});
const rebranding = computed<string | undefined>(() => props.cvLogo);

// We need to switch body background color for correct color during print
let previousBackgroundColor = "#f4f4f4";
onMounted(() => {
  previousBackgroundColor = document.body.style.background;
  // Background color should match scss variable $background
  document.body.style.background = "#ffffff";
});
// Restore previous background color
onUnmounted(() => {
  document.body.style.background = previousBackgroundColor;
});

// TODO Optimize once backend supports returning SkillsCvInterface for work/project experience
const skillsToCvSkills = (skills: (SkillInterface | SkillInterface2)[]): SkillCvInterface[] =>
  skills.map((s) => ({
    ...s,
    skill_id: s.id,
    highlight: false,
    description: s.description ?? "",
    ...props.cv.skills.find((cvSkill) => cvSkill.skill_id === s.id),
  }));
</script>

<style scoped lang="scss">
$background: #ffffff;
$footer-background: #9da1a2;
$footer-text: #ffffff;
$text-dark: #000000;
$text-dark-faded: #00000099;
$text-light: #ffffff;
$text-light-faded: #ffffff99;
$text-gray: #7d7d7d;
$default-border: 1px solid #ebebeb;
$footer-height: 10mm;
$cv-width: 210mm;

$separating-dot-spacing: 6mm;
$triangle-size: 70mm;

$theme-triangle: #9da1a2;
$theme-title-border: #1a1a1a;
$theme-title-card-border: #d6d6d6;

$skill-card-shadow: #cccccc;

$black-heading-background: #1a1a1a;
$black-skill-card: #eeeeee;

$gradient-angle: 76.99deg;
$gradient-start: 0%;
$gradient-end: 100%;

$turquoise-gradient-start: hsl(168, 84%, 80%);
$turquoise-gradient-end: hsl(157, 94%, 93%);
$turquoise-skill-card: hsl(164, 62%, 92%);
$turquoise-heading-background: linear-gradient(
  $gradient-angle,
  $turquoise-gradient-start $gradient-start,
  $turquoise-gradient-end $gradient-end
);

$purple-gradient-start: hsl(257, 84%, 80%);
$purple-gradient-end: hsl(260, 84%, 83%);
$purple-skill-card: hsl(293, 53%, 93%);
$purple-heading-background: linear-gradient(
  $gradient-angle,
  $purple-gradient-start $gradient-start,
  $purple-gradient-end $gradient-end
);

$red-gradient-start: hsl(0, 84%, 80%);
$red-gradient-end: hsl(1, 87%, 85%);
$red-skill-card: hsl(5, 63%, 93%);
$red-heading-background: linear-gradient(
  $gradient-angle,
  $red-gradient-start $gradient-start,
  $red-gradient-end $gradient-end
);

$yellow-gradient-start: hsl(51, 82%, 80%);
$yellow-gradient-end: hsl(52, 86%, 92%);
$yellow-skill-card: hsl(51, 64%, 91%);
$yellow-heading-background: linear-gradient(
  $gradient-angle,
  $yellow-gradient-start $gradient-start,
  $yellow-gradient-end $gradient-end
);

$green-gradient-start: hsl(110, 77%, 81%);
$green-gradient-end: hsl(127, 92%, 95%);
$green-skill-card: hsl(127, 78%, 91%);
$green-heading-background: linear-gradient(
  $gradient-angle,
  $green-gradient-start $gradient-start,
  $green-gradient-end $gradient-end
);

$blue-gradient-start: hsl(198, 77%, 80%);
$blue-gradient-end: hsl(182, 83%, 93%);
$blue-skill-card: hsl(191, 76%, 91%);
$blue-heading-background: linear-gradient(
  $gradient-angle,
  $blue-gradient-start $gradient-start,
  $blue-gradient-end $gradient-end
);

@page {
  margin: 0;
  size: a4 portrait;
}

@mixin shadow-border {
  @media print {
    border: $default-border;
    border-bottom-width: 1.5px;
  }
}

h1,
h2,
h3,
h4,
h5 {
  break-after: avoid;
}

.table {
  background-color: $background;

  @media print {
    position: absolute;
  }

  & tr,
  & td {
    padding: 0;
  }
}

article {
  position: relative;
  background: $background;
  color: $text-dark;
  font-size: 13px;
  line-height: 1.3;
  font-weight: 350;
  letter-spacing: 0.25px;
  overflow: hidden;
  font-family: "ABCWhyte", serif;
  font-feature-settings: normal;
  font-variant: normal;
  display: grid;
  width: $cv-width;

  @media not print {
    margin: auto;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  }
}

.cv-container {
  display: flex;
  gap: 4mm;
}

/*******************/
/*** Cv Controls ***/
/*******************/

#cv-controls {
  width: 60mm;
  top: 56mm;
  position: sticky;
  align-self: flex-start;
  @media print {
    display: none;
  }

  & > div {
    z-index: 10;
    display: grid;
    user-select: none;
    padding: 1.5em;
    border-radius: 1em;
    border: 1px solid #0006;
    width: 60mm;
    background: #f6f6f6;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  }
}

/*************/
/*** Utils ***/
/*************/

.stack {
  display: grid;

  &.center-items {
    align-items: center;
  }
}

.self-center {
  align-self: center;
}

.slim {
  padding: 0;
  margin: 0;
}

.flow {
  display: flex;

  &.from-right {
    flex-direction: row-reverse;
  }

  &.along-column {
    flex-direction: column;

    &.pad-12 > *:not(:first-child) {
      padding-top: 12mm;
    }
  }

  &.wrap {
    flex-wrap: wrap;
  }

  &.flex-start {
    align-items: flex-start;
  }

  &.center-items {
    align-items: center;
  }
}

.two-columns {
  column-count: 2;
  columns: 2;

  & > * {
    break-inside: avoid;
  }

  .gap-0-35 {
    gap: 0.35mm;

    & > *:not(:last-child) {
      margin-bottom: 0.35mm;
    }
  }

  &.gap-1 {
    gap: 1mm;

    & > *:not(:last-child) {
      margin-bottom: 1mm;
    }
  }

  &.gap-1-5 {
    gap: 1.5mm;

    & > *:not(:last-child) {
      margin-bottom: 1.5mm;
    }
  }

  &.gap-4 {
    gap: 4mm;

    & > *:not(:last-child) {
      margin-bottom: 4mm;
    }
  }

  &.gap-6 {
    gap: 6mm;

    & > *:not(:last-child) {
      margin-bottom: 6mm;
    }
  }

  &.gap-12 {
    gap: 12mm;

    & > *:not(:last-child) {
      margin-bottom: 12mm;
    }
  }
}
.space-between {
  justify-content: space-between;
}

.absolute {
  position: absolute;
}

.gap-0-35 {
  gap: 0.35mm;
}

.gap-1 {
  gap: 1mm;
}

.gap-1-5 {
  gap: 1.5mm;
}

.gap-4 {
  gap: 4mm;
}

.gap-6 {
  gap: 6mm;
}

.gap-12 {
  gap: 12mm;
}

.bold {
  font-weight: bold;
}

.content {
  padding: 4mm 18mm;
}

h2.section-title {
  text-transform: uppercase;
  margin: 7mm auto;
  font-size: 1.1em;
  font-weight: 600;
  padding: 0 0 1mm 2mm;
  border-left: 0.75mm solid $theme-title-border;
  border-bottom: 0.75mm solid $theme-title-border;
  transform: translateX(-3mm);
}

section {
  page-break-inside: avoid;

  a {
    color: $text-dark;
  }
}

.section-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  .education-title {
    font-weight: bold;
  }
}

span.title-card {
  display: inline-block;
  border-radius: 1mm;
  white-space: nowrap;
  padding: 1.5mm 2.5mm 1mm 2.5mm;
  border: 0.6mm solid $theme-title-card-border;
}

.break-avoiding {
  @media print {
    break-inside: avoid-page;
  }
}

/***************/
/*** Heading ***/
/***************/

#heading {
  display: grid;
  grid-template-areas: "heading-grid-area";
  margin-bottom: 6mm;

  h1,
  h2,
  h3,
  h4,
  h5 {
    break-after: avoid;
  }

  .hint,
  .hint a {
    color: $text-dark-faded;
    text-decoration: $text-dark-faded;
  }
}

.theme-turquoise,
.theme-purple,
.theme-red,
.theme-yellow,
.theme-green,
.theme-blue {
  // Override for light themes
  #heading-content {
    grid-template-rows: 11mm 36mm auto auto;
  }
}

.theme-black {
  // Override for dark themes
  #heading {
    color: $text-light;

    h1,
    h2,
    h3,
    h4,
    h5 {
      color: $text-light;
    }
    .hint,
    .hint a {
      color: $text-light-faded;
      text-decoration: $text-light-faded;
    }
  }
  #heading-content {
    grid-template-rows: 7.5mm 36mm auto auto;
  }
}

.theme-turquoise #heading-background {
  background: $turquoise-heading-background;
}

.theme-purple #heading-background {
  background: $purple-heading-background;
}

.theme-red #heading-background {
  background: $red-heading-background;
}

.theme-yellow #heading-background {
  background: $yellow-heading-background;
}

.theme-green #heading-background {
  background: $green-heading-background;
}

.theme-blue #heading-background {
  background: $blue-heading-background;
}

.theme-black #heading-background {
  background: $black-heading-background;
}

#heading-background {
  background: $turquoise-heading-background;
  grid-area: heading-grid-area;
  z-index: 0;
  display: grid;
  grid-template-areas: "branding-placement";

  #skry-background {
    grid-area: branding-placement;
  }

  #rebrand-big {
    grid-area: branding-placement;
    z-index: 1;
  }

  & .bg-logo-group {
    &:not(.temp-large-bg) {
      transform: translateX(52.5mm) translateY(-33.5mm) scale(0.568);
    }
    & .bg-logo-container {
      $gap: 0.18mm;
      $overlap: 33%;
      margin-left: 2mm;
      transform: translateX(-1mm) translateY(-4.5mm);
      gap: calc(3 * $gap);
      & .stacked-logo svg {
        margin: $gap 0;
        width: calc(100% + $overlap);
        height: 100%;
      }
      & .stacked-logo {
        transform: translateX(-$overlap);
      }
      &:nth-child(2) .stacked-logo {
        transform: translateY(-63.66%) translateX(-$overlap);
      }
      &:nth-child(3) > .stacked-logo {
        transform: translateY(-26.33%) translateX(-$overlap);
      }
      &:nth-child(4) > .stacked-logo {
        transform: translateY(11.33%) translateX(-$overlap);
      }
      &:nth-child(5) > .stacked-logo {
        transform: translateY(48.33%) translateX(-$overlap);
      }
    }
  }
}

#heading-triangle {
  background: $theme-triangle;
  clip-path: polygon(0 0, $triangle-size 0, 0 $triangle-size);
  grid-area: heading-grid-area;
  z-index: 1;
}

#heading-content {
  grid-area: heading-grid-area;
  z-index: 2;
  display: grid;
  gap: 5mm;
  font-size: 1.04em;
  grid-template-rows: 7.5mm 36mm auto auto;
  min-height: 104mm;
}

img.logo {
  height: 10mm;
}

h2.name {
  font-size: 10mm;
  margin: 0;
}

h3.title {
  font-size: 4mm;
  margin: 1mm 0;
}

.separating-dot {
  &:not(:last-child)::after {
    content: "\2219"; // Small dot
    display: inline-block;
    width: $separating-dot-spacing;
    text-align: center;
  }

  /* This will create some spacing to properly center the dots */
  &:not(:first-child)::before {
    content: "";
    display: inline-block;
  }
}

.rebranding {
  img {
    max-height: 25mm;
    max-width: 60mm;
    margin: 20mm 10mm;
    border-radius: 1.5mm;
  }
}

/**************/
/*** Skills ***/
/**************/

.skill-theme-turquoise .skill-card {
  background: $turquoise-skill-card;
}

.skill-theme-purple .skill-card {
  background: $purple-skill-card;
}

.skill-theme-red .skill-card {
  background: $red-skill-card;
}

.skill-theme-yellow .skill-card {
  background: $yellow-skill-card;
}

.skill-theme-green .skill-card {
  background: $green-skill-card;
}

.skill-theme-blue .skill-card {
  background: $blue-skill-card;
}

.skill-theme-black .skill-card,
.skill-card.no-color {
  background: $black-skill-card;
}

.skill-card.raise {
  transform: translateY(-0.5mm);
  box-shadow: 0 0.75mm 0 0 $skill-card-shadow;
}

.skill-card {
  border-radius: 10mm;
  padding: 3mm 4mm 2.25mm 4mm;
  text-align: center;
  vertical-align: center;
  background: $turquoise-skill-card;
}

/**************/
/*** Footer ***/
/**************/

.page-footer,
.page-footer-space {
  height: $footer-height;
}

.page-footer {
  background: $footer-background;
  color: $footer-text;
  position: fixed;
  bottom: -1px;
  width: $cv-width;
  vertical-align: center;
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  justify-content: space-between;

  @media not print {
    position: relative;
  }

  p {
    font-size: 1.25em;
    margin-bottom: 0.25em;
  }

  div {
    display: flex;
    height: 100%;
    span {
      margin: auto;
    }
  }
}
</style>
