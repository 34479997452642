<template>
  <a-form data-vv-scope="general">
    <h3>General Info</h3>

    <a-row type="flex" justify="space-between">
      <a-col :span="11">
        <a-form-item
          :colon="false"
          :validate-status="$validator.errors.first('general.first_title') ? 'error' : ''"
          :help="$validator.errors.first('general.first_title')"
          label="First title"
        >
          <a-input
            v-model="firstTitle"
            v-validate="'required'"
            data-vv-name="first_title"
            data-vv-as="first title"
            placeholder="First title"
            @change="(event) => $emit('cv-change', 'first_title', event.target.value)"
          />
        </a-form-item>
      </a-col>

      <a-col :span="11">
        <a-form-item :colon="false" label="Second title">
          <a-input
            v-model="secondTitle"
            placeholder="Second title (optional)"
            @change="(event) => $emit('cv-change', 'second_title', event.target.value)"
          />
        </a-form-item>
      </a-col>
    </a-row>

    <a-form-item
      :colon="false"
      :validate-status="$validator.errors.first('general.location') ? 'error' : ''"
      :help="$validator.errors.first('general.location')"
      label="Location"
    >
      <a-input
        v-model="location"
        placeholder="Enter location, e.g., 'Stockholm, Sweden'"
        @change="(event) => $emit('cv-change', 'location', event.target.value)"
      />
    </a-form-item>

    <a-row>
      <a-form-item
        :colon="false"
        :validate-status="$validator.errors.first('general.background') ? 'error' : ''"
        :help="$validator.errors.first('general.background')"
        label="Background"
        class="mb-0"
      >
        <a-textarea
          v-model="background"
          v-validate="'required'"
          :auto-size="{ minRows: 3 }"
          data-vv-name="background"
          placeholder="Background"
          @change="(event) => $emit('cv-change', 'background', event.target.value)"
        />
      </a-form-item>
    </a-row>

    <a-row type="flex" justify="end" align="middle" class="mb-4">
      <a @click="setBackground(cvData.user.profile_description)">Use profile description</a>
    </a-row>

    <a-row>
      <a-form-item
        :colon="false"
        :validate-status="$validator.errors.first('general.motivation') ? 'error' : ''"
        :help="$validator.errors.first('general.motivation')"
        label="Motivation"
      >
        <a-textarea
          v-model="motivation"
          :auto-size="{ minRows: 2 }"
          data-vv-name="motivation"
          placeholder="Motivation"
          @change="(event) => $emit('cv-change', 'motivation', event.target.value)"
        />
      </a-form-item>
    </a-row>

    <a-row type="flex" justify="space-between">
      <a-col>
        <h3>Characteristics</h3>
      </a-col>
      <a-col>
        <a @click="setCharacteristics([])">none</a>

        <!-- #TODO implement the user characteristics later on -->
        <!-- <a-divider type="vertical" /> -->
        <!-- <a @click="setCharacteristics(cvData.user.characteristics.map(c => c.id))">users</a> -->
      </a-col>
    </a-row>

    <a-row v-if="characteristics" type="flex" class="mb-4">
      <a-tag
        v-for="characteristic in cvData.characteristics"
        :key="characteristic.id"
        :color="selected(characteristic) ? 'blue' : ''"
        class="text-center mb-2 mr-2 hoverable"
        @click="() => handleCharacteristicClick(characteristic)"
      >
        {{ characteristic.title }}
      </a-tag>
    </a-row>

    <a-row>
      <a-col>
        <h3>Contact Info</h3>
      </a-col>
    </a-row>

    <a-row type="flex" justify="space-between">
      <a-col :span="11">
        <a-form-item
          :colon="false"
          :validate-status="$validator.errors.first('general.contact_email') ? 'error' : ''"
          :help="$validator.errors.first('general.contact_email')"
          label="Email"
        >
          <a-input
            v-model="contactEmail"
            data-vv-name="contact_email"
            data-vv-as="contact email"
            placeholder="Email"
            @change="(event) => $emit('cv-change', 'contact_email', event.target.value)"
          />
        </a-form-item>
      </a-col>

      <a-col :span="11">
        <a-form-item
          :colon="false"
          :validate-status="$validator.errors.first('general.contact_phone') ? 'error' : ''"
          :help="$validator.errors.first('general.contact_phone')"
          label="Phone number"
        >
          <a-input
            v-model="contactPhone"
            data-vv-name="contact_phone"
            data-vv-as="contact phone"
            placeholder="Phone number"
            @change="(event) => $emit('cv-change', 'contact_phone', event.target.value)"
          />
        </a-form-item>
      </a-col>
    </a-row>
  </a-form>
</template>

<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount, getCurrentInstance, toRefs } from "vue";
import { CharacteristicInterface } from "../../consultant_profiles/types";
import { CvInterface, CvDataInterface } from "../types";
import { emitter } from "@/mitt";

// Props
const props = defineProps({
  cv: { type: Object as () => CvInterface, default: undefined },
  cvData: { type: Object as () => CvDataInterface, default: undefined },
  step: { type: Number, default: -1 },
});

// Emits
const emits = defineEmits(["cv-change", "go-to-step"]);

// Instances
const instance = getCurrentInstance();
const $validator = instance?.proxy.$validator;

// Data Properties
const { cv } = toRefs(props);
const characteristics = ref<Array<CharacteristicInterface>>(cv.value.characteristics || []);
const firstTitle = ref<string>(cv.value.first_title || "");
const secondTitle = ref<string>(cv.value.second_title || "");
const background = ref<string>(cv.value.background || "");
const motivation = ref<string>(cv.value.motivation || "");
const location = ref<string>(cv.value.location || "");
const contactEmail = ref<string | undefined>(cv.value.contact_email);
const contactPhone = ref<string | undefined>(cv.value.contact_phone);

// Life-cycle Hooks
onMounted(() => {
  emitter.on(`validate-cv-step-${props.step}`, (e) => validate(e as string));
  if (!background.value && props.cvData?.user.profile_description) {
    background.value = props.cvData.user.profile_description;
    emits("cv-change", "background", background.value);
  }
});

onBeforeUnmount(() => {
  emitter.off(`validate-cv-step-${props.step}`, (e) => validate(e as string));
});

// Class Methods
const handleCharacteristicClick = (characteristic: any) => {
  characteristics.value = characteristics.value.some((id) => id === characteristic.id)
    ? characteristics.value.filter((id) => id !== characteristic.id)
    : [...characteristics.value, characteristic.id];

  emits("cv-change", "characteristics", characteristics.value);
};

const selected = (characteristic: any) => {
  return characteristics.value.some((id) => id === characteristic.id);
};

const validate = (nextStep: string) => {
  $validator?.validateAll("general").then((result) => {
    if (result) {
      emits("cv-change", "characteristics", characteristics.value);
      emits("go-to-step", nextStep);
    }
  });
};

const setCharacteristics = (c: Array<CharacteristicInterface>) => {
  characteristics.value = c;
  emits("cv-change", "characteristics", characteristics.value);
};

const setBackground = (b: string) => {
  background.value = b;
  emits("cv-change", "background", background.value);
};
</script>
