<template>
  <div>
    <empty-resource-table v-if="!projectsPage[0] && !loadingTable" resource="projects" />

    <a-table
      v-if="projectsPage[0] || loadingTable"
      :columns="projectsColumns"
      :data-source="projectsPage"
      :pagination="false"
      :loading="loadingTable"
      :scroll="{ x: 1000 }"
      :row-key="(project) => project.id"
      @change="(pagination, filters, sorter) => handleChange(pagination, filters, sorter)"
    >
      <template #name="name, p">
        <span class="d-flex flex-column">
          <small>{{ p.client.name }}</small>
          <div class="d-flex align-items-center">
            <router-link :to="{ name: 'project', params: { projectId: p.id } }">
              {{ name }}
            </router-link>
          </div>
        </span>
      </template>

      <template #last_modified="text, p">
        <span class="d-flex align-items-center">
          {{ toHumanDate(p.updated_at) }}
        </span>
      </template>

      <template #status="status">
        <span class="d-flex align-items-center">
          <a-tag :color="getStatusTagColor(status)">{{ status.toUpperCase() }}</a-tag>
        </span>
      </template>

      <template #pending="pendinghours">
        <span class="d-flex align-items-center">{{ pendinghours ? pendinghours : 0 }}h </span>
      </template>

      <template #invoiced="invoicedHours">
        <span class="d-flex align-items-center">{{ invoicedHours ? invoicedHours : 0 }}h</span>
      </template>

      <template #edit="text, p">
        <span class="d-flex align-items-center">
          <a-icon
            v-show="p.editable"
            type="edit"
            class="action-default"
            @click="() => handleEditProjectModalVisible(p)"
          />
          <a-icon v-if="!p.editable" type="edit" class="text-grey" />
        </span>
      </template>
    </a-table>

    <a-pagination
      v-if="loadingTable || projectsPage[0]"
      :current="currentPage"
      :total="projectsPagination ? projectsPagination.total : 1"
      :show-size-changer="true"
      :page-size="pageSize"
      :page-size-options="['10', '25', '50', '100']"
      size="small"
      class="d-flex justify-content-end mt-2"
      @change="(newPage, newPageSize) => handleGetProjectsPage(newPage, newPageSize)"
      @showSizeChange="(pageNumber, newSize) => handleGetProjectsPage(pageNumber, newSize)"
    />

    <!-- EDIT PROJECT MODAL -->
    <edit-project-modal
      :acl="acl"
      :visible="editProjectModalVisible"
      :users="users"
      :project="project"
      :clients="clients"
      @close="editProjectModalVisible = false"
      @update-project="(newProject) => handleUpdateProject(newProject)"
    />
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import EmptyResourceTable from "@/modules/common/_components/EmptyResourceTable.vue";
import { ProjectInterface, ProjectPayloadInterface } from "@/modules/projects/types";
import { MinimalClientInterface } from "@/modules/clients/types";
import { BaseAclInterface } from "../../authentication/types";
import { MinimalUserInterface } from "@/modules/users/types";
import EditProjectModal from "./EditProjectModal.vue";
import { getStatusTagColor } from "../_utils/utils";
import { projectsColumns as defaultProjectsColumns } from "../_utils/tables";
import useFilters from "@/useFilters";
import { TableColumnInterface } from "@/common/types";

// Props
const props = defineProps({
  projectsPage: { type: Array as () => Array<any>, default: () => [] },
  currentPage: { type: Number, default: 1 },
  pageSize: { type: Number, default: 25 },
  projectsPagination: { type: Object, default: undefined },
  acl: { type: Object as () => BaseAclInterface, default: undefined },
  loadingTable: { type: Boolean, default: false },
  users: { type: Array as () => Array<MinimalUserInterface>, default: () => [] },
  projects: { type: Array as () => Array<ProjectInterface>, default: () => [] },
  clients: { type: Array as () => Array<MinimalClientInterface>, default: () => [] },
  sortable: { type: Boolean, default: false },
  projectsColumns: {
    type: Array as () => Array<TableColumnInterface>,
    default: () => defaultProjectsColumns,
  },
});

const emits = defineEmits([
  "update-project",
  "handle-edit-project-modal-visible",
  "get-projects-page",
  "apply-sorting",
]);

// Filters
const { toHumanDate } = useFilters();

// Data properties
const editProjectModalVisible = ref<boolean>(false);
const project = ref<ProjectInterface | undefined>(undefined);

// Component methods
const handleUpdateProject = (newProject: ProjectPayloadInterface): void => {
  emits("update-project", newProject);
};

const handleEditProjectModalVisible = (p: ProjectInterface): void => {
  emits("handle-edit-project-modal-visible");
  project.value = p;
  editProjectModalVisible.value = true;
};

const handleGetProjectsPage = (page: number, pageSize: number): void => {
  emits("get-projects-page", { page, pageSize });
};

const handleChange = (pagination: any, filters: any, sorter: any): void => {
  emits("apply-sorting", {
    sort: sorter.columnKey,
    order: sorter.columnKey ? (sorter.order === "descend" ? "desc" : "asc") : undefined,
  });
};
</script>
