import { RouteInterface } from "@/common/types";
import Roles from "./_views/Roles.vue";

export default [
  {
    path: "roles",
    component: Roles,
    name: "roles",
    meta: { auth: true },
  },
] as Array<RouteInterface>;
