<template>
  <a-modal
    :visible="visible"
    :destroy-on-close="true"
    @cancel="$emit('close')"
    @ok="handleStoreCharacteristic"
  >
    <h3>Create a new characteristic</h3>

    <a-form layout="horizontal">
      <a-form-item
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
        :validate-status="errors.first('title') ? 'error' : ''"
        :help="errors.first('title')"
        label="Title"
      >
        <a-input
          v-model="newCharacteristic.title"
          v-validate="'required|max:255'"
          placeholder="Characteristic"
          data-vv-name="title"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script setup lang="ts">
import { ref, watch, getCurrentInstance } from "vue";
import useMixin from "@/useMixin";

// Props
const props = defineProps({
  visible: { type: Boolean, default: false },
});

// Emits
const emits = defineEmits(["store-characteristic", "close"]);

// Mixins
const { setObject } = useMixin();

// Data properties
const newCharacteristic = ref<any>({
  title: undefined,
});

// Watchers
watch(
  () => props.visible,
  (newProp, oldProp) => {
    if (newProp && !oldProp) {
      newCharacteristic.value = setObject(newCharacteristic.value, undefined);
    }
  }
);

// Class methods
const instance = getCurrentInstance();
const $validator = instance?.proxy.$validator;
const handleStoreCharacteristic = (): void => {
  $validator?.validateAll().then((result) => {
    if (result) {
      emits("store-characteristic", newCharacteristic.value);
      emits("close");
    }
  });
};
</script>
