<template>
  <a-layout-content class="my-4 mx-3">
    <div class="container">
      <roles-view :acl="baseAcl" :allow-filtering="true" />
    </div>
  </a-layout-content>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { BaseAclInterface } from "@/modules/authentication/types";
import { useAuthenticationStore } from "@/modules/authentication/_store";
import RolesView from "../_components/RolesView.vue";

// Pinia
const authenticationStore = useAuthenticationStore();
const baseAcl = computed<BaseAclInterface>(() => authenticationStore.baseAcl);
</script>
