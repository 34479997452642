<template>
  <a-modal
    :visible="visible"
    :destroy-on-close="true"
    :title="'Edit Vacation Year'"
    ok-text="Save"
    @ok="() => handleUpdateVacationYear()"
    @cancel="() => $emit('close')"
  >
    <a-form v-if="updatedVacationYear" layout="horizontal">
      <a-form-item :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }" label="Name">
        <strong> {{ updatedVacationYear.user.name }}</strong>
      </a-form-item>

      <a-form-item :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }" label="Vacation year">
        <strong> {{ updatedVacationYear.year }}</strong>
      </a-form-item>

      <a-form-item
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
        label="Earned vacation days"
        :validate-status="$validator.errors.first('days earned') ? 'error' : ''"
        :help="$validator.errors.first('days earned')"
        class="w-50"
      >
        <a-input-number
          v-model="updatedVacationYear.vacation_days_earned"
          v-validate="'required|min_value:0'"
          data-vv-name="days earned"
          placeholder="Days earned"
          class="w-50"
          :default-value="updatedVacationYear.vacation_days_earned"
        />
      </a-form-item>

      <a-form-item
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
        label="Paid vacation days"
        :validate-status="$validator.errors.first('days paid') ? 'error' : ''"
        :help="$validator.errors.first('days paid')"
        class="w-50"
      >
        <a-input-number
          v-model="updatedVacationYear.vacation_days_paid"
          v-validate="'required|min_value:0'"
          data-vv-name="days paid"
          placeholder="Days paid"
          class="w-50"
          :default-value="updatedVacationYear.vacation_days_paid"
        />
      </a-form-item>

      <a-form-item
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
        label="Saved vacation days"
        :validate-status="$validator.errors.first('days saved') ? 'error' : ''"
        :help="$validator.errors.first('days saved')"
      >
        <a-input-number
          v-model="updatedVacationYear.vacation_days_saved"
          v-validate="'required|min_value:0'"
          data-vv-name="days saved"
          placeholder="Days saved"
          class="w-50"
          :default-value="updatedVacationYear.vacation_days_saved"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script setup lang="ts">
import { ref, watch, getCurrentInstance } from "vue";
import { UserVacationYearInterface } from "../types";

const updatedVacationYear = ref<UserVacationYearInterface>();

// Props
const props = defineProps({
  vacationYear: { type: Object as () => UserVacationYearInterface, default: undefined },
  visible: { type: Boolean, default: false },
});

// Emits
const emits = defineEmits(["update-vacation-year", "close"]);

// Instances
const instance = getCurrentInstance();
const $validator = instance?.proxy.$validator;

// Watchers
watch(
  () => props.visible,
  (newProp, oldProp) => {
    if (newProp && !oldProp) {
      updatedVacationYear.value = { ...props.vacationYear };
    }
  }
);

const handleUpdateVacationYear = (): void => {
  $validator?.validate().then((result) => {
    if (result) {
      emits("update-vacation-year", updatedVacationYear.value);
      emits("close");
    }
  });
};
</script>
