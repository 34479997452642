<template>
  <!-- VACATION YEARS FILTER -->
  <div>
    <div v-if="filterPanelVisible" class="card mb-3">
      <a-form layout="vertical">
        <a-row>
          <a-col :lg="11" class="ml-3">
            <SkNameSelect
              v-model="newFilter.year"
              label="Year"
              :label-col-size="10"
              placeholder="Select year"
              :allow-clear="false"
              :options="yearList"
              @change="applyFilters"
            />
          </a-col>
          <a-col :lg="11" class="ml-3">
            <SkNameSelect
              v-model="newFilter.user_id"
              label="Users"
              :label-col-size="10"
              placeholder="Select users"
              :allow-clear="true"
              :options="usersCompactList"
              @change="applyFilters"
            />
          </a-col>
        </a-row>
      </a-form>
      <span class="d-flex justify-content-end">
        <span>
          <strong>Results:</strong>
          {{ numberOfResults }}
        </span>
      </span>
    </div>
    <portal to="vacation-years-filter-buttons">
      <a-button
        v-show="filterPanelVisible"
        class="mr-2"
        icon="close"
        type="danger"
        @click="() => clearFilters()"
      />
      <a-button
        v-show="filterPanelVisible"
        class="mr-2"
        type="primary"
        icon="up"
        @click="() => toggleFilterPanel()"
      />
      <a-button
        v-show="!filterPanelVisible"
        class="mr-2"
        type="primary"
        icon="filter"
        @click="() => toggleFilterPanel()"
      />
    </portal>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, onBeforeMount } from "vue";
import { getUsersCompactList } from "@/api";
import { VacationYearsFilterInterface } from "../types";
import { MinimalUserInterface } from "@/modules/users/types";
import useMixin from "@/useMixin";
import moment from "@/date";
import SkNameSelect from "@/common/SkNameSelect.vue";

// Props
const props = defineProps({
  filters: { type: Object as () => VacationYearsFilterInterface, default: undefined },
  numberOfResults: { type: Number, default: undefined },
});

// Emits
const emits = defineEmits(["apply-filters"]);

// Mixins
const { setObject } = useMixin();

// 2019 is hardcoded here since there will not be any data before that year
// See: https://linear.app/skry-ab/issue/SK-859/backfill-all-vacation-years
const firstYear = 2019;
const currentYear = moment().year();
const yearList: Array<{ id: number; name: number }> = // structure needed for SkNameSelect
  [...Array(currentYear - firstYear + 1)].map((_, i) => ({
    id: currentYear - i,
    name: currentYear - i,
  }));

// Data properties
const filterPanelVisible = ref<boolean>(false);
const usersCompactList = ref<Array<MinimalUserInterface>>([]);
const newFilter = ref<VacationYearsFilterInterface>({
  year: moment().year(),
});

// Computed Properties
const filterIsEmpty = computed((): boolean => {
  return Boolean(!newFilter.value.year && !newFilter.value.user_id);
});

// Watchers
watch(filterPanelVisible, (newProp, oldProp) => {
  if (newProp && !oldProp) {
    setFilter(props.filters);
  }
});

onBeforeMount(() => {
  handleGetUsersCompactList();
});

// Component Methods
const toggleFilterPanel = (): void => {
  filterPanelVisible.value = !filterPanelVisible.value;
};

const handleGetUsersCompactList = (): void => {
  getUsersCompactList().then((users) => {
    usersCompactList.value = users;
  });
};

const applyFilters = (): void => {
  emits("apply-filters", newFilter.value);
};

const clearFilters = (): void => {
  handleClearReportsFilter();
  applyFilters();
};

const handleClearReportsFilter = (): void => {
  newFilter.value = {
    year: moment().year(),
  };
};

const setFilter = (filter: any): void => {
  if (filter === undefined) {
    return;
  }
  newFilter.value = setObject(newFilter.value, filter);
};
</script>
