import { defineStore, acceptHMRUpdate } from "pinia";
import { StateInterface } from "@/modules/transactions/types";
import { apiStoreTransaction } from "@/modules/transactions/_utils/api";

export const useTransactionsStore = defineStore("transactions", {
  state: (): StateInterface => {
    return {
      transaction: {},
      transactions: new Map(),
      transactions_pagination: {},
      aggregated_summary: {},
      skp_overview: {},
    };
  },
  actions: {
    storeTransaction(newTransaction: any) {
      return new Promise((resolve, reject) => {
        apiStoreTransaction(newTransaction)
          .then((res: any) => resolve(res.data.data))
          .catch((err: any) => {
            reject(err);
          });
      });
    },

    setTransactions({ page, transactions }: any) {
      this.transactions = this.transactions.set(page, transactions);
    },

    setTransactionsPagination(meta: any) {
      this.transactions_pagination = meta;
    },

    setAggregatedSummary(aggregated_summary: any) {
      this.aggregated_summary = aggregated_summary;
    },

    clearTransactions() {
      this.transactions = new Map();
    },

    setSkpOverview(skp_overview: any) {
      this.skp_overview = skp_overview;
    },
  },
});

// HMR
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useTransactionsStore, import.meta.hot));
}
