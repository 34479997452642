import CostCenters from "./_views/CostCenters.vue";
import { RouteInterface } from "@/common/types";

export default [
  {
    path: "cost-centers",
    component: CostCenters,
    name: "cost-centers",
    meta: { auth: true },
  },
] as Array<RouteInterface>;
