<template v-if="jobPosting">
  <div class="card">
    <div class="d-flex flex-column pb-2 mb-3" :style="{ borderBottom: '1px solid grey' }">
      <div class="d-flex flex-row mb-2">
        <h2 class="mb-0 pr-4">{{ jobPosting?.title }}</h2>
        <a-button icon="close" class="ml-auto min-w-8" @click="hideSelectedJobPostingDetails" />
      </div>

      <div class="d-flex flex-row">
        <div class="d-flex flex-column">
          <span class="my-1">
            <em>{{ jobPosting?.organisation }}</em>
          </span>

          <div class="mt-1 d-flex flex-row align-items-center">
            <span v-if="jobPosting?.owner.id" class="min-w-8 mr-2">
              <a-avatar :src="jobPosting?.owner.gravatar" size="small" />
            </span>
            <a-icon v-else type="user" class="mr-1" />

            <router-link
              v-if="jobPosting?.owner.id"
              :to="{ name: 'user', params: { id: jobPosting?.owner.id } }"
              class="mr-2"
            >
              {{ jobPosting?.owner.name }}
            </router-link>
            <span v-else>{{ jobPosting?.owner.name }}</span>
          </div>
        </div>

        <div class="ml-auto mt-auto d-flex flex-column">
          <div v-if="jobPosting?.updated_at" class="d-flex flex-row align-items-center ml-auto">
            <em>
              updated
              {{ handleDurationBetweenDates(jobPosting?.updated_at) }}
              ago
            </em>
            <a-icon type="clock-circle" class="ml-2 text-blue" />
          </div>

          <div v-if="jobPosting?.created_at" class="d-flex flex-row align-items-center ml-auto">
            <em>
              created
              {{ handleDurationBetweenDates(jobPosting?.created_at) }}
              ago
            </em>
            <a-icon type="plus-circle" class="ml-2 text-green" />
          </div>
        </div>
      </div>
    </div>

    <div v-if="loading" class="text-center"><a-spin tip="Loading…" /></div>
    <div v-else :style="{ maxHeight: maxHeight, overflow: 'auto' }">
      <div v-if="selectedJobPostingDetails" class="pb-3">
        <div v-if="selectedJobPostingDetails.has_shown_interest">
          <em>You have shown interest</em>
          <a-icon type="check-circle" class="ml-2" theme="twoTone" />
        </div>
        <a-button
          v-else
          icon="star"
          :loading="showInterestLoading"
          @click="handleSendJobPostingInterest"
        >
          I'm interested!
        </a-button>
      </div>

      <div v-if="selectedJobPostingDetails">
        <div v-if="selectedJobPostingDetails.descriptions.length > 0">
          <div v-for="description in selectedJobPostingDetails.descriptions" :key="description.id">
            <job-posting-details-description :description="description" />
          </div>
        </div>
        <div v-else><em>This job posting does not have a description yet.</em></div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted, getCurrentInstance, computed } from "vue";
import JobPostingDetailsDescription from "./JobPostingDetailsDescription.vue";
import { JobPostingDetailsInterface, JobPostingInterface } from "../types";
import { useJobPostingsStore } from "@/modules/job_postings/_store";
import useFilters from "@/useFilters";
import moment from "@/date";
import { RFC_3339 } from "@/date";

// Pinia
const jobPostingsStore = useJobPostingsStore();
const jobPosting = computed<JobPostingInterface | undefined>(
  () => jobPostingsStore.selectedJobPosting
);
const loading = computed<boolean>(() => jobPostingsStore.loadingSelectedJobPosting);
const selectedJobPostingDetails = computed<JobPostingDetailsInterface | undefined>(
  () => jobPostingsStore.selectedJobPostingDetails
);
const hideSelectedJobPostingDetails = () => jobPostingsStore.hideSelectedJobPostingDetails();

// Filters
const { durationBetweenDates } = useFilters();

// Instances
const instance = getCurrentInstance();
const $message = instance?.proxy.$message;

// Data properties
const showInterestLoading = ref<boolean>(false);
const maxHeight = ref<string>("0px");

// Lifecycle methods
onMounted(() => {
  window.addEventListener("resize", handleResize);
  handleResize();
});

onUnmounted(() => {
  window.removeEventListener("resize", handleResize);
});

// Component methods
const handleResize = () => {
  maxHeight.value = `${window.innerHeight - 400}px`;
};

const handleSendJobPostingInterest = () => {
  showInterestLoading.value = true;
  jobPostingsStore
    .sendJobPostingInterest()
    .catch(() => $message?.error("Couldn't send that you were interested! Try again", 3))
    .finally(() => (showInterestLoading.value = false));
};

const handleDurationBetweenDates = (time: string): string => {
  return durationBetweenDates(new Date(), moment(time, [moment.ISO_8601, RFC_3339]).toDate());
};
</script>
