<template>
  <a-modal
    :visible="visible"
    title="Edit Knowledge Domain"
    ok-text="Update"
    @cancel="$emit('close')"
    @ok="updateKnowledgeDomain"
  >
    <a-form data-vv-scope="knowledge-domain">
      <a-row>
        <a-form-item
          :validate-status="$validator.errors.first('knowledge-domain.level') ? 'error' : ''"
          :help="$validator.errors.first('knowledge-domain.level')"
          :colon="false"
          label="Level"
        >
          <a-select v-model="level" v-validate="'required'" data-vv-name="level">
            <a-select-option value="productive">Productive</a-select-option>
            <a-select-option value="advanced">Advanced</a-select-option>
            <a-select-option value="expert">Expert</a-select-option>
          </a-select>
        </a-form-item>
      </a-row>

      <a-row v-if="userKd">
        <h4>
          <span class="text-capitalize">{{ level }}</span>
          in
          {{ userKd.knowledge_domain.title }}
        </h4>
        <p>{{ userKd.knowledge_domain[`description_${level}`] }}</p>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script setup lang="ts">
import { ref, watch, getCurrentInstance } from "vue";

// Props
const props = defineProps({
  userKd: { type: Object, default: undefined },
  visible: { type: Boolean, default: false },
});

// Emits
const emits = defineEmits(["update-knowledge-domain"]);

// Instances
const instance = getCurrentInstance();
const $validator = instance?.proxy.$validator;

// Data Properties
const level = ref<string | null>(null);

// Watchers
watch(
  () => props.visible,
  (newProp, oldProp) => {
    if (newProp && !oldProp) {
      if (newProp && !oldProp) {
        level.value = props.userKd.level;
      }
    }
  }
);

// Class Methods
const updateKnowledgeDomain = () => {
  $validator?.validateAll("knowledge-domain").then((result) => {
    if (result) {
      emits("update-knowledge-domain", {
        id: props.userKd.id,
        level: level.value,
      });
    }
  });
};
</script>
