<template>
  <div>
    <a-row type="flex" justify="end" class="mb-2">
      <a-button
        :loading="loadingCsv"
        class="mr-2"
        icon="download"
        type="default"
        @click="$emit('download-csv')"
      />
      <portal-target class="mr-2" name="transactions-filter-buttons"></portal-target>
    </a-row>

    <transactions-filter
      :visible="filterVisible"
      :number-of-results="transactionsPagination?.total"
      :filters="filters"
      :profile="profile"
      @apply-filters="(newFilters) => $emit('apply-filters', newFilters)"
    />

    <div class="card">
      <detailed-transactions-table
        :transactions-page="transactionsPage"
        :loading-table="loadingTable"
        :transactions-pagination="transactionsPagination"
        :current-page="currentPage"
        :page-size="pageSize"
        @apply-sorting="(newSorting) => $emit('apply-sorting', newSorting)"
        @get-transactions-page="(params) => $emit('get-transactions', params)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import DetailedTransactionsTable from "../_components/DetailedTransactionsTable.vue";
import { TransactionInterface, TransactionsFilterInterface } from "../types";
import TransactionsFilter from "../_components/TransactionsFilter.vue";
import { ProfileInterface } from "../../authentication/types";
import { PaginationInterface } from "@/common/types";

// Props
const props = defineProps({
  transactionsPage: { type: Array as () => Array<TransactionInterface>, default: () => [] },
  transactionsPagination: { type: Object as () => PaginationInterface, default: undefined },
  filters: { type: Object as () => TransactionsFilterInterface, default: undefined },
  profile: { type: Object as () => ProfileInterface, default: undefined },
  loadingTable: { type: Boolean, default: false },
  loadingCsv: { type: Boolean, default: false },
  currentPage: { type: Number, default: 0 },
  pageSize: { type: Number, default: 0 },
});

// Data Properties
const filterVisible = ref<boolean>(false);
</script>
