import { RouteInterface } from "@/common/types";
import Home from "./_views/Home.vue";

export default [
  {
    path: "home",
    component: Home,
    name: "home",
    meta: { auth: true },
  },
] as Array<RouteInterface>;
