import axios from "@/network";
import { QuestionPayloadInterface, SubmissionPayloadInterface } from "@/modules/quiz/types";

export function apiGetQuestions(
  params: any,
  headers: Record<string, string> = {}
): Promise<Object> {
  const url = "/quiz_questions";
  return axios.get(url, { params, headers });
}

export function apiSubmitAnswer(
  questionId: number,
  quizSubmission: SubmissionPayloadInterface
): Promise<Object> {
  const url = `/quiz_questions/${questionId}/submissions`;
  return axios.post(url, quizSubmission);
}

export function apiUpdateAnswer(
  questionId: number,
  userId: number,
  quizSubmission: SubmissionPayloadInterface
): Promise<Object> {
  const url = `/quiz_questions/${questionId}/submissions/${userId}`;
  return axios.patch(url, quizSubmission);
}

export function apiStoreQuestion(newQuestion: QuestionPayloadInterface) {
  const url = "/quiz_questions";
  const payload = { quiz_question: newQuestion };
  return axios.post(url, payload);
}

export function apiDestroyQuestion(questionId: number) {
  const url = `/quiz_questions/${questionId}`;
  return axios.delete(url);
}

export function apiUpdateQuestion(questionId: number, updatedQuestion: QuestionPayloadInterface) {
  const url = `/quiz_questions/${questionId}`;
  const payload = { quiz_question: updatedQuestion };
  return axios.patch(url, payload);
}
