<template>
  <div v-show="filterPanelVisible" class="card mb-3">
    <a-form layout="vertical">
      <bookmarked-filters-selection
        :bookmarked-filters="bookmarkedFilters"
        :filter-is-empty="filterIsEmpty"
        bookmark-type="Clients"
        class="ml-3"
        @selected="(bookmarkFilterUrl) => handleCustomFilterSelected(bookmarkFilterUrl)"
        @create-bookmark-filter="handleCreateBookmarkFilter"
        @update-bookmark-filter="handleUpdateBookmarkFilter"
        @delete-bookmark-filter="handleDeleteBookmarkFilter"
      />
      <a-row>
        <a-col :lg="11" class="ml-3">
          <SkNameSelect
            v-model="newFilter.client_id"
            label="Clients"
            :label-col-size="10"
            mode="multiple"
            placeholder="Select clients"
            :allow-clear="true"
            :options="clientsCompactList"
            @change="applyFilters"
          />

          <SkNameSelect
            v-model="newFilter.account_manager_id"
            label="Account managers"
            :label-col-size="10"
            mode="multiple"
            placeholder="Select account managers"
            :allow-clear="true"
            :options="accountManagersCompactList"
            @change="applyFilters"
          />
        </a-col>

        <a-col :lg="11" class="ml-3">
          <a-form-item :label-col="{ span: 10 }" :wrapper-col="{ span: 16 }" label="Status">
            <a-select
              v-model="newFilter.project_status"
              allow-clear
              placeholder="Select status"
              @change="() => applyFilters()"
            >
              <a-select-option value="active">Active</a-select-option>
              <a-select-option value="closed">Inactive</a-select-option>
            </a-select>
          </a-form-item>
          <!-- wrapper-col: span=15 to keep icons in one line on diff screens -->
          <a-form-item
            :label-col="{ span: 8 }"
            :wrapper-col="{ span: 15 }"
            label="By Secrecy level"
          >
            <a-switch v-model="withSecrecyLevel" @change="() => handleBySecrecyLevel()" />
          </a-form-item>
          <a-form-item
            v-if="withSecrecyLevel"
            :label-col="{ span: 8 }"
            :wrapper-col="{ span: 15 }"
            label="Secrecy level"
          >
            <a-checkbox
              :checked="newFilter.secrecy_mention_client_spoken"
              :indeterminate="newFilter.secrecy_mention_client_spoken === undefined"
              @change="
                (e) => handleCheckboxChange('secrecy_mention_client_spoken', e.target.checked)
              "
            >
              <a-tag :color="Colors.cyan">M</a-tag>
            </a-checkbox>
            <a-checkbox
              :checked="newFilter.secrecy_client_is_official_written"
              :indeterminate="newFilter.secrecy_client_is_official_written === undefined"
              @change="
                (e) => handleCheckboxChange('secrecy_client_is_official_written', e.target.checked)
              "
            >
              <a-tag :color="Colors.blue_darker">O</a-tag>
            </a-checkbox>
            <a-checkbox
              :checked="newFilter.secrecy_ok_show_client_logo"
              :indeterminate="newFilter.secrecy_ok_show_client_logo === undefined"
              @change="(e) => handleCheckboxChange('secrecy_ok_show_client_logo', e.target.checked)"
            >
              <a-tag :color="Colors.blue_darkest">L</a-tag>
            </a-checkbox>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>

    <a-row class="d-flex justify-content-end">
      <span>
        <strong>Results:</strong>
        {{ numberOfResults }}
      </span>
    </a-row>

    <portal to="clients-filter-buttons">
      <a-button
        v-show="filterPanelVisible"
        class="mr-2"
        icon="close"
        type="danger"
        @click="() => clearFilters()"
      />
      <a-button
        v-show="filterPanelVisible"
        type="primary"
        icon="up"
        @click="() => toggleFilterPanel()"
      />
      <a-button
        v-show="!filterPanelVisible"
        type="primary"
        icon="filter"
        @click="() => toggleFilterPanel()"
      />
    </portal>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, getCurrentInstance } from "vue";

import BookmarkedFiltersSelection from "../../bookmarked_filters/_components/BookmarkedFiltersSelection.vue";
import { ProfileInterface } from "../../authentication/types";
import { MinimalUserInterface } from "../../users/types";
import { MinimalClientInterface } from "../types";
import { BookmarkedFilterInterface } from "../../bookmarked_filters/types";
import useMixin from "@/useMixin";
import { getUsersCompactList, getClientsCompactList } from "@/api";
import {
  apiStoreUserBookmarkedFilter,
  apiUpdateUserBookmarkedFilter,
  apiDestroyUserBookmarkedFilter,
  apiGetUserBookmarkedFilters,
} from "@/modules/bookmarked_filters/_utils/api";
import Colors from "@/assets/scss/_colors.module.scss";

// Props
const props = defineProps({
  profile: { type: Object as () => ProfileInterface, default: () => ({}) },
  filters: { type: Object as () => object, default: () => ({}) },
  numberOfResults: { type: Number, default: 0 },
});

// Emits
const emits = defineEmits(["apply-filters", "apply-url-filter"]);

// Mixins
const { equals, setObject } = useMixin();

// Data properties
const filterPanelVisible = ref<boolean>(false);
const accountManagersCompactList = ref<Array<MinimalUserInterface>>([]);
const clientsCompactList = ref<Array<MinimalClientInterface>>([]);

const emptyFilter = {
  project_status: undefined,
  secrecy_mention_client_spoken: undefined,
  secrecy_client_is_official_written: undefined,
  secrecy_ok_show_client_logo: undefined,
  account_manager_id: [],
  client_id: [],
};
const newFilter = ref<any>(emptyFilter);

const bookmarkedFilters = ref<BookmarkedFilterInterface[]>([]);
const withSecrecyLevel = ref(false);

// Computed properties
const filterIsEmpty = computed(() => equals(emptyFilter, newFilter.value));

// Watchers
watch(
  () => filterPanelVisible.value,
  (newProp, oldProp) => {
    if (newProp && !oldProp) {
      handleGetClientsCompactList();
      handleGetAccountManagersCompactList();
      handleGetBookmarkedFilters();
      setFilter(props.filters);
    }
  }
);

//Component methods
const toggleFilterPanel = (): void => {
  filterPanelVisible.value = !filterPanelVisible.value;
};

const applyFilters = (): void => {
  emits("apply-filters", filterIsEmpty.value ? {} : newFilter.value);
};

const clearFilters = (): void => {
  handleClearClientsFilter();
  emits("apply-filters", {});
};

const handleCustomFilterSelected = (bookmarkFilterUrl: string): void => {
  handleClearClientsFilter();
  bookmarkFilterUrl ? emits("apply-url-filter", bookmarkFilterUrl) : applyFilters();
};

const handleClearClientsFilter = (): void => {
  withSecrecyLevel.value = false;
  newFilter.value = emptyFilter;
};

const handleBySecrecyLevel = (): void => {
  if (!withSecrecyLevel.value) {
    newFilter.value.secrecy_mention_client_spoken = undefined;
    newFilter.value.secrecy_client_is_official_written = undefined;
    newFilter.value.secrecy_ok_show_client_logo = undefined;
    applyFilters();
  }
};

const handleCheckboxChange = (attribute: string): void => {
  if (newFilter.value[attribute] === false) {
    newFilter.value[attribute] = undefined;
  } else if (newFilter.value[attribute] === undefined) {
    newFilter.value[attribute] = true;
  } else {
    newFilter.value[attribute] = false;
  }
  applyFilters();
};

const setFilter = (filter: any): void => {
  if (filter === undefined) {
    return;
  }
  newFilter.value = setObject(newFilter.value, filter);
  if (
    filter.secrecy_mention_client_spoken ||
    filter.secrecy_client_is_official_written ||
    filter.secrecy_ok_show_client_logo
  ) {
    withSecrecyLevel.value = true;
  }
  if (filter.secrecy_mention_client_spoken === "false") {
    newFilter.value.secrecy_mention_client_spoken = false;
  } else if (filter.secrecy_mention_client_spoken === "true") {
    newFilter.value.secrecy_mention_client_spoken = true;
  }

  if (filter.secrecy_client_is_official_written === "false") {
    newFilter.value.secrecy_client_is_official_written = false;
  } else if (filter.secrecy_client_is_official_written === "true") {
    newFilter.value.secrecy_client_is_official_written = true;
  }

  if (filter.secrecy_ok_show_client_logo === "false") {
    newFilter.value.secrecy_ok_show_client_logo = false;
  } else if (filter.secrecy_ok_show_client_logo === "true") {
    newFilter.value.secrecy_ok_show_client_logo = true;
  }
};

const handleGetClientsCompactList = (): void => {
  getClientsCompactList().then((clients) => {
    clientsCompactList.value = clients;
  });
};

const handleGetAccountManagersCompactList = (): void => {
  getUsersCompactList({ only_account_managers: true }).then((accountManagers) => {
    accountManagersCompactList.value = accountManagers;
  });
};

const handleGetBookmarkedFilters = (): void => {
  apiGetUserBookmarkedFilters(props.profile.id).then(
    ({ data }: any) => (bookmarkedFilters.value = data.data)
  );
};

const instance = getCurrentInstance();
const $message = instance?.proxy?.$message;
const handleCreateBookmarkFilter = (bookmarkFilter: any): void => {
  apiStoreUserBookmarkedFilter(props.profile.id, bookmarkFilter)
    .then(({ data }: any) => bookmarkedFilters.value.push(data.data))
    .then(() => $message?.success("New filter created successfully!", 3))
    .catch(() => $message?.error("Couldn't create new filter!", 3));
};

const handleUpdateBookmarkFilter = (bookmarkFilterId: number, bookmarkFilter: any): void => {
  apiUpdateUserBookmarkedFilter(props.profile.id, bookmarkFilterId, bookmarkFilter)
    .then(
      ({ data }: any) =>
        (bookmarkedFilters.value = bookmarkedFilters.value.map((f) =>
          f.id === data.data.id ? data.data : f
        ))
    )
    .then(() => $message?.success("Filter updated successfully!", 3))
    .catch(() => $message?.error("Couldn't update filter!", 3));
};

const handleDeleteBookmarkFilter = (bookmarkFilterId: number) => {
  apiDestroyUserBookmarkedFilter(props.profile.id, bookmarkFilterId)
    .then(
      () =>
        (bookmarkedFilters.value = bookmarkedFilters.value.filter((f) => f.id !== bookmarkFilterId))
    )
    .then(() => $message?.success("Filter removed successfully!", 3))
    .catch(() => $message?.error("Couldn't remove filter!", 3));
};
</script>
