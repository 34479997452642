import { TableColumnInterface } from "@/common/types";

const userColumn: TableColumnInterface = {
  title: "User",
  dataIndex: "user.name",
  key: "user",
  scopedSlots: { customRender: "user" },
};

const typeColumn: TableColumnInterface = {
  title: "Type",
  dataIndex: "type",
  key: "type",
  scopedSlots: { customRender: "type" },
};

const projectRoleColumn: TableColumnInterface = {
  title: "Role",
  dataIndex: "project.name",
  scopedSlots: { customRender: "project" },
  key: "project",
};

const costCenterColumn: TableColumnInterface = {
  title: "Cost center",
  dataIndex: "cost_center.name",
  scopedSlots: { customRender: "cost_center" },
  key: "cost-center",
};

const dateColumn: TableColumnInterface = {
  title: "Date",
  dataIndex: "start_date",
  sorter: true,
  key: "start_date",
  scopedSlots: { customRender: "date" },
};

const actionsColumn: TableColumnInterface = {
  title: "Actions",
  key: "actions",
  scopedSlots: { customRender: "actions" },
};

const roleColumn: TableColumnInterface = {
  title: "Role",
  dataIndex: "role.title",
  key: "role",
  scopedSlots: { customRender: "role" },
};

const amountColumn: TableColumnInterface = {
  title: "Logged time",
  dataIndex: "amount",
  key: "amount",
  scopedSlots: { customRender: "amount" },
};

const hoursColumn: TableColumnInterface = {
  title: "Hourly rate",
  dataIndex: "hourly_rate",
  key: "hourly_rate",
  scopedSlots: { customRender: "hourly" },
};

const commentColumn: TableColumnInterface = {
  title: "Comment",
  dataIndex: "comment",
  key: "comment",
  scopedSlots: { customRender: "comment" },
};

export const reportsColumns: Array<TableColumnInterface> = [
  userColumn,
  typeColumn,
  projectRoleColumn,
  costCenterColumn,
  dateColumn,
  actionsColumn,
];

export const invoiceReportsColumns: Array<TableColumnInterface> = [
  typeColumn,
  roleColumn,
  dateColumn,
  amountColumn,
  hoursColumn,
  commentColumn,
];

export const expenseReportsColumns: Array<TableColumnInterface> = [
  {
    title: "Role",
    dataIndex: "title",
    key: "role",
    scopedSlots: { customRender: "role" },
  },
  dateColumn,
  {
    title: "Value",
    dataIndex: "cost",
    key: "value",
    scopedSlots: { customRender: "value" },
  },
];
