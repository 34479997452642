<template>
  <div>
    <a-row type="flex" justify="space-between">
      <a-col>
        <h3>Educations</h3>
      </a-col>
      <a-col>
        Sorting:
        <a class="ml-1" @click="sortAllEducations('custom')">custom</a>
        <a-divider type="vertical" />
        <a @click="sortAllEducations('chronological')">chronological</a>
      </a-col>
      <a-col>
        Select:
        <a @click="setEducations([])">none</a>
        <a-divider type="vertical" />
        <a
          @click="
            setEducations(
              cvData.user.educations.map((education) =>
                education.description ? education.id : undefined
              )
            )
          "
          >all</a
        >
      </a-col>
    </a-row>

    <em v-if="cvData.user.educations.length === 0">No educations</em>
    <a-checkbox-group
      v-else
      v-model="educations"
      class="d-block"
      @change="(educations) => setEducations(educations)"
    >
      <draggable v-model="allEducations" handle=".handle" @end="() => setEducations(educations)">
        <a-row
          v-for="education in allEducations"
          :key="education.id"
          class="mb-2"
          type="flex"
          align="middle"
        >
          <a-col :span="1">
            <a-checkbox v-if="education.description" :value="education.id" />
          </a-col>
          <a-col :span="23">
            <a-card :class="[education.description ? '' : 'conditional-transparency']" type="inner">
              <a-row type="flex">
                <a-col :span="6">
                  <a-row type="flex">
                    <a-col :span="18">
                      <h4>{{ education.university_name }}</h4>
                    </a-col>
                    <a-col></a-col>
                  </a-row>

                  <p class="mt-2 mr-2">
                    {{ formatDate(education.start_date) }} -
                    {{ formatDate(education.end_date) }}
                  </p>
                </a-col>

                <a-col :span="16" class="description-col">
                  <p>
                    {{
                      education.description
                        ? education.description
                        : "Missing education description..."
                    }}
                  </p>
                  <a
                    v-if="education.url_link"
                    :href="`${education.url_link}`"
                    target="_blank"
                    class="d-flex mb-1"
                  >
                    {{ education.url_link }}
                  </a>
                  <span
                    v-for="skill in education.skills"
                    :key="skill.id"
                    class="cv-badge cv-badge-rounded cv-badge-blue mt-2"
                  >
                    {{ skill.title }}
                  </span>
                </a-col>

                <a-col :span="2" class="handle-col">
                  <a-icon type="menu" class="handle" />
                </a-col>
              </a-row>
            </a-card>
          </a-col>
        </a-row>
      </draggable>
    </a-checkbox-group>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted, toRefs } from "vue";
import draggable from "vuedraggable";
import { CvInterface, CvDataInterface, MinimalEducationCvInterface } from "../types";
import moment from "@/date";
import { emitter } from "@/mitt";
import { EducationInterface } from "@/modules/users/types";
import { sortByCustomSortOrder } from "@/modules/cv/_utils/sorting";

// Props
const props = defineProps({
  cv: { type: Object as () => CvInterface, default: undefined },
  cvData: { type: Object as () => CvDataInterface, default: undefined },
  step: { type: Number, default: -1 },
});

// Emits
const emits = defineEmits(["cv-change", "go-to-step"]);

// Data Properties
const { cv, cvData } = toRefs(props);
const educations = ref<number[]>(
  cv.value.educations.map((education: MinimalEducationCvInterface) => education.education_id)
);
const allEducations = ref<Array<EducationInterface>>([]);

// Life-cycle Hooks
onMounted(() => {
  emitter.on(`validate-cv-step-${props.step}`, (e) => validate(e as string));

  // Initialize allEducations from cvData
  props.cvData?.user.educations.forEach((edu: EducationInterface) => {
    allEducations.value.push({ ...edu });
  });

  // When opening the menu, let educations shown on the cv be in cv order above the educations not shown on the cv
  sortAllEducations("custom");
});

onUnmounted(() => {
  emitter.off(`validate-cv-step-${props.step}`, (nextStep) => validate(nextStep as string));
});

// Component methods
const validate = (nextStep: string) => {
  // Nothing to validate here
  emits("cv-change", "educations", transformEducations(educations.value));
  emits("go-to-step", nextStep);
};

const setEducations = (w: number[]) => {
  educations.value = w;
  educations.value.sort((a, b) => {
    const aIndex = allEducations.value.findIndex((edu) => edu.id === a);
    const bIndex = allEducations.value.findIndex((edu) => edu.id === b);
    return aIndex - bIndex;
  });
  emits("cv-change", "educations", transformEducations(educations.value));
};

const transformEducations = (eds: number[]): Array<MinimalEducationCvInterface> => {
  const newEducations: Array<MinimalEducationCvInterface> = [];
  eds.forEach((eduIndex) => {
    const r = allEducations.value.find((e) => e.id === eduIndex);
    if (!r) return;
    newEducations.push({
      education_id: eduIndex,
    });
  });
  return newEducations;
};

const formatDate = (date: string) => {
  return moment(date).format("MMMM YYYY");
};

const sortAllEducations = (how: "chronological" | "custom") => {
  if (how === "chronological") {
    allEducations.value.sort((a, b) => Date.parse(b.start_date) - Date.parse(a.start_date));
  }
  if (how === "custom") {
    allEducations.value = sortByCustomSortOrder(
      allEducations.value,
      props.cv.educations,
      (ae) => ae.id,
      (ce) => ce.education_id
    );
  }
  setEducations(educations.value);
};
</script>

<style lang="scss" scoped>
$default-border-radius: 4px;
$default-border: 1px solid #ebebeb;

@mixin shadow-border {
  border: $default-border;
  border-bottom-width: 1.5px;
}

.description-col {
  border-left: 1px lightGrey solid;
  padding-left: 0.9rem;
  word-wrap: break-word;
}

.handle-col {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.handle {
  padding: 0.5rem;
  cursor: move;
}

.conditional-transparency {
  opacity: 0.5;
}

.cv-badge {
  display: inline-block;
  background: white;
  padding: 0.5em 1.25em;
  border-radius: $default-border-radius;
  font-size: smaller;

  &:not(:last-of-type) {
    margin-right: 1em;
  }

  &-block {
    display: block;
    text-align: center;
  }

  &-raised {
    @include shadow-border;
  }

  &-rounded {
    border-radius: 100px;
  }

  &-blue {
    background: rgba(0, 100, 255, 0.1);
    border: 0;
  }
}
</style>
