<template>
  <a-modal
    :visible="visible"
    :destroy-on-close="true"
    title="Create a new Cost Center"
    @cancel="$emit('close')"
    @ok="handleUpdateCostCenter"
  >
    <a-form>
      <SkInput
        v-model="updatedCostCenter.name"
        v-validate="'required|max:255'"
        :error="errors.first('name')"
        data-vv-name="name"
        label="Name"
        placeholder="Cost center name"
      />

      <SkSelect
        v-model="updatedCostCenter.manager_id"
        v-validate="'required'"
        :options="users"
        :error="errors.first('manager')"
        data-vv-name="manager"
        label="Manager"
        placeholder="Select a manager"
        display-prop="name"
      />

      <SkTextarea
        v-model="updatedCostCenter.description"
        v-validate="'required'"
        :error="errors.first('description')"
        data-vv-name="description"
        label="Description"
      />

      <SkInput
        v-model="updatedCostCenter.fortnox_cost_center_id"
        v-validate="'alpha_num|max:255'"
        :error="errors.first('fortnox_cost_center_id')"
        data-vv-name="fortnox_cost_center_id"
        data-vv-as="Fortnox ID"
        label="Fortnox ID"
        placeholder="Fortnox ID"
      />

      <SkCheckbox v-model="updatedCostCenter.billable" label="Cost center is billable" />
      <a-form-item :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }" label="Use Cases">
        <a-select v-model="updatedCostCenter.use_cases" placeholder="Use Cases" mode="multiple">
          <a-select-option :value="CostCenterUseCase.DETACHEDTIME">{{
            costCenterUseCases[CostCenterUseCase.DETACHEDTIME]
          }}</a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script setup lang="ts">
import { ref, watch, getCurrentInstance } from "vue";
import { MinimalUserInterface } from "@/modules/users/types";
import { CostCenterInterface, CostCenterUseCase, costCenterUseCases } from "../types";
import useMixin from "@/useMixin";

// Props
const props = defineProps({
  users: { type: Array as () => Array<MinimalUserInterface>, default: () => [] },
  visible: { type: Boolean, default: false },
  costCenter: { type: Object as () => CostCenterInterface, default: undefined },
});

// Emits
const emits = defineEmits(["update-cost-center", "close"]);

// Mixins
const { setObject } = useMixin();

// Data properties
const updatedCostCenter = ref<any>({
  id: undefined,
  name: undefined,
  manager_id: undefined,
  description: undefined,
  billable: undefined,
  fortnox_cost_center_id: undefined,
  use_cases: undefined,
});

// Watchers
watch(
  () => props.visible,
  (newProp: boolean, oldProp: boolean) => {
    if (newProp && !oldProp) {
      updatedCostCenter.value = setObject(updatedCostCenter.value, props.costCenter);
      updatedCostCenter.value.manager_id = props.costCenter.manager.id;
    }
  }
);

// Class methods
const instance = getCurrentInstance();
const $validator = instance?.proxy.$validator;
const handleUpdateCostCenter = (): void => {
  $validator?.validateAll().then((result) => {
    if (result) {
      emits("update-cost-center", updatedCostCenter.value);
      emits("close");
    }
  });
};
</script>
