<template>
  <div v-if="projectsPagination">
    <!-- HEADER -->
    <div class="d-flex justify-content-between">
      <h2>{{ title }}</h2>
      <div class="d-flex">
        <a-button
          v-if="allowDownload"
          :loading="downloadingCsv"
          class="mr-2"
          icon="download"
          type="default"
          @click="handleDownloadCsv"
        />
        <portal-target name="projects-filter-buttons"></portal-target>
        <a-button
          v-if="allowCreation && baseAcl.isInternal"
          type="primary"
          icon="plus"
          class="ml-2"
          @click="storeProjectModalVisible = true"
        />
      </div>
    </div>

    <!-- PROJECTS FILTER -->
    <projects-filter
      v-if="allowFiltering"
      :profile="profile"
      :filters="{ ...filtersRef, ...projectsFilter }"
      :number-of-results="projectsPagination.total"
      :unchangeable-filter="projectsFilter"
      @apply-filters="(filters) => applyFilters(filters)"
    />

    <!-- PROJECTS TABLE -->
    <div :class="allowFiltering ? 'card' : ''">
      <projects-table
        :projects-page="projectsPage"
        :current-page="currentPage"
        :page-size="pageSizeRef"
        :projects-pagination="projectsPagination"
        :acl="baseAcl"
        :loading-table="loadingTable"
        :users="usersCompactList"
        :clients="clientsCompactList"
        :sortable="true"
        :projects-columns="projectsColumns"
        @handle-edit-project-modal-visible="handleEditProjectModalVisible"
        @get-projects-page="(params) => getProjectsPage(params)"
        @update-project="(updatedProject) => handleUpdateProject(updatedProject)"
        @apply-sorting="(sorting) => applySorting(sorting)"
      />
    </div>

    <!-- CREATE NEW PROJECT MODAL -->
    <add-project-modal
      :clients="clientsCompactList"
      :profile="profile"
      :acl="baseAcl"
      :visible="storeProjectModalVisible"
      @close="storeProjectModalVisible = false"
      @get-clients="handleGetClientsCompactList"
      @store-project="(newProject) => handleStoreProject(newProject)"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, computed, getCurrentInstance, onBeforeMount } from "vue";
import { apiGetProjects, apiStoreProject, apiUpdateProject } from "@/modules/projects/_utils/api";
import { ProfileInterface, BaseAclInterface } from "@/modules/authentication/types";
import { PaginationInterface, TableColumnInterface } from "@/common/types";
import { useBookmarkedFiltersStore } from "@/modules/bookmarked_filters/_store";
import { useAuthenticationStore } from "@/modules/authentication/_store";
import { MinimalClientInterface } from "@/modules/clients/types";
import { MinimalUserInterface } from "@/modules/users/types";
import AddProjectModal from "./AddProjectModal.vue";
import ProjectsFilter from "./ProjectsFilter.vue";
import ProjectsTable from "./ProjectsTable.vue";
import { getClientsCompactList } from "@/api";
import { downloadCsv } from "@/utils";
import {
  ProjectInterface,
  ProjectPayloadInterface,
  ProjectsFilterInterface,
} from "@/modules/projects/types";
import useMixin from "@/useMixin";

// Props
const props = defineProps({
  projectsColumns: { type: Array as () => Array<TableColumnInterface>, default: undefined },
  projectsFilter: { type: Object as () => ProjectsFilterInterface, default: undefined },
  acl: { type: Object as () => BaseAclInterface, default: undefined },
  allowFiltering: { type: Boolean, default: false },
  allowDownload: { type: Boolean, default: false },
  allowCreation: { type: Boolean, default: false },
  title: { type: String, default: "Projects" },
});

// Pinia
const authenticationStore = useAuthenticationStore();
const bookmarkedFiltersStore = useBookmarkedFiltersStore();
const profile = computed<ProfileInterface | undefined>(() => authenticationStore.profile);
const baseAcl = computed<BaseAclInterface>(() => authenticationStore.baseAcl);

// Mixins
const { qsDecode, syncRouteParams, persistTablePageSize, loadTablePageSize } = useMixin();

// Instances
const instance = getCurrentInstance();
const $route = instance?.proxy?.$route;
const $message = instance?.proxy?.$message;

// Data properties
const projectsPagination = ref<PaginationInterface | undefined>(undefined);
const clientsCompactList = ref<Array<MinimalClientInterface>>([]);
const usersCompactList = ref<Array<MinimalUserInterface>>([]);
const projectsPage = ref<Array<ProjectInterface>>([]);
const storeProjectModalVisible = ref<boolean>(false);
const filtersRef = ref<ProjectsFilterInterface>({});
const downloadingCsv = ref<boolean>(false);
const loadingTable = ref<boolean>(true);
const pageSizeRef = ref<number>(25);
const currentPage = ref<number>(1);
const sortingRef = ref<any>({});

// Lifecycle hooks
onBeforeMount(() => {
  bookmarkedFiltersStore.getUserBookmarkedFilters(profile.value?.id as number);
  getProjectsPagefromURL();
});

// Component methods
const getProjectsPagefromURL = (url: any = $route?.query): void => {
  const { page, filters, sorting, pageSize } = qsDecode(url);

  pageSizeRef.value = loadTablePageSize();

  if (page) currentPage.value = parseInt(page);
  if (filters) filtersRef.value = filters;
  if (sorting) sortingRef.value = sorting;
  if (pageSize) pageSizeRef.value = parseInt(pageSize);

  refreshProjects();
};

const getProjectsPage = ({
  page,
  pageSize = pageSizeRef.value,
  filters = filtersRef.value,
  sorting = sortingRef.value,
}: {
  page: number;
  pageSize?: number;
  filters?: any;
  sorting?: any;
}): void => {
  loadingTable.value = true;
  currentPage.value = page;
  pageSizeRef.value = pageSize;

  if (props.allowFiltering) {
    syncRouteParams(
      {
        page: currentPage.value,
        pageSize: pageSizeRef.value,
        filters: filtersRef.value,
        sorting: sortingRef.value,
      },
      instance
    );
  }
  persistTablePageSize(pageSize);

  apiGetProjects({
    page,
    page_size: pageSize,
    ...{ ...filters, ...props.projectsFilter },
    ...sorting,
  }).then((res: any) => {
    loadingTable.value = false;
    projectsPage.value = res.data.data;
    projectsPagination.value = res.data.meta;
  });
};

const applyFilters = (filters: any): void => {
  filtersRef.value = filters;
  currentPage.value = 1;

  syncRouteParams(
    {
      page: currentPage.value,
      pageSize: pageSizeRef.value,
      filters: filtersRef.value,
      sorting: sortingRef.value,
    },
    instance
  );
  refreshProjects();
};

const applySorting = (sorting: any): void => {
  sortingRef.value = sorting;

  syncRouteParams(
    {
      page: currentPage.value,
      pageSize: pageSizeRef.value,
      filters: filtersRef.value,
      sorting: sortingRef.value,
    },
    instance
  );
  refreshProjects();
};

const handleEditProjectModalVisible = (): void => {
  handleGetClientsCompactList();
};

const handleStoreProject = (newProject: ProjectPayloadInterface): void => {
  apiStoreProject(newProject)
    .then(() => refreshProjects())
    .then(() => $message?.success("Project created successfully!", 3))
    .catch(() => $message?.error("Couldn't create project!", 3));
};

const handleUpdateProject = (updatedProject: ProjectPayloadInterface): void => {
  if (updatedProject.id) {
    apiUpdateProject(updatedProject.id, updatedProject)
      .then(() => refreshProjects())
      .then(() => $message?.success("Project updated successfully!", 3))
      .catch(() => $message?.error("Couldn't update project!", 3));
  }
};

const handleDownloadCsv = (): void => {
  downloadingCsv.value = true;

  apiGetProjects(props.projectsFilter ?? filtersRef.value, { Accept: "text/csv" })
    .then((res: any) => downloadCsv(res.data, "projects", !!Object.keys(filtersRef.value).length))
    .catch(() => $message?.error("Couldn't download CSV file!", 3))
    .finally(() => (downloadingCsv.value = false));
};

const handleGetClientsCompactList = (): void => {
  getClientsCompactList().then((clients) => (clientsCompactList.value = clients));
};

const refreshProjects = (): void => {
  getProjectsPage({ page: currentPage.value });
};

defineExpose({ refreshProjects, currentPage });
</script>
