<template>
  <a-modal
    :visible="visible"
    :destroy-on-close="true"
    title="Edit Financial Info"
    ok-text="Save"
    @ok="handleUpdateUser"
    @cancel="$emit('close')"
  >
    <a-form layout="horizontal">
      <a-row v-if="acl.isAdmin || acl.isFinancial" type="flex" justify="space-between">
        <a-col :span="11">
          <a-form-item label="Employment type">
            <a-select v-model="newUser.employment_type" placeholder="Select an employment type">
              <a-select-option value="fixed">Fixed salary</a-select-option>
              <a-select-option value="variable">Variable salary</a-select-option>
              <a-select-option value="hourly">Hourly salary</a-select-option>
              <a-select-option value="subcontractor">Subcontractor</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="11">
          <a-form-item
            :validate-status="$validator.errors.first('vacation_percent') ? 'error' : ''"
            :help="$validator.errors.first('vacation_percent')"
            label="Vacation percent"
          >
            <a-input
              v-model="newUser.vacation_percent"
              v-validate="'decimal|min_value:0|max_value:100'"
              addon-after="%"
              data-vv-name="vacation_percent"
              data-vv-as="vacation percent"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row v-if="acl.isAdmin || acl.isFinancial" type="flex" justify="space-between">
        <a-col :span="11">
          <a-form-item
            v-if="newUser.employment_type === 'fixed'"
            :validate-status="$validator.errors.first('fixed_salary_start_date') ? 'error' : ''"
            :help="$validator.errors.first('fixed_salary_start_date')"
            label="Fixed salary start date"
          >
            <a-date-picker
              :value="parseDate(newUser.fixed_salary_start_date ?? undefined)"
              data-vv-name="fixed_salary_start_date"
              placeholder="Fixed salary start date"
              class="w-100"
              allow-clear
              @change="(date) => onDateChange('fixed_salary_start_date', date)"
            />
          </a-form-item>
          <a-form-item
            v-if="newUser.employment_type === 'fixed'"
            :validate-status="$validator.errors.first('salary') ? 'error' : ''"
            :help="$validator.errors.first('salary')"
            label="Salary"
          >
            <a-input
              v-model="newUser.salary"
              v-validate="'decimal|min_value:0|max_value:100000000'"
              addon-after="SEK"
              data-vv-name="salary"
              placeholder="Salary in SEK"
            />
          </a-form-item>
        </a-col>

        <a-col :span="11">
          <a-form-item
            :validate-status="$validator.errors.first('vacation_days') ? 'error' : ''"
            :help="$validator.errors.first('vacation_days')"
            label="Vacation days"
          >
            <a-input
              v-model="newUser.vacation_days"
              v-validate="'integer|min_value:0'"
              addon-after="days"
              data-vv-name="vacation_days"
              data-vv-as="vacation days"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row type="flex" justify="space-between">
        <a-col v-if="acl.isAdmin || acl.isFinancial" :span="11">
          <a-form-item
            :validate-status="$validator.errors.first('payroll_tax_percent') ? 'error' : ''"
            :help="$validator.errors.first('payroll_tax_percent')"
            label="Payroll tax"
          >
            <a-input
              v-model="newUser.payroll_tax_percent"
              v-validate="'decimal|min_value:0|max_value:100'"
              addon-after="%"
              data-vv-name="payroll_tax_percent"
              data-vv-as="payroll tax"
            />
          </a-form-item>
        </a-col>

        <a-col v-if="acl.isAdmin || acl.isFinancial" :span="11">
          <a-form-item
            :validate-status="$validator.errors.first('vacation_days_saved') ? 'error' : ''"
            :help="$validator.errors.first('vacation_days_saved')"
            label="Vacation days saved"
          >
            <a-input
              v-model="newUser.vacation_days_saved"
              v-validate="'integer|min_value:0'"
              addon-after="days"
              data-vv-name="vacation_days_saved"
              data-vv-as="vacation days saved"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row type="flex" justify="space-between">
        <a-col :span="11">
          <a-form-item
            :validate-status="$validator.errors.first('income_tax_percent') ? 'error' : ''"
            :help="$validator.errors.first('income_tax_percent')"
            label="Income tax"
          >
            <a-input
              v-model="newUser.income_tax_percent"
              v-validate="'decimal|min_value:0|max_value:100'"
              addon-after="%"
              data-vv-name="income_tax_percent"
              data-vv-as="income tax"
            />
          </a-form-item>
        </a-col>

        <a-col v-if="acl.isAdmin || acl.isFinancial" :span="11">
          <a-form-item
            :validate-status="$validator.errors.first('vacation_days_yearly') ? 'error' : ''"
            :help="$validator.errors.first('vacation_days_yearly')"
            label="Vacation days yearly"
          >
            <a-input
              v-model="newUser.vacation_days_yearly"
              v-validate="'integer|min_value:0'"
              addon-after="days"
              data-vv-name="vacation_days_yearly"
              data-vv-as="vacation days yearly"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row v-if="acl.isAdmin || acl.isFinancial" type="flex" justify="space-between">
        <a-col :span="11">
          <a-form-item
            :validate-status="$validator.errors.first('fortnox_cost_center_id') ? 'error' : ''"
            :help="$validator.errors.first('fortnox_cost_center_id')"
            label="Fortnox cost-center ID"
          >
            <a-input
              v-model="newUser.fortnox_cost_center_id"
              v-validate="'max:255'"
              data-vv-name="fortnox_cost_center_id"
              data-vv-as="Fortnox ID"
            />
          </a-form-item>
        </a-col>

        <a-col :span="11">
          <a-form-item
            :validate-status="$validator.errors.first('vacation_days_prepaid') ? 'error' : ''"
            :help="$validator.errors.first('vacation_days_prepaid')"
            label="Vacation days prepaid"
          >
            <a-input
              v-model="newUser.vacation_days_prepaid"
              v-validate="'integer|min_value:0'"
              addon-after="days"
              data-vv-name="vacation_days_prepaid"
              data-vv-as="vacation days prepaid"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row type="flex" justify="space-between">
        <a-col :span="11">
          <a-form-item
            :validate-status="$validator.errors.first('bank') ? 'error' : ''"
            :help="$validator.errors.first('bank')"
            label="Bank"
          >
            <a-input
              v-model="newUser.bank"
              v-validate="'max:255'"
              data-vv-name="bank"
              data-vv-as="bank"
            />
          </a-form-item>
        </a-col>

        <a-col :span="11">
          <a-form-item
            :validate-status="$validator.errors.first('salary_account_number') ? 'error' : ''"
            :help="$validator.errors.first('salary_account_number')"
            label="Salary account number"
          >
            <a-input
              v-model="newUser.salary_account_number"
              v-validate="'max:255'"
              data-vv-name="salary_account_number"
              data-vv-as="salary account number"
            />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script setup lang="ts">
import { getCurrentInstance, ref, watch } from "vue";
import { Moment } from "moment";
import {
  ProfileInterface,
  BaseAclInterface,
  EmploymentTypes,
} from "@/modules/authentication/types";
import useMixin from "@/useMixin";
import useFilters from "@/useFilters";

const { parseDate } = useFilters();

// Props
const props = defineProps({
  acl: { type: Object as () => BaseAclInterface, default: undefined },
  user: { type: Object as () => ProfileInterface, default: undefined },
  visible: { type: Boolean, default: false },
});

// Emits
const emits = defineEmits(["update-user", "close"]);

// Mixins
const { setObject } = useMixin();

// Instances
const instance = getCurrentInstance();
const $validator = instance?.proxy.$validator;

type NewUser = Pick<
  ProfileInterface,
  | "vacation_percent"
  | "employment_type"
  | "salary"
  | "fixed_salary_start_date"
  | "income_tax_percent"
  | "payroll_tax_percent"
  | "fortnox_cost_center_id"
  | "bank"
  | "salary_account_number"
  | "vacation_days"
  | "vacation_days_saved"
  | "vacation_days_yearly"
  | "vacation_days_prepaid"
>;
// Data Properties
const newUser = ref<Partial<NewUser>>({
  vacation_percent: undefined,
  employment_type: undefined,
  salary: undefined,
  fixed_salary_start_date: undefined,
  income_tax_percent: undefined,
  payroll_tax_percent: undefined,
  fortnox_cost_center_id: undefined,
  bank: undefined,
  salary_account_number: undefined,
  vacation_days: undefined,
  vacation_days_saved: undefined,
  vacation_days_yearly: undefined,
  vacation_days_prepaid: undefined,
});

// Watchers
watch(
  () => props.visible,
  (newProp, oldProp) => {
    if (newProp && !oldProp) {
      // Set fields to financial tab current values
      newUser.value = setObject(newUser.value, props.user);

      castFieldToPercentage("income_tax_percent");
      castFieldToPercentage("payroll_tax_percent");
      castFieldToPercentage("vacation_percent");

      // Default salary model to variable if not set.
      if (newUser.value.employment_type === undefined) {
        newUser.value.employment_type = EmploymentTypes.VARIABLE;
      }
    }
  }
);

// Component methods
const handleUpdateUser = (): void => {
  $validator?.validateAll().then((result) => {
    if (result) {
      castFieldToDecimal("income_tax_percent");
      castFieldToDecimal("payroll_tax_percent");
      castFieldToDecimal("vacation_percent");

      emits("update-user", newUser.value);
      emits("close");
    }
  });
};

const castFieldToPercentage = (field: keyof NewUser): void => {
  if (newUser.value[field]) {
    newUser.value[field] = Math.round(newUser.value[field] * 10000) / 100;
  } else {
    newUser.value[field] = 0.0;
  }
};

const castFieldToDecimal = (field: keyof NewUser): void => {
  if (newUser.value[field]) {
    newUser.value[field] = newUser.value[field] / 100;
  } else {
    newUser.value[field] = 0.0;
  }
};

function onDateChange(field: keyof NewUser, date: Moment | null) {
  newUser.value[field] = date ? date.format("YYYY-MM-DD") : null;
}
</script>
