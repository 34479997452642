<template>
  <a-layout-content class="my-4 mx-3">
    <div class="container">
      <!-- HEADER -->
      <div class="d-flex justify-content-between">
        <h2>Quiz</h2>
        <div class="d-flex">
          <portal-target name="quiz-filter-buttons"></portal-target>
          <a-button
            v-if="baseAcl.isAdmin"
            type="primary"
            icon="plus"
            @click="storeQuestionModalVisible = true"
          />
        </div>
      </div>

      <!-- QUESTIONS FILTER -->
      <quiz-filter
        :number-of-results="questionsPagination?.total"
        :filters="filtersRef"
        @apply-filters="applyFilters"
      />

      <!-- QUESTIONS TABLE -->
      <div class="card">
        <quiz-table
          :questions-page="questionsPage"
          :current-page="currentPage"
          :loading-table="loadingTable"
          :questions-pagination="questionsPagination"
          :page-size="pageSizeRef"
          :quiz-tags="quizTags"
          @get-quiz-tags="handleGetQuizTags"
          @apply-sorting="(newSorting) => applySorting(newSorting)"
          @get-questions-page="(params) => getQuestionsPage(params)"
          @update-question="
            (questionId, updatedQuestion) => handleUpdateQuestion(questionId, updatedQuestion)
          "
          @destroy-question="(questionId) => handleDestroyQuestion(questionId)"
          @submit-answer="
            (questionId, quizSubmission) => handleSubmitAnswer(questionId, quizSubmission)
          "
          @update-answer="
            (questionId, userId, quizSubmission) =>
              handleUpdateAnswer(questionId, userId, quizSubmission)
          "
        />
      </div>

      <!-- CREATE NEW QUESTION MODAL -->
      <add-question-modal
        :visible="storeQuestionModalVisible"
        :quiz-tags="quizTags"
        @close="storeQuestionModalVisible = false"
        @store-question="(newQuestion) => handleStoreQuestion(newQuestion)"
        @get-quiz-tags="handleGetQuizTags"
      />
    </div>
  </a-layout-content>
</template>

<script setup lang="ts">
import { ref, getCurrentInstance, computed, onBeforeMount } from "vue";
import AddQuestionModal from "../_components/AddQuestionModal.vue";
import { BaseAclInterface } from "@/modules/authentication/types";
import { useAuthenticationStore } from "@/modules/authentication/_store";
import { apiGetQuizTags } from "@/modules/quiz_tags/_utils/api";
import { QuizTagInterface } from "@/modules/quiz_tags/types";
import QuizFilter from "../_components/QuizFilter.vue";
import QuizTable from "../_components/QuizTable.vue";
import { PaginationInterface } from "@/common/types";
import useMixin from "@/useMixin";
import {
  QuestionInterface,
  QuestionPayloadInterface,
  QuestionsFilterInterface,
  SubmissionPayloadInterface,
} from "../types";
import {
  apiGetQuestions,
  apiSubmitAnswer,
  apiUpdateAnswer,
  apiStoreQuestion,
  apiDestroyQuestion,
  apiUpdateQuestion,
} from "../_utils/api";

// Pinia
const authenticationStore = useAuthenticationStore();
const baseAcl = computed<BaseAclInterface>(() => authenticationStore.baseAcl);

// Mixins
const { qsDecode, loadTablePageSize, syncRouteParams, persistTablePageSize } = useMixin();

// Instances
const instance = getCurrentInstance();
const $route = instance?.proxy?.$route;
const $message = instance?.proxy?.$message;

// Data properties
const questionsPagination = ref<PaginationInterface | undefined>(undefined);
const questionsPage = ref<Array<QuestionInterface>>([]);
const storeQuestionModalVisible = ref<boolean>(false);
const quizTags = ref<Array<QuizTagInterface>>([]);
const filtersRef = ref<QuestionsFilterInterface>({});
const loadingTable = ref<boolean>(false);
const currentPage = ref<number>(1);
const pageSizeRef = ref<number>(25);
const sortingRef = ref<object>({});

// Lifecycle hooks
onBeforeMount(() => {
  getQuestionsPagefromURL();
});

// Component methods
const handleSubmitAnswer = (questionId: number, quizSubmission: SubmissionPayloadInterface) => {
  apiSubmitAnswer(questionId, quizSubmission)
    .then((res: any) => {
      getQuestionsPage({ page: currentPage.value });
      if (res.data.data.status === "correct") {
        $message?.success("Answer was correct!", 3);
      } else {
        $message?.warning("Answer was incorrect!", 3);
      }
    })
    .catch(() => $message?.error("Couldn't submit answer!", 3));
};

const handleUpdateAnswer = (
  questionId: number,
  userId: number,
  quizSubmission: SubmissionPayloadInterface
) => {
  apiUpdateAnswer(questionId, userId, quizSubmission)
    .then((res: any) => {
      getQuestionsPage({ page: currentPage.value });
      if (res.data.data.status === "correct") {
        $message?.success("Answer was correct!", 3);
      } else {
        $message?.warning("Answer was incorrect!", 3);
      }
    })
    .catch(() => $message?.error("Couldn't submit answer!", 3));
};

const getQuestionsPagefromURL = (url: any = $route?.query): void => {
  const { page, filters, sorting, pageSize } = qsDecode(url);

  pageSizeRef.value = loadTablePageSize();

  if (page) currentPage.value = parseInt(page);
  if (filters) filtersRef.value = filters;
  if (sorting) sortingRef.value = sorting;
  if (pageSize) pageSizeRef.value = parseInt(pageSize);

  getQuestionsPage({ page: currentPage.value });
};

const getQuestionsPage = ({
  page,
  pageSize = pageSizeRef.value,
  filters = filtersRef.value,
  sorting = sortingRef.value,
}: {
  page: number;
  pageSize?: number;
  filters?: object;
  sorting?: object;
}) => {
  loadingTable.value = true;
  currentPage.value = page;
  pageSizeRef.value = pageSize;

  syncRouteParams(
    {
      page: currentPage.value,
      pageSize: pageSizeRef.value,
      filters: filtersRef.value,
      sorting: sortingRef.value,
    },
    instance
  );
  persistTablePageSize(pageSize);

  apiGetQuestions({ page, page_size: pageSize, ...filters, ...sorting })
    .then(({ data }: any) => {
      currentPage.value = page;
      questionsPage.value = data.data;
      questionsPagination.value = data.meta;
    })
    .then(() => (loadingTable.value = false));
};

const handleStoreQuestion = (newQuestion: QuestionPayloadInterface): void => {
  apiStoreQuestion(newQuestion)
    .then(() => getQuestionsPage({ page: currentPage.value }))
    .then(() => $message?.success("Question added successfully!", 3))
    .catch(() => $message?.error("Couldn't add question!", 3));
};

const handleDestroyQuestion = (questionId: number): void => {
  apiDestroyQuestion(questionId)
    .then(() => getQuestionsPage({ page: currentPage.value }))
    .then(() => $message?.success("Question deleted successfully", 3))
    .catch(() => $message?.error("Couldn't delete question", 3));
};

const handleUpdateQuestion = (
  questionId: number,
  updatedQuestion: QuestionPayloadInterface
): void => {
  apiUpdateQuestion(questionId, updatedQuestion)
    .then(() => {
      getQuestionsPage({ page: currentPage.value });
      $message?.success("Question updated successfully!", 3);
    })
    .catch(() => {
      $message?.error("Couldn't update question!", 3);
    });
};

const handleGetQuizTags = (): void => {
  apiGetQuizTags().then((res: any) => (quizTags.value = res.data.data));
};

const applyFilters = (filters: any): void => {
  filtersRef.value = filters;
  currentPage.value = 1;

  syncRouteParams(
    {
      page: currentPage.value,
      pageSize: pageSizeRef.value,
      filters: filtersRef.value,
      sorting: sortingRef.value,
    },
    instance
  );
  getQuestionsPage({ page: currentPage.value, filters: filters });
};

const applySorting = (sorting: object): void => {
  sortingRef.value = sorting;
  syncRouteParams(
    {
      page: currentPage.value,
      pageSize: pageSizeRef.value,
      filters: filtersRef.value,
      sorting: sortingRef.value,
    },
    instance
  );
  getQuestionsPage({ page: currentPage.value });
};
</script>
