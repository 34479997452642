<template>
  <div>
    <a-row type="flex" justify="end" class="mb-2">
      <a-button type="primary" @click="addKnowledgeDomainModalVisible = true"
        >Add Knowledge Domain</a-button
      >
    </a-row>
    <a-row v-for="knowledgeDomain in knowledgeDomains" :key="knowledgeDomain.id">
      <a-card class="mb-2">
        <!-- Title and Actions -->
        <a-row class="mb-2" type="flex" justify="space-between" align="middle">
          <h3>{{ knowledgeDomain.title }}</h3>

          <div>
            <a-icon
              type="edit"
              class="action-default"
              @click.stop="handleEditKnowledgeDomain(knowledgeDomain)"
            />
            <a-icon
              type="delete"
              class="ml-2 action-danger"
              @click.stop="confirmDeleteKnowledgeDomain(knowledgeDomain)"
            />
          </div>
        </a-row>

        <a-row type="flex" class="mb-3">
          <!-- Knowledge Domain Levels column -->
          <a-col :span="4" class="mr-1">
            <div class="level">Productive</div>
          </a-col>
          <a-col :span="18" :push="1" class="description-col">
            <span>{{ knowledgeDomain.description_productive }}</span>
          </a-col>
        </a-row>

        <a-row v-if="knowledgeDomain.description_advanced" type="flex" class="mb-3">
          <!-- Knowledge Domain Description column -->
          <a-col :span="4" class="mr-1">
            <div class="level">Advanced</div>
          </a-col>
          <a-col :span="18" :push="1" class="description-col">
            <span>{{ knowledgeDomain.description_advanced }}</span>
          </a-col>
        </a-row>

        <a-row v-if="knowledgeDomain.description_expert" type="flex">
          <a-col :span="4" class="mr-1">
            <div class="level">Expert</div>
          </a-col>
          <a-col :span="18" :push="1" class="description-col">
            <span>{{ knowledgeDomain.description_expert }}</span>
          </a-col>
        </a-row>

        <!-- Show/Hide Skills -->
        <a-row type="flex" justify="end">
          <a
            v-if="!showSkills.includes(knowledgeDomain.id)"
            @click="showSkills.push(knowledgeDomain.id)"
            >Show skills</a
          >
          <a v-else @click="showSkills.splice(showSkills.indexOf(knowledgeDomain.id), 1)"
            >Hide skills</a
          >
        </a-row>

        <a-row v-if="showSkills.includes(knowledgeDomain.id)" type="flex" justify="end">
          <!-- Knowledge domain Skills -->
          <a-col :span="22">
            <a-row v-if="knowledgeDomain.skills.length === 0" class="text-center">
              <em>This knowledge domain has no connected skills.</em>
            </a-row>
            <a-row class="text-right">
              <a-popconfirm
                v-for="skill in knowledgeDomain.skills"
                :key="skill.id"
                title="Remove skill?"
                ok-text="Yes"
                ok-type="danger"
                cancel-text="No"
                class="mb-2"
                @confirm="removeSkill(skill.id, knowledgeDomain.id)"
              >
                <template #icon>
                  <a-icon type="delete" style="color: red" />
                </template>
                <a-tag>{{ skill.title }}</a-tag>
              </a-popconfirm>
            </a-row>
          </a-col>

          <!-- Add Skill -->
          <a-col :span="2" class="text-right">
            <a-popover title="Add a skill" trigger="click" placement="topLeft">
              <template #content>
                <a-row>
                  <a-select
                    v-model="skillId"
                    show-search
                    option-filter-prop="children"
                    placeholder="Select a skill"
                    class="mr-2"
                  >
                    <a-select-option
                      v-for="skill in skills.filter(
                        (s) => !knowledgeDomain.skills.find((kds) => kds.skill_id === s.id)
                      )"
                      :key="skill.id"
                      :value="skill.id"
                      >{{ skill.title }}</a-select-option
                    >
                  </a-select>
                  <a-button
                    :disabled="!skillId"
                    type="primary"
                    @click="addSkill(knowledgeDomain.id)"
                    >Add</a-button
                  >
                </a-row>
              </template>

              <a>Add Skill</a>
            </a-popover>
          </a-col>
        </a-row>
      </a-card>
    </a-row>

    <add-knowledge-domain-modal
      :visible="addKnowledgeDomainModalVisible"
      @close="addKnowledgeDomainModalVisible = false"
      @store-knowledge-domain="
        (newKnowledgeDomain) => $emit('store-knowledge-domain', newKnowledgeDomain)
      "
    />
    <edit-knowledge-domain-modal
      :visible="editKnowledgeDomainModalVisible"
      :knowledge-domain="editKnowledgeDomain"
      @close="editKnowledgeDomainModalVisible = false"
      @update-knowledge-domain="
        (updatedKnowledgeDomain) => $emit('update-knowledge-domain', updatedKnowledgeDomain)
      "
    />
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import AddKnowledgeDomainModal from "./AddKnowledgeDomainModal.vue";
import EditKnowledgeDomainModal from "./EditKnowledgeDomainModal.vue";

// Props
const props = defineProps({
  knowledgeDomains: { type: Array as () => Array<any>, default: () => [] },
  skills: { type: Array as () => Array<any>, default: () => [] },
});

// Emits
const emits = defineEmits([
  "destroy-knowledge-domain",
  "store-knowledge-domain-skill",
  "destroy-knowledge-domain-skill",
]);

// Data Properties
const editKnowledgeDomain = ref<any>(null);
const editKnowledgeDomainModalVisible = ref<boolean>(false);
const addKnowledgeDomainModalVisible = ref<boolean>(false);
const showSkills = ref<Array<any>>([]);
const skillId = ref<number | null>(null);

// Class Methods
const handleEditKnowledgeDomain = (knowledgeDomain: any) => {
  editKnowledgeDomain.value = knowledgeDomain;
  editKnowledgeDomainModalVisible.value = true;
};

const confirmDeleteKnowledgeDomain = (course: any) => {
  const mod = window.confirm("Are you sure you want to delete this knowledge domain?");
  if (mod) {
    emits("destroy-knowledge-domain", course.id);
  }
};

const addSkill = (knowledgeDomainId: number) => {
  emits("store-knowledge-domain-skill", {
    skillId: skillId.value,
    knowledgeDomainId: knowledgeDomainId,
  });
  skillId.value = null;
};

const removeSkill = (skillId: number, knowledgeDomainId: number) => {
  emits("destroy-knowledge-domain-skill", knowledgeDomainId, skillId);
};
</script>

<style scoped>
.level {
  background-color: #e9f1fe;
  border-radius: 20px;
  padding: 0.35rem;
  text-align: center;
}
.description-col {
  border-left: 1px lightGrey solid;
  padding-left: 0.9rem;
  word-wrap: break-word;
}
.text-right {
  text-align: end;
}
.ant-select {
  min-width: 120px !important;
}
</style>
