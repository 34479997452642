<template>
  <div>
    <div v-if="skpOverview" class="card mb-3">
      <a-row type="flex">
        <a-col :span="8">
          <h4>Remaining this quarter</h4>
          <span>{{ toMoney(skpOverview.available_amount) }}</span>
        </a-col>

        <a-col :span="8">
          <h4>Earned for next quarter</h4>
          <span>{{ toMoney(skpOverview.pending_amount) }}</span>
        </a-col>

        <a-col v-if="profile.employment_type === 'variable'" :span="8">
          <h4>Available vacation</h4>
          <span>{{ toMoney(skpOverview.vacation_amount) }}</span>
        </a-col>
      </a-row>

      <a-list :data-source="skpOverview.year_overview" item-layout="horizontal" class="mt-5">
        <template #renderItem="item">
          <a-list-item>
            <a-list-item-meta>
              <template #title>
                <span>{{ item.year }}</span>
              </template>
            </a-list-item-meta>
            <a-list-item-meta :description="toMoney(item.paid_out)">
              <template #title>
                <span>Paid out</span>
              </template>
            </a-list-item-meta>
            <a-list-item-meta :description="toMoney(item.salary)">
              <template #title>
                <span>Gross salary</span>
              </template>
            </a-list-item-meta>
            <a-list-item-meta :description="toMoney(item.income_tax)">
              <template #title>
                <span>Income tax</span>
              </template>
            </a-list-item-meta>
          </a-list-item>
        </template>
      </a-list>
    </div>

    <div v-if="skpOverview && profile.employment_type === 'fixed'" class="card">
      <a-divider orientation="left">Vacation days</a-divider>
      <a-list :data-source="vacationSummary" :split="false" item-layout="horizontal">
        <template #renderItem="item">
          <a-list-item>
            <a-list-item-meta>
              <template #title>
                <span>{{ item.year }}</span>
              </template>
            </a-list-item-meta>
            <a-list-item-meta :description="`${item.nb_vacation_days}`">
              <template #title>
                <span>Reported vacation days</span>
              </template>
            </a-list-item-meta>
            <a-list-item-meta :description="`${item.nb_unpaid_vacation_days}`">
              <template #title>
                <span>Reported unpaid vacation days</span>
              </template>
            </a-list-item-meta>
            <a-list-item-meta
              :description="item.year === currentYear() ? getRemainingVacationDaysCount() : ''"
            >
              <template #title>
                <span>
                  {{ item.year === currentYear() ? "Remaining vacation days" : "" }}
                </span>
              </template>
            </a-list-item-meta>
          </a-list-item>
        </template>
      </a-list>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onBeforeMount, ref } from "vue";
import { apiGetVacationSummary } from "@/modules/users/_utils/api";
import { ProfileInterface } from "../../authentication/types";
import useMixin from "@/useMixin";

interface YearlyVacationInformation {
  year: string;
  nb_vacation_days: number;
  nb_unpaid_vacation_days: number;
}

// Props
const props = defineProps({
  profile: { type: Object as () => ProfileInterface, default: undefined },
  skpOverview: { type: Object, default: undefined },
});

// Mixins
const { toMoney } = useMixin();

// Data properties
const vacationSummary = ref<Array<YearlyVacationInformation>>([]);

// Lifecycle hooks
onBeforeMount(() => {
  apiGetVacationSummary(props.profile.id).then((res: any) => (vacationSummary.value = res.data));
});

// Class methods
const currentYear = (): number => {
  return new Date().getFullYear();
};

const getRemainingVacationDaysCount = (): string => {
  return `${props.profile.vacation_days} ${
    props.profile.vacation_days_saved ? `(+ ${props.profile.vacation_days_saved} saved)` : ""
  }`;
};
</script>
