<template>
  <a-modal
    :visible="visible"
    title="Add Knowledge Domain"
    ok-text="Add"
    @cancel="$emit('close')"
    @ok="storeKnowledgeDomain"
  >
    <a-row v-if="validationError">
      <p class="text-red">{{ validationError }}</p>
    </a-row>

    <p v-if="allKnowledgeDomains.length === 0">No more knowledge domains to add</p>

    <a-row v-for="knowledgeDomain in allKnowledgeDomains" :key="knowledgeDomain.id" class="mb-2">
      <a-card
        :class="`cursor-pointer  ${
          selectedKnowledgeDomain
            ? knowledgeDomain.id === selectedKnowledgeDomain.id
              ? 'selected'
              : ''
            : ''
        }`"
        type="inner"
        @click="setSelected(knowledgeDomain)"
      >
        <a-row type="flex" justify="space-between" class="mb-2">
          <a-col>
            <h4>{{ knowledgeDomain.title }}</h4>
          </a-col>
          <a-select v-model="knowledgeDomain.level">
            <a-select-option value="productive">Productive</a-select-option>
            <a-select-option value="advanced">Advanced</a-select-option>
            <a-select-option value="expert">Expert</a-select-option>
          </a-select>
        </a-row>
        <a-row
          v-show="selectedKnowledgeDomain && knowledgeDomain.id === selectedKnowledgeDomain.id"
        >
          <p>{{ knowledgeDomain[`description_${knowledgeDomain.level}`] }}</p>
        </a-row>
      </a-card>
    </a-row>
  </a-modal>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { KnowledgeDomainInterface } from "../../consultant_profiles/types";
import { ProfileInterface } from "../../authentication/types";

// Props
const props = defineProps({
  knowledgeDomains: { type: Array as () => Array<KnowledgeDomainInterface>, default: () => [] },
  visible: { type: Boolean, default: false },
  user: { type: Object as () => ProfileInterface, default: undefined },
});

// Emits
const emits = defineEmits(["store-knowledge-domain"]);

// Data Properties
const selectedKnowledgeDomain = ref<KnowledgeDomainInterface | null>(null);
const validationError = ref<string | null>(null);

// Computed Properties
const allKnowledgeDomains = computed((): Array<KnowledgeDomainInterface> => {
  const allKnowledgeDomains: Array<KnowledgeDomainInterface> = [];
  props.knowledgeDomains.forEach((kd: KnowledgeDomainInterface) => {
    if (!props.user.knowledge_domains.find((userKd: any) => userKd.knowledge_domain.id === kd.id)) {
      allKnowledgeDomains.push({
        ...kd,
        level: "productive",
      });
    }
  });
  return allKnowledgeDomains;
});

// Component Methods
const setSelected = (knowledgeDomain: KnowledgeDomainInterface): void => {
  selectedKnowledgeDomain.value = knowledgeDomain;
};

const storeKnowledgeDomain = (): void => {
  if (selectedKnowledgeDomain.value) {
    validationError.value = null;
    emits("store-knowledge-domain", {
      knowledge_domain_id: selectedKnowledgeDomain.value.id,
      level: selectedKnowledgeDomain.value.level,
    });
  } else {
    validationError.value = "A knowledge domain needs to be selected.";
  }
};
</script>

<style scoped>
.selected {
  border-color: #1890ff;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
