import moment from "moment";

moment.updateLocale("en", {
  relativeTime: {
    d: "1 day",
  },
  week: {
    dow: 1,
    doy: 1,
  },
});

export const RFC_3339: string = "YYYY-MM-DD HH:mm Z";

export default moment;
