<template>
  <a-layout-content class="my-4 mx-3">
    <div class="container">
      <h2>SKP</h2>

      <a-tabs default-active-key="overview" @change="tabChange">
        <a-tab-pane key="overview" tab="Overview">
          <SkpOverview :skp-overview="skpOverview" :profile="profile" />
        </a-tab-pane>

        <a-tab-pane key="list" tab="Transactions">
          <skp-list
            v-if="transactionsPage"
            :transactions-page="transactionsPage"
            :transactions-pagination="transactionsPagination"
            :current-page="currentPage"
            :page-size="pageSizeRef"
            :loading-table="loadingTable"
            :loading-csv="downloadingCsv"
            :profile="profile"
            @apply-sorting="(newSorting) => applySorting(newSorting)"
            @get-transactions="(params) => handleGetTransactionsPage(params)"
            @apply-filters="(newFilters) => applyFilters(newFilters)"
            @download-csv="handleDownloadCsv"
          />
        </a-tab-pane>

        <a-tab-pane
          v-if="profile?.employment_type === 'fixed'"
          key="vacation_days"
          tab="Vacaction Days"
        >
          <VacationDaysOverview :profile="profile" />
        </a-tab-pane>
      </a-tabs>
    </div>
  </a-layout-content>
</template>

<script setup lang="ts">
import { ref, onBeforeMount, computed, getCurrentInstance } from "vue";
import { apiGetUserTransactions, apiGetSkpOverview } from "../_utils/api";
import { ProfileInterface } from "@/modules/authentication/types";
import { PaginationInterface } from "@/common/types";
import SkpList from "../_components/SkpList.vue";
import SkpOverview from "../_components/SkpOverview.vue";
import VacationDaysOverview from "../_components/VacationDaysOverview.vue";
import { downloadCsv } from "@/utils";
import useMixin from "@/useMixin";
import { useAuthenticationStore } from "@/modules/authentication/_store";

// Pinia
const authenticationStore = useAuthenticationStore();
const profile = computed<ProfileInterface | undefined>(() => authenticationStore.profile);

// Mixins
const { qsDecode, loadTablePageSize, syncRouteParams, persistTablePageSize } = useMixin();

// Instances
const instance = getCurrentInstance();
const $route = instance?.proxy.$route;
const $router = instance?.proxy.$router;
const $message = instance?.proxy.$message;

// Data properties
const transactionsPagination = ref<PaginationInterface | undefined>(undefined);
const skpOverview = ref<object | undefined>(undefined);
const transactionsPage = ref<Array<any>>([]);
const downloadingCsv = ref<boolean>(false);
const loadingTable = ref<boolean>(false);
const pageSizeRef = ref<number>(25);
const currentPage = ref<number>(1);
const filtersRef = ref<object>({});
const sortingRef = ref<object>({});
const listParams = ref<any>(null);

// Lifecycle hooks
onBeforeMount(() => {
  apiGetSkpOverview(profile.value?.id as number).then(
    ({ data }: any) => (skpOverview.value = data.data)
  );
});

// Component methods
const tabChange = (key: string): void => {
  if (key === "overview") {
    listParams.value = $route?.params;
    $router?.replace($route?.path as string);
  } else if (key === "list") {
    const { page, filters, sorting, pageSize } = qsDecode(
      listParams.value ? listParams.value : $route?.query
    );
    pageSizeRef.value = loadTablePageSize();

    if (page) currentPage.value = parseInt(page);
    if (filters) filtersRef.value = filters;
    if (sorting) sortingRef.value = sorting;
    if (pageSize) pageSizeRef.value = parseInt(pageSize);

    handleGetTransactionsPage({ page: currentPage.value });
  }
};

const handleGetTransactionsPage = ({
  page,
  pageSize = pageSizeRef.value,
  filters = filtersRef.value,
  sorting = sortingRef.value,
}: {
  page: any;
  pageSize?: number;
  filters?: object;
  sorting?: object;
}): void => {
  loadingTable.value = true;
  currentPage.value = page;
  pageSizeRef.value = pageSize;

  syncRouteParams(
    {
      page: currentPage.value,
      pageSize: pageSizeRef.value,
      filters: filtersRef.value,
      sorting: sortingRef.value,
    },
    instance
  );
  persistTablePageSize(pageSize);

  apiGetUserTransactions(profile.value?.id as number, {
    page,
    page_size: pageSize,
    ...filters,
    ...sorting,
  })
    .then(({ data }: any) => {
      currentPage.value = page;
      transactionsPage.value = data.data;
      transactionsPagination.value = data.meta;
    })
    .then(() => (loadingTable.value = false));
};

const applyFilters = (filters: object): void => {
  filtersRef.value = filters;
  currentPage.value = 1;
  syncRouteParams(
    {
      page: currentPage.value,
      pageSize: pageSizeRef.value,
      filters: filtersRef.value,
      sorting: sortingRef.value,
    },
    instance
  );
  handleGetTransactionsPage({ page: currentPage.value });
};

const applySorting = (sorting: object): void => {
  sortingRef.value = sorting;
  syncRouteParams(
    {
      page: currentPage.value,
      pageSize: pageSizeRef.value,
      filters: filtersRef.value,
      sorting: sortingRef.value,
    },
    instance
  );
  handleGetTransactionsPage({ page: currentPage.value });
};

const handleDownloadCsv = () => {
  downloadingCsv.value = true;

  apiGetUserTransactions(profile.value?.id as number, filtersRef.value, {
    Accept: "text/csv",
  })
    .then((res: any) =>
      downloadCsv(
        res.data,
        `user_${profile.value?.id}_transactions`,
        !!Object.keys(filtersRef.value).length
      )
    )
    .catch((err: any) => $message?.error("Couldn't download CSV file!", 3))
    .finally(() => (downloadingCsv.value = false));
};
</script>
