import { TableColumnInterface } from "@/common/types";

export const membersColumns: Array<TableColumnInterface> = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    scopedSlots: { customRender: "name" },
  },
  {
    title: "Email Address",
    dataIndex: "email",
    key: "email",
  },
];
