import axios from "@/network";
import { BudgetPayloadInterface } from "../types";

export function apiGetProjectBudgets(params: any, headers = {}) {
  const url = `/projects/${params.project_id}/budgets`;
  return axios.get(url, { params, headers });
}

export function apiGetProjectBudget(projectId: number, budgetId: number) {
  const url = `/projects/${projectId}/budgets/${budgetId}`;
  return axios.get(url);
}

// Gets all reports that can be added to an budget.
export function apiCreateProjectBudget(projectId: number) {
  const url = `/projects/${projectId}/budgets/new`;
  return axios.get(url);
}

export function apiStoreProjectBudget(newBudget: BudgetPayloadInterface) {
  const url = `/projects/${newBudget.project_id}/budgets`;
  const payload = { budget: newBudget };
  return axios.post(url, payload);
}

export function apiUpdateProjectBudget(updatedBudget: BudgetPayloadInterface) {
  const url = `/projects/${updatedBudget.project_id}/budgets/${updatedBudget.id}`;
  return axios.patch(url, updatedBudget);
}

export function apiDestroyProjectBudget(projectId: number, budgetId: number) {
  const url = `/projects/${projectId}/budgets/${budgetId}`;
  return axios.delete(url);
}

export function apiGetProjectCostCenterReport(projectId: number) {
  const url = `/projects/${projectId}/cost_centers_report`;
  return axios.get(url);
}
