import moment from "@/date";
import { TableColumnInterface } from "@/common/types";
import { RoleInterface } from "../types";

const userColumn: TableColumnInterface = {
  title: "User",
  dataIndex: "user.name",
  key: "user",
  width: 180,
  scopedSlots: { customRender: "user" },
};

const titleColumn: TableColumnInterface = {
  title: "Title",
  dataIndex: "title",
  key: "role",
};

const projectColumn: TableColumnInterface = {
  title: "Project",
  dataIndex: "project.name",
  scopedSlots: { customRender: "project" },
  key: "project",
};

const rateColumn: TableColumnInterface = {
  title: "Rate",
  dataIndex: "hourly_rate",
  key: "hourly_rate",
  scopedSlots: { customRender: "rate" },
};

const workloadColumn: TableColumnInterface = {
  title: "Workload",
  dataIndex: "workload",
  key: "workload",
  width: 90,
  scopedSlots: { customRender: "workload" },
  sorter: true,
};

const descriptionStatusColumn: TableColumnInterface = {
  title: "Description status",
  dataIndex: "approved_description",
  key: "descriptionStatus",
  width: 120,
  scopedSlots: { customRender: "descriptionStatus" },
  align: "center",
};

const extensionStatusColumn: TableColumnInterface = {
  title: "Extension status",
  key: "extensionStatus",
  width: 130,
  scopedSlots: { customRender: "extensionStatus" },
  align: "center",
};

const startDateColumn: TableColumnInterface = {
  title: "Start date",
  dataIndex: "start_date",
  key: "start_date",
  width: 120,
  sorter: (a: RoleInterface, b: RoleInterface) =>
    +moment(a.start_date || undefined) - +moment(b.start_date || undefined),
};

const endDateColumn: TableColumnInterface = {
  title: "End date",
  dataIndex: "end_date",
  key: "end_date",
  width: 120,
  sorter: (a: RoleInterface, b: RoleInterface) =>
    +moment(a.end_date || undefined) - +moment(b.end_date || undefined),
};

const actionsColumn: TableColumnInterface = {
  title: "Actions",
  key: "actions",
  width: 90,
  scopedSlots: { customRender: "actions" },
};

const roleColumn: TableColumnInterface = {
  title: "Role",
  dataIndex: "title",
  key: "role",
  scopedSlots: { customRender: "role" },
};

const valueColumn: TableColumnInterface = {
  title: "Value",
  dataIndex: "cost",
  key: "value",
  scopedSlots: { customRender: "value" },
};

export const rolesColumns: Array<TableColumnInterface> = [
  userColumn,
  titleColumn,
  projectColumn,
  workloadColumn,
  descriptionStatusColumn,
  extensionStatusColumn,
  startDateColumn,
  endDateColumn,
];

export const myRolesColumns: Array<TableColumnInterface> = [
  titleColumn,
  projectColumn,
  rateColumn,
  workloadColumn,
  descriptionStatusColumn,
  extensionStatusColumn,
  startDateColumn,
  endDateColumn,
];

export const projectRolesColumns: Array<TableColumnInterface> = [
  userColumn,
  titleColumn,
  rateColumn,
  workloadColumn,
  startDateColumn,
  endDateColumn,
  actionsColumn,
];

export const outerInvoiceRolesColumns: Array<TableColumnInterface> = [
  roleColumn,
  {
    title: "Logged time",
    dataIndex: "total_reports_hours",
    key: "total_role_hours",
    scopedSlots: { customRender: "total_role_hours" },
  },
  {
    title: "Value",
    dataIndex: "total_reports_amount",
    key: "total_value",
    scopedSlots: { customRender: "total_value" },
  },
];

export const innerInvoiceRolesColumns: Array<TableColumnInterface> = [
  startDateColumn,
  {
    title: "Logged time",
    dataIndex: "amount",
    key: "role_hours",
    scopedSlots: { customRender: "role_hours" },
  },
  rateColumn,
  valueColumn,
];

export const invoiceSummaryRolesColumns: Array<TableColumnInterface> = [
  roleColumn,
  {
    title: "Logged time",
    dataIndex: "amount",
    key: "amount",
    scopedSlots: { customRender: "amount" },
  },
  valueColumn,
];
