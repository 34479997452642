<template>
  <div>
    <empty-resource-table v-if="!questionsPage[0] && !loadingTable" resource="questions" />

    <a-table
      v-if="loadingTable || questionsPage[0]"
      :columns="questionsColumns"
      :data-source="questionsPage"
      :loading="loadingTable"
      :pagination="false"
      :expand-row-by-click="true"
      :scroll="{ x: 1000 }"
      :row-key="(question) => question.id"
      @change="(pagination, filters, sorter) => handleChange(pagination, filters, sorter)"
    >
      <template #question_title="question_title">
        <span>{{ question_title }}</span>
      </template>

      <template #date="date">
        <span>{{ date }}</span>
      </template>

      <template #quiz_tags="quiz_tags">
        <span v-for="quizTag in quiz_tags" :key="quizTag.id">
          <a-tag :color="Colors.blue">
            {{ quizTag.name }}
          </a-tag>
        </span>
      </template>

      <template #status="status">
        <a-tag v-if="status == 'correct'" :color="Colors.green">CORRECT</a-tag>
        <a-tag v-else-if="status == 'incorrect'" :color="Colors.red">INCORRECT</a-tag>
        <a-tag v-else :color="Colors.grey">UNANSWERED</a-tag>
      </template>

      <template #actions="status, question">
        <a-icon
          v-if="status == 'unanswered'"
          type="form"
          class="action-default"
          @click.stop="handleAnswerQuestionModalVisible(question)"
        />

        <a-icon
          v-if="status == 'incorrect' && question.onboarding"
          type="form"
          class="action-default"
          @click.stop="handleUpdateAnswerModalVisible(question)"
        />

        <span v-if="baseAcl.isAdmin">
          <a-divider
            v-if="status == 'unanswered' || (status == 'incorrect' && question.onboarding)"
            type="vertical"
          />
          <a-icon
            type="edit"
            class="action-default"
            @click.stop="handleEditQuestionModalVisible(question)"
          />
          <a-divider type="vertical" />
          <a-icon
            type="delete"
            class="action-danger"
            @click.stop="showDeleteQuestionConfirm(question.id)"
          />
        </span>
      </template>

      <template #expandedRowRender="question">
        <a-row>
          <a-col :span="3" class="d-flex justify-content-end">
            <b class="mr-2">Question:</b>
          </a-col>
          <a-col :span="15">
            {{ question.question }}
          </a-col>
        </a-row>
        <a-row v-if="question.status != 'unanswered'">
          <a-col :span="3" class="d-flex justify-content-end">
            <b class="mr-2">Your answer:</b>
          </a-col>
          <a-col :span="15">
            {{ question.quiz_submission.answer }}
          </a-col>
        </a-row>
        <a-row
          v-if="
            (!question.onboarding && question.status == 'incorrect') || question.status == 'correct'
          "
        >
          <a-col :span="3" class="d-flex justify-content-end">
            <b class="mr-2">Correct answer:</b>
          </a-col>
          <a-col :span="15">
            {{ question.answer }}
          </a-col>
        </a-row>
      </template>
    </a-table>

    <a-pagination
      v-if="questionsPagination && (loadingTable || questionsPage[0])"
      :current="currentPage"
      :total="questionsPagination.total"
      :show-size-changer="true"
      :page-size="pageSize"
      :page-size-options="['10', '25', '50', '100']"
      size="small"
      class="d-flex justify-content-end mt-2"
      @change="(page, pageSize) => handleGetQuestionsPage(page, pageSize)"
      @showSizeChange="(pageNumber, newPageSize) => handleGetQuestionsPage(pageNumber, newPageSize)"
    />

    <answer-question-modal
      :visible="answerQuestionModalVisible"
      :question="questionToBeAnswered"
      @submit-answer="
        (questionId, quizSubmission) => $emit('submit-answer', questionId, quizSubmission)
      "
      @close="answerQuestionModalVisible = false"
    />

    <update-answer-modal
      :visible="updateAnswerModalvisible"
      :question="questionToGetNewAnswer"
      @update-answer="
        (questionId, userId, quizSubmission) =>
          $emit('update-answer', questionId, userId, quizSubmission)
      "
      @close="updateAnswerModalvisible = false"
    />

    <edit-question-modal
      :visible="editQuestionModalVisible"
      :original-question="questionToBeEdited"
      :quiz-tags="quizTags"
      @get-quiz-tags="$emit('get-quiz-tags')"
      @update-question="
        (questionId, updatedQuestion) => $emit('update-question', questionId, updatedQuestion)
      "
      @close="editQuestionModalVisible = false"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, computed, getCurrentInstance } from "vue";
import { useAuthenticationStore } from "@/modules/authentication/_store";
import EmptyResourceTable from "@/modules/common/_components/EmptyResourceTable.vue";
import { BaseAclInterface } from "@/modules/authentication/types";
import { QuizTagInterface } from "@/modules/quiz_tags/types";
import AnswerQuestionModal from "./AnswerQuestionModal.vue";
import EditQuestionModal from "./EditQuestionModal.vue";
import UpdateAnswerModal from "./UpdateAnswerModal.vue";
import Colors from "@/assets/scss/_colors.module.scss";
import { PaginationInterface } from "@/common/types";
import { questionsColumns } from "../_utils/tables";
import { QuestionInterface } from "../types";

// Props
const props = defineProps({
  questionsPagination: { type: Object as () => PaginationInterface, default: undefined },
  questionsPage: { type: Array as () => Array<QuestionInterface>, default: () => [] },
  quizTags: { type: Array as () => Array<QuizTagInterface>, default: () => [] },
  loadingTable: { type: Boolean, default: false },
  currentPage: { type: Number, default: 1 },
  pageSize: { type: Number, default: 25 },
});

// Emits
const emits = defineEmits(["apply-sorting", "get-questions-page", "destroy-question"]);

// Pinia
const authenticationStore = useAuthenticationStore();
const baseAcl = computed<BaseAclInterface>(() => authenticationStore.baseAcl);

// Instances
const instance = getCurrentInstance();
const $confirm = instance?.proxy?.$confirm;

// Data Properties
const questionToGetNewAnswer = ref<QuestionInterface | undefined>(undefined);
const questionToBeAnswered = ref<QuestionInterface | undefined>(undefined);
const questionToBeEdited = ref<QuestionInterface | undefined>(undefined);
const answerQuestionModalVisible = ref<boolean>(false);
const editQuestionModalVisible = ref<boolean>(false);
const updateAnswerModalvisible = ref<boolean>(false);

// Component methods
const handleChange = (pagination: any, filters: any, sorter: any): void => {
  emits("apply-sorting", {
    sort: sorter.columnKey,
    order: sorter.columnKey ? (sorter.order === "descend" ? "desc" : "asc") : undefined,
  });
};

const handleGetQuestionsPage = (page: number, pageSize: number): void => {
  emits("get-questions-page", { page, pageSize });
};

const handleAnswerQuestionModalVisible = (question: QuestionInterface): void => {
  questionToBeAnswered.value = question;
  answerQuestionModalVisible.value = true;
};

const handleEditQuestionModalVisible = (question: QuestionInterface): void => {
  questionToBeEdited.value = question;
  editQuestionModalVisible.value = true;
};

const handleUpdateAnswerModalVisible = (question: QuestionInterface): void => {
  questionToGetNewAnswer.value = question;
  updateAnswerModalvisible.value = true;
};

const showDeleteQuestionConfirm = (questionId: number): void => {
  const mod = $confirm?.({
    title: "Are you sure you want to delete this question?",
    okText: "Yes",
    okType: "danger",
    cancelText: "No",
    onOk() {
      emits("destroy-question", questionId);
      mod?.destroy();
    },
  });
};
</script>
