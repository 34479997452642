import axios from "@/network";
import { AxiosResponse } from "axios";
import { UserVacationYearInterface, VacationYearsFilterInterface } from "../types";

export async function apiGetAllUserVacationYears(
  filters: VacationYearsFilterInterface
): Promise<AxiosResponse<{ data: Array<UserVacationYearInterface> }>> {
  const url = `/vacation_years`;
  const params = filters;
  return axios.get(url, { params });
}

export function apiUpdateUserVacationYear(updatedVacationYear: UserVacationYearInterface) {
  const url = `/users/${updatedVacationYear.user.id}/vacation_years/${updatedVacationYear.year}`;
  const payload = {
    vacation_year: { ...updatedVacationYear },
  };
  return axios.patch(url, payload);
}
