<template>
  <div class="d-flex justify-content-between align-items-center mb-4">
    <!-- PROJECT NAME AND CLIENT -->
    <div>
      <h2 class="m-0">{{ client.name }}</h2>

      <p v-if="client.account_manager" class="text-uppercase text-grey_dark m-0">
        Account Manager: <strong>{{ client.account_manager.name }}</strong>
      </p>
      <p v-if="client.registration_number" class="text-uppercase text-grey_dark m-0">
        Registration Number: <strong>{{ client.registration_number }}</strong>
      </p>
      <p
        v-if="client.fortnox_id && (acl.isAdmin || acl.isFinancial)"
        class="text-uppercase text-grey_dark m-0"
      >
        Fortnox ID: <strong>{{ client.fortnox_id }}</strong>
      </p>
    </div>

    <!-- ACTIONS -->
    <div v-if="acl.isAdmin || acl.isFinancial || acl.isCr">
      <a-dropdown :trigger="['click']">
        <a class="ant-dropdown-link d-flex align-items-center" href="#">
          <span class="d-none d-lg-block mr-1">Actions</span>
          <a-icon type="down" class="d-none d-lg-block" />
          <a-icon type="down-circle-o" class="d-block d-lg-none" />
        </a>
        <template #overlay>
          <a-menu>
            <a-menu-item
              v-if="acl.isAccountManager || acl.isAdmin || acl.isFinancial"
              @click="() => (editClientModalVisible = true)"
            >
              <a-icon type="edit" class="pr-1" />Edit
            </a-menu-item>
            <a-menu-item
              v-if="acl.isAccountManager || acl.isAdmin || acl.isFinancial"
              @click="() => showDeleteClientConfirm()"
            >
              <a-icon type="delete" class="pr-1" />Delete
            </a-menu-item>
            <a-menu-item @click="() => (addProjectModalVisible = true)">
              <a-icon type="plus" class="pr-1" />Add project
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
    </div>

    <!-- EDIT CLIENT MODAL -->
    <edit-client-modal
      :visible="editClientModalVisible"
      :client="client"
      :users="users"
      @close="editClientModalVisible = false"
      @update-client="(updatedClient) => $emit('update-client', updatedClient)"
      @get-users="() => $emit('get-users')"
    />

    <!-- ADD PROJECT MODAL -->
    <add-project-modal
      :client="client"
      :profile="profile"
      :acl="acl"
      :visible="addProjectModalVisible"
      @close="addProjectModalVisible = false"
      @store-project="(newProject) => $emit('store-client-project', newProject)"
    />

    <!-- DELETE CLIENT MODAL -->
    <a-modal
      :visible="deleteClientModalVisible"
      title="Are you sure you want to delete this client and all its associations?"
      ok-text="Yes"
      ok-type="danger"
      cancel-text="No"
      @ok="() => handleDeleteClient()"
      @cancel="() => (deleteClientModalVisible = false)"
    >
      <h3 class="mb-0">Associations:</h3>
      <p v-for="entity in clientAssociations" :key="entity.name" class="mb-0">
        <strong>{{ entity.name }}:</strong> {{ entity.count }}
      </p>
    </a-modal>
  </div>
</template>

<script setup lang="ts">
import { ref, getCurrentInstance } from "vue";
import AddProjectModal from "@/modules/projects/_components/AddProjectModal.vue";
import EditClientModal from "../_components/EditClientModal.vue";
import { MinimalUserInterface } from "@/modules/users/types";
import { ClientInterface, ClientAssociation } from "../types";
import { ProfileInterface, BaseAclInterface } from "../../authentication/types";

// Props
const props = defineProps({
  users: { type: Array as () => Array<MinimalUserInterface>, default: () => [] },
  clientAssociations: { type: Array as () => Array<ClientAssociation>, default: () => [] },
  acl: {
    type: Object as () => BaseAclInterface & { isAccountManager: boolean },
    default: () => ({}),
  },
  profile: { type: Object as () => ProfileInterface, default: () => ({}) },
  client: { type: Object as () => ClientInterface, default: () => ({}) },
});

// Emits
const emits = defineEmits(["destroy-client"]);

// Instances
const instance = getCurrentInstance();
const $error = instance?.proxy.$error;

// Data properties
const editClientModalVisible = ref<boolean>(false);
const addProjectModalVisible = ref<boolean>(false);
const deleteClientModalVisible = ref<boolean>(false);

const showDeleteClientConfirm = (): void => {
  if (props.client.deletable) {
    deleteClientModalVisible.value = true;
  } else {
    $error?.({
      title: "You don't have permission to delete this client.",
      content: "Only deletable by the clients account manager or an admin.",
    });
  }
};

const handleDeleteClient = (): void => {
  deleteClientModalVisible.value = false;
  emits("destroy-client", props.client.id);
};
</script>
