<template>
  <a-layout-content class="my-4 mx-3">
    <div class="container">
      <projects-view
        :acl="baseAcl"
        :allow-download="true"
        :allow-creation="true"
        :allow-filtering="true"
      />
    </div>
  </a-layout-content>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useAuthenticationStore } from "@/modules/authentication/_store";
import { BaseAclInterface } from "@/modules/authentication/types";
import ProjectsView from "../_components/ProjectsView.vue";

// Pinia
const authenticationStore = useAuthenticationStore();
const baseAcl = computed<BaseAclInterface>(() => authenticationStore.baseAcl);
</script>
