<template>
  <div class="flex-center-between mb-4">
    <!-- MEMBERS AND TEAM LEADER -->
    <div>
      <h2 class="m-0">Team {{ team.name }}</h2>
      <p class="text-uppercase text-grey_dark m-0">
        Number of members: <strong>{{ team.users_count }}</strong>
      </p>
      <p class="text-uppercase text-grey_dark m-0">
        Team leader: <strong>{{ team.leader.name }}</strong>
      </p>
    </div>

    <!-- ACTIONS -->
    <div v-if="acl.isTeamLeader || acl.isAdmin">
      <a-dropdown :trigger="['click']">
        <a class="ant-dropdown-link d-flex align-items-center" href="#">
          <span class="d-none d-lg-block mr-1">Actions</span>
          <a-icon type="down" class="d-none d-lg-block" />
          <a-icon type="down-circle-o" class="d-block d-lg-none" />
        </a>

        <template #overlay>
          <a-menu>
            <a-menu-item @click="() => (editTeamModalVisible = true)">
              <a-icon type="edit" class="pr-1" />Edit
            </a-menu-item>
            <a-menu-item v-if="acl.isAdmin" @click="showDeleteTeamConfirm(team.id)">
              <a-icon type="delete" class="pr-1" />Delete
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
    </div>

    <edit-team-modal
      :visible="editTeamModalVisible"
      :team="team"
      :users="users"
      @close="editTeamModalVisible = false"
      @get-users="() => $emit('get-users')"
      @update-team="(updatedTeam) => $emit('update-team', updatedTeam)"
    />
  </div>
</template>

<script setup lang="ts">
import { getCurrentInstance, ref } from "vue";
import { BaseAclInterface } from "@/modules/authentication/types";
import EditTeamModal from "../_components/EditTeamModal.vue";
import { MinimalUserInterface } from "@/modules/users/types";
import { TeamInterface } from "../types";

// Props
const props = defineProps({
  users: { type: Array as () => Array<MinimalUserInterface>, default: () => [] },
  acl: { type: Object as () => BaseAclInterface & { isTeamLeader: boolean }, default: undefined },
  team: { type: Object as () => TeamInterface, default: undefined },
});

// Emits
const emits = defineEmits(["destroy-team"]);

// Instances
const instance = getCurrentInstance();
const $confirm = instance?.proxy.$confirm;

// Data properties
const editTeamModalVisible = ref<boolean>(false);

// Class methods
const showDeleteTeamConfirm = (teamId: number): void => {
  const mod = $confirm?.({
    title: "Are you sure you want to delete this team?",
    okText: "Yes",
    okType: "danger",
    cancelText: "No",
    onOk() {
      emits("destroy-team", teamId);
      mod?.destroy();
    },
  });
};
</script>
