<template>
  <a-modal :visible="visible" :destroy-on-close="true" :title="title" @cancel="closeModal">
    <a-form-item :label-col="{ span: 7 }" :wrapper-col="{ span: 16 }" label="Status">
      <a-radio-group :value="newStatus" @change="(el) => (newStatus = el.target.value)">
        <a-radio-button v-for="choice in choices" :key="choice.value" :value="choice.value">
          {{ choice.label }}
        </a-radio-button>
      </a-radio-group>
    </a-form-item>

    <template #footer>
      <div>
        <a-button type="text" @click="closeModal"> Cancel </a-button>

        <a-button type="primary" :disabled="newStatus === ''" @click="handleBulkUpdateElements">
          Update
        </a-button>
      </div>
    </template>
  </a-modal>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";

// Interfaces
export interface ChoiceInterface {
  label: string;
  value: string;
}

// Props
const props = defineProps({
  visible: { type: Boolean, default: false },
  elementType: { type: String, default: undefined },
  elements: { type: Array, default: () => [] },
  choices: { type: Array as () => Array<ChoiceInterface>, default: () => [] },
});

// Emits
const emits = defineEmits(["bulk-update-elements", "clear-selection", "close"]);

// Data properties
const title = ref<string>("");
const newStatus = ref<string>("");

onMounted(() => {
  title.value = `Bulk Update ${capitalize(props.elementType)}s`;
});

// Component methods
const handleBulkUpdateElements = (): void => {
  emits(
    "bulk-update-elements",
    props.elements.map((elem: any) => elem.id),
    newStatus.value
  );
  emits("clear-selection");
  closeModal();
};

const closeModal = (): void => {
  emits("close");
};

const capitalize = (value: string): string => {
  return value[0].toUpperCase() + value.slice(1);
};
</script>
