import Colors from "@/assets/scss/_colors.module.scss";
import { RoleExtensionInterface, RoleInterface } from "../types";
import { apiCreateRoleExtension } from "./api";

export function hasPendingExtension(role?: RoleInterface): boolean {
  if (!role || !role.extension_status) return false;
  return (
    role.extension_status === ExtensionStatus.WAITING ||
    role.extension_status === ExtensionStatus.TO_BE_DISCUSSED ||
    role.extension_status === ExtensionStatus.APPROVED
  );
}

export function handleCreateRoleExtension(
  roleId: number,
  newRoleExtension: RoleExtensionInterface,
  onSuccessCallback: any,
  vueComponent: any
): void {
  apiCreateRoleExtension(roleId, newRoleExtension)
    .then(() => onSuccessCallback())
    .then(() => vueComponent.$message.success("Role extension successfully created!", 3))
    .catch(() => vueComponent.$message.error("Couldn't create role extension!", 3));
}

export enum ExtensionStatus {
  NO_PENDING = "no_pending",
  PENDING = "pending",
  WAITING = "waiting",
  TO_BE_DISCUSSED = "to_be_discussed",
  APPROVED = "approved",
}

export const extensionStatusColor: { [key in ExtensionStatus]: any } = {
  [ExtensionStatus.NO_PENDING]: Colors.grey,
  [ExtensionStatus.PENDING]: Colors.orange,
  [ExtensionStatus.WAITING]: Colors.yellow,
  [ExtensionStatus.TO_BE_DISCUSSED]: Colors.red,
  [ExtensionStatus.APPROVED]: Colors.green,
};
