<template>
  <a-modal
    :visible="visible"
    :destroy-on-close="true"
    title="Create Role"
    ok-text="Create"
    @cancel="closeModal"
  >
    <!-- CREATE PROJECT ROLE STEPS -->
    <a-steps
      :current="currentStep"
      size="small"
      class="px-3 pb-4 d-none d-lg-flex"
      direction="horizontal"
    >
      <a-step title="Description">
        <template #icon>
          <a-icon type="file-text" />
        </template>
      </a-step>
      <a-step title="Financial">
        <template #icon>
          <a-icon type="credit-card" />
        </template>
      </a-step>
    </a-steps>

    <a-form v-show="currentStep === 0" data-vv-scope="step-info" layout="horizontal">
      <SkNameSelect
        v-model="newProjectRole.user_id"
        v-validate="'required'"
        :error="$validator.errors.first('step-info.user')"
        data-vv-name="user"
        label="User"
        placeholder="Select a user"
        :options="users"
        @change="(userId) => $emit('get-user', userId)"
      />

      <SkInput
        v-model="newProjectRole.title"
        v-validate="'required|max:255'"
        :error="$validator.errors.first('step-info.title')"
        data-vv-name="title"
        label="Title"
        placeholder="Role title"
      />

      <SkInput
        v-model="newProjectRole.agreement_link"
        :error="$validator.errors.first('step-info.agreement_link')"
        data-vv-name="agreement_link"
        label="Agreement Link"
        placeholder="Role agreement link"
      />

      <a-form-item
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
        :validate-status="$validator.errors.first('step-info.start_date') ? 'error' : ''"
        :help="$validator.errors.first('step-info.start_date')"
        label="Start date"
      >
        <a-date-picker
          v-validate="'required'"
          :value="parseDate(newProjectRole.start_date)"
          :disabled-date="(date) => isDateOnOrAfterProjectEndDate(date)"
          class="w-100"
          data-vv-name="start_date"
          data-vv-as="start date"
          @change="(d) => onDateChange(d, 'start_date')"
        />
      </a-form-item>

      <a-form-item
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
        :validate-status="$validator.errors.first('step-info.end_date') ? 'error' : ''"
        :help="$validator.errors.first('step-info.end_date')"
        label="End date"
      >
        <a-date-picker
          v-validate="'required'"
          :value="parseDate(newProjectRole.end_date)"
          :disabled-date="(date) => date <= new Date(newProjectRole.start_date)"
          class="w-100"
          data-vv-name="end_date"
          data-vv-as="end date"
          @change="(d) => onDateChange(d, 'end_date')"
        />
      </a-form-item>

      <SkInput
        v-model="newProjectRole.notice_period"
        v-validate="'required|integer|min_value:0'"
        :error="$validator.errors.first('step-info.notice_period')"
        data-vv-name="notice_period"
        label="Notice Period"
        placeholder="Notice period in days"
      />

      <SkInput
        v-model="newProjectRole.workload"
        v-validate="'required|integer|min_value:0'"
        :error="$validator.errors.first('step-info.workload')"
        data-vv-name="workload"
        label="Workload"
        placeholder="Workload in percentages"
        addon-after="%"
      />

      <SkNameSelect
        v-model="newProjectRole.skill_ids"
        label="Role Skills"
        mode="multiple"
        placeholder="Select role skills"
        :options="skills"
        option-attribute-name="title"
      />

      <a-form-item :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }" label="Description">
        <a-textarea
          v-model="newProjectRole.description"
          :auto-size="{ minRows: 3 }"
          placeholder="Add a description"
        />
      </a-form-item>

      <a-form-item
        v-if="acl.isAdmin"
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
        label="Hide in CV selection"
      >
        <a-checkbox v-model="newProjectRole.disable_cv" />
      </a-form-item>

      <a-form-item
        v-if="acl.isAdmin"
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
        label="Time reporting"
      >
        <a-radio-group
          v-if="!(subprojects.length > 0)"
          v-model="newProjectRole.automatic_time_reporting"
          button-style="solid"
          @change="(el) => (newProjectRole.automatic_time_reporting = el.target.value)"
        >
          <a-radio-button :value="false">Manual</a-radio-button>
          <a-radio-button :value="true">Automatic</a-radio-button>
        </a-radio-group>
        <div v-else>
          <span>Must be manual for projects with subprojects</span>
        </div>
      </a-form-item>
    </a-form>

    <!-- FINANCIAL STEP -->
    <a-form v-show="currentStep === 1" data-vv-scope="step-financial" layout="horizontal">
      <SkMoney
        v-model="newProjectRole.hourly_rate_cents"
        v-validate="'required|decimal|min_value:0'"
        :error="$validator.errors.first('step-financial.hourly_rate')"
        :currency="newProjectRole.hourly_rate_currency"
        data-vv-name="hourly_rate"
        data-vv-as="hourly rate"
        label="Hourly rate"
        placeholder="Hourly rate"
        @set-currency="(v) => (newProjectRole.hourly_rate_currency = v)"
      />

      <SkMoney
        v-model="newProjectRole.future_hourly_rate_cents"
        v-validate="'required|decimal|min_value:0'"
        :error="$validator.errors.first('step-financial.future_hourly_rate')"
        :currency="newProjectRole.future_hourly_rate_currency"
        data-vv-name="future_hourly_rate"
        data-vv-as="future hourly rate"
        label="Future Hourly rate"
        placeholder="Future Hourly rate"
        @set-currency="(v) => (newProjectRole.future_hourly_rate_currency = v)"
      />

      <a-form-item :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }" label="Future hourly date">
        <a-date-picker
          :value="parseDate(newProjectRole.future_hourly_rate_date)"
          class="w-100"
          @change="(d) => onDateChange(d, 'future_hourly_rate_date')"
        />
      </a-form-item>

      <a-form-item
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
        :validate-status="$validator.errors.first('step-financial.cost_center_id') ? 'error' : ''"
        :help="$validator.errors.first('step-financial.cost_center_id')"
        label="Cost center"
      >
        <a-select
          v-model="newProjectRole.cost_center_id"
          v-validate="'required'"
          :default-value="newProjectRole.cost_center_id"
          placeholder="Select cost center"
          data-vv-name="cost_center_id"
          data-vv-as="cost center"
        >
          <a-select-option v-for="cc in costCenters" :key="cc.id">
            {{ cc.name }} ({{ cc.manager.name }})
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-form>

    <template #footer>
      <div class="d-flex justify-content-between">
        <div>
          <a-button @click="closeModal">Cancel</a-button>
        </div>

        <div>
          <a-button v-show="currentStep !== 0" @click="toPrevious()">Previous</a-button>
          <a-button v-show="currentStep === 0" type="primary" @click="goToFinancialStep"
            >Next</a-button
          >
          <a-button v-show="currentStep === 1" type="primary" @click="handleStoreProjectRole"
            >Create</a-button
          >
        </div>
      </div>
    </template>
  </a-modal>
</template>

<script setup lang="ts">
import { ref, watch, getCurrentInstance } from "vue";
import { MinimalUserInterface } from "@/modules/users/types";
import { ProfileInterface } from "@/modules/authentication/types";
import { ProjectInterface, NewSubprojectInterface } from "../../projects/types";
import { BaseAclInterface } from "@/modules/authentication/types";
import { SkillInterface } from "@/modules/skills/types";
import { CostCenterInterface } from "@/modules/cost_centers/types";
import useMixin from "@/useMixin";
import useFilters from "@/useFilters";

// Props
const props = defineProps({
  users: { type: Array as () => Array<MinimalUserInterface>, default: () => [] },
  user: { type: Object as () => ProfileInterface, default: undefined },
  project: { type: Object as () => ProjectInterface, default: undefined },
  visible: { type: Boolean, default: false },
  acl: { type: Object as () => BaseAclInterface, default: undefined },
  skills: { type: Array as () => Array<SkillInterface>, default: () => [] },
  costCenters: { type: Array as () => Array<CostCenterInterface>, default: () => [] },
  subprojects: { type: Array as () => Array<NewSubprojectInterface>, default: () => [] },
});

// Emits
const emits = defineEmits([
  "get-users",
  "get-skills",
  "get-cost-centers",
  "store-project-role",
  "close",
  "get-subprojects-page",
]);

// Mixins
const { setObject, toMoneyCents } = useMixin();

// Filters
const { parseDate } = useFilters();

// Instances
const instance = getCurrentInstance();
const $validator = instance?.proxy.$validator;

// Data properties
const newProjectRole = ref<any>({
  hourly_rate_cents: null,
  hourly_rate_currency: null,
  start_date: null,
  end_date: null,
  description: null,
  automatic_time_reporting: null,
  title: null,
  user_id: null,
  skill_ids: null,
  future_hourly_rate_cents: null,
  future_hourly_rate_currency: null,
  future_hourly_rate_date: null,
  notice_period: null,
  workload: null,
  cost_center_id: null,
  disable_cv: null,
});
const currentStep = ref<number>(0);

// Watchers
watch(
  () => props.visible,
  (newProp, oldProp) => {
    // When the modal is opened:
    if (newProp && !oldProp) {
      // Load users
      emits("get-users");
      emits("get-skills");
      emits("get-cost-centers");
      emits("get-subprojects-page", props.project.id);

      // Initialize all fields
      newProjectRole.value = setObject(newProjectRole.value, undefined);
      newProjectRole.value.hourly_rate_cents = 0;
      newProjectRole.value.future_hourly_rate_cents = 0;
      newProjectRole.value.end_date = addOneMonth(new Date());
      newProjectRole.value.start_date = new Date();
      newProjectRole.value.workload = 100;
      newProjectRole.value.automatic_time_reporting = false;
    }
  }
);

// Component methods
const onDateChange = (date: any, attribute: string): void => {
  if (date === null) {
    newProjectRole.value[attribute] = null;
  } else {
    newProjectRole.value[attribute] = date.format("YYYY-MM-DD");
  }
};

const toPrevious = (): void => {
  emits("get-users");
  currentStep.value--;
};

const goToFinancialStep = (): void => {
  $validator?.validateAll("step-info").then((result) => {
    if (result) {
      emits("get-users", { employment_type: "variable" });
      currentStep.value++;
    }
  });
};

const changeRatesToCents = (): void => {
  newProjectRole.value.hourly_rate_cents = toMoneyCents(newProjectRole.value.hourly_rate_cents);
  newProjectRole.value.future_hourly_rate_cents = toMoneyCents(
    newProjectRole.value.future_hourly_rate_cents
  );
};

const handleStoreProjectRole = (): void => {
  $validator?.errors.clear();
  $validator?.validateAll("step-financial").then((result) => {
    // If last step and validation pass...
    if (result) {
      changeRatesToCents();
      newProjectRole.value.workload = newProjectRole.value.workload / 100;
      emits("store-project-role", newProjectRole.value);
      currentStep.value = 0;
      emits("close");
    }
  });
};

const closeModal = (): void => {
  currentStep.value = 0;
  emits("close");
};

const isDateOnOrAfterProjectEndDate = (date: any): boolean => {
  return newProjectRole.value.end_date
    ? date >= new Date(newProjectRole.value.end_date) ||
        date.isSame(new Date(newProjectRole.value.end_date), "day")
    : false;
};

const addOneMonth = (date: Date): Date => {
  const newDate = new Date(date);
  newDate.setMonth(date.getMonth() + 1);
  return newDate;
};
</script>
