<template>
  <a-form-item
    :label-col="labelCol"
    :wrapper-col="wrapperCol"
    :validate-status="error ? 'error' : ''"
    :help="error"
    :label="label"
  >
    <a-input :value="value" :placeholder="placeholder" @input="$emit('input', $event.target.value)">
      <template #addonAfter>
        <a-select
          :value="currency"
          style="width: 80px"
          @change="(val) => $emit('set-currency', val)"
        >
          <a-select-option v-for="c in currencies" :key="c" :value="c">{{ c }}</a-select-option>
        </a-select>
      </template>
    </a-input>
  </a-form-item>
</template>

<script setup lang="ts">
import { CURRENCIES } from "@/utils";

const props = defineProps({
  value: { type: [String, Number], default: undefined },
  label: { type: String, default: undefined },
  error: { type: String, default: undefined },
  placeholder: { type: String, default: undefined },
  currency: { type: String, default: undefined },
  labelCol: { type: Object, default: () => ({ span: 8 }) },
  wrapperCol: { type: Object, default: () => ({ span: 16 }) },
});

const currencies: Array<string> = CURRENCIES;
</script>
