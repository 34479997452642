<template>
  <a-modal
    :visible="visible"
    :destroy-on-close="true"
    :title="'Create a new Expense Report'"
    ok-text="Create"
    @ok="() => handleCreateExpenseReport()"
    @cancel="() => $emit('close')"
  >
    <a-form layout="horizontal" :data-vv-scope="ReportType.EXPENSE">
      <a-form-item
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
        :validate-status="$validator.errors.first('expense-report.title') ? 'error' : ''"
        :help="$validator.errors.first('expense-report.title')"
        label="Title"
      >
        <a-input
          v-model="newReport.title"
          v-validate="'required'"
          data-vv-name="title"
          placeholder="Report title"
          class="w-100"
        />
      </a-form-item>

      <a-form-item
        v-if="project"
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
        label="Project"
      >
        <strong>{{ project.name }}</strong>
      </a-form-item>

      <a-form-item v-else :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }" label="Project">
        <a-select
          v-model="newReport.project_id"
          placeholder="Select a project"
          show-search
          option-filter-prop="children"
        >
          <a-select-option v-for="p in projectsCompactList" :key="p.id" :value="p.id">
            {{ p.name }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }" label="User">
        <strong>{{ user.name }}</strong>
      </a-form-item>

      <a-form-item v-if="amount" :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }" label="Cost">
        <strong>{{ amount.formatted_value }} {{ amount.currency_code }}</strong>
      </a-form-item>

      <SkMoney
        v-else
        v-model="newReport.cost_cents"
        v-validate="'required|decimal|min_value:0'"
        :error="$validator.errors.first('expense-report.cost')"
        :currency="newReport.cost_currency"
        data-vv-name="cost"
        label="Cost"
        placeholder="Cost"
        @set-currency="(v) => (newReport.cost_currency = v)"
      />

      <a-form-item
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
        :validate-status="$validator.errors.first('expense-report.date') ? 'error' : ''"
        :help="$validator.errors.first('expense-report.date')"
        label="Date"
      >
        <a-date-picker
          v-validate="'required'"
          :value="newReport.start_date ?? date"
          data-vv-name="date"
          class="w-100"
          @change="(date) => onDateChange(date)"
        />
      </a-form-item>

      <a-form-item :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }" label="Cost center">
        <a-select
          v-model="newReport.cost_center_id"
          placeholder="Select a cost center"
          show-search
          option-filter-prop="children"
        >
          <a-select-option v-for="c in costCenters" :key="c.id" :value="c.id">
            {{ c.name }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item
        v-if="description"
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
        label="Comment"
      >
        <a-textarea v-model="localDescription" :auto-size="{ minRows: 2 }" />
      </a-form-item>

      <a-form-item v-else :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }" label="Comment">
        <a-textarea v-model="newReport.comment" :auto-size="{ minRows: 2 }" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script setup lang="ts">
import { getCurrentInstance, ref, toRefs, watch } from "vue";
import { MinimalProjectInterface } from "@/modules/projects/types";
import { CostCenterInterface } from "../../cost_centers/types";
import { ProfileInterface } from "../../authentication/types";
import { ReportPayloadInterface, ReportType } from "../types";
import { MoneyInterface } from "@/common/types";
import { getProjectsCompactList } from "@/api";
import useMixin from "@/useMixin";

// Props
const props = defineProps({
  user: { type: Object as () => ProfileInterface, default: undefined },
  project: { type: Object, default: undefined },
  costCenters: { type: Array as () => Array<CostCenterInterface>, default: () => [] },
  visible: { type: Boolean, default: false },
  date: { type: String, default: undefined },
  amount: { type: Object as () => MoneyInterface, default: undefined },
  description: { type: String, default: undefined },
});

// Emits
const emits = defineEmits(["get-cost-centers", "store-report", "close"]);

// Mixins
const { setObject, toMoneyCents } = useMixin();

// Instance
const instance = getCurrentInstance();
const $validator = instance?.proxy.$validator;

// Data properties
const projectsCompactList = ref<Array<MinimalProjectInterface>>([]);
const newReport = ref<ReportPayloadInterface>({
  project_id: undefined,
  cost_cents: undefined,
  cost_currency: undefined,
  date: undefined,
  start_date: undefined,
  comment: undefined,
  title: undefined,
  cost_center_id: undefined,
  user_id: undefined,
  type: ReportType.EXPENSE,
});

// Computed properties
const { description } = toRefs(props);
const localDescription = ref(description.value);

// Watchers
watch(
  () => props.visible,
  (newProp, oldProp) => {
    if (newProp && !oldProp) {
      // Initialize all fields
      newReport.value = setObject(newReport.value, undefined);
      newReport.value.type = ReportType.EXPENSE;
      newReport.value.user_id = props.user ? props.user.id : undefined;
      newReport.value.project_id = props.project ? props.project.id : undefined;
      emits("get-cost-centers");
      handleGetProjectsCompactList();
    }
  }
);

// Component Methods
const changeCostToCents = (): void => {
  newReport.value.cost_cents = toMoneyCents(newReport.value.cost_cents ?? 0);
};

const handleCreateExpenseReport = (): void => {
  $validator?.validateAll(ReportType.EXPENSE).then((result) => {
    if (result) {
      changeCostToCents();
      props.project?.id ? (newReport.value.project_id = props.project?.id) : undefined;
      props.amount ? (newReport.value.cost_currency = props.amount.currency_code) : undefined;
      props.amount ? (newReport.value.cost_cents = props.amount.value) : undefined;
      props.description ? (newReport.value.comment = props.description) : undefined;
      props.date && !newReport.value.start_date
        ? (newReport.value.start_date = props.date)
        : undefined;
      emits("store-report", newReport.value);
      emits("close");
    }
  });
};

const onDateChange = (date: any): void => {
  newReport.value.start_date = date ? date.format("YYYY-MM-DD") : undefined;
};

const handleGetProjectsCompactList = (): void => {
  getProjectsCompactList().then((projects) => {
    projectsCompactList.value = projects;
  });
};
</script>
