<template>
  <a-modal
    :visible="visible"
    :destroy-on-close="true"
    title="Create Project"
    @cancel="() => closeModal()"
  >
    <!-- CREATE PROJECT STEPS -->
    <a-steps
      :current="currentStep"
      size="small"
      class="px-3 pb-4 d-none d-lg-flex"
      direction="horizontal"
    >
      <a-step title="Info">
        <template #icon>
          <a-icon type="form" />
        </template>
      </a-step>
      <a-step title="Description">
        <template #icon>
          <a-icon type="file-text" />
        </template>
      </a-step>
      <a-step title="Financial">
        <template #icon>
          <a-icon type="credit-card" />
        </template>
      </a-step>
    </a-steps>

    <!-- GENERAL INFO STEP -->
    <a-form v-show="currentStep === 0" data-vv-scope="step-info" layout="horizontal">
      <SkInput
        v-model="newProject.name"
        v-validate="'required|max:255'"
        :error="$validator.errors.first('step-info.name')"
        data-vv-name="name"
        label="Name"
        placeholder="Project name"
      />

      <SkInput
        v-model="newProject.alias"
        v-validate="'max:255'"
        :error="$validator.errors.first('step-info.alias')"
        data-vv-name="alias"
        label="Alias"
        placeholder="Project alias"
      />

      <!-- IF CLIENT IS ALREADY DEFINED -->
      <a-form-item
        v-if="client"
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
        label="Client"
      >
        <strong>{{ client.name }}</strong>
      </a-form-item>

      <!-- IF CLIENT IS NOT DEFINED -->
      <SkNameSelect
        v-else
        v-model="newProject.client_id"
        v-validate="'required'"
        :error="$validator.errors.first('step-info.client')"
        data-vv-name="client"
        label="Client"
        placeholder="Select a client"
        :options="clients"
      />

      <SkCheckbox v-model="newProject.github_team_sync_enabled" label="GitHub Team sync" />

      <SkInput
        v-if="newProject.github_team_sync_enabled"
        v-model="newProject.github_team_name"
        v-validate="'required|max:100'"
        :error="$validator.errors.first('step-info.github_team_name')"
        data-vv-name="github_team_name"
        data-vv-as="GitHub team name"
        label="GitHub team name"
        placeholder="GitHub team name"
      />
    </a-form>

    <!-- DESCRIPTION STEP -->
    <a-form v-show="currentStep === 1" data-vv-scope="step-description" layout="horizontal">
      <a-form-item
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
        :validate-status="$validator.errors.first('step-description.type') ? 'error' : ''"
        :help="$validator.errors.first('step-description.type')"
        label="Type"
      >
        <a-select
          v-model="newProject.project_type"
          v-validate="'required'"
          placeholder="Select a type"
          data-vv-name="type"
          data-vv-as="project type"
        >
          <a-select-option value="sk_for_hire">SK for hire</a-select-option>
          <a-select-option value="expert_for_hire">Expert for hire</a-select-option>
        </a-select>
      </a-form-item>

      <SkTextarea v-model="newProject.internal_description" label="Internal description" />

      <SkTextarea v-model="newProject.public_description" label="Public description" />
    </a-form>

    <!-- STEP FINANCIAL -->
    <a-form v-show="currentStep === 2" data-vv-scope="step-financial" layout="horizontal">
      <SkInput
        v-if="acl.isAdmin || acl.isFinancial"
        v-model="newProject.fortnox_id"
        v-validate="'alpha_num|max:255'"
        :error="$validator.errors.first('step-financial.fortnox_id')"
        data-vv-name="fortnox_id"
        data-vv-as="Fortnox ID"
        label="Fortnox ID"
        placeholder="Fortnox ID"
      />

      <SkTextarea
        v-if="acl.isAdmin || acl.isFinancial"
        v-model="newProject.invoicing_info"
        label="Invoicing information"
      />
    </a-form>

    <!-- CUSTOM FOOTER TO ADVANCE STEPS -->
    <template #footer>
      <div class="d-flex justify-content-between">
        <div>
          <a-button @click="() => closeModal()">Cancel</a-button>
        </div>

        <div>
          <a-button v-show="currentStep !== 0" @click="() => prev()">Previous</a-button>
          <a-button v-show="currentStep === 0" type="primary" @click="() => goToPeopleStep()"
            >Next</a-button
          >
          <a-button v-show="currentStep === 1" type="primary" @click="() => goToDescStep()"
            >Next</a-button
          >
          <a-button v-show="currentStep === 2" type="primary" @click="() => handleStoreProject()"
            >Create</a-button
          >
        </div>
      </div>
    </template>
  </a-modal>
</template>

<script setup lang="ts">
import { ref, watch, toRef, getCurrentInstance } from "vue";
import { ProfileInterface, BaseAclInterface } from "@/modules/authentication/types";
import { ClientInterface, MinimalClientInterface } from "@/modules/clients/types";
import { ProjectPayloadInterface } from "../types";
import useMixin from "@/useMixin";

// Props
const props = defineProps({
  clients: { type: Array as () => Array<MinimalClientInterface>, default: () => [] },
  client: { type: Object as () => ClientInterface, default: undefined },
  profile: { type: Object as () => ProfileInterface, default: undefined },
  acl: { type: Object as () => BaseAclInterface, default: undefined },
  visible: { type: Boolean, default: false },
});

// Emits
const emits = defineEmits(["get-clients", "store-project", "close"]);

// Instances
const instance = getCurrentInstance();
const $validator = instance?.proxy.$validator;

// Mixins
const { setObject } = useMixin();

// Data properties
const newProject = ref<ProjectPayloadInterface>({
  name: null,
  alias: null,
  project_type: null,
  internal_description: null,
  public_description: null,
  client_id: null,
  account_manager_id: null,
  fortnox_id: null,
  invoicing_info: null,
  github_team_sync_enabled: undefined,
  github_team_name: undefined,
});
const currentStep = ref<number>(0);

// Watchers
watch(toRef(props, "visible"), (newProp, oldProp) => {
  if (newProp && !oldProp) {
    // Initialize all fields
    newProject.value = setObject(newProject.value, undefined);

    // If the client is not given then load list so that
    // user can select otherwise just set the id
    if (!props.client) {
      emits("get-clients");
    } else {
      newProject.value.client_id = props.client.id;
    }
  }
});

// Class methods
const prev = (): void => {
  currentStep.value--;
};

const goToPeopleStep = (): void => {
  $validator?.validateAll("step-info").then((result) => {
    if (result) {
      currentStep.value++;
    }
  });
};

const goToDescStep = (): void => {
  $validator?.validateAll("step-description").then((result) => {
    if (result) {
      currentStep.value++;
    }
  });
};

const handleStoreProject = (): void => {
  $validator?.validateAll("step-financial").then((result) => {
    // If last step and validation pass...
    if (result) {
      emits("store-project", newProject.value);
      currentStep.value = 0;
      emits("close");
    }
  });
};

const closeModal = (): void => {
  currentStep.value = 0;
  emits("close");
};
</script>
