<template>
  <a-modal
    :visible="visible"
    :destroy-on-close="true"
    title="Send Invoice to FORTNOX"
    ok-text="Send"
    @ok="handleSendToFortnox"
    @cancel="$emit('close')"
  >
    <p class="text-center">
      <strong>Are you sure you want to send the invoice SK#{{ invoice.id }} to FORTNOX?</strong>
    </p>

    <a-form layout="horizontal">
      <a-form-item
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
        :validate-status="$validator.errors.first('invoice_date') ? 'error' : ''"
        :help="$validator.errors.first('invoice_date')"
        label="Invoice date"
      >
        <a-date-picker
          v-validate="'required'"
          :value="parseDate(fortnox_invoice.invoice_date)"
          class="w-100"
          data-vv-name="invoice_date"
          data-vv-as="invoice date"
          @change="(d) => onDatesChange(d)"
        />
      </a-form-item>

      <a-form-item
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
        :validate-status="$validator.errors.first('remarks') ? 'error' : ''"
        :help="$validator.errors.first('remarks')"
        label="Remarks"
      >
        <a-input
          v-model="fortnox_invoice.remarks"
          v-validate="'required|max:255'"
          data-vv-name="remarks"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script setup lang="ts">
import { ref, watch, toRef, getCurrentInstance } from "vue";
import useMixin from "@/useMixin";
import moment from "@/date";
import useFilters from "@/useFilters";

// Props
const props = defineProps({
  visible: { type: Boolean, default: false },
  invoice: { type: Object, default: undefined },
});

// Emits
const emits = defineEmits(["send-to-fortnox", "close"]);

// Mixins
const { setObject } = useMixin();

// Filters
const { parseDate } = useFilters();

// Instances
const instances = getCurrentInstance();
const $validator = instances?.proxy.$validator;

// Data properties
const fortnox_invoice = ref<any>({
  invoice_date: undefined,
  remarks: undefined,
});

// Watchers
watch(toRef(props, "visible"), (newProp, oldProp) => {
  if (newProp && !oldProp) {
    // Initialize all fields
    fortnox_invoice.value = setObject(fortnox_invoice.value, undefined);

    let date =
      props.invoice.type === "RequestedInvoice"
        ? moment(props.invoice.date)
        : moment().subtract(1, "months").endOf("month");

    fortnox_invoice.value.invoice_date = date.format("YYYY-MM-DD");
    fortnox_invoice.value.remarks = `${date.format("MMMM")} ${date.format("YYYY")}`;
  }
});

// Component methods
const onDatesChange = (date: any): void => {
  if (date === null) {
    fortnox_invoice.value.invoice_date = null;
  } else {
    fortnox_invoice.value.invoice_date = date.format("YYYY-MM-DD");
  }
};

const handleSendToFortnox = (): void => {
  $validator?.validateAll().then((result) => {
    if (result) {
      emits("send-to-fortnox", fortnox_invoice.value);
      emits("close");
    }
  });
};
</script>
