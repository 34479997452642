import { TableColumnInterface } from "@/common/types";

export const questionsColumns: Array<TableColumnInterface> = [
  {
    title: "Title",
    key: "title",
    dataIndex: "title",
    scopedSlots: { customRender: "question_title" },
    sorter: true,
  },
  {
    title: "Date of creation",
    key: "created_at",
    dataIndex: "created_at",
    scopedSlots: { customRender: "date" },
    sorter: true,
  },
  {
    title: "Tags",
    key: "quiz_tags",
    dataIndex: "quiz_tags",
    scopedSlots: { customRender: "quiz_tags" },
  },
  {
    title: "Status",
    key: "status",
    dataIndex: "status",
    scopedSlots: { customRender: "status" },
    sorter: true,
  },
  {
    title: "Actions",
    key: "actions",
    dataIndex: "status",
    scopedSlots: { customRender: "actions" },
  },
];
