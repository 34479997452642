<template>
  <a-layout-content class="my-4 mx-3">
    <div class="container">
      <div class="flex-center-between mb-4">
        <div class="w-100 flex-center-between">
          <router-link
            :to="{ name: 'project', params: { projectId: parseInt($route.params.projectId) } }"
            tag="h3"
            class="m-0 action-default"
          >
            <a-icon type="arrow-left" class="mr-2" />Back
          </router-link>

          <h2 class="m-0 ml-2">Request Invoice</h2>

          <a-button type="primary" @click="() => handleStoreInvoice()">Request</a-button>
        </div>
      </div>

      <div class="card mt-4">
        <div class="d-flex justify-content-between mb-3">
          <h2 class="m-0">Invoice information</h2>
        </div>

        <a-row :gutter="16" class="d-flex align-items-end">
          <a-col span="8">
            Request invoice for date:
            <a-date-picker
              :value="parseDate(newInvoice.date)"
              class="w-100 mt-1"
              @change="(date) => onDatesChange(date, 'date')"
            />
          </a-col>
          <a-col span="16">
            <a-textarea
              v-model="newInvoice.comment"
              :auto-size="{ minRows: 2 }"
              placeholder="Comments..."
            />
          </a-col>
        </a-row>
      </div>

      <div class="card mt-4">
        <div class="d-flex justify-content-between mb-3">
          <h2 class="m-0">Items</h2>
        </div>

        <a-form layout="vertical" data-vv-scope="add-item" class="mb-4">
          <a-row :gutter="16">
            <a-col span="16">
              <a-form-item
                :validate-status="$validator.errors.first('add-item.description') ? 'error' : ''"
                :help="$validator.errors.first('add-item.description')"
                label="Description"
              >
                <a-input
                  v-model="item.description"
                  v-validate="'required'"
                  data-vv-name="description"
                />
              </a-form-item>
            </a-col>
            <a-col span="8">
              <a-form-item
                :validate-status="$validator.errors.first('add-item.cost_center_id') ? 'error' : ''"
                :help="$validator.errors.first('add-item.cost_center_id')"
                label="Cost Center"
              >
                <a-select
                  v-model="item.cost_center_id"
                  v-validate="'required'"
                  placeholder="Select cost center"
                  data-vv-name="cost_center_id"
                  data-vv-as="cost center"
                >
                  <a-select-option v-for="cc in costCenters" :key="cc.id">
                    {{ cc.name }} ({{ cc.manager.name }})
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="16" class="d-flex align-items-end">
            <a-col span="6">
              <a-form-item
                :validate-status="$validator.errors.first('add-item.quantity') ? 'error' : ''"
                :help="$validator.errors.first('add-item.quantity')"
                label="Quantity"
              >
                <a-input
                  v-model="item.quantity"
                  v-validate="'required|integer|min_value:0'"
                  data-vv-name="quantity"
                  type="number"
                />
              </a-form-item>
            </a-col>
            <a-col span="6">
              <a-form-item
                :validate-status="$validator.errors.first('add-item.unit_cost') ? 'error' : ''"
                :help="$validator.errors.first('add-item.unit_cost')"
                label="Unit cost"
              >
                <a-input
                  v-model="item.unit_cost_cents"
                  v-validate="'required|decimal|min_value:0'"
                  data-vv-name="unit_cost"
                  data-vv-as="unit cost"
                  type="number"
                >
                  <template #addonAfter>
                    <a-select v-model="item.unit_cost_currency" style="width: 80px">
                      <a-select-option v-for="c in currencies" :key="c" :value="c">
                        {{ c }}
                      </a-select-option>
                    </a-select>
                  </template>
                </a-input>
              </a-form-item>
            </a-col>
            <a-col span="6">
              <a-form-item label="Total Cost">
                <a-input
                  :value="previewTotalAmount"
                  :addon-after="item.unit_cost_currency"
                  disabled
                />
              </a-form-item>
            </a-col>
            <a-col span="6">
              <a-form-item label>
                <a-button block type="primary" @click="handleAddItem">
                  <a-icon type="plus" />Add
                </a-button>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>

        <a-table
          :columns="invoiceRequestItemsTableColumns"
          :data-source="newInvoice.items"
          :pagination="false"
          :scroll="{ x: 400 }"
          row-key="description"
        >
          <template #description="description, i">
            <small>{{ getCostCenterDataById(i.cost_center_id) }}</small>
            <div class="d-flex align-items-center">{{ description }}</div>
          </template>
          <template #unitCost="text, i">
            <span> {{ i.unit_cost_cents }} {{ i.unit_cost_currency }} </span>
          </template>

          <template #totalCost="text, i">
            <span> {{ i.unit_cost_cents * i.quantity }} {{ i.unit_cost_currency }} </span>
          </template>

          <template #actions="text, i">
            <a-icon
              type="minus-circle"
              class="action-danger"
              @click="handleRemoveItem(newInvoice.items.indexOf(i))"
            />
          </template>
        </a-table>
      </div>
    </div>
  </a-layout-content>
</template>

<script setup lang="ts">
import { ref, getCurrentInstance, computed, onMounted } from "vue";
import { BaseAclInterface } from "@/modules/authentication/types";
import { useAuthenticationStore } from "@/modules/authentication/_store";
import { apiGetProject } from "@/modules/projects/_utils/api";
import { ProjectInterface } from "@/modules/projects/types";
import { invoiceRequestItemsTableColumns } from "../_utils/tables";
import { apiStoreProjectInvoice } from "../_utils/api";
import { CURRENCIES } from "@/utils";
import useMixin from "@/useMixin";
import moment from "@/date";
import { useCostCentersStore } from "@/modules/cost_centers/_store";
import { CostCenterInterface } from "@/modules/cost_centers/types";
import useFilters from "@/useFilters";

// Pinia
const authenticationStore = useAuthenticationStore();
const costCentersStore = useCostCentersStore();
const baseAcl = computed<BaseAclInterface>(() => authenticationStore.baseAcl);
const costCenters = computed<Array<CostCenterInterface>>(() => costCentersStore.cost_centers);

// Mixins
const { setObject, toMoneyCents } = useMixin();

// Filters
const { parseDate } = useFilters();

// Instances
const instance = getCurrentInstance();
const $route = instance?.proxy.$route;
const $validator = instance?.proxy.$validator;
const $message = instance?.proxy.$message;
const $router = instance?.proxy.$router;
const $error = instance?.proxy.$error;

// Data properties
const project = ref<ProjectInterface | undefined>(undefined);
const currencies = ref<Array<string>>(CURRENCIES);
const item = ref<any>({
  description: undefined,
  quantity: undefined,
  unit_cost_cents: undefined,
  cost_center_id: undefined,
  unit_cost_currency: "SEK",
});
const newInvoice = ref<any>({
  date: moment().format("YYYY-MM-DD"),
  comment: undefined,
  status: "requested",
  type: "RequestedInvoice",
  items: [],
});

// Lifecycle hooks
onMounted(() => {
  costCentersStore.getCostCenters();
  apiGetProject(parseInt($route?.params.projectId as string)).then(
    (res: any) => (project.value = res.data.data)
  );
});

// Computed properties
const previewTotalAmount = computed((): number => {
  return item.value.quantity && item.value.unit_cost_cents
    ? item.value.quantity * item.value.unit_cost_cents
    : 0;
});

// Component methods
const onDatesChange = (date: any, attribute: string): void => {
  newInvoice.value[attribute] = date ? date.format("YYYY-MM-DD") : moment().format("YYYY-MM-DD");
};

const checkCurrencyDifferenceForItems = (): boolean => {
  return newInvoice.value.items.every(
    (item: any) => item.unit_cost_currency === newInvoice.value.items[0].unit_cost_currency
  );
};

const handleAddItem = (): void => {
  $validator?.validateAll("add-item").then((result) => {
    if (result) {
      newInvoice.value.items.push(item.value);
      var currency = item.value.unit_cost_currency;
      item.value = setObject(item.value, undefined);
      item.value.unit_cost_currency = currency;
    }
  });
};

const handleRemoveItem = (index: number): void => {
  newInvoice.value.items = [
    ...newInvoice.value.items.slice(0, index),
    ...newInvoice.value.items.slice(index + 1),
  ];
};

const getCostCenterDataById = (id: number): string => {
  const cc = costCenters.value.find((cc: any) => cc.id === id);
  return `${cc?.name} (${cc?.manager.name})`;
};

const changeItemCostsToCents = (): void => {
  newInvoice.value.items.map((item: any) => {
    item.unit_cost_cents = toMoneyCents(item.unit_cost_cents);
  });
};

const handleStoreInvoice = (): void => {
  if (checkCurrencyDifferenceForItems()) {
    changeItemCostsToCents();
    if (project.value) {
      apiStoreProjectInvoice(project.value.id, newInvoice.value)
        .then((res: any) => {
          $message?.success("Invoice requested successfully!", 3);
          if (baseAcl.value && (baseAcl.value.isFinancial || baseAcl.value.isCr) && project.value) {
            $router?.push({
              name: "invoice",
              params: {
                projectId: project.value.id + "",
                invoiceId: res.data.data.id,
              },
            });
          } else {
            if (project.value) {
              $router?.push({
                name: "project",
                params: { projectId: project.value.id + "" },
              });
            }
          }
        })
        .catch(() => $message?.error("Couldn't request invoice!", 3));
    }
  } else {
    $error?.({
      title: "Multiple Currencies",
      content:
        "All invoice items must have the same currency to request an invoice! Please remove the items with different currencies or convert them to a specific currency that you wish.",
    });
  }
};
</script>
