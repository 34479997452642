<template>
  <a-layout-content class="m-5">
    <div class="container">
      <h2>Consultant Profiles</h2>

      <a-tabs :active-key="tab" @change="handleTabChanged">
        <a-tab-pane key="knowledge-domains" tab="Knowledge Domains">
          <KnowledgeDomains
            :knowledge-domains="knowledgeDomains"
            :skills="skills"
            @store-knowledge-domain="handleStoreKnowledgeDomain"
            @update-knowledge-domain="handleUpdateKnowledgeDomain"
            @destroy-knowledge-domain="handleDestroyKnowledgeDomain"
            @store-knowledge-domain-skill="handleStoreKnowledgeDomainSkill"
            @destroy-knowledge-domain-skill="handleDestroyKnowledgeDomainSkill"
          />
        </a-tab-pane>

        <a-tab-pane key="courses" tab="Courses">
          <Courses
            :courses="courses"
            @store-course="(newCourse) => handleStoreCourse(newCourse)"
            @update-course="handleUpdateCourse"
            @destroy-course="handleDestroyCourse"
          />
        </a-tab-pane>

        <a-tab-pane key="characteristics" tab="Characteristics">
          <Characteristics
            :characteristics="characteristics"
            @store-characteristic="handleStoreCharacteristic"
            @update-characteristic="handleUpdateCharacteristic"
            @destroy-characteristic="handleDestroyCharacteristic"
          />
        </a-tab-pane>
      </a-tabs>
    </div>
  </a-layout-content>
</template>

<script lang="ts">
import { defineComponent, ref, getCurrentInstance, watch, onMounted } from "vue";
import { useAuthenticationStore } from "@/modules/authentication/_store";
import KnowledgeDomains from "../_components/KnowledgeDomains.vue";
import Characteristics from "../_components/Characteristics.vue";
import { apiGetSkills } from "../../skills/_utils/api";
import Courses from "../_components/Courses.vue";
import {
  apiDestroyKnowledgeDomainSkill,
  apiStoreKnowledgeDomainSkill,
  apiDestroyKnowledgeDomain,
  apiUpdateKnowledgeDomain,
  apiDestroyCharacteristic,
  apiStoreKnowledgeDomain,
  apiUpdateCharacteristic,
  apiGetKnowledgeDomains,
  apiStoreCharacteristic,
  apiGetCharacteristics,
  apiDestroyCourse,
  apiUpdateCourse,
  apiStoreCourse,
  apiGetCourses,
} from "../_utils/api";
import {
  KnowledgeDomainPayloadInterface,
  CharacteristicPayloadInterface,
  KnowledgeDomainInterface,
  CharacteristicInterface,
  CoursePayloadInterface,
  CourseInterface,
  SkillInterface,
} from "../types";

export default defineComponent({
  components: {
    KnowledgeDomains,
    Characteristics,
    Courses,
  },
  // Router guards
  beforeRouteEnter(to: any, from: any, next: any): void {
    const authenticationStore = useAuthenticationStore();
    authenticationStore.isInternal ? next() : next({ name: "dashboard" });
  },
  setup() {
    // Instances
    const instance = getCurrentInstance();
    const $route = instance?.proxy.$route;
    const $router = instance?.proxy.$router;
    const $message = instance?.proxy.$message;

    // Data properties
    const knowledgeDomains = ref<Array<KnowledgeDomainInterface>>([]);
    const characteristics = ref<Array<CharacteristicInterface>>([]);
    const courses = ref<Array<CourseInterface>>([]);
    const skills = ref<Array<SkillInterface>>([]);
    const tab = ref<string>("knowledge-domains");

    // Watchers
    watch(
      () => $route?.hash,
      // @ts-ignore
      (hash: string) => {
        changeTab(hash.substring(1));
      }
    );

    // Lifecycle hooks
    onMounted(() => {
      changeTab($route?.hash as string);
      apiGetKnowledgeDomains().then(({ data }: any) => (knowledgeDomains.value = data.data));
      apiGetCourses().then(({ data }: any) => (courses.value = data.data));
      apiGetCharacteristics().then(({ data }: any) => (characteristics.value = data.data));
      apiGetSkills().then(({ data }: any) => (skills.value = data.data));
    });

    // Class methods
    const handleStoreKnowledgeDomain = (newKnowledgeDomain: KnowledgeDomainPayloadInterface) => {
      apiStoreKnowledgeDomain(newKnowledgeDomain)
        .then(() =>
          apiGetKnowledgeDomains().then(({ data }: any) => (knowledgeDomains.value = data.data))
        )
        .then(() => $message?.success("Knowledge domain added successfully!", 3))
        .catch(() => $message?.error("Couldn't add knowledge domain!", 3));
    };

    const handleUpdateKnowledgeDomain = (
      updatedKnowledgeDomain: KnowledgeDomainPayloadInterface
    ) => {
      apiUpdateKnowledgeDomain(updatedKnowledgeDomain)
        .then(() =>
          apiGetKnowledgeDomains().then(({ data }: any) => (knowledgeDomains.value = data.data))
        )
        .then(() => $message?.success("Knowledge domain updated successfully!", 3))
        .catch(() => $message?.error("Couldn't update knowledge domain!", 3));
    };

    const handleDestroyKnowledgeDomain = (knowledgeDomainId: number) => {
      apiDestroyKnowledgeDomain(knowledgeDomainId)
        .then(() =>
          apiGetKnowledgeDomains().then(({ data }: any) => (knowledgeDomains.value = data.data))
        )
        .then(() => $message?.success("Knowledge domain deleted successfully!", 3))
        .catch(() => $message?.error("Couldn't delete knowledge domain"));
    };

    const handleStoreCourse = (newCourse: CoursePayloadInterface) => {
      apiStoreCourse(newCourse)
        .then(() => apiGetCourses().then(({ data }: any) => (courses.value = data.data)))
        .then(() => $message?.success("Course added successfully!", 3))
        .catch(() => $message?.error("Couldn't add course!", 3));
    };

    const handleUpdateCourse = (updatedCourse: CoursePayloadInterface) => {
      apiUpdateCourse(updatedCourse)
        .then(() => apiGetCourses().then(({ data }: any) => (courses.value = data.data)))
        .then(() => $message?.success("Course updated successfully!", 3))
        .catch(() => $message?.error("Couldn't update course!", 3));
    };

    const handleDestroyCourse = (courseId: number) => {
      apiDestroyCourse(courseId)
        .then(() => apiGetCourses().then(({ data }: any) => (courses.value = data.data)))
        .then(() => $message?.success("Course deleted successfully!", 3))
        .catch(() => $message?.error("Couldn't delete course"));
    };

    const handleStoreCharacteristic = (newCharacteristic: CharacteristicPayloadInterface) => {
      apiStoreCharacteristic(newCharacteristic)
        .then(() =>
          apiGetCharacteristics().then(({ data }: any) => (characteristics.value = data.data))
        )
        .then(() => $message?.success("Characteristic added successfully!", 3))
        .catch(() => $message?.error("Couldn't add characteristic!", 3));
    };

    const handleUpdateCharacteristic = (updatedCharacteristic: CharacteristicPayloadInterface) => {
      apiUpdateCharacteristic(updatedCharacteristic)
        .then(() =>
          apiGetCharacteristics().then(({ data }: any) => (characteristics.value = data.data))
        )
        .then(() => $message?.success("Characteristic updated successfully!", 3))
        .catch(() => $message?.error("Couldn't update characteristic!", 3));
    };

    const handleDestroyCharacteristic = (characteristicId: number) => {
      apiDestroyCharacteristic(characteristicId)
        .then(() =>
          apiGetCharacteristics().then(({ data }: any) => (characteristics.value = data.data))
        )
        .then(() => $message?.success("Characteristic deleted successfully!", 3))
        .catch(() => $message?.error("Couldn't delete characteristic"));
    };

    const handleStoreKnowledgeDomainSkill = (knowledgeDomainSkill: any) => {
      apiStoreKnowledgeDomainSkill(knowledgeDomainSkill)
        .then(() =>
          apiGetKnowledgeDomains().then(({ data }: any) => (knowledgeDomains.value = data.data))
        )
        .then(() => $message?.success("Skill added successfully!", 3))
        .catch(() => $message?.error("Couldn't add skill!", 3));
    };

    const handleDestroyKnowledgeDomainSkill = (
      knowledgeDomainId: number,
      knowledgeDomainSkillId: number
    ) => {
      apiDestroyKnowledgeDomainSkill(knowledgeDomainId, knowledgeDomainSkillId)
        .then(() =>
          apiGetKnowledgeDomains().then(({ data }: any) => (knowledgeDomains.value = data.data))
        )
        .then(() => $message?.success("Skill removed successfully!", 3))
        .catch(() => $message?.error("Couldn't remove skill!", 3));
    };

    const changeTab = (hash: string) => {
      if (hash) {
        const t = hash.substring(1);
        if (t !== tab.value) {
          handleTabChanged(t);
        }
      }
    };

    const isTabValid = (tab: string) => {
      const validTabs = ["knowledge-domains", "courses", "characteristics"];
      return validTabs.includes(tab);
    };

    const handleTabChanged = (t: string) => {
      if (!isTabValid(t)) {
        t = "";
      }
      tab.value = t;
      $router?.replace(t ? `#${t}` : "");
    };

    return {
      handleDestroyKnowledgeDomainSkill,
      handleStoreKnowledgeDomainSkill,
      handleDestroyKnowledgeDomain,
      handleDestroyCharacteristic,
      handleUpdateKnowledgeDomain,
      handleStoreKnowledgeDomain,
      handleUpdateCharacteristic,
      handleStoreCharacteristic,
      handleDestroyCourse,
      handleUpdateCourse,
      handleStoreCourse,
      knowledgeDomains,
      handleTabChanged,
      characteristics,
      courses,
      skills,
      tab,
    };
  },
});
</script>
