import { defineStore, acceptHMRUpdate } from "pinia";
import {
  apiGetUserBookmarkedFilters,
  apiStoreUserBookmarkedFilter,
  apiUpdateUserBookmarkedFilter,
  apiDestroyUserBookmarkedFilter,
} from "@/modules/bookmarked_filters/_utils/api";

export const useBookmarkedFiltersStore = defineStore("bookmarked_filters", {
  actions: {
    getUserBookmarkedFilters(userId: number) {
      return new Promise((resolve, reject) => {
        apiGetUserBookmarkedFilters(userId)
          .then((res: any) => {
            resolve(res.data.data);
          })
          .catch((err: any) => {
            reject(err);
          });
      });
    },

    storeUserBookmarkedFilter({ userId, newBookmarkedFilter }: any) {
      return new Promise((resolve, reject) => {
        apiStoreUserBookmarkedFilter(userId, newBookmarkedFilter)
          .then((res: any) => {
            resolve(res.data.data);
          })
          .catch((err: any) => {
            reject(err);
          });
      });
    },

    updateUserBookmarkedFilter({ userId, bookmarkedFilterId, updatedBookmarkedFilter }: any) {
      return new Promise((resolve, reject) => {
        apiUpdateUserBookmarkedFilter(userId, bookmarkedFilterId, updatedBookmarkedFilter)
          .then((res: any) => {
            resolve(res.data.data);
          })
          .catch((err: any) => {
            reject(err);
          });
      });
    },

    destroyUserBookmarkedFilter({ userId, bookmarkedFilterId }: any) {
      return new Promise((resolve, reject) => {
        apiDestroyUserBookmarkedFilter(userId, bookmarkedFilterId)
          .then(() => {
            resolve(bookmarkedFilterId);
          })
          .catch((err: any) => {
            reject(err);
          });
      });
    },
  },
});

// HMR
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useBookmarkedFiltersStore, import.meta.hot));
}
