<template>
  <a-modal
    :visible="visible"
    :destroy-on-close="true"
    @cancel="$emit('close')"
    @ok="handleUpdateKnowledgeDomain"
  >
    <h3>Edit knowledge domain</h3>

    <a-form layout="horizontal">
      <a-form-item
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
        :validate-status="errors.first('title') ? 'error' : ''"
        :help="errors.first('title')"
        label="Title"
      >
        <a-input
          v-model="updatedKnowledgeDomain.title"
          v-validate="'required|max:255'"
          placeholder="Knowledge domain title"
          data-vv-name="title"
        />
      </a-form-item>

      <a-form-item
        :colon="false"
        :validate-status="errors.first('description_productive') ? 'error' : ''"
        :help="errors.first('description_productive')"
        label="Productive"
      >
        <a-textarea
          v-model="updatedKnowledgeDomain.description_productive"
          v-validate="'required'"
          :auto-size="{ minRows: 3 }"
          data-vv-name="description_productive"
          data-vv-as="productive description"
          placeholder="Description for Productive"
        />
      </a-form-item>

      <a-form-item :colon="false" label="Advanced">
        <a-textarea
          v-model="updatedKnowledgeDomain.description_advanced"
          :auto-size="{ minRows: 3 }"
          placeholder="Description for Advanced"
        />
      </a-form-item>

      <a-form-item :colon="false" label="Expert">
        <a-textarea
          v-model="updatedKnowledgeDomain.description_expert"
          :auto-size="{ minRows: 3 }"
          placeholder="Description for Expert"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script setup lang="ts">
import { ref, watch, getCurrentInstance } from "vue";
import useMixin from "@/useMixin";

// Props
const props = defineProps({
  knowledgeDomain: { type: Object, default: undefined },
  visible: { type: Boolean, default: false },
});

// Emits
const emits = defineEmits(["update-knowledge-domain", "close"]);

// Mixins
const { setObject } = useMixin();

// Data Properties
const updatedKnowledgeDomain = ref<any>({
  id: undefined,
  title: undefined,
  description_productive: undefined,
  description_advanced: undefined,
  description_expert: undefined,
});

// Watchers
watch(
  () => props.visible,
  (newProp, oldProp) => {
    if (newProp && !oldProp) {
      updatedKnowledgeDomain.value = setObject(updatedKnowledgeDomain.value, props.knowledgeDomain);
    }
  }
);

// Class Methods
const instance = getCurrentInstance();
const $validator = instance?.proxy.$validator;
const handleUpdateKnowledgeDomain = () => {
  $validator?.validateAll().then((result) => {
    if (result) {
      emits("update-knowledge-domain", updatedKnowledgeDomain.value);
      emits("close");
    }
  });
};
</script>
