import axios from "@/network";
import { MinimalUserInterface } from "./modules/users/types";
import {
  MinimalProjectInterface,
  MinimalProjectInterfaceWithMinimalClient,
} from "./modules/projects/types";
import { MinimalClientInterface } from "./modules/clients/types";
import { ReceiptInterface } from "./modules/receipts/types";
import { KnowledgeDomainInterface } from "./modules/consultant_profiles/types";
import { CompanyInterface, MinimalCompanyInterface } from "./types/company";
import { MinimalCvInterface } from "./modules/cv/types";

export function getClientsCompactList(): Promise<Array<MinimalClientInterface>> {
  return new Promise((resolve, reject) => {
    axios
      .get("/clients/minimal_list")
      .then((res) => resolve(res.data.data))
      .catch((err) => reject(err.data));
  });
}

export function getProjectsCompactList(): Promise<Array<MinimalProjectInterface>> {
  return new Promise((resolve, reject) => {
    axios
      .get("/projects/minimal_list")
      .then((res) => resolve(res.data.data))
      .catch((err) => reject(err.data));
  });
}

export function getProjectsAndClientsCompactList(): Promise<
  Array<MinimalProjectInterfaceWithMinimalClient>
> {
  return new Promise((resolve, reject) => {
    axios
      .get("/projects/minimal_list_clients")
      .then((res) => resolve(res.data.data))
      .catch((err) => reject(err.data));
  });
}

export function getUsersCompactList(params = {}): Promise<Array<MinimalUserInterface>> {
  return new Promise((resolve, reject) => {
    axios
      .get("/users/minimal_list", { params })
      .then((res) => resolve(res.data.data))
      .catch((err) => reject(err.data));
  });
}

export function getReceiptsCompactList(params: any): Promise<Array<ReceiptInterface>> {
  return new Promise((resolve, reject) => {
    axios
      .get("/receipts/minimal_list", { params })
      .then((res) => resolve(res.data.data))
      .catch((err) => reject(err.data));
  });
}

export function getCompanyCompactList(params?: any): Promise<Array<CompanyInterface>> {
  return new Promise((resolve, reject) => {
    axios
      .get("/companies/minimal_list", { params })
      .then((res) => resolve(res.data.data))
      .catch((err) => reject(err.data));
  });
}

export function getKnowledgeDomainsCompactList(): Promise<Array<KnowledgeDomainInterface>> {
  return new Promise((resolve, reject) => {
    axios
      .get("/knowledge_domains/minimal_list")
      .then((res) => resolve(res.data.data))
      .catch((err) => reject(err.data));
  });
}

export function getCvsCompactList(): Promise<Array<MinimalCvInterface>> {
  return new Promise((resolve, reject) => {
    axios
      .get("/cvs/minimal_list")
      .then((res) => resolve(res.data.data))
      .catch((err) => reject(err.data));
  });
}

export function searchCompanies(query: string): Promise<Array<MinimalCompanyInterface>> {
  return new Promise((resolve, reject) => {
    axios
      .get("/companies/search", { params: { query } })
      .then((res) => resolve(res.data.data))
      .catch((err) => reject(err.data));
  });
}
