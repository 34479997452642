<template>
  <a-modal
    :visible="visible"
    :destroy-on-close="true"
    title="Add Tag"
    ok-text="Save"
    @ok="() => handleStoreQuizTag()"
    @cancel="() => $emit('close')"
  >
    <a-form layout="horizontal">
      <SkInput
        v-model="newQuizTag.name"
        v-validate="'required|max:255'"
        :error="$validator.errors.first('name')"
        data-vv-name="name"
        label="Name"
        placeholder="Tag name"
      />
    </a-form>
  </a-modal>
</template>

<script setup lang="ts">
import { ref, watch, toRef, getCurrentInstance } from "vue";
import { QuizTagPayloadInterface } from "../types";
import useMixin from "@/useMixin";

// Props
const props = defineProps({
  visible: { type: Boolean, default: false },
});

// Emits
const emits = defineEmits(["store-quiz-tag", "close"]);

// Mixins
const { setObject } = useMixin();

// Instances
const instance = getCurrentInstance();
const $validator = instance?.proxy.$validator;

// Data properties
const newQuizTag = ref<QuizTagPayloadInterface>({
  name: undefined,
});

// Watchers
watch(toRef(props, "visible"), (newProp, oldProp) => {
  if (newProp && !oldProp) {
    newQuizTag.value = setObject(newQuizTag.value, undefined);
  }
});

// Component methods
const handleStoreQuizTag = (): void => {
  $validator?.validateAll().then((result) => {
    if (result) {
      emits("store-quiz-tag", newQuizTag.value);
      emits("close");
    }
  });
};
</script>
