import { RouteInterface } from "@/common/types";
import Projects from "./_views/Projects.vue";
import Project from "./_views/Project.vue";

export default [
  {
    path: "projects/:projectId",
    component: Project,
    name: "project",
    meta: { auth: true },
  },
  {
    path: "projects",
    component: Projects,
    name: "projects",
    meta: { auth: true },
  },
] as Array<RouteInterface>;
