import Transactions from "./_views/Transactions.vue";
import { RouteInterface } from "@/common/types";
import Skp from "./_views/SKP.vue";

export default [
  {
    path: "transactions",
    component: Transactions,
    name: "transactions",
    meta: { auth: true },
  },
  {
    path: "skp",
    component: Skp,
    name: "skp",
    meta: { auth: true },
  },
] as Array<RouteInterface>;
