import { TableColumnInterface } from "@/common/types";

export const usersColumns: Array<TableColumnInterface> = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    sorter: true,
    scopedSlots: { customRender: "name" },
  },
  {
    title: "Reports Lock Date",
    dataIndex: "report_locking_date",
    key: "report_locking_date",
    sorter: true,
  },
  {
    title: "Total workload",
    dataIndex: "workload",
    key: "workload",
    scopedSlots: { customRender: "workload" },
    sorter: true,
    width: 150,
  },
  {
    title: "Status",
    scopedSlots: { customRender: "status" },
  },
  {
    title: "Profile description status",
    dataIndex: "profile_description_status",
    key: "profile_description_status",
    scopedSlots: { customRender: "profile_description_status" },
  },
  {
    key: "action",
    scopedSlots: { customRender: "action" },
  },
];
