<template>
  <div>
    <a-textarea v-model="editableText" :auto-size="{ minRows: 3 }" />

    <div class="d-flex flex-row-reverse justify-content-between mt-2">
      <span>
        <a-button type="default" class="mr-2" @click="handleCancel()">Cancel</a-button>
        <a-button type="primary" @click="handleSave()">Save</a-button>
      </span>
      <span v-if="resetText || withClear">
        <a-button v-if="resetText" type="default" class="mr-2" @click="editableText = resetText">
          Reset
        </a-button>
        <a-button v-if="withClear" type="default" @click="editableText = ''">Clear</a-button>
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onBeforeMount } from "vue";

// Props
const props = defineProps({
  resetText: { type: String, default: undefined },
  withClear: { type: Boolean, default: true },
  text: { type: String, default: undefined },
});

// Emits
const emits = defineEmits(["save", "cancel"]);

// Data Properties
const editableText = ref<string>("");
const newText = ref<string>("");

// Life-cycle Hooks
onBeforeMount(() => {
  editableText.value = props.text;
  newText.value = props.text;
});

// Class Methods
const handleSave = (): void => {
  newText.value = editableText.value;
  emits("save", newText.value);
};

const handleCancel = (): void => {
  editableText.value = newText.value;
  emits("cancel");
};
</script>
