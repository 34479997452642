<template>
  <a-modal
    :visible="visible"
    :destroy-on-close="true"
    title="Update Role Extension"
    ok-text="Update"
    @ok="handleUpdateRoleExtension"
    @cancel="$emit('close')"
  >
    <a-form-item
      :label-col="{ span: 8 }"
      :wrapper-col="{ span: 16 }"
      :validate-status="$validator.errors.first('new_end_date') ? 'error' : ''"
      :help="$validator.errors.first('new_end_date')"
      label="New end date"
    >
      <a-date-picker
        v-validate="'required'"
        :value="newRoleExtension.end_date"
        :disabled-date="isDisabledDate"
        class="w-100"
        data-vv-name="new_end_date"
        data-vv-as="new end date"
        @change="(d) => (newRoleExtension.end_date = d.format('YYYY-MM-DD'))"
      />
    </a-form-item>

    <SkMoney
      v-model="newHourlyRateInput"
      v-validate="'required|decimal|min_value:0'"
      :error="$validator.errors.first('new_hourly_rate')"
      :currency="newRoleExtension.hourly_rate_currency"
      data-vv-name="new_hourly_rate"
      data-vv-as="new hourly rate"
      label="New hourly rate"
      placeholder="Hourly rate"
      @set-currency="(v) => (newRoleExtension.hourly_rate_currency = v)"
    />

    <SkInput
      v-model="newWorkloadInput"
      v-validate="'required|integer|min_value:0|max_value:100'"
      :error="$validator.errors.first('new_workload')"
      data-vv-name="new_workload"
      data-vv-as="new workload"
      label="New workload"
      placeholder="Workload in percentages"
      addon-after="%"
    />

    <SkInput
      v-model="newRoleExtension.notice_period"
      v-validate="'required|integer|min_value:0'"
      :error="$validator.errors.first('notice_period')"
      data-vv-name="notice_period"
      data-vv-as="notice period"
      label="Notice period"
      placeholder="Notice period in days"
    />

    <h4>
      <strong>Warning</strong>
      <a-icon type="warning" class="ml-2" />
    </h4>

    <h4>
      If any of the fields above are modified, the colleague will be notified of such changes and
      will be prompted to give their approval.
    </h4>

    <hr class="mt-3 mb-3" />

    <SkInput
      v-model="newRoleExtension.pipedrive_id"
      v-validate="'integer|min_value:0'"
      :error="$validator.errors.first('pipedrive_id')"
      data-vv-name="pipedrive_id"
      data-vv-as="PipeDrive ID"
      label="PipeDrive ID"
      placeholder="PipeDrive ID"
    />

    <SkTextarea
      v-model="newRoleExtension.comment"
      data-vv-name="comment"
      placeholder="Comment"
      label="Comment"
    />
  </a-modal>
</template>

<script setup lang="ts">
import { ref, watch, getCurrentInstance } from "vue";
import { RoleExtensionInterface, RoleInterface } from "@/modules/roles/types";
import { BaseAclInterface } from "@/modules/authentication/types";
import SkTextarea from "../../../common/SkTextarea.vue";
import { disableDate } from "../_utils/disabled-dates";
import SkInput from "../../../common/SkInput.vue";
import useMixin from "@/useMixin";

// Props
const props = defineProps({
  visible: { type: Boolean, default: false },
  acl: { type: Object as () => BaseAclInterface, default: undefined },
  role: { type: Object as () => RoleInterface, default: undefined },
  roleExtension: { type: Object as () => RoleExtensionInterface, default: undefined },
});

// Emits
const emits = defineEmits(["reissue-role-extension", "update-role-extension", "close"]);

// Mixins
const { setObject } = useMixin();

// Instances
const instance = getCurrentInstance();
const $validator = instance?.proxy?.$validator;
const $message = instance?.proxy?.$message;

// Data properties
const initialRoleExtension = ref<RoleExtensionInterface>({
  end_date: "",
  hourly_rate_cents: 0,
  hourly_rate_currency: "",
  workload: 0,
  notice_period: 0,
  initiated_by: "",
  pipedrive_id: 0,
  comment: "",
});
const newRoleExtension = ref<RoleExtensionInterface>({
  end_date: "",
  hourly_rate_cents: 0,
  hourly_rate_currency: "",
  workload: 0,
  notice_period: 0,
  initiated_by: "",
  pipedrive_id: 0,
  comment: "",
});
const newHourlyRateInput = ref<string>("");
const newWorkloadInput = ref<string>("");

// Watchers
watch(
  () => props.visible,
  (newProp, oldProp) => {
    if (newProp && !oldProp) {
      newRoleExtension.value = setObject(newRoleExtension.value, undefined);

      newRoleExtension.value.id = props.roleExtension.id;
      newRoleExtension.value.role_id = props.role.id;
      newRoleExtension.value.end_date = props.roleExtension.end_date;
      let hourlyRate = props.roleExtension.hourly_rate ?? props.role.hourly_rate;
      newHourlyRateInput.value = ((hourlyRate?.value ?? 0) / 100).toString();
      newRoleExtension.value.hourly_rate_currency = hourlyRate?.currency_code ?? "";
      newWorkloadInput.value = (
        (props.roleExtension.workload ?? props.role.workload) * 100
      ).toString();
      newRoleExtension.value.notice_period =
        props.roleExtension.notice_period ?? props.role.notice_period;
      newRoleExtension.value.initiated_by = props.roleExtension.initiated_by;
      newRoleExtension.value.pipedrive_id = props.roleExtension.pipedrive_id;
      newRoleExtension.value.comment = props.roleExtension.comment;
      fillNewRoleExtensionWithInputs();

      initialRoleExtension.value = setObject(initialRoleExtension.value, newRoleExtension.value);
    }
  }
);

// Component methods
const handleUpdateRoleExtension = (): void => {
  $validator?.errors.clear();
  $validator?.validate().then((result: any) => {
    if (result) {
      fillNewRoleExtensionWithInputs();
      if (shouldRoleExtensionBeReissued()) {
        emits("reissue-role-extension", newRoleExtension.value);
      } else if (shouldRoleExtensionBeUpdated()) {
        emits("update-role-extension", newRoleExtension.value);
      } else {
        $message?.info("Role extension didn't change", 3);
      }
      emits("close");
    }
  });
};

const fillNewRoleExtensionWithInputs = (): void => {
  newRoleExtension.value.hourly_rate_cents = parseFloat(newHourlyRateInput.value) * 100;
  newRoleExtension.value.workload = parseFloat(newWorkloadInput.value) / 100;
};

const isDisabledDate = (date: any): boolean => {
  return disableDate(date, props.role);
};

const shouldRoleExtensionBeReissued = (): boolean => {
  let dateModified = newRoleExtension.value.end_date != initialRoleExtension.value.end_date;
  let hourlyRateValueModified =
    newRoleExtension.value.hourly_rate_cents != initialRoleExtension.value.hourly_rate_cents;
  let hourlyRateCurrencyModified =
    newRoleExtension.value.hourly_rate_currency != initialRoleExtension.value.hourly_rate_currency;
  let workloadModified = newRoleExtension.value.workload != initialRoleExtension.value.workload;
  let noticePeriodModified =
    newRoleExtension.value.notice_period != initialRoleExtension.value.notice_period;
  return (
    dateModified ||
    hourlyRateValueModified ||
    hourlyRateCurrencyModified ||
    workloadModified ||
    noticePeriodModified
  );
};

const shouldRoleExtensionBeUpdated = (): boolean => {
  let pipedriveIDModified =
    newRoleExtension.value.pipedrive_id != initialRoleExtension.value.pipedrive_id;
  let commentModified = newRoleExtension.value.comment != initialRoleExtension.value.comment;
  return pipedriveIDModified || commentModified;
};
</script>
