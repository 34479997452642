import { RouteInterface } from "@/common/types";
import Clients from "./_views/Clients.vue";
import Client from "./_views/Client.vue";

export default [
  {
    path: "clients",
    component: Clients,
    name: "clients",
    meta: { auth: true },
  },
  {
    path: "clients/:id",
    component: Client,
    name: "client",
    meta: { auth: true },
  },
] as Array<RouteInterface>;
