import { computeDayDifference, getDayDateRange, isWeekendDay } from "@/utils";
import { PublicHolidayInterface, ReportPayloadInterface, ReportType } from "../types";
import { computePublicHolidayEndDate } from "./public-holidays";

export function computeExpectedHours(
  start: Date,
  end: Date,
  roleWorkload: number,
  reports: Array<ReportPayloadInterface>,
  publicHolidays: Array<PublicHolidayInterface>
): number {
  const allDays = getDayDateRange(start, end);

  const weekendDays = allDays.filter((d: Date) => isWeekendDay(d));

  const publicHolidayDays = publicHolidays.flatMap((ph: PublicHolidayInterface) =>
    getDayDateRange(new Date(ph.date), computePublicHolidayEndDate(ph))
  );

  const vacationDays = reports
    .filter(
      (r: ReportPayloadInterface) =>
        r.type === ReportType.VACATION ||
        r.type === ReportType.ABSENCE ||
        r.type === ReportType.PARENTAL ||
        r.type === ReportType.SICK
    )
    .flatMap((r: ReportPayloadInterface) =>
      getDayDateRange(new Date(r.start_date!), new Date(r.end_date ?? r.start_date!))
    );

  const expectedDays = allDays
    .filter((d: Date) => !weekendDays.some((d2: Date) => isSameDay(d, d2)))
    .filter((d: Date) => !publicHolidayDays.some((d2: Date) => isSameDay(d, d2)))
    .filter((d: Date) => !vacationDays.some((d2: Date) => isSameDay(d, d2)));

  return expectedDays.length * 8.0 * roleWorkload;
}

function isSameDay(date1: Date, date2: Date): boolean {
  return computeDayDifference(date1, date2) === 0;
}
