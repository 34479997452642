import { TableColumnInterface } from "@/common/types";

export const clientsColumns: Array<TableColumnInterface> = [
  {
    title: "Name",
    dataIndex: "name",
    sorter: true,
    key: "name",
    scopedSlots: { customRender: "name" },
  },
  {
    title: "Status",
    dataIndex: "active_projects",
    key: "status",
    scopedSlots: { customRender: "status" },
  },
  {
    title: "Secrecy Level",
    dataIndex: "secrecy_ok_show_client_logo",
    key: "secrecy_level",
    scopedSlots: { customRender: "secrecy" },
  },
];
