<template>
  <a-modal
    :visible="visible"
    :destroy-on-close="true"
    title="Create Subproject"
    @ok="() => handleStoreSubproject(newSubproject)"
    @cancel="() => closeModal()"
  >
    <a-form data-vv-scope="newSubproject" layout="horizontal">
      <SkInput
        v-model="newSubproject.name"
        v-validate="'required|max:255'"
        :error="$validator.errors.first('newSubproject.name')"
        label="Name"
        placeholder="Subproject name"
        data-vv-name="name"
      />
    </a-form>

    <a-form-item
      :label-col="{ span: 8 }"
      :wrapper-col="{ span: 16 }"
      :validate-status="$validator.errors.first('newSubproject.start_date') ? 'error' : ''"
      :error="$validator.errors.first('newSubproject.start_date')"
      :help="$validator.errors.first('newSubproject.start_date')"
      label="Start date"
    >
      <a-date-picker
        :value="parseDate(newSubproject.start_date)"
        :disabled-date="disabledDateForStartDate"
        class="w-100"
        allow-clear
        placeholder="Start date"
        data-vv-name="start_date"
        data-vv-as="start date"
        @change="(date) => onDateChange('start_date', date)"
      />
    </a-form-item>

    <a-form-item
      :label-col="{ span: 8 }"
      :wrapper-col="{ span: 16 }"
      :validate-status="$validator.errors.first('newSubproject.end_date') ? 'error' : ''"
      :help="$validator.errors.first('newSubproject.end_date')"
      label="End date"
    >
      <a-date-picker
        :value="parseDate(newSubproject.end_date)"
        :disabled-date="disabledDateForEndDate"
        class="w-100"
        allow-clear
        placeholder="End date"
        data-vv-name="end_date"
        data-vv-as="end date"
        @change="(date) => onDateChange('end_date', date)"
      />
    </a-form-item>

    <a-form-item :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }" label="Description">
      <a-textarea
        v-model="newSubproject.description"
        :auto-size="{ minRows: 3 }"
        placeholder="Add a description"
      />
    </a-form-item>

    <SkInput
      v-model="newSubproject.link"
      :error="$validator.errors.first('step-info.link')"
      data-vv-name="link"
      label="URL"
      placeholder="Add a link"
    />
  </a-modal>
</template>

<script setup lang="ts">
import { ref, watch, getCurrentInstance, onMounted } from "vue";
import { ProjectInterface, NewSubprojectInterface } from "../../projects/types";
import moment from "@/date";
import { Moment } from "moment";
import useMixin from "@/useMixin";
import useFilters from "@/useFilters";

// Props
const props = defineProps({
  project: { type: Object as () => ProjectInterface, default: undefined },
  visible: { type: Boolean, default: false },
});

// Emits
const emits = defineEmits(["store-subproject", "close"]);

// Mixins
const { setObject } = useMixin();

// Filters
const { parseDate } = useFilters();

// Data properties

const newSubproject = ref<NewSubprojectInterface>({
  id: null,
  project: null,
  name: null,
  start_date: moment().format("YYYY-MM-DD"),
  end_date: undefined,
  description: undefined,
  link: undefined,
});

// Watchers
watch(
  () => props.visible,
  (newProp, oldProp) => {
    if (newProp && !oldProp) {
      newSubproject.value = setObject(newSubproject.value, undefined);
      newSubproject.value.name = null;
      newSubproject.value.start_date = moment().format("YYYY-MM-DD");
      newSubproject.value.end_date = undefined;
      newSubproject.value.description = undefined;
      newSubproject.value.link = undefined;
    }
  }
);

onMounted(() => {
  newSubproject.value.project = {
    id: props.project.id,
    name: props.project.name,
  };
});

// Class Methods
const onDateChange = (dateName: string, date: Moment): void => {
  newSubproject.value[dateName] = date ? date.format("YYYY-MM-DD") : null;
};

const disabledDateForStartDate = (current: Moment): boolean => {
  return Boolean(
    current &&
      newSubproject.value.end_date &&
      current > moment(newSubproject.value.end_date).endOf("day")
  );
};

const disabledDateForEndDate = (current: Moment): boolean => {
  return Boolean(
    current &&
      newSubproject.value.start_date &&
      current < moment(newSubproject.value.start_date).startOf("day")
  );
};

const closeModal = (): void => {
  emits("close");
};

const instance = getCurrentInstance();
const $validator = instance?.proxy?.$validator;
const handleStoreSubproject = (newSubproject: NewSubprojectInterface): void => {
  $validator?.validateAll("newSubproject").then((result) => {
    // If last step and validation pass...
    if (result) {
      emits("store-subproject", newSubproject);
      emits("close");
    }
  });
};
</script>
