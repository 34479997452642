import { defineStore, acceptHMRUpdate } from "pinia";
import { CostCenterPayloadInterface } from "@/modules/cost_centers/types";
import { CostCenterInterface } from "@/modules/cost_centers/types";
import {
  apiGetCostCenters,
  apiStoreCostCenter,
  apiUpdateCostCenter,
} from "@/modules/cost_centers/_utils/api";

export interface StateInterface {
  cost_centers: Array<CostCenterInterface>;
}

const nameSort = (a: CostCenterInterface, b: CostCenterInterface): number => {
  return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
};

export const useCostCentersStore = defineStore("cost_centers", {
  state: (): StateInterface => {
    return {
      cost_centers: [],
    };
  },
  actions: {
    getCostCenters() {
      return new Promise((resolve, reject) => {
        apiGetCostCenters()
          .then((res: any) => {
            this.setCostCenters(res.data.data);
            resolve(res.data.data);
          })
          .catch((err: any) => {
            reject(err);
          });
      });
    },

    storeCostCenter(newCostCenter: CostCenterPayloadInterface) {
      return new Promise((resolve, reject) => {
        apiStoreCostCenter(newCostCenter)
          .then((res: any) => {
            this.addCostCenter(res.data.data);
            resolve(res.data.data);
          })
          .catch((err: any) => {
            reject(err);
          });
      });
    },

    updateCostCenter(updatedCostCenter: CostCenterPayloadInterface) {
      return new Promise((resolve, reject) => {
        apiUpdateCostCenter(updatedCostCenter)
          .then((res: any) => {
            this.handleUpdateCostCenter(res.data.data);
            resolve(res.data.data);
          })
          .catch((err: any) => {
            reject(err);
          });
      });
    },

    setCostCenters(cost_centers: Array<CostCenterInterface>): void {
      this.cost_centers = cost_centers;
    },

    addCostCenter(newCostCenter: CostCenterInterface): void {
      this.cost_centers = [...this.cost_centers, newCostCenter].sort(nameSort);
    },

    handleUpdateCostCenter(updatedCostCenter: CostCenterInterface): void {
      this.cost_centers = this.cost_centers
        .map((costCenter) =>
          costCenter.id === updatedCostCenter.id ? updatedCostCenter : costCenter
        )
        .sort(nameSort);
    },
  },
});

// HMR
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCostCentersStore, import.meta.hot));
}
