<template>
  <div v-if="project" class="card">
    <div class="d-flex justify-content-between">
      <h2>Summary for {{ project.name }}</h2>
      <div class="d-flex">
        <!-- Buttons |Clear|Toggle-Filter|Create Expense| -->
        <portal-target class="mr-2" name="reports-summary-filter-buttons"></portal-target>
        <a-button
          v-if="project.status === 'active' && (acl.isAdmin || acl.isProjectManager)"
          class="mr-2"
          icon="plus"
          type="primary"
          @click="addExpenseReportModalVisible = true"
        />
      </div>
    </div>

    <reports-summary-filter
      :number-of-results="reportsPagination.total"
      :roles="reportsSummary"
      :cost-centers="costCenters"
      @apply-filters="(filter) => $emit('apply-filters', filter)"
      @clear-filters="(filter) => $emit('clear-filters', filter)"
    />

    <reports-summary
      :reports-summary="reportsSummary"
      :loading-summary="loadingSummary"
      class="mb-4"
    />

    <subprojects-summary
      :subprojects-summary="subprojectsSummary"
      :loading-summary="loadingSummary"
      class="mb-4"
    />

    <h2>Reports</h2>

    <!-- ADD EXPENSE-REPORT MODAL -->
    <add-expense-report-modal
      :visible="addExpenseReportModalVisible"
      :user="currentUser"
      :cost-centers="costCenters"
      :project="project"
      @get-cost-centers="() => $emit('get-cost-centers')"
      @store-report="(newReport) => handleStoreExpenseReport(newReport)"
      @close="addExpenseReportModalVisible = false"
    />

    <!-- REPORTS TABLE -->
    <reports-table
      :reports-page="reportsPage"
      :current-page="currentPage"
      :users="users"
      :user-to-be-edited="userToBeEdited"
      :user-roles="userRoles"
      :loading-table="loadingTable"
      :cost-centers="costCenters"
      :page-size="pageSize"
      :reports-pagination="reportsPagination"
      @get-reports-page="(params) => $emit('get-reports-page', params)"
      @destroy-report="(reportId) => $emit('handle-destroy-report', reportId)"
      @update-user-report="(updatedReport) => $emit('handle-update-report', updatedReport)"
      @update-expense-report="(updatedReport) => $emit('handle-update-report', updatedReport)"
      @handle-edit-user-report-modal-visible="
        (report) => $emit('handle-edit-user-report-modal-visible', report)
      "
      @get-cost-centers="() => $emit('get-cost-centers')"
      @store-recurring-job="(recurringJob) => $emit('store-recurring-job', recurringJob)"
    />
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import AddExpenseReportModal from "../../reports/_components/AddExpenseReportModal.vue";
import { ProfileInterface, BaseAclInterface } from "@/modules/authentication/types";
import { ReportsSummaryFilterInterface } from "@/modules/reports/types";
import ReportsTable from "../../reports/_components/ReportsTable.vue";
import { CostCenterInterface } from "@/modules/cost_centers/types";
import ReportsSummaryFilter from "./ReportsSummaryFilter.vue";
import { MinimalUserInterface } from "@/modules/users/types";
import { ProjectInterface } from "@/modules/projects/types";
import { RoleInterface } from "@/modules/roles/types";
import ReportsSummary from "./ReportsSummary.vue";
import SubprojectsSummary from "./SubprojectsSummary.vue";

// Props
const props = defineProps({
  reportsPage: { type: Array as () => Array<any>, default: () => [] },
  project: { type: Object as () => ProjectInterface, default: undefined },
  reportsSummary: { type: Array as () => Array<any>, default: () => [] },
  subprojectsSummary: { type: Array as () => Array<any>, default: () => [] },
  users: { type: Array as () => Array<MinimalUserInterface>, default: () => [] },
  currentUser: { type: Object as () => ProfileInterface, default: undefined },
  acl: { type: Object as () => BaseAclInterface, default: undefined },
  userToBeEdited: { type: Object, default: undefined },
  costCenters: { type: Array as () => Array<CostCenterInterface>, default: () => [] },
  userRoles: { type: Array as () => Array<RoleInterface>, default: () => [] },
  loadingTable: { type: Boolean, default: false },
  loadingSummary: { type: Boolean, default: false },
  filters: { type: Object as () => ReportsSummaryFilterInterface, default: undefined },
  reportsPagination: { type: Object, default: undefined },
  currentPage: { type: Number, default: 1 },
  pageSize: { type: Number, default: 25 },
});

// Emits
const emits = defineEmits(["store-expense-report"]);

// Data properties
const filterPanelVisible = ref<boolean>(false);
const addExpenseReportModalVisible = ref<boolean>(false);

// Class methods
const handleStoreExpenseReport = (newReport: any): void => {
  emits("store-expense-report", newReport);
  addExpenseReportModalVisible.value = false;
};
</script>
