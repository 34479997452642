<template>
  <a-modal
    :visible="visible"
    :destroy-on-close="true"
    :title="question ? question.title : ''"
    ok-text="Save"
    @ok="handleStoreQuizSubmission"
    @cancel="$emit('close')"
  >
    <p>{{ question?.question }}</p>
    <a-form data-vv-scope="quiz-submission">
      <a-form-item
        :validate-status="$validator.errors.first('quiz-submission.answer') ? 'error' : ''"
        :help="$validator.errors.first('quiz-submission.answer')"
      >
        <a-textarea
          v-model="quizSubmission.answer"
          v-validate="'required'"
          :auto-size="{ minRows: 2 }"
          data-vv-as="answer"
          data-vv-name="answer"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script setup lang="ts">
import { ref, watch, toRef, getCurrentInstance } from "vue";
import { QuestionInterface, SubmissionPayloadInterface } from "../types";

// Props
const props = defineProps({
  question: { type: Object as () => QuestionInterface, default: undefined },
  visible: { type: Boolean, default: false },
});

// Emits
const emits = defineEmits(["submit-answer", "close"]);

// Instances
const instance = getCurrentInstance();
const $validator = instance?.proxy.$validator;
const $confirm = instance?.proxy.$confirm;

// Data properties
const quizSubmission = ref<SubmissionPayloadInterface>({
  answer: undefined,
});

// Watchers
watch(toRef(props, "visible"), (newProp, oldProp) => {
  if (newProp && !oldProp) {
    quizSubmission.value = {
      answer: undefined,
    };
  }
});

// Class methods
const handleStoreQuizSubmission = (): void => {
  $validator?.validateAll("quiz-submission").then((result) => {
    if (result) {
      if (props.question.onboarding) {
        emits("submit-answer", props.question.id, quizSubmission.value);
        emits("close");
      } else {
        const mod = $confirm?.({
          title: "Is this your final answer? It cannot be changed later.",
          content: quizSubmission.value.answer,
          okText: "Continue",
          onOk() {
            emits("submit-answer", props.question.id, quizSubmission.value);
            emits("close");
          },
        });
      }
    }
  });
};
</script>
