<template>
  <div>
    <a-row>
      <h2>
        Feedback
        <a-popover trigger="click">
          <template #content>
            <p class="info-text mt-3">
              <strong>Purpose:</strong> The purpose of this form is to gather feedback from
              employees about what SK could be doing better.
            </p>
            <p class="info-text">
              <strong>Benefit for you:</strong> The insights gained from this form will help us to
              improve our organization.
            </p>

            <p class="info-text mb-3">Your submission will be <strong>anonymous</strong>.</p>
          </template>
          <a-icon type="info-circle" class="info-icon p-1" />
        </a-popover>
      </h2>
    </a-row>
    <a-row align="middle">
      <a-form layout="vertical" data-vv-scope="colleague-survey">
        <a-form-item
          :required="true"
          :validate-status="$validator.errors.first('comment') ? 'error' : ''"
          :help="$validator.errors.first('comment')"
        >
          <a-textarea
            v-model="newSurveyResponse.comment"
            v-validate="'required'"
            data-vv-name="comment"
            :auto-size="{ minRows: 4 }"
            @change="onCommentChange"
          />
        </a-form-item>
      </a-form>
    </a-row>
    <a-row>
      <div v-if="show_not_valid_text" class="d-flex justify-content-center">
        <p class="warning-text">You need to fill in the survey form before submitting!</p>
      </div>
      <div class="d-flex justify-content-center">
        <a-button class="send-button" type="primary" @click="handleStoreSurveyResponse()"
          >Submit</a-button
        >
      </div>
    </a-row>
  </div>
</template>

<script setup lang="ts">
import { getCurrentInstance, ref } from "vue";
import { SurveyPayloadInterface } from "../types";
import useMixin from "@/useMixin";

// Emits
const emits = defineEmits(["save-survey-response"]);

// Mixins
const { setObject } = useMixin();

// Instances
const instance = getCurrentInstance();
const $validator = instance?.proxy.$validator;

// Data properties
const show_not_valid_text = ref<Boolean>(false);
const newSurveyResponse = ref<SurveyPayloadInterface>({
  comment: "",
});

//Component methods
const onCommentChange = (): void => {
  show_not_valid_text.value = false;
};

const handleStoreSurveyResponse = (): void => {
  $validator?.validateAll("colleague-survey").then((valid) => {
    if (!valid) {
      show_not_valid_text.value = true;
    } else {
      emitSurveyAndClearFields();
    }
  });
};

const emitSurveyAndClearFields = (): void => {
  emits("save-survey-response", newSurveyResponse.value);
  newSurveyResponse.value = setObject(newSurveyResponse.value, undefined);
  newSurveyResponse.value.comment = "";
};
</script>

<style scoped>
.info-icon {
  color: rgba(0, 0, 0, 0.45);
}

.send-button {
  width: 50%;
}

.warning-text {
  color: red;
  text-align: center;
}

.info-text {
  font-size: 14px;
  line-height: 12px;
}

@media (min-width: 1025px) {
  .vertical {
    border-right: 2px solid rgb(243, 243, 243);
    height: 150px;
    padding: 5px;
    top: 5px;
  }

  .side-paddings-left {
    float: left;
    padding-left: 130px;
    padding-right: 10px;
  }

  .side-paddings-right {
    float: right;
    padding-left: 10px;
    padding-right: 130px;
  }
}

.icon-wrapper {
  display: flex;
}

.icon-wrapper .ant-slider {
  flex-grow: 1;
}

.icon-wrapper .anticon {
  font-size: 24px;
  color: rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
}
</style>
